import React from 'react';

const IcLeftArrow = () => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00028 16.0003C7.74428 16.0003 7.48825 15.9023 7.29325 15.7073L0.29325 8.70731C-0.09775 8.31631 -0.09775 7.68425 0.29325 7.29325L7.29325 0.29325C7.68425 -0.09775 8.31631 -0.09775 8.70731 0.29325C9.09831 0.68425 9.09831 1.31631 8.70731 1.70731L2.41434 8.00028L8.70731 14.2933C9.09831 14.6842 9.09831 15.3163 8.70731 15.7073C8.51231 15.9023 8.25628 16.0003 8.00028 16.0003Z" fill="#686A6C"/>
    </svg>
  );
};

export default IcLeftArrow;
