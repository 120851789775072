import s from '@/modules/AlphaPWA/Home/PostItem/PostItemV2.module.scss';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { goToRoute } from '@/utils/go-route';
import { ROUTE_PATH } from '@/constants/route-path';
import cn from 'classnames';
import { formatPublishedDate } from '@/utils/format';
import { PointerCounter } from '@/modules/AlphaPWA/Home/PostItem/PointerCounter';
import { MenuActions } from '@/modules/AlphaPWA/Home/PostItem/MenuActions';
import TipButton from '@/modules/AlphaPWA/Home/PostItem/TipButton';
import ReplyButton from '@/modules/AlphaPWA/Home/PostItem/ReplyButton';
import RepostButton from '@/modules/AlphaPWA/Home/PostItem/RepostButon';
import LikeButton from '@/modules/AlphaPWA/Home/PostItem/LikeButton';
import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import { WalletContext } from '@/contexts/wallet-context';
import { PostItemContext } from '@/contexts/post-item';
import { IPostData } from '@/interfaces/api/post';
import { createPortal } from 'react-dom';
import CreatePost from '@/modules/AlphaPWA/Home/CreatePost';
import PostContent from '../PostContent';
import { PinLabel } from '@/modules/AlphaPWA/Home/PostItem/PinLabel';
import { RepostLabel } from '@/modules/AlphaPWA/Home/PostItem/RepostLabel';
// import { ReplyLabel } from '@/modules/AlphaPWA/Home/PostItem/Replylabel';
import styles from './MainPost.module.scss';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { PostFollow } from '../PostFollow';

type Props = {
  isFetchAllData?: boolean;
  data: IPostData;
  dataParent?: any;
  isPinned?: boolean;
  onFetchData?: (isRefeshFromTop?: boolean) => void;
  fetchPinnedPosts?: any;
  isProfile?: boolean;
  isProfilePage?: boolean;
  isShowFooter?: boolean;
  parent?: any;
  postType?: 'post' | 'reply';
  onClickReply?: () => void;
};

export const MainPost = React.memo(
  ({
    data,
    dataParent,
    onFetchData,
    isPinned = false,
    isProfilePage = false,
    fetchPinnedPosts,
    postType = 'post',
    parent,
    onClickReply,
    isProfile,
  }: Props) => {
    const router = useRouter();

    const { addressL2 } = useContext(WalletContext);
    const { isRelayShow, isShowReaction, isCanRepost } =
      useContext(PostItemContext);
    const [setShowReplyPost, setSetShowReplyPost] = useState(false);

    const ref = useRef<any>(null);

    const user = data?.user;

    const [isFollowed, setIsFollowed] = useState<boolean | undefined>(
      undefined
    );

    const [reCheckFollow, setReCheckFollow] = useState<number>(0);

    const postByMe = addressL2 === data?.user?.address;
    const isShowPoint = isFollowed || postByMe;

    const handleClickReply = () => {
      if (onClickReply) {
        onClickReply();
      }
      setSetShowReplyPost(!setShowReplyPost);
    };
    const isReplyPost = useMemo(() => {
      return postType === 'reply';
    }, [postType]);

    const isRepost = useMemo((): boolean => {
      return (
        dataParent?.reposted_list &&
        dataParent?.reposted_list.length > 0 &&
        !isReplyPost
      );
    }, [dataParent, isReplyPost]);

    const isCanRepostCheck = useMemo((): boolean => {
      return isCanRepost(data, dataParent);
    }, [data, dataParent]);

    const goToPostDetail = useCallback(() => {
      if (data.activity) return;
      // router.push(
      //   `${ROUTE_PATH.ALPHA_MOBILE_TWEET_DETAIL}/${data?.id}?ownerAddress=${data?.user?.address}`
      // );
    }, []);

    if (!data) return <></>;

    return (
      <>
        {isPinned && !isReplyPost && <PinLabel />}
        {isRepost && (
          <RepostLabel userRepost={dataParent?.reposted_list?.[0]} />
        )}
        <div
          className={`${s.post_inner} post_inner`}
          onClick={() => {
            if (
              data?.activity === 'bull_bear_create' ||
              data?.activity === 'bull_bear_end'
            ) {
              return;
            } else {
              goToPostDetail();
            }
          }}
          ref={ref}
        >
          <div className={s.post_avatar}>
            <Avatar
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                if (!user.address) return;
                goToRoute(
                  router,
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${user.address}`
                );
              }}
              className={s.avatar}
              url={getUrlAvatarTwitter(
                user?.twitter_avatar || user?.avatar || '',
                'medium'
              )}
              address={user?.address || ''}
              width={44}
              name={user?.twitter_name}
              fontSize={16}
            />
          </div>
          <div className={cn(s.post_content)}>
            <div onClick={goToPostDetail} className={s.post_content_top}>
              <div className={s.post_content_top_user}>
                <span className={s.post_content_top_user_name}>
                  {user?.twitter_name}
                </span>
              </div>
              <span className={cn(s.post_content_top_date, s.text_seconde)}>
                {formatPublishedDate(data?.created_at)}
              </span>
              {isShowPoint && isProfilePage && (
                <PointerCounter
                  portfolio_value={data?.user?.portfolio_value}
                  total_point={data?.user?.total_point}
                  last_claim={data?.user?.last_claim}
                />
              )}

              <div className={`${s.leftAction}`}>
                {!postByMe && (
                  <PostFollow
                    isFollowed={isFollowed}
                    setIsFollowed={setIsFollowed}
                    reCheckFollow={reCheckFollow}
                    twitter_id={data?.user?.twitter_id}
                    twitter_name={
                      data?.user?.twitter_name || data?.user?.twitter_username
                    }
                  />
                )}

                <MenuActions
                  isPinned={isPinned}
                  fetchPinnedPosts={fetchPinnedPosts}
                  data={data}
                  isFollowed={isFollowed}
                  setIsFollowed={setIsFollowed}
                  dataParent={dataParent}
                  onFetchData={() => {
                    onFetchData && onFetchData(true);
                  }}
                  postType={postType}
                />
              </div>
            </div>
            <div
              className={`${s.post_address} ${isShowReaction ? s.isShowReaction : ''
                }`}
              id="post-item-wrap-content"
            >
              <div className={`${s.right} ${isRelayShow ? s.isRelayShow : ''}`}>
                <PostContent data={data} />
                <>
                  <div
                    onClick={goToPostDetail}
                    className={`${s.post_content_footer}`}
                  >
                    <ReplyButton
                      classNames={'footer_item'}
                      handleClickReply={handleClickReply}
                      replyCount={
                        isRepost ? dataParent?.reply_count : data?.reply_count
                      }
                    />
                    {isCanRepostCheck && (
                      <RepostButton
                        classNames={'footer_item'}
                        onFetchData={() => {
                          onFetchData && onFetchData();
                        }}
                        isPublic={dataParent?.public}
                        // postId={!isRepost ? data?.id : dataParent?.id}
                        postId={data?.id}
                        userAddress={addressL2 || ''}
                        dataRepost={{
                          isRepost,
                          userRepost: dataParent?.user?.address,
                          count: dataParent?.repost_count,
                          isRepostByMe: dataParent?.reposted_by_me,
                        }}
                      />
                    )}

                    <LikeButton
                      classNames={'footer_item'}
                      reactionType={
                        !isRepost
                          ? data?.reaction_type
                          : dataParent?.reaction_type
                      }
                      // id={isRepost ? dataParent?.id : data?.id}
                      id={data?.id}
                      numOfLikes={isRepost ? dataParent?.liked : data?.liked}
                      isLiked={
                        isRepost ? dataParent?.user_liked : data?.user_liked
                      }
                    />
                    {!isRepost && !data?.activity ? (
                      <TipButton
                        postId={data?.id}
                        tipToAddress={data?.user?.address}
                      />
                    ) : null}
                  </div>

                  {/* <ReactionList
                      postId={data?.id}
                      address={addressL2}
                      isActionLiked={isActionLiked}
                      liked={data?.liked}
                    /> */}
                </>
              </div>
            </div>
          </div>
        </div>
        {setShowReplyPost && ref.current && (
          <div
            style={{
              width: ref.current.offsetWidth + 32,
              position: 'fixed',
              right: isProfile ? 16 : 0,
            }}
            className={cn(styles.createContent)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CreatePost
              show={true}
              onClose={() => setSetShowReplyPost(false)}
              reply={data}
              onFetchData={() => {
                onFetchData && onFetchData(true);
              }}
            ></CreatePost>
          </div>
        )}
      </>
    );
  }
);
