import React from 'react';
import s from './Feed.module.scss';

const Empty = () => {
  return (
    <div className={s.empty}>
      <p className={s.emptyText}>
        No data
      </p>
    </div>
  )
}

export default Empty;