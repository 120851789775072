import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';

import AppLoading from '@/components/AppLoading';
import EmptyList from '@/components/ListTable/EmptyList';
import ScrollWrapper from '@/components/ScrollWrapper/ScrollWrapper';
import {
  IBodyBuySweetFloorKeys,
  IResponseBuySweetFloorKeys,
} from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import useDebounce from '@/hooks/useDebounce';
import { showError } from '@/modules/AlphaPWA/Profiles/TradeKey/components/toast';
import {
  DEFAULT_SLIDE,
  KEY_FILTER,
  KEY_LAST_SORT,
  KEY_LAST_SORT_VALUE,
  PLACE_HOLDER_LAST_SORT,
  PLACE_HOLDER_LAST_SORT_VALUE,
  SLIDE_FT,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import {
  DESKTOP_SORTS,
  PLACE_HOLDER_SORTS,
} from '@/modules/AlphaPWA/Profiles/TradeKey/pro/itemFilterSort';
import { FILTER_TYPES } from '@/modules/AlphaPWA/Profiles/TradeKey/pro/itemFilterTypes';
import { IParamsGetKeys, ITradeKey } from '@/services/interfaces/trade';
import {
  requestReload,
  selectCommonReducer,
  setFilterKeyParams,
} from '@/state/common';
import { compareString } from '@/utils';
import { getErrorMessageContract } from '@/utils/helpers';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import ProKeyFooter from './footer';
import ProKeyHeader from './header';
import ProKeyItem from './item';
import s from './styles.module.scss';
import storageLocal from '@/utils/storage.local';
import ListTable, { ColumnProp } from '@/components/ListTable';
import IcTwitterX from '@/modules/AlphaPWA/Keys/pro/key/img/icTwitterX';
import ItemRow from '@/modules/AlphaPWA/Keys/pro/key/itemRow';
import { getReferralCode } from '@/services/referral';

export interface IKeysCommon {
  selectKeys: IBodyBuySweetFloorKeys[];
}

const ModuleProKeys = () => {
  const { needReload, filterKeyParams } = useSelector(selectCommonReducer);
  const { getTradeKeys, isUserFT, buySweetFloorKeys } = useTradeKey();

  const dispatch = useDispatch();

  const parseParams = useMemo(() => {
    if (filterKeyParams) {
      const _params = queryString.parse(filterKeyParams);
      if (compareString(_params?.placeholder, '1')) {
        if (storageLocal.get(PLACE_HOLDER_LAST_SORT)) {
          _params.sort_col = storageLocal.get(PLACE_HOLDER_LAST_SORT);
          _params.sort_type = storageLocal.get(PLACE_HOLDER_LAST_SORT_VALUE);
        }
      } else {
        if (storageLocal.get(KEY_LAST_SORT)) {
          _params.sort_col = storageLocal.get(KEY_LAST_SORT);
          _params.sort_type = storageLocal.get(KEY_LAST_SORT_VALUE);
        }
      }
      return _params;
    }
    return {};
  }, [filterKeyParams]);

  const DEFAULT_LIMIT = useRef(30).current;
  const refDone = useRef(false);
  const refParams = useRef<IParamsGetKeys>({
    page: 1,
    limit: DEFAULT_LIMIT,
    key_type: '1',
    side: DEFAULT_SLIDE,
    ...parseParams,
  });
  const hasIncrementedPageRef = useRef(false);
  const refInitial = useRef(false);

  const [keys, setKeys] = useState<ITradeKey[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [searching, setSearching] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [numProcessDone, setNumProcessDone] = useState(0);
  const [selectKeys, setSelectKeys] = useState<IBodyBuySweetFloorKeys[]>([]);
  const [waitKeys, setWaitKeys] = useState<IResponseBuySweetFloorKeys[]>([]);
  const keyListView = useSelector(selectCommonReducer).keyListView;

  const numProcessDoneDebounced = useDebounce(numProcessDone);

  const [referralCode, setReferralCode] = useState();

  useEffect(() => {
    getReferralCode()
      .then(code => {
        setReferralCode(code);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    // if (!refInitial.current) {
    refInitial.current = true;
    const localParams = storageLocal.get(KEY_FILTER);
    if (localParams) {
      const _params = queryString.parse(localParams);

      refParams.current = {
        ...refParams.current,
        ..._params,
      };

      dispatch(setFilterKeyParams(localParams));
    }
    fetchData(true);
    // }
  }, [needReload]);

  useEffect(() => {
    if (
      submitting &&
      numProcessDone === selectKeys.length &&
      !refDone.current
    ) {
      refDone.current = true;
      setIsDone(true);
    }
  }, [numProcessDoneDebounced, submitting, selectKeys, refDone.current]);

  const onDoneAndClose = () => {
    setNumProcessDone(0);
    setSelectKeys([]);
    setSubmitting(false);
    setWaitKeys([]);
    setIsDone(false);
    dispatch(requestReload());
    onRefresh();
    refDone.current = false;
  };

  const fetchData = async (isNew?: boolean) => {
    try {
      setIsFetching(true);
      const response = await getTradeKeys(refParams.current);

      if (isNew) {
        refParams.current = {
          ...refParams.current,
          page: 1,
        };
        setKeys(response.rows);
      } else {
        setKeys(_keys => [..._keys, ...response.rows]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsFetching(false);
      setSearching(false);
      setFiltering(false);
      setInitialLoading(false);
      hasIncrementedPageRef.current = false;
      //   refInitial.current = true;
    }
  };

  const onFilter = (_params: any) => {
    setInitialLoading(true);
    if (isFetching || submitting || refreshing || searching || filtering) {
      return true;
    }
    refParams.current = {
      ...refParams.current,
      page: 1,
      ..._params,
    };

    setSelectKeys([]);

    dispatch(setFilterKeyParams(queryString.stringify(_params)));
    setFiltering(true);
    fetchData(true);
  };

  const onFetch = () => {
    refParams.current = {
      ...refParams.current,
      page: Number(refParams.current.page) + 1,
    };
    hasIncrementedPageRef.current = true;
    fetchData();
  };

  const onRefresh = async () => {
    if (searching || isFetching) {
      return;
    }
    try {
      setRefreshing(true);
      refParams.current = {
        ...refParams.current,
        page: 1,
      };
      await fetchData(true);
    } catch (error) {
      console.log('refreshing err', error);
    } finally {
      setRefreshing(false);
    }
  };

  const hasSortNew = useMemo(() => {
    if (parseParams) {
      return (
        (compareString(parseParams?.sort_col, DESKTOP_SORTS[0].key) &&
          compareString(parseParams?.placeholder, '0')) ||
        (compareString(parseParams?.sort_col, PLACE_HOLDER_SORTS[1].key) &&
          compareString(parseParams?.placeholder, '1'))
      );
    }
  }, [parseParams]);

  const hasSortPortfolio = useMemo(() => {
    if (parseParams) {
      return compareString(parseParams?.sort_col, DESKTOP_SORTS[3].key);
    }
  }, [parseParams]);

  const isPlaceHolder = useMemo(() => {
    return compareString(parseParams?.placeholder, '1');
  }, [parseParams]);

  const hasPlaceholderShowTop = useMemo(() => {
    if (
      compareString(parseParams?.placeholder, '1') &&
      (compareString(parseParams?.sort_col, PLACE_HOLDER_SORTS[2].key) ||
        compareString(parseParams?.sort_col, PLACE_HOLDER_SORTS[3].key))
    ) {
      return parseParams?.sort_col as string;
    }
    return '0';
  }, [parseParams]);

  const onChangeSweep = (_numSweep: any) => {
    let _data = [];
    if (!isUserFT) {
      _data = keys.filter(v => !compareString(v.side, SLIDE_FT));
    } else {
      _data = keys;
    }

    if (Number(_numSweep) <= keys.length) {
      const _selectKeys = keys.slice(0, Number(_numSweep));

      setSelectKeys(
        _selectKeys.map(v => ({
          token_address: v.address as string,
          token_amount: 1,
          side: v.side,
          key: v,
        }))
      );
    }
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      const nbSelectKeys = selectKeys;
      const txs = (await buySweetFloorKeys(
        nbSelectKeys
      )) as IResponseBuySweetFloorKeys[];

      setWaitKeys(txs);
    } catch (error: any) {
      showError(getErrorMessageContract(error));
    } finally {
      dispatch(requestReload());
      // setSubmitting(false);
    }
  };

  const renderKeys = () => {
    if (initialLoading) {
      return (
        <Flex alignItems={'center'} justifyContent={'center'} height={'100%'}>
          <AppLoading />
        </Flex>
      );
    }
    if (keys.length === 0 && !initialLoading) {
      return (
        <Flex alignItems={'center'} justifyContent={'center'} height={'100%'}>
          <EmptyList
            labelText={
              <Text textAlign={'center'}>
                Your filters produced no results.
                <br />
                Try adjusting or clearing your filters to display better
                results.
              </Text>
            }
          />
        </Flex>
      );
    }

    return (
      <SimpleGrid
        // minChildWidth={['180px', '270px', '450px']}
        minChildWidth={['180px']}
        className={s.gridContainer}
        spacing={'8px'}
      >
        {keys.map(item => (
          <GridItem key={`${item.ft_trader_address}_${item.owner}`}>
            <ProKeyItem
              userKey={item}
              selectKeys={selectKeys}
              setSelectKeys={setSelectKeys}
              hasSortNew={hasSortNew}
              hasPlaceholderShowTop={hasPlaceholderShowTop}
              hasSortPortfolio={hasSortPortfolio}
            />
          </GridItem>
        ))}
      </SimpleGrid>
    );
  };

  const renderKeysList = () => {
    if (initialLoading) {
      return (
        <Flex alignItems={'center'} justifyContent={'center'} height={'100%'}>
          <AppLoading />
        </Flex>
      );
    }

    return (
      <ListTable
        data={keys}
        columns={columns}
        showEmpty={!isFetching}
        emptyLabel={
          <Text textAlign={'center'}>
            Your filters produced no results.
            <br />
            Try adjusting or clearing your filters to display better results.
          </Text>
        }
        initialLoading={filtering}
        // onItemClick={e => onCheckBox(e)}
        ItemListComponent={(row, extraData, columns, i) => {
          return (
            <ItemRow
              userKey={row}
              selectKeys={selectKeys}
              setSelectKeys={setSelectKeys}
              hasSortNew={hasSortNew}
              hasPlaceholderShowTop={hasPlaceholderShowTop}
              refParams={refParams}
              isPlaceHolder={isPlaceHolder}
              referralCode={referralCode}
              hasSortPortfolio={hasSortPortfolio}
            />
          );
        }}
      />
    );
  };

  const columns: ColumnProp[] = useMemo(() => {
    return [
      {
        id: 'player',
        label: 'Key',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey, extraData: any, index: number) {
          return (
            <Flex
              gap={3}
              alignItems={'center'}
              width={'100%'}
              id={`user_${data.user_twitter_id}`}
            ></Flex>
          );
        },
      },
      {
        id: 'tradingVolume',
        label: 'Price',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey) {
          return (
            <Flex gap={2} direction={'column'} alignItems={'flex-end'}></Flex>
          );
        },
      },
      {
        id: 'twitter_followers_count',
        label: isPlaceHolder ? (
          <Tooltip
            hasArrow
            bg="var(--alpha-headerBg)"
            color="white"
            placement="top"
            label={
              'Publish a post on X inviting owners of placeholder keys to join NBC and earn 100 airdrop point per invitation.'
            }
            className="popup-tooltip"
          >
            <Box className={s.textInviteToEarn}>Invite to Earn</Box>
          </Tooltip>
        ) : (
          <Flex gap={1}>
            <IcTwitterX />
          </Flex>
        ),
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey) {
          return (
            <Flex gap={2} direction={'column'} alignItems={'flex-end'}></Flex>
          );
        },
      },
    ];
  }, [selectKeys, submitting, refParams.current, isPlaceHolder]);

  return (
    <>
      <ProKeyHeader
        filters={FILTER_TYPES}
        onFilter={onFilter}
        setSearching={setSearching}
        searching={searching}
        isFetching={isFetching}
      />
      <ScrollWrapper
        onFetch={onFetch}
        isFetching={isFetching}
        hasIncrementedPageRef={hasIncrementedPageRef}
        onFetchNewData={onRefresh}
        containerClassName={s.listContainer}
      >
        {compareString(keyListView, '1') ? (
          <>{renderKeysList()}</>
        ) : (
          <>{renderKeys()}</>
        )}
      </ScrollWrapper>

      <ProKeyFooter
        sweepMax={10}
        selectKeys={selectKeys}
        onChangeSweep={onChangeSweep}
        onSubmit={onSubmit}
        isFetching={isFetching}
        submitting={submitting}
        isDone={isDone}
        keys={keys}
        waitKeys={waitKeys}
        setNumProcessDone={setNumProcessDone}
        numProcessDone={numProcessDone}
        onDoneAndClose={onDoneAndClose}
      />
    </>
  );
};

export default ModuleProKeys;
