import { API_DGAMES } from '@/configs';
import { IReferralEarning } from '@/interfaces/api/referral';
import { apiClient } from '@/services';

const API_PATH = 'v1/referral';

export const getReferralCode = async () => {
  return ((await apiClient.get(`${API_DGAMES}/${API_PATH}/code`)) as any).code;
};

export const getReferralCodeByTwId = async (payload: {
  twitter_id: string;
}) => {
  return (
    (await apiClient.get(
      `${API_DGAMES}/${API_PATH}/get-referral-code?twitterId=${payload.twitter_id}`
    )) as any
  ).code;
};

export const useReferralCode = async (payload: { code: string }) => {
  return (await apiClient.post(`${API_DGAMES}/${API_PATH}`, {
    ...payload,
  })) as any;
};

export const getAddressReferrer = async () => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/referrer`);
  return (res as any).referrer;
};

export const getAddressReferents = async (): Promise<{ address: string }[]> => {
  const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/referents`);
  return (res as any).referents;
};

export const getAddressReferralEarning =
  async (): Promise<IReferralEarning> => {
    const res = await apiClient.get(`${API_DGAMES}/${API_PATH}/earning`);
    return res as any;
  };

export const claimReferralEarning = async () => {
  return (
    (await apiClient.post(`${API_DGAMES}/${API_PATH}/claim-earning`, {})) as any
  ).result;
};
