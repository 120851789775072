import { ReferralModal } from '@/modules/AlphaPWA/OverlayPages/ShareReferral/types';
import styles from './styles.module.scss';
import cs from 'classnames';
import { useOverlay } from '@/providers/OverlayProvider';
import EnvelopIcon from '@/modules/AlphaPWA/OverlayPages/ShareReferral/Congratulation/EnvelopIcon';
import { WalletContext } from '@/contexts/wallet-context';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { useContext } from 'react';
import { TRADE_ACTION } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import CloseIcon from '@/modules/AlphaPWA/OverlayPages/ShareReferral/ReferralList/CloseIcon';

export default function Congratulation({
  setModal,
}: {
  setModal: React.Dispatch<React.SetStateAction<ReferralModal>>;
}) {
  const { closeOverlayScreen, screenProps } = useOverlay();
  const gaEventTracker = useAnalyticsEventTracker();
  const { addressL2 } = useContext(WalletContext);

  const getUserName = () => {
    return Object(screenProps)?.keyInfo?.user_twitter_name || '';
  };

  const getKeys = () => {
    const keys = Object(screenProps)?.baseAmount || 1;
    if (keys > 1) {
      return `${keys} keys`;
    }
    return `${keys} key`;
  };

  const getAction = () => {
    return Object(screenProps)?.action === TRADE_ACTION.BUY ? 'bought' : 'sold';
  };

  const renderHeading = () => {
    if (screenProps) {
      return (
        <div className={styles.heading}>
          <span
            className={styles.heading__close}
            onClick={() => {
              closeOverlayScreen();
              gaEventTracker(
                GameFiActions.PopupReferralLaterButtonClick,
                addressL2
              );
            }}
          >
            <CloseIcon />
          </span>
          Congratulations ✨ You have successfully {getAction()} {getKeys()} for{' '}
          {getUserName()}
        </div>
      );
    }
    return (
      <div className={styles.heading}>
        <span
          className={styles.heading__close}
          onClick={() => {
            closeOverlayScreen();
            gaEventTracker(
              GameFiActions.PopupReferralLaterButtonClick,
              addressL2
            );
          }}
        >
          <CloseIcon />
        </span>
        Congratulations
      </div>
    );
  };

  return (
    <div className={styles.congratulation}>
      <div className={styles.container}>
        {renderHeading()}

        <div className={styles.body}>
          <div className={styles.body__icon}>
            <EnvelopIcon />
          </div>
          <div className={styles.body__message}>
            Refer your friends and
            <span className={styles.body__message__highlight}>
              {' '}
              earn 100%
            </span>{' '}
            of their earnings.
          </div>

          <div className={styles.body__actions}>
            <button
              className={cs(styles.body__button, styles.body__actions__invite)}
              onClick={() => {
                setModal('ReferralList');
                gaEventTracker(
                  GameFiActions.PopupReferralInviteYourFriendButtonClick,
                  addressL2
                );
              }}
            >
              Refer a friend
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
