import {PERP_API_URL} from '@/configs';
import {WalletContext} from '@/contexts/wallet-context';
import {apiClient} from '@/services';
import {closeModal} from '@/state/modal';
import accountStorage from '@/utils/account.storage';
import {decryptAES} from '@/utils/encryption';
import {Box, Flex, ListItem, ModalHeader, OrderedList, Text,} from '@chakra-ui/react';
import React, {useContext} from 'react';
import QRCode from 'react-qr-code';
import {useDispatch} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import s from './styles.module.scss';
import {generateRandomString} from "@/utils";

export const SYNC_PROFILE_MODAL = 'SYNC_PROFILE_MODAL';

const SyncProfileModal = () => {
  const dispatch = useDispatch();
  const { onLoginL2 } = useContext(WalletContext);

  const publicCode = uuidv4();
  const privateCode = uuidv4();

  const randomCode = generateRandomString(10);
  const publicCodeInput = randomCode.substring(0, 2);
  const privateCodeInput = randomCode.substring(2, 10);

  const qrInfo = JSON.stringify({
    publicCode: publicCode,
    privateCode: privateCode,
  });

  const getSyncData = async (publicCode: string) => {
    try {
      const res: any = await Promise.allSettled([
        apiClient.get(
          `${PERP_API_URL}/api/user/qr-sync?token=${publicCode}`
        ),
        apiClient.get(
          `${PERP_API_URL}/api/user/qr-sync?token=${publicCodeInput}`
        )
      ]);
      let rs = '';
      let descryptCode = '';

      if(res[0].value) {
        rs = res[0].value;
        descryptCode = privateCode;
      } else if(res[1].value) {
        rs = res[1].value;
        descryptCode = privateCodeInput;
      }
      // const rs: any = await apiClient.get(
      //   `${PERP_API_URL}/api/user/qr-sync?token=${publicCode}`
      // );

      if (rs) {
        const data = JSON.parse(decryptAES(rs, descryptCode));

        if (data && data.ACCOUNT_CIPHER_TEXT) {
          accountStorage.setTwitterToken(data.twitter_token);

          accountStorage.setAccount({
            prvKey: decryptAES(
              data.ACCOUNT_CIPHER_TEXT,
              `${data.NUMBER_STORAGE_L2}`
            ),
            password: `${data.NUMBER_STORAGE_L2}`,
          });
          onLoginL2(`${data.NUMBER_STORAGE_L2}`, true);

          dispatch(closeModal({ id: SYNC_PROFILE_MODAL }));
          window.location.reload();
        }
      }
    } catch (error) {
      console.log('errorrrrr', error);
    }
  };

  React.useEffect(() => {
    getSyncData(publicCode);
    const interval = setInterval(() => {
      getSyncData(publicCode);
    }, 3000); // 3s
    return () => {
      clearInterval(interval);
    };
  }, [publicCode]);

  return (
    <div className={s.container}>
      <ModalHeader>
        <Text fontSize="32px" fontWeight={'600'} textAlign={'center'}>
          Scan to log in
        </Text>
      </ModalHeader>
      <Box className={s.qrWrapper}>
        <QRCode className={s.qrCode} size={200} value={qrInfo} />
        <Flex
          fontSize={'14px'}
          flexDirection="column"
          mt={8}
          mb={4}
          px={12}
          py={8}
          bgColor={'#FFFFFF26'}
          borderRadius={'8px'}
          gap="4px"
        >
          <Text
            textAlign="left"
            fontSize={'16px'}
            fontWeight={600}
            color={'#ECECED'}
            mb="4px"
          >
            How to sync your New Bitcoin profile to the desktop:
          </Text>
          <OrderedList fontWeight={400} color={'#ECECED'}>
            <ListItem textAlign="left">
              Visit{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="https://alpha.wtf/app"
              >
                https://alpha.wtf/app
              </a>{' '}
              and follow the on-screen instructions to install New Bitcoin app.
            </ListItem>
            <ListItem textAlign="left">
              Open New Bitcoin App on your mobile
            </ListItem>
            <ListItem mt="4px" textAlign="left">
              Tap the balance at the top right to access the Wallet page
            </ListItem>
            <ListItem mt="4px" textAlign="left">
              Click on the QR scan icon next to the three dots icon
            </ListItem>
            <ListItem mt="4px" textAlign="left">
              Scan the QR code provided
            </ListItem>
          </OrderedList>
        </Flex>
        <Flex
          fontSize={'14px'}
          flexDirection="column"
          mt={8}
          mb={4}
          px={12}
          py={8}
          bgColor={'#FFFFFF26'}
          borderRadius={'8px'}
          gap="4px"
        >
          <Text
            textAlign="left"
            fontSize={'16px'}
            fontWeight={600}
            color={'#ECECED'}
            mb="4px"
          >
            Can't scan qr code
          </Text>
          <Text fontWeight={400} color={'#ECECED'}>
            Please use this code on your mobile app.
          </Text>
          <Text
            textAlign="center"
            fontSize={'20px'}
            fontWeight={600}
            color={'#ECECED'}
            mb="4px"
          >
            {publicCodeInput}{privateCodeInput}
          </Text>
        </Flex>
      </Box>
    </div>
  );
};

export default React.memo(SyncProfileModal);
