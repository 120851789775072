import React, { useState } from 'react';
import s from './ChartSelector.module.scss';
import CheckBox from '@/components/CheckBox/components/CheckBoxSavePassInfo';
import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

export const SYMBOL_OPTIONS = ['BTC', 'ETH', 'SOL', 'PEPE'];
const INTERVAL = ['1h', '4h', '1d', '2d', '1w', '2w'];

type Props = {
  chartOptionSelect: {
    symbol: string;
    interval: string;
  };
  setchartOptionSelect: ({
    symbol,
    interval,
  }: {
    symbol: string;
    interval: string;
  }) => void;
};

const ChartSelector = ({ chartOptionSelect, setchartOptionSelect }: Props) => {
  // map SYMBOL_OPTIONS with name
  enum symbolName {
    BTC = 'Bitcoin',
    ETH = 'Ethereum',
    SOL = 'Solana',
    PEPE = 'Pepe',
  }

  return (
    <div className={s.wrapper}>
      <div className={s.symbol}>
        <div className={s.symbolOption}>
          <RadioGroup
            onChange={value => {
              setchartOptionSelect({
                symbol: value,
                interval: chartOptionSelect.interval,
              });
            }}
            value={chartOptionSelect.symbol}
          >
            <Stack direction="column">
              {SYMBOL_OPTIONS.map(symbol => (
                <Radio key={symbol} value={symbol}>
                  <Text fontSize={'14px'} fontWeight={500}>
                    {symbol}
                  </Text>
                  <Text fontSize={'12px'} opacity={0.7}>
                    {symbolName[symbol as keyof typeof symbolName]}
                  </Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </div>
      </div>

      <div className={s.interval}>
        <div className={s.intervalOption}>
          <RadioGroup
            onChange={value => {
              setchartOptionSelect({
                symbol: chartOptionSelect.symbol,
                interval: value,
              });
            }}
            value={chartOptionSelect.interval}
          >
            <Stack direction="row" justifyContent={'space-between'}>
              {INTERVAL.map(interval => (
                <Radio key={interval} value={interval}>
                  {interval}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};

export default ChartSelector;
