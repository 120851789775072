import { WalletContext } from '@/contexts/wallet-context';
import TopupModal, { TOPUP_MODAL_L2 } from '@/layouts/AlphaLayout/TopupModal';
import { GG_RECAPTCHA_SITE_V3 } from '@/modules/TopupPage/constants';
import {
  generateMessage,
  getOtpByWalletAddress,
  verifySignature,
} from '@/services/signature';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { openModal, selectModals } from '@/state/modal';
import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import useCountdown from './useCountdown';
import { ModalComponentProps } from '@/components/ModalManage/ModalComponent';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import { isProduction } from '@/utils/commons';

const useOpenDepositModal = () => {
  const dispatch = useAppDispatch();
  const modals: ModalComponentProps[] = useAppSelector(selectModals).modals;
  const { keySetL2, addressL2 } = useContext(WalletContext);
  const [expiredTime, setExpiredTime] = useState('');
  const [needNewOtp, setNeedNewOtp] = useState(false);
  const [fetchingOtp, setFetchingOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker();

  const { ended } = useCountdown(expiredTime);

  const generateWalletCode = async (): Promise<void> => {
    try {
      setFetchingOtp(true);
      const otpRes = await getOtpByWalletAddress(keySetL2.address!);
      if (!otpRes) {
        // addCapcha();
        generateMessageCode();
      } else {
        setOtp(otpRes.otp);
        setExpiredTime(otpRes.expired);
        window.open(
          `https://${
            isProduction() ? '' : 'dev.'
          }newbitcoincity.com/deposit?code=${otpRes.otp}`,
          '_blank'
        );
        // dispatch(
        //   openModal({
        //     id: TOPUP_MODAL_L2,
        //     theme: 'dark',
        //     modalProps: {
        //       centered: true,
        //       zIndex: 9999999,
        //     },
        //     render: () => (
        //       <TopupModal otp={otpRes.otp} expiredTime={otpRes.expired} />
        //     ),
        //   })
        // );
      }
    } catch (err: unknown) {
      console.log(err);
    } finally {
      setNeedNewOtp(false);
      setFetchingOtp(false);
      setShowModal(true);
    }
  };

  const generateMessageCode = async (): Promise<void> => {
    try {
      setFetchingOtp(true);

      const message = await generateMessage({
        wallet_address: keySetL2.address!,
      });
      let wallet = new ethers.Wallet(keySetL2.prvKey!);
      const signature = await wallet.signMessage(message);
      const verifyRes = await verifySignature({
        wallet_address: keySetL2.address!,
        signature,
        reCaptcha: token,
      });
      setOtp(verifyRes.otp);
      setExpiredTime(verifyRes.expired);

      dispatch(
        openModal({
          id: TOPUP_MODAL_L2,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          render: () => (
            <TopupModal otp={verifyRes.otp} expiredTime={verifyRes.expired} />
          ),
        })
      );
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setFetchingOtp(false);
    }
  };

  const handleLoaded = () => {
    if (window && window.grecaptcha && window.grecaptcha.ready) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(GG_RECAPTCHA_SITE_V3, { action: 'homepage' })
          .then((token: string) => {
            setToken(token);
            // generateMessageCode();
          });
      });
    }
  };

  const addCapcha = async () => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GG_RECAPTCHA_SITE_V3}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
    return () => {
      const script = document.createElement('script');
      document.body.removeChild(script);
    };
  };

  const handleOpenTopup = async () => {
    gaEventTracker(AlphaActions.ClickDeposit, addressL2 || '');
    generateWalletCode();
  };

  useEffect(() => {
    if (token) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [token]);

  useEffect(() => {
    addCapcha();
  }, []);

  useEffect(() => {
    if (modals.length === 0) {
      setShowModal(false);
    }
  }, [modals]);

  useEffect(() => {
    if (ended) {
      setNeedNewOtp(true);
    }
  }, [ended]);

  // useEffect(() => {
  //   if (needNewOtp) {
  //     if (window && window.grecaptcha && window.grecaptcha.ready) {
  //       window.grecaptcha.ready(() => {
  //         window.grecaptcha
  //           .execute(GG_RECAPTCHA_SITE_V3, { action: 'homepage' })
  //           .then((token: string) => {
  //             generateWalletCode();
  //           });
  //       });
  //     }
  //   }
  // }, [needNewOtp]);

  return { fetchingOtp, handleOpenTopup, loading };
};

export default useOpenDepositModal;
