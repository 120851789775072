import React, { memo } from 'react';
import s from './styles.module.scss';

interface IProps {
  days: string | null;
  hours: string | null;
  seconds: string | null;
  minutes: string | null;
  isHide: boolean;
}

const CountDown: React.FC<IProps> = ({
  days,
  hours,
  minutes,
  seconds,
  isHide,
}: IProps): React.ReactElement => {
  const getTimeLeft = () => {
    try {
      const time = [];
      if ((days && days !== '0') || time.length) {
        time.push(`${days}d`);
      }
      if ((hours && hours !== '00') || time.length) {
        time.push(`${hours}h`);
      }
      if ((minutes && minutes !== '00') || time.length) {
        time.push(`${minutes}m`);
      }
      if ((seconds && seconds !== '00') || time.length) {
        time.push(`${seconds}s`);
      }

      return time.filter(t => !!t).join(' : ');
    } catch (e) {
      return '-- : --';
    }
  };

  if (isHide) {
    return <></>;
  }
  return (
    <div className={s.countDown} style={{ opacity: isHide ? 0 : 1 }}>
      <h4 className={s.heading}>🔥 Time left</h4>
      <p className={s.time}>{getTimeLeft()}</p>
    </div>
  );
};

export default memo(CountDown);
