import { WalletContext } from '@/contexts/wallet-context';
import { BASE_CHAIN_ID } from '@/contracts/configs';
import { compareString } from '@/utils';
import { Button } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import React, { PropsWithChildren, useContext, useState } from 'react';

interface IWrapButtonSubmit extends PropsWithChildren {
  isDetectNetwork?: boolean;
  className?: string;
}

const WrapButtonSubmit: React.FC<IWrapButtonSubmit> = ({
  children,
  isDetectNetwork,
  className,
}) => {
  const { onConnect } = useContext(WalletContext);
  const { chainId } = useWeb3React();
  const [switching, setSwitching] = useState(false);

  const onSwitch = async () => {
    try {
      setSwitching(true);
      await onConnect(BASE_CHAIN_ID as any);
    } catch (error) {
    } finally {
      setSwitching(false);
    }
  };

  if (isDetectNetwork) {
    if (!compareString(chainId, BASE_CHAIN_ID)) {
      return (
        <Button
          type="button"
          onClick={onSwitch}
          loadingText={'Switching'}
          isDisabled={switching}
          isLoading={switching}
          width={'100%'}
          className={className}
        >
          Switch network to link friend.tech
        </Button>
      );
    }
  }

  return <>{children}</>;
};

export default WrapButtonSubmit;
