import {
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import styles from '@/modules/AlphaPWA/Profiles/TradeKey/styles.module.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import s from './styles.module.scss';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { IBodyBuySweetFloorKeys } from '@/contracts/interfaces/tradeKey';
import { BigNumber } from 'ethers';
import { AssetsContext } from '@/contexts/assets-context';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { useSelector } from 'react-redux';
import { selectCommonReducer } from '@/state/common';
import useDebounce from '@/hooks/useDebounce';

interface IModalAmount {
  selectKeys: IBodyBuySweetFloorKeys[];
  onConfirm: () => void;
  setSelectKeys33: (_: any) => void;
  setEstimateKeysTC33: (_: any) => void;
  setEstimateTC33: (_: any) => void;
}

const ModalAmount: React.FC<IModalAmount> = props => {
  const {
    selectKeys,
    onConfirm,
    setSelectKeys33,
    setEstimateKeysTC33,
    setEstimateTC33,
  } = props;
  const [keyAmount, setKeyAmount] = useState(1);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const [estimateTC, setEstimateTC] = useState<string>('0');
  const [estimateKeysTC, setEstimateKeysTC] = useState<any[]>([]);
  const [estimating, setEstimating] = useState(false);
  const [allowBuy, setAllowBuy] = useState(true);
  const { balanceL2 } = useContext(AssetsContext);
  const needReload = useSelector(selectCommonReducer).needReload;

  const { estimateTCMultiKeys } = useTradeKey();

  const onChangeSweep = (val: number) => {
    setKeyAmount(val);
  };

  const newSelectedKeys = useMemo(() => {
    const result = selectKeys.map(k => {
      return {
        token_address: k.token_address,
        token_amount: keyAmount,
      };
    });

    setSelectKeys33(result);

    return result;
  }, [selectKeys.length, keyAmount]);

  const selectKeysDebounced = useDebounce(newSelectedKeys);

  useEffect(() => {
    onEstimateTC();
  }, [selectKeysDebounced, needReload]);

  const onEstimateTC = async () => {
    try {
      setEstimating(true);
      if (newSelectedKeys.length > 0) {
        const response = await estimateTCMultiKeys(newSelectedKeys);
        setEstimateTC(response.total_tc_formatted);
        setEstimateKeysTC(response.alphaTokens);
        setAllowBuy(BigNumber.from(response.total_tc).lt(balanceL2.amountBTC));

        setEstimateTC33(response.total_tc_formatted);
        setEstimateKeysTC33(response.alphaTokens);
      } else {
        setEstimateTC('0');
        setAllowBuy(true);
        setEstimateKeysTC([]);

        setEstimateTC33('0');
        setEstimateKeysTC33([]);
      }
    } catch (error) {
      setAllowBuy(false);
      setEstimateTC('0');
      setEstimateKeysTC([]);

      setEstimateTC33('0');
      setEstimateKeysTC33([]);
    } finally {
      setEstimating(false);
    }
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  const isDisabled =
    (newSelectedKeys?.length || 0) === 0 || estimating || !allowBuy;

  return (
    <Flex className={s.container} direction={'column'} gap={10}>
      <Flex direction={'column'} gap={4}>
        <Text className={styles.title}>
          Set the key amount for (3,3) requests between 0.1 and 1. This applies
          to all your requests.
        </Text>
        <Text className={styles.label}>
          Amount: {keyAmount} key{labelAmountOrNumberAdds(keyAmount)}
        </Text>
        <Slider
          aria-label="slider-ex-1"
          defaultValue={1}
          value={keyAmount}
          min={0.1}
          max={1}
          step={0.1}
          onChange={onChangeSweep}
          // onChangeEnd={onChangeSweep}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
          <Tooltip
            hasArrow
            bg="teal.500"
            color="white"
            placement="top"
            isOpen={showTooltip}
            label={`${keyAmount}`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </Flex>
      <Flex>
        <Button
          fontSize={'16px'}
          fontWeight={500}
          isDisabled={isDisabled}
          onClick={handleConfirm}
          w={'100%'}
        >
          Confirm
        </Button>
      </Flex>
      {!allowBuy && <Text className={s.errorText}>Not enough BTC balance</Text>}
    </Flex>
  );
};

export default ModalAmount;
