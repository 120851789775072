import UserNotification from '@/modules/AlphaPWA/Notification/NewVersion/UserNotification';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import s from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NotificationsModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px 22px"
        maxW={'560px'}
        bg="#212121"
        textAlign={'center'}
      >
        <Flex justifyContent={'flex-end'}>
          <ModalCloseButton />
        </Flex>
        <ModalHeader textAlign="left" fontSize="24px" fontWeight={'600'}>
          Notifications
        </ModalHeader>
        <ModalBody p={0}>
          <div className={s.container}>
            <UserNotification />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationsModal;
