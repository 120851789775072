import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const circular = definePartsStyle({
  container: defineStyle({
    verticalAlign: 'middle',
    gap: '3px',
  }),
  control: defineStyle({
    width: '16px',
    height: '16px',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid rgb(105, 105, 105)',
    background: 'rgba(30, 30, 30, 0.5)',
    padding: 0,
    borderColor: 'rgb(105, 105, 105)',
    _checked: {
      background: 'var(--chakra-colors-blue-600)',
      color: '#000',
      borderColor: 'transparent',
      svg: {
        width: '12px',
      },
    },
  }),
  label: defineStyle({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '100%',
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { circular },
  defaultProps: {
    variant: 'circular',
  },
});
