import { ALPHA_KEY_FACTORY_ADDRESS, BTC_L2_ADDRESS } from '@/configs';
import { AssetsContext, IAssetsContext } from '@/contexts/assets-context';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import {
  SLIDE_ALPHA,
  SLIDE_FT,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import CTradeAPI from '@/services/classes/trade';
import errorLogger from '@/services/errorLogger';
import { WalletManager } from '@/services/wallet';
import store from '@/state';
import { requestReload } from '@/state/common';
import { compareString } from '@/utils';
import { formatAmountToClient, parseFee } from '@/utils/helpers';
import sleep from '@/utils/sleep';
import { useWeb3React } from '@web3-react/core';
import NBigNumber from 'bignumber.js';
import { BigNumber, Wallet, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { ceil } from 'lodash';
import { useContext } from 'react';
import { BASE_CHAIN_ID, BASE_SYMBOL } from './configs';
import {
  IBodyBuySweetFloorKeys,
  IBodyCheckABalance,
  IBodyFillOrderLimit,
  IBodyFillOrderLimitV2,
  IEstimateTCMultiKeys,
  IResponseBuySweetFloorKeys,
} from './interfaces/tradeKey';

export enum ETypes {
  'issue',
  'buy',
  'sell',
  'approve',
  'update_creator_fee',
  'withdraw',
  'transfer',
}

export const typeToFee = {
  [ETypes.issue]: 1000000,
  [ETypes.buy]: 300000,
  [ETypes.sell]: 200000,
  [ETypes.approve]: 80000,
  [ETypes.update_creator_fee]: 100000,
  [ETypes.withdraw]: 2000000,
  [ETypes.transfer]: 10000,
};

class CPlayerShare {
  metamaskWallet = new WalletManager();
  gameWalletProvider: IWalletContext = useContext(WalletContext);
  web3React = useWeb3React();

  assetContext: IAssetsContext = useContext(AssetsContext);

  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;
  metamaskWalletProvider = this.metamaskWallet.metamaskProvider;
  etherWeb3Provider: any = this.metamaskWallet.etherWeb3Provider;
  web3ChainID = this.web3React?.chainId;
  web3UserAddress = this.web3React?.account;
  web3Provider = this.web3React.provider;

  private contract = new CContract();
  private tradeAPI = new CTradeAPI();

  public platformFee = 50000;
  public defaultCreatorFee = 50000;

  public initialize = () => {};

  private balanceL2 = this.assetContext.balanceL2;
  private MIN_BUY_TC = '0.05'; // need buy 0.05 TC for fee

  private CURRENT_RETRY = 0;

  // maximum 5%
  public getPlatformFee = async (token_address: string) => {
    try {
      const fee = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getProtocolFeeRatio();

      return parseFee(fee);
    } catch (error) {
      console.log('error', error);
      return parseFee(this.platformFee);
    }
  };

  // maximum 5%
  public getCreatorFee = async (token_address: string) => {
    try {
      const fee = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getPlayerFeeRatio();

      return parseFee(fee);
    } catch (error) {
      console.log('error', error);
      return parseFee(this.defaultCreatorFee);
    }
  };

  // fee: 0 -> 5;
  public updateCreateFee = async ({
    token_address,
    fee,
  }: {
    token_address: string;
    fee: number;
  }) => {
    try {
      const amount = (parseFloat(fee.toString()) * 10000).toString();

      await this.estimateTCGasFee({ type: ETypes.update_creator_fee });

      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .connect(this.wallet)
        .updatePlayerFeeRatio(amount);
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public getPrice = (supply: BigNumber, amount: BigNumber) => {
    if (supply.eq(0)) throw 'bad supply';
    if (amount.eq(0)) {
      return BigNumber.from(0);
    }
    const sum1 = supply
      .add(1)
      .mul(supply)
      .mul(supply.sub(1).mul(2).add(1))
      .div(6);
    const sum2 = supply
      .sub(1)
      .add(amount)
      .mul(supply.add(amount))
      .mul(supply.sub(1).add(amount).mul(2).add(1))
      .div(6);
    const summation = sum2.sub(sum1);
    return summation.mul(parseEther('1')).div(264000);
  };

  public getPriceV2 = (supply: BigNumber, amount: BigNumber) => {
    if (supply.eq(0)) throw 'bad supply';
    if (amount.eq(0)) {
      return BigNumber.from(0);
    }
    const sum1 = supply
      .sub(10)
      .mul(supply)
      .mul(supply.sub(10).mul(2).add(10))
      .div(6);
    const sum2 = supply
      .sub(10)
      .add(amount)
      .mul(supply.add(amount))
      .mul(supply.sub(10).add(amount).mul(2).add(10))
      .div(6);
    const summation = sum2.sub(sum1);
    return summation.mul(parseEther('1')).div(264000).div(1000);
  };

  public getBuyPriceV2 = (supply: BigNumber, amount: BigNumber) => {
    return this.getPriceV2(
      supply.div(parseEther('0.1')),
      amount.div(parseEther('0.1'))
    ).add(1);
  };

  public getBuyPriceAfterFeeV2 = (
    supply: BigNumber,
    amount: BigNumber,
    {
      _platformFee,
      _creatorFee,
    }: {
      _platformFee: number;
      _creatorFee: number;
    }
  ) => {
    const byPrice = this.getPriceV2(
      supply.div(parseEther('0.1')),
      amount.div(parseEther('0.1'))
    ).add(1);
    console.log('creatorFee', _creatorFee, _platformFee, byPrice.toString());
    const creatorFee = byPrice
      .mul(parseEther(_creatorFee.toString()))
      .div(parseEther('1'));
    const platformFee = byPrice
      .mul(parseEther(_platformFee.toString()))
      .div(parseEther('1'));

    return byPrice.add(creatorFee).add(platformFee);
  };

  public tokenTransfer = async ({
    amountBTC,
    transferToAddress,
  }: {
    amountBTC: any;
    transferToAddress: string;
  }) => {
    try {
      await this.estimateTCGasFee({ type: ETypes.transfer });

      await this.getBTCApprove({
        spender_address: BTC_L2_ADDRESS,
        token_amount: 1,
        need_approve: true,
      });

      const tx = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .connect(this.wallet)
        .transfer(transferToAddress, parseEther(amountBTC));
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public estimateTCBeforeMint = async ({
    token_amount,
    supply,
  }: {
    token_amount: number;
    supply: number;
  }) => {
    try {
      const getBuyPrice = (supply: BigNumber, amount: BigNumber) => {
        return this.getPrice(supply, amount).add(1);
      };

      const price = getBuyPrice(
        BigNumber.from(supply),
        BigNumber.from(token_amount)
      );
      const protocolFee = price.mul(5).div(100);
      const playerFee = price.mul(5).div(100);
      const amount = price.add(protocolFee).add(playerFee);
      return amount;
    } catch (error) {
      console.log('error', error);
    }
  };

  /**
   * createAlphaKeysFor
   */
  public autoCreateAlphaKeysFor = async ({
    twitter_id,
    name,
    symbol,
    signature,
    token_amount = 0,
    tc_amount = 0,
  }: {
    twitter_id: string;
    name: string;
    symbol: string;
    signature: string;
    token_amount: number;
    tc_amount: number;
  }) => {
    try {
      const alphaKeyFactory = this.contract.getAlphaKeysFactoryContract();
      await this.estimateTCGasFee({ type: ETypes.issue });
      const multicalls = [
        alphaKeyFactory.interface.encodeFunctionData(
          'createAlphaKeysForTwitter',
          [twitter_id, name, symbol, signature]
        ),
      ];

      if (token_amount && tc_amount) {
        await this.getBTCApprove({
          spender_address: alphaKeyFactory.address,
          token_amount,
          need_approve: true,
        });

        await this.estimateTCGasFee({ type: ETypes.buy });
        multicalls.push(
          alphaKeyFactory.interface.encodeFunctionData(
            'buyKeysForV2ByTwitterId',
            [
              twitter_id,
              parseEther(token_amount.toString()),
              tc_amount,
              this.wallet.address,
            ]
          )
        );
      }

      const tx = await alphaKeyFactory
        .connect(this.wallet)
        .multicall(multicalls);
      await tx.wait();

      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getAlphaKeysToken
   */
  public getAlphaKeysToken = async () => {
    try {
      const alphaTokenAddress = await this.contract
        .getAlphaKeysFactoryContract()
        .getPlayerKeys(this.wallet.address);
      return alphaTokenAddress;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceAfterFee
   */
  public getBuyPriceAfterFee = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getBuyPriceAfterFeeV2(parseEther(token_amount.toString()));

      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceAfterFee
   */
  public getBuyPrice = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getBuyPriceV2(parseEther(token_amount.toString()));
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getSellPriceAfterFee
   */
  public getSellPriceAfterFee = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getSellPriceAfterFeeV2(parseEther(token_amount.toString()));
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getSellPriceAfterFee
   */
  public getSellPrice = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getAlphaKeysTokenContract(token_address)
        .getSellPriceV2(parseEther(token_amount.toString()));
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * buyKeys
   */
  public buyKeys = async ({
    token_address,
    token_amount,
    tc_amount,
    no_wait = false,
    is_multi = false,
  }: {
    token_address: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
    is_multi?: boolean;
  }) => {
    try {
      console.log(
        'tokenAddress -buyKeys',
        token_address,
        token_amount,
        tc_amount.toString()
      );

      const akf = this.contract.getAlphaKeysFactoryContract();

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount,
      });

      if (!is_multi) {
        await this.estimateTCGasFee({ type: ETypes.buy });
      }

      const tx = await akf
        .connect(this.wallet)
        .buyKeysV2ByToken(
          token_address,
          parseEther(token_amount.toString()),
          tc_amount.toString()
        );
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * buyKeys
   */
  public sellKeys = async ({
    token_address,
    token_amount,
  }: {
    token_address: string;
    token_amount: number;
  }) => {
    console.log('tokenAddress - sellKeys', token_address, token_amount);

    try {
      const akf = this.contract.getAlphaKeysFactoryContract();

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount,
      });

      await this.estimateTCGasFee({ type: ETypes.sell });

      const { amount } = this.balanceL2;
      console.log('-----amount---', amount);

      // const sellPriceAfterFee = await this.getSellPriceAfterFee({
      //   token_address,
      //   token_amount,
      // });

      const tx = await akf
        .connect(this.wallet)
        .sellKeysV2ByToken(
          token_address,
          parseEther(token_amount.toString()),
          0
        );

      await tx.wait();
      return tx;
    } catch (error) {
      console.log('sellkey error', error);

      throw error;
    }
  };

  public getTokenBalance = async (tokenAddress: string) => {
    try {
      const balance = await this.contract
        .getERC20Contract(tokenAddress)
        .connect(this.wallet)
        .balanceOf(this.wallet.address);

      return formatAmountToClient(balance.toString());
    } catch (e) {
      throw e;
    }
  };

  public getTokenERC20Info = async (tokenAddress: string) => {
    try {
      const [name, symbol, decimals, totalSupply] = await Promise.all([
        this.contract.getERC20Contract(tokenAddress).name(),
        this.contract.getERC20Contract(tokenAddress).symbol(),
        this.contract.getERC20Contract(tokenAddress).decimals(),
        this.contract.getERC20Contract(tokenAddress).totalSupply(),
      ]);

      return { name, symbol, decimals, totalSupply };
    } catch (e) {
      throw e;
    }
  };

  /**
   * buySweetFloorKeys
   */
  public buySweetFloorKeys = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      const bodyNB = body.filter(v =>
        compareString(v.key?.base_token_symbol, 'BTC')
      );
      const bodyFT = body.filter(v =>
        compareString(v.key?.base_token_symbol, BASE_SYMBOL)
      );
      const [{ alphaTokens: alphaNBTokens }, { alphaTokens: alphaFTTokens }] =
        await Promise.all([
          this.estimateTCMultiKeys(bodyNB),
          this.estimateFTMultiKeys(bodyFT),
        ]);

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount: 1,
      });

      const txs: IResponseBuySweetFloorKeys[] = [];

      const needFeeTC = typeToFee[ETypes.buy] * bodyNB.length * 1.1;

      await this.estimateTCGasFee({ type: ETypes.buy, needFeeTC });

      console.log('alphaNBTokens', alphaNBTokens, alphaFTTokens);

      for (let i = 0; i < alphaNBTokens.length; i++) {
        const v = alphaNBTokens[i];

        try {
          const _tx = await this.buyKeys({
            token_address: v.token_address,
            token_amount: v.token_amount,
            tc_amount: v.amount_tc.toString(),
            no_wait: true,
            is_multi: true,
          });

          txs.push({
            tx: _tx,
            token_address: v.token_address,
            token_amount: v.token_amount,
            tc_amount: v.amount_tc,
            side: v.side,
            amount_after_fee: 0,
          });
          postMessage(
            JSON.stringify({
              tx: _tx,
              token_address: v.token_address,
              token_amount: v.token_amount,
              tc_amount: v.amount_tc,
              side: v.side,
              amount_after_fee: 0,
            })
          );
        } catch (error) {
          postMessage(
            JSON.stringify({
              tx_hash: null,
              token_address: v.token_address,
              token_amount: v.token_amount,
              tc_amount: v.amount_tc,
              error: error,
            })
          );
        }
      }

      for (let i = 0; i < alphaFTTokens.length; i++) {
        const v = alphaFTTokens[i];

        txs.push({
          tx: null,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc,
          side: v.side,
          amount_after_fee: 0,
        });
        postMessage(
          JSON.stringify({
            tx: null,
            token_address: v.token_address,
            token_amount: v.token_amount,
            tc_amount: v.amount_tc,
            side: v.side,
            amount_after_fee: 0,
          })
        );
      }

      return txs;
    } catch (error: any) {
      throw error;
    }
  };

  public getBuyPriceAfterFeeForMultiCall = async ({
    token_address,
    token_amount,
    order_id,
  }: {
    token_address: string;
    token_amount: number;
    order_id?: string;
  }) => {
    try {
      const alphaTokenContract =
        this.contract.getAlphaKeysTokenContract(token_address);

      const amount_tc = await alphaTokenContract.getBuyPriceAfterFeeV2(
        parseEther(token_amount.toString())
      );

      return {
        alphaTokenContract,
        amount_tc,
        token_address,
        token_amount,
        order_id,
        side: SLIDE_ALPHA,
      };
    } catch (error) {
      throw error;
    }
  };

  /**
   * estimateTCMultiKeys
   */
  public estimateTCMultiKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<IEstimateTCMultiKeys> => {
    try {
      const promises = () =>
        body.map(v => this.getBuyPriceAfterFeeForMultiCall(v));

      const response = await Promise.all(promises());

      const total_tc = response.reduce(
        (p, c) => BigNumber.from(p).add(c.amount_tc).toString(),
        '0'
      );

      return {
        alphaTokens: response,
        total_tc,
        total_tc_formatted: formatAmountToClient(total_tc),
      };
    } catch (error) {
      return {
        alphaTokens: [],
        total_tc: '0',
        total_tc_formatted: '0',
      };
    }
  };

  /**
   * estimateTCMultiKeys
   */
  public estimateFTMultiKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<IEstimateTCMultiKeys> => {
    try {
      const ftPromises = () =>
        body.map(v =>
          this.getBuyPriceAfterFeeFTForSweep({
            ft_trader_address: v.token_address,
            token_amount: v.token_amount,
          })
        );

      const response = await Promise.all(ftPromises());

      const total_tc = response.reduce(
        (p, c) => BigNumber.from(p).add(c.amount_tc.toString()).toString(),
        '0'
      );

      return {
        alphaTokens: response,
        total_tc,
        total_tc_formatted: formatAmountToClient(total_tc),
      };
    } catch (error) {
      console.log('error', error);

      return {
        alphaTokens: [],
        total_tc: '0',
        total_tc_formatted: '0',
      };
    }
  };

  /**
   * getERC20Approve
   */
  public getBTCApprove = async ({
    spender_address,
    token_amount,
    need_approve = false,
  }: {
    spender_address: string;
    token_amount: number;
    need_approve: boolean;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .allowance(this.wallet.address, spender_address);

      if (
        BigNumber.from(response).lt(parseEther(token_amount.toString())) &&
        need_approve
      ) {
        const isBuy = await this.estimateTCGasFee({
          type: ETypes.approve,
        });
        if (!isBuy) {
          const txApprove = await this.contract
            .getERC20Contract(BTC_L2_ADDRESS)
            .connect(this.wallet)
            .approve(spender_address, ethers.constants.MaxUint256);
          await txApprove.wait();
          return ethers.constants.MaxUint256.toString();
        }
      }
      this.CURRENT_RETRY = 0;

      return response.toString();
    } catch (error) {
      if (this.CURRENT_RETRY < 3) {
        await sleep(2);
        await this.getBTCApprove({
          spender_address,
          token_amount,
          need_approve,
        });
        this.CURRENT_RETRY += 1;
      } else {
        this.CURRENT_RETRY = 0;
        throw error;
      }
    }
  };

  public getBTCAllowanceOfAddresss = async ({
    spender_address,
    address,
  }: {
    spender_address: string;
    address: string;
  }) => {
    try {
      const response = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .allowance(address, spender_address);

      return response.toString();
    } catch (error) {
      return 0;
    }
  };

  private getFaucet = async () => {
    try {
      const tx = await this.tradeAPI.faucetTC({
        address: this.wallet.address,
        network: 'nos',
      });
      console.log('getFaucettx', tx);

      if (tx) {
        await this.gameWalletProvider.gameWallet?.provider.waitForTransaction(
          tx as any
        );
      }
      return true;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.FAUCET_TC,
        address: this.wallet.address,
        error: JSON.stringify(error),
      });
      return true;
    } finally {
      store.dispatch(requestReload());
    }
  };

  public estimateTCGasFee = async ({
    type,
    needFeeTC,
  }: {
    type: ETypes;
    needFeeTC?: any;
  }) => {
    const _needFeeTC = ceil(needFeeTC || typeToFee[type]);
    console.log('_needFeeTC', _needFeeTC, typeToFee[type]);

    let isBuy = false;
    try {
      const [amount, amountBTC] = await Promise.all([
        this.gameWalletProvider.gameWallet?.provider.getBalance(
          this.gameWalletProvider.gameWallet.address
        ),
        this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .connect(this.wallet)
          .balanceOf(this.wallet.address),
      ]);

      /**
       * Faucet
       */

      console.log(
        'aaaa',
        amountBTC.toString(),
        BigNumber.from(amountBTC).gt('0')
      );

      if (
        BigNumber.from(amount?.toString()).lt(parseEther('0.0005')) &&
        BigNumber.from(amountBTC).gt('0')
      ) {
        const amountApprove = await this.contract
          .getERC20Contract(BTC_L2_ADDRESS)
          .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);

        if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
          await this.getFaucet();
          const txApprove = await this.contract
            .getERC20Contract(BTC_L2_ADDRESS)
            .connect(this.wallet)
            .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
          await txApprove.wait();
          isBuy = true;
        }
      }

      /**
       * End Faucet
       */
      const gasPrice = await this.contract.provider?.getGasPrice();
      const gasFee = new NBigNumber(_needFeeTC).multipliedBy(
        gasPrice?.toString() as string
      );
      console.log(
        'gasFee',
        gasFee.toString(),
        BigNumber.from(amount?.toString()).lt(gasFee.toString()),
        amount?.toString()
      );

      if (
        BigNumber.from(amount?.toString()).lt(parseEther('0.001')) ||
        BigNumber.from(amount?.toString()).lt(gasFee.toString())
      ) {
        await this.buyTCFee(this.MIN_BUY_TC);
        isBuy = true;
      }
    } catch (error) {
      console.log('error', error);

      isBuy = false;
      throw error;
    }
    return isBuy;
  };

  public getBTCPrice = async () => {
    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      return await akf.getBTCPrice();
    } catch (error) {
      return 0;
    }
  };

  public buyTCFee = async (need_amount_tc = '0.1') => {
    const [btcPrice, chainNetwork, userAmountBTC] = await Promise.all([
      this.getBTCPrice(),
      this.gameWalletProvider.gameWallet?.provider.getNetwork(),
      this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .connect(this.wallet)
        .balanceOf(this.wallet.address),
    ]);

    const amountApprove = await this.contract
      .getERC20Contract(BTC_L2_ADDRESS)
      .allowance(this.wallet.address, ALPHA_KEY_FACTORY_ADDRESS);
    if (BigNumber.from(amountApprove).lt(parseEther('1'))) {
      const txApprove = await this.contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .connect(this.wallet)
        .approve(ALPHA_KEY_FACTORY_ADDRESS, ethers.constants.MaxUint256);
      await txApprove.wait();
    }

    const estimateBTCAmount = parseEther(need_amount_tc)
      .mul(parseEther('1'))
      .div(btcPrice);

    let amountBTC = estimateBTCAmount;

    if (BigNumber.from(estimateBTCAmount).gt(userAmountBTC)) {
      amountBTC = BigNumber.from(userAmountBTC).gt(estimateBTCAmount)
        ? amountBTC
        : userAmountBTC;
    }

    try {
      console.log('need_amount_tc', need_amount_tc);

      const akf = this.contract.getAlphaKeysFactoryContract();
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));

      const chainId = chainNetwork?.chainId as number;

      const messagePack = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'uint256',
          'uint256',
          'address',
          'uint256',
          'uint256',
          'uint256',
        ],
        [
          akf.address,
          chainId,
          nonce,
          this.wallet.address,
          amountBTC,
          btcPrice,
          ethers.constants.MaxUint256,
        ]
      );

      const messageHash = ethers.utils.keccak256(
        ethers.utils.arrayify(messagePack)
      );
      const signature = await this.wallet.signMessage(
        ethers.utils.arrayify(messageHash)
      );

      const data_hex = akf.interface.encodeFunctionData('requestTC', [
        nonce,
        this.wallet.address,
        amountBTC,
        btcPrice,
        ethers.constants.MaxUint256,
        signature,
      ]);
      const result: any = await this.tradeAPI.sendTx({
        contract_address: akf.address,
        data_hex,
      });
      const r: any =
        await this.gameWalletProvider.gameWallet?.provider.getTransaction(
          result
        );
      await r.wait();
      await this.assetContext.fetchAssets();
      return;
    } catch (error) {
      errorLogger.report({
        action: errorLogger.ERROR_LOGGER_TYPE.BUY_TC,
        address: this.wallet.address,
        error: JSON.stringify(error),
        info: JSON.stringify({
          userAmountBTC: userAmountBTC.toString(),
          amountBTC: amountBTC.toString(),
        }),
      });
      await this.getFaucet();
      throw error;
    }
  };

  /**
   * buySweetFloorKeys
   */
  public requestTrade33Keys = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      console.log('body', body);

      const { total_tc, alphaTokens, total_tc_formatted } =
        await this.estimateTCMultiKeys(body);

      const txs: IResponseBuySweetFloorKeys[] = [];

      for (let i = 0; i < alphaTokens.length; i++) {
        const v = alphaTokens[i];

        const _tx = await this.request33Keys({
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc.toString(),
          no_wait: true,
        });

        txs.push({
          tx: _tx,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc,
          amount_after_fee: 0,
        });
      }
      return txs;
    } catch (error) {
      console.log('eeeee', error);
      throw error;
    }
  };

  /**
   * buyKeys
   */
  public request33Keys = async ({
    token_address,
    token_amount,
    tc_amount,
    no_wait = false,
  }: {
    token_address: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
  }) => {
    try {
      console.log(
        'tokenAddress -buyKeys',
        token_address,
        token_amount,
        tc_amount.toString()
      );

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount,
      });

      await this.estimateTCGasFee({ type: ETypes.buy });

      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeRequest(
          token_address,
          parseEther(token_amount.toString()),
          tc_amount.toString()
        );
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * getTokenBalanceFree
   */
  public getTokenBalanceFree = async (token_address: string) => {
    try {
      const akt = this.contract.getAlphaKeysTokenContract(token_address);
      const response = await akt
        .connect(this.wallet)
        .freeBalanceOf(this.wallet.address);
      return response;
    } catch (error) {
      return 0;
    }
  };

  /**
   * buySweetFloorKeys
   */
  public trade33Keys = async (body: IBodyBuySweetFloorKeys[]) => {
    try {
      const { total_tc, alphaTokens, total_tc_formatted } =
        await this.estimateTCMultiKeys(body);

      const txs: IResponseBuySweetFloorKeys[] = [];

      for (let i = 0; i < alphaTokens.length; i++) {
        const v = alphaTokens[i];

        const _tx = await this.executeTrade33Keys({
          order_id: v.order_id,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc.toString(),
          no_wait: false,
        });

        txs.push({
          tx: _tx,
          token_address: v.token_address,
          token_amount: v.token_amount,
          tc_amount: v.amount_tc,
          amount_after_fee: 0,
        });
      }
      return txs;
    } catch (error) {
      throw error;
    }
  };

  /**
   * buyKeys
   */
  public executeTrade33Keys = async ({
    order_id,
    token_amount,
    tc_amount,
    no_wait = false,
  }: {
    order_id: string;
    token_amount: number;
    tc_amount: string;
    no_wait?: boolean;
  }) => {
    try {
      console.log('tokenAddress -buyKeys', order_id, tc_amount.toString());

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        token_amount,
        need_approve: true,
      });
      await this.estimateTCGasFee({ type: ETypes.buy });
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeTrade(order_id, tc_amount.toString());
      if (!no_wait) {
        await tx.wait();
      }

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * cancel three three
   */

  public cancelTrade33Keys = async (order_id: string) => {
    try {
      await this.estimateTCGasFee({ type: ETypes.buy });
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeCancel(order_id);

      await tx.wait();

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * reject three three
   */

  public rejectTrade33Keys = async (order_id: string) => {
    try {
      const tx = await this.contract
        .getAlphaKeysFactoryContract()
        .connect(this.wallet)
        .threeThreeReject(order_id);

      await tx.wait();

      return tx;
    } catch (error) {
      console.log('error', error);

      throw error;
    }
  };

  /**
   * re request
   */
  public reRequestTrade33Keys = async (body: IBodyBuySweetFloorKeys) => {
    try {
      const v = await this.getBuyPriceAfterFeeForMultiCall(body);

      await this.getBTCApprove({
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
        token_amount: v.token_amount,
      });

      const alphaKeyFactory = this.contract.getAlphaKeysFactoryContract();

      const multicalls = [
        alphaKeyFactory.interface.encodeFunctionData(
          'threeThreeReject' as any,
          [body.order_id] as any
        ),
      ];

      multicalls.push(
        alphaKeyFactory.interface.encodeFunctionData('threeThreeRequest', [
          v.token_address,
          parseEther(v.token_amount.toString()),
          v.amount_tc.toString(),
        ])
      );

      const tx = await alphaKeyFactory
        .connect(this.wallet)
        .multicall(multicalls);
      await tx.wait();

      return tx;
    } catch (error) {
      console.log('reRequestTrade33Keys error', error);
      throw error;
    }
  };

  public checkAEnoughBalanceAndAllowance = async (body: IBodyCheckABalance) => {
    const amount = BigNumber.from(parseEther(body.amount));

    const allowance = await this.getBTCAllowanceOfAddresss({
      spender_address: ALPHA_KEY_FACTORY_ADDRESS,
      address: body.user_address,
    });

    const balance = await this.contract
      .getERC20Contract(BTC_L2_ADDRESS)
      .balanceOf(body.user_address);

    return (
      amount.lt(BigNumber.from(allowance)) && amount.lt(BigNumber.from(balance))
    );
  };

  /**
   * FillLimitOrder
   */
  public getSignatureFillLimitOrder = async (body: IBodyFillOrderLimit) => {
    try {
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));
      const amount = parseEther(body.amount.toString());
      const triggerPrice = parseEther(body.trigger_price.toString());
      const isBuy = body.is_buy || true;

      const akf = this.contract.getAlphaKeysFactoryContract();
      const akt = this.contract.getAlphaKeysTokenContract(body.token_address);

      const network =
        await this.gameWalletProvider.gameWallet?.provider.getNetwork();
      const chainId = network?.chainId;
      const messagePack = ethers.utils.defaultAbiCoder.encode(
        [
          'address',
          'uint256',
          'uint256',
          'address',
          'address',
          'bool',
          'uint256',
          'uint256',
        ],
        [
          akf.address,
          chainId,
          nonce,
          this.wallet.address,
          akt.address,
          isBuy,
          amount,
          triggerPrice,
        ]
      );

      const messageHash = ethers.utils.keccak256(
        ethers.utils.arrayify(messagePack)
      );
      const signature = await this.wallet.signMessage(
        ethers.utils.arrayify(messageHash)
      );
      return {
        signature,
        nonce: nonce.toString(),
      };
    } catch (error) {
      throw error;
    }
  };

  /**
   * createFillOrder
   */
  public createFillOrder = async (body: IBodyFillOrderLimitV2) => {
    try {
      const nonce = BigNumber.from(ethers.utils.randomBytes(32));
      const amount = parseEther(body.amount.toString());
      const supply = parseEther(Number(body.supply).toString());
      const triggerPrice = parseEther(body.trigger_price.toString());
      const isBuy = body.is_buy || true;

      const akf = this.contract.getAlphaKeysFactoryContract();
      const akt = this.contract.getAlphaKeysTokenContract(body.token_address);

      const [_creatorFee, _platformFee] = await Promise.all([
        this.getCreatorFee(body.token_address),
        this.getPlatformFee(body.token_address),
      ]);

      const buyPriceAfterFee = this.getBuyPriceAfterFeeV2(supply, amount, {
        _creatorFee,
        _platformFee,
      });

      await this.getBTCApprove({
        spender_address: akf.address,
        token_amount: parseFloat(body.amount.toString()),
        need_approve: true,
      });

      await this.estimateTCGasFee({ type: ETypes.buy });

      const tx = await akf
        .connect(this.wallet)
        .limitOrderCreate(
          nonce,
          akt.address,
          isBuy,
          amount,
          triggerPrice,
          buyPriceAfterFee
        );
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * CancelFillOrder
   */
  public cancelFillOrder = async ({ nonce }: { nonce: string }) => {
    try {
      const akf = this.contract.getAlphaKeysFactoryContract();
      const tx = await akf.connect(this.wallet).limitOrderCancel(nonce);
      await tx.wait();
      return tx;
    } catch (error) {
      throw error;
    }
  };

  public necessaryConnectETHWallet = async () => {
    try {
      const connectedAddress = this.web3UserAddress;
      const isConnected = this.web3React.isActive;
      const chainID = this.web3ChainID;

      if (
        !isConnected ||
        !connectedAddress ||
        !compareString(chainID, Number(BASE_CHAIN_ID))
      ) {
        await this.gameWalletProvider.onConnect(BASE_CHAIN_ID as any);

        this.web3Provider = this.web3React.provider as any;
      }

      return true;
    } catch (error) {
      console.log('error', error);
    }
  };

  public getETHBalance = async () => {
    try {
      const chainID = this.web3ChainID;
      if (!compareString(chainID, Number(BASE_CHAIN_ID))) {
        return '0';
      }
      // await this.necessaryConnectETHWallet();

      if (this.web3UserAddress) {
        const response = await this.metamaskWallet.getBalance(
          this.web3UserAddress
        );

        return response.data;
      }
      return '0';
    } catch (error) {
      return '0';
    }
  };

  public FTBuyShares = async ({
    ft_trader_address,
    token_amount,
    tc_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
    tc_amount: string;
  }) => {
    try {
      await this.necessaryConnectETHWallet();
      const response = await this.contract
        .getFTContractFactory()
        .connect(this.etherWeb3Provider.getSigner(0))
        .buyShares(ft_trader_address, token_amount, {
          value: tc_amount.toString(),
        });

      return response;
    } catch (error) {
      throw error;
    }
  };

  public FTSellShares = async ({
    ft_trader_address,
    token_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
  }) => {
    try {
      await this.necessaryConnectETHWallet();

      console.log('provider', this.web3Provider, this.web3React.provider);

      const response = await this.contract
        .getFTContractFactory(this.web3React.provider)
        .connect(this.web3React.provider?.getSigner(0) as any)
        .sellShares(ft_trader_address, token_amount);

      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getSellPriceAfterFee
   */
  public getSellPriceAfterFeeFT = async ({
    ft_trader_address,
    token_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getFTContractFactory(window.publicProvider)
        .getSellPriceAfterFee(ft_trader_address, token_amount.toString());
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getSellPriceFT
   */
  public getSellPriceFT = async ({
    ft_trader_address,
    token_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getFTContractFactory(window.publicProvider)
        .getSellPrice(ft_trader_address, token_amount.toString());
      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceAfterFeeFT
   */
  public getBuyPriceAfterFeeFT = async ({
    ft_trader_address,
    token_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getFTContractFactory(window.publicProvider)
        .getBuyPriceAfterFee(ft_trader_address, token_amount.toString());

      return tx;
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceAfterFeeFT
   */
  public getBuyPriceAfterFeeFTForSweep = async ({
    ft_trader_address,
    token_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getFTContractFactory(window.publicProvider)
        .getBuyPriceAfterFee(ft_trader_address, token_amount.toString());

      return {
        token_address: ft_trader_address,
        token_amount,
        amount_tc: tx,
        side: SLIDE_FT,
      };
    } catch (error) {
      throw error;
    }
  };

  /**
   * getBuyPriceFT
   */
  public getBuyPriceFT = async ({
    ft_trader_address,
    token_amount,
  }: {
    ft_trader_address: string;
    token_amount: number;
  }) => {
    try {
      const tx = await this.contract
        .getFTContractFactory(window.publicProvider)
        .getBuyPrice(ft_trader_address, token_amount.toString());

      return tx;
    } catch (error) {
      throw error;
    }
  };

  public getBalanceFT = async (
    ft_token_address: string,
    ft_trader_address: string
  ) => {
    try {
      const tx = await this.contract
        .getFTContractFactory(window.publicProvider)
        .sharesBalance(ft_token_address, ft_trader_address);
      return tx;
    } catch (error) {
      return 0;
    }
  };
}

export default CPlayerShare;
