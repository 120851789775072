import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { Button, Flex } from '@chakra-ui/react';
import IcTwitter from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icTwitter';
import { useContext, useEffect, useState } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import { getLink } from '@/utils/helpers';
import { getReferralCode } from '@/services/referral';
import s from './styles.module.scss';
import { User } from '@/interfaces/api/post';
import { compareString, formatCurrency } from '@/utils';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';

const BtnShareTw = ({
  wheelCreator,
  isDisabled,
  wheelDetail,
  users,
}: {
  wheelCreator: any;
  isDisabled?: boolean;
  wheelDetail?: any;
  users: Array<any>;
}) => {
  const { addressL2 } = useContext(WalletContext);
  const [referralCode, setReferralCode] = useState();
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    getReferralCode()
      .then(code => {
        setReferralCode(code);
      })
      .finally(() => {});
  }, []);

  return (
    <Button
      isDisabled={isDisabled}
      maxW={'200px'}
      className={s.btnSendInvitePlaceHolder}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        let content: string = '';
        if (
          compareString(
            addressL2,
            wheelCreator?.address || wheelCreator?.Address
          )
        ) {
          content = `It's so addictive folks!\n\nI've just sponsored a lucky wheel for some community fun at @NewBitcoinCity.\n\n${formatCurrency(
            users.length,
            0,
            0
          )} played and @${wheelDetail?.PrizeWheelSpend?.ToTwitter
            ?.TwitterUsername} won ${formatCurrency(
            wheelDetail?.PrizeWheelSpend?.Amount
          )} @${wheelDetail?.PrizeWheelSpend?.Token?.TwitterUser
            ?.TwitterUsername} key${labelAmountOrNumberAdds(
            wheelDetail?.PrizeWheelSpend?.Amount
          )}.\n\nDon't miss out on the chance to join via my referral link and test your luck:\n\n`;
        } else {
          if (!wheelDetail?.PrizeWheelSpend) {
            content = `@${
              wheelCreator?.twitter_username || wheelCreator.TwitterUsername
            } created a Lucky Wheel at @NewBitcoinCity with a prize of ${formatCurrency(
              wheelDetail?.Amount,
              0,
              4
            )} BTC.\n\n${formatCurrency(
              users.length,
              0,
              0
            )} people are playing.\n\n#TheWheel\n\n`;
          } else {
            if (
              compareString(
                addressL2,
                wheelDetail?.PrizeWheelSpend?.ToTwitter?.Address
              )
            ) {
              content = `@${
                wheelCreator?.twitter_username || wheelCreator.TwitterUsername
              } has created a lucky wheel on @NewBitcoinCity.\n\n${formatCurrency(
                users.length,
                0,
                0
              )} participants and I'm the lucky one who won ${formatCurrency(
                wheelDetail?.PrizeWheelSpend?.Amount
              )} @${wheelDetail?.PrizeWheelSpend?.Token?.TwitterUser
                ?.TwitterUsername} key${labelAmountOrNumberAdds(
                wheelDetail?.PrizeWheelSpend?.Amount
              )}!\n\nUse my referral link to join for a chance at the next win!\n\n`;
            } else {
              content = `@${
                wheelCreator?.twitter_username || wheelCreator.TwitterUsername
              } created a lucky wheel at @NewBitcoinCity.\n\n${formatCurrency(
                users.length,
                0,
                0
              )} played and @${
                wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterUsername ||
                wheelCreator.TwitterUsername
              } won ${formatCurrency(wheelDetail?.PrizeWheelSpend?.Amount)} @${
                wheelDetail?.PrizeWheelSpend?.Token?.TwitterUser
                  ?.TwitterUsername || wheelCreator.TwitterUsername
              } key${labelAmountOrNumberAdds(
                wheelDetail?.PrizeWheelSpend?.Amount
              )}.\n\nWish me luck next time!\n\n`;
            }
          }
        }
        const shareUrl = getLink(referralCode || '');

        gaEventTracker(
          GameFiActions.TweetTabPlaceHolderReferralCode,
          addressL2
        );
        window.open(
          `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
            content
          )}`,
          '_blank'
        );
      }}
    >
      <Flex alignItems={'center'} gap={2} justifyContent={'space-between'}>
        Share on <IcTwitter />
      </Flex>
    </Button>
  );
};

export default BtnShareTw;
