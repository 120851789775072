const IconUnStake = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66406 0.762091C5.57422 0.609747 6.49219 0.687872 7.34375 0.969122L9.28906 3.99647L6.34375 6.94178C6.28516 7.00037 6.25 7.0824 6.25391 7.16834C6.25781 7.25428 6.29297 7.3324 6.35547 7.391L10.7305 11.4535C10.8438 11.559 11.0195 11.5668 11.1406 11.4652C11.2617 11.3637 11.2891 11.1918 11.207 11.059L8.84766 7.22693L12.3906 4.27381C12.4922 4.19178 12.5273 4.05115 12.4844 3.93006L11.5938 1.4535C12.707 0.801153 14.0312 0.547247 15.332 0.765997C18.0273 1.21131 20 3.53943 20 6.2699V6.49647C20 8.11756 19.3281 9.66834 18.1406 10.7738L11.082 17.3637C10.7891 17.6371 10.4023 17.7894 10 17.7894C9.59766 17.7894 9.21094 17.6371 8.91797 17.3637L1.85938 10.7738C0.671875 9.66834 0 8.11756 0 6.49647V6.2699C0 3.53943 1.97266 1.21131 4.66406 0.762091Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};

export default IconUnStake;
