import React from 'react';

const IcListView = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.26667 5H18M7.26667 10.4H18M7.26667 15.8H18M3 5V5.01067M3 10.4V10.4107M3 15.8V15.8107" stroke="#5B5B5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default IcListView;
