import React, { useContext, useEffect, useMemo, useState } from 'react';

import s from './styles.module.scss';
import { Button, Flex } from '@chakra-ui/react';
import { IcTwitter } from '@/modules/AlphaPWA/Notification/svg/ic-twitter';
import { WalletContext } from '@/contexts/wallet-context';
import { getReferralCodeByTwId } from '@/services/referral';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { getLink } from '@/utils/helpers';
import { User } from '@/interfaces/api/post';
import { formatCurrency } from '@/utils';
import BigNumber from 'bignumber.js';
import { shareTwitterWheel } from '@/services/prize-wheel';

export const SHARE_TWITTER_AFTER_JOIN_MODAL: string =
  'SHARE_TWITTER_AFTER_JOIN_MODAL';

interface IProps {
  onClose: () => void;
  wheelCreator: any;
  totalReward?: any;
  users: Array<any>;
}

const ShareTwitterAfterJoinModal: React.FC<IProps> = ({
  onClose,
  wheelCreator,
  totalReward,
  users,
}): React.ReactElement => {
  const { addressL2, btc2Usd } = useContext(WalletContext);

  const usdValue = useMemo(() => {
    if (!btc2Usd || !totalReward) return '-';
    const priceBn = new BigNumber(totalReward);
    return `$${formatCurrency(priceBn.times(btc2Usd), 0, 2)}`;
  }, [btc2Usd, totalReward]);

  const btcValue = useMemo(() => {
    if (!totalReward) return '-';
    return `${totalReward} BTC`;
  }, [totalReward]);

  const [referralCode, setReferralCode] = useState();
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    if (wheelCreator?.twitter_id || wheelCreator?.TwitterId) {
      getReferralCodeByTwId({
        twitter_id: wheelCreator?.twitter_id || wheelCreator?.TwitterId,
      })
        .then(code => {
          setReferralCode(code);
        })
        .finally(() => {});
    }
  }, [wheelCreator]);

  return (
    <div className={s.shareTwitterAfterJoinModal}>
      <h2 className={s.title}>Share on X to win the lucky wheel</h2>
      <p className={s.description}>
        Increase your odds of winning the wheel by sharing on X with your
        friends! The more you share and engage, the higher your chances of
        securing that victory.
      </p>

      <div className={s.actions}>
        <Button
          className={s.btnSendInvitePlaceHolder}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();
            let content: string = '';

            content = `@${
              wheelCreator?.twitter_username || wheelCreator?.TwitterUsername
            } created a Lucky Wheel at @NewBitcoinCity with a prize of ${btcValue}\n\n${
              users.length || 1
            } people are playing\n\n#TheWheel\n\n`;

            const shareUrl = getLink(referralCode || '');

            gaEventTracker(
              GameFiActions.ShareTwitterAfterJoinPublicWheel,
              addressL2
            );
            window.open(
              `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
                content
              )}`,
              '_blank'
            );
            // call api after user share
            if (!addressL2) return;
            const res = await shareTwitterWheel({ address: addressL2 });
            if (res) {
              onClose();
            }
          }}
        >
          <Flex alignItems={'center'} gap={2} justifyContent={'space-between'}>
            Share on{' '}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="8" fill="black" />
              <path
                d="M10.875 3.46875H12.4087L9.05875 7.3075L13 12.5313H9.91437L7.4975 9.3625L4.73187 12.5313H3.1975L6.78062 8.425L3 3.46875H6.16438L8.34875 6.36438L10.875 3.46875ZM10.3375 11.6113H11.1875L5.70187 4.34063H4.79062L10.3375 11.6113Z"
                fill="white"
              />
            </svg>
          </Flex>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ShareTwitterAfterJoinModal);
