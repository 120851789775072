import React from 'react';

const IcMenuMinKey = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 6.91091C18.3333 9.80758 15.975 12.1559 13.0666 12.1559C12.5366 12.1559 11.3283 12.0342 10.7408 11.5459L10.0058 12.2776C9.5733 12.7084 9.68996 12.8351 9.88246 13.0434C9.96246 13.1309 10.0558 13.2317 10.1283 13.3759C10.1283 13.3759 10.7408 14.2292 10.1283 15.0834C9.7608 15.5709 8.73163 16.2534 7.55663 15.0834L7.31163 15.3267C7.31163 15.3267 8.0458 16.1809 7.43413 17.0351C7.06663 17.5226 6.08663 18.0101 5.22913 17.1567L4.37246 18.0101C3.78413 18.5959 3.0658 18.2542 2.7808 18.0101L2.04496 17.2784C1.35913 16.5951 1.75913 15.8551 2.04496 15.5701L8.4133 9.22842C8.4133 9.22842 7.8008 8.25341 7.8008 6.91175C7.8008 4.01508 10.1591 1.66675 13.0675 1.66675C15.9758 1.66675 18.3333 4.01508 18.3333 6.91091ZM13.0675 8.74091C13.5535 8.7418 14.0201 8.54961 14.3645 8.2066C14.7089 7.86358 14.903 7.39783 14.9041 6.91175C14.9037 6.67099 14.8558 6.43268 14.7633 6.21041C14.6708 5.98815 14.5354 5.78629 14.3648 5.61636C14.1943 5.44643 13.9919 5.31175 13.7693 5.22003C13.5467 5.1283 13.3082 5.08131 13.0675 5.08175C12.8267 5.08131 12.5882 5.1283 12.3656 5.22003C12.143 5.31175 11.9407 5.44643 11.7701 5.61636C11.5996 5.78629 11.4642 5.98815 11.3716 6.21041C11.2791 6.43268 11.2312 6.67099 11.2308 6.91175C11.2319 7.39783 11.426 7.86358 11.7704 8.2066C12.1148 8.54961 12.5814 8.7418 13.0675 8.74091Z"
        fill="black"
      />
    </svg>
  );
};

export default IcMenuMinKey;
