import React, { useContext } from 'react';
import s from './styles.module.scss';
import { CDN_URL_ICONS } from '@/configs';
import Link from 'next/link';
import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';

const Empty: React.FC = (): React.ReactElement => {
  const { isAuthenticated, onSyncProfile } = useContext(WalletContext);

  return (
    <div className={s.empty}>
      <div className={s.iconWrapper}>
        <img src={`${CDN_URL_ICONS}/keyCircle.svg`} alt="icon" />
      </div>
      <p className={s.description}>
        Fren, you are not getting any alpha. <br />
        Hold at least one key to be part of an alpha group.
      </p>
      <div className={s.actionWrapper}>
        <Link
          className={s.link}
          href={isAuthenticated ? ROUTE_PATH.ALPHA_MOBILE_KEYS : ''}
          onClick={isAuthenticated ? undefined : onSyncProfile}
        >
          Buy a key
        </Link>
      </div>
    </div>
  );
};

export default Empty;
