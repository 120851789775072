export const IcSwap = ()=>{
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="-2.49989062%" y1="50.0008593%" x2="102.499891%" y2="50.0008593%" id="linearGradient-_2zwzngpm4-1">
          <stop stop-color="#00D9F5" offset="0%"></stop>
          <stop stop-color="#A0FFF9" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-2.49989062%" y1="48.5952051%" x2="102.499891%" y2="48.5952051%" id="linearGradient-_2zwzngpm4-2">
          <stop stop-color="#00D9F5" offset="0%"></stop>
          <stop stop-color="#A0FFF9" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-4.54545455%" y1="50%" x2="104.545455%" y2="50%" id="linearGradient-_2zwzngpm4-3">
          <stop stop-color="#00D9F5" offset="0%"></stop>
          <stop stop-color="#A0FFF9" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-4.54545455%" y1="50%" x2="104.545455%" y2="50%" id="linearGradient-_2zwzngpm4-4">
          <stop stop-color="#00F5A0" offset="0%"></stop>
          <stop stop-color="#00D9F5" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="ic-swap">
          <rect id="Rectangle" fill="#000000" fill-rule="nonzero" x="0" y="0" width="24" height="24" rx="12"></rect>
          <g id="Group">
            <rect id="Rectangle" fill="#000000" fill-rule="nonzero" x="0" y="0" width="24" height="24" rx="12"></rect>
            <path d="M7.4486,14.4856 L6.9609,14.9734 L7.6507,14.9734 L17.8851,14.9734 C17.9154,14.9734 17.9445,14.9854 17.9659,15.0069 C17.9873,15.0283 17.9994,15.0574 17.9994,15.0877 C17.9994,15.118 17.9873,15.147 17.9659,15.1685 C17.9445,15.1899 17.9154,15.2019 17.8851,15.2019 L7.6507,15.2019 L6.9609,15.2019 L7.4486,15.6897 L9.5636,17.8046 C9.5836,17.826 9.5947,17.8544 9.5944,17.8837 C9.5942,17.9137 9.5822,17.9424 9.561,17.9636 C9.5398,17.9848 9.5111,17.9968 9.4811,17.9970059 C9.4518,17.9973 9.4234,17.9862 9.402,17.9661 L6.6043,15.1685 C6.5829,15.147 6.5709,15.118 6.5709,15.0877 C6.5709,15.0574 6.5829,15.0283 6.6043,15.0069 L9.402,12.2092 C9.4234,12.1891 9.4518,12.178 9.4811,12.1782941 C9.5111,12.1786 9.5398,12.1906 9.561,12.2118 C9.5822,12.233 9.5942,12.2616 9.5944,12.2916 C9.5947,12.321 9.5836,12.3493 9.5635,12.3707 L7.4486,14.4856 Z" id="Path" stroke="url(#linearGradient-_2zwzngpm4-1)" stroke-width="0.571429" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M16.9196,9.3733 L17.6093,9.3733 L17.1216,8.8855 L15.0067,6.7706 C14.9866,6.7492 14.9755,6.7209 14.9758,6.6915 C14.9761,6.6615 14.9881,6.6329 15.0093,6.6117 C15.0304,6.5905 15.0591,6.5785 15.0891,6.57819407 C15.1185,6.5779 15.1468,6.589 15.1682,6.6091 L17.9659,9.4068 C17.9659,9.4068 17.9659,9.4068 17.9659,9.4068 C17.9873,9.4282 17.9994,9.4573 17.9994,9.4876 C17.9994,9.5179 17.9873,9.5469 17.9659,9.5683 C17.9659,9.5683 17.9659,9.5684 17.9659,9.5684 L15.1659,12.3683 L15.1659,12.3683 L15.1624,12.3719 C15.1519,12.3828 15.1393,12.3915 15.1253,12.3975 C15.1114,12.4035 15.0964,12.4066 15.0812,12.4068 C15.0661,12.4069 15.051,12.404 15.037,12.3983 C15.0229,12.3925 15.0102,12.384 14.9994,12.3733 L14.7974,12.5753 L14.9994,12.3733 C14.9887,12.3626 14.9802,12.3498 14.9745,12.3358 L14.71,12.4439 L14.9745,12.3358 C14.9687,12.3217 14.9658,12.3067 14.966,12.2915 C14.9661,12.2763 14.9692,12.2613 14.9752,12.2474 C14.9812,12.2334 14.9899,12.2208 15.0008,12.2103 L15.0009,12.2103 L15.0044,12.2068 L17.1216,10.0896 L17.6093,9.6019 L16.9196,9.6019 L6.6852,9.6019 C6.6548,9.6019 6.6258,9.5898 6.6043,9.5684 C6.5829,9.5469 6.5709,9.5179 6.5709,9.4876 C6.5709,9.4573 6.5829,9.4282 6.6043,9.4068 C6.6258,9.3853 6.6548,9.3733 6.6852,9.3733 L16.9196,9.3733 Z" id="Path" stroke="url(#linearGradient-_2zwzngpm4-2)" stroke-width="0.571429" fill="#FFFFFF" fill-rule="nonzero"></path>
            <rect id="Rectangle" stroke="url(#linearGradient-_2zwzngpm4-3)" x="1" y="1" width="22" height="22" rx="11"></rect>
          </g>
          <rect id="Rectangle" stroke="url(#linearGradient-_2zwzngpm4-4)" x="1" y="1" width="22" height="22" rx="11"></rect>
        </g>
      </g>
    </svg>
  )
}
