import { IcTwitter } from '@/modules/AlphaPWA/Notification/svg/ic-twitter';
import s from './styles.module.scss';
import { kFormatter } from '@/utils/format';

export const Follower = ({ followNumber }: { followNumber: number }) => {
  return (
    <div className={s.follower}>
      <IcTwitter /> <strong>{kFormatter(followNumber)}</strong>
    </div>
  );
};
