import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import Persistor from './Persistor';
import moment from 'moment';
import {
  getListPlayerPoolProfile,
  getPlayerPoolProfile,
} from '@/services/player-share';

const COLLECTION = 'PoolProfile';
const DATABASE_NAME = 'PoolProfile';

// 1 minutes
const REVALIDATE_TIME = [1, 'minutes'];
const BATCH_TIMEOUT = 10000; //10s

const KEY_PATH = 'stored_id';

type StoreProfileType = IGetPlayerPoolProfile & {
  writtenAt: number;
};

class PoolProfilePersistor extends Persistor {
  private timeOutManager: Record<string, ReturnType<typeof setTimeout>> = {};
  private batchAddressToRevalidate: string[] = [];
  private isStorageds: Record<string, boolean> = {};

  constructor() {
    super(DATABASE_NAME, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreProfileType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION);
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private clearTimeoutByAddress(address: string) {
    if (this.timeOutManager[address]) {
      this.batchAddressToRevalidate = this.batchAddressToRevalidate.filter(
        addr => `${addr}`.toLowerCase() !== `${address}`.toLowerCase()
      );
      clearTimeout(this.timeOutManager[address]);
    }
  }

  private retryUpdateNewData(address: string) {
    this.batchAddressToRevalidate = [...this.batchAddressToRevalidate, address];
    this.timeOutManager[address] = setTimeout(() => {
      // one of any timeout fire will be call by list api and cleanup all timeout instances

      const uniqueAddress = Array.from(new Set(this.batchAddressToRevalidate));
      if (uniqueAddress.length) {
        getListPlayerPoolProfile(uniqueAddress).then(players => {
          players
            .filter(p => !!p)
            .forEach(player => {
              player &&
                this.upsertItem(`${player?.address}`.toLowerCase(), player);
            });
        });
      }

      this.batchAddressToRevalidate = [];
      Object.values(this.timeOutManager[address]).forEach(timeout => {
        if (timeout) {
          clearTimeout(timeout);
        }
      });
    }, BATCH_TIMEOUT);
  }

  public getItem = async (
    address: string
  ): Promise<IGetPlayerPoolProfile | null> => {
    if (this.isStorageds[`${address}`.toLowerCase()] === false) {
      return new Promise(resolve => {
        if (this.isStorageds[`${address}`.toLowerCase()] === undefined) {
          resolve(this.getItem(address));
        } else {
          setTimeout(() => {
            resolve(this.getItem(address));
          }, 50);
        }
      });
    } else if (this.isStorageds[`${address}`.toLowerCase()] === undefined) {
      this.isStorageds[`${address}`.toLowerCase()] = false;
    }
    try {
      this.clearTimeoutByAddress(address);
      const record = await this.getRecord(address);
      if (record) {
        this.isStorageds[`${address}`.toLowerCase()] = true;
        if (
          record.writtenAt &&
          moment(record.writtenAt)
            .add(...REVALIDATE_TIME)
            .isAfter(moment())
        ) {
          return record;
        }

        this.retryUpdateNewData(address);
        return record;
      }
      const player = await getPlayerPoolProfile(address as string);
      this.upsertItem(address, player);
      return player;
    } catch (e) {
      delete this.isStorageds[`${address}`.toLowerCase()];
      const player = await getPlayerPoolProfile(address as string);
      this.upsertItem(address, player);
      return player;
    }
  };

  private addItem = async (
    address: string,
    item: IGetPlayerPoolProfile
  ): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private updateItem = async (
    address: string,
    item: IGetPlayerPoolProfile
  ): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);

        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private upsertItem = async (
    address: string,
    item: IGetPlayerPoolProfile
  ): Promise<void> => {
    try {
      const record = await this.getRecord(address);
      if (record) {
        this.updateItem(address, item);
      } else {
        this.addItem(address, item);
      }
    } catch (e) {
      //
    } finally {
      //
      this.isStorageds[`${address}`.toLowerCase()] = true;
    }
  };
}

const persistor = new PoolProfilePersistor();

export default persistor;
