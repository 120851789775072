import {
  ChatGroupResponse,
  ChatMessageResponse,
  ChatPeerToPeerResponse,
  ChatRoomResponse,
  LanguageBackEndEnum,
  LanguageSupportedEnum,
  MessageTypeEnum,
  ChatTribeResponse,
} from '@/interfaces/api/chat';

const DEFAULT_SETTING = { allowFtJoin: false };

export const unifyChatRoom = (
  data: ChatPeerToPeerResponse
): ChatRoomResponse => {
  const memberCount = 2;
  return {
    ...data,
    owner: data.to || '',
    members: [],
    isTribe: false,
    type: 'CHAT_ROOM',
    mute: false,
    memberCount, // chat DM always have 2 members
    isCalling: false,
    setting: DEFAULT_SETTING,
    language: DEFAULT_LANGUAGE,
    lastMessage: data.lastMessage
      ? formatMessageResponse(data.lastMessage)
      : undefined,
  };
};

export const unifyChatGroup = (data: ChatGroupResponse): ChatRoomResponse => {
  const members = data.members || [];
  const memberCount = data.memberCount || members.length;

  return {
    ...data,
    owner: data.owner || '',
    members,
    type: 'CHAT_GROUP',
    mute: data.mute || false,
    memberCount,
    setting: data.setting || DEFAULT_SETTING,
    isCalling: data.isCalling,
    language: data.language || DEFAULT_LANGUAGE,
    lastMessage: data.lastMessage
      ? formatMessageResponse(data.lastMessage)
      : undefined,
    jamSessionId: data?.jamSessionId,
    jamChannelName: data?.jamChannelName,
  };
};

export const unifyChatTribe = (data: ChatTribeResponse): ChatRoomResponse => {
  const members = data.members || [];
  const memberCount = data.memberCount || members.length;

  return {
    ...data,
    owner: data.owner || '',
    members,
    type: 'CHAT_TRIBE',
    kind: data.type,
    mute: data.mute || false,
    memberCount,
    setting: data.setting || DEFAULT_SETTING,
    isCalling: data.isCalling,
    language: data.language || DEFAULT_LANGUAGE,
    lastMessage: data.lastMessage
      ? formatMessageResponse(data.lastMessage)
      : undefined,
    jamSessionId: data?.jamSessionId,
    jamChannelName: data?.jamChannelName,
  };
};

export const formatMessageResponse = <T extends ChatMessageResponse>(
  message: T
): T => {
  if (message) {
    return {
      ...message,
      type: message.type | MessageTypeEnum.NORMAL,
      content: message.content,
      mentions: (message.mentions || []).map(item => `${item}`.toLowerCase()),
      // contentEn: 'this message english',
      // contentCn: 'this message chinese 1',
      // contentTw: 'this message chinese 2',
      // contentJp: 'this message japanese',
    };
  }
  return message;
};

export const formatMessagesResponse = (data: ChatMessageResponse[]) => {
  return data.map(item => formatMessageResponse(item));
};

export const DEFAULT_LANGUAGE = LanguageSupportedEnum.OFF;

export const MAPPING_UI_2_DB = {
  [LanguageSupportedEnum.OFF]: LanguageBackEndEnum.OFF,
  [LanguageSupportedEnum.ENGLISH]: LanguageBackEndEnum.ENGLISH,
  [LanguageSupportedEnum.CHINESE_SIMPLIFIED]:
    LanguageBackEndEnum.CHINESE_SIMPLIFIED,
  [LanguageSupportedEnum.CHINESE_TRADITIONAL]:
    LanguageBackEndEnum.CHINESE_TRADITIONAL,
  [LanguageSupportedEnum.JAPANESE]: LanguageBackEndEnum.JAPANESE,
};

export const MAPPING_DB_2_UI = {
  [LanguageBackEndEnum.OFF]: LanguageSupportedEnum.OFF,
  [LanguageBackEndEnum.ENGLISH]: LanguageSupportedEnum.ENGLISH,
  [LanguageBackEndEnum.CHINESE_SIMPLIFIED]:
    LanguageSupportedEnum.CHINESE_SIMPLIFIED,
  [LanguageBackEndEnum.CHINESE_TRADITIONAL]:
    LanguageSupportedEnum.CHINESE_TRADITIONAL,
  [LanguageBackEndEnum.JAPANESE]: LanguageSupportedEnum.JAPANESE,
};
