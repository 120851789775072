import React from 'react';

const IcFire = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17724_13692)">
        <path
          d="M14.8779 7.20618L12.7945 5.12285C12.632 4.96035 12.3679 4.96035 12.2054 5.12285L0.955371 16.3729C0.792871 16.5354 0.792871 16.7995 0.955371 16.962L3.0387 19.0454C3.2012 19.2079 3.46495 19.2079 3.62787 19.0454L14.8779 7.79535C15.0404 7.63285 15.0404 7.36868 14.8779 7.20618ZM12.0833 9.4116L10.5891 7.91743L12.5 6.0066L13.9941 7.50077L12.0833 9.4116Z"
          fill="#65FFBE"
        />
        <path
          d="M18.7498 13.3333C17.8294 13.3333 17.0832 12.5871 17.0832 11.6667C17.0832 11.4363 16.8969 11.25 16.6665 11.25C16.4361 11.25 16.2498 11.4363 16.2498 11.6667C16.2498 12.5871 15.5036 13.3333 14.5832 13.3333C14.3528 13.3333 14.1665 13.5196 14.1665 13.75C14.1665 13.9804 14.3528 14.1667 14.5832 14.1667C15.5036 14.1667 16.2498 14.9129 16.2498 15.8333C16.2498 16.0637 16.4361 16.25 16.6665 16.25C16.8969 16.25 17.0832 16.0637 17.0832 15.8333C17.0832 14.9129 17.8294 14.1667 18.7498 14.1667C18.9803 14.1667 19.1665 13.9804 19.1665 13.75C19.1665 13.5196 18.9803 13.3333 18.7498 13.3333Z"
          fill="#65FFBE"
        />
        <path
          d="M19.1666 2.50033C18.2462 2.50033 17.4999 1.75408 17.4999 0.833659C17.4999 0.603242 17.3137 0.416992 17.0833 0.416992C16.8528 0.416992 16.6666 0.603242 16.6666 0.833659C16.6666 1.75408 15.9203 2.50033 14.9999 2.50033C14.7695 2.50033 14.5833 2.68658 14.5833 2.91699C14.5833 3.14741 14.7695 3.33366 14.9999 3.33366C15.9203 3.33366 16.6666 4.07991 16.6666 5.00033C16.6666 5.23074 16.8528 5.41699 17.0833 5.41699C17.3137 5.41699 17.4999 5.23074 17.4999 5.00033C17.4999 4.07991 18.2462 3.33366 19.1666 3.33366C19.397 3.33366 19.5833 3.14741 19.5833 2.91699C19.5833 2.68658 19.397 2.50033 19.1666 2.50033Z"
          fill="#65FFBE"
        />
        <path
          d="M8.33333 2.91732C7.41292 2.91732 6.66667 2.17107 6.66667 1.25065C6.66667 1.02023 6.48042 0.833984 6.25 0.833984C6.01958 0.833984 5.83333 1.02023 5.83333 1.25065C5.83333 2.17107 5.08708 2.91732 4.16667 2.91732C3.93625 2.91732 3.75 3.10357 3.75 3.33398C3.75 3.5644 3.93625 3.75065 4.16667 3.75065C5.08708 3.75065 5.83333 4.4969 5.83333 5.41732C5.83333 5.64773 6.01958 5.83398 6.25 5.83398C6.48042 5.83398 6.66667 5.64773 6.66667 5.41732C6.66667 4.4969 7.41292 3.75065 8.33333 3.75065C8.56375 3.75065 8.75 3.5644 8.75 3.33398C8.75 3.10357 8.56375 2.91732 8.33333 2.91732Z"
          fill="#65FFBE"
        />
      </g>
      <defs>
        <clipPath id="clip0_17724_13692">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcFire;
