import React, { useContext } from 'react';
import s from './styles.module.scss';
import { reportPost } from '@/services/alpha_tweet';
import { WalletContext } from '@/contexts/wallet-context';
import toast from 'react-hot-toast';
import { IconReport } from '../Icon/IconReport';

interface IProps {
  postId: string;
  twitterId: string;
  onClose?: () => void;
}

const ReportButton = (props: IProps) => {
  const { addressL2 } = useContext(WalletContext);
  const { twitterId, postId, onClose } = props;

  const handleReportPost = async () => {
    if (!addressL2) return;
    try {
      const res = await reportPost({
        tweetId: postId,
        twitterId: twitterId,
        network: 'nos',
        address: addressL2,
      });

      if (res) {
        onClose && onClose();
      }
    } catch (error) {
      console.log('error ', error);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return (
    <div
      className={s.pinPostBtn}
      onClick={e => {
        e.stopPropagation();
        handleReportPost();
      }}
    >
      <span className={s.text}>
        <IconReport />
        Report this post
      </span>
    </div>
  );
};

export default ReportButton;
