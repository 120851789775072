import React from 'react';

import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { requestReload } from '@/state/common';
import { formatFeePercent, getErrorMessageContract } from '@/utils/helpers';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
  showError,
  showSuccess,
} from '../../Profiles/TradeKey/components/toast';
import SettingCreateFeeForm from './form';
import s from './styles.module.scss';
import { getExplorer } from '@/utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fetchPlayerPoolProfile: () => void;
  poolProfile: any;
};

interface IFormValue {
  amount: number;
}

const SettingCreatorFee: React.FC<Props> = ({
  isOpen,
  onClose,
  fetchPlayerPoolProfile,
}) => {
  const { userProfile, updateCreateFee } = useTradeKey();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (payload: IFormValue): Promise<void> => {
    try {
      setLoading(true);
      const response: any = await updateCreateFee({
        token_address: userProfile?.token_address as string,
        fee: payload.amount,
      });
      onClose();
      dispatch(requestReload());
      showSuccess({
        message: 'Creator earnings updated successfully.',
        linkText: 'See more',
        url: getExplorer(response.hash, 'nos', 'tx'),
      });
    } catch (error) {
      showError(getErrorMessageContract(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={s.wrapper}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent
          p="24px 22px"
          w={'90%'}
          textAlign={'center'}
          className={s.wrapper}
          maxW="600px"
          bg={'#292929'}
        >
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            textAlign="left"
            fontSize="24px"
            fontWeight={'600'}
            mb="24px"
          >
            Set up creator earnings
          </ModalHeader>
          <ModalBody>
            <Text textAlign={'left'} mb={5}>
              Set the fee you want to earn for each key buy or sell transaction.
              This fee can be adjusted from 0% to 8%.
            </Text>
            <Form
              initialValues={{
                amount: formatFeePercent(userProfile?.creator_fee),
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit }) => (
                <SettingCreateFeeForm
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              )}
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SettingCreatorFee;
