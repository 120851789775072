import { WalletContext } from '@/contexts/wallet-context';
import { getPosts } from '@/services/alpha_tweet';
import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import useApi from '@/hooks/useApi';
import s from './style.module.scss';
import { MainPost } from '../MainPost';
import { useIsInViewportOnce } from '@/hooks/useIsInViewportOnce';
import { PostItemContext } from '@/contexts/post-item';
import cs from 'classnames';
import { IPostData } from '@/interfaces/api/post';

type Props = {
  parentId: number;
  isFetchData?: boolean;
  isShowReply?: boolean;
  isShowTipBtn?: boolean;
  isShowActionList?: boolean;
  isShowFooter?: boolean;
  showRepliesOfReply?: boolean;
  isDetail?: boolean;
  parent?: IPostData;
  isFetchAllData?: boolean;
};

type ComponentProps = {
  replies: IPostData[];
};

const LIMIT = 100;
const MORE = 3;

const ReplySectionComponent = (props: Props & ComponentProps) => {
  const {
    isFetchData,
    isShowReply,
    isDetail = false,
    isShowFooter,
    showRepliesOfReply = false,
    parent,
    replies,
  } = props;

  const { setIsRelayShow } = useContext(PostItemContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [limitReply, setLimitReply] = useState<any>([]);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  useEffect(() => {
    if (!isShowReply) return;
    setLimitReply([...replies]);
  }, [isShowMore, replies, isShowReply]);

  useEffect(() => {
    setIsRelayShow(limitReply.length > 0);
  }, [limitReply]);

  if (!limitReply || !limitReply.length) return <></>;

  return (
    <div
      ref={sectionRef}
      className={`${s.wrapperReply} wrapperReply`}
      style={{
        marginTop: replies?.length > 0 ? '12px' : '0',
      }}
    >
      {limitReply &&
        limitReply?.length > 0 &&
        limitReply?.map((reply: any) => {
          if (!reply.user) {
            return <></>;
          }

          return (
            <React.Fragment key={reply.id}>
              <div
                className={cs(s.postReply, 'postReply', {
                  [`${s.postDetailReply} postDetailReply`]: isDetail,
                  [`${s.isHadReply} isHadReply`]: reply.reply_count > 0,
                })}
              >
                <MainPost
                  postType="reply"
                  parent={parent}
                  {...reply}
                  data={reply}
                  isShowFooter={isShowFooter}
                />
              </div>
              {showRepliesOfReply && (
                <>
                  <ReplySection
                    isFetchAllData={false}
                    parentId={reply.id}
                    parent={reply}
                    isFetchData={isFetchData}
                    isShowReply={true}
                    isShowFooter={false}
                    isShowTipBtn={reply.is_repost === false && !reply?.activity}
                    isDetail={isDetail}
                    showRepliesOfReply={false}
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

const ReplySection = ({
  isDetail,
  isFetchAllData,
  isFetchData,
  isShowFooter = true,
  parentId,
  parent,
  ...rest
}: Props) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { addressL2 } = useContext(WalletContext);

  // const isInView = useIsInViewportOnce(sectionRef, { threshold: 0 });

  const { data: replies } = useApi(
    getPosts,
    {
      page: 1,
      limit: isDetail ? LIMIT : 1,
      isFetchAllData,
      isFetchData,
      parentId: parentId,
      address: addressL2,
      only_following: !isDetail,
    },
    {
      shouldFetch: true,
    }
  );
  return (
    <>
      <div ref={sectionRef}></div>
      <ReplySectionComponent
        isDetail={isDetail}
        isFetchAllData={isFetchAllData}
        isFetchData={isFetchData}
        parentId={parentId}
        isShowFooter={isShowFooter}
        parent={parent}
        {...rest}
        replies={replies}
      />
    </>
  );
};

export default React.memo(ReplySection);
