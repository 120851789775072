import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { CSwap } from '@/contracts/swap';
import { ISwapParams } from '@/contracts/swap/swap.interface';
import sleep from '@/utils/sleep';
import CBridge from '@/contracts/bridge/bridge.class';
import { TOKEN_ADDRESS } from '@/constants/token';
import BigNumber from 'bignumber.js';
import { SupportedChainId } from '@/constants/chains';
import { AssetsContext } from './assets-context';
import { TRANSACTION_FEE } from '@/constants/amount';
import convertTC2BTCStorage from '@/utils/convert-storage';

interface IWithdrawBTCParams {
  humanAmount: string;
  receiver: string;
}

export interface ICurrencyContext {
  onSwapBTC2TC: (params: ISwapParams) => Promise<void>; // withdraw BTC to TC
  onSwapBTC2ETH: (params: ISwapParams) => Promise<void>; // Swap BTC to ETH
  onSwapTC2BTC: (params: ISwapParams) => Promise<void>; // Swap BTC to ETH
  withdrawBTCOnBTCNetwork: (params: IWithdrawBTCParams) => Promise<void>; // withdraw BTC on BTC network
  swapAndWithdrawBTCOnETHNetwork: (params: IWithdrawBTCParams) => Promise<void>; // swap BTC to ETH and withdraw on ETH network
  swapTC2BTCAble: boolean;
  isForceConvert: boolean;
}

const initialValue: ICurrencyContext = {
  onSwapBTC2TC: async () => undefined,
  onSwapBTC2ETH: async () => undefined,
  onSwapTC2BTC: async () => undefined,
  withdrawBTCOnBTCNetwork: async () => undefined,
  swapAndWithdrawBTCOnETHNetwork: async () => undefined,
  swapTC2BTCAble: false,
  isForceConvert: false,
};

export const CurrencyContext = React.createContext<ICurrencyContext>(
  initialValue
);

export const CurrencyProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const swapContract = new CSwap();
  const bridgeContract = new CBridge();
  const [isConvertSuccess, setIsConvertSuccess] = React.useState(false);

  const { balanceL2 } = useContext(AssetsContext);

  const swapTC2BTCAble = React.useMemo(() => {
    return (
      !!balanceL2 &&
      new BigNumber(balanceL2.amount).gt(TRANSACTION_FEE.TC_NEEDS_SWAP_BTC2TC)
    );
  }, [balanceL2]);

  const isForceConvert = React.useMemo(() => {
    // return (
    //   swapTC2BTCAble &&
    //   !isConvertSuccess &&
    //   convertTC2BTCStorage.isConvertNeeded()
    // );
    return false
  }, [swapTC2BTCAble, isConvertSuccess, balanceL2]);

  const onSwapBTC2TC = async (params: ISwapParams) => {
    await swapContract.swapBTC2TC({
      humanAmount: params.humanAmount,
    });
  };

  const onSwapBTC2ETH = async (params: ISwapParams) => {
    await swapContract.swapBTC2ETH({
      humanAmount: params.humanAmount,
    });
  };

  const onSwapTC2BTC = async (params: ISwapParams) => {
    await swapContract.swapTC2BTC({
      humanAmount: params.humanAmount,
    });
    setIsConvertSuccess(true);
    convertTC2BTCStorage.updateIsConvertNeeded(false);
    convertTC2BTCStorage.updateCheckedAmountThreshold();
  };

  const withdrawBTCOnBTCNetwork = async (params: IWithdrawBTCParams) => {
    // bridge out
    await bridgeContract.burnTokenL2({
      humanAmount: params.humanAmount,
      receiver: params.receiver,
      tokenAddress: TOKEN_ADDRESS.BTC_ADDRESS_L2,
      destinationChainId: SupportedChainId.BITCOIN,
    });
    await sleep(1);
  };

  const swapAndWithdrawBTCOnETHNetwork = async (params: IWithdrawBTCParams) => {
    const amountBeforeSwap = await bridgeContract.balanceOf(
      TOKEN_ADDRESS.ETH_ADDRESS_L2
    );

    await onSwapBTC2ETH({
      humanAmount: params.humanAmount,
    });
    await sleep(2);
    const amountAfterSwap = await bridgeContract.balanceOf(
      TOKEN_ADDRESS.ETH_ADDRESS_L2
    );

    const burnAmount = new BigNumber(amountAfterSwap)
      .minus(amountBeforeSwap)
      .dividedBy(1e18);

    console.log('burnAmount', {
      burnAmount: burnAmount.toString(),
      amountAfterSwap,
      amountBeforeSwap,
    });

    if (burnAmount.lte(0)) {
      throw new Error('Invalid amount');
    }

    // bridge out
    await bridgeContract.burnTokenL2({
      humanAmount: burnAmount.toString(),
      receiver: params.receiver,
      tokenAddress: TOKEN_ADDRESS.ETH_ADDRESS_L2,
      destinationChainId: SupportedChainId.ETHEREUM,
    });

    await sleep(1);
  };

  const contextValues = useMemo((): ICurrencyContext => {
    return {
      onSwapBTC2TC,
      onSwapBTC2ETH,
      onSwapTC2BTC,
      withdrawBTCOnBTCNetwork,
      swapAndWithdrawBTCOnETHNetwork,
      swapTC2BTCAble,
      isForceConvert,
    };
  }, [
    swapContract,
    onSwapBTC2TC,
    onSwapBTC2ETH,
    onSwapTC2BTC,
    withdrawBTCOnBTCNetwork,
    swapAndWithdrawBTCOnETHNetwork,
    swapTC2BTCAble,
    isForceConvert,
  ]);

  return (
    <CurrencyContext.Provider value={contextValues}>
      {children}
    </CurrencyContext.Provider>
  );
};
