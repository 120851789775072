import React from 'react';

const IcTwitterX = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.03096 2.38574L8.66237 8.57838L4.00171 13.6133H5.05064L9.13104 9.20515L12.4279 13.6133H15.9974L11.1054 7.0723L15.4435 2.38574H14.3946L10.6368 6.44553L7.6005 2.38574H4.03096ZM5.57348 3.15838H7.21334L14.4547 12.8405H12.8148L5.57348 3.15838Z" fill="white"/>
    </svg>
  );
};

export default IcTwitterX;
