import s from '@/modules/AlphaPWA/Home/PostItem/PostItemV2.module.scss';
import RepostSvg from '@/modules/AlphaPWA/Home/PostItem/Icon/RepostSvg';
import React, { useContext } from 'react';
import { AssetsContext } from '@/contexts/assets-context';

export function RepostLabel({ userRepost }: { userRepost: any }) {
  const { playerPoolProfile } = useContext(AssetsContext);
  return (
    <div className={s.additionalInfoBlock}>
      <span className={s.additionalInfoBlock_icon}>
        <RepostSvg />
      </span>
      <div className={`${s.additionalInfoBlock_text}`}>
        <span className={s.additionalInfoBlock_text_name}>
          {userRepost?.user_twitter_id === playerPoolProfile?.twitterId
            ? 'You'
            : userRepost?.user_twitter_name ||
              '@' + userRepost?.user_twitter_user_name}
        </span>{' '}
        reposted
      </div>
    </div>
  );
}
