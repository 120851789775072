import { PropsWithChildren, useEffect } from 'react';

export const Keyboard = ({ children }: PropsWithChildren) => {
  if (typeof window === 'undefined' || !window.visualViewport) {
    return <>{children}</>;
  }

  const handleResize = () => {
    if (window.visualViewport) {
      document.documentElement.style.setProperty(
        '--keyboard-inset-height',
        `${window.innerHeight - Math.round(window.visualViewport.height)}px`
      );
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize, false);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          'resize',
          handleResize,
          false
        );
      }
    };
  }, []);

  return <>{children}</>;
};
