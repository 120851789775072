export const IcBuy = (): React.ReactElement => (
  <svg height="30" width="30" fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <rect height="28" width="28" fill="black" rx="14" x="1" y="1"/>
    <rect height="28" width="28" rx="14" stroke="url(#paint1_linear_42_944)" x="1" y="1"/>
    <path d="M15 10.2L15 19.8M19.8 15L10.2 15" stroke="url(#paint0_linear_42_944)" strokeLinecap="round" strokeWidth="2"/>
    <defs>
      <linearGradient id="paint0_linear_42_944" gradientUnits="userSpaceOnUse" x1="10.2" x2="19.8" y1="15" y2="15">
        <stop stopColor="#00F5A0"/>
        <stop offset="1" stopColor="#00D9F5"/>
      </linearGradient>
      <linearGradient id="paint1_linear_42_944" gradientUnits="userSpaceOnUse" x1="1" x2="29" y1="15" y2="15">
        <stop stopColor="#00F5A0"/>
        <stop offset="1" stopColor="#00D9F5"/>
      </linearGradient>
    </defs>
  </svg>
);
