import px2rem from '@/utils/px2rem';
import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  tablist: {
    borderBottom: '1px solid',
    borderColor: 'var(--alpha-tabs-border)',
  },
});

const alphaVariant = definePartsStyle(props => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    tab: {
      fontSize: px2rem(16),
      padding: '8px',
      fontWeight: '400',
      opacity: 0.7,
      marginBottom: '-1px',
      borderBottom: '1px solid',
      borderColor: 'transparent',
      _selected: {
        opacity: 1,
        borderColor: 'var(--alpha-tabs-border-active)',
      },
    },
    tablist: {
      borderBottom: '1px solid',
      borderColor: 'var(--alpha-tabs-border)',
      minHeight: '36px',
      paddingLeft: '20px',
      paddingRight: '20px',
      gap: '16px',
      position: 'relative',
    },
    tabpanel: {
      padding: '0px',
    },
  };
});

const variants = {
  alpha: alphaVariant,
};

// export the component theme
const Tabs = defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: 'alpha',
  },
});

export default Tabs;
