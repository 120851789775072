import { PWA_NAME } from "@/configs";
import { PWAType } from "@/enums/configs";

export const getPWAType = () => {
  switch (PWA_NAME) {
    case 'MegaWhales':
      return PWAType.MegaWhales;
    case 'Alpha':
      return PWAType.Alpha;
    default:
      return PWAType.MegaWhales;
  }
}

export const getPWAIcon = () => {
  const pwaType = getPWAType();

  switch (pwaType) {
    case PWAType.MegaWhales:
      return '/icons/mega-whales/512x512.jpg';
    case PWAType.Alpha:
      return '/icons/alpha/512x512.jpg';
    default:
      return '/icons/mega-whales/512x512.jpg';
  }
}

export const getPWAFavicon = () => {
  const pwaType = getPWAType();

  switch (pwaType) {
    case PWAType.MegaWhales:
      return '/icons/mega-whales/favicon.svg';
    case PWAType.Alpha:
      return '/icons/alpha/152x152.jpg';
    default:
      return '/icons/mega-whales/152x152.jpg';
  }
}
