export default function CircleIcon() {
  return (
    <svg height="28" width="29" fill="none" viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <g clipPath="url(#clip0_18432_7514)">
          <path d="M13.444 20.079C10.3114 21.7455 6.42074 20.5572 4.75408 17.4246C3.08741 14.2918 4.27586 10.4012 7.40852 8.73455C10.5412 7.06789 14.4318 8.25631 16.0985 11.389C17.7652 14.5217 16.5767 18.4123 13.444 20.079Z" fill="url(#paint0_linear_18432_7514)" opacity="0.7"/>
          <path d="M20.4498 10.6179C23.8438 11.6546 25.7547 15.2464 24.718 18.6403C23.6813 22.0341 20.0894 23.9451 16.6956 22.9084C13.3017 21.8716 11.3908 18.2799 12.4275 14.886C13.4642 11.4921 17.056 9.58123 20.4498 10.6179Z" fill="url(#paint1_linear_18432_7514)" opacity="0.7"/>
          <path d="M22.4875 11.3044C21.4508 14.6983 17.8591 16.6092 14.4652 15.5724C11.0712 14.5357 9.16033 10.944 10.197 7.5501C11.2338 4.15619 14.8255 2.2453 18.2194 3.28201C21.6133 4.31872 23.5243 7.91042 22.4875 11.3044Z" fill="url(#paint2_linear_18432_7514)" opacity="0.7"/>
        </g>
      </g>
      <defs>
        <linearGradient id="paint0_linear_18432_7514" gradientUnits="userSpaceOnUse" x1="4.74594" x2="16.0903" y1="17.4093" y2="11.3737">
          <stop stopColor="#DCDCDC"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint1_linear_18432_7514" gradientUnits="userSpaceOnUse" x1="21.5907" x2="15.5547" y1="22.4358" y2="11.0905">
          <stop stopColor="#DCDCDC"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint2_linear_18432_7514" gradientUnits="userSpaceOnUse" x1="14.5006" x2="18.2548" y1="15.6106" y2="3.32011">
          <stop stopColor="#F9F9F9"/>
          <stop offset="1" stopColor="#D3D3D3"/>
        </linearGradient>
        <clipPath id="clip0_18432_7514">
          <rect height="21" width="21" fill="white" transform="translate(4 3)"/>
        </clipPath>
      </defs>
    </svg>

  );
}
