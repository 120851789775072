import {
  SHOW_NOTIFICATION,
  TWITTER_TOKEN,
  TWITTER_PROFILE,
} from '@/constants/storage-key';
import { decryptAES, encryptAES } from '@/utils/encryption';
import { StorageLocal } from '@/utils/storage.local';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';

class AccountStorage extends StorageLocal {
  ACCOUNT_KEY = 'ACCOUNT_CIPHER_TEXT';
  ADDRESS_KEY = 'ADDRESS_STORAGE';
  PASS_WORD = 'NUMBER_STORAGE_L2';
  BOOLEAN_STORAGE_PASS_L2 = 'BOOLEAN_STORAGE_PASS_L2';

  getAccountKey = () => this.ACCOUNT_KEY;

  getAccountCipher = () => {
    const key = this.getAccountKey();
    const cipherText = this.get(key);
    return cipherText;
  };

  getAccount = (password: string) => {
    const cipherText = this.getAccountCipher();
    const prvKey = decryptAES(cipherText, password);
    return prvKey;
  };

  setAccount = ({ prvKey, password }: { prvKey: string; password: string }) => {
    const key = this.getAccountKey();
    const cipherText = encryptAES(prvKey, password);
    this.set(key, cipherText);
  };

  getAddressKey = () => this.ADDRESS_KEY;

  getAddress = () => {
    const key = this.getAddressKey();
    const address = this.get(key);
    return address;
  };

  getTwitterToken = () => {
    const token = this.get(TWITTER_TOKEN);
    return token;
  };

  setTwitterToken = (token: string) => {
    this.set(TWITTER_TOKEN, token);
  };

  setTwitterProfile = (data: IGetPlayerPoolProfile) => {
    this.set(TWITTER_PROFILE, JSON.stringify(data));
  };

  getTwitterProfile = (): IGetPlayerPoolProfile => {
    return this.get(TWITTER_PROFILE);
  };

  get isUserLinkingTwitter() {
    const address = this.getAddress();
    const profile = this.getTwitterProfile();

    return (
      address &&
      profile &&
      profile?.twitterId &&
      profile?.address?.toLowerCase() === address?.toLowerCase()
    );
  }

  setAddress = ({ address }: { address: string }) => {
    const key = this.getAddressKey();
    this.set(key, address);
  };

  getPassWordKey = () => this.PASS_WORD;

  getPassWord = () => {
    const key = this.getPassWordKey();
    const password = this.get(key);
    return password;
  };

  setPassWord = ({ password }: { password: string }) => {
    const key = this.getPassWordKey();
    this.set(key, password + '');
  };

  removePassWord = () => {
    const key = this.getPassWordKey();
    this.remove(key);
  };

  getIsStoragePassKey = () => this.BOOLEAN_STORAGE_PASS_L2;

  getIsStoragePass = () => {
    const key = this.getIsStoragePassKey();
    const isSave = this.get(key);
    return (
      isSave === undefined ||
      isSave === null ||
      isSave === 'true' ||
      isSave === true
    );
  };

  setIsStoragePass = ({ isSave }: { isSave: boolean }) => {
    const key = this.getIsStoragePassKey();
    this.set(key, isSave ? 'true' : 'false');
  };

  getNeedShowNotification = () => {
    const showed = this.get(SHOW_NOTIFICATION);
    return showed === 'true' || showed === true;
  };

  setShowedNotification = () => {
    this.set(SHOW_NOTIFICATION, 'true');
  };

  removeShowedNotification = () => {
    this.remove(SHOW_NOTIFICATION);
  };
}

const accountStorage = new AccountStorage();

export default accountStorage;
