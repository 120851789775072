import Router from 'next/router';
import NProgress from './nprogress';

let timer: NodeJS.Timeout;
let state: string;
const delay: number = 250;

const ProgressBar = (): null => {
  if (!document?.querySelector('#progress-bar')) return null;

  NProgress.configure({ showSpinner: false, parent: '#progress-bar' });

  const load = () => {
    if (state === 'loading') {
      return;
    }

    state = 'loading';

    timer = setTimeout(() => {
      if (!document?.querySelector('#progress-bar')) return;
      NProgress.start();
    }, delay);
  };

  const stop = () => {
    state = 'stop';

    clearTimeout(timer);
    setTimeout(() => {
      if (!document?.querySelector('#progress-bar')) {
        clearTimeout(timer);
        return;
      }

      NProgress.done();
    }, delay);
  };

  Router.events.on('routeChangeStart', load);
  Router.events.on('routeChangeComplete', stop);
  Router.events.on('routeChangeError', stop);

  return null;
};

export default ProgressBar;
