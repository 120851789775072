import React, { useEffect, useRef } from 'react';
import { EMOJI_REACTIONS } from '@/modules/AlphaPWA/Home/PostItem/Emoji';
import s from '../../PostItem.module.scss';
import cs from 'classnames';

type IEmojisBar = {
  submitEmoji: (reactionType: number) => void;
  classNames?: string;
};

const EmojisBar: React.FC<IEmojisBar> = ({ submitEmoji, classNames }) => {
  const refContent = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!refContent.current) return;

    const right = refContent.current?.getBoundingClientRect()?.right;
    const width = window.innerWidth;
    const disRight = right + 16 - width;

    if (disRight > 0) {
      refContent.current.style.setProperty('--tran', `${disRight}px`);
    }

    return () => {
      if (refContent.current)
        refContent.current.style.setProperty('--tran', `0px`);
    };
  }, []);
  return (
    <div ref={refContent} className={cs(s.emojis, classNames, 'emojis')}>
      <ul className={s.emojis_list}>
        {EMOJI_REACTIONS.map(em => {
          if (em?.hide) return <></>;
          return (
            <li
              key={em.type}
              className={s.emojiItem}
              onClick={() => submitEmoji(em.type)}
            >
              {em.lottie ? em.lottie() : em.icon()}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EmojisBar;
