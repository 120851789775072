import { PropsWithChildren, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import s from './styles.module.scss';
import cs from 'classnames';
import useWindowSize from '@/hooks/useWindowSize';

interface LayoutProps extends PropsWithChildren {
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideMenu?: boolean;
  hideBanner?: boolean;
  isWallet?: boolean;
  subHeader?: React.ReactNode;
  isLight?: boolean;
  isGame?: boolean;
  isAi?: boolean;
  isHome?: boolean;
  isArcade?: boolean;
  bgImg?: string;
  bgVideo?: string;
  cssMain?: boolean;
  cssBg?: string;
  classNameMain?: string;
  coverVideo?: boolean;
}

const MainLayout: React.FC<LayoutProps> = ({
  children,
  hideHeader = false,
  hideFooter,
  hideMenu,
  isWallet,
  subHeader,
  isLight = true,
  isGame = false,
  isAi = false,
  isHome = false,
  isArcade = false,
  bgImg,
  bgVideo,
  cssMain = true,
  cssBg,
  classNameMain,
  coverVideo = false,
  hideBanner = false,
}) => {
  const { mobileScreen } = useWindowSize();

  useEffect(() => {
    var elemHtml = document.getElementsByTagName('html');
    if (elemHtml && elemHtml[0]) {
      elemHtml[0].className = s.wrapHtml;
    }
  }, []);

  return (
    <div
      className={cs(s.container, {
        [`${s.container__light}`]: isLight,
        [`${s.container__game}`]: isGame,
        [`${s.container__ai}`]: isAi,
      })}
    >
      {bgVideo && (
        <div className={cs(s.backgroundVideo, cssBg)}>
          <video autoPlay muted loop id="myVideo">
            <source src={bgVideo} type="video/mp4" />
          </video>
          {coverVideo && <div className={s.coverVideo} />}
        </div>
      )}
      {bgImg && (
        <div className={cs(s.backgroundImg, cssBg)}>
          <img alt="bg" src={bgImg} />
        </div>
      )}
      {/* {!hideBanner && !mobileScreen && (
        <Banner theme={isLight ? 'light' : 'dark'} />
      )} */}
      {!hideHeader && (
        <Header
          isHideMenu={hideMenu}
          isWallet={isWallet}
          subHeader={subHeader}
          isLight={isLight}
          isGame={isGame}
          isAi={isAi}
          isHome={isHome}
          isArcade={isArcade}
        />
      )}
      <main className={cs({ [`${s.children}`]: cssMain }, classNameMain)}>
        {children}
      </main>
      {!hideFooter && <Footer isLight={isLight} />}
    </div>
  );
};

export default MainLayout;
