import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';
import s from './styles.module.scss';
import { JackpotProfile } from '@/modules/AlphaPWA/Profile';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ProfileDrawer = ({ isOpen, onClose }: Props) => {
  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'xl'}>
      <DrawerOverlay />
      <DrawerContent className={s.filterModal}>
        <DrawerBody>
          <Box>
            <JackpotProfile onClose={onClose} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ProfileDrawer;
