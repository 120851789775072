import { Flex, Text } from '@chakra-ui/react';
import clsx from 'classnames';
import React, { useContext, useMemo } from 'react';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { IItemSort } from './itemFilterSort';

import { WalletContext } from '@/contexts/wallet-context';
import { compareString } from '@/utils';
import storageLocal from '@/utils/storage.local';
import {
  KEY_LAST_SORT,
  KEY_LAST_SORT_VALUE,
  PLACE_HOLDER_LAST_SORT,
  PLACE_HOLDER_LAST_SORT_VALUE,
} from '../constants';
import s from '../styles.module.scss';

interface ISortBy {
  sorts: IItemSort[];
  filterKeys: any;
  onFilter: any;
  setFilterKeys: any;
  className?: string;
}

const SortBy: React.FC<ISortBy> = ({
  sorts,
  filterKeys,
  onFilter,
  setFilterKeys,
  className,
}) => {
  const { addressL2 } = useContext(WalletContext);
  const currentSortBy = useMemo(() => {
    const sort_col = filterKeys?.sort_col?.toString()?.split(',');
    const sort_type = filterKeys?.sort_type?.toString()?.split?.(',');

    const key =
      sort_col?.find?.(
        (v: any) => sorts.findIndex(_v => compareString(v, _v.key)) > -1
      ) || sorts?.find(v => v.isDefault)?.key;

    const findSortKey = sorts.find(v => compareString(v.key, key));

    return { ...findSortKey, value: sort_type };
  }, [filterKeys, sorts]);

  const onSetSort = (sort: IItemSort) => {
    setFilterKeys((_filter: any) => {
      let __filters = {
        ..._filter,
        holder: 0,
        sort_col: sort.key,
        sort_type: sort.value,
      };

      const sortType = compareString(_filter?.sort_type, '1') ? '0' : '1';

      if (
        compareString(_filter?.sort_col, sort.key) &&
        !compareString(_filter?.sort_col, 'created_at')
      ) {
        __filters = {
          ...__filters,
          sort_type: sortType,
        };
      }

      storageLocal.set(KEY_LAST_SORT, __filters?.sort_col);
      storageLocal.set(KEY_LAST_SORT_VALUE, __filters?.sort_type);

      if (compareString(sort.key, 'holder')) {
        __filters = {
          ...__filters,
          holder: 1,
          address: addressL2,
        };
      } else if (compareString(__filters.placeholder, '1')) {
        __filters = {
          ...__filters,
          placeholder: '1',
        };
        storageLocal.set(PLACE_HOLDER_LAST_SORT, __filters?.sort_col);
        storageLocal.set(PLACE_HOLDER_LAST_SORT_VALUE, __filters?.sort_type);
      }

      onFilter(__filters);
      return __filters;
    });
  };

  const getActive = (sort: IItemSort) => {
    return compareString(currentSortBy.key, sort.key);
  };

  const canShowSortUpDown = useMemo(() => {
    if (
      compareString(filterKeys?.placeholder, '1') &&
      !compareString(filterKeys?.sort_col, 'created_at')
    ) {
      return true;
    }
    return false;
  }, [filterKeys]);

  return (
    <Flex
      className={clsx(s.sortByTextContainer, className)}
      gap={'16px'}
      alignItems={'center'}
    >
      {sorts.map(st => (
        <Flex alignItems={'center'} gap={2} key={st.key}>
          <Text
            onClick={() => onSetSort(st)}
            className={getActive(st) ? clsx(s.active, 'sort-by-active') : ''}
          >
            {st.label}
          </Text>
          {getActive(st) && canShowSortUpDown && (
            <Flex flexDirection={'column'}>
              <BiSolidUpArrow
                opacity={compareString(currentSortBy.value, '1') ? 1 : 0.4}
              />
              <BiSolidDownArrow
                opacity={compareString(currentSortBy.value, '0') ? 1 : 0.4}
              />
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default SortBy;
