import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import Item, { ITEM_HEIGHT } from './Item';
import { useDM } from '../../provider';
import Empty from '@/modules/AlphaPWA/DirectMessage/Empty';
import moment from 'moment';
import { orderBy } from 'lodash';
import { DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';
import { compareString } from '@/utils';
import { HoldingUserTokens } from '@/interfaces/api/verifyFriendTech';
import { tokenHoldingPersistor } from '@/utils/persistor';
import Banner from '@/modules/AlphaPWA/DirectMessage/List/banner';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { selectCommonReducer, setShowKeyLeft } from '@/state/common';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import EmptySearch from '../EmptySearch';

const Chat = dynamic(() => import('../../Chat'), {
  ssr: false,
});

type RenderRoom = DMRoom & { sortedTime?: number };

const MIN_DATE = new Date();
MIN_DATE.setFullYear(2000);

export const filterUser = (r: any, valueSearch: string | undefined) => {
  if (!valueSearch) return true;
  valueSearch = valueSearch.toLocaleLowerCase();

  let ownerInfo = null;

  if (r?.type === 'CHAT_ROOM') {
    ownerInfo = r?.toInfo;
  } else {
    ownerInfo = r?.ownerInfo;
  }

  if (!ownerInfo) return true;

  return ownerInfo?.twitterName?.toLocaleLowerCase().includes(valueSearch);
};

const CirclesTab: React.FC<any> = forwardRef(
  (
    { isActive, valueSearch }: { isActive: boolean; valueSearch?: string },
    ref
  ) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const [listHeight, setListHeight] = useState(0);
    const { rooms, screen, yourPoolProfile, setRoomId, roomId } = useDM();
    const [holdingTokenUser, setHoldingTokenUser] =
      useState<HoldingUserTokens | null>();

    const [renderRooms, setRenderRooms] = useState<RenderRoom[]>([]);
    const shouldRenderRef = useRef(true);
    const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = useState('');
    const [pinnedLength, setPinnedLength] = useState(0);

    const isSearching = useMemo(() => {
      return valueSearch !== currentSearch;
    }, [valueSearch]);

    const [dmDetail, setDmDetail] = useState<string | undefined>();
    const dispatch = useDispatch();
    const showKeyLeft = useSelector(selectCommonReducer).showKeyLeft;
    const showKeyRight = useSelector(selectCommonReducer).showKeyRight;

    useImperativeHandle(ref, () => ({
      dmDetail: dmDetail,
    }));

    const onClickDmDetail = (roomId: string) => {
      if (compareString(showKeyLeft, '-1')) {
        dispatch(setShowKeyLeft('2'));
      }
      if (dmDetail !== roomId) {
        onSetActiveRoom(roomId);
      }
    };

    const onSetActiveRoom = (roomId: string) => {
      setDmDetail(roomId);
      setRoomId(undefined);
      setTimeout(() => {
        setRoomId(roomId);
      }, 200);
    };

    useEffect(() => {
      if (contentRef.current) {
        // 73 is nav
        setListHeight(contentRef.current.clientHeight - 73);
      }
    }, []);

    useEffect(() => {
      if (isActive && dmDetail) {
        setRoomId(dmDetail);
        setDmDetail(dmDetail);
      }
    }, [isActive]);

    useEffect(() => {
      let interval: ReturnType<typeof setInterval>;
      if (yourPoolProfile) {
        setTimeout(() => {
          tokenHoldingPersistor
            .getItem(yourPoolProfile.tokenAddress)
            .then(storedItem => {
              setHoldingTokenUser(storedItem);
            });
        });
        interval = setInterval(() => {
          tokenHoldingPersistor
            .getItem(yourPoolProfile.tokenAddress)
            .then(storedItem => {
              setHoldingTokenUser(storedItem);
            });
        }, 5 * 60 * 1000);
      }
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [yourPoolProfile]);

    const yourRoom = useMemo(() => {
      return rooms.find(
        r =>
          r.type === 'CHAT_GROUP' &&
          r.isActive &&
          (compareString(r.owner, yourPoolProfile?.address) ||
            compareString(r.owner, yourPoolProfile?.tokenAddress))
      );
    }, [rooms, yourPoolProfile]);

    useEffect(() => {
      if (yourRoom && !roomId && !compareString(showKeyLeft, '-1')) {
        onClickDmDetail(yourRoom?.id);
      }
    }, [yourRoom, showKeyLeft]);

    useEffect(() => {
      const computedData = () => {
        const chatGroup = rooms.filter(
          r => r.type === 'CHAT_GROUP' && r.isActive && !r.isTribe
        );
        const yourRoom = chatGroup.find(
          r =>
            compareString(r.owner, yourPoolProfile?.address) ||
            compareString(r.owner, yourPoolProfile?.tokenAddress)
        );

        let dataWithoutYourRoom = [];
        if (yourRoom) {
          dataWithoutYourRoom = chatGroup.filter(r => r.id !== yourRoom?.id);
        } else {
          dataWithoutYourRoom = chatGroup;
        }

        if (valueSearch) {
          dataWithoutYourRoom = chatGroup.filter(room =>
            filterUser(room, valueSearch)
          );
          if (!dataWithoutYourRoom.length && chatGroup.length) {
            setIsEmptySearch(true);
          }
        } else {
          setIsEmptySearch(false);
        }

        const pinnedGroup = dataWithoutYourRoom.filter(r => !!r.pinned);
        const unPinnedGroup = dataWithoutYourRoom.filter(r => !r.pinned);
        setRenderRooms([
          ...(yourRoom && !valueSearch ? [yourRoom] : []),
          ...pinnedGroup,
          ...orderBy(
            unPinnedGroup.map(r => ({
              ...r,
              sortedTime: Math.floor(
                moment(
                  r.lastMessage
                    ? r.lastMessage.createdAt
                    : MIN_DATE.toISOString()
                ).valueOf() / 60000
              ),
            })),
            ['sortedTime'],
            ['desc']
          ),
        ]);

        shouldRenderRef.current = true;
      };

      if (shouldRenderRef.current) {
        if (isActive) {
          shouldRenderRef.current = false;

          const isSearching = valueSearch !== currentSearch;
          if (isSearching) {
            computedData();
            setCurrentSearch(valueSearch || '');
          } else {
            const pinL = rooms?.filter(
              r => r !== null && r !== undefined && r.pinned
            ).length;

            const isPinning = pinL !== pinnedLength;
            if (renderRooms.length) {
              setTimeout(
                () => {
                  computedData();
                },
                isPinning ? 0 : 1000
              );
              if (isPinning) {
                setPinnedLength(pinL);
              }
              return;
            }

            computedData();
          }
        }
      }
    }, [rooms, isActive, yourPoolProfile, valueSearch]);

    const gridTemplate = useMemo(() => {
      if (!dmDetail || compareString(showKeyLeft, '-1')) {
        return '1fr 0';
      } else {
        if (compareString(showKeyRight, '1')) {
          return '1fr 2fr';
        } else {
          if (compareString(showKeyLeft, '1')) {
            return '1fr 3fr';
          } else {
            return '1fr 2fr';
          }
        }
      }
    }, [dmDetail, showKeyRight, showKeyLeft]);

    if (renderRooms.length) {
      return (
        <Grid
          className={cs(s.wrapper, s.visible)}
          ref={contentRef}
          templateColumns={gridTemplate}
        >
          <GridItem>
            <Banner />
            <div
              className={cs(s.list)}
              style={{
                height: `${renderRooms.length * ITEM_HEIGHT}px`,
              }}
            >
              {renderRooms.map((r, idx) => (
                <Item
                  holdingTokenUser={holdingTokenUser}
                  key={r.id}
                  room={r}
                  order={idx}
                  onClickItem={onClickDmDetail}
                />
              ))}
            </div>
          </GridItem>

          <GridItem
            w="100%"
            h="100%"
            borderLeft={'1px solid #353945'}
            borderRight={'1px solid #353945'}
            position={'relative'}
          >
            {isActive && dmDetail && (
              <Box w="100%" h="100%">
                <Chat />
              </Box>
            )}
            {!isActive && <Box w="100%" h="100%" />}
          </GridItem>
        </Grid>
      );
    }

    if (isEmptySearch) {
      return <EmptySearch />;
    }

    return (
      <div
        className={cs(s.wrapper, screen === 'HOME' ? s.visible : s.hidden)}
        ref={contentRef}
      >
        <Banner />
        <div className={cs(s.list)}>
          <Empty />
        </div>
      </div>
    );
  }
);

export default React.memo(CirclesTab);
