import React from 'react';
import { Field } from 'react-final-form';
import FieldAmount from '../../Profiles/TradeKey/components/form/fieldAmount';
import InputWrapper from '../../Profiles/TradeKey/components/form/inputWrapper';
import { Box, Button, Text } from '@chakra-ui/react';

const SettingCreateFeeForm = ({ handleSubmit, loading }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <InputWrapper label={'Fee'}>
        <Field
          component={FieldAmount}
          name="amount"
          min={0}
          max={8}
          decimals={2}
          appendComp={<Text>%</Text>}
        />
      </InputWrapper>
      <Box py={4} />
      <Button
        type="submit"
        isDisabled={loading}
        isLoading={loading}
        loadingText={'Processing'}
        width={'100%'}
      >
        Confirm
      </Button>
    </form>
  );
};

export default SettingCreateFeeForm;
