import { WELCOME_FROM_FT } from '@/constants/storage-key';
import { WalletContext } from '@/contexts/wallet-context';
import { useProfiles } from '@/modules/AlphaPWA/Profiles/provider';
import { getUserPoint, userPointClaimFTPoint } from '@/services/player-share';
import { getReferralCode } from '@/services/referral';
import { compareString, formatCurrency } from '@/utils';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import { getLink } from '@/utils/helpers';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import s from './styles.module.scss';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import IcX from './icX';

const ModalJoinFromFT = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id: 'ModalJoinFromFT' });
  const { addressL2 } = useContext(WalletContext);
  const [referralCode, setReferralCode] = useState();
  const gaEventTracker = useAnalyticsEventTracker();
  const [userPoint, setUserPoint] = useState<any>();

  useEffect(() => {
    checkIsShow();
    return () => {
      onCloseAndDone();
    };
  }, [addressL2]);

  const checkIsShow = async () => {
    const hasWelcome = localStorage.getItem(WELCOME_FROM_FT);
    if (!addressL2 || compareString(hasWelcome, '1')) {
      return;
    }

    try {
      const response = await Promise.all([
        getUserPoint(addressL2),
        getReferralCode(),
      ]);

      if (Number(response?.[0]?.pointFriendtechClaimable) > 0) {
        onOpen();
        setUserPoint(response?.[0]);
        setReferralCode(response?.[1]);
      }
    } catch (error) {}
  };

  const onCloseAndDone = () => {
    localStorage.setItem(WELCOME_FROM_FT, '1');
    if (addressL2) {
      userPointClaimFTPoint(addressL2 as string);
    }
    onClose();
  };

  const onShareToTw = () => {
    if (referralCode) {
      const content = `I recently discovered @NewBitcoinCity - the friendtech of #Bitcoin!\n\n\n- 8% creator royalty\n- No deposit or wallet needed to join\n- BTC, ETH & AVAX accepted\n\nJoin now via my referral link and get 1,000 airdrop points:`;
      const shareUrl = getLink(referralCode);
      const hashtags = 'NewBitcoinCity,Bitcoin';

      gaEventTracker(AlphaActions.SpreedContent, addressL2);
      window.open(
        `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
          content
        )}&hashtags=${hashtags}`,
        '_blank'
      );
    }

    onCloseAndDone();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onCloseAndDone}>
      <ModalOverlay />
      <ModalContent className={s.modalContainer}>
        <ModalCloseButton opacity={0.7} className={s.modalBtnClose} />
        <ModalHeader className={s.modalTitle}>🎉 Congratulations</ModalHeader>
        <ModalBody>
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <svg
              width="61"
              height="61"
              viewBox="0 0 61 61"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_20275_40683)">
                <path
                  d="M15.2132 36.8686C13.9419 36.8664 12.6999 36.4875 11.6438 35.7799C10.5877 35.0723 9.76501 34.0676 9.27955 32.8927C8.79408 31.7178 8.66764 30.4254 8.91619 29.1787C9.16473 27.932 9.77711 26.7869 10.676 25.888C11.5749 24.9891 12.72 24.3767 13.9667 24.1282C15.2134 23.8796 16.5058 24.0061 17.6807 24.4915C18.8556 24.977 19.8603 25.7997 20.5679 26.8558C21.2755 27.9118 21.6544 29.1539 21.6566 30.4252C21.6545 32.1334 20.975 33.7711 19.7671 34.979C18.5591 36.187 16.9214 36.8665 15.2132 36.8686ZM15.2149 35.0375C16.127 35.0374 17.0187 34.7669 17.7772 34.2601C18.5356 33.7533 19.1268 33.033 19.4758 32.1902C19.8249 31.3475 19.9162 30.4201 19.7383 29.5255C19.5603 28.6308 19.121 27.809 18.476 27.164C17.831 26.519 17.0092 26.0797 16.1145 25.9017C15.2199 25.7238 14.2925 25.8151 13.4498 26.1642C12.607 26.5132 11.8867 27.1044 11.3799 27.8628C10.8731 28.6212 10.6025 29.5129 10.6025 30.4251C10.6044 31.6478 11.0909 32.8199 11.9555 33.6845C12.8201 34.5491 13.9922 35.0356 15.2149 35.0375Z"
                  fill="white"
                />
                <path
                  d="M19.4326 31.3688C18.3427 31.3688 17.2774 31.0456 16.3712 30.4402C15.465 29.8347 14.7587 28.9741 14.3416 27.9672C13.9245 26.9603 13.8154 25.8524 14.028 24.7835C14.2406 23.7146 14.7654 22.7327 15.5361 21.9621C16.3067 21.1914 17.2886 20.6666 18.3575 20.454C19.4264 20.2414 20.5343 20.3505 21.5412 20.7676C22.5481 21.1847 23.4087 21.891 24.0142 22.7972C24.6196 23.7034 24.9428 24.7687 24.9428 25.8586C24.9402 27.3192 24.3589 28.7192 23.3261 29.7521C22.2933 30.7849 20.8932 31.3662 19.4326 31.3688ZM19.4326 29.536C20.16 29.5358 20.8711 29.32 21.4759 28.9157C22.0806 28.5115 22.552 27.937 22.8303 27.2649C23.1086 26.5928 23.1814 25.8533 23.0394 25.1399C22.8975 24.4264 22.5472 23.7711 22.0328 23.2567C21.5184 22.7423 20.8631 22.392 20.1496 22.2501C19.4362 22.1081 18.6966 22.1809 18.0245 22.4592C17.3524 22.7375 16.778 23.2088 16.3737 23.8136C15.9695 24.4184 15.7537 25.1295 15.7535 25.8569C15.7539 26.8325 16.1417 27.7681 16.8316 28.4579C17.5214 29.1478 18.457 29.5355 19.4326 29.536Z"
                  fill="white"
                />
                <path
                  d="M19.4286 40.5461C18.3392 40.546 17.2744 40.2229 16.3687 39.6176C15.463 39.0123 14.7572 38.1521 14.3403 37.1457C13.9235 36.1393 13.8145 35.0319 14.027 33.9635C14.2396 32.8951 14.7642 31.9137 15.5344 31.1435C16.3047 30.3732 17.286 29.8486 18.3544 29.6361C19.4228 29.4235 20.5302 29.5326 21.5366 29.9494C22.5431 30.3662 23.4033 31.0721 24.0086 31.9778C24.6138 32.8835 24.9369 33.9483 24.9371 35.0376C24.936 36.4982 24.3552 37.8987 23.3224 38.9315C22.2896 39.9643 20.8892 40.545 19.4286 40.5461ZM19.4286 38.7167C20.156 38.7165 20.8671 38.5007 21.4718 38.0964C22.0766 37.6922 22.5479 37.1177 22.8263 36.4456C23.1046 35.7735 23.1773 35.034 23.0354 34.3206C22.8934 33.6071 22.5431 32.9518 22.0287 32.4374C21.5144 31.923 20.859 31.5727 20.1456 31.4308C19.4321 31.2888 18.6926 31.3616 18.0205 31.6399C17.3484 31.9182 16.7739 32.3895 16.3697 32.9943C15.9655 33.5991 15.7496 34.3102 15.7495 35.0376C15.7496 36.0142 16.1377 36.9507 16.8282 37.6413C17.5188 38.3318 18.4553 38.7199 19.4319 38.72L19.4286 38.7167Z"
                  fill="white"
                />
                <path
                  d="M25.3369 32.8282L25.3369 28.0684L22.3876 28.0684L22.3876 32.8282L25.3369 32.8282Z"
                  fill="#FFDE17"
                />
                <path
                  d="M47.5152 35.8714C47.7365 35.871 47.9557 35.9144 48.1602 35.9989C48.3648 36.0835 48.5506 36.2075 48.7071 36.364C48.8636 36.5205 48.9877 36.7064 49.0722 36.9109C49.1567 37.1154 49.2001 37.3346 49.1997 37.556L50.1296 37.556L50.1296 28.9917L44.8704 28.9917L44.8704 37.5559L45.8306 37.556C45.8302 37.3346 45.8736 37.1154 45.9581 36.9109C46.0427 36.7064 46.1667 36.5205 46.3232 36.364C46.4797 36.2075 46.6656 36.0834 46.8701 35.9989C47.0746 35.9144 47.2938 35.871 47.5152 35.8714Z"
                  fill="white"
                />
                <g>
                  <path
                    d="M15.2111 36.938C13.9234 36.938 12.6646 36.5562 11.5939 35.8407C10.5231 35.1253 9.6886 34.1085 9.1958 32.9187C8.70299 31.729 8.57405 30.4199 8.82527 29.1569C9.07649 27.8939 9.69659 26.7338 10.6072 25.8232C11.5177 24.9126 12.6779 24.2925 13.9409 24.0413C15.2039 23.7901 16.513 23.919 17.7027 24.4118C18.8924 24.9046 19.9093 25.7392 20.6247 26.8099C21.3401 27.8806 21.722 29.1394 21.722 30.4272C21.72 32.1534 21.0335 33.8083 19.8129 35.0289C18.5923 36.2495 16.9373 36.9361 15.2111 36.938ZM15.2128 34.9721C16.1109 34.9711 16.9886 34.7039 17.7349 34.2043C18.4812 33.7047 19.0627 32.9951 19.4059 32.1652C19.7491 31.3353 19.8386 30.4222 19.6632 29.5414C19.4877 28.6606 19.0551 27.8516 18.4201 27.2166C17.785 26.5815 16.976 26.1489 16.0952 25.9735C15.2144 25.798 14.3014 25.8875 13.4714 26.2307C12.6415 26.574 11.9319 27.1554 11.4323 27.9018C10.9327 28.6481 10.6655 29.5257 10.6645 30.4238C10.6655 31.6299 11.1453 32.7863 11.9984 33.6389C12.8516 34.4914 14.0084 34.9704 15.2145 34.9704L15.2128 34.9721Z"
                    fill="#FFB81A"
                  />
                </g>
                <path
                  d="M44.082 37.5547L44.082 28.9902L41.7593 28.9902L41.7593 37.5547L44.082 37.5547Z"
                  fill="white"
                />
                <path
                  d="M52.7705 30.1501L52.7705 30.6266C52.7705 30.9425 52.645 31.2455 52.4216 31.4689C52.1982 31.6923 51.8953 31.8178 51.5793 31.8178L22.3887 31.8177L22.3887 28.9589L51.5793 28.959C51.8953 28.959 52.1982 29.0845 52.4216 29.3079C52.645 29.5313 52.7705 29.8342 52.7705 30.1501Z"
                  fill="white"
                />
                <g opacity="0.9">
                  <path
                    d="M47.5152 35.8714C47.7365 35.871 47.9557 35.9144 48.1602 35.9989C48.3648 36.0835 48.5506 36.2075 48.7071 36.364C48.8636 36.5205 48.9877 36.7064 49.0722 36.9109C49.1567 37.1154 49.2001 37.3346 49.1997 37.556L50.1296 37.556L50.1296 28.9917L44.8704 28.9917L44.8704 37.5559L45.8306 37.556C45.8302 37.3346 45.8736 37.1154 45.9581 36.9109C46.0427 36.7064 46.1667 36.5205 46.3232 36.364C46.4797 36.2075 46.6656 36.0834 46.8701 35.9989C47.0746 35.9144 47.2938 35.871 47.5152 35.8714Z"
                    fill="#FFDE17"
                  />
                </g>
                <g opacity="0.9">
                  <path
                    d="M44.082 37.5547L44.082 28.9902L41.7593 28.9902L41.7593 37.5547L44.082 37.5547Z"
                    fill="#FFDE17"
                  />
                </g>
                <g opacity="0.9">
                  <path
                    d="M52.7705 30.1501L52.7705 30.6266C52.7705 30.9425 52.645 31.2455 52.4216 31.4689C52.1982 31.6923 51.8953 31.8178 51.5793 31.8178L22.3887 31.8177L22.3887 28.9589L51.5793 28.959C51.8953 28.959 52.1982 29.0845 52.4216 29.3079C52.645 29.5313 52.7705 29.8342 52.7705 30.1501Z"
                    fill="#FFB81A"
                  />
                </g>
                <g>
                  <path
                    d="M19.4379 31.4401C18.3353 31.4389 17.2579 31.1111 16.3416 30.4978C15.4253 29.8846 14.7113 29.0135 14.2899 27.9947C13.8685 26.9758 13.7585 25.8549 13.9739 24.7736C14.1893 23.6923 14.7203 22.6991 15.4999 21.9195C16.2796 21.1399 17.2728 20.6088 18.3541 20.3934C19.4354 20.1781 20.5563 20.288 21.5751 20.7095C22.594 21.1309 23.465 21.8448 24.0783 22.7611C24.6915 23.6774 25.0194 24.7549 25.0205 25.8574C25.0201 27.3379 24.4318 28.7576 23.385 29.8045C22.3381 30.8514 20.9184 31.4397 19.4379 31.4401ZM19.4379 29.4725C20.1515 29.472 20.849 29.26 21.4421 28.8632C22.0353 28.4664 22.4975 27.9027 22.7704 27.2433C23.0432 26.5839 23.1145 25.8584 22.9752 25.1585C22.8358 24.4587 22.4921 23.8158 21.9875 23.3112C21.4829 22.8066 20.84 22.4629 20.1401 22.3235C19.4403 22.1842 18.7148 22.2554 18.0554 22.5283C17.396 22.8012 16.8323 23.2634 16.4355 23.8565C16.0387 24.4497 15.8267 25.1472 15.8262 25.8608C15.8281 26.8167 16.2087 27.733 16.8847 28.4089C17.5607 29.0849 18.4769 29.4655 19.4328 29.4674L19.4379 29.4725Z"
                    fill="#FFDE17"
                  />
                </g>
                <g>
                  <path
                    d="M19.4302 40.6178C18.3273 40.6177 17.2491 40.2905 16.332 39.6777C15.415 39.0648 14.7002 38.1938 14.2782 37.1747C13.8561 36.1557 13.7457 35.0344 13.961 33.9526C14.1762 32.8708 14.7073 31.8771 15.4872 31.0972C16.2672 30.3173 17.2608 29.7861 18.3426 29.5709C19.4244 29.3557 20.5457 29.4661 21.5648 29.8882C22.5838 30.3102 23.4548 31.0249 24.0677 31.942C24.6805 32.8591 25.0077 33.9372 25.0078 35.0402C25.0061 36.519 24.4179 37.9366 23.3723 38.9822C22.3266 40.0279 20.909 40.616 19.4302 40.6178ZM19.4319 38.6519C20.1462 38.6519 20.8445 38.4401 21.4384 38.0432C22.0323 37.6464 22.4952 37.0823 22.7685 36.4224C23.0419 35.7624 23.1134 35.0363 22.974 34.3357C22.8347 33.6351 22.4907 32.9916 21.9856 32.4865C21.4805 31.9814 20.837 31.6375 20.1364 31.4981C19.4359 31.3587 18.7097 31.4303 18.0498 31.7036C17.3898 31.9769 16.8258 32.4398 16.4289 33.0337C16.0321 33.6277 15.8203 34.3259 15.8202 35.0402C15.8215 35.9977 16.2024 36.9156 16.8794 37.5927C17.5565 38.2698 18.4744 38.6507 19.4319 38.6519Z"
                    fill="#FFDE17"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_20275_40683">
                  <rect
                    width="42.6396"
                    height="42.6396"
                    fill="white"
                    transform="translate(30.5 60.9531) rotate(-135)"
                  />
                </clipPath>
              </defs>
            </svg>

            <Text className={s.portfolioValue}>
              {formatCurrency(userPoint?.portfolioFt, 2, 4)} ETH
            </Text>
            <Text className={s.portfolioLabel}>Your Friend Tech Portfolio</Text>
          </Flex>
        </ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Text className={s.pointValue}>
            Received{' '}
            <b>{formatCurrency(userPoint?.pointFriendtechClaimable, 2, 2)}</b>{' '}
            Point{labelAmountOrNumberAdds(userPoint?.pointFriendtechClaimable)}
          </Text>
          <Button className={s.btnShare} onClick={onShareToTw}>
            Share to <IcX />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalJoinFromFT;
