import ReferralEarning from '@/modules/AlphaPWA/OverlayPages/ReferralEarning';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const RefferalModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px 20px"
        maxW={'560px'}
        bg="#212121"
        textAlign={'center'}
      >
        <Flex justifyContent={'flex-end'}>
          <ModalCloseButton />
        </Flex>
        <ModalBody>
          <Box h="86dvh">
            <ReferralEarning />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RefferalModal;
