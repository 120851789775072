import { APP_ENV } from '@/configs';
import { DEFAULT_GAS_PRICE, TOKEN_ICON_DEFAULT } from '@/constants/common';
import { ROUTE_PATH } from '@/constants/route-path';
import { UUID } from '@/constants/storage-key';
import { BASE_SYMBOL } from '@/contracts/configs';
import { TokenDetail } from '@/interfaces/api/player-share';
import { IToken } from '@/interfaces/tokens';
import { ITradeKey } from '@/services/interfaces/trade';
import localStorage from '@/utils/localstorage';
import { getParsedEthersError } from '@enzoferey/ethers-error-parser';
import { AddressZero } from '@ethersproject/constants';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { last, random } from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import web3 from 'web3';
import { ERROR_CODE, ERROR_MESSAGE } from './error';
import { compareString } from './string';
import { SupportPlatform } from './supportPlatform';
import tokenIcons from './tokenIcons';

export const getTokenIconUrl = (token: IToken) => {
  let url = TOKEN_ICON_DEFAULT;
  if (token?.logo) {
    url = token?.logo;
  } else if (tokenIcons?.[token?.symbol?.toLowerCase()]) {
    url = tokenIcons?.[token?.symbol?.toLowerCase()];
  }
  return url;
};

export function shortenAddress(
  address: string,
  charsStart = 4,
  charsEnd?: number
): string {
  if (!address) return '';

  return `${address.substring(0, charsStart + 2)}...${address.substring(
    42 - (charsEnd || charsStart)
  )}`;
}

export const getDefaultGasPrice = () => {
  return random(35, 45) * DEFAULT_GAS_PRICE;
};

export const checkBalanceTokenIsApprove = (
  required: any = 0,
  amount: any = 0
) => {
  return required > 0 && new BigNumber(required).minus(amount).toNumber() >= 0;
};

export const shortCryptoAddress = (address = '', toLength?: number) => {
  if (toLength) {
    if (address.length <= toLength) return address;
    const x = Math.floor(toLength / 2);
    return `${address?.substr(0, x)}...${address?.substr(address?.length - x)}`;
  }
  if (address.length <= 16) return address;
  return `${address?.substr(0, 8)}...${address?.substr(address?.length - 8)}`;
};

export const hexToBas64 = (data: string) => {
  let bytes = ethers.utils.arrayify(data);
  const pfpData =
    'data:image/png;base64,' + Buffer.from(bytes).toString('base64');
  return pfpData;
};

export const formatAmountToContract = (amount: any, _decimals = 18) => {
  return web3.utils.toWei(amount).toString();
};

export const formatAmountToClient = (amount: any, _decimals = 18) => {
  if (amount) {
    return web3.utils.fromWei(amount).toString();
  }
  return '0';
};

export const getUuid = (): string => {
  let uuidText = localStorage.get(UUID) as string;
  if (!uuidText) {
    uuidText = uuidv4();
    localStorage.set(UUID, uuidText);
  }
  return uuidText;
};

enum ETwitterImageProfileSize {
  normal = 'normal',
  medium = '200x200',
  high = '400x400',
}

export const getUrlAvatarTwitter = (
  url: string,
  size: 'normal' | 'medium' | 'high' = 'normal',
  is_tw_avatar = false
) => {
  if (url) {
    if (!url.includes('pbs.twimg.com') && !url.includes('abs.twimg.com')) {
      return url;
    }

    if (url?.includes('default_profile_normal.png')) {
      return undefined;
    }

    const urls = url?.split('/');

    let finalUrl = urls.splice(0, urls.length - 1).join('/');

    const lastPartUrl = last(urls)?.split('_');

    if (lastPartUrl?.[0] === 'default') {
      return url;
    }

    finalUrl += `/${lastPartUrl
      ?.splice(0, lastPartUrl.length - 1)
      ?.join('_')}_${ETwitterImageProfileSize[size]}.${last(
      last(lastPartUrl)?.split('.')
    )}`;

    // console.log('xQKHFNjT_normal.png', finalUrl);

    return finalUrl;
  }
  return undefined;
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const settingMomentFromNow = () => {
  moment.locale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%ds',
      ss: '%ss',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1M',
      MM: '%dM',
      y: '1Y',
      yy: '%dY',
    },
  });
};

export const getErrorMessageContract = (error: any) => {
  const parsedEthersError = getParsedEthersError(error);

  let errorCode: any = parsedEthersError?.errorCode;

  const unknownError = compareString(
    parsedEthersError?.errorCode,
    ERROR_CODE.UNKNOWN_ERROR
  );

  const invalidArgumentError = compareString(
    parsedEthersError?.errorCode,
    ERROR_CODE.INVALID_HASH
  );

  const invalidSupply = compareString(
    parsedEthersError?.context,
    ERROR_CODE.NM_IP
  );

  if (unknownError || invalidArgumentError || invalidSupply) {
    const AKF_BBP = error?.message?.includes?.(ERROR_CODE.AKF_BBP);
    const INVALID_HASH = error?.message?.includes?.(ERROR_CODE.INVALID_HASH);
    const NM_IP = error?.message?.includes?.(ERROR_CODE.NM_IP);
    if (AKF_BBP || INVALID_HASH) {
      errorCode = ERROR_CODE.AKF_BBP;
    }
    if (NM_IP) {
      errorCode = ERROR_CODE.NM_IP;
    }
    const AKF_IBB = error?.message?.includes?.(ERROR_CODE['AKF_IBB']);
    if (AKF_IBB) {
      errorCode = ERROR_CODE['AKF_IBB'];
    }
    const ENOUGH_BTC_BUY_TC = error?.message?.includes?.(
      ERROR_CODE['ENOUGH_BTC_BUY_TC']
    );
    if (ENOUGH_BTC_BUY_TC) {
      errorCode = ERROR_CODE['ENOUGH_BTC_BUY_TC'];
    }
    const AKT_IK = error?.message?.includes?.(ERROR_CODE['AKT_IK']);
    if (AKT_IK) {
      errorCode = ERROR_CODE['AKT_IK'];
    }
    const PT_NEF = error?.message?.includes?.(ERROR_CODE['PT_NEF']);
    if (PT_NEF) {
      errorCode = ERROR_CODE['PT_NEF'];
    }
  }

  return ERROR_MESSAGE?.[errorCode] || errorCode;
};

export const parseFee = (fee: number = 0) => {
  return fee / 1000000;
};

export const formatFeePercent = (fee: number = 0) => {
  return fee * 100;
};

export const isPlatformKey = (tradeKey: ITradeKey): SupportPlatform => {
  let platform = SupportPlatform.NB;
  if (
    tradeKey?.ft_trader_address &&
    tradeKey.ft_trader_address !== AddressZero &&
    compareString(tradeKey.ft_trader_address, tradeKey.address) &&
    compareString(tradeKey.base_token_symbol, BASE_SYMBOL)
  ) {
    platform = SupportPlatform.FT;
  }

  return platform;
};

export const isPlatformTradeKey = (
  tradeKey: TokenDetail | null
): SupportPlatform => {
  let platform = SupportPlatform.NB;
  if (
    tradeKey &&
    compareString(tradeKey.ftTraderAddress, tradeKey.address) &&
    tradeKey.baseTokenSymbol === BASE_SYMBOL
  ) {
    platform = SupportPlatform.FT;
  }
  return platform;
};

export const getChainID = (chainId: number) => `0x${chainId.toString(16)}`;

export const getAvatarName = (name: string): string => {
  let words = '';
  if (name && name.split(' ').length > 0) {
    name.split(' ').length = 21;
    const arrName = name.split(' ');
    words = arrName[0].charAt(0);
    if (arrName[1]) {
      words += arrName[1].charAt(0);
    } else if (arrName[0].charAt(1)) {
      words += arrName[0].charAt(1);
    }
    words = words.toUpperCase();
  }
  return words;
};

export const getLink = (referralCode: string) => {
  if (referralCode) {
    if (APP_ENV === 'production') {
      return `https://newbitcoincity.com?referral=${referralCode}`;
    }
    return `${window.location.origin}?referral=${referralCode}`;
  }
  return '';
};

export const isUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const convertIpfsToHttp = (uri: string, base?: string): string => {
  if (!uri) return '';
  if (isUrl(uri)) {
    return String(uri)
      .replace('https://ipfs.io/ipfs/', 'https://cloudflare-ipfs.com/ipfs/')
      .replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/');
  } else {
    const url = `${base || 'https://cloudflare-ipfs.com/ipfs'}/${uri}`;
    return url;
  }
};

export const isVideoUrl = (url: string): boolean => {
  const videoExtensions = [
    '.mp4',
    '.avi',
    '.mkv',
    '.mov',
    '.wmv',
    '.flv',
    '.webm',
    '.m4v',
  ];

  const urlParts = url.split('.');
  if (urlParts.length > 1) {
    const fileExtension = `.${urlParts[urlParts.length - 1]}`;

    return videoExtensions.includes(fileExtension.toLowerCase());
  }

  return false;
};

export const editDistance = (s1: string, s2: string) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = new Array();
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

export const similarity = (s1: string, s2: string) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) /
    parseFloat(`${longerLength}`)
  );
};

export const isInValidAmount = (amount?: string | number) => {
  if (!amount) return true;
  const amountBN = new BigNumber(amount);

  return (
    amountBN.isLessThan(0) ||
    amountBN.isNaN() ||
    amountBN.isNegative() ||
    !amountBN.isFinite()
  );
};

export const formatMaxDecimals = (params: {
  value: any;
  maxDecimals?: number;
}) => {
  const value = params.value;
  const maxDecimals = params.maxDecimals || 3;
  if (
    value &&
    value.toString().includes('.') &&
    value.toString().split('.')[1]?.length > maxDecimals
  ) {
    return undefined;
  }
  return value;
};

export const checkRateValid = async (
  rate: string | number,
  symbol?: string
) => {
  try {
    // console.log('[WALLET] [checkRateValid] 2 ', {
    //   rate,
    //   symbol,
    // });
    const rateBN = new BigNumber(rate);
    if (rateBN.isZero() || isInValidAmount(rate)) {
      throw Error(`[WALLET] Rate ${symbol} is Invalid`);
    }
  } catch (error) {
    console.log(`[WALLET] Rate ${symbol} is Invalid`);
    throw error;
  }
};

export const getUrlToSwap = (
  to_address = '',
  from_address = '',
  default_tab = 0,
  is_pass = false
) => {
  return `${ROUTE_PATH.ALPHA_MOBILE_SWAP}?from_token=${from_address}&to_token=${to_address}&is_pass=${is_pass}`;
};

export const getShareLink = (postFix?: string) => {
  if (APP_ENV === 'production') {
    return `https://alpha.wtf${postFix}`;
  }
  return `${window.location.origin}${postFix}`;
};
