import React, { useContext, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BTC_L2_ADDRESS } from '@/configs';
import s from './styles.module.scss';
import BTCInput from '@/components/BTCInput';
import { AssetsContext } from '@/contexts/assets-context';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '@/utils';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import toast from 'react-hot-toast';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import TipImg from '../TipImg.png';
import Image from 'next/image';
import { submitTweetTip } from '@/services/alpha_tweet';

export const TIP_MODAL_ID: string = 'TIP_MODAL_ID';

interface IProps {
  onClose: () => void;
  postId: number;
  tipToAddress: string;
}

const TipModal: React.FC<IProps> = ({
  onClose,
  postId,
  tipToAddress,
}): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { addressL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const contract = new CContract();
  const gameWalletProvider: IWalletContext = useContext(WalletContext);
  const [errorMessage, setErrorMessage] = useState('');
  const gaEventTracker = useAnalyticsEventTracker();

  const validateForm = (
    values: Record<string, string>
  ): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!values.amount) {
      errors.amount = 'Amount is required.';
    } else {
      const amountBn = new BigNumber(values.amount);
      const balanceBn = new BigNumber(balanceL2.amountBTCFormatted);
      if (amountBn.isLessThanOrEqualTo(0)) {
        errors.amount = 'Amount must be greater than 0.';
      }
      if (amountBn.isGreaterThan(balanceBn)) {
        errors.amount = `Amount must be less than ${formatCurrency(
          balanceL2.amountBTCFormatted,
          0,
          4
        )} BTC.`;
      }
    }

    return errors;
  };

  const handleSubmit = async (values: Record<string, string>) => {
    if (loading || !addressL2 || !gameWalletProvider.gameWallet) {
      toast.error('Unauthorized');
      return;
    }

    if (!tipToAddress || !postId) {
      toast.error('Something wrong. Please try again.');
      return;
    }

    try {
      setLoading(true);
      const amount = new BigNumber(values.amount).multipliedBy(1e18).toFixed(0);
      const tx = await contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .connect(gameWalletProvider.gameWallet)
        .transfer(tipToAddress, amount);
      await tx.wait();

      if (!tx?.hash) {
        toast.error('Transaction not found.');
        gaEventTracker(AlphaActions.SendTipFail, addressL2);
        return;
      }

      await submitTweetTip({
        postId,
        txHash: tx.hash,
        address: addressL2,
        amount: values.amount,
      });
      // toast.remove();
      // toast.success('Tips were successfully delivered. Thank you.');
      gaEventTracker(AlphaActions.SendTipSuccess, addressL2);

      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      gaEventTracker(AlphaActions.ClickSendTip, addressL2);
    }
  };

  return (
    <>
      <div className={s.imgWrapper}>
        <Image src={TipImg} height={189} width={189} alt="tip" />
      </div>
      <h6 className={s.title}>Tips</h6>
      <p className={s.description}>
        Give tips to show your appreciation and encourage creators to keep
        creating the content you enjoy.
      </p>
      <Formik
        key="create"
        initialValues={{
          amount: '',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form className="form" onSubmit={handleSubmit}>
              <BTCInput
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus={false}
                setFieldValue={setFieldValue}
                name="amount"
                label="Tips amount"
                errorMsg={
                  errors.amount && touched.amount ? errors.amount : undefined
                }
              />

              <Button
                className={s.submitButton}
                type="submit"
                isDisabled={loading}
              >
                {loading ? 'Processing...' : 'Give tips'}
              </Button>
              {errorMessage && <p className={s.errorMessage}>{errorMessage}</p>}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default React.memo(TipModal);
