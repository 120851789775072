import React from 'react';

import styled from 'styled-components';
import { useWindowSize } from '@trustless-computer/dapp-core';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import px2rem from '@/utils/px2rem';
import { HEADER_COLUMNS } from './Page.contansts';
import { Item } from './Page.types';
import { AutoSizer, List } from '@/components/ReactVirtualized';
import TableCell from './Page.TableCell';

const AutoSizerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
  padding-bottom: 10px;
  min-height: 350px;
`;

const Row = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1px;

  /* border-bottom: 0.5px solid #8e8585; */
`;

type Props<T> = {
  dataSource: T[];
};

const HistoryTableContent = React.memo((props: Props<Item>) => {
  const { dataSource = [] } = props;
  const { mobileScreen } = useWindowSize();

  const onCopyStr = (str: string) => {
    copy(str || '');
    toast.success('Copied');
  };

  const renderAddressCell = (item: Item) => {
    const { btcAddress, btcAddressShorten, explorer, isLasted } = item;
    if (!btcAddress || btcAddress.length < 1)
      return <TableCell text="TOTAL"></TableCell>;
    else
      return (
        <TableCell
          style={{
            justifyContent: mobileScreen ? 'left' : 'left',
            width: '90%',
          }}
        >
          <a
            href={explorer}
            target="_blank"
            style={{
              color: '#F8F0AC',
              fontSize: isLasted ? px2rem(20) : px2rem(16),
              display: 'contents',
            }}
          >
            {mobileScreen ? btcAddressShorten : btcAddress}
          </a>
        </TableCell>
      );
  };

  const renderRow = ({
    index,
    key,
    style,
  }: {
    index: any;
    key: any;
    style: any;
  }) => {
    const item = dataSource[index];
    const { btcAddress = '', amountStr, isLasted } = item;
    if (!item) return;
    return (
      <Row
        key={key}
        style={{
          ...style,
          gridTemplateColumns: mobileScreen ? '3fr 1fr' : '3fr 1fr',
        }}
      >
        {/* <TableCell
          text={isLasted ? 'TOTAL' : index + 1}
          style={{
            fontSize: isLasted
              ? mobileScreen
                ? px2rem(18)
                : px2rem(20)
              : px2rem(16),
            fontWeight: isLasted ? 700 : 500,
          }}
        /> */}
        {renderAddressCell(item)}
        <TableCell
          text={amountStr}
          style={{
            fontSize: isLasted
              ? mobileScreen
                ? px2rem(20)
                : px2rem(22)
              : px2rem(16),
            fontWeight: isLasted ? 700 : 500,
            justifyContent: 'flex-end',
          }}
        />
      </Row>
    );
  };

  return (
    <AutoSizerWrapper style={{}}>
      <AutoSizer>
        {({ height, width }: { height: any; width: any }) => (
          <List
            height={height}
            width={width}
            rowCount={dataSource.length}
            rowHeight={50}
            rowRenderer={props => {
              return renderRow(props);
            }}
          />
        )}
      </AutoSizer>
    </AutoSizerWrapper>
  );
});

export default HistoryTableContent;
