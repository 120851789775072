import React from 'react';

const IcNB = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1437 10.2845C21.1827 10.1754 21.2194 10.0649 21.2535 9.95297C22.4385 6.07428 20.2545 1.96949 16.3759 0.784689C12.4971 -0.400102 8.39234 1.78374 7.20756 5.66246C7.15651 5.82953 7.11173 5.99702 7.07306 6.16469C6.03716 6.21996 4.99659 6.49673 4.02041 7.01608C0.440255 8.92081 -0.917936 13.3672 0.986797 16.9474C2.8338 20.419 7.07076 21.8013 10.5903 20.1451C11.4971 21.5665 12.8953 22.6846 14.6341 23.2157C18.5128 24.4004 22.6177 22.2166 23.8025 18.338C24.7364 15.2801 23.5769 12.0819 21.1437 10.2845Z"
        fill="white"
      />
      <g opacity="0.9">
        <path
          d="M10.918 19.9807C7.3379 21.8854 2.89152 20.5273 0.986789 16.947C-0.917934 13.3669 0.440265 8.92058 4.0204 7.01585C7.60056 5.11112 12.0469 6.46931 13.9516 10.0495C15.8563 13.6296 14.4982 18.076 10.918 19.9807Z"
          fill="url(#paint0_linear_18828_26362)"
        />
      </g>
      <g opacity="0.9">
        <path
          d="M18.9241 9.16945C22.8029 10.3543 24.9867 14.459 23.802 18.3378C22.6172 22.2164 18.5123 24.4002 14.6336 23.2155C10.7549 22.0307 8.57108 17.926 9.75589 14.0472C10.9407 10.1685 15.0455 7.98467 18.9241 9.16945Z"
          fill="url(#paint1_linear_18828_26362)"
        />
      </g>
      <g opacity="0.9">
        <path
          d="M21.253 9.95273C20.0683 13.8314 15.9635 16.0153 12.0848 14.8305C8.20611 13.6457 6.02226 9.54091 7.20705 5.6622C8.39185 1.78349 12.4966 -0.40035 16.3753 0.784449C20.254 1.96924 22.438 6.07402 21.253 9.95273Z"
          fill="url(#paint2_linear_18828_26362)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_18828_26362"
          x1="0.977503"
          y1="16.9297"
          x2="13.9424"
          y2="10.0321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006CEA" />
          <stop offset="1" stopColor="#E6F1FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18828_26362"
          x1="20.2279"
          y1="22.6755"
          x2="13.3298"
          y2="9.70951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00ED7B" />
          <stop offset="1" stopColor="#E7FFF3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18828_26362"
          x1="12.1253"
          y1="14.8741"
          x2="16.4159"
          y2="0.828028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF2E1" />
          <stop offset="1" stopColor="#F7931A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IcNB;
