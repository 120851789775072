import React from 'react';
import toast from 'react-hot-toast';
import { ToastPending } from './ToastMessage.styled';
import { IconButton } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import useOpenDepositModal from '@/hooks/useOpenDepositModal';
import { compareString } from '@/utils';

interface IProps {
  id: string;
  message: string;
  url?: string;
  linkText?: string;
}

const ToastMessage: React.FC<IProps> = ({
  id,
  url,
  message,
  linkText,
}: IProps): React.ReactElement => {
  const { handleOpenTopup } = useOpenDepositModal();

  const onHandleLink = () => {
    if (!url) {
      return;
    }
    if (compareString(url, 'deposit')) {
      return handleOpenTopup();
    }
    return window.open(url, '_blank');
  };

  return (
    <ToastPending>
      <div>
        {message}
        {url && (
          <>
            <br />
            <a
              // href={url}
              onClick={onHandleLink}
              target="_blank"
              rel="noopener noreferrer"
              className="wallet-link"
              style={{ cursor: 'pointer' }}
            >
              {linkText || 'View transaction'}
            </a>
          </>
        )}
      </div>
      <IconButton
        onClick={() => toast.dismiss(id)}
        icon={<MdClose color="black" />}
        aria-label={''}
        background={'transparent'}
      />
    </ToastPending>
  );
};

export default ToastMessage;
