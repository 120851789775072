import { PERP_API_URL } from '@/configs';
import {
  IBullBearAddress,
  IDetailBullBear,
  IGetBullBearAddressParams,
  IGetListBullBearParams,
  IListBullBearResponse,
  IRewardBullBear,
  ISetupEventBullBearParams,
  ITokenBullBear,
  IUserJoinBullBear,
} from '../interfaces/bullBear';
import CApiClient from './apiClient';
import axios from 'axios';
import { IKLine, IKLineData } from '../interfaces/binance';

class CBullBearAPI {
  private prefix = `${PERP_API_URL}/api/alpha-keys/bull-bear`;

  private apiClient = new CApiClient().api;

  private addressL2: string = localStorage.getItem('ADDRESS_STORAGE') || '';

  private getUrl(url: string) {
    return `${this.prefix}/${url}`;
  }

  /**
   *
   */
  public setupEventBullBear = async (
    params: ISetupEventBullBearParams
  ): Promise<IDetailBullBear> => {
    try {
      const response: IDetailBullBear = await this.apiClient.post(
        `${this.getUrl('setup-event')}`,
        {},
        {
          params: {
            address: this.addressL2,
            network: 'nos',
            ...params,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getBullAndBearAddress = async (
    params: IGetBullBearAddressParams
  ): Promise<IBullBearAddress> => {
    try {
      const response: IBullBearAddress = await this.apiClient.get(
        `${this.getUrl('bull-bear-address')}`,
        {
          params: {
            address: this.addressL2,
            network: 'nos',
            ...params,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getListBullAndBear = async (
    params: IGetListBullBearParams
  ): Promise<IListBullBearResponse> => {
    try {
      const response: IListBullBearResponse = await this.apiClient.get(
        `${this.getUrl('list')}`,
        {
          params: {
            address: this.addressL2,
            network: 'nos',
            ...params,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getDetailBullAndBear = async (
    id: number
  ): Promise<IDetailBullBear> => {
    try {
      const response: IDetailBullBear = await this.apiClient.get(
        `${this.getUrl(`detail/${id}`)}`,
        {
          params: {
            address: this.addressL2,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public freeJoinBullAndBear = async (
    event_id: number,
    address: string = this.addressL2
  ): Promise<IUserJoinBullBear[]> => {
    try {
      const response: IUserJoinBullBear[] = await this.apiClient.post(
        `${this.getUrl(`join-free/${event_id}`)}`,
        {},
        {
          params: {
            address,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getUserJoinBullAndBear = async (
    event_id: number
  ): Promise<IUserJoinBullBear[]> => {
    try {
      const response: IUserJoinBullBear[] = await this.apiClient.get(
        `${this.getUrl(`join/${event_id}`)}`,
        {
          params: {
            network: 'nos',
            address: this.addressL2,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public scanCreate = async (tx_hash: string): Promise<IBullBearAddress> => {
    try {
      const response: IBullBearAddress = await this.apiClient.get(
        `${this.getUrl('scan-hash')}`,
        {
          params: {
            network: 'nos',
            tx_hash,
            address: this.addressL2,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public joinCreate = async (tx_hash: string): Promise<IBullBearAddress> => {
    try {
      const response: IBullBearAddress = await this.apiClient.get(
        `${this.getUrl('scan-join-hash')}`,
        {
          params: {
            network: 'nos',
            tx_hash,
            address: this.addressL2,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getBullAndBearRewards = async (
    event_id: string,
    address: string
  ): Promise<IRewardBullBear[]> => {
    try {
      const response: IRewardBullBear[] = await this.apiClient.get(
        `${this.getUrl('winner-reward/' + event_id)}`,
        {
          params: {
            network: 'nos',
            address,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getJoinBullAndBear = async (
    event_id: string,
    join_id: string
  ): Promise<IUserJoinBullBear> => {
    try {
      const response: IUserJoinBullBear = await this.apiClient.get(
        `${this.getUrl('join/' + event_id + '/' + join_id)}`,
        {
          params: {
            address: this.addressL2,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getPriceKLines = async (
    symbol: string = 'btcusdt',
    start_time: string,
    end_time: string,
    limit: number = 500,
    interval: string = '1s'
  ): Promise<IKLine[]> => {
    try {
      const _symbol = `${symbol}`;
      const response = await axios.get(
        `https://api.binance.com/api/v3/uiKlines`,
        {
          params: {
            symbol: _symbol.toUpperCase(),
            interval: interval,
            startTime: start_time,
            endTime: end_time,
            limit: limit,
          },
        }
      );
      const data: IKLine[] = response?.data?.map(
        (d: any) =>
          ({
            stream: `${_symbol}@kline_${interval}`,
            data: {
              E: null,
              e: 'kline',
              s: _symbol.toUpperCase(),
              k: {
                t: d[0],
                T: d[6],
                s: _symbol.toUpperCase(),
                i: interval,
                f: 100,
                L: 200,
                o: d[1],
                c: d[4],
                h: d[2],
                l: d[3],
                v: d[5],
                n: d[8],
                x: false,
                q: d[7],
                V: d[9],
                Q: d[10],
                B: d[11], // Ignore
              },
            },
          } as unknown as IKLine)
      );

      return data;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   */
  public getSymbolsBullAndBear = async (): Promise<string[]> => {
    try {
      const response: string[] = await this.apiClient.get(
        `${this.getUrl('support-symbols')}`,
        {
          params: {
            address: this.addressL2,
            network: 'nos',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default CBullBearAPI;
