import React, { useMemo } from 'react';
import s from './styles.module.scss';
import { IMetaData } from '@/interfaces/api/alpha';
import { getDomainFromUrl } from '@/utils/url';

interface IProps extends IMetaData {}

const MetaDataPreview: React.FC<IProps> = ({
  title,
  description,
  image,
  url,
}: IProps): React.ReactElement => {
  return (
    <>
      {url && title && (
        <a
          className={s.metaDataPreview}
          href={url}
          rel="noopener noreferrer nofollow"
          target={'_blank'}
          role="link"
        >
          <div className={s.contentWrapper}>
            <div className={s.imageWrapper}>
              {image && <img className={s.image} src={image} alt={title} />}
            </div>
            <div className={s.content}>
              <p className={s.url}>{getDomainFromUrl(url)}</p>
              {title && <p className={s.title}>{title}</p>}
              {description && <p className={s.description}>{description}</p>}
            </div>
          </div>
        </a>
      )}
    </>
  );
};

export default React.memo(MetaDataPreview);
