import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import WheelPrize from './WheelPrize';
import CountDown from './CountDown';
import Participants from './Participants';
import { CDN_URL_ICONS } from '@/configs';
import Wheel from './Wheel';
import useCountdown from '@/hooks/useCountdown';
import {
  getPrizeWheelDetail,
  getPrizeWheelJoining,
  joinPrizeWheel,
} from '@/services/prize-wheel';
import { WalletContext } from '@/contexts/wallet-context';
import { compareString, formatCurrency } from '@/utils';
import { IPostData } from '@/interfaces/api/post';
import { ROUTE_PATH } from '@/constants/route-path';
import { closeModal, openModal } from '@/state/modal';
import { useDispatch } from 'react-redux';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { getPlayerPoolProfile } from '@/services/player-share';
import {
  MAX_DECIMAL,
  MIN_DECIMAL,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import BigNumber from 'bignumber.js';
import ShareTwitterAfterJoinModal, {
  SHARE_TWITTER_AFTER_JOIN_MODAL,
} from '@/modules/AlphaPWA/Home/PostItem/WheelPost/ShareTwitterAfterJoinModal';
import useAsyncEffect from 'use-async-effect';
import { getPostDetail } from '@/services/alpha_tweet';
import { PostItemContext } from '@/contexts/post-item';

interface IProps {
  wheelId: string;
  data: IPostData;
}

const NOT_HOLD_KEY_MODAL = 'NOT_HOLD_KEY_MODAL';

const WheelPost: React.FC<IProps> = ({
  wheelId,
  data,
}: IProps): React.ReactElement => {
  const { addressL2 } = useContext(WalletContext);

  const [isStopped, setIsStopped] = useState(true);

  const [wheelDetail, setWheelDetail] = useState<any>();
  const [usersJoining, setUsersJoing] = useState<any[]>([]);

  const intervalRef = useRef<any>();
  const intervalJoiningRef = useRef<any>();
  const dispatch = useDispatch();
  const router = useRouter();
  const { isParentRepost } = useContext(PostItemContext);

  const [wheelDetailId, setWheelDetailId] = useState<string>(wheelId);
  const [isFetchPostDetail, setIsFetchPostDetail] = useState<boolean>(
    Boolean(wheelId)
  );

  const didSpinRef = useRef<boolean>(false);
  const wheelRef = useRef<
    HTMLDivElement & {
      startSpin: () => void;
      stopSpin: () => void;
    }
  >(null);

  const [ExpiredAt, setExpiredAt] = useState<string>();

  useEffect(() => {
    if (!ExpiredAt && !!wheelDetail?.ExpiredAt) {
      setExpiredAt(wheelDetail?.ExpiredAt);
    }
  }, [wheelDetail?.ExpiredAt, ExpiredAt]);

  useAsyncEffect(async () => {
    if (isParentRepost && addressL2 && data?.id) {
      setIsFetchPostDetail(false);
      const res: any = await getPostDetail(Number(data?.id), addressL2);
      setWheelDetailId(res?.prize_wheel_event?.ID || '');
      handleGetWheelDetail(res?.prize_wheel_event?.ID);
      setIsFetchPostDetail(true);
    }
  }, [data, isParentRepost, addressL2]);

  const { days, hours, minutes, seconds, ended, countingEnded } = useCountdown(
    ExpiredAt as string
  );

  const totalReward = useMemo(() => {
    if (wheelDetail?.Amount && wheelDetail?.TreasuryAmount) {
      const amountBN = new BigNumber(wheelDetail?.Amount);
      const treasuryAmountBN = new BigNumber(wheelDetail?.TreasuryAmount);
      const totalBN = amountBN.plus(treasuryAmountBN);
      return totalBN.toString();
    }

    return '0';
  }, [wheelDetail?.Amount, wheelDetail?.TreasuryAmount]);

  const gotoWinner = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    router.push(
      `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterName}`
    );
  };

  const gotoCreator = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    router.push(
      `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${wheelDetail?.Twitter?.Address}`
    );
  };
  const isTimeLeft = ended && countingEnded;

  const isWheelSuccess = useMemo(() => {
    return wheelDetail && wheelDetail.Status === 'done';
  }, [wheelDetail]);

  const hasJoined = useMemo(() => {
    return usersJoining.find(item => {
      return compareString(item.user.address, addressL2);
    });
  }, [usersJoining, addressL2]);

  const isOwnerWheel = useMemo(() => {
    return wheelDetail && compareString(wheelDetail?.TwitterAddress, addressL2);
  }, [wheelDetail, addressL2]);

  useEffect(() => {
    handleGetWheelDetail();
  }, [addressL2]);

  useEffect(() => {
    if (isWheelSuccess) {
      //TODO: handle stop spin

      setTimeout(() => {
        if (wheelRef?.current?.stopSpin) {
          wheelRef.current.stopSpin();
        }
      }, 5000);

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [isWheelSuccess]);

  if (window) {
    (window as any).ref = wheelRef?.current;
  }

  useEffect(() => {
    if (isTimeLeft && wheelDetail && !isWheelSuccess) {
      //TODO: handle spin
      if (
        wheelRef?.current?.startSpin &&
        didSpinRef.current === false &&
        (hasJoined || isOwnerWheel)
      ) {
        didSpinRef.current = true;
        wheelRef.current.startSpin();
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      const interval = setInterval(handleGetWheelDetail, 5000);
      intervalRef.current = interval;
      return () => {
        clearInterval(interval);
      };
    }
  }, [isTimeLeft, wheelDetail, isWheelSuccess, isOwnerWheel]);

  useEffect(() => {
    handleGetUsersJoining();
    if (intervalJoiningRef.current) {
      clearInterval(intervalJoiningRef.current);
    }
    const interval = setInterval(handleGetUsersJoining, 15000);
    intervalJoiningRef.current = interval;
    return () => {
      clearInterval(interval);
    };
  }, [addressL2, isWheelSuccess]);

  const handleGetWheelDetail = useCallback(
    async (wheelIdInput?: string) => {
      if (!addressL2) return;
      try {
        const data = await getPrizeWheelDetail({
          address: addressL2,
          wheelId: wheelIdInput || wheelDetailId,
        });
        if (data) {
          setWheelDetail(data as any);
        }
      } catch (error) {}
    },
    [wheelDetailId, addressL2]
  );

  const handleGetUsersJoining = useCallback(async () => {
    if (!addressL2 || isWheelSuccess) return;
    try {
      const data = await getPrizeWheelJoining({
        address: addressL2,
        wheelId: wheelDetailId,
      });
      setUsersJoing(data as any);
    } catch (error) {}
  }, [wheelDetailId, addressL2]);

  const handleCloseShareTwitter = () => {
    //
    dispatch(closeModal({ id: SHARE_TWITTER_AFTER_JOIN_MODAL }));
  };

  const openShareTwitterForJoinPublicEvent = useCallback(() => {
    try {
      // show popup share
      if (!wheelDetail?.IsHolder) {
        dispatch(
          openModal({
            id: SHARE_TWITTER_AFTER_JOIN_MODAL,
            theme: 'dark',
            modalProps: {
              centered: true,
              zIndex: 9999999,
              className: s.modal_wheel,
            },
            className: s.modal_wheel,
            hideCloseButton: false,
            render: () => (
              <ShareTwitterAfterJoinModal
                onClose={handleCloseShareTwitter}
                wheelCreator={data.user}
                totalReward={totalReward}
                users={usersJoining}
              />
            ),
          })
        );
      }
    } catch (e) {
      //
    }
  }, [wheelDetail, data, totalReward, usersJoining]);

  const joinWheelEvent = useCallback(async (): Promise<void> => {
    if (!addressL2) return;
    try {
      const result = await joinPrizeWheel({
        address: addressL2,
        wheelId: wheelDetailId,
      });

      handleGetUsersJoining();
      openShareTwitterForJoinPublicEvent();
    } catch (error: any) {
      if (error?.code === -1082) {
        showNotAllowNotHolder();
      }
    }
  }, [JSON.stringify(wheelDetail), wheelDetailId, addressL2]);

  const handleBuyKey = useCallback(() => {
    dispatch(closeModal({ id: NOT_HOLD_KEY_MODAL }));
    router.push(`${ROUTE_PATH.ALPHA_TRADE}?address=${data?.user?.address}`);
  }, [data]);

  const showNotAllowNotHolder = useCallback(async () => {
    const playerPoolProfile = await getPlayerPoolProfile(data?.user?.address);

    dispatch(
      openModal({
        id: NOT_HOLD_KEY_MODAL,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: true,
        render: () => (
          <Flex direction={'column'} justifyContent={'center'}>
            <img
              src={`${CDN_URL_ICONS}/IcKeyWheel2.svg`}
              alt="menu-ic"
              style={{ height: '80px' }}
            />
            <Text
              mt={8}
              mb={8}
              fontSize={'18px'}
              fontWeight={400}
              textAlign={'center'}
            >
              Hold at least{' '}
              {formatCurrency(
                wheelDetail?.MinHoldingRequirement ||
                  wheelDetail?.Twitter?.MinHoldingRequirement,
                MIN_DECIMAL,
                MAX_DECIMAL
              )}{' '}
              {data?.user?.twitter_name} key to join the lucky wheel
            </Text>
            <Button onClick={handleBuyKey}>Buy keys</Button>
          </Flex>
        ),
      })
    );
  }, [JSON.stringify(wheelDetail), data]);

  if (wheelDetail === undefined) {
    return <></>;
  }

  const renderPostByType = () => {
    if (data?.activity === 'wheel_reward') {
      const toUser = data?.to_user;
      if (toUser) {
        return (
          <>
            <div className={s.postRewardTitle}>
              Win a Lucky Wheel open by{' '}
              <a
                href={`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${toUser.address}`}
                rel="nofollow"
              >
                {toUser.twitter_name || toUser.twitter_username}
              </a>
            </div>
          </>
        );
      }
    } else if (data?.activity === 'wheel_create') {
      // return (
      //   <>
      //     <div className={s.postRewardTitle}>Open a Lucky Wheel</div>
      //   </>
      // );
    }
    return <></>;
  };

  return (
    <>
      {renderPostByType()}
      {isWheelSuccess && isStopped && (
        <div className={s.notiText}>
          Congrats{' '}
          <span className={s.name} onClick={gotoWinner}>
            {wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterName}
          </span>{' '}
          on winning the{' '}
          <span onClick={gotoCreator} className={s.name}>
            {' '}
            {wheelDetail?.Twitter?.TwitterName}
          </span>{' '}
          Wheel.
        </div>
      )}
      <div
        data-id={data.id}
        className={cs(
          s.mainContent,
          isWheelSuccess && isStopped ? s.noPadding : '',
          !isWheelSuccess ? s.noGap : ''
        )}
        style={{
          height:
            isWheelSuccess && isStopped
              ? usersJoining.length > 0
                ? '439px'
                : '315px'
              : 'auto',
        }}
      >
        <CountDown
          isHide={isWheelSuccess}
          minutes={minutes}
          seconds={seconds}
          hours={hours}
          days={`${days}`}
        />
        <div
          className={cs(
            s.mainContent,
            isWheelSuccess && isStopped ? s.noPadding : ''
          )}
        >
          <Wheel
            url={wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterAvatar}
            ref={wheelRef}
            users={usersJoining}
            isWheelSuccess={isWheelSuccess}
            isStopped={isStopped}
            setIsStopped={setIsStopped}
          />
          {!isTimeLeft && !isWheelSuccess && (
            <Participants
              users={usersJoining}
              rewardPrice={totalReward}
              joinWheelEvent={joinWheelEvent}
              hasJoined={hasJoined}
              isOwnerWheel={isOwnerWheel}
              isHolder={wheelDetail?.IsHolder}
              wheelDetail={wheelDetail}
            />
          )}
        </div>
        {isWheelSuccess && isStopped && (
          <WheelPrize
            twitterAvatar={
              wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterAvatar
            }
            twitterName={wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterName}
            twitterTokenAvatar={
              wheelDetail?.PrizeWheelSpend?.Token?.TwitterUser?.TwitterAvatar
            }
            twitterTokenName={
              wheelDetail?.PrizeWheelSpend?.Token?.TwitterUser?.TwitterName
            }
            rewardAmount={wheelDetail?.PrizeWheelSpend?.Amount}
            rewardPrice={totalReward}
            users={usersJoining}
            data={data}
            wheelDetail={wheelDetail}
          />
        )}
      </div>
    </>
  );
};

export default memo(WheelPost);
