import React from 'react';

type Props = {};

const IconBell = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.81824 20.7754C10.0661 21.1517 10.4035 21.4606 10.8002 21.6743C11.1968 21.888 11.6404 21.9999 12.091 21.9999C12.5416 21.9999 12.9851 21.888 13.3818 21.6743C13.7785 21.4606 14.1159 21.1517 14.3637 20.7754"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.4546 11.0909V8.36364C18.4546 6.6759 17.7841 5.05728 16.5907 3.86387C15.3973 2.67045 13.7787 2 12.0909 2C10.4032 2 8.78456 2.67045 7.59114 3.86387C6.39773 5.05728 5.72728 6.6759 5.72728 8.36364V11.0909C5.72728 14.1364 3 14.8182 3 16.5455C3 18.0455 6.5 19.2727 12.0909 19.2727C17.6818 19.2727 21.1818 18.0455 21.1818 16.5455C21.1818 14.8182 18.4546 14.1364 18.4546 11.0909Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default IconBell;
