import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {},
  thumb: {
    bg: 'var(--alpha-bgFlashing0)',
    _checked: {
      bg: 'var(--chakra-colors-blue-600)',
    },
  },
  track: {
    bg: 'var(--alpha-chakraBox)',
    _checked: {
      bg: 'var(--alpha-listMenu)',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
