import Web3 from 'web3';
import { ceilPrecised } from '@trustless-computer/dapp-core';
import moment from 'moment';
import { BTC_TO_USDT_RATE } from '@/constants/currency';

export const formatEthPrice = (
  price: string | number | null,
  emptyStr?: string,
  precision = 4
): string => {
  if (!price) return emptyStr || '-';
  return ceilPrecised(
    parseFloat(Web3.utils.fromWei(`${price}`, 'ether')),
    precision
  )
    .toString()
    .replace(',', '.');
};

export const formatTCAddress = (address: string): string => {
  const start = address?.substring(2, 8);
  return `${start}`;
};

export const formatEVMAddress = (
  address: string,
  startLen = 4,
  endLen = 4
): string => {
  const start = address.substring(0, startLen);
  const end = address.substring(address.length - endLen);

  return `${start}...${end}`;
};

export const formatUsd = (value: number, fractionDigits = 2): string => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fractionDigits,
  });
};

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertTcToChips = (amountFormated: string) => {
  const blueAmount = Number(amountFormated) / 10;
  const redAmount = Number(amountFormated) % 10;
  const numberAfferComma = Number(
    Number(amountFormated).toFixed(4).split('.')[1].substring(1, 3) || '0'
  );
  return {
    blue: `${Math.trunc(blueAmount)}`,
    red: `${Math.trunc(redAmount * 10)}`,
    white: `${Math.trunc(Number(numberAfferComma))}`,
  };
};

export const kFormatter = (num: number): any => {
  return Math.abs(num) > 999 // @ts-ignore
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
};

export const formatTime = (date: string) => {};

export const formatPublishedDate = (dateString: string) => {
  // const date = moment(dateString);
  const now = moment();

  const duration = moment.duration(now.diff(dateString));

  if (duration.days() > 7) {
    return moment(dateString).format('DD MMM');
  }

  if (duration.days() > 0) {
    return `${duration.days()}d`;
  } else if (duration.hours() > 0) {
    return `${duration.hours()}h`;
  } else if (duration.minutes() > 0) {
    return `${duration.minutes()}m`;
  } else {
    return 'Now';
  }
};

let BTC_PER_USDT_RATE: string = '';
const getBtcUsdtRate = async () => {
  if (!BTC_PER_USDT_RATE) {
    try {
      const response = await fetch(
        'https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT'
      );
      const { price } = await response.json();
      BTC_PER_USDT_RATE = price;
    } catch (error) {
      BTC_PER_USDT_RATE = BTC_TO_USDT_RATE;
      console.log('Fail to get BTCUSDT price: ', error);
    }
  }
};
export const convertBtcToUsdt = async (btc: number | string | undefined) => {
  if (!!btc) {
    if (!BTC_PER_USDT_RATE) {
      await getBtcUsdtRate();
    }
    // return Number((Number(btc) / 1e18) * parseFloat(BTC_PER_USDT_RATE));
    return Number(Number(btc) * parseFloat(BTC_PER_USDT_RATE));
  }
  return 0;
};

const btcFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 4,
  minimumFractionDigits: 2,
});

const satFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});
export const displayBTCValue = (
  bitcoin: number | string | undefined
): string => {
  try {
    const btcAmount = Number(bitcoin);
    if (!!btcAmount) {
      const satAmount = btcAmount * 1e8;
      if (satAmount < 1e4) {
        return `${satFormat.format(Math.floor(satAmount))} sats`;
      }
      return `${btcFormat.format(Number(btcAmount?.toFixed(4)))} BTC`;
    }
    return `${Number(0).toFixed(4)} BTC`;
  } catch (e) {
    return `${Number(0).toFixed(4)} BTC`;
  }
};

export function formatNumberAbbreviation(value: number) {
  const abbreviations = ['', 'k', 'M', 'B', 'T'];

  let newValue = value;
  let abbreviationIndex = 0;

  while (newValue >= 1000 && abbreviationIndex < abbreviations.length - 1) {
    newValue /= 1000;
    abbreviationIndex++;
  }

  return `${newValue.toFixed(value > 1000 ? 2 : 0)}${
    abbreviations[abbreviationIndex]
  }`;
}

export const getBtcUsdtRateV2 = async (symbol = 'BTCUSDT') => {
  if (!BTC_PER_USDT_RATE) {
    try {
      const response = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`
      );
      const { price } = await response.json();
      BTC_PER_USDT_RATE = price;
      return price;
    } catch (error) {
      BTC_PER_USDT_RATE = BTC_TO_USDT_RATE;
      console.log('Fail to get BTCUSDT price: ', error);
    }
  }
  return BTC_PER_USDT_RATE;
};

export function formatString(str: string | undefined, length = 8) {
  if (str?.length && str.length > length) {
    return str.slice(0, length) + '...';
  }
  return str;
}
