import React, { PropsWithRef, useMemo } from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import { CDN_URL_IMAGES } from '@/configs';
import FakeAvatars from '@/modules/AlphaPWA/Home/PostItem/WheelPost/Roulette/FakeAvatars';
import RealAvatars from '@/modules/AlphaPWA/Home/PostItem/WheelPost/Roulette/RealAvatars';
import { chunk } from 'lodash';

interface IProps extends PropsWithRef<any> {
  isSpinning: boolean;
  items: any[];
  url?: string;
  isWheelSuccess: boolean;
}

const ITEMS = Array.from(Array(8)).map((_, idx) => idx);

const Roulette: React.FC<IProps> = React.forwardRef(
  (
    { className, isSpinning, url, items, isWheelSuccess, ...rest }: IProps,
    ref: any
  ) => {
    const chunkItems = useMemo(() => {
      const total = items.length;
      const segment = Math.ceil(total / 8);

      return chunk(items, segment);
    }, [items]);

    return (
      <div className={cs(s.roulette, className)} {...rest} ref={ref}>
        {/* to preload image */}
        <div style={{ display: 'none' }}>
          <FakeAvatars isWheelSuccess={true} />
        </div>
        <img
          src={`${CDN_URL_IMAGES}/lucky-wheel-roulette-wheeling.png`}
          alt="lucky-wheel-roulette-wheeling.png"
        />
        <div className={cs(s.rouletteItems)}>
          <ul className={cs(s.circleContainer, s.round2, 'round2')}>
            {ITEMS.map((item, idx) => (
              <div
                key={`roulette_item_fake_${item}`}
                className={cs(s.userItem)}
              >
                <div
                  className={cs(
                    s.avatar,
                    idx === 0 && !!url ? s.winnerAvatar : ''
                  )}
                >
                  {isSpinning ? (
                    <FakeAvatars isWheelSuccess={isWheelSuccess} />
                  ) : (
                    <RealAvatars
                      isWheelSuccess={isWheelSuccess}
                      url={idx === 0 && !!url ? url : ''}
                      items={chunkItems[idx] || []}
                      isSpinning={isSpinning}
                    />
                  )}
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
    );
  }
);

export default Roulette;
