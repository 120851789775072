import { CDN_URL_IMAGES } from '@/configs';
import { formatCurrency } from '@/utils';
import s from './styles.module.scss';

type Props = {
  balance: string;
};

const TreasuryAmount = (props: Props) => {
  const { balance } = props;
  return (
    <div className={s.body}>
      <div className={s.thumbnail_wrapper}>
        <img
          src={`${CDN_URL_IMAGES}/treasury_thumbnail.png`}
          alt="treasury thumbnail"
        />
      </div>
      <h5>Amount</h5>
      <p className={s.balance}>{formatCurrency(balance, 0, 4)} BTC</p>
      <div className={s.desc}>
        <p className={s.descLine}>
          The treasury accumulates fees from various utilities on the platform,
          such as trading, gifting, and games.
        </p>
        <p className={s.descLine}>
          Initially, while the treasury is still small, the dev team decides on
          the usage of the treasury so we can move fast. Our singular goal in
          the early stage is to maximize user growth.
        </p>
        <p className={s.descLine}>
          In the future, when the treasury is larger, it will be managed by the
          community via a DAO.
        </p>
      </div>
    </div>
  );
};

export default TreasuryAmount;
