import {
  HolderUserTokens,
  HoldingUserTokens,
} from '@/interfaces/api/verifyFriendTech';
import Persistor from './Persistor';
import moment from 'moment';
import { getHolderUserTokens } from '@/services/verifyFriendTech';

const COLLECTION = 'TokenHolder';
const DATABASE_NAME = 'TokenHolder';

// 1 minutes
const REVALIDATE_TIME = [1, 'minutes'];
const BATCH_TIMEOUT = 10000; //10s

const KEY_PATH = 'stored_id';

type StoreProfileType = HoldingUserTokens & {
  writtenAt: number;
};

class TokenHolderPersistor extends Persistor {
  private isStorageds: Record<string, boolean> = {};
  constructor() {
    super(DATABASE_NAME, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreProfileType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION);
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  public getItem = async (
    address: string
  ): Promise<HoldingUserTokens | null> => {
    try {
      const player = await getHolderUserTokens({
        token_address: address,
      });

      return player;
    } catch (e) {
      return null;
    }

    // if (this.isStorageds[`${address}`.toLowerCase()] === false) {
    //   return new Promise(resolve => {
    //     if (this.isStorageds[`${address}`.toLowerCase()] === undefined) {
    //       resolve(this.getItem(address));
    //     } else {
    //       setTimeout(() => {
    //         resolve(this.getItem(address));
    //       }, 50);
    //     }
    //   });
    // } else if (this.isStorageds[`${address}`.toLowerCase()] === undefined) {
    //   this.isStorageds[`${address}`.toLowerCase()] = false;
    // }
    // try {
    //   const record = await this.getRecord(address);

    //   if (record) {
    //     this.isStorageds[`${address}`.toLowerCase()] = true;
    //     if (
    //       record.writtenAt &&
    //       moment(record.writtenAt)
    //         .add(...REVALIDATE_TIME)
    //         .isAfter(moment())
    //     ) {
    //       return record;
    //     }

    //     setTimeout(() => {
    //       getHolderUserTokens({
    //         token_address: address,
    //       }).then(metadata => {
    //         this.upsertItem(address, metadata);
    //       });
    //     }, BATCH_TIMEOUT);
    //     return record;
    //   }
    //   const player = await getHolderUserTokens({
    //     token_address: address,
    //   });
    //   this.upsertItem(address, player);
    //   return player;
    // } catch (e) {
    //   delete this.isStorageds[`${address}`.toLowerCase()];
    //   const player = await getHolderUserTokens({
    //     token_address: address,
    //   });
    //   this.upsertItem(address, player);
    //   return player;
    // }
  };

  private addItem = async (
    address: string,
    item: HoldingUserTokens
  ): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private updateItem = async (
    address: string,
    item: HoldingUserTokens
  ): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);

        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${address}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private upsertItem = async (
    address: string,
    item: HoldingUserTokens
  ): Promise<void> => {
    try {
      const record = await this.getRecord(address);
      if (record) {
        this.updateItem(address, item);
      } else {
        this.addItem(address, item);
      }
    } catch (e) {
      //
    } finally {
      //
      this.isStorageds[`${address}`.toLowerCase()] = true;
    }
  };
}

const persistor = new TokenHolderPersistor();

export default persistor;
