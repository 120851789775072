import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import s from './styles.module.scss';
import { CDN_URL_IMAGES } from '@/configs';
import cs from 'classnames';
import { AssetsContext } from '@/contexts/assets-context';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { RafflePostContext } from '@/components/RaffleNFT/RaffleNFTPost/provider';
import { WalletContext } from '@/contexts/wallet-context';

type IProps = React.ComponentPropsWithRef<'div'> & {
  url?: string;
  users: any[];
  isWheelSuccess: boolean;
  isRunning: boolean;
  isStopped: boolean;
  isOwnerWheel: boolean;
};

const Wheel = React.forwardRef(
  ({
    url,
    users,
    isWheelSuccess,
    className,
    isOwnerWheel,
    isStopped,
    isRunning,
    ...rest
  }: IProps) => {
    const { addressL2 } = useContext(WalletContext);
    const { playerPoolProfile } = useContext(AssetsContext);
    const { isJoin } = useContext(RafflePostContext);
    const [usersShuflle, setUserShuffle] = useState<any[]>(users);

    const renderParticipantList = useMemo(() => {
      const participantCount = users.length || 0;

      if (participantCount === 0) return <></>;

      return (
        <div
          className={cs(s.listWrapper, {
            [`${s.ownerScreen}`]: isOwnerWheel,
          })}
        >
          <div className={`${s.userList} ${s.userList__noneAnimation}`}>
            {users &&
              participantCount > 0 &&
              users.slice(0, 3).map((item: any, index: number) => {
                const { user } = item;
                return (
                  <div key={user.id} className={s.userItem}>
                    <Avatar
                      className={`${s.avatar}`}
                      url={user?.twitter_avatar || ''}
                      address={user.address || ''}
                      width={40}
                    />
                  </div>
                );
              })}
            {participantCount > 3 && (
              <div className={s.moreCircle}>
                <span>{`+${participantCount - 5}`}</span>
              </div>
            )}
          </div>
        </div>
      );
    }, [users]);

    useEffect(() => {
      if (!isRunning && isStopped) return;
      const tmp: any[] = users.slice(0, 10);
      let had = false;
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i]?.user?.address === addressL2) {
          had = true;
          break;
        }
      }
      if (had) {
        tmp[tmp.length - 1] = {
          user: {
            twitter_avatar: playerPoolProfile?.twitterAvatar,
            address: addressL2,
          },
        };
      }
      setUserShuffle(tmp);
    }, [users, addressL2, isRunning]);

    const renderParticipantListAnimation = useMemo(() => {
      const participantCount = usersShuflle.length || 0;
      if (participantCount === 0) return <></>;

      return (
        <div className={`${s.userList_animation}`}>
          {usersShuflle &&
            participantCount > 0 &&
            usersShuflle.map((item: any, index: number) => {
              const { user } = item;
              return (
                <div
                  key={user.id}
                  className={`${s.userList_animation_item} ${s.bounce2}`}
                  style={
                    {
                      '--random': `${Math.random() * 20}px`,
                      '--delay': `${(Math.random() * 2).toFixed(3)}s`,
                    } as React.CSSProperties
                  }
                >
                  <Avatar
                    className={`${s.avatar}`}
                    url={user?.twitter_avatar || ''}
                    address={user.address || ''}
                    width={50}
                  />
                </div>
              );
            })}
        </div>
      );
    }, [usersShuflle]);

    return (
      <>
        <div
          {...rest}
          className={cs(
            s.wheel,
            isStopped && isWheelSuccess ? s.isWheelSuccess_wrap : '',
            isStopped && isWheelSuccess && users.length ? s.hasUser : '',
            isWheelSuccess && !isStopped ? s.hithere : '',
            className
          )}
        >
          <div
            className={`${isRunning && !isStopped ? s.shake : ''} ${
              isStopped && isWheelSuccess ? s.isWheelSuccess : ''
            } ${s.hat}`}
          >
            <div className={`${s.hat_item} ${s.hat_item__be}`}>
              <img src={`${CDN_URL_IMAGES}/hat03-02-min.png`} />
            </div>
            {!isRunning && isStopped && renderParticipantList}
            {isRunning && !isStopped && renderParticipantListAnimation}
            <div className={`${s.wheel_join} ${isJoin ? s.isJoin : ''}`}>
              <Avatar
                url={
                  playerPoolProfile?.twitterAvatar || playerPoolProfile?.avatar
                }
              />
            </div>
            <div className={`${s.hat_item} ${s.hat_item__fe}`}>
              <img src={`${CDN_URL_IMAGES}/hat03-01-min.png`} />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default memo(Wheel);
