import React from 'react';

const IcRightArrow = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999719 -0.00028038C1.25572 -0.00028038 1.51175 0.0976877 1.70675 0.292687L8.70675 7.29269C9.09775 7.68369 9.09775 8.31575 8.70675 8.70675L1.70675 15.7068C1.31575 16.0978 0.683687 16.0978 0.292687 15.7068C-0.0983124 15.3158 -0.0983124 14.6837 0.292687 14.2927L6.58566 7.99972L0.292687 1.70675C-0.0983124 1.31575 -0.0983124 0.683687 0.292687 0.292687C0.487687 0.0976877 0.743719 -0.00028038 0.999719 -0.00028038Z"
        fill="#686A6C"
      />
    </svg>
  );
};

export default IcRightArrow;
