import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import s from './ShareInfo.module.scss';
import cs from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { APP_ENV } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import {
  claimReferralEarning,
  getAddressReferents,
  getAddressReferralEarning,
  getReferralCode,
} from '@/services/referral';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { IReferralEarning } from '@/interfaces/api/referral';
import { detectClientOs, isWebApp } from '@/utils/client';
import { ClientOs } from '@/enums/client';
import CopyIcon from '../InviteAndRewards/svg/CopyIcon';
import ShareIcon from '../InviteAndRewards/svg/ShareIcon';
import TweetIcon from '../InviteAndRewards/svg/TweetIcon';
import { TWITTER_SHARE_DM, TWITTER_SHARE_TWEET } from '@/constants/twitter';
import { convertBtcToUsdt, displayBTCValue } from '@/utils/format';
import {
  Button,
  Flex,
  Spinner,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { AssetsContext } from '@/contexts/assets-context';
import { formatCurrency } from '@/utils';
import InviteIcon from '../InviteAndRewards/svg/InviteIcon';
import { getAddressReferrer } from '@/services/referral';
import EnterInviteCodeModal from '../EnterInviteCodeModal';
import ReferentsModal from '../ReferentsModal';
import { IcQuestion } from '@/modules/AlphaPWA/Profile/svgs/ic-question';
import { Taptip } from '@/components/Taptip';
import { BigNumber } from 'bignumber.js';

type Props = React.ComponentPropsWithRef<'div'> & {
  referents: { address: string }[];
  referralEarnings?: IReferralEarning;
  setReferralEarnings?: (referralEarnings: IReferralEarning) => void;
};

const ShareInfo = ({
  referents,
  referralEarnings,
  setReferralEarnings,
  children,
  className,
  ...rest
}: PropsWithChildren<Props>) => {
  const { addressL2, btc2Usd } = useContext(WalletContext);

  const { referralCode, fetchingReferralCode } = useContext(AssetsContext);
  const [isReferralFromAnother, setIsReferralFromAnother] = useState(false);

  const earningUsdt = useMemo(() => {
    if (!btc2Usd || !referralEarnings?.totalBtcEarning) return '-';
    const priceBn = new BigNumber(referralEarnings?.totalBtcEarning);
    return `$${formatCurrency(priceBn.times(btc2Usd), 0, 2)}`;
  }, [btc2Usd, referralEarnings]);

  const {
    isOpen: isOpenReferents,
    onOpen: onOpenReferents,
    onClose: onCloseReferents,
  } = useDisclosure({ id: 'referents' });

  const {
    isOpen: isOpenEnterInviteCode,
    onOpen: onOpenEnterInviteCode,
    onClose: onCloseEnterInviteCode,
  } = useDisclosure({
    id: 'enter_invite_code ',
  });

  const gaEventTracker = useAnalyticsEventTracker();
  const isPWA = isWebApp();

  const getLink = () => {
    if (referralCode) {
      if (APP_ENV === 'production') {
        return `https://newbitcoincity.com?referral=${referralCode}`;
      }
      return `${window.location.origin}?referral=${referralCode}`;
    }
    return '';
  };

  const callApiCheck = useCallback(() => {
    getAddressReferrer().then(reference => {
      setIsReferralFromAnother(!!reference);
    });
  }, []);

  useEffect(() => {
    callApiCheck();
  }, []);

  useEffect(() => {
    if (!setReferralEarnings) return;
    const getEarings = () => {
      getAddressReferralEarning().then(res => {
        setReferralEarnings(res);
      });
    };

    getEarings();

    let interval: any;
    if (!isOpenReferents) {
      interval = setInterval(() => {
        getEarings();
      }, 30000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOpenReferents]);

  return (
    <>
      <div {...rest} className={cs(s.shareInfo, className)}>
        {fetchingReferralCode && <div className={s.loading}></div>}
        {/* Heading */}
        <div className={s.heading}>
          <h2 className={s.heading__title}>
            Refer friends and <strong>earn 100%</strong> of their trading fees.
            <Taptip
              label={`The terms and conditions of the referral program are subject to change.`}
            >
              <IcQuestion />
            </Taptip>
          </h2>
        </div>

        {/* Codes */}
        <div className={s.codes}>
          <div
            className={cs(s.codes__block, s.codes__link)}
            onClick={() => {
              if (referralCode) {
                copy(getLink());
                toast.remove();
                toast.success('Copied');
                gaEventTracker(GameFiActions.CopyReferralCode, addressL2);
              }
            }}
          >
            {/* <span className={s.codes__block__label}>Link</span> */}
            <span className={s.codes__block__value}>
              <span>{getLink()}</span>
              <CopyIcon />
            </span>
          </div>

          <div
            className={cs(s.codes__block, s.codes__share)}
            onClick={() => {
              if (referralCode) {
                const url = getLink();
                if (isPWA) {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: TWITTER_SHARE_DM,
                        text: TWITTER_SHARE_DM,
                        url,
                      })
                      .catch(error => {
                        window.open(url, '_blank');
                      });
                  } else {
                    window.open(url, '_blank');
                  }
                } else {
                  window.open(url, '_blank');
                }
                gaEventTracker(GameFiActions.ShareReferralCode, addressL2);
              }
            }}
          >
            {/* <SvgInset size={20} svgUrl={getShareIcon()} /> */}
            <span className={s.codes__share__icon}>
              <ShareIcon />
            </span>
            <span className={s.codes__block__label}>Share</span>
          </div>
          <div
            className={cs(s.codes__block, s.codes__share, s.tweet)}
            onClick={() => {
              const shareUrl = getLink();
              const content = `${TWITTER_SHARE_TWEET}${shareUrl}`;
              const hashtags = '';

              gaEventTracker(GameFiActions.TweetReferralCode, addressL2);
              window.open(
                `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  content
                )}`,
                '_blank'
              );
            }}
          >
            {/* <SvgInset size={20} svgUrl={getShareIcon()} /> */}
            <span className={s.codes__share__icon}>
              <TweetIcon />
            </span>
            <span className={s.codes__block__label}>Tweet</span>
          </div>
        </div>

        {/* earningRewards */}
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={'20px'}
          p={'0 16px'}
          mb={'16px'}
        >
          <div
            className={s.earningRewards}
            onClick={e => {
              // if has invited friend should show
              if (referents.length) {
                onOpenReferents();
              }
            }}
          >
            <div
              className={cs(s.earningRewards__block, s.earningRewards__invited)}
            >
              <span className={cs(s.earningRewards__block__amount)}>
                {referents.length || 0}
              </span>
              <h4 className={cs(s.earningRewards__block__title)}>invited</h4>
            </div>
            {referralEarnings && (
              <div
                className={cs(s.earningRewards__block, s.earningRewards__total)}
              >
                <h4 className={cs(s.earningRewards__block__title)}>Earnings</h4>
                <span className={cs(s.earningRewards__block__amount)}>
                  {formatCurrency(
                    referralEarnings?.totalBtcEarning,
                    0,
                    4,
                    'BTC'
                  )}
                  &nbsp;BTC
                </span>
                <div className={cs(s.earningRewards__block__amountUSD)}>
                  {earningUsdt}
                </div>
              </div>
            )}
          </div>
          <div
            className={cs(s.earningRewards__block, s.earningRewards__referCode)}
            onClick={() => {
              if (referralCode) {
                copy(referralCode);
                toast.remove();
                toast.success('Copied');
                gaEventTracker(GameFiActions.CopyReferralCode, addressL2);
              }
            }}
          >
            <h4 className={cs(s.earningRewards__block__title)}>Code</h4>
            <span className={cs(s.earningRewards__block__amount)}>
              {referralCode}
            </span>
            <CopyIcon />
          </div>
        </Flex>
        {!isReferralFromAnother && (
          <div className={s.invite_code}>
            <button
              className={s.invite_code_btn}
              onClick={onOpenEnterInviteCode}
            >
              <InviteIcon />
              <p>Enter invite code</p>
            </button>
          </div>
        )}

        {/* Claim */}
        {/* temp hide this section until implement claim feature */}
        {/*
            {Number(referralEarnings?.totalBtcRemain) >
            MINIMUM_BTC_AMOUNT_CAN_CLAIM ? (
              <>
                {isClaiming ? (
                  <div className={s.rewardClaiming}>
                    <Spinner color="blue" />
                  </div>
                ) : (
                  <div className={s.rewardClaiming} onClick={handleOnClaim}>
                    <span>Claim</span>
                  </div>
                )}
              </>
            ) : (
              <div className={cs(s.rewardClaiming, s.rewardClaiming_inactive)}>
                <span>Claim</span>
              </div>
            )}
          </div> */}
        {/* {children} */}

        <EnterInviteCodeModal
          isOpen={isOpenEnterInviteCode}
          onClose={() => {
            // getAddressReferrer().then(reference => {
            //   setIsReferralFromAnother(!!reference);
            // });
            callApiCheck();
            onCloseEnterInviteCode();
          }}
        />
      </div>
      <ReferentsModal
        isOpen={isOpenReferents}
        onClose={onCloseReferents}
        referents={referents || []}
        referentsInfo={referralEarnings?.referents || []}
      />
    </>
  );
};

export default ShareInfo;
