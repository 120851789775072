import { isProduction } from '@/utils/commons';

export const TokenIcon = {
  turbo: `https://s2.coinmarketcap.com/static/img/coins/64x64/24911.png`,
  pepe: `	https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png`,
  eth: `	https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png`,
  btc: `https://s2.coinmarketcap.com/static/img/coins/64x64/1.png`,
};

const BTC_ADDRESS_L2 = isProduction()
  ? '0x111808abe352c8003e0effcc04998eab26cebe3c'
  : '0x1d45c32C97707C916b350042CEAC2164fb6D00A1';

const ETH_ADDRESS_L2 = isProduction()
  ? '0x43bda480de297a14cec95bfb1c6a313615f809ef'
  : '0x0fba66555b74f13809862bd6f15fffa0a0237059'; // '0xe09385f5b5170322d441a4f36dda01804f2993ab';

const USDT_ADDRESS_L2 = isProduction()
  ? '0xf1612388D43A6b00316CA05ca358BC1a2e7b8E97'
  : '0x5B68dEd386C681232a1308eD1eCC68c997792055';

const DEX_ROUTER_ADDRESS_L2 = isProduction()
  ? '0xB3eAc9358462356B231801309f553c48667B2CB7'
  : '0x3a3885F7a03beC1F4A1c00f155A5d57168fDE205';

const TC_ADDRESS_L2 = isProduction()
  ? '0xaD771ED0F8C5df06D21A7eDA3b00acD6688dD532'
  : '0x8b485d217096cE20A09AF11D15ccCc63323C1469';

const BRIDGE_ADDRESS_L2 = '0x966d0714efa3e3950a2bd4f4b3760d0fc07209f5';

const TOTAL_BTC_KEY_TVL = isProduction()
  ? '0xea21fbBB923E553d7b98D14106A104665BA57eCd'
  : '0xea21fbBB923E553d7b98D14106A104665BA57eCd';

const USDC_ADDRESS_L2 = isProduction()
  ? '0xe8b280ebb57be03adc9d87e207bcd689efadef96'
  : '0xe8b280ebb57be03adc9d87e207bcd689efadef96';

const TM_ADDRESS_L2 = isProduction()
  ? '0xF7051dfeF294FB125Bd30C035197bA9a68F8D997'
  : '0x9f64F7C388B7d2c6f67f1dF191F1Cd0da0a40294';

const ROUTER_ADDRESS = isProduction()
  ? '0xC29108123659a8640d48f1EF2287a558a74a95ee'
  : '0xd8255B554e7D57e409B797D25c48a08A94FA1FC5';

const PEPE_ADDRESS_L2 = isProduction()
  ? '0xdC0155d60dB4f9DeeE0bde67b3Cf5f72ef716c20'
  : '0x9f64F7C388B7d2c6f67f1dF191F1Cd0da0a40294';

const SOL_ADDRESS_L2 = '0xca0ea534e2a7e116be739f4ccc1121515ce6f56a';

const TOKEN_ADDRESS = {
  BTC_ADDRESS_L2,
  ETH_ADDRESS_L2,
  USDT_ADDRESS_L2,
  DEX_ROUTER_ADDRESS_L2,
  TC_ADDRESS_L2,
  BRIDGE_ADDRESS_L2,
  TOTAL_BTC_KEY_TVL,
  USDC_ADDRESS_L2,
  ROUTER_ADDRESS,
  TM_ADDRESS_L2,
  PEPE_ADDRESS_L2,
  SOL_ADDRESS_L2,
};

export { TOKEN_ADDRESS };
