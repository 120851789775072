import React, { useCallback, useMemo } from 'react';
import s from './Feed.module.scss';
import moment from 'moment';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import { formatLongAddress } from '@trustless-computer/dapp-core';
import { FeedDataType } from './types';
import { formatCurrency } from '@/utils';
import GreenKeyIcon from '@/modules/AlphaPWA/Notification/svg/GreenKeyIcon';
import clsx from 'clsx';
import { getUrlAvatarTwitter, settingMomentFromNow } from '@/utils/helpers';
import IcRealKey from '@/modules/AlphaPWA/Notification/svg/ic-real-key';
import IcPlaceholderKey from '@/modules/AlphaPWA/Notification/svg/ic-pleaceholder-key';
import { Follower } from '@/modules/AlphaPWA/Notification/Follower';
import IcDonate from './svg/IcDonate';
import { labelAmountOrNumberAdds } from '../Profiles/TradeKey/constants';
import Avatar from '../Profiles/TradeKey/components/avatar';
import { goToRoute } from '@/utils/go-route';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';

settingMomentFromNow();

const activities: any = {
  issue_token: 'released keys',
  presale_issue_token: 'placeholder keys were just issued',
  give_away_deposit: 'gifted',
  give_away_receive: 'snatched a red packet in',
};

function calculateTimeAgo(targetTime: string) {
  const targetMoment = moment(targetTime);
  return targetMoment.fromNow();
}

const compareString = (a: unknown, b: unknown) => {
  return a?.toString?.()?.toLowerCase?.() === b?.toString?.()?.toLowerCase?.();
};

const NewestFeedItem = ({ item }: { item: FeedDataType }) => {
  const router = useRouter();

  const isPresale = useMemo(() => {
    return compareString(item?.player.twitter_id, item?.player.address);
  }, [item]);

  const isGiveAway =
    item.activity === 'give_away_deposit' ||
    item.activity === 'give_away_receive';

  const isGiveAwayDeposit = useMemo((): boolean => {
    return item.activity === 'give_away_deposit';
  }, [item]);

  const isGiveAwayReceive = useMemo((): boolean => {
    return item.activity === 'give_away_receive';
  }, [item]);

  const isGiveAwayPublic = useMemo((): boolean => {
    return item.tweet_activity === 'public';
  }, [item]);

  const returnActivityText = () => {
    if (item.activity === 'issue_token' || isGiveAway) {
      if (isPresale) {
        return activities['presale_issue_token'];
      } else {
        return activities[item.activity];
      }
    }
  };

  const statusText = returnActivityText();

  const renderContent = () => {
    if (!isGiveAway) {
      return (
        <>
          <div className={s.detail_firstUser}>
            <div
              className={s.detail_firstUser_left}
              onClick={(event: React.MouseEvent<unknown>) => {
                event?.stopPropagation();
                if (!item.player?.address) return;
                goToRoute(
                  router,
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item.player?.address}`
                );
              }}
            >
              <span>
                {item?.player.twitter_name ||
                  (item?.player.twitter_username &&
                    `@${item?.player.twitter_username}`) ||
                  formatLongAddress(item?.player.address || '')}
              </span>
            </div>
          </div>
          {statusText}
        </>
      );
    }
    return (
      <>
        {isGiveAwayPublic && isGiveAwayReceive ? (
          <>
            {`Share your referral link. `}
            <div
              className={s.detail_center_user}
              onClick={(event: React.MouseEvent<unknown>) => {
                event?.stopPropagation();
                if (!item.user?.address) return;
                goToRoute(
                  router,
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item.user?.address}`
                );
              }}
            >
              <span>
                {item?.user.twitter_name ||
                  (item?.user.twitter_username &&
                    `@${item?.user.twitter_username}`) ||
                  formatLongAddress(item?.user.address || '')}
              </span>
            </div>
            {` snatched a red packet using `}
            <div
              className={s.detail_center_user}
              onClick={(event: React.MouseEvent<unknown>) => {
                event?.stopPropagation();
                if (!item.player?.address) return;
                goToRoute(
                  router,
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item.player?.address}`
                );
              }}
            >
              <span>
                {item?.player.twitter_name ||
                  (item?.player.twitter_username &&
                    `@${item?.player.twitter_username}`) ||
                  formatLongAddress(item?.player.address || '')}
              </span>
            </div>
            {` referral link.`}
          </>
        ) : (
          <>
            <div className={s.detail_firstUser}>
              <div
                className={s.detail_firstUser_left}
                onClick={(event: React.MouseEvent<unknown>) => {
                  event?.stopPropagation();
                  if (!item.user?.address) return;
                  goToRoute(
                    router,
                    `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item.user?.address}`
                  );
                }}
              >
                <span>
                  {item?.user.twitter_name ||
                    (item?.user.twitter_username &&
                      `@${item?.user.twitter_username}`) ||
                    formatLongAddress(item?.user.address || '')}
                </span>
              </div>
            </div>
            {isGiveAwayPublic ? (
              <>
                {isGiveAwayDeposit &&
                  `gifted red packets to new referees via referral on X.`}
              </>
            ) : (
              <>
                {statusText}
                {item.activity === 'give_away_deposit' && (
                  <>
                    &nbsp;{item.amount} red packet
                    {labelAmountOrNumberAdds(item.amount)} to
                  </>
                )}
                <div className={s.detail_firstUser}>
                  <div className={s.detail_firstUser_left}>
                    <span>
                      &nbsp;
                      {item?.player.twitter_name ||
                        (item?.player.twitter_username &&
                          `@${item?.player.twitter_username}`) ||
                        formatLongAddress(item?.player.address || '')}
                    </span>
                    &nbsp;dao
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const goToTrade = (address: string) => {
    if (!address) return;
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${address}`);
  };

  // const getRoomId = async () => {
  //   if (!item.token.contract_address) return;
  //   try {
  //     const res = await getGroupMessageRoomId({
  //       token: item.token.contract_address,
  //     });
  //     setRoomId(res?.roomId || '');
  //   } catch (error) {
  //     console.log('getRoomId ~ error:', error);
  //   }
  // };
  //
  // useEffect(() => {
  //   if (isGiveAway) {
  //     getRoomId();
  //   }
  // }, [item.activity]);

  const { rooms, checkRoomPermission } = useDM();
  const gotoGiveAwayCircle = useCallback(async () => {
    const dmDetail = await rooms
      ?.filter((v: any) => v.type !== 'CHAT_ROOM')
      .find(
        (__item: any) =>
          __item.owner.toLowerCase() === item?.player.address.toLowerCase() &&
          __item.isActive
      );

    let urlRedirect = `${ROUTE_PATH.ALPHA_MOBILE_DM}?address=${item?.player.address}&ownerAddress=${item?.player?.address}&ownerUsername=${item?.player?.twitter_name}`;
    if (dmDetail?.id) {
      try {
        const checkPermission = await checkRoomPermission(dmDetail?.id);
        if (checkPermission) {
          urlRedirect = `${ROUTE_PATH.ALPHA_MOBILE_DM}?id=${dmDetail?.id}&address=${dmDetail?.owner}&ownerAddress=${item?.player?.address}&ownerUsername=${item?.player?.twitter_name}`;
        }
      } catch (e) {}
    }

    router.push(urlRedirect);
  }, [item, isGiveAway]);

  return (
    <div
      className={`${s.feed_item} ${s.feed_item__news}`}
      onClick={() => {
        if (isGiveAway) {
          gotoGiveAwayCircle();
        } else {
          if (!item.player.address) return;
          goToTrade(item?.player.address);
        }
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={clsx(s.feed_img, s.feed_img__news)}>
          <Avatar
            url={getUrlAvatarTwitter(
              isGiveAway
                ? item?.user.avatar || item?.user.twitter_avatar
                : item?.player.avatar || item?.player.twitter_avatar
            )}
            name={
              isGiveAway ? item?.user?.twitter_name : item.player?.twitter_name
            }
            fontSize={14}
            address={isGiveAway ? item?.user?.address : item.player?.address}
            onClick={(event: React.MouseEvent<unknown>) => {
              event?.stopPropagation();
              if (isGiveAway) {
                if (!item.user?.address) return;
                goToRoute(
                  router,
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item.user?.address}`
                );
              } else {
                if (!item.player?.address) return;
                goToRoute(
                  router,
                  `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item.player?.address}`
                );
              }
            }}
            width={38}
          />
          <div className={clsx(s.type_img, s.type_img__news)}>
            {isGiveAway ? (
              <IcDonate />
            ) : isPresale ? (
              <IcPlaceholderKey />
            ) : (
              <IcRealKey />
            )}
          </div>
        </div>
      </div>

      <div className={`${s.feed_content} ${s.feed_content__news}`}>
        <div className={s.detail}>
          <div>{renderContent()}</div>
        </div>
        <div className={`${s.info} ${s.info__news}`}>
          {item.activity !== 'give_away_receive' && (
            <div className={s.info__news_left}>
              <span className={s.price}>
                {formatCurrency(item.price, 0, 6, 'BTC')}
                {item.base_token_symbol}
                {item?.price_usd && (
                  <>
                    &nbsp;{`|`}&nbsp;
                    <span className={s.price_usd}>
                      {formatCurrency(item?.price_usd, 0, 2)} {'USD '}
                    </span>
                  </>
                )}
              </span>
              {!isGiveAway && (
                <span className={s.sold}>
                  <GreenKeyIcon />
                  {item.amount}{' '}
                </span>
              )}
              {!isGiveAway && (
                <Follower
                  followNumber={item?.player.twitter_followers_count || 0}
                />
              )}
            </div>
          )}

          <span className={s.time}>{calculateTimeAgo(item.activity_time)}</span>
        </div>
      </div>
    </div>
  );
};

export default NewestFeedItem;
