import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import React, { useContext, useMemo } from 'react';

import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';
import HorizontalItem from '@/modules/AlphaPWA/Profiles/TradeKey/components/horizontalItem';
import WrapButtonSubmit from '@/modules/AlphaPWA/Profiles/TradeKey/components/wrapButtonNetwork';
import {
  SLIDE_ALPHA,
  SLIDE_FT,
  labelAmountOrNumberAdds, MIN_DECIMAL, MAX_DECIMAL,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import Buying from '@/modules/AlphaPWA/Profiles/TradeKey/pro/buying';
import { ITradeKey } from '@/services/interfaces/trade';
import { compareString, formatCurrency } from '@/utils';
import px2rem from '@/utils/px2rem';
import BigNumber from 'bignumber.js';
import { IKeysCommon } from '.';
import s from './styles.module.scss';
import { BASE_SYMBOL } from '@/contracts/configs';
import {useSelector} from "react-redux";
import {selectCommonReducer} from "@/state/common";
import KeyIcon from "@/modules/AlphaPWA/Profiles/BuyAKey/KeyIcon";

interface IProKeyFooter extends IKeysCommon {
  sweepMax: number;
  onChangeSweep: (_: any) => void;
  onSubmit: (_: any) => void;
  onDoneAndClose: () => void;
  setNumProcessDone: (_: any) => void;
  submitting?: boolean;
  isFetching?: boolean;
  isDone?: boolean;
  keys: ITradeKey[];
  waitKeys: any[];
  numProcessDone: number;
}

const ProKeyFooter: React.FC<IProKeyFooter> = ({
  selectKeys,
  sweepMax,
  onChangeSweep,
  submitting,
  onSubmit,
  isFetching,
  isDone,
  keys,
  waitKeys,
  setNumProcessDone,
  onDoneAndClose,
  numProcessDone,
}) => {
  const buyNumKeys = useMemo(() => selectKeys.length, [selectKeys.length]);
  const { isAuthenticated } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showKeyLeft = useSelector(selectCommonReducer).showKeyLeft;
  const showKeyRight = useSelector(selectCommonReducer).showKeyRight;

  const amountKeys = selectKeys?.length * selectKeys[0]?.token_amount;

  const { estimateNB, estimateFT } = useMemo(() => {
    const _estimateNB = selectKeys
      .filter(v => compareString(v.key?.base_token_symbol, 'BTC'))
      .reduce(
        (p, c) => parseFloat(p.toString()) + parseFloat(c.key?.price as string),
        0
      );

    const _estimateFT = selectKeys
      .filter(v => compareString(v.key?.base_token_symbol, BASE_SYMBOL))
      .reduce(
        (p, c) => parseFloat(p.toString()) + parseFloat(c.key?.price as string),
        0
      );

    return {
      estimateNB: _estimateNB,
      estimateFT: _estimateFT,
    };
  }, [selectKeys]);

  const validateBalance = useMemo(() => {
    if (new BigNumber(estimateNB).gt(balanceL2.amountBTCFormatted)) {
      return 'Not enough BTC balance';
    }
    if (new BigNumber(estimateFT).gt(balanceL2.amountETHFormatted)) {
      return 'Not enough ETH balance';
    }

    return undefined;
  }, [balanceL2, estimateFT, estimateNB]);

  const isDisabled =
    buyNumKeys === 0 || !isAuthenticated || Boolean(validateBalance);

  const renderAmount = useMemo(() => {
    let result = undefined;
    if (selectKeys.length > 0) {
      return (
        <Flex
          flexDirection={'column'}
          alignItems={'flex-end'}
          justifyContent={'center'}
          gap={'2px'}
        >
          {Boolean(Number(estimateNB)) && (
            <Flex>
              <Text style={{ lineHeight: '100%' }}>
                {`${formatCurrency(estimateNB, 0, 6, 'BTC')}`} BTC
              </Text>
            </Flex>
          )}
          {Boolean(Number(estimateFT)) && (
            <Flex>
              <Text style={{ lineHeight: '100%' }}>
                {`${formatCurrency(estimateFT, 0, 4)}`} ETH
              </Text>
            </Flex>
          )}
        </Flex>
      );
    }
    return result;
  }, [selectKeys, estimateFT, estimateNB]);

  return (
    <>
      <Flex className={s.footer}>
        <Flex flexDirection={'column'}>
          <Button
            onClick={onOpen}
            isDisabled={isDisabled}
            className={clsx(s.btnBuy, validateBalance && s.btnBuyWithError)}
          >
            <Flex alignItems={"center"}>
              Buy
              {buyNumKeys > 0
                ? (compareString(showKeyLeft, '0') && compareString(showKeyRight, '1')) ?
                  <>
                    &nbsp;{buyNumKeys}&nbsp;
                    <KeyIcon />
                  </>
                  : ` ${buyNumKeys} key${labelAmountOrNumberAdds(buyNumKeys)}`
                : ''}
            </Flex>
            {renderAmount}
          </Button>
          <Text className={s.error}>{validateBalance}</Text>
        </Flex>
        <Flex className={s.right} flex={1}>
          {
            !(compareString(showKeyLeft, '0') && compareString(showKeyRight, '1')) && (
              <Text className={s.title}>Sweep</Text>
            )
          }
          <Flex className={s.wrapSweep}>
            <Flex className={s.iconSweep}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9997 14.5001H10.6664C10.3904 14.5001 10.1664 14.2761 10.1664 14.0001C10.1664 13.7241 10.3904 13.5001 10.6664 13.5001H13.9997C14.2757 13.5001 14.4997 13.7241 14.4997 14.0001C14.4997 14.2761 14.2757 14.5001 13.9997 14.5001ZM2.29305 8.37342C1.89971 8.76675 1.89971 9.40008 2.29305 9.78675L2.8997 10.3934L4.31303 8.98007C4.50636 8.78674 4.8264 8.78674 5.01973 8.98007C5.21306 9.1734 5.21306 9.49344 5.01973 9.68677L3.60636 11.1001L6.21305 13.7068C6.59972 14.1001 7.23305 14.1001 7.62638 13.7068L10.2597 11.0801L4.92639 5.74675L2.29305 8.37342ZM6.00638 3.99276L5.53039 4.46876C5.40039 4.59876 5.40039 4.81007 5.53039 4.94007L11.059 10.4688C11.189 10.5988 11.4004 10.5988 11.5304 10.4688L12.0064 9.99276C12.3837 9.61543 12.4997 9.05007 12.3017 8.55473L11.5704 6.72677C11.4071 6.31744 11.5304 5.84942 11.8744 5.57475L13.5244 4.25477C14.1137 3.78343 14.1624 2.90476 13.6284 2.37143C13.095 1.83809 12.2164 1.88676 11.745 2.47543L10.425 4.12541C10.1497 4.46941 9.68169 4.59274 9.27303 4.42941L7.44507 3.69809C6.94973 3.49942 6.38372 3.61543 6.00638 3.99276Z"
                  fill="white"
                />
              </svg>
            </Flex>
            <Flex className={s.numBuy}>
              <Text>{buyNumKeys}</Text>
            </Flex>
            <Flex className={s.sliderContainer}>
              <Slider
                aria-label="slider-ex-1"
                defaultValue={Number(buyNumKeys)}
                value={Number(buyNumKeys)}
                max={sweepMax}
                onChange={onChangeSweep}
                isDisabled={isFetching}
                step={1}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Modal
        closeOnEsc={!submitting}
        closeOnOverlayClick={!submitting}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        onCloseComplete={() => {
          if (isDone) {
            onDoneAndClose();
          }
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px">Buy keys</ModalHeader>
          <ModalBody overflow={'auto'}>
            {submitting || isDone ? (
              <Buying
                keys={keys}
                selectKeys={selectKeys}
                waitKeys={waitKeys}
                setNumProcessDone={setNumProcessDone}
                isInvite={false}
              />
            ) : (
              <>
                <HorizontalItem
                  label={
                    <Text fontSize={px2rem(16)} fontWeight={500}>
                      Amount:
                    </Text>
                  }
                  value={`${formatCurrency(
                    amountKeys,
                    MIN_DECIMAL, MAX_DECIMAL
                  )} key${labelAmountOrNumberAdds(amountKeys)}`}
                />
                <Box py={4} />
                <HorizontalItem
                  label={
                    <Text fontSize={px2rem(16)} fontWeight={500}>
                      Total:
                    </Text>
                  }
                  value={renderAmount}
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {/* {isDone && (
              <Button
                width={'100%'}
                className={s.btnConfirm}
                onClick={() => {
                  onDoneAndClose();
                  onClose();
                }}
              >
                Close
              </Button>
            )} */}

            {!(submitting || isDone) ? (
              <WrapButtonSubmit
                className={s.btnConfirm}
                isDetectNetwork={
                  selectKeys.findIndex(v => compareString(v.side, SLIDE_FT)) >
                  -1
                }
              >
                <Button
                  width={'100%'}
                  onClick={onSubmit}
                  className={s.btnConfirm}
                >
                  Confirm
                </Button>
              </WrapButtonSubmit>
            ) : (
              <Button
                width={'100%'}
                className={s.btnConfirm}
                onClick={() => {
                  onDoneAndClose();
                  onClose();
                }}
                isDisabled={submitting && !isDone}
              >
                {submitting && !isDone
                  ? `Processing ${numProcessDone} / ${selectKeys.length}`
                  : 'Close'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProKeyFooter;
