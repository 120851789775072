import { PERP_API_URL } from '@/configs';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import accountStorage from '@/utils/account.storage';
import axios, {
  Axios,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

class CApiClient {
  private requestConfig: AxiosRequestConfig = {
    baseURL: PERP_API_URL,
    timeout: 5 * 60000,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  api: Axios;

  account = accountStorage;

  constructor() {
    this.api = axios.create(this.requestConfig);

    this.api.interceptors.request.use(
      (config: any) => {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem(
          TWITTER_TOKEN
        )}`;
        // config.params
        return config;
      },
      (error: AxiosError) => {
        Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (res: AxiosResponse) => {
        let result = res?.data?.data || res?.data?.result;
        const error = res?.data?.error;
        if (error) {
          return Promise.reject(error);
        }
        if (res?.data?.count !== undefined) {
          result = {
            rows: result,
            count: res?.data?.count,
          };
        }
        return Promise.resolve(result);
      },
      (error: any) => {
        if (!error.response) {
          return Promise.reject(error);
        } else {
          const response = error?.response?.data || error;
          const errorMessage =
            response?.error || error?.Message || JSON.stringify(error);
          return Promise.reject(errorMessage);
        }
      }
    );
  }
}

export default CApiClient;
