import React, { useContext, useState } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import s from './styles.module.scss';
import { getErrorMessage } from '@/utils/error';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useReferralCode } from '@/services/referral';
import { WalletContext } from '@/contexts/wallet-context';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface IFormValue {
  code: string;
}

const EnterInviteCodeModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { addressL2 } = useContext(WalletContext);
  const [submitError, setSubmitError] = useState('');
  const gaEventTracker = useAnalyticsEventTracker();

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.code) {
      errors.code = 'Code is required.';
    }
    return errors;
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    if (loading) return;
    setLoading(true);

    try {
      gaEventTracker(GameFiActions.SetReferralCodeStart, addressL2);
      await useReferralCode({
        code: `${payload.code}`.toLowerCase(),
      });
      gaEventTracker(GameFiActions.SetReferralCodeManual, addressL2);
      gaEventTracker(GameFiActions.SetReferralCode, addressL2);
      toast.success('Successfully');
      onClose();
    } catch (error: any) {
      if ((error as any).message) {
        setSubmitError((error as any).message);
      }

      toast.error((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={s.wrapper}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent p="24px 22px" minW={330} textAlign={'center'}>
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            textAlign="center"
            fontSize="24px"
            fontWeight={'600'}
            mb="24px"
          >
            Enter invite code
          </ModalHeader>
          <ModalBody>
            <Formik
              key="cash-out-form"
              initialValues={{
                code: '',
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({
                values,
                touched,
                errors,
                // handleChange,
                setFieldValue,
                handleSubmit,
              }) => {
                // console.log(values)
                return (
                  <form className={s.form} onSubmit={handleSubmit}>
                    <div className={s.codeInput}>
                      <OtpInput
                        value={values.code}
                        onChange={v => {
                          setSubmitError('');
                          setFieldValue('code', v);
                        }}
                        numInputs={4}
                        renderSeparator={<></>}
                        renderInput={props => <input {...props} />}
                        placeholder="----"
                        inputType="text"
                      />
                      {!!(errors.code || submitError) && (
                        <div className={s.error}>
                          <Text className="error">
                            {errors.code || submitError}
                          </Text>
                        </div>
                      )}
                    </div>
                    <button
                      disabled={!!errors.code || !!submitError || loading}
                      className={s.submitBtn}
                      type="submit"
                    >
                      {loading ? 'Processing...' : 'Confirm'}
                    </button>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EnterInviteCodeModal;
