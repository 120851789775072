import React, { useContext } from 'react';
import { APP_ENV, CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import s from './styles.module.scss';
import { AssetsContext } from '@/contexts/assets-context';
import { TwitterShareButton } from 'react-share';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';

export const DONATE_PUBLIC_SHARE_MODAL: string = 'DONATE_PUBLIC_SHARE_MODAL';

interface IProps {
  onClose: () => void;
  amount: string;
  numOfTicket: number;
}

const DonatePublicShareModal: React.FC<IProps> = ({
  onClose,
  amount,
  numOfTicket,
}): React.ReactElement => {
  const { referralCode } = useContext(AssetsContext);

  const getLink = () => {
    if (referralCode) {
      if (APP_ENV === 'production') {
        return `https://newbitcoincity.com?referral=${referralCode}`;
      }
      return `${window.location.origin}?referral=${referralCode}`;
    }
    return '';
  };

  const onClickCopy = () => {
    copy(getLink());
    toast.remove();
    toast.success('Copied');
  };

  return (
    <div className={s.wrapper}>
      <div className={s.imgWrapper}>
        <img src={`${CDN_URL_IMAGES}/public-lixi.png`} alt="public-lixi" />
      </div>
      <h2 className={s.title}>Red packets ready!</h2>
      <div className={s.descriptionWrapper}>
        <p className={s.description}>
          You are about to gift up to <span>{numOfTicket} red packets </span>{' '}
          total worth <span>{amount} BTC </span>.
        </p>
        <p className={s.description}>
          These red packets are only available to referrers who sign up through
          your referral link. Spread the excitement and surprise to get more
          successful referrals!
        </p>
      </div>
      <div className={s.referralWrapper}>
        <div className={s.referralCode}>{getLink()}</div>
        <button onClick={onClickCopy}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <g clip-path="url(#clip0_19098_13723)">
              <path
                d="M10 4H5C4.44772 4 4 4.44772 4 5V10C4 10.5523 4.44772 11 5 11H10C10.5523 11 11 10.5523 11 10V5C11 4.44772 10.5523 4 10 4Z"
                stroke="#FFE899"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 8C1.45 8 1 7.55 1 7V2C1 1.45 1.45 1 2 1H7C7.55 1 8 1.45 8 2"
                stroke="#FFE899"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_19098_13723">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <TwitterShareButton
        className={s.shareBtn}
        url={getLink()}
        title={`Gift up to 5 Red packets total worth ${parseFloat(
          amount
        ).toFixed(
          4
        )} BTC.\n\nJoin @newbitcoincity through my referral link and seize one for yourself.\n\nBe quick, as it's first come, first served!\n\n`}
        hashtags={[]}
      >
        <img
          className={s.xIcon}
          src={`${CDN_URL_ICONS}/ic-twitter-x.svg`}
          alt="ic-twitter-x"
        />
        <span>Share your referral link</span>
      </TwitterShareButton>
    </div>
  );
};

export default DonatePublicShareModal;
