import { DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';
import Persistor from './Persistor';

const COLLECTION = 'ChatRoomDesktop';
const DATABASE_NAME = 'ChatRoomDesktop';

type StoreMetadataType = DMRoom & {
  writtenAt: number;
};

const KEY_PATH = 'stored_id';

class ChatRoomPersistor extends Persistor {
  constructor() {
    super(DATABASE_NAME, (db: any): void => {
      try {
        const store = db.createObjectStore(COLLECTION, {
          keyPath: KEY_PATH,
        });

        store.createIndex('item', 'item', {
          unique: false,
        });
      } catch (e) {
        try {
          this.sendErrorLog(
            'Cannot create room chat persistor',
            JSON.stringify(e)
          );
        } catch (_e) {
          //
        }
      }
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreMetadataType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION);
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
            try {
              this.sendErrorLog(
                'Cannot read room chat from indexdb',
                JSON.stringify(event)
              );
            } catch (_e) {
              //
            }
          };
        }
      } catch (e) {
        try {
          this.sendErrorLog(
            'Cannot read room chat from indexdb',
            JSON.stringify(e)
          );
        } catch (_e) {
          //
        }
        reject(e);
      }
    });
  };

  public getItem = async (roomId: string): Promise<DMRoom | null> => {
    try {
      const record = await this.getRecord(roomId);
      if (record) {
        return record;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  private addItem = async (roomId: string, item: DMRoom): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${roomId}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);

            try {
              this.sendErrorLog(
                'Cannot add room chat to indexdb',
                JSON.stringify(event)
              );
            } catch (_e) {
              //
            }
          };
        }
      } catch (e) {
        try {
          this.sendErrorLog(
            'Cannot add room chat to indexdb',
            JSON.stringify(e)
          );
        } catch (_e) {
          //
        }
        reject(e);
      }
    });
  };

  private updateItem = async (roomId: string, item: DMRoom): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);

        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${roomId}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
            try {
              this.sendErrorLog(
                'Cannot update room chat to indexdb',
                JSON.stringify(event)
              );
            } catch (_e) {
              //
            }
          };
        }
      } catch (e) {
        try {
          this.sendErrorLog(
            'Cannot update room chat to indexdb',
            JSON.stringify(e)
          );
        } catch (_e) {
          //
        }
        reject(e);
      }
    });
  };

  // public updatePinned = async (roomId: string, item: DMRoom): Promise<void> => {
  //   try {
  //     const record = await this.getRecord(roomId);
  //     if (record) {
  //       this.updateItem(roomId, item);
  //     } else {
  //       this.addItem(roomId, item);
  //     }
  //   } catch (e) {
  //     //
  //   } finally {
  //     //
  //   }
  // };

  public upsertItem = async (roomId: string, item: DMRoom): Promise<void> => {
    try {
      const record = await this.getRecord(roomId);
      // item.pinned = record?.pinned;
      if (record) {
        this.updateItem(roomId, item);
      } else {
        this.addItem(roomId, item);
      }
    } catch (e) {
      //
    } finally {
      //
    }
  };

  public getAllItem = async (): Promise<DMRoom[]> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION);
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.getAll();

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);

            try {
              this.sendErrorLog(
                'Cannot get all room chat from indexdb',
                JSON.stringify(event)
              );
            } catch (_e) {
              //
            }
          };
        }
      } catch (e) {
        try {
          this.sendErrorLog(
            'Cannot get all room chat from indexdb',
            JSON.stringify(e)
          );
        } catch (_e) {
          //
        }
        reject(e);
      }
    });
  };

  public removeItem = async (roomId: string) => {
    try {
      await this.remove();
      this.database
        ?.transaction(COLLECTION, 'readwrite')
        .objectStore(COLLECTION)
        .delete(roomId);
    } catch (e) {
      //
      try {
        this.sendErrorLog(
          'Cannot delete room chat from indexdb',
          JSON.stringify(e)
        );
      } catch (_e) {
        //
      }
    }
  };
}

const persistor = new ChatRoomPersistor();

export default persistor;
