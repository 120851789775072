import {CDN_URL_ICONS} from '@/configs';
import {ROUTE_PATH} from '@/constants/route-path';
import {AssetsContext} from '@/contexts/assets-context';
import {CurrencyContext} from '@/contexts/currency-context';
import {WalletContext} from '@/contexts/wallet-context';
import {ClientOs} from '@/enums/client';
import {ALPHA_LAYOUT_HEADER_ID} from '@/layouts/AlphaLayout/constants';
import SwapTcToBTCModal from '@/modules/AlphaPWA/Profile/SwapTcToBtcModal';
import IcFire from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icFire';
import {useOverlay} from '@/providers/OverlayProvider';
import {formatCurrency} from '@/utils';
import {detectClientOs} from '@/utils/client';
import useAnalyticsEventTracker, {AlphaActions, HeaderActions,} from '@/utils/ga';
import {Box} from '@chakra-ui/react';
import cs from 'classnames';
import {useRouter} from 'next/router';
import React, {useContext, useEffect, useRef, useState} from 'react';
import Treasury from '../Treasury';
import {useAppDispatch} from '@/state/hooks';
import {closeModal, openModal} from '@/state/modal';
import DonateModal, {DONATE_MODAL} from '@/components/DonateModal';
import s from './styles.module.scss';
import IconBell from './svg/IconBell';
import {resetUnreadNotification} from '@/state/notification/reducer';
import ProgressBar from '@/components/ProgressBar';
import TVL from '../TVL';
import {getUnreadNotification} from "@/services/player-share";

import {getDatabase, ref} from 'firebase/database';
import {useObject} from 'react-firebase-hooks/database';
import getFirebaseApp from '@/services/firebase/base';
import useTradeKey from "@/hooks/playerShareToken/useTradeKey";
import useDebounce from "@/hooks/useDebounce";

const firebaseApp = getFirebaseApp();

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(firebaseApp);

const Header: React.FC = (): React.ReactElement => {
  const router = useRouter();
  const clientOs = detectClientOs();
  const dispatch = useAppDispatch();

  const [showConvertTC, setShowConvertTC] = useState(false);
  const { balanceL2, playerPoolProfile } = useContext(AssetsContext);
  const { addressL2 } = useContext(WalletContext);
  const { isForceConvert } = useContext(CurrencyContext);
  // const isForceConvert = false;
  // const [referralEarnings, setReferralEarnings] = useState<IReferralEarning>();
  const timer = useRef<any>();
  const { openOverlayScreen } = useOverlay();
  const [unreadCount, setUnreadCount] = useState(0);
  const isOnPage = router.asPath === ROUTE_PATH.ALPHA_MOBILE_NOTI;
  const gaEventTracker = useAnalyticsEventTracker();
  const { userProfile } = useTradeKey();

  const [snapshots, loading, error] = useObject(ref(database, `user-activities/${userProfile?.twitter_id}`));

  const snapshotsDebounced = useDebounce(snapshots, 2000);

  useEffect(() => {
    const list = snapshots?.val();

    if(!!list) {
      getUnreadNotif();
    }
  }, [snapshotsDebounced]);

  useEffect(() => {
    if(addressL2) {
      getUnreadNotif();
    }
  }, [addressL2]);

  const getUnreadNotif = async () => {
    const res: number = await getUnreadNotification({
      page: 1, limit: 50, address: addressL2 || '', is_count: 1
    });

    setUnreadCount(res || 0);
  }

  const goToProfilePage = () => {
    if (!isForceConvert) {
      router.push(ROUTE_PATH.ALPHA_MOBILE_PROFILE);
    } else {
      setShowConvertTC(true);
    }
  };

  const goToUrl = (url: string): void => {
    router.push(url);
  };

  const handleCloseModal = () => {
    dispatch(closeModal({ id: DONATE_MODAL }));
  };

  const handleOpenDonateModal = () => {
    dispatch(
      openModal({
        id: DONATE_MODAL,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: false,
        render: () => <DonateModal onClose={handleCloseModal} />,
      })
    );
    gaEventTracker(AlphaActions.OpenDonateModal, addressL2);
  };

  useEffect(() => {
    if (isOnPage) {
      dispatch(resetUnreadNotification());
    }
  }, [router.asPath]);

  // useEffect(() => {
  //   const getEarings = () => {
  //     if (addressL2) {
  //       getAddressReferralEarning(addressL2).then(res => {
  //         setReferralEarnings(res);
  //       });
  //     }
  //   };

  //   getEarings();

  //   timer.current = setInterval(() => {
  //     getEarings();
  //   }, 30000);

  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }, [addressL2]);

  // const isReferReward = useMemo(() => {
  //   // return (
  //   // //  !!referralEarnings?.totalEarning &&
  //   //   ethers.BigNumber.from(referralEarnings?.totalEarning).gt(0)
  //   // );
  //   return Number(referralEarnings?.totalBtcEarning) > 0;
  // }, [referralEarnings]);

  // const referRewardText = useMemo(() => {
  //   return displayBTCValue(referralEarnings?.totalBtcEarning);
  // }, [referralEarnings?.totalBtcEarning]);

  return (
    <>
      <header
        className={cs(s.header, clientOs === ClientOs.Ios && s.ios)}
        id={ALPHA_LAYOUT_HEADER_ID}
      >
        <div className={s.containerLeft}>
          <TVL />
          <Treasury />
          {/*{referralEarnings !== undefined ? (
            <>
              {isReferReward ? (
                <button
                  className={s.earningStats}
                  onClick={() => {
                    gaEventTracker(
                      GameFiActions.ClickInviteAndEarnButton,
                      addressL2
                    );
                    // goToUrl(
                    //   `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}?section=referral`
                    // );
                    openOverlayScreen('REFERRAL_EARNING');
                  }}
                >
                  <span className={s.earningStats__reward}>
                    {referRewardText}
                  </span>
                  <div className={s.earningStats__refer}>
                    <span>Refer</span>
                  </div>
                </button>
              ) : (
                <button
                  className={s.referAndEarn}
                  onClick={() => {
                    gaEventTracker(
                      GameFiActions.ClickInviteAndEarnButton,
                      addressL2
                    );
                    // goToUrl(
                    //   `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}?section=referral`
                    // );
                    openOverlayScreen('REFERRAL_EARNING');
                  }}
                >
                  <div>
                    Refer & Earn
                    <span>&nbsp;20%</span>
                  </div>
                </button>
              )}
            </>
          ) : (
            <Skeleton borderRadius="100px" height="40px" width="97px" />
          )}*/}
        </div>

        <div className={s.containerRight}>
          <div className={s.links}>
            {/* <div
              onClick={() => {
                // open donate modal
                handleOpenDonateModal();
              }}
            >
              <img
                src={`${CDN_URL_ICONS}/ic-read-envelop.png`}
                alt="red envelop"
                className={s.donate_icon}
              />
            </div> */}
            <Box
              position={'relative'}
              onClick={() => {
                getUnreadNotif();
                goToUrl(ROUTE_PATH.ALPHA_MOBILE_NOTI);
                gaEventTracker(HeaderActions.ClickPage, addressL2);
              }}
            >
              {unreadCount > 0 && !isOnPage && (
                <span className={`${s.redDot}`}>
                   {unreadCount < 10 ? unreadCount : '9+'}
                </span>
              )}
              <IconBell />
            </Box>
            {/* <div
              onClick={() => {
                goToUrl(ROUTE_PATH.ALPHA_MOBILE_MINE);
                gaEventTracker(HeaderActions.ClickPage, addressL2);
              }}
            >
              <IconAirdrop />
            </div> */}
            {/* <div
              onClick={() => {
                goToUrl(ROUTE_PATH.ALPHA_MOBILE_MINE);
                gaEventTracker(HeaderActions.ClickPage, addressL2);
              }}
            >
              <IconAirdrop />
            </div> */}
            {/* <div
              onClick={() => {
                goToUrl(ROUTE_PATH.ALPHA_MOBILE_GAMES);
                gaEventTracker(HeaderActions.ClickPage, addressL2);
              }}
              className={s.noti}
            >
              <IconGames />
            </div> */}
          </div>

          <button className={s.profileBtn} onClick={goToProfilePage}>
            {!isForceConvert && (
              <img
                alt={'logo-bitcoin-white'}
                src={`${CDN_URL_ICONS}/logo-bitcoin-white.svg`}
              />
            )}
            <span>
              {formatCurrency(
                isForceConvert
                  ? balanceL2.amountFormated
                  : balanceL2.amountBTCFormatted,
                0,
                4
              )}{' '}
              {isForceConvert ? 'TC' : ''}
            </span>

            {isForceConvert && <IcFire />}
          </button>
        </div>
      </header>
      <SwapTcToBTCModal
        isOpen={showConvertTC}
        onClose={() => {
          setShowConvertTC(false);
        }}
      />
      <div className={s.progessBar} id="progress-bar">
        <ProgressBar />
      </div>
    </>
  );
};

export default Header;
