import { WalletContext } from '@/contexts/wallet-context';
import { useWeb3React } from '@web3-react/core';
import { useContext } from 'react';
import ReactGA from 'react-ga4';

export enum BannerActions {
  ClickPlayNow = 'click_play_now',
}

export enum HeaderActions {
  ClickPage = 'click_page',
}

export enum GameFiActions {
  CreateIngameWallet = 'create_ingame_wallet',
  TopUp = 'topup',
  ViewGame = 'view_game',
  DownloadApk = 'download_apk',
  PlayInBrower = 'play_in_your_browser',
  ViewGameInSideBar = 'view_game_side_bar',
  ClickTrendingGames = 'click_tab_trending_games',
  ClickLeaderboard = 'click_tab_leaderboard',
  ClickGetOnAllowlist = 'click_get_on_allowlist',
  ClickBeFirstToPlay = 'click_be_first_to_play',
  ClickPractice = 'click_practice_game',
  ClickLearmore = 'click_learmore_at_mega_whales',
  ClickInstallApp = 'click_install_app',
  ClickLinkInstallApp = 'click_link_install_app',
  ClickPracticeBlockchainGame = 'click_practice_blockchain_game',
  ClickInviteFriendAndEarn = 'click_invite_friend_and_earn',
  ClickLearmoreMegaWheels = 'click_learnmore_mega_wheel',
  ClickCopyInviteMegaWheelsLink = 'click_copy_invite_link_megawheel',
  ClickTweetInviteMegaWheels = 'click_tweet_invite_megawheel',
  SetAvatarSuccess = 'set_avatar_success',
  SetNameSuccess = 'set_name_success',
  SetReferralCodeStart = 'set_referral_code_start',
  SetReferralCode = 'set_referral_code',
  SetReferralCodeManual = 'set_referral_code_manual',
  SetReferralCodeAutomatic = 'set_referral_code_automatic',
  CopyReferralCode = 'copy_referral_code',
  ShareReferralCode = 'share_referral_code',
  TweetReferralCode = 'tweet_referral_code',
  TweetReferralCodeToExactUser = 'tweet_referral_code_to_exact_user',
  TweetPlaceHolderKeyReferralCode = 'tweet_placeholder_key_referral_code',
  TweetTabPlaceHolderReferralCode = 'tweet_tab_placeholder_referral_code',
  ClickInviteAndEarnButton = 'click_invite_and_earn_button',
  ClickInviteBanner = 'click_invite_banner',

  PopupReferralInviteYourFriendButtonClick = 'popup_referral_invite_your_friend_button_click',
  PopupReferralOpenModal = 'popup_referral_open_modal',
  PopupReferralLaterButtonClick = 'popup_referral_later_button_click',
  PopupReferralCopyReferralCode = 'popup_referral_copy_referral_code',
  PopupReferralShareReferralCode = 'popup_referral_share_referral_code',
  PopupReferralTweetReferralCode = 'popup_referral_tweet_referral_code',

  ShareTwitterAfterJoinPublicWheel = 'share_twitter_after_join_public_wheel',

  ReferralClaimClick = 'referral_claim_click',
}

export enum TopupActions {
  ChangeTap = 'change_tap',
  PostTweet = 'post_tweet',
  SubmitTweetURL = 'submit_tweet_url',
  BuyTC = 'buy_tc',
  CustomTC = 'custom_tc',
  PaymentCurrency = 'payment_currency',
  BuyMore = 'buy_more',
}

export enum AlphaActions {
  InstallApp = 'install_app',
  TopUp = 'app_topup',
  TopUpSuccess = 'app_topup_success',
  TopUpBTCSuccess = 'app_topup_btc_success',
  ImportPrivateKey = 'import_private_key',
  ClickTab = 'click_tab',
  ClickDeposit = 'click_deposit',
  ClickDepositOnMobile = 'click_deposit_on_mobile',
  ClickDepositMetamask = 'click_deposit_metamask',
  ClickCopyDepositAddress = 'click_copy_deposit_address',
  InstallAlphaApp = 'install_alpha_app',
  CreateNewWallet = 'create_new_wallet',
  LinkTwSuccess = 'link_tw_success',
  OpenPracticeGame = 'open_practice_game',
  PlayPracticeGame = 'play_practice_game',
  ClickLinkTw = 'click_link_tw',
  InstallNBCApp = 'install_nbc_app',
  IssuedKey = 'issue_key',
  SweepTradeKey = 'sweep_buy_key',
  TradeKey = 'buy_key',
  DeskTopBuyNBC = 'desktop_buy_nbc',
  DeskTopSellNBC = 'desktop_sell_nbc',
  DeskTopBuyFT = 'desktop_buy_ft',
  DeskTopSellFT = 'desktop_sell_ft',
  SellKey = 'sell_key',
  BuyKey = 'buy_key',
  Request33 = 'request_33',
  ReRequest33 = 're_request_33',
  Trade33 = 'trade_33',
  CancelTrade33 = 'cancel_trade_33',
  EnterDepositCode = 'enter_deposit_code',
  SpreedContent = 'spreed_content',
  ClickDonateBanner = 'click_donate_banner',
  OpenLimitOrder = 'open_limit_order',
  CancelLimitOrder = 'cancel_limit_order',
  PageView = 'app_page_view',
  OpenDonateModal = 'open_donate_modal',
  ClickSendDonate = 'click_send_donate',
  SendDonateSuccess = 'send_donate_success',
  SendDonateFail = 'send_donate_fail',
  ClickClaim = 'click_claim',
  ClaimSuccess = 'claim_success',
  ClaimFail = 'claim_fail',
  ClickSendTip = 'click_send_tip',
  SendTipSuccess = 'send_tip_success',
  SendTipFail = 'send_tip_fail',
  ClickJam = 'click_jam',
  ClickCopyLinkAndroid = 'click_copy_link_android',
  OpenNotEnoughStorage = 'open_not_enough_storage',
  VerifyTwitter = 'verify_twitter',
  OpenCreateWheelModal = 'open_create_wheel_modal',
  ClickSendFeeRaffle = 'click_send_fee_raffle',
  SendFeeRaffleSuccess = 'send_fee_raffle_success',
  SendFeeRaffleFail = 'send_fee_raffle_fail',
}

export enum GameActions {
  UnityStartLoad = 'unity_start_load',
  UnityLoadError = 'unity_load_error',
  UnityLoaded = 'unity_loaded',
  UnityBootstrapLoad = 'unity_bootstrap_load',
  UnityGameLoad = 'unity_game_load',
  OpenPracticeGame = 'open_practice_game',
  PlayPracticeGame = 'play_practice_game',
  CreateTournament = 'tournament_create',
  CreateTournamentSuccess = 'tournament_create_success',
  CreateTournamentFail = 'tournament_create_fail',
  VisitTournament = 'tournament_visit',
  OpenCreateTournamentModal = 'tournament_open_create_modal',
  PressPlay = 'tournament_press_play',
  PressRule = 'tournament_press_rule',
  TournamentOpenPractice = 'tournament_press_open_practice',
  TournamentPlayPractice = 'tournament_press_play_practice',
  NotEnoughBTC = 'tournament_not_enough_btc',
  AutoByTC = 'tournament_auto_buy_btc',
}

const useAnalyticsEventTracker = () => {
  const { account } = useWeb3React();
  const { addressL2 } = useContext(WalletContext);

  const eventTracker = (action: string, label = '') => {
    let pathname = '';
    if (typeof window !== 'undefined') {
      pathname = window.location.pathname;
    }
    const params = JSON.stringify({
      nosAddress: (account || '').replace('0x', ''),
      gameFiAddress: (addressL2 || '').replace('0x', ''),
    });
    ReactGA.send({
      hitType: action,
      page: window.location.pathname + window.location.search,
      title: pathname,
      label: label.replace('0x', ''),
      nosAddress: account,
      gameFiAddress: addressL2,
    });
    ReactGA.event(
      { category: pathname, action, label: label.replace('0x', '') },
      params
    );
  };
  return eventTracker;
};

export default useAnalyticsEventTracker;
