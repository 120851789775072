export const IcLimit = ()=>{
  return (
    <svg height="28" width="28" fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" fill="black" r="14"/>
      <path d="M13.9999 6.0666C14.2575 6.0666 14.4665 5.85753 14.4665 5.59993V3.73327C14.4665 3.47567 14.2575 3.2666 13.9999 3.2666C13.7423 3.2666 13.5332 3.47567 13.5332 3.73327V5.59993C13.5332 5.85753 13.7423 6.0666 13.9999 6.0666Z" fill="url(#paint0_linear_18462_9091)"/>
      <path d="M20.941 6.39929C20.9373 6.40303 20.9336 6.40676 20.9294 6.41096L19.6096 7.73069C19.4276 7.91316 19.4276 8.20856 19.6096 8.39056C19.6969 8.47783 19.8159 8.52729 19.9396 8.52729C20.0632 8.52729 20.1818 8.47829 20.2695 8.39056L21.5892 7.07083C21.7745 6.89163 21.7796 6.59623 21.6009 6.41096C21.4217 6.22569 21.1263 6.22056 20.941 6.39929Z" fill="url(#paint1_linear_18462_9091)"/>
      <path d="M24.2664 13.5332H22.3998C22.1422 13.5332 21.9331 13.7423 21.9331 13.9999C21.9331 14.2575 22.1422 14.4665 22.3998 14.4665H24.2664C24.524 14.4665 24.7331 14.2575 24.7331 13.9999C24.7331 13.7423 24.524 13.5332 24.2664 13.5332Z" fill="url(#paint2_linear_18462_9091)"/>
      <path d="M5.59993 13.5332H3.73327C3.47567 13.5332 3.2666 13.7423 3.2666 13.9999C3.2666 14.2575 3.47567 14.4665 3.73327 14.4665H5.59993C5.85753 14.4665 6.0666 14.2575 6.0666 13.9999C6.0666 13.7423 5.85753 13.5332 5.59993 13.5332Z" fill="url(#paint3_linear_18462_9091)"/>
      <path d="M14 0C6.2678 0 0 6.2678 0 14C0 21.7322 6.2678 28 14 28C21.7322 28 28 21.7322 28 14C27.9911 6.27153 21.7285 0.00886667 14 0ZM1.9852 19.1333C-0.8512 12.4992 2.22787 4.8216 8.862 1.9852C10.486 1.2908 12.2337 0.933333 14 0.933333C21.2151 0.931933 27.0653 6.7802 27.0667 13.9953C27.0667 15.7617 26.7092 17.5093 26.0148 19.1333H1.9852Z" fill="url(#paint4_linear_18462_9091)"/>
      <path d="M6.81315 6.15963C6.62742 6.02056 6.36702 6.03876 6.20322 6.20303C6.03895 6.36729 6.02028 6.62723 6.15982 6.81296C6.76882 7.62543 12.1252 14.7645 12.6801 15.3198C13.0324 15.6726 13.5014 15.8668 13.9998 15.8668C14.4982 15.8668 14.9672 15.6726 15.3196 15.3198C15.6724 14.9675 15.8665 14.4985 15.8665 14.0001C15.8665 13.5017 15.6724 13.0327 15.3196 12.6804C14.7652 12.126 7.62515 6.76909 6.81269 6.16009L6.81315 6.15963Z" fill="url(#paint5_linear_18462_9091)"/>
      <defs>
        <linearGradient id="paint0_linear_18462_9091" gradientUnits="userSpaceOnUse" x1="13.5332" x2="14.4665" y1="4.6666" y2="4.6666">
          <stop stopColor="#00F5A0"/>
          <stop offset="1" stopColor="#00D9F5"/>
        </linearGradient>
        <linearGradient id="paint1_linear_18462_9091" gradientUnits="userSpaceOnUse" x1="19.4731" x2="21.7317" y1="7.39792" y2="7.39792">
          <stop stopColor="#00F5A0"/>
          <stop offset="1" stopColor="#00D9F5"/>
        </linearGradient>
        <linearGradient id="paint2_linear_18462_9091" gradientUnits="userSpaceOnUse" x1="21.9331" x2="24.7331" y1="13.9999" y2="13.9999">
          <stop stopColor="#00F5A0"/>
          <stop offset="1" stopColor="#00D9F5"/>
        </linearGradient>
        <linearGradient id="paint3_linear_18462_9091" gradientUnits="userSpaceOnUse" x1="3.2666" x2="6.0666" y1="13.9999" y2="13.9999">
          <stop stopColor="#00F5A0"/>
          <stop offset="1" stopColor="#00D9F5"/>
        </linearGradient>
        <linearGradient id="paint4_linear_18462_9091" gradientUnits="userSpaceOnUse" x1="0" x2="28" y1="14" y2="14">
          <stop stopColor="#00F5A0"/>
          <stop offset="1" stopColor="#00D9F5"/>
        </linearGradient>
        <linearGradient id="paint5_linear_18462_9091" gradientUnits="userSpaceOnUse" x1="6.06641" x2="15.8665" y1="10.9666" y2="10.9666">
          <stop stopColor="#00F5A0"/>
          <stop offset="1" stopColor="#00D9F5"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
