import React from "react";

export function IcEnableNotification () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.2144 5.60718C17.2144 7.15426 15.9559 8.41277 14.4088 8.41277C12.8617 8.41277 11.6032 7.15426 11.6032 5.60718C11.6032 4.0601 12.8617 2.8016 14.4088 2.8016C15.9559 2.8016 17.2144 4.0601 17.2144 5.60718ZM15.6112 9.84762C15.2104 9.95183 14.8096 10.016 14.4088 10.016C13.2401 10.0138 12.12 9.54866 11.2936 8.72231C10.4673 7.89597 10.0021 6.77581 10 5.60718C10 4.42884 10.4649 3.36272 11.2024 2.56914C11.0569 2.39081 10.8735 2.24719 10.6655 2.14874C10.4575 2.05028 10.2301 1.99947 10 2C9.11825 2 8.39681 2.72144 8.39681 3.60319V3.83566C6.01607 4.54106 4.38883 6.72942 4.38883 9.21436V14.0239L2.78564 15.6271V16.4287H17.2144V15.6271L15.6112 14.0239V9.84762ZM10 18.8335C10.8898 18.8335 11.6032 18.1201 11.6032 17.2303H8.39681C8.39681 17.6555 8.56572 18.0633 8.86637 18.3639C9.16703 18.6646 9.57481 18.8335 10 18.8335Z"
        fill="black"
      />
      <path
        d="M17.2144 5.6071C17.2144 7.15417 15.9559 8.41268 14.4089 8.41268C12.8618 8.41268 11.6033 7.15417 11.6033 5.6071C11.6033 4.06002 12.8618 2.80151 14.4089 2.80151C15.9559 2.80151 17.2144 4.06002 17.2144 5.6071Z"
        fill="#FF4747"
      />
    </svg>
  )

}
