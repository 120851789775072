import React from 'react';

const PinChatSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.1652 3.09445C13.9981 2.92647 13.7711 2.83173 13.5342 2.83106C13.2973 2.83039 13.0698 2.92384 12.9018 3.09087C12.8132 3.18123 12.7446 3.28933 12.7008 3.40806C11.9574 4.95914 11.139 5.83386 10.1061 6.35029C8.94725 6.92212 7.61686 7.31971 5.47251 7.31971C5.29612 7.32055 5.12388 7.37326 4.97723 7.47129C4.83059 7.56931 4.71603 7.70832 4.64783 7.87099C4.5803 8.03432 4.56268 8.21399 4.59719 8.38732C4.6317 8.56065 4.71679 8.71987 4.84171 8.84489L7.73927 11.7424L3.68555 17.148L9.0911 13.0943L11.9878 15.9909C12.0707 16.0746 12.1698 16.1406 12.279 16.1848C12.388 16.2304 12.5042 16.2545 12.6204 16.2545C12.7365 16.2545 12.8527 16.2304 12.9617 16.1848C13.1251 16.1178 13.2648 16.0037 13.3631 15.857C13.4615 15.7103 13.5139 15.5376 13.5138 15.361C13.5138 13.2167 13.9105 11.8863 14.4815 10.7453C14.997 9.71245 15.8717 8.89403 17.4237 8.15065C17.5424 8.10718 17.6503 8.0386 17.74 7.94962C17.907 7.78159 18.0005 7.55411 17.9998 7.31719C17.9991 7.08027 17.9044 6.85332 17.7364 6.68624L14.1652 3.09445Z"
        fill="#F8F0AC"
      />
    </svg>
  );
};

export default PinChatSvg;
