import React from 'react';

const IcBanner33 = () => {
  return (
    <svg width="94" height="80" viewBox="0 0 94 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M82.0391 46.0859C82.0391 58.1792 75.7296 68.8265 66.1339 74.7416C65.6081 75.0045 65.0823 75.3989 64.688 75.6618C59.8244 78.4222 54.1721 79.9996 48.2569 79.9996C42.7361 79.9996 37.4782 78.6851 32.8775 76.319C21.8358 70.6667 14.3433 59.2307 14.3433 46.0859C14.3433 27.4203 29.4598 12.3037 48.1255 12.3037C54.3035 12.3037 59.9558 13.8811 64.9509 16.773C68.3685 18.6132 71.2604 21.1107 73.7579 24.1341C74.2837 24.6598 74.8095 25.3171 75.2038 25.9743C79.5416 31.4952 82.0391 38.4619 82.0391 46.0859Z" fill="white" fill-opacity="0.2"/>
      <path d="M39.7123 12.0411V48.1894C39.7123 51.4756 37.0833 54.1046 33.7971 54.1046C33.5342 54.1046 33.2713 54.1046 32.877 54.1046C29.9851 53.7103 27.7505 51.2127 27.7505 48.1894V12.0411C27.7505 9.01784 29.9851 6.52032 32.877 6.12598C33.1399 6.12598 33.4028 6.12598 33.7971 6.12598C35.5059 6.12598 36.9519 6.78322 38.0035 7.8348C39.055 8.88639 39.7123 10.3323 39.7123 12.0411Z" fill="#95A4FC"/>
      <path d="M51.5424 28.3406V64.4888C51.5424 67.775 48.9134 70.404 45.6272 70.404C45.3643 70.404 45.1014 70.404 44.7071 70.2726C41.8152 69.8782 39.5806 67.3807 39.5806 64.3574V28.3406C39.5806 25.3173 41.8152 22.8197 44.7071 22.4254C44.9699 22.4254 45.2328 22.2939 45.6272 22.2939C47.336 22.2939 48.7819 22.9512 49.8335 24.0028C50.8851 25.1858 51.5424 26.6317 51.5424 28.3406Z" fill="#95A4FC"/>
      <path d="M63.5048 32.2849V68.4332C63.5048 71.7194 60.8758 74.3483 57.5896 74.3483C57.3267 74.3483 57.0638 74.3483 56.6695 74.2169C53.7776 73.8226 51.543 71.325 51.543 68.3017V32.2849C51.543 29.2616 53.7776 26.8955 56.6695 26.3697C56.9323 26.3697 57.1952 26.2383 57.5896 26.2383C59.2984 26.2383 60.7444 26.8955 61.7959 27.9471C62.7161 29.1301 63.5048 30.5761 63.5048 32.2849Z" fill="#95A4FC"/>
      <path d="M41.2899 68.6953C40.6326 69.2211 39.8439 69.6155 39.0552 69.8783C36.5577 70.5356 33.7973 69.6155 32.0885 67.5123L14.343 44.2459C12.1083 41.3541 12.6341 37.1477 15.6574 34.9131L15.7889 34.7817C16.9719 33.993 18.2864 33.5986 19.6009 33.5986C21.5726 33.5986 23.5443 34.5188 24.8588 36.2276L42.4729 59.4939C44.7075 62.3858 44.1817 66.5921 41.2899 68.6953Z" fill="#7F3F98"/>
      <path d="M27.7507 40.0396V61.9915L14.343 44.2459C12.1083 41.3541 12.6341 37.1477 15.6574 34.9131L15.7889 34.7817C16.9719 33.993 18.2864 33.5986 19.6009 33.5986C21.5726 33.5986 23.5443 34.5188 24.8588 36.2276L27.7507 40.0396Z" fill="#95A4FC"/>
      <path d="M75.3347 20.7169V46.8751C75.3347 61.8602 66.1334 63.832 66.1334 67.1182V74.7422C65.6076 75.0051 65.0818 75.3994 64.6874 75.6623C59.8238 78.4227 54.1716 80.0001 48.2564 80.0001C42.7356 80.0001 37.4776 78.6856 32.877 76.3195V38.8568L60.0867 38.0681H61.5327L62.0585 38.4624L63.5044 39.3826V20.7169C63.5044 19.1396 64.1616 17.6936 65.0818 16.642C66.0019 15.5904 67.3164 14.9332 68.7623 14.8018C69.0252 14.8018 69.2881 14.8018 69.551 14.8018C72.7058 14.8018 75.3347 17.4307 75.3347 20.7169Z" fill="#95A4FC"/>
      <path d="M49.834 28.3408V33.5987H51.4114V32.2842C51.4114 29.2609 53.646 26.8948 56.5378 26.369C57.8523 26.5005 59.0354 27.1577 59.824 28.0778C61.0071 29.1294 61.6643 30.5753 61.6643 32.2842V36.3591H63.5046V20.7167C63.5046 19.1393 64.1619 17.6934 65.082 16.6418C66.0021 15.5902 67.3166 14.933 68.7626 14.8015C71.523 15.1959 73.889 17.6934 73.889 20.7167V46.8749C73.889 57.0807 69.621 61.2503 66.8985 63.91C65.6236 65.1555 64.6877 66.0699 64.6877 67.118V75.5306C59.8241 78.2911 54.1718 79.8684 48.2566 79.8684C42.7358 79.8684 37.4779 78.554 32.8772 76.1879V68.3363C32.5955 68.0863 32.3314 67.8112 32.0885 67.5122L14.343 44.2459C12.1083 41.354 12.6341 37.1477 15.6574 34.9131L15.7889 34.7816C16.3147 34.6502 16.9719 34.5187 17.4977 34.5187C19.4694 34.5187 21.4412 35.4389 22.7556 37.1477L27.6192 43.572V12.0411C27.6192 9.01784 29.8539 6.52032 32.7457 6.12598C34.0602 6.25742 35.2432 6.91467 36.0319 7.8348C37.215 8.88639 37.8722 10.3323 37.8722 12.0411V31.6269H39.581V28.3408C39.581 25.3175 41.8156 22.8199 44.7075 22.4256C46.022 22.557 47.205 23.2143 47.9937 24.1344C49.1767 25.186 49.834 26.6319 49.834 28.3408Z" fill="#D1D2FD"/>
      <path d="M51.8432 9.24311C53.5856 9.24311 54.998 7.83068 54.998 6.08835C54.998 4.34603 53.5856 2.93359 51.8432 2.93359C50.1009 2.93359 48.6885 4.34603 48.6885 6.08835C48.6885 7.83068 50.1009 9.24311 51.8432 9.24311Z" fill="#BAEDBD"/>
      <path d="M90.8457 40.4345C92.588 40.4345 94.0004 39.0221 94.0004 37.2798C94.0004 35.5374 92.588 34.125 90.8457 34.125C89.1034 34.125 87.6909 35.5374 87.6909 37.2798C87.6909 39.0221 89.1034 40.4345 90.8457 40.4345Z" fill="#B1E3FF"/>
      <path d="M84.9159 3.3072H81.6087V0H78.774V3.3072H75.4668V6.23643H78.774V9.54362H81.6087V6.23643H84.9159V3.3072Z" fill="#FFE899"/>
      <path d="M9.85613 19.0389H6.64145V15.8242H3.886V19.0389H0.786133V21.7943H3.886V24.8942H6.64145V21.7943H9.85613V19.0389Z" fill="#95A4FC"/>
    </svg>

  );
};

export default IcBanner33;
