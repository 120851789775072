import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import { useMemo } from 'react';

const FriendTechIcon = ({
  size = 24,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  return (
    <SvgInset
      size={size}
      svgUrl={`${CDN_URL_ICONS}/chat-circle-friendtech-icon.svg`}
      className={className}
    />
  );
};

export default FriendTechIcon;
