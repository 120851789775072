import React, { useEffect, useRef, useState } from 'react';
import s from './styles.module.scss';
import { CDN_URL_ICONS } from '@/configs';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { formatNumberAbbreviation } from '@/utils/format';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import cs from 'classnames';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import {
  DMMessage,
  EnvelopContent,
} from '@/modules/AlphaPWA/DirectMessage/types';

interface IProps {
  donatorName: string;
  avatar: string;
  // follower: number;
  keyNum: number;
  // twitterUsername: string;
  className?: string;

  data?: EnvelopContent;
}

const OpenedEnvelop: React.FC<IProps> = ({
  donatorName,
  avatar,
  // follower,
  keyNum,
  // twitterUsername,
  className,
  data,
}: IProps): React.ReactElement => {
  // const twitterUrl = `https://twitter.com/${twitterUsername}`;
  const router = useRouter();
  const targetRef = useRef<any>(null);

  const isInViewport = useIsInViewport(targetRef, { threshold: 0 });
  const [isInViewportOnce, setIsInViewportOnce] = useState(isInViewport);

  useEffect(() => {
    if (isInViewport) {
      setIsInViewportOnce(isInViewport);
    }
  }, [isInViewport]);

  return (
    <div
      className={cs(
        s.openedEnvelop,
        className,
        isInViewportOnce ? '' : s.loadPlaceholder,
        'openedEnvelop'
      )}
      ref={targetRef}
      onClick={() => {
        if (data?.rewardInfo?.address) {
          window.open(
            `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data?.rewardInfo?.address}`
          );
        }
      }}
    >
      {isInViewportOnce && (
        <>
          <div className={`${s.header} header`}>
            <img
              className={s.icEnvelop}
              src={`${CDN_URL_ICONS}/ic-read-envelop.png`}
              alt="ic-read-envelop"
            />
            <span>Red Packet opened ✨</span>
          </div>
          <div
            className={s.body}
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          >
            <div className={`${s.content} content`}>
              <Avatar url={avatar} className={s.avatar} width={64} />
              <div className={`${s.donatorInfo} donatorInfo`}>
                <p className={`${s.description} description`}>
                  {`Received ${keyNum}`}{' '}
                  <span className={s.donatorName}>{donatorName}</span>{' '}
                  {`key${labelAmountOrNumberAdds(keyNum)}`}
                </p>
              </div>
              {/* <div className={s.actions}>
            <a href={twitterUrl} target='_blank' rel='noreferrer' className={s.twitterBtn}>
              <img
                className={s.icTwitter}
                src={`${CDN_URL_ICONS}/bi_twitter-x.svg`}
                alt="ic-twitter-x"
              />
              <span className={s.followerText}>
                <span className={s.follower}>{formatNumberAbbreviation(follower)}</span> followers
              </span>
            </a>
          </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(OpenedEnvelop);
