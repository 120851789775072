import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';

type Props = {
  src: string;
  size?: number;
  speed?: number;
};

const LottieWrapper = ({ src, speed = 1.5, size = 40, ...props }: Props) => {
  const ref = useRef(null);

  const [data, setData] = useState<any>();
  const fetchJson = () => {
    fetch(src)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setData(data);
      })
      .catch((e: Error) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    fetchJson();
  }, []);

  useEffect((): void => {
    // @ts-ignore
    ref.current?.setSpeed(speed);
  }, []);

  return (
    <Box width={`${size}px`} height={`${size}px`}>
      <Lottie ref={ref} animationData={data} {...props} />
    </Box>
  );
};

export default LottieWrapper;
