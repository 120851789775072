import SvgInset from '@/components/SvgInset';
import { CDN_URL } from '@/configs';
import { TOP_CENTER_LEFT, TOP_MENU_RIGHT } from '@/constants/header';
import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import useWindowSize from '@/hooks/useWindowSize';
import useAnalyticsEventTracker, { HeaderActions } from '@/utils/ga';
import { useDisclosure } from '@chakra-ui/react';
import cs from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import MobileMenu from '../MobileMenu';
import WalletConnectV3 from '../WalletConnectV3';
import s from './styles.module.scss';

const Header = ({
  isHideMenu,
  isWallet,
  subHeader,
  isLight,
  isGame,
  isAi,
  isHome,
  isArcade,
}: {
  isHideMenu?: boolean;
  isWallet?: boolean;
  subHeader?: React.ReactNode;
  isLight?: boolean;
  isGame?: boolean;
  isAi?: boolean;
  isHome?: boolean;
  isArcade?: boolean;
}) => {
  const gaEventTracker = useAnalyticsEventTracker();
  const { handleShowGetTcModal } = useContext(WalletContext);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [isScrollOut, setIsScrollOut] = useState(false);

  const { xSMobileScreen } = useWindowSize();
  const router = useRouter();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const activePath = router.pathname.split('/')[1];

  const onOpenMobileMenu = () => {
    setOpenMobileMenu(true);
  };

  const onCloseMobileMenu = () => {
    setOpenMobileMenu(false);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 10) {
      setIsScrollOut(true);
    } else {
      setIsScrollOut(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cs(s.container, {
        [`${s.container__light}`]: isLight,
        [`${s.container__dark}`]: !isLight && !isGame && !isHome,
        [`${s.container__ai}`]: isAi,
        [`${s.container__game}`]: isGame,
        [`${s.container__gameActive}`]: isGame && isScrollOut,
        [`${s.container__home}`]: isHome,
        [`${s.container__homeActive}`]: isHome && isScrollOut,
        [`${s.container__arcade}`]: isArcade,
      })}
      id="header"
    >
      <div id="header-hidden" onClick={() => isOpen && onClose()} />
      <header className={`${s.header}`}>
        <Link
          className={cs(s.navbarBrand, {
            [`${s.navbarBrand__light}`]: isLight,
          })}
          href={ROUTE_PATH.HOME}
        >
          New Bitcoin City
        </Link>
        <div
          className={cs(s.leftContent, {
            [`${s.leftContent__light}`]: isLight,
          })}
        >
          {!isHideMenu && (
            <div className={s.leftMenu}>
              <ul className={cs(s.navBar)}>
                {TOP_CENTER_LEFT.map(menu => {
                  return (
                    <li className={s.navItem} key={`header-${menu.activePath}`}>
                      <a
                        className={cs(s.navLink, {
                          [`${s.navLink__active}`]:
                            activePath === menu.activePath,
                          // [`${s.navLink__builder}`]: menu.activePath === 'tc',
                        })}
                        href={menu.route}
                        onClick={() => {
                          gaEventTracker(HeaderActions.ClickPage, menu.name);
                          if (menu?.isExternal) {
                            window.open(
                              'https://newbitcoincity.com/trustless-computer',
                              '_self'
                            );
                          }
                        }}
                      >
                        {menu.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div className={s.navBarIndicator} />
              {/* <ul className={cs(s.navBar)}>
                {TOP_MENU_RIGHT.map(menu => {
                  return (
                    <li className={s.navItem} key={`header-${menu.activePath}`}>
                      <a
                        className={cs(s.navLink, {
                          [`${s.navLink__active}`]:
                            activePath === menu.activePath,
                        })}
                        href={menu.route}
                      >
                        {menu.name}
                      </a>
                    </li>
                  );
                })}
              </ul> */}
            </div>
          )}
        </div>

        <div
          className={cs(s.rightContent, {
            [`${s.rightContent__light}`]: isLight,
            [`${s.rightContent_install}`]: activePath !== 'tc',
          })}
        >
          <div className={s.rightMenu}>
            <ul className={s.navBarRight}>
              {/* {activePath !== 'topup' && (
                <div
                  className={cs(s.getTc, { [`${s.getTc_light}`]: isLight })}
                  // target="_blank"
                  onClick={() => handleShowGetTcModal()}
                  // href={'/topup'}
                >
                  GET TC
                </div>
              )} */}

              {/* <li className={s.navItem}>
                <Link
                  className={cs(s.navLink, {
                    [`${s.navLink__active}`]: activePath === 'story',
                  })}
                  href={`${ROUTE_PATH.STORY}`}
                >
                  Our Story
                </Link>
              </li> */}
              {/* {!xSMobileScreen && (
                <div
                  className={cs(s.socialShare, {
                    [`${s.socialShare__light}`]: isLight,
                  })}
                >
                  <Link
                    className={s.link}
                    href={'https://discord.gg/yNbatuGMDG'}
                    target="_blank"
                  >
                    <SvgInset
                      size={20}
                      svgUrl={`${CDN_URL}/icons/ic-discord-18x18.svg`}
                    />
                  </Link>
                  <Link
                    className={s.link}
                    href={'https://twitter.com/NewBitcoinCity'}
                    target="_blank"
                  >
                    <SvgInset
                      size={20}
                      svgUrl={`${CDN_URL}/icons/ic-twitter-18x18.svg`}
                    />
                  </Link>
                </div>
              )} */}

              {activePath === 'tc' && (
                <WalletConnectV3
                  isOpen={isOpen}
                  onClose={onClose}
                  onOpen={onOpen}
                  isLight={isLight}
                />
              )}

              <li className={cs(s.mobileMenuBtn)}>
                <button onClick={onOpenMobileMenu}>
                  <SvgInset
                    svgUrl={
                      isLight
                        ? 'https://cdn.generative.xyz/soul-art/ic-menu-right.svg'
                        : 'https://cdn.generative.xyz/soul-art/ic-menu-right-w.svg'
                    }
                    size={24}
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <MobileMenu show={openMobileMenu} handleClose={onCloseMobileMenu} />
      {subHeader && subHeader}
    </div>
  );
};

export default Header;
