import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { IReferentEarning } from '@/interfaces/api/referral';
import s from './styles.module.scss';
import cs from 'classnames';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { useContext, useEffect, useMemo, useState } from 'react';
import Avatar from '@/components/Avatar';
import {
  convertBtcToUsdt,
  displayBTCValue,
  formatTCAddress,
} from '@/utils/format';
import { WalletContext } from '@/contexts/wallet-context';
import { BigNumber } from 'bignumber.js';
import { formatCurrency } from '@/utils/string';

type Props = {
  player?: IGetPlayerPoolProfile;
  earning: IReferentEarning;
};

const InvitedItem: React.FC<Props> = ({
  player,
  earning,
}: Props): React.ReactElement => {
  const { btc2Usd } = useContext(WalletContext);

  const usdt = useMemo(() => {
    if (!btc2Usd || !earning?.totalBtcEarning) return '-';
    const priceBn = new BigNumber(earning?.totalBtcEarning);
    return `$${formatCurrency(priceBn.times(btc2Usd), 0, 2)}`;
  }, [btc2Usd, earning]);

  return (
    <div className={s.invitedItem}>
      <div className={cs(s.invitedItem__item, s.invitedPlayer)}>
        <div className={s.avatar}>
          {player && (player.twitterAvatar || player.avatar) ? (
            <Avatar
              width={48}
              height={48}
              imgSrcs={player.twitterAvatar || player.avatar}
            />
          ) : (
            <Jazzicon
              diameter={54}
              seed={jsNumberForAddress(player?.address || '')}
            />
          )}
        </div>
        <div className={cs(s.invitedPlayer__info)}>
          <div className={cs(s.invitedItem__item__label)}>
            {player && (
              <>
                {player.twitterName ||
                  (player.twitterUsername && `@${player.twitterUsername}`) ||
                  player.bns ||
                  formatTCAddress(player.address || '')}
              </>
            )}
          </div>
          <div className={cs(s.invitedItem__item__value)}>
            Vol {displayBTCValue(earning.totalBtcTrade)}
            {/* {Number(formatEthPriceInput(ethers.BigNumber.from(earning?.totalEarning || '0').mul(100).toString())).toFixed(2)} TC */}
          </div>
        </div>
      </div>
      <div className={cs(s.invitedItem__item, s.invitedEarning)}>
        <div className={cs(s.invitedItem__item__label)}>
          {displayBTCValue(earning.totalBtcEarning)}
        </div>
        <div className={cs(s.invitedItem__item__value)}>{usdt}</div>
      </div>
    </div>
  );
};

export default InvitedItem;
