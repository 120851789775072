import { IUserGameInfo } from '@/interfaces/api/gamefi';
import {
  IGetPlayerPoolProfile,
  TokenDetail,
} from '@/interfaces/api/player-share';
import React from 'react';

export interface IProfilesContext {
  isProfileLoading: boolean;
  setIsProfileLoading: React.Dispatch<React.SetStateAction<boolean>>;

  yourPoolProfile: IGetPlayerPoolProfile | null;
  playerPoolProfile: IGetPlayerPoolProfile | null;
  setPlayerPoolProfile: React.Dispatch<
    React.SetStateAction<IGetPlayerPoolProfile | null>
  >;

  showCreatePost: boolean;
  setShowCreatePost: React.Dispatch<React.SetStateAction<boolean>>;

  userGameInfo: IUserGameInfo | undefined;
  setUserGameInfo: React.Dispatch<
    React.SetStateAction<IUserGameInfo | undefined>
  >;

  playerTokenDetail: TokenDetail | null;

  isTokenHolding: boolean;
  isTokenHolded: boolean;
  isTokenHoldingLoading: boolean;
  keyBalance: number;
  keyRemaining: number;
  isFTToken: any;
  holderLists: any[];
}

export default React.createContext<IProfilesContext>({} as IProfilesContext);
