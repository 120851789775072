import {
  MultiSigWalletsData,
  getMultiSigWallets,
} from '@/services/bitcoinWallets';
import { getErrorMessage } from '@/utils/error';
import { Spinner } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { itemDecorator } from './Page.helper';
import { Item, PageProps } from './Page.types';
import * as formatter from 'tc-formatter';
import CContract from '@/contracts/contract';
import BigNumber from 'bignumber.js';

const enhanFetchData =
  (WrappedComponent: FC<PageProps>) => (props: PageProps) => {
    const [isDataFetching, setDataFetching] = useState(true);
    const [data, setData] = useState<MultiSigWalletsData | undefined>(
      undefined
    );
    const [mintedOnNOS, setMintedOnNOS] = useState('0');
    const [totalValueLocked, setTotalValueLocked] = useState('0');

    const contracInstance = new CContract();

    const getMintedOnNOS = async () => {
      const value = await contracInstance.getTotalSupply();
      setMintedOnNOS(value);
    };

    const getTotalValueLock = async () => {
      const value = await contracInstance.getTotalBTCKeyTVLOnL2();
      setTotalValueLocked(value);
    };

    const fetchData = async () => {
      try {
        setDataFetching(true);
        const data = await getMultiSigWallets({});
        setData(data);
        setDataFetching(false);
      } catch (error) {
        const { message } = getErrorMessage(error);
        toast.error(message);
      }
    };

    const walletList = useMemo(() => {
      let list: Item[] = [];
      for (const [key, value] of Object.entries(data?.wallets || {})) {
        const newObject = itemDecorator(key, value);
        list.push(newObject);
      }
      return list;
    }, [data]);

    const totalAmountStr = useMemo(() => {
      return (
        formatter.formatAmount({
          originalAmount: data?.totalAmount || 0,
          decimals: 8,
          isCeil: true,
          maxDigits: 2,
        }) || '0'
      );
    }, [data]);

    const mintedOnNOSStr = useMemo(() => {
      return (
        formatter.formatAmount({
          originalAmount: mintedOnNOS,
          decimals: 18,
          isCeil: true,
          maxDigits: 2,
        }) || '0'
      );
    }, [mintedOnNOS]);

    const totalValueLockedStr = useMemo(() => {
      return (
        formatter.formatAmount({
          originalAmount: totalValueLocked,
          decimals: 18,
          isCeil: true,
          maxDigits: 2,
        }) || '0'
      );
    }, [totalValueLocked]);

    const availableBalanceStr = useMemo(() => {
      return new BigNumber(mintedOnNOSStr)
        .minus(totalValueLockedStr)
        .toFixed(2);
    }, [mintedOnNOSStr, totalValueLockedStr]);

    useEffect(() => {
      getMintedOnNOS();
      getTotalValueLock();
      fetchData();
    }, []);

    return (
      <WrappedComponent
        {...{
          ...props,
          data,
          walletList,
          totalAmountStr,
          isDataFetching,
          mintedOnNOSStr: mintedOnNOSStr,
          totalValueLockedStr: totalValueLockedStr,
          availableBalanceStr: availableBalanceStr,
        }}
      />
    );
  };
export default enhanFetchData;
