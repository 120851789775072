const IconPinnedPost = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g opacity="0.5">
        <path d="M4.13737 7.3291L2.1105 9.35581C1.96317 9.50313 1.96317 9.74126 2.1105 9.88858C2.18398 9.96205 2.28044 9.99898 2.37691 9.99898C2.47337 9.99898 2.56983 9.96205 2.64331 9.88858L4.67018 7.86187L4.13737 7.3291Z" fill="white" />
        <path d="M10.5565 4.08097L7.91881 1.4435C7.77147 1.29618 7.53333 1.29618 7.386 1.4435C7.23866 1.59082 7.23866 1.82895 7.386 1.97627L7.72814 2.31839L3.57531 4.63597L3.02026 4.08097C2.87293 3.93365 2.63479 3.93365 2.48745 4.08097C2.34012 4.22829 2.34012 4.46642 2.48745 4.61374L7.386 9.5119C7.45947 9.58537 7.55594 9.6223 7.6524 9.6223C7.74887 9.6223 7.84533 9.58537 7.91881 9.5119C8.06614 9.36458 8.06614 9.12645 7.91881 8.97913L7.36376 8.42413L9.68153 4.27162L10.0237 4.61374C10.0972 4.68721 10.1936 4.72414 10.2901 4.72414C10.3865 4.72414 10.483 4.68721 10.5565 4.61374C10.7038 4.46642 10.7038 4.22829 10.5565 4.08097Z" fill="white" />
      </g>
    </svg>
  )
}

export default IconPinnedPost;
