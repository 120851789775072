import { FC, useMemo, useState } from 'react';
import { PageProps } from './Page.types';

const enhanSort = (WrappedComponent: FC<PageProps>) => (props: PageProps) => {
  const { walletList, data } = props;
  const [sortType, setSortType] = useState<'desc' | 'asc'>('desc');

  let walletListSorted = useMemo(() => {
    // if (sortType === 'desc') {
    //   return walletList.sort((a, b) => b.amount - a.amount);
    // } else {
    //   return walletList.sort((a, b) => a.amount - b.amount);
    // }

    return walletList;
  }, [walletList, sortType]);

  return (
    <WrappedComponent {...{ ...props, data, walletList: walletListSorted }} />
  );
};

export default enhanSort;
