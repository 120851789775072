import AppLoading from '@/components/AppLoading';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import SvgInset from '@/components/SvgInset';
import { CDN_URL, PERP_API_URL } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import useApiInfinite from '@/hooks/useApiInfinite';
import useOnClickOutside from '@/hooks/useOnClickOutSide';
import { apiClient } from '@/services';
import { formatCurrency, formatName } from '@/utils';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { Box, Flex, Text } from '@chakra-ui/react';
import cs from 'classnames';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import s from './styles.module.scss';
import KeyPresaleIcon from '@/modules/AlphaPWA/Notification/svg/KeyPresaleIcon';
import KeyIcon from '@/modules/AlphaPWA/Profiles/BuyAKey/KeyIcon';
import px2rem from '@/utils/px2rem';
import IcSearch from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icSearch';

const SearchKeys = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [showResult, setShowResult] = useState(false);

  const inputSearchRef = useRef<HTMLInputElement>(null);
  const resultSearchRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(resultSearchRef, () => handleCloseSearchResult());

  const handleCloseSearchResult = (): void => {
    setShowResult(false);
  };

  const getData = async ({ page = 1, limit = 50, search = '' }) => {
    try {
      const data: any[] = await apiClient.get(
        `${PERP_API_URL}/api/nbc-keys/tokens?network=nos&address=&page=${page}&limit=${limit}&search=${search}`
      );
      return data;
    } catch (error) {
      console.log({ error });
    }
    return [];
  };

  const {
    dataInfinite,
    loadMore,
    refresh,
    isReachingEnd,
    hasFirstFetching,
    isLoadingMore,
  } = useApiInfinite(
    getData,
    { search: searchText, limit: 100 },
    { revalidateOnFocus: true }
  );

  const renderItem = (userKey: any) => {
    return (
      <a
        id={`user_${userKey.user_twitter_id}`}
        href={`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${userKey.owner}`}
      >
        <Flex gap={3} alignItems={'center'} width={'100%'} mb="16px" mt="16px">
          <Flex width={'100%'} flex={1} gap={2} alignItems={'center'}>
            <Avatar
              url={getUrlAvatarTwitter(
                (userKey?.user_twitter_avatar || userKey?.avatar) as string,
                'normal',
                Boolean(userKey?.user_twitter_avatar)
              )}
              address={userKey?.owner}
              width={36}
            />
            <Flex gap={2} width={'100%'} direction={'column'}>
              <Flex
                alignItems={'center'}
                maxWidth={'100%'}
                width={'90%'}
                gap={2}
              >
                <Text className={s.buyer}>
                  {formatName(userKey?.user_twitter_name as string, 50)}
                </Text>
                <Flex
                  className={cs(
                    s.keyContainer,
                    userKey.is_pre_sale && s.keyContainerPresale
                  )}
                >
                  {userKey.is_pre_sale ? <KeyPresaleIcon /> : <KeyIcon />}
                </Flex>
              </Flex>
              <Text
                color={'#000000'}
                fontSize={`${px2rem(14)} !important`}
                opacity={'70%'}
              >
                @{userKey?.user_twitter_username}
              </Text>
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={2} alignItems={'flex-end'}>
            <Text className={s.buyer}>
              {formatCurrency(userKey?.price, 0, 2, userKey.base_token_symbol)}{' '}
              {userKey.base_token_symbol}
            </Text>
            <Text
              color={'#000000'}
              fontSize={`${px2rem(14)} !important`}
              opacity={'70%'}
            >
              ${formatCurrency(userKey?.usd_price, 2, 2)}
            </Text>
          </Flex>
        </Flex>
      </a>
    );
  };

  return (
    <div>
      <div className={s.wrapper} ref={wrapperRef}>
        <div className={cs(s.searchInput_wrapper)}>
          <div className={s.searchIcon} onClick={() => setShowResult(true)}>
            <IcSearch></IcSearch>
          </div>
          <input
            className={cs(s.input)}
            onChange={debounce(e => {
              setSearchText(e.target.value);
              setShowResult(true);
            }, 300)}
            onFocus={() => {
              setShowResult(true);
            }}
            ref={inputSearchRef}
            placeholder={'Search...'}
            type="text"
          />
        </div>
        {showResult && hasFirstFetching === false && (
          <div className={s.searchResult_wrapper}>
            <div className={s.loading}>
              <AppLoading />
            </div>
          </div>
        )}
        {hasFirstFetching && showResult && (
          <div className={s.searchResult_wrapper} ref={resultSearchRef}>
            <InfiniteScroll
              className={`hide-scrollbar`}
              height="calc(40dvh)"
              dataLength={dataInfinite?.length || 500}
              hasMore={isReachingEnd === false}
              refreshFunction={refresh}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              next={loadMore}
              loader={
                isLoadingMore && (
                  <div className={s.loading}>
                    <AppLoading />
                  </div>
                )
              }
            >
              {dataInfinite &&
                dataInfinite.length > 0 &&
                dataInfinite.map(userKey => {
                  return renderItem(userKey);
                })}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchKeys;
