import React, { useEffect } from 'react';
import s from './TreasuryDetail.module.scss';
import { CDN_URL_IMAGES } from '@/configs';
import { formatCurrency } from '@/utils';

type Props = {
  show: boolean;
  onClose: () => void;
  balance: string;
};

const TreasuryDetail = ({ show, onClose, balance }: Props) => {
  if (!show) return <></>;

  return (
    <div className={s.wrapper}>
      <div className={s.heading}>
        <div className={s.backIcon} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.4001 16.7998L9.6001 11.9998L14.4001 7.19981"
              stroke="white"
              stroke-width="2"
              strokeLinecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h2>Community Treasury</h2>
      </div>
      <div className={s.body}>
        <div className={s.thumbnail_wrapper}>
          <img
            src={`${CDN_URL_IMAGES}/treasury_thumbnail.png`}
            alt="treasury thumbnail"
          />
        </div>
        <h5>Amount</h5>
        <p className={s.balance}>{formatCurrency(balance, 0, 4)} BTC</p>
        <p className={s.desc}>
          The treasury accumulates trading fees and awaits community-driven
          decisions via a DAO.
          <br />
          Stay tuned for details!
        </p>
      </div>
    </div>
  );
};

export default TreasuryDetail;
