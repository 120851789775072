import GlobalNotification from '@/modules/AlphaPWA/Notification/GlobalNotification';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useContext, useRef, useState } from 'react';
import s from './styles.module.scss';
import Posts from '@/modules/AlphaPWA/Home/Posts';
import { compareString } from '@/utils';
import CreatePost from '@/modules/AlphaPWA/Home/CreatePost';
import { WalletContext } from '@/contexts/wallet-context';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonReducer } from '@/state/common';
// import CreateLuckyWheelButton from '@/components/CreateLuckyWheelButton';

enum TabIndex {
  Feed = 0,
  Activities = 1,
}

const ProKeySocial = () => {
  const [indexTab, setIndexTab] = useState<number>(TabIndex.Feed);
  const postsRef = useRef<any>(null);
  const { isAuthenticated } = useContext(WalletContext);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const dispatch = useDispatch();
  const showRightSide = useSelector(selectCommonReducer).showKeyRight;

  const showCreatePostModal = () => {
    setShowCreatePost(!showCreatePost);
  };

  return (
    <div className={s.container}>
      <Tabs
        className={s.tabs}
        isLazy
        index={indexTab}
        onChange={k => {
          setIndexTab(k);
        }}
      >
        <TabList className={s.tabList}>
          <Tab className={s.tab}>FEED</Tab>
          <Tab className={s.tab}>ACTIVITIES</Tab>
          {/* <div className={s.tab} style={{ marginTop: 4 }}>
            <CreateLuckyWheelButton />
          </div> */}
        </TabList>
        <TabPanels>
          <TabPanel className={s.tabPanel}>
            <Posts ref={postsRef} />
          </TabPanel>
          <TabPanel className={s.tabPanel}>
            <GlobalNotification />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isAuthenticated &&
        indexTab === TabIndex.Feed &&
        compareString(showRightSide, '1') && (
          <button className={s.create_btn} onClick={showCreatePostModal}>
            +
          </button>
        )}
      {showCreatePost && (
        <div className={s.createContent}>
          <CreatePost
            show={showCreatePost}
            onClose={() => setShowCreatePost(false)}
            onFetchData={() => postsRef?.current?.refresh()}
          />
        </div>
      )}
    </div>
  );
};

export default ProKeySocial;
