import { PropsWithChildren } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import React from 'react';
import { isMobile } from 'react-device-detect';

const PullableWrapper: React.FC<PropsWithChildren> = ({
  children,
}): React.ReactElement => {
  const handleRefresh = async () => {
    window.location.reload();
  };

  if (isMobile) {
    return (
      <PullToRefresh onRefresh={handleRefresh}>
        <>{children}</>
      </PullToRefresh>
    );
  }

  return (
    <>{children}</>
  );
};

export default PullableWrapper;
