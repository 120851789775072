import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';

const ChatImageBrowserIcon = ({ size = 24 }: { size?: number }) => {
  return (
    <SvgInset
      size={size}
      svgUrl={`${CDN_URL_ICONS}/chat-image-browser-icon.svg`}
    />
  );
};

export default ChatImageBrowserIcon;
