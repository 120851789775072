export const TRADE_ACTION = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export const DEFAULT_FOLLOWERS_MIN = 0;
export const DEFAULT_FOLLOWERS_MAX = 200000;
export const DEFAULT_TC_MIN = 0;
export const DEFAULT_TC_MAX = 1000;
export const DEFAULT_RED_FLAG_MIN = 0;
export const DEFAULT_RED_FLAG_MAX = 10;
export const DEFAULT_RED_FLAG = 6.9;
export const DEFAULT_TAGGED_MIN = 0;
export const DEFAULT_TAGGED_MAX = 10;

export const DEFAULT_SLIDE = 0;
export const SLIDE_FT = 2;
export const SLIDE_SA = 3;
export const SLIDE_ALPHA = 1;

export const DEFAULT_HOLDING = false;

export const SORT_ASD = 1;
export const SORT_DSD = 0;

export const KEY_FILTER = 'key_filters_2';
export const KEY_SELECT = 'key_selects';
export const PLACE_HOLDER_KEY_FILTER = 'place_holder_key_filter_3';
export const PLACE_HOLDER_KEY_SELECT = 'place_holder_key_selects_3';
export const NUMBER_OF_KEYS = [0.1, 0.5, 1, 2, 5];
export const NUMBER_OF_KEYS_IN_FT = [1, 2, 3, 4, 5];
export const SHOW_KEY_LEFT = 'show_key_left';
export const SHOW_KEY_RIGHT = 'show_key_right';
export const PLACE_HOLDER_LAST_SORT = 'placeholder_last_sort';
export const PLACE_HOLDER_LAST_SORT_VALUE = 'placeholder_last_sort_value';
export const KEY_LAST_SORT = 'key_last_sort';
export const KEY_LAST_SORT_VALUE = 'key_last_sort_value';
export const KEY_LIST_VIEW = 'key_list_view';
export const KEY_SWAP_FILTER = 'key_swap_filter';

export const MIN_DECIMAL = 0;
export const MAX_DECIMAL = 6;
export const MIN_KEY_AMOUNT = 0.000001;
export const LARGE_TOKEN_DECIMAL = 2;

export const labelAmountOrNumber = (amount: number) => {
  return Number(amount) < 1 ? 'Amount' : 'Number';
};

export const labelAmountOrNumberAdds = (amount: number) => {
  return (Number(amount) < 1 || Number(amount) > 1) && Number(amount) !== 0
    ? 's'
    : '';
};
