import { CDN_URL_ICONS } from '@/configs';
import { AssetsContext } from '@/contexts/assets-context';
import { abbreviateNumber, formatCurrency } from '@/utils';
import { useContext, useEffect, useState } from 'react';
import s from './Tvl.module.scss';
import InfoTooltip from '@/modules/AlphaPWA/Profiles/TradeKey/components/infoTooltip';
import { Tooltip } from '@chakra-ui/react';
import { WalletContext } from '@/contexts/wallet-context';

const TVL = () => {
  const { tvlBalance: balance } = useContext(AssetsContext);
  const { handleGetBTC2USD } = useContext(WalletContext);
  const [btc2USD, setBTC2USD] = useState(0);

  useEffect(() => {
    getBTC2USD();
  }, []);

  const getBTC2USD = async () => {
    try {
      const response = await handleGetBTC2USD();
      setBTC2USD(response);
    } catch (error) {}
  };

  return (
    <Tooltip
      label={`$${abbreviateNumber(Number(btc2USD) * parseFloat(balance))}`}
    >
      <div className={s.wrapper}>
        <span className={s.textDetail} style={{ marginRight: '4px' }}>
          TVL
        </span>
        <img
          alt={'logo-bitcoin-white'}
          src={`${CDN_URL_ICONS}/logo-bitcoin-white.svg`}
        ></img>
        {formatCurrency(balance, 0, 4)}
      </div>
    </Tooltip>
  );
};

export default TVL;
