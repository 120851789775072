import React, { } from 'react';
import SuggestionFollowList from '../SuggestionFollowList';

export const SUGGESTION_FOLLOW_LIST = 'SUGGESTION_FOLLOW_LIST';

const SuggestionFollowListModal: React.FC = ({
}): React.ReactElement => {
  return (
    <SuggestionFollowList
      renderType='modal'
    />
  );
};

export default React.memo(SuggestionFollowListModal);
