import { CLOSE_CIRCLE_BANNER } from '@/constants/storage-key';
import {
  CloseButton,
  Collapse,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import s from './styles.module.scss';
import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';

const Banner = () => {
  const hasCloseForgingBanner = localStorage.getItem(CLOSE_CIRCLE_BANNER);

  const { isOpen, onToggle } = useDisclosure({
    id: 'collapse_banner_placeholder',
    defaultIsOpen: !hasCloseForgingBanner,
  });

  const onClose = () => {
    localStorage.setItem(CLOSE_CIRCLE_BANNER, '1');
    onToggle();
  };

  return (
    <Collapse className={s.bannerContainer} in={isOpen} animateOpacity>
      <Flex
        gap={'6px'}
        px="28px"
        py={'20px'}
        color="white"
        bg="black"
        alignItems={'center'}
      >
        <Flex flex={1} flexDirection={'column'}>
          <SvgInset svgUrl={`${CDN_URL_ICONS}/friendtech-logo.svg`} />
          <Text mt={'12px'} className={s.bannerTitle}>
            Super Circles
          </Text>
          <Text className={s.bannerDesc}>
            Welcome friend.tech holders to NewBitcoinCity!
          </Text>
          <Text className={s.bannerDesc}>
            You can now access New Bitcoin City circles with your friend.tech
            keys to chat, snatch red packets, and jam. With more integration
            over time.
          </Text>
        </Flex>
      </Flex>
      <CloseButton onClick={onClose} className={s.closeButton} />
    </Collapse>
  );
};

export default Banner;
