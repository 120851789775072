import React, { useEffect, useRef } from 'react';
import s from './style.module.scss';
import { useIsInViewport } from '@/hooks/useIsInViewport';

const Confettiful: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const refConfettiInterval = useRef<NodeJS.Timeout | null>(null);
  const isInview = useIsInViewport(containerRef, { threshold: 0.5 });

  const confettiFrequency = 3;
  const confettiColors = [
    '#EF2964',
    '#00C09D',
    '#2D87B0',
    '#48485E',
    '#EFFF1D',
  ];
  const confettiAnimations = ['slow', 'medium', 'fast'];

  const setupElements = () => {
    if (containerRef.current)
      containerRef.current.classList.add(s['confetti-container']);
  };

  const renderConfetti = () => {
    refConfettiInterval.current = setInterval(() => {
      const confettiEl: any = document.createElement('div');
      const confettiSize = Math.floor(Math.random() * 3) + 7 + 'px';
      const confettiBackground =
        confettiColors[Math.floor(Math.random() * confettiColors.length)];
      const confettiLeft =
        Math.floor(Math.random() * (containerRef?.current?.offsetWidth || 0)) +
        'px';
      const confettiAnimation =
        confettiAnimations[
          Math.floor(Math.random() * confettiAnimations.length)
        ];

      if (confettiEl) {
        confettiEl.classList.add(
          s['confetti'],
          s[`confetti--animation-${confettiAnimation}`]
        );
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;

        confettiEl.removeTimeout = setTimeout(function () {
          if (confettiEl.parentNode)
            confettiEl.parentNode.removeChild(confettiEl);
        }, 2000);

        if (containerRef.current) containerRef.current.appendChild(confettiEl);
      }
    }, 100);
  };

  useEffect(() => {
    if (isInview) {
      setupElements();
      renderConfetti();
    } else {
      refConfettiInterval.current && clearInterval(refConfettiInterval.current);
    }
    return () => {
      refConfettiInterval.current && clearInterval(refConfettiInterval.current);
    };
  }, [isInview]);

  return <div ref={containerRef} className={s['bounce-in']}></div>;
};

export default Confettiful;
