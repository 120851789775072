import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { WalletTransactionData } from '@/components/WrapButton/alertInfoProcessing/interface';
import {
  DEFAULT_FOLLOWERS_MAX,
  DEFAULT_FOLLOWERS_MIN,
  DEFAULT_HOLDING,
  DEFAULT_TAGGED_MAX,
  DEFAULT_TAGGED_MIN,
  DEFAULT_TC_MAX,
  DEFAULT_TC_MIN,
  KEY_FILTER,
  KEY_LIST_VIEW,
  KEY_SWAP_FILTER,
  KEY_SELECT,
  PLACE_HOLDER_KEY_FILTER,
  PLACE_HOLDER_KEY_SELECT,
  SHOW_KEY_LEFT,
  SHOW_KEY_RIGHT,
  SLIDE_ALPHA,
  SORT_DSD,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import storageLocal from '@/utils/storage.local';
import { IBodyBuySweetFloorKeys } from '@/contracts/interfaces/tradeKey';

interface CommonState {
  needReload: number;
  currentTransaction: WalletTransactionData | undefined | null;
  filterKeyParams: string;
  selectKeys: IBodyBuySweetFloorKeys[];
  filterPlaceholderKeyParams: string;
  filterKeySwapParams: string;
  selectPlaceholderKeys: IBodyBuySweetFloorKeys[];
  showKeyLeft: '-1' | '0' | '1' | '2';
  showKeyRight: '0' | '1';
  keyListView: '0' | '1';
}

export const defaultKeyParams = {
  sort_col: 'k.price',
  sort_type: SORT_DSD.toString(),
  followers: `${DEFAULT_FOLLOWERS_MIN},${DEFAULT_FOLLOWERS_MAX}`,
  price_usd: `${DEFAULT_TC_MIN},${DEFAULT_TC_MAX}`,
  side: `${SLIDE_ALPHA}`,
};

export const defaultPlaceholderKeyParams = {
  sort_col: 'mcap_eth',
  sort_type: SORT_DSD.toString(),
  followers: `${DEFAULT_FOLLOWERS_MIN},${DEFAULT_FOLLOWERS_MAX}`,
  price_usd: `${DEFAULT_TC_MIN},${DEFAULT_TC_MAX}`,
  tweet_count: `${DEFAULT_TAGGED_MIN},${DEFAULT_TAGGED_MAX}`,
  holding: `${DEFAULT_HOLDING}`,
};

const filterKeyParams = `sort_col=k.price&sort_type=${SORT_DSD}&followers=${DEFAULT_FOLLOWERS_MIN},${DEFAULT_FOLLOWERS_MAX}&price_usd=${DEFAULT_TC_MIN},${DEFAULT_TC_MAX}&side=${SLIDE_ALPHA}`;
const filterPlaceholderKeyParams = `sort_col=mcap_eth&sort_type=${SORT_DSD}&followers=${DEFAULT_FOLLOWERS_MIN},${DEFAULT_FOLLOWERS_MAX}&price_usd=${DEFAULT_TC_MIN},${DEFAULT_TC_MAX}&tweet_count=${DEFAULT_TAGGED_MIN},${DEFAULT_TAGGED_MAX}&holding=${DEFAULT_HOLDING}`;
const filterKeySwapParams = `sort_col=buy_price&sort_type=${SORT_DSD}&followers=${DEFAULT_FOLLOWERS_MIN},${DEFAULT_FOLLOWERS_MAX}&price_usd=${DEFAULT_TC_MIN},${DEFAULT_TC_MAX}`;

const initialState: CommonState = {
  needReload: 0,
  currentTransaction: undefined,
  filterKeyParams: filterKeyParams,
  selectKeys: [],
  filterPlaceholderKeyParams: filterPlaceholderKeyParams,
  filterKeySwapParams: filterKeySwapParams,
  selectPlaceholderKeys: [],
  showKeyLeft: '1',
  showKeyRight: '0',
  keyListView: '1',
};

const slice = createSlice({
  name: 'commonState',
  initialState,
  reducers: {
    requestReload: state => {
      state.needReload += 1;
    },
    updateCurrentTransaction: (
      state,
      action: {
        payload: WalletTransactionData | null | undefined;
      }
    ) => {
      state.currentTransaction = action.payload;
    },
    setDPSelectKeys: (state, actions) => {
      storageLocal.set(KEY_SELECT, JSON.stringify(actions.payload));
      state.selectKeys = actions.payload;
    },
    setFilterKeyParams: (state, actions) => {
      storageLocal.set(KEY_FILTER, actions.payload);
      state.filterKeyParams = actions.payload;
    },
    setDPSelectPlaceholderKeys: (state, actions) => {
      storageLocal.set(
        PLACE_HOLDER_KEY_SELECT,
        JSON.stringify(actions.payload)
      );
      state.selectPlaceholderKeys = actions.payload;
    },
    setFilterPlaceHolderKeyParams: (state, actions) => {
      storageLocal.set(PLACE_HOLDER_KEY_FILTER, actions.payload);
      state.filterPlaceholderKeyParams = actions.payload;
    },
    setShowKeyLeft: (state, actions) => {
      storageLocal.set(SHOW_KEY_LEFT, actions.payload);
      state.showKeyLeft = actions.payload;
    },
    setShowKeyRight: (state, actions) => {
      storageLocal.set(SHOW_KEY_RIGHT, actions.payload);
      state.showKeyRight = actions.payload;
    },
    setKeyListView: (state, actions) => {
      storageLocal.set(KEY_LIST_VIEW, actions.payload);
      state.keyListView = actions.payload;
    },
    setFilterKeySwapParams: (state, actions) => {
      storageLocal.set(KEY_SWAP_FILTER, actions.payload);
      state.filterKeySwapParams = actions.payload;
    },
  },
});

export const {
  requestReload,
  updateCurrentTransaction,
  setDPSelectKeys,
  setFilterKeyParams,
  setDPSelectPlaceholderKeys,
  setFilterPlaceHolderKeyParams,
  setFilterKeySwapParams,
  setShowKeyLeft,
  setShowKeyRight,
  setKeyListView,
} = slice.actions;

export const selectCommonReducer = (state: RootState) => state.common;
export const selectCurrentTransaction = (state: RootState) =>
  state.common.currentTransaction;

export default slice.reducer;
