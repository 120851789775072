import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {
    bg: 'var(--chakra-colors-blackAlpha-400)',
  },
  dialog: {
    bg: 'rgb(41, 41, 41)',
    height: 'fit-content',
    position: 'relative',
    padding: '10px 20px',
    minWidth: '440px',
    borderRadius: '16px',
  },
  header: {
    fontFamily: 'var(--pro-font-family)',
    fontSize: '20px',
  },
  closeButton: {
    right: '20px',
    top: '20px',
    width: '32px',
    height: '32px',
    svg: {
      width: '15px',
      height: '15px',
    },
  },
  body: {
    marginTop: '20px',
  },
  footer: {
    marginTop: '20px',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
