export function IcMenu() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.68002 9.33317C1.94402 9.33317 1.34335 8.73584 1.34335 7.99984C1.34335 7.26384 1.93668 6.6665 2.67335 6.6665H2.68002C3.41602 6.6665 4.01336 7.26384 4.01336 7.99984C4.01336 8.73584 3.41669 9.33317 2.68002 9.33317ZM9.34669 7.99984C9.34669 7.26384 8.74936 6.6665 8.01336 6.6665H8.00668C7.27068 6.6665 6.67669 7.26384 6.67669 7.99984C6.67669 8.73584 7.27669 9.33317 8.01336 9.33317C8.75002 9.33317 9.34669 8.73584 9.34669 7.99984ZM14.68 7.99984C14.68 7.26384 14.0827 6.6665 13.3467 6.6665H13.34C12.604 6.6665 12.01 7.26384 12.01 7.99984C12.01 8.73584 12.61 9.33317 13.3467 9.33317C14.0834 9.33317 14.68 8.73584 14.68 7.99984Z"
        fill="#C7C7C7"
      />
    </svg>
  );
}
