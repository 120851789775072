import Lottie from 'lottie-react';
import s from './styles.module.scss';
import loadingFile from './alpha-loading.json';
import { useEffect, useRef } from 'react';

const AppLoading = (props: any) => {
  const ref = useRef(null);
  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref.current?.setSpeed(1.5);
  }, []);

  return (
    <div className={`${s.container} apploading`}>
      <Lottie ref={ref} animationData={loadingFile} {...props} />
    </div>
  );
};

export default AppLoading;
