import React from 'react';

type Props = {};

const TribeMemberIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <g opacity="0.7">
        <path
          d="M1.08301 12.4165C1.08301 11.2672 1.53955 10.165 2.35221 9.35237C3.16487 8.53972 4.26707 8.08317 5.41634 8.08317C6.56561 8.08317 7.66781 8.53972 8.48047 9.35237C9.29313 10.165 9.74967 11.2672 9.74967 12.4165H1.08301ZM5.41634 7.5415C3.62072 7.5415 2.16634 6.08713 2.16634 4.2915C2.16634 2.49588 3.62072 1.0415 5.41634 1.0415C7.21197 1.0415 8.66634 2.49588 8.66634 4.2915C8.66634 6.08713 7.21197 7.5415 5.41634 7.5415ZM9.40463 8.75105C10.2332 8.964 10.9734 9.43292 11.52 10.091C12.0666 10.7492 12.3916 11.5629 12.4488 12.4165H10.833C10.833 11.0028 10.2913 9.71575 9.40463 8.75105ZM8.30884 7.51821C8.76275 7.11222 9.12576 6.61489 9.37406 6.05882C9.62236 5.50276 9.75036 4.90049 9.74967 4.2915C9.75083 3.5513 9.56149 2.82325 9.19988 2.17738C9.81339 2.30066 10.3653 2.63258 10.7618 3.11674C11.1582 3.60089 11.3748 4.2074 11.3747 4.83317C11.3748 5.21909 11.2925 5.60058 11.1331 5.95208C10.9738 6.30357 10.7412 6.61695 10.4509 6.87121C10.1606 7.12547 9.81926 7.31474 9.44982 7.42633C9.08039 7.53791 8.69137 7.56924 8.30884 7.51821Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default TribeMemberIcon;
