import React, { memo, useCallback, useContext, useMemo } from 'react';
import s from './styles.module.scss';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { WalletContext } from '@/contexts/wallet-context';
import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import { formatCurrency } from '@/utils';
import BigNumber from 'bignumber.js';
import IcTwitter from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icTwitter';

import cs from 'classnames';
import KeyIconHolder from '../icon/KeyIconHolder';
import { User } from '@/interfaces/user';
import BtnShareTw from '../WheelPrize/btnShareTw';
import { Box, Flex } from '@chakra-ui/react';
import { closeModal, openModal } from '@/state/modal';
import ShareTwitterAfterJoinModal, {
  SHARE_TWITTER_AFTER_JOIN_MODAL,
} from '../ShareTwitterAfterJoinModal';
import { useDispatch } from 'react-redux';

interface IProps {
  users: Array<any>;
  rewardPrice: string;
  joinWheelEvent: () => void;
  hasJoined: boolean;
  isOwnerWheel: boolean;
  wheelCreator?: User | null;
  isHolder: boolean;
  wheelDetail: any;
}

const Participants: React.FC<IProps> = ({
  users,
  rewardPrice,
  joinWheelEvent,
  hasJoined,
  isOwnerWheel,
  wheelCreator,
  wheelDetail,
  isHolder,
}: IProps): React.ReactElement => {
  const { btc2Usd } = useContext(WalletContext);
  const dispatch = useDispatch();

  const usdValue = useMemo(() => {
    if (!btc2Usd || !rewardPrice) return '-';
    const priceBn = new BigNumber(rewardPrice);
    return `$${formatCurrency(priceBn.times(btc2Usd), 0, 2)}`;
  }, [btc2Usd, rewardPrice]);

  const btcValue = useMemo(() => {
    if (!rewardPrice) return '-';
    return `${rewardPrice} BTC`;
  }, [rewardPrice]);

  const joinWheelEvents = (event: any) => {
    event?.stopPropagation();
    joinWheelEvent();
  };

  const isHaveUserJoined = useMemo(() => {
    return users.length > 0;
  }, [users]);

  const handleCloseShareTwitter = () => {
    //
    dispatch(closeModal({ id: SHARE_TWITTER_AFTER_JOIN_MODAL }));
  };

  const openShareTwitterForJoinPublicEvent = useCallback(() => {
    try {
      // show popup share
      dispatch(
        openModal({
          id: SHARE_TWITTER_AFTER_JOIN_MODAL,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
            className: s.modal_wheel,
          },
          className: s.modal_wheel,
          hideCloseButton: false,
          render: () => (
            <ShareTwitterAfterJoinModal
              onClose={handleCloseShareTwitter}
              wheelCreator={wheelDetail.Twitter}
              totalReward={rewardPrice}
              users={users}
            />
          ),
        })
      );
    } catch (e) {
      //
    }
  }, [wheelDetail, wheelCreator, rewardPrice, users]);

  const renderParticipantList = useMemo(() => {
    const participantCount = users.length || 0;

    if (participantCount === 0) return <></>;

    return (
      <div
        className={cs(s.listWrapper, {
          [`${s.ownerScreen}`]: isOwnerWheel,
        })}
      >
        <div
          className={cs(s.userList, {
            [`${s.ownerScreen}`]: isOwnerWheel,
          })}
        >
          {users &&
            participantCount > 0 &&
            users.slice(0, 5).map((item: any, index: number) => {
              const { user } = item;
              return (
                <div
                  key={user.id}
                  className={s.userItem}
                  // style={{
                  //   zIndex: index,
                  //   left: `${index > 0 ? index * 24 - 0 : index * 24}px`,
                  // }}
                >
                  <Avatar
                    className={`${s.avatar}`}
                    url={user?.twitter_avatar || ''}
                    address={user.address || ''}
                    width={36}
                  />
                </div>
              );
            })}
          {participantCount > 5 && (
            <div
              className={s.moreCircle}
              // style={{
              //   zIndex: participantCount - 2,
              //   left: `${5 * 24}px`,
              // }}
            >
              <span>{`+${participantCount - 5}`}</span>
            </div>
          )}
        </div>
        {isOwnerWheel && (
          <h4 className={s.listHeading}>
            {participantCount} New Bitcoiners are playing
          </h4>
        )}
      </div>
    );
  }, [users]);

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.boxContent}>
          <img
            className={s.box}
            // src={`${CDN_URL_IMAGES}/box-lucky.png`}
            src={`${CDN_URL_ICONS}/wheel-key.svg`}
            alt="box-lucky.png"
            style={{
              transform: 'translateY(25%)',
            }}
          />
          <p className={s.btcPrice}>{btcValue}</p>
          <p className={s.usdPrice}>{usdValue}</p>
        </div>
        {/* {isHolder && (
          <div
            className={cs(s.holderType, {
              [`${s.ownerScreen}`]: isOwnerWheel,
            })}
          >
            <KeyIconHolder /> Exclusive for my key holders
          </div>
        )} */}
        <div
          className={cs(s.actionWrapper, {
            [s.ownerScreen]: isOwnerWheel,
          })}
        >
          <Flex flexDirection={'column'}>
            {(isOwnerWheel || isHaveUserJoined) && <>{renderParticipantList}</>}

            {/* <BtnShareTw
            wheelCreator={wheelCreator}
            users={users}
            // tokenName={tokenName}
          /> */}
          </Flex>
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            gap="12px"
            mt="12px"
            overflow={'hidden'}
          >
            {/* <BtnShareTw
              wheelCreator={wheelDetail.Twitter}
              wheelDetail={wheelDetail}
              users={users}
            /> */}
            <Box bg="white" color="black" borderRadius={'100px'} p={'8px 24px'}>
              <Flex
                alignItems={'center'}
                whiteSpace={'nowrap'}
                gap={2}
                justifyContent={'space-between'}
                onClick={openShareTwitterForJoinPublicEvent}
              >
                Share on <IcTwitter />
              </Flex>
            </Box>
            {!isOwnerWheel && !hasJoined && (
              <button
                onClick={joinWheelEvents}
                className={cs(s.joinBtn, {
                  [s.fullWidth]: !isHaveUserJoined,
                })}
              >
                Tap to play
              </button>
            )}
          </Flex>
          {/* {!isOwnerWheel && hasJoined && (
            <button className={s.joinedBtn}>Joined</button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default memo(Participants);
