import React, {useContext, useEffect, useRef, useState} from 'react';
import {useRouter} from 'next/router';
import {ROUTE_PATH} from '@/constants/route-path';
import AppLoading from '@/components/AppLoading';
import {PERP_API_URL} from '@/configs';
import {WalletContext} from '@/contexts/wallet-context';
import useApiInfinite from '@/hooks/useApiInfinite';
import {apiClient} from '@/services';
import InfiniteScroll from 'react-infinite-scroll-component';
import Empty from '../Empty';
import s from './Feed.module.scss';
import FeedItem from './NotificationItem';
import {NotificationDataType} from './types';
import {updateLastTimeSeenNotification} from "@/services/player-share";
import {requestReload} from "@/state/common";
import {useDispatch} from "react-redux";

const SUPPORT_ACTIVITIES = [
  'repost_tweet',
  'like_your_post',
  'reply_tweet',
  'trade',
  'trade_pass',
  'staking',
  'staking_pass',
  'free_spin',
  'donate_pass',
  'stake_blast'
];

const JackpotFeed = () => {
  const { addressL2 } = useContext(WalletContext);
  const router = useRouter();
  const keyId = `user-notification${router?.pathname?.replaceAll('/', '-')}`;
  const infiniteScrollRef = useRef<any>(null);

  const [initialScrollYSaved, setInitialScrollYSaved] = useState<number>(
    Number(sessionStorage.getItem(keyId)) || 0
  );

  const dispatch = useDispatch();

  const handleRouteChangeStart = (url: string) => {
    if (url === ROUTE_PATH.ALPHA_MOBILE_NOTI) {
      // scroll to top
      sessionStorage.setItem(keyId, '0');
      setInitialScrollYSaved(0);
      if (infiniteScrollRef?.current) {
        infiniteScrollRef?.current?.el?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
      // reload data
      refresh();
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events]);

  const getFeedData = async ({ page = 1, limit = 50 }) => {
    try {
      const rs: NotificationDataType[] = await apiClient.get(
        `${PERP_API_URL}/api/player-share/user-notification?network=nos&page=${page}&limit=${limit}&address=${addressL2}`
        // `${PERP_API_URL}/api/player-share/activities?network=nos&page=${page}&limit=${limit}&address=${addressL2}`
      );
      const result = rs?.filter(r => SUPPORT_ACTIVITIES.includes(r.activity));
      return result;
    } catch (error) {
      console.log({ error });
    }
    return [];
  };

  const {
    dataInfinite,
    loadMore,
    refresh,
    isLoadingMore,
    isReachingEnd,
    hasFirstFetching,
    isEmpty,
    isRefreshing,
  } = useApiInfinite(
    getFeedData,
    { key: keyId, limit: 50 },
    { revalidateOnFocus: true }
  );

  useEffect(() => {
    const timeout = setTimeout(handleUpdateLastSeenNotification, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [addressL2]);

  const handleUpdateLastSeenNotification = async () => {
    if(!addressL2) {
      return;
    }

    await updateLastTimeSeenNotification(addressL2);
    refresh();
    dispatch(requestReload());
  }

  // console.log('dataInfinite', dataInfinite);
  //
  // const isSignup = (item: FeedDataType): boolean => {
  //   return item.tweet_activity === 'signup';
  // };
  //
  // const isConvert = (item: FeedDataType): boolean => {
  //   return item.tweet_activity === 'convert';
  // };
  //
  // const isLimitFill = (item: FeedDataType): boolean => {
  //   return (
  //     item.tweet_activity === 'limit_order_filled' ||
  //     item.tweet_activity === 'limit_order_pending'
  //   );
  // };
  //
  // const isGiveAway = (item: FeedDataType): boolean => {
  //   return (
  //     item.tweet_activity === 'give_away_deposit' ||
  //     item.tweet_activity === 'give_away_receive'
  //   );
  // };

  return (
    <div className={s.feed_wrapper}>
      {hasFirstFetching === false ? (
        <div className={s.loadingMore}>
          <AppLoading />
        </div>
      ) : (
        <InfiniteScroll
          ref={infiniteScrollRef}
          className="disable-scrollbar-x"
          height="calc(100dvh - 164px)"
          dataLength={dataInfinite?.length || 500}
          hasMore={isReachingEnd === false}
          loader={
            isLoadingMore && (
              <div className={s.loadingMore}>
                <AppLoading />
              </div>
            )
          }
          initialScrollY={initialScrollYSaved}
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          next={loadMore}
          onScroll={(event: any) => {
            sessionStorage.setItem(keyId, event?.srcElement?.scrollTop);
          }}
        >
          {isRefreshing && (
            <div className={s.loadingMore}>
              <AppLoading />
            </div>
          )}
          {dataInfinite &&
            dataInfinite.length > 0 &&
            dataInfinite?.map(
              (item: any, index) =>
                <FeedItem item={item} />
                // !isSignup(item) &&
                // !isConvert(item) &&
                // !isLimitFill(item) &&
                // !isGiveAway(item) && (
                //   <div key={index}>
                //     {item.activity === 'give_away_deposit' ||
                //     item.activity === 'give_away_receive' ? (
                //       <NewestFeedItem item={item} />
                //     ) : (
                //       <FeedItem item={item} />
                //     )}
                //   </div>
                // )
            )}
          {isEmpty && <Empty />}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default React.memo(JackpotFeed);
