import { ROUTE_PATH } from '@/constants/route-path';
import { PostItemContext } from '@/contexts/post-item';
import { WalletContext } from '@/contexts/wallet-context';
import { LogLevel } from '@/enums/log-level';
import { IPostData } from '@/interfaces/api/post';
import CreatePost from '@/modules/AlphaPWA/Home/CreatePost';
import LikeButton from '@/modules/AlphaPWA/Home/PostItem/LikeButton';
import { MenuActions } from '@/modules/AlphaPWA/Home/PostItem/MenuActions';
import { PointerCounter } from '@/modules/AlphaPWA/Home/PostItem/PointerCounter';
import s from '@/modules/AlphaPWA/Home/PostItem/PostItemV2.module.scss';
import ReplyButton from '@/modules/AlphaPWA/Home/PostItem/ReplyButton';
import RepostButton from '@/modules/AlphaPWA/Home/PostItem/RepostButon';
import TipButton from '@/modules/AlphaPWA/Home/PostItem/TipButton';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getPostDetail } from '@/services/alpha_tweet';
import log from '@/services/logger';
import { abbreviateNumber, formatCurrency } from '@/utils';
import { goToRoute } from '@/utils/go-route';
import { default as cn, default as cs } from 'classnames';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import IconPinnedPost from '../Icon/IconPinnedPost';
import RepostSvg from '../Icon/RepostSvg';
import { MainPost } from '../MainPost';
import PostContent from '../PostContent';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import ReactionList from '@/modules/AlphaPWA/Home/PostItem/ReactionList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PostFollow } from '@/modules/AlphaPWA/Home/PostItem/PostFollow';
import { useAppDispatch } from '@/state/hooks';
import styles from './MainPost.module.scss';

dayjs.extend(utc);

type Props = {
  isFetchAllData?: boolean;
  data: IPostData;
  dataParent?: any;
  isPinned?: boolean;
  onFetchData?: (isRefeshFromTop?: boolean) => void;
  fetchPinnedPosts?: any;
  isProfile?: boolean;
  postType?: 'post' | 'reply';
};

export const MainPostDetail = React.memo(
  ({
    data,
    dataParent,
    onFetchData,
    isPinned = false,
    fetchPinnedPosts,
    postType = 'post',
    isProfile,
  }: Props) => {
    const router = useRouter();
    const { id } = router.query as { id: string };
    const { addressL2 } = useContext(WalletContext);
    const { isRelayShow, tips, isCanRepost } =
      useContext(PostItemContext);
    const [showReplyPost, setShowReplyPost] = useState(false);
    const [parentsData, setParentsData] = useState<any>([]);
    const mainPostRef = useRef<HTMLDivElement>(null);
    const [isActionLiked, setActionLiked] = useState<boolean>(false);
    const [isFollowed, setIsFollowed] = useState<boolean | undefined>(
      undefined
    );
    const dispatch = useAppDispatch();
    const ref = useRef<any>(null);

    const user = data?.user;

    // get parentsData from sessionStorage

    const isRepost = useMemo((): boolean => {
      return (
        data?.reposted_list &&
        data?.reposted_list.length > 0 &&
        postType !== 'reply'
      );
    }, [data, postType]);

    const handleClickReply = () => {
      setShowReplyPost(!showReplyPost);
    };

    const time = useMemo((): string => {
      let expiredDate = data?.updated_at;
      if (data?.raffle_event?.ExpiredAt) {
        expiredDate = data?.raffle_event?.ExpiredAt;
      } else if (data?.prize_wheel_event?.ExpiredAt) {
        expiredDate = data?.prize_wheel_event?.ExpiredAt;
      }
      const tmp = dayjs.utc(expiredDate || '').local();
      return expiredDate
        ? `${tmp.format('HH:mm')} • ${tmp.format('DD MMM YYYY')}`
        : '';
    }, [data]);

    const totalTipsFormat = useMemo((): string | number => {
      return formatCurrency(tips, 0, 4);
    }, [tips]);
    const fetchParentData = useCallback(async (id: number) => {
      if (!addressL2) return;
      try {
        // use recursive function to fetch parent post
        const res: any = await getPostDetail(Number(id), addressL2);
        if (!res.parent_id) {
          setParentsData((prev: any) => [res, ...prev]);
          return;
        }
        if (res && !!res.parent_id) {
          setParentsData((prev: any) => [res, ...prev]);
          await fetchParentData(res.parent_id);
        }
      } catch (err: unknown) {
        log('failed to fetch parent post', LogLevel.ERROR, 'fetchParentData');
        // throw Error();
      }
    }, []);

    const showMetadata = useMemo((): boolean => {
      return (
        !!dataParent?.reply_count ||
        !!data?.reply_count ||
        !!dataParent?.repost_count ||
        !!dataParent?.liked ||
        !!data?.liked ||
        !!tips
      );
    }, [
      dataParent?.reply_count,
      data?.reply_count,
      dataParent?.repost_count,
      dataParent?.liked,
      data?.liked,
      tips,
    ]);

    useEffect(() => {
      if (data.parent_id) {
        fetchParentData(Number(data.parent_id));
      }
    }, [data.parent_id]);

    useEffect(() => {
      if (id) {
        setParentsData([]);
      }
    }, [id]);

    const isCanRepostCheck = useMemo((): boolean => {
      return isCanRepost(data, dataParent);
    }, [data, dataParent]);

    const postByMe = addressL2 === data?.user?.address;
    const isShowPoint = isFollowed || postByMe;

    useEffect(() => {
      // scroll to mainPostRef
      if (mainPostRef.current) {
        mainPostRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, []);

    if (!data) return <></>;

    return (
      <>
        <div className={s.ancestor_post_wrapper}>
          {parentsData &&
            parentsData.length > 0 &&
            parentsData.map((data: any) => (
              <>
                {!!data?.id ? (
                  <div
                    className={cs(s.ancestor_post, s.isHadReply, 'isHadReply')}
                  >
                    <MainPost data={data} key={data.id} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ))}
        </div>
        <div className={s.postDetail} ref={mainPostRef}>
          <div className={cs(s.additionalInfoBlockPostDetail)}>
            {isPinned && (
              <>
                <span className={s.additionalInfoBlockPostDetail_icon}>
                  <IconPinnedPost />
                </span>
                <span className={s.additionalInfoBlockPostDetail_text}>
                  Pinned post
                </span>
              </>
            )}
            {isRepost && (
              <>
                <span className={s.additionalInfoBlockPostDetail_icon}>
                  <RepostSvg />
                </span>
                <div className={`${s.additionalInfoBlockPostDetail_text}`}>
                  <span className={s.additionalInfoBlock_text_name}>
                    {dataParent?.user?.address === addressL2
                      ? 'You'
                      : dataParent?.user?.twitter_name ||
                        '@' + dataParent?.user?.twitter_username}
                  </span>{' '}
                  reposted
                </div>
              </>
            )}
          </div>
          <div className={s.postDetail_top}>
            <div className={s.postDetail_topLeft}>
              <Avatar
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  if (!user.address) return;
                  goToRoute(
                    router,
                    `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${user.address}`
                  );
                }}
                className={s.avatar}
                url={getUrlAvatarTwitter(
                  user?.twitter_avatar || user?.avatar || '',
                  'medium'
                )}
                address={user?.address || ''}
                name={user?.twitter_name || ''}
                width={44}
                fontSize={16}
              />
              <div className={s.postDetail_authorInfo}>
                <div className={s.postDetail_authorMainInfo}>
                  <p className={s.postDetail_fullname}>{user?.twitter_name}</p>
                  {isShowPoint && (
                    <PointerCounter
                      portfolio_value={data?.user?.portfolio_value}
                      total_point={data?.user?.total_point}
                      last_claim={data?.user?.last_claim}
                    />
                  )}
                </div>
                <p className={cn(s.postDetail_username, s.text_seconde)}>
                  @{user?.twitter_username}
                </p>
              </div>
            </div>
            <div className={s.postDetail_topRight}>
              {!postByMe && (
                <PostFollow
                  isFollowed={isFollowed}
                  setIsFollowed={setIsFollowed}
                  twitter_id={data?.user?.twitter_id}
                  twitter_name={
                    data?.user?.twitter_name || data?.user?.twitter_username
                  }
                />
              )}
              <MenuActions
                isPinned={isPinned}
                isFollowed={isFollowed}
                setIsFollowed={setIsFollowed}
                fetchPinnedPosts={fetchPinnedPosts}
                data={data}
                dataParent={dataParent}
                onFetchData={() => {
                  onFetchData && onFetchData(true);
                }}
                postType={postType}
              />
            </div>
          </div>
          <div className={cn(s.postDetail_content)}>
            <div className={s.post_address} id="post-item-wrap-content">
              <div className={`${s.right} ${isRelayShow ? s.isRelayShow : ''}`}>
                <PostContent isDetail data={data} />
              </div>
              <div className={s.postDetail_content_time}>
                <span>{time}</span>
              </div>
              <div className={s.postDetail_content_metaData}>
                {showMetadata ? (
                  <>
                    {Boolean(
                      isRepost ? dataParent?.reply_count : data?.reply_count
                    ) && (
                      <span className={s.postDetail_content_metaData_item}>
                        <b>
                          {abbreviateNumber(
                            isRepost
                              ? dataParent?.reply_count
                              : data?.reply_count
                          )}
                        </b>
                        {` ${
                          isRepost
                            ? dataParent?.reply_count
                            : data?.reply_count !== 1
                            ? 'Replies'
                            : 'Reply'
                        }`}
                      </span>
                    )}

                    {Boolean(dataParent?.repost_count) && (
                      <span className={s.postDetail_content_metaData_item}>
                        <b>{abbreviateNumber(dataParent?.repost_count)}</b>
                        {` Repost${dataParent?.repost_count !== 1 ? 's' : ''}`}
                      </span>
                    )}

                    {Boolean(isRepost ? dataParent?.liked : data?.liked) && (
                      <span className={s.postDetail_content_metaData_item}>
                        <b>
                          {abbreviateNumber(
                            isRepost ? dataParent?.liked : data?.liked
                          )}
                        </b>
                        {` Like${
                          isRepost
                            ? dataParent?.liked
                            : data?.liked !== 1
                            ? 's'
                            : ''
                        }`}
                      </span>
                    )}

                    {Boolean(tips > 0) && (
                      <span className={s.postDetail_content_metaData_item}>
                        <b>{totalTipsFormat}</b>
                        {` Tips`}
                      </span>
                    )}
                    <div className={s.postDetail_content_metaData_rection}>
                      <ReactionList
                        postId={data?.id}
                        address={addressL2}
                        isActionLiked={isActionLiked}
                        liked={data?.liked}
                      />
                    </div>
                  </>
                ) : (
                  <span className={s.postDetail_content_metaData_item}>
                    No activity
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={`${s.postDetail_contentFooter}`}>
            <ReplyButton
              classNames={'footer_item'}
              handleClickReply={handleClickReply}
              hideNumber={true}
              replyCount={
                isRepost ? dataParent?.reply_count : data?.reply_count
              }
            />
            {isCanRepostCheck && (
              <RepostButton
                classNames={'footer_item'}
                onFetchData={() => {
                  onFetchData && onFetchData();
                }}
                isPublic={dataParent?.public}
                // postId={!isRepost ? data?.id : dataParent?.id}
                postId={data?.id}
                hideNumber={true}
                userAddress={addressL2 || ''}
                dataRepost={{
                  isRepost,
                  userRepost: dataParent?.user?.address,
                  count: dataParent?.repost_count,
                  isRepostByMe: dataParent?.reposted_by_me,
                }}
              />
            )}

            <LikeButton
              classNames={'footer_item'}
              hideNumber={true}
              setActionLiked={setActionLiked}
              reactionType={
                !isRepost ? data?.reaction_type : dataParent?.reaction_type
              }
              // id={isRepost ? dataParent?.id : data?.id}
              id={data?.id}
              numOfLikes={isRepost ? dataParent?.liked : data?.liked}
              isLiked={isRepost ? dataParent?.user_liked : data?.user_liked}
            />
            {!isRepost && !data?.activity ? (
              <TipButton
                classNames={'footer_item'}
                hideNumber={true}
                postId={data?.id}
                tipToAddress={data?.user?.address}
              />
            ) : null}
          </div>
        </div>
        {showReplyPost && (
          <div
            style={{
              width: mainPostRef.current ? mainPostRef.current.offsetWidth : 0,
              right: isProfile ? 16 : 0,
            }}
            className={cn(styles.createContent, {
              [`${styles.createContent_profile}`]: isProfile,
            })}
          >
            <CreatePost
              show={showReplyPost}
              onClose={() => setShowReplyPost(false)}
              // reply={isRepost ? dataParent : data}
              reply={data}
              onFetchData={() => {
                onFetchData && onFetchData(true);
              }}
            ></CreatePost>
          </div>
        )}
      </>
    );
  }
);
