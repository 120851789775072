import { apiClient } from '@/services/index';
import { PERP_API_URL } from '@/configs';
import qs from 'query-string';
import { camelCaseKeys } from '@trustless-computer/dapp-core';

export const getPrizeWheelAddress = ({
  address,
  network = 'nos',
}: {
  address: string;
  network?: string;
}): Promise<string> => {
  const query = qs.stringify({
    address,
    network,
  });
  return apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/prize-wheel/prize-wheel-address?${query}`
  );
};

export const joinPrizeWheel = ({
  wheelId,
  address,
  network = 'nos',
}: {
  wheelId: string;
  address: string;
  network?: string;
}) => {
  const query = qs.stringify({
    address,
    network,
  });
  return apiClient.post(
    `${PERP_API_URL}/api/alpha-keys/prize-wheel/join/${wheelId}?${query}`
  );
};

export const scanPrizeWheelHash = ({
  address,
  txHash,
  network = 'nos',
}: {
  address: string;
  txHash: string;
  network?: string;
}): Promise<boolean> => {
  const query = qs.stringify({
    address,
    tx_hash: txHash,
    network,
  });
  return apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/prize-wheel/scan-hash?${query}`
  );
};

export const getPrizeWheelJoining = ({
  wheelId,
  address,
  network = 'nos',
}: {
  wheelId: string;
  address: string;
  network?: string;
}): Promise<string> => {
  const query = qs.stringify({
    address,
    network,
  });
  return apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/prize-wheel/join/${wheelId}?${query}`
  );
};

export const getPrizeWheelDetail = ({
  wheelId,
  address,
  network = 'nos',
}: {
  wheelId: string;
  address: string;
  network?: string;
}): Promise<string> => {
  const query = qs.stringify({
    address,
    network,
  });
  return apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/prize-wheel/detail/${wheelId}?${query}`
  );
};

export const shareTwitterWheel = ({ address }: { address: string }) => {
  return apiClient.post(
    `${PERP_API_URL}/api/alpha-keys/prize-wheel/prize-wheel-shared?network=nos&address=${address}`
  );
};
