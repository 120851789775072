import {Flex, Grid, GridItem} from '@chakra-ui/react';
import React, {useContext, useEffect, useMemo, useRef} from 'react';

import s from './styles.module.scss';
import ProKeyTrading from './trading';
import ProKeySocial from './social';
import ProKey from './key';
import clsx from 'classnames';
import IcLeftArrow from './key/img/icLeftArrow';
import IcRightArrow from './key/img/icRightArrow';
import {useDispatch, useSelector} from 'react-redux';
import {selectCommonReducer, setKeyListView, setShowKeyLeft, setShowKeyRight,} from '@/state/common';
import {KEY_LIST_VIEW, SHOW_KEY_LEFT, SHOW_KEY_RIGHT,} from '../../Profiles/TradeKey/constants';
import {compareString} from '@/utils';
import {WalletContext} from "@/contexts/wallet-context";

const ModuleProKeys = () => {
  const dispatch = useDispatch();
  const showKeyLeft = useSelector(selectCommonReducer).showKeyLeft;
  const showKeyRight = useSelector(selectCommonReducer).showKeyRight;
  const { addressL2 } = useContext(WalletContext);

  // console.log('addressL2', addressL2);
  // console.log('showKeyLeft', showKeyLeft);
  // console.log('showKeyRight', showKeyRight);
  // console.log('======')

  const refInitial = useRef(false);

  // const { sreenWidth } = useWindowSize();
  //
  // const maxLeftWidth = sreenWidth < 1500 ? 750 : 1000;
  // const maxRightWidth = sreenWidth < 1500 ? 325 : 400;

  useEffect(() => {
    if (!refInitial.current) {
      refInitial.current = true;
      if (localStorage.getItem(SHOW_KEY_LEFT)) {
        dispatch(setShowKeyLeft(localStorage.getItem(SHOW_KEY_LEFT)));
      }
      if (localStorage.getItem(SHOW_KEY_RIGHT)) {
        dispatch(setShowKeyRight(localStorage.getItem(SHOW_KEY_RIGHT)));
      }
      if (localStorage.getItem(KEY_LIST_VIEW)) {
        dispatch(setKeyListView(localStorage.getItem(KEY_LIST_VIEW)));
      }
    }
  }, []);

  useEffect(() => {
    if(!addressL2) {
      dispatch(setShowKeyLeft('-1'));
    }
    if(!addressL2) {
      dispatch(setShowKeyRight('1'));
    }
  }, [addressL2]);

  const leftWidth = useMemo(() => {
    switch (showKeyLeft) {
      case '-1': {
        if(compareString(showKeyRight, '1')) {
          return "0.33fr";
        } else {
          return "0.2fr";
        }
      }
      case '0': {
        if(compareString(showKeyRight, '1')) {
          return "2fr";
        } else {
          return "1fr";
        }
      }
      case '1': {
        if(compareString(showKeyRight, '1')) {
          return "3fr";
        } else {
          return "1.5fr";
        }
      }
      case '2': {
        if(compareString(showKeyRight, '1')) {
          return "2fr";
        } else {
          return "1fr";
        }
      }
    }
  }, [showKeyLeft, showKeyRight]);

  const rightWidth = useMemo(() => {
    if(compareString(showKeyRight, '0')) {
      return "0px";
    } else {
      switch (showKeyLeft) {
        case '-1': {
          return "0.33fr";
        }
        case '0': {
          return "1fr";
        }
        case '1': {
          return "1fr";
        }
        case '2': {
          return "1fr";
        }
      }
    }
  }, [showKeyLeft, showKeyRight]);

  return (
    <Grid
      className={clsx(
        s.gridMainContainer,
        // compareString(showKeyLeft, '1') &&
        //   compareString(showKeyRight, '0') &&
        //   s.showLeftSide,
        // compareString(showKeyLeft, '0') &&
        //   compareString(showKeyRight, '1') &&
        //   s.showRightSide,
        // compareString(showKeyLeft, '0') &&
        //   compareString(showKeyRight, '0') &&
        //   s.hideLeftRight
      )}
      style={{gridTemplateColumns: `${leftWidth} 1fr ${rightWidth}`}}
    >
      <GridItem position={'relative'} area={'g-social'}>
        <ProKeySocial />
        {(compareString(showKeyLeft, '1') || compareString(showKeyLeft, '0')) && (
          <Flex
            className={clsx(
              s.btnSide,
              s.btnLeftSide,
              (compareString(showKeyLeft, '1') || compareString(showKeyLeft, '0')) ? s.hideLeftSide : ''
            )}
            onClick={() => {
              if(compareString(showKeyLeft, '1')) {
                dispatch(setShowKeyLeft('0'));
              } else {
                dispatch(setShowKeyLeft('-1'));
              }
            }}
          >
            <IcLeftArrow />
          </Flex>
        )}
      </GridItem>

      <GridItem
        area={'g-key'}
        height={'100%'}
        overflow={'hidden'}
        position={'relative'}
      >
        <ProKey />
      </GridItem>
      <GridItem position={'relative'} area={'g-trading'} borderLeft={"1px solid #353945"}>
        <ProKeyTrading />
        {compareString(showKeyRight, '1') && (
          <Flex
            className={clsx(s.btnSideRight)}
            onClick={() => dispatch(setShowKeyRight('0'))}
          >
            <IcRightArrow />
          </Flex>
        )}
      </GridItem>
    </Grid>
  );
};

export default ModuleProKeys;
