import { CDN_URL_ICONS } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { AssetsContext } from '@/contexts/assets-context';
import { CurrencyContext } from '@/contexts/currency-context';
import { WalletContext } from '@/contexts/wallet-context';
import { ClientOs } from '@/enums/client';
import { ALPHA_LAYOUT_HEADER_ID } from '@/layouts/AlphaLayout/constants';
import SwapTcToBTCModal from '@/modules/AlphaPWA/Profile/SwapTcToBtcModal';
import IcFire from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icFire';
import { useAppDispatch } from '@/state/hooks';
import { resetUnreadNotification } from '@/state/notification/reducer';
import { formatCurrency } from '@/utils';
import { detectClientOs } from '@/utils/client';
import { Box, Text } from '@chakra-ui/react';
import cs from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import NotificationsModal from './NotificationsModal';
import Treasury from '../Treasury';
import LinkFT from './LinkFT';
import s from './styles.module.scss';
import IconBell from './svg/IconBell';
import IconLogo from './svg/IconLogo';
import TVL from '../TVL';
import usePlayerPoolProfile from '@/hooks/userInfo/usePlayerPoolProfile';
import TreasuryModal from './TreasuryModal';
import RefferalModal from './RefferalModal';
import DownloadAppModal from './DownloadAppModal';
import ProfileDrawer from './ProfileDrawer';
import Link from 'next/link';
import useAnalyticsEventTracker from '@/utils/ga';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { useObject } from 'react-firebase-hooks/database';
import { getDatabase, ref } from 'firebase/database';
import useDebounce from '@/hooks/useDebounce';
import { getUnreadNotification } from '@/services/player-share';
import getFirebaseApp from '@/services/firebase/base';
import { useSelector } from 'react-redux';
import { selectCommonReducer } from '@/state/common';
import SearchKeys from './SearchKeys';

const firebaseApp = getFirebaseApp();

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(firebaseApp);

const MENU_ITEMS = [
  {
    label: 'Home',
    path: ROUTE_PATH.HOME,
  },
];

const DesktopHeader: React.FC = (): React.ReactElement => {
  const router = useRouter();
  const clientOs = detectClientOs();
  const dispatch = useAppDispatch();

  const [showConvertTC, setShowConvertTC] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showTreasury, setShowTreasury] = useState(false);
  const [showRefferal, setShowRefferal] = useState(false);
  const [showDownloadApp, setShowDownloadApp] = useState(false);

  const [showProfile, setShowProfile] = useState(false);

  const { balanceL2 } = useContext(AssetsContext);
  const { isAuthenticated, addressL2, onSyncProfile } =
    useContext(WalletContext);
  const { isForceConvert } = useContext(CurrencyContext);

  const { playerPoolProfile } = usePlayerPoolProfile(addressL2);

  const [unreadCount, setUnreadCount] = useState(0);
  const isOnPage = router.asPath === ROUTE_PATH.ALPHA_MOBILE_NOTI;

  const gaEventTracker = useAnalyticsEventTracker();
  const { userProfile } = useTradeKey();

  const [snapshots, loading, error] = useObject(
    ref(database, `user-activities/${userProfile?.twitter_id}`)
  );

  const snapshotsDebounced = useDebounce(snapshots, 2000);
  const needReload = useSelector(selectCommonReducer).needReload;

  useEffect(() => {
    const list = snapshots?.val();

    if (!!list) {
      getUnreadNotif();
    }
  }, [snapshotsDebounced]);

  useEffect(() => {
    if (addressL2) {
      getUnreadNotif();
    }
  }, [addressL2, needReload]);

  const getUnreadNotif = async () => {
    const res: number = await getUnreadNotification({
      page: 1,
      limit: 50,
      address: addressL2 || '',
      is_count: 1,
    });

    setUnreadCount(res || 0);
  };

  const goToProfilePage = () => {
    if (!isForceConvert) {
      router.push(ROUTE_PATH.ALPHA_MOBILE_PROFILE);
    } else {
      setShowConvertTC(true);
    }
  };

  const renderMenuItems = () => {
    return MENU_ITEMS.map(item => {
      return (
        <Link
          key={item.path}
          href={item.path}
          className={cs(
            s.menuItem,
            router.asPath === item.path && s.menuItem_active
          )}
        >
          {item.label}
        </Link>
      );
    });
  };

  useEffect(() => {
    if (isOnPage) {
      dispatch(resetUnreadNotification());
    }
  }, [router.asPath]);

  return (
    <>
      <header
        className={cs(s.header, clientOs === ClientOs.Ios && s.ios)}
        id={ALPHA_LAYOUT_HEADER_ID}
      >
        <div className={s.containerLeft}>
          {/* <TVL /> */}

          <a href="/">
            <IconLogo />
          </a>

          <Treasury onClick={() => setShowTreasury(true)} />
          {isAuthenticated && (
            <div className={s.invite} onClick={() => setShowRefferal(true)}>
              <p>{`Share & earn`}</p>
            </div>
          )}
          {isAuthenticated && (
            <button
              className={s.btnSwap}
              onClick={() => router.push(ROUTE_PATH.ALPHA_MOBILE_SWAP)}
            >
              <p>Swap</p>
            </button>
          )}

          {/*<Flex flexDirection="row" gap="8px" ml="32px">*/}
          {/*  {renderMenuItems()}*/}
          {/*</Flex>*/}
        </div>

        <SearchKeys />

        {!isAuthenticated && (
          <div className={s.containerRight}>
            <button className={s.syncProfileBtn} onClick={onSyncProfile}>
              <span>Sign in by phone</span>
            </button>

            <button
              className={s.syncProfileBtn}
              onClick={() => setShowDownloadApp(true)}
            >
              <span>Download app</span>
            </button>
          </div>
        )}

        {isAuthenticated && (
          <div className={s.containerRight}>
            {/* <LinkFT /> */}
            <Box w="280px" />

            <Box
              position={'relative'}
              onClick={() => setShowNotifications(true)}
              cursor="pointer"
            >
              {unreadCount > 0 && !isOnPage && (
                <span className={`${s.redDot}`}>
                  {unreadCount < 10 ? unreadCount : '9+'}
                </span>
              )}
              <IconBell />
            </Box>

            <button className={s.profile} onClick={() => setShowProfile(true)}>
              <div className={s.profileBtn}>
                <span>
                  {formatCurrency(
                    isForceConvert
                      ? balanceL2.amountFormated
                      : balanceL2.amountBTCFormatted,
                    0,
                    4
                  )}{' '}
                </span>
              </div>
              {playerPoolProfile && (
                <img
                  className={s.avatarTw}
                  src={playerPoolProfile.twitterAvatar}
                ></img>
              )}
            </button>
          </div>
        )}
      </header>
      <SwapTcToBTCModal
        isOpen={showConvertTC}
        onClose={() => {
          setShowConvertTC(false);
        }}
      />
      <NotificationsModal
        isOpen={showNotifications}
        onClose={() => setShowNotifications(false)}
      />
      <TreasuryModal
        isOpen={showTreasury}
        onClose={() => setShowTreasury(false)}
      />
      <RefferalModal
        isOpen={showRefferal}
        onClose={() => setShowRefferal(false)}
      />
      <DownloadAppModal
        isOpen={showDownloadApp}
        onClose={() => setShowDownloadApp(false)}
      />
      <ProfileDrawer
        isOpen={showProfile}
        onClose={() => setShowProfile(false)}
      />
      {/* <div className={s.progessBar} id="progress-bar">
        <ProgressBar />
      </div> */}
    </>
  );
};

export default DesktopHeader;
