import Lottie from 'lottie-react';
import s from './styles.module.scss';
import fileLottie from './hallowin.json';
import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

type Props = {
  size?: number;
};

const LottieHallowin = ({ size = 40, ...props }: Props) => {
  const ref = useRef(null);
  useEffect((): void => {
    // @ts-ignore
    ref.current?.setSpeed(1.5);
  }, []);

  return (
    <Box className={s.container} width={`${size}px`} height={`${size}px`}>
      <Lottie ref={ref} animationData={fileLottie} {...props} />
    </Box>
  );
};

export default LottieHallowin;
