import PinSvg from '@/modules/AlphaPWA/Home/CreatePost/Icon/PinIcon';
import React, { useMemo } from 'react';
import s from './style.module.scss';
import { useDM } from '../../provider';
import PinChatSvg from '../Icon/PinChatSvg';
import UnpinChatSvg from '../Icon/UnpinChatSvg';
import { DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';

const PinChat = ({ room }: { room: DMRoom }) => {
  const { updateRoomPinned } = useDM();
  const handlePinChat = (e: any) => {
    e.stopPropagation();

    updateRoomPinned(room.id, !room.pinned);
  };

  return (
    <span className={`${s.pinChat}`}>
      {room.pinned ? <PinChatSvg /> : <></>}
    </span>
  );
};

export default PinChat;
