import s from '@/modules/AlphaPWA/Home/PostItem/PostItemV2.module.scss';
import IconPinnedPost from '@/modules/AlphaPWA/Home/PostItem/Icon/IconPinnedPost';
import React from 'react';

export function PinLabel() {
  return (
    <div className={s.additionalInfoBlock}>
      <span className={s.additionalInfoBlock_icon}>
        <IconPinnedPost />
      </span>
      <span className={s.additionalInfoBlock_text}>Pinned post</span>
    </div>
  );
}
