import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Switch,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

import useDebounce from '@/hooks/useDebounce';
import IcSearch from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icSearch';
import BtnFilter from '@/modules/AlphaPWA/Profiles/TradeKey/pro/btnFilter';
import SortBy from '@/modules/AlphaPWA/Profiles/TradeKey/pro/sortBy';
import {
  selectCommonReducer,
  setKeyListView,
  setShowKeyLeft,
  setShowKeyRight,
} from '@/state/common';
import { colors } from '@/utils/color';
import queryString from 'query-string';
import { IoIosCloseCircle } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import s from './styles.module.scss';
import {
  DEFAULT_SLIDE,
  KEY_LAST_SORT,
  KEY_LAST_SORT_VALUE,
  PLACE_HOLDER_LAST_SORT,
  PLACE_HOLDER_LAST_SORT_VALUE,
  SLIDE_ALPHA,
  SLIDE_FT,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { compareString } from '@/utils';
import { clone } from 'lodash';
import {
  DESKTOP_SORTS,
  PLACE_HOLDER_SORTS,
} from '@/modules/AlphaPWA/Profiles/TradeKey/pro/itemFilterSort';
import { IItemFilterType } from '@/modules/AlphaPWA/Profiles/TradeKey/pro/itemFilterTypes';
import IcRightArrow from './img/icRightArrow';
import IcLeftArrow from './img/icLeftArrow';
import clsx from 'classnames';
import storageLocal from '@/utils/storage.local';
import IcGridView from '@/modules/AlphaPWA/Keys/pro/key/img/IcGridView';
import IcListView from '@/modules/AlphaPWA/Keys/pro/key/img/IcListView';

export interface IKeyHeader {
  filters: IItemFilterType[];
  onFilter: any;
  setSearching: any;
  searching: any;
  isFetching: boolean;
}

export interface IItemPlatform {
  key: string;
  label: string;
  value: number;
}

export const Platforms: IItemPlatform[] = [
  {
    key: 'nb',
    label: 'NBC',
    value: SLIDE_ALPHA,
  },
  // {
  //   key: 'ft',
  //   label: 'FT',
  //   value: SLIDE_FT,
  // },
  // {
  //   key: 'sa',
  //   label: 'SA',
  //   value: SLIDE_SA,
  // },
];

const ProKeyHeader: React.FC<IKeyHeader> = ({
  onFilter,
  filters,
  searching,
  setSearching,
  isFetching,
}) => {
  const dispatch = useDispatch();
  const showKeyLeft = useSelector(selectCommonReducer).showKeyLeft;
  const showKeyRight = useSelector(selectCommonReducer).showKeyRight;
  const filterKeyParams = (useSelector(selectCommonReducer) as any)[
    'filterKeyParams'
  ] as any;
  const [filterKeys, setFilterKeys] = useState(
    queryString.parse(filterKeyParams)
  );
  const _textSearch = useMemo(() => {
    return filterKeys?.['search'];
  }, [filterKeys]);

  const isPlaceholder = useMemo(
    () => compareString(filterKeys?.placeholder, '1'),
    [filterKeys]
  );

  const [textSearch, setTextSearch] = useState<any>(_textSearch);

  const [currSide, setCurrSide] = useState<any[]>([]);

  const textSearchDebounced = useDebounce(textSearch, 1000);

  const keyListView = useSelector(selectCommonReducer).keyListView;

  useEffect(() => {
    const _filterKeyParams = queryString.parse(filterKeyParams);

    if (compareString(_filterKeyParams?.placeholder, '1')) {
      if (storageLocal.get(PLACE_HOLDER_LAST_SORT)) {
        _filterKeyParams.sort_col = storageLocal.get(PLACE_HOLDER_LAST_SORT);
        _filterKeyParams.sort_type = storageLocal.get(
          PLACE_HOLDER_LAST_SORT_VALUE
        );
      }
    } else {
      if (storageLocal.get(KEY_LAST_SORT)) {
        _filterKeyParams.sort_col = storageLocal.get(KEY_LAST_SORT);
        _filterKeyParams.sort_type = storageLocal.get(KEY_LAST_SORT_VALUE);
      }
    }

    setFilterKeys(_filterKeyParams);
    setTextSearch(_filterKeyParams?.['search']);
    const _side = _filterKeyParams?.side || DEFAULT_SLIDE;

    setCurrSide(
      compareString(_side, DEFAULT_SLIDE) ? [SLIDE_ALPHA, SLIDE_FT] : [_side]
    );
  }, [filterKeyParams]);

  useEffect(() => {
    if (!isFetching) {
      onSearch(textSearch);
    }
  }, [textSearchDebounced]);

  const onEnter = (e: any) => {
    if (e.code === 'Enter') {
      e.target.blur();
      return onSearch(textSearch);
    }
  };

  const onSearch = (_txtSearch: any) => {
    setSearching(true);
    setTextSearch(_txtSearch);
    return onFilter({
      ...filterKeys,
      search: _txtSearch?.trim(),
    });
  };

  const onCheckSlide = (e: any) => {
    const _side = e.target.value;
    let __curr = clone(currSide);
    if (currSide.find((v: any) => compareString(v, _side))) {
      __curr = __curr.filter(v => !compareString(v, _side));
    } else {
      __curr = __curr.concat([_side]);
    }

    if (__curr.length === 0) {
      __curr = [SLIDE_ALPHA, SLIDE_FT];
    }

    setCurrSide(__curr);
    onFilter({
      ...filterKeys,
      side: __curr?.length === 2 ? DEFAULT_SLIDE : __curr[0],
    });
  };

  const onSwitchPlaceHolder = (e: any) => {
    const _placeholder = isPlaceholder ? '0' : '1';

    const _params = filterKeys;

    if (compareString(_placeholder, '1')) {
      _params.sort_col = storageLocal.get(PLACE_HOLDER_LAST_SORT);
      _params.sort_type = storageLocal.get(PLACE_HOLDER_LAST_SORT_VALUE);
    } else {
      _params.sort_col = storageLocal.get(KEY_LAST_SORT);
      _params.sort_type = storageLocal.get(KEY_LAST_SORT_VALUE);
    }

    onFilter({
      ..._params,
      placeholder: _placeholder,
    });
  };

  return (
    <Flex
      className={s.header}
      pl={compareString(showKeyLeft, '1') ? '20px' : '40px'}
      pr={compareString(showKeyRight, '1') ? '10px' : '30px'}
    >
      {(compareString(showKeyLeft, '-1') ||
        compareString(showKeyLeft, '2')) && (
        <Flex
          className={clsx(s.btnSide, s.btnLeftSide)}
          onClick={() => {
            if (compareString(showKeyLeft, '-1')) {
              dispatch(setShowKeyLeft('2'));
            } else {
              dispatch(setShowKeyLeft('1'));
            }
          }}
        >
          <IcRightArrow />
        </Flex>
      )}
      <Flex>
        <SortBy
          sorts={isPlaceholder ? PLACE_HOLDER_SORTS : DESKTOP_SORTS}
          filterKeys={filterKeys}
          onFilter={onFilter}
          setFilterKeys={setFilterKeys}
          className={s.sortBy}
        />
      </Flex>
      <Flex gap={4} alignItems={'center'} flex={1} justifyContent={'flex-end'}>
        {!isPlaceholder && (
          <Flex alignItems={'center'} gap={8} mr={10}>
            {Platforms.map(p => {
              return (
                <Checkbox
                  key={p.key}
                  value={p.value}
                  isChecked={
                    currSide.findIndex(v => compareString(p.value, v)) > -1
                  }
                  onChange={onCheckSlide}
                >
                  {p.label}
                </Checkbox>
              );
            })}
          </Flex>
        )}

        <FormControl
          className={s.switchPlaceholders}
          // mr={10}
          display="flex"
          alignItems="center"
          width={'auto'}
        >
          <FormLabel
            htmlFor="placeholder"
            mb="0"
            className={isPlaceholder ? 'active' : ''}
          >
            Placeholders
          </FormLabel>
          <Switch
            isChecked={isPlaceholder}
            onChange={onSwitchPlaceHolder}
            id="placeholder"
            size={'lg'}
          />
        </FormControl>

        <InputGroup className={s.inputContainer}>
          <InputLeftElement pointerEvents="none">
            {searching ? (
              <Spinner color={colors.hLabelColor} size={'md'} />
            ) : (
              <IcSearch />
            )}
          </InputLeftElement>
          <Input
            placeholder="Search"
            value={textSearch}
            onChange={e => setTextSearch(e.target.value)}
            enterKeyHint="search"
            onKeyDownCapture={onEnter}
          />
          {textSearch && (
            <InputRightElement
              pointerEvents={searching ? 'none' : 'auto'}
              onClick={() => setTextSearch('')}
            >
              <IoIosCloseCircle
                fontSize={'16px'}
                color={colors.hLabelColor}
                cursor={'pointer'}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <BtnFilter
          onFilter={onFilter}
          filters={filters}
          keyName={'filterKeyParams'}
        />
        <Flex gap={4} className={s.selectViewWrapper}>
          <Box
            aria-label={'btn-grid'}
            onClick={() => dispatch(setKeyListView('1'))}
            className={compareString(keyListView, '1') ? s.isSelected : ''}
          >
            <IcListView />
          </Box>
          <Box
            aria-label={'btn-grid'}
            onClick={() => dispatch(setKeyListView('0'))}
            className={compareString(keyListView, '0') ? s.isSelected : ''}
          >
            <IcGridView />
          </Box>
        </Flex>
      </Flex>
      {compareString(showKeyRight, '0') && (
        <Flex
          className={clsx(s.btnSideRight)}
          onClick={() => {
            dispatch(setShowKeyRight('1'));
          }}
        >
          <IcLeftArrow />
        </Flex>
      )}
    </Flex>
  );
};

export default ProKeyHeader;
