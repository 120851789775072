export default function CircleActiveIcon() {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          d="M12.7927 20.4831C9.65999 22.1498 5.76939 20.9615 4.10274 17.8286C2.43609 14.6961 3.62452 10.8055 6.75717 9.13884C9.88983 7.4722 13.7804 8.66061 15.4471 11.7933C17.1137 14.9259 15.9253 18.8165 12.7927 20.4831Z"
          fill="url(#paint0_linear_18290_6497)"
        />
      </g>
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          d="M19.7985 11.0232C23.1925 12.0599 25.1033 15.6516 24.0667 19.0456C23.03 22.4394 19.4382 24.3502 16.0443 23.3136C12.6505 22.2769 10.7396 18.6852 11.7763 15.2913C12.813 11.8974 16.4047 9.98652 19.7985 11.0232Z"
          fill="url(#paint1_linear_18290_6497)"
        />
      </g>
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <path
          d="M21.8363 11.7086C20.7996 15.1025 17.2079 17.0134 13.814 15.9767C10.4201 14.94 8.50927 11.3483 9.54596 7.95437C10.5827 4.56047 14.1744 2.6496 17.5683 3.6863C20.9621 4.723 22.8731 8.3147 21.8363 11.7086Z"
          fill="url(#paint2_linear_18290_6497)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_18290_6497"
          x1="4.09461"
          y1="17.8135"
          x2="15.439"
          y2="11.778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006CEA" />
          <stop offset="1" stopColor="#E6F1FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18290_6497"
          x1="20.9394"
          y1="22.8411"
          x2="14.9035"
          y2="11.4958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00ED7B" />
          <stop offset="1" stopColor="#E7FFF3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18290_6497"
          x1="13.8495"
          y1="16.0148"
          x2="17.6038"
          y2="3.72442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF2E1" />
          <stop offset="1" stopColor="#F7931A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
