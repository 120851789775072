import { CDN_URL_IMAGES } from '@/configs';
import { AssetsContext } from '@/contexts/assets-context';
import { formatCurrency } from '@/utils';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useContext, useMemo, useState } from 'react';
import s from './styles.module.scss';
import cs from 'classnames';
import TreasuryAmount from './TreasuryAmount';
import {
  Carousel,
  CarouselContainer,
  CarouselSlot,
} from '@/modules/AlphaPWA/DirectMessage/List/Carousel';
import BitcoinWallets from '@/modules/BitcoinWallets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TreasuryModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const { treasuryBalance: balance } = useContext(AssetsContext);

  const [tab, setTab] = useState<'TREASURY' | 'TVL'>('TREASURY');

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px 22px"
        maxW={'560px'}
        bg="#212121"
        textAlign={'center'}
      >
        <Flex justifyContent={'flex-end'}>
          <ModalCloseButton />
        </Flex>
        <ModalHeader
          textAlign="center"
          fontSize="24px"
          fontWeight={'600'}
          mb="8px"
        >
          Community Treasury
        </ModalHeader>
        <ModalBody marginTop={0}>
          <div className={s.tabs}>
            <div className={s.tabList}>
              <div
                className={cs(
                  s.tabListItem,
                  tab === 'TREASURY' ? s.tabListItemActive : ''
                )}
                onClick={() => setTab('TREASURY')}
              >
                TREASURY
              </div>
              <div
                className={cs(
                  s.tabListItem,
                  tab === 'TVL' ? s.tabListItemActive : ''
                )}
                onClick={() => setTab('TVL')}
              >
                TVL
              </div>
            </div>
          </div>
          <div className={s.tabPanels}>
            <Carousel>
              <CarouselContainer pos={tab === 'TREASURY' ? 0 : 1}>
                <CarouselSlot>
                  <div
                    className={cs(
                      s.tabPanelsItem,
                      tab === 'TREASURY' ? s.tabPanelsItemActive : ''
                    )}
                  >
                    <TreasuryAmount balance={balance} />
                  </div>
                </CarouselSlot>
                <CarouselSlot>
                  <div
                    className={cs(
                      s.tabPanelsItem,
                      tab === 'TVL' ? s.tabPanelsItemActive : ''
                    )}
                  >
                    <BitcoinWallets />
                  </div>
                </CarouselSlot>
              </CarouselContainer>
            </Carousel>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TreasuryModal;
