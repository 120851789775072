/* eslint-disable @typescript-eslint/no-explicit-any */
const tokenIcons: any = {
  weth: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  eth: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  wbtc: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
  btc: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
  gm: 'https://i.ibb.co/GCCRx0C/GM.png',
  'wrapped gm': 'https://i.ibb.co/GCCRx0C/GM.png',
  tc: 'https://cdn.trustless.domains/icons/tc_ic.svg',
  usdt: 'https://i.ibb.co/Jkt0Djj/tether-usdt-icon.png',
  pepe: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png',
  sol: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
};

export default tokenIcons;
