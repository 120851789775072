import React, { useEffect, useMemo, useRef, useState } from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import Item, { ITEM_HEIGHT } from './Item';
import { useDM } from '../../provider';
import Empty from '@/modules/AlphaPWA/DirectMessage/Empty';
import moment from 'moment';
import { orderBy } from 'lodash';
import { DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { selectCommonReducer, setShowKeyLeft } from '@/state/common';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { compareString } from '@/utils';
import { filterUser } from '../CirclesTab';
import EmptySearch from '../EmptySearch';

const Chat = dynamic(() => import('../../Chat'), {
  ssr: false,
});

type RenderRoom = DMRoom & { sortedTime?: number };

const MIN_DATE = new Date();
MIN_DATE.setFullYear(2000);

const DMTab = ({
  isActive,
  valueSearch,
}: {
  isActive: boolean;
  valueSearch?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const shouldRenderRef = useRef(true);
  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);

  const [currentSearch, setCurrentSearch] = useState('');
  const [pinnedLength, setPinnedLength] = useState(0);

  const [listHeight, setListHeight] = useState(0);
  const { rooms, roomId, setRoomId } = useDM();
  const [renderRooms, setRenderRooms] = useState<RenderRoom[]>([]);

  let refDidSetFirstRoomId = useRef<boolean>(false);

  const [dmDetail, setDmDetail] = useState<string | undefined>();
  const dispatch = useDispatch();
  const showKeyLeft = useSelector(selectCommonReducer).showKeyLeft;
  const showKeyRight = useSelector(selectCommonReducer).showKeyRight;

  const isSearching = useMemo(() => {
    return valueSearch !== currentSearch;
  }, [valueSearch]);

  const onClickDmDetail = (roomId: string) => {
    if (compareString(showKeyLeft, '-1')) {
      dispatch(setShowKeyLeft('2'));
    }
    if (dmDetail !== roomId) {
      onSetActiveRoom(roomId);
    }
  };

  const onSetActiveRoom = (roomId: string) => {
    setDmDetail(roomId);
    setRoomId(undefined);
    setTimeout(() => {
      setRoomId(roomId);
    }, 200);
  };

  useEffect(() => {
    if (ref.current) {
      // 73 is nav
      setListHeight(ref.current.clientHeight - 73);
    }
  }, []);

  useEffect(() => {
    if (isActive && dmDetail) {
      setRoomId(dmDetail);
      setDmDetail(dmDetail);
    }
  }, [isActive]);

  useEffect(() => {
    if (
      renderRooms &&
      renderRooms.length > 0 &&
      !dmDetail &&
      !refDidSetFirstRoomId.current
    ) {
      refDidSetFirstRoomId.current = true;
      onClickDmDetail(renderRooms[0].id);
    }
  }, [renderRooms]);

  useEffect(() => {
    const computedData = () => {
      let chatDms = rooms.filter(r => r.type === 'CHAT_ROOM' && r.isActive);
      const isHaveData = chatDms.length;

      if (valueSearch) {
        chatDms = chatDms.filter(room => filterUser(room, valueSearch));
        if (!chatDms.length && isHaveData) {
          setIsEmptySearch(true);
        }
      } else {
        setIsEmptySearch(false);
      }

      const pinnedGroup = chatDms.filter(r => !!r.pinned);
      const unPinnedGroup = chatDms.filter(r => !r.pinned);

      setRenderRooms([
        ...pinnedGroup,
        ...orderBy(
          unPinnedGroup.map(r => ({
            ...r,
            sortedTime: Math.floor(
              moment(
                r.lastMessage ? r.lastMessage.createdAt : MIN_DATE.toISOString()
              ).valueOf() / 60000
            ),
          })),
          ['sortedTime'],
          ['desc']
        ),
      ]);

      shouldRenderRef.current = true;
    };

    if (shouldRenderRef.current) {
      if (isActive) {
        shouldRenderRef.current = false;

        const isSearching = valueSearch !== currentSearch;
        if (isSearching) {
          computedData();
          setCurrentSearch(valueSearch || '');
        } else {
          const pinL = rooms?.filter(
            r => r !== null && r !== undefined && r.pinned
          ).length;
          const isPinning = pinL !== pinnedLength;
          if (renderRooms.length) {
            setTimeout(
              () => {
                computedData();
              },
              isPinning ? 0 : 1000
            );
            if (isPinning) {
              setPinnedLength(pinL);
            }
            return;
          }

          computedData();
        }
      }
    }
  }, [rooms, isActive, valueSearch]);

  const gridTemplate = useMemo(() => {
    if (!dmDetail || compareString(showKeyLeft, '-1')) {
      return '1fr 0';
    } else {
      if (compareString(showKeyRight, '1')) {
        return '1fr 2fr';
      } else {
        if (compareString(showKeyLeft, '1')) {
          return '1fr 3fr';
        } else {
          return '1fr 2fr';
        }
      }
    }
  }, [dmDetail, showKeyRight, showKeyLeft]);

  if (rooms.length) {
    return (
      <Grid className={cs(s.wrapper, s.visible)} templateColumns={gridTemplate}>
        <GridItem>
          <div
            className={cs(s.list)}
            style={{
              height: `${renderRooms.length * ITEM_HEIGHT}px`,
            }}
          >
            {renderRooms.map((r, idx) => (
              <Item
                key={r.id}
                room={r}
                order={idx}
                onClickItem={onClickDmDetail}
              />
            ))}
          </div>
        </GridItem>
        <GridItem
          w="100%"
          h="100%"
          borderLeft={'1px solid #353945'}
          borderRight={'1px solid #353945'}
          position={'relative'}
        >
          {isActive && dmDetail && (
            <Box w="100%" h="100%">
              <Chat />
            </Box>
          )}
          {!isActive && <Box w="100%" h="100%" />}
        </GridItem>
      </Grid>
    );
  }

  if (isEmptySearch) {
    return <EmptySearch />;
  }

  return (
    <div className={cs(s.wrapper, s.visible)} ref={ref}>
      <div className={cs(s.list)}>
        <Empty />
      </div>
    </div>
  );
};

export default React.memo(DMTab);
