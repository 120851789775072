import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// define the base component styles
const baseStyle = defineStyle({
  fontSize: '13px',
  fontFamily: 'var(--pro-font-family)',
  borderRadius: '4px',
  bg: '#000',
  padding: '5px 10px',
});

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle });
