import s from '@/modules/AlphaPWA/Home/PostItem/PostItemV2.module.scss';
import { linkifyText } from '@/utils/dom';
import DOMPurify from 'dompurify';
import { Box } from '@chakra-ui/react';
import { isImageOrVideo } from '@/utils/media';
import MetaDataPreview from '@/modules/AlphaPWA/Home/MetaDataPreview';
import React, { useMemo, useRef } from 'react';
import { IPostData } from '@/interfaces/api/post';
import ShowMoreByLines from '@/components/ShowMoreText';

type Props = {
  data: IPostData;
  isDetail?: boolean;
};

const MediaPost: React.FC<Props> = ({
  data,
  isDetail,
}: Props): React.ReactElement => {
  const videoRef = useRef(null);
  const isHadMedia = useMemo((): boolean => {
    return data?.media_urls && data?.media_urls.length > 0;
  }, [data]);

  return (
    <div className={s.mediaPost}>
      {isDetail ? (
        <div
          className={s.postContent}
          dangerouslySetInnerHTML={{
            __html: linkifyText(DOMPurify.sanitize(data?.content)).replace(
              /\n/g,
              '<br />'
            ),
          }}
        />
      ) : (
        <ShowMoreByLines lineHeight={16} linesToShow={24}>
          <div
            className={s.postContent}
            dangerouslySetInnerHTML={{
              __html: linkifyText(DOMPurify.sanitize(data?.content)).replace(
                /\n/g,
                '<br />'
              ),
            }}
          />
        </ShowMoreByLines>
      )}
      {isHadMedia && (
        <Box
          className={s.media_wrapper}
          gridTemplateColumns={
            data?.media_urls?.length === 1 ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
          }
        >
          {data?.media_urls.map((src: string) => (
            <div key={src} className={s.single_img_wrapper}>
              {isImageOrVideo(src) === 'video' && (
                <>
                  <video
                    controls
                    playsInline={true}
                    preload="metadata"
                    autoPlay={true}
                    muted={true}
                    loop={false}
                    ref={videoRef}
                  >
                    <source src={src}></source>
                  </video>
                </>
              )}

              {isImageOrVideo(src) === 'image' && <img src={src}></img>}
              {src.includes('giphy.com') && <img src={src}></img>}
            </div>
          ))}
        </Box>
      )}

      {data?.metadata?.url && (
        <Box mt={4} mb={4}>
          <MetaDataPreview {...data.metadata} />
        </Box>
      )}
    </div>
  );
};

export default React.memo(MediaPost);
