import React from 'react';

import { useWindowSize } from '@trustless-computer/dapp-core';
import { PageProps } from './Page.types';
import s from './styles.module.scss';

const SectionDescription = React.memo((props: PageProps) => {
  const {
    totalAmountStr,
    mintedOnNOSStr,
    totalValueLockedStr,
    availableBalanceStr,
  } = props;
  const { mobileScreen } = useWindowSize();

  const renderBox = ({
    label,
    data,
    note,
    link,
    onClick,
  }: {
    label: string;
    data?: any;
    note?: string;
    link?: string;
    onClick?: any;
  }) => {
    return (
      <div className={s.box} onClick={onClick}>
        {link ? (
          <div
            className={s.row}
            onClick={() => {
              window.open(link, '_blank');
            }}
          >
            <p className={s.label}>{label}</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
              <path d="M11 13l9 -9"></path>
              <path d="M15 4h5v5"></path>
            </svg>
          </div>
        ) : (
          <div className={s.row}>
            <p className={s.label}>{label}</p>
          </div>
        )}
        <p className={s.amount}>{data}</p>
      </div>
    );
  };
  return (
    <div className={s.boxContainer}>
      {renderBox({
        label: `BTC TVL `,
        data: `${mintedOnNOSStr || '--'} BTC`,
        note: 'The total amount of BTC is currently held within the Bitcoin L2.',
        link: 'https://explorer.l2.trustless.computer/token/0x111808AbE352c8003e0eFfcc04998EaB26Cebe3c/token-transfers',
      })}

      {renderBox({
        label: 'Key TVL',
        data: `${totalValueLockedStr || '--'} BTC`,
        note: 'The amount of BTC is securely locked within our smart contract used for key purchases',
        link: 'https://explorer.l2.trustless.computer/address/0xea21fbBB923E553d7b98D14106A104665BA57eCd',
      })}

      {renderBox({
        label: 'Floating BTC',
        data: `${availableBalanceStr || '--'} BTC`,
        note: `The balance available in users' wallets for key purchases. When users use this balance to buy keys, the available balance decreases and the total locked value increases accordingly.`,
      })}

      {renderBox({
        label: 'Backing BTC on the Bitcoin network',
        data: `${totalAmountStr || '--'} BTC`,
        note: `To ensure a 1:1 correspondence between BTC on Bitcoin L2 and BTC on Bitcoin, this ensures that you can always withdraw your full amount without any complications.`,
      })}

      {renderBox({
        label: 'Smart contract audit report by PeckShield',
        data: (
          <svg
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.75 13.9393L15.9697 10.7197L17.0303 11.7803L12 16.8107L6.96967 11.7803L8.03033 10.7197L11.25 13.9393L11.25 4.5L12.75 4.5L12.75 13.9393Z"
              fill="#ffffff"
            />
            <path d="M18 18L18 19.5L6 19.5L6 18L18 18Z" fill="#ffffff" />
          </svg>
        ),
        note: `To ensure a 1:1 correspondence between BTC on Bitcoin L2 and BTC on Bitcoin, this ensures that you can always withdraw your full amount without any complications.`,
        onClick: () => {
          window.open(
            'https://cdn.newbitcoincity.com/docs/PeckShield-Audit-Report-NBC-v1.0.pdf',
            '_blank'
          );
        },
      })}
    </div>
  );
});

export default SectionDescription;
