import chalk from 'chalk';

const getPrefix = () => {
  return `${chalk.bold.cyan(`[${new Date().toISOString()}]`)} ${chalk.bold.blue(
    '[agora-service]'
  )}`;
};

class Logger {
  public static instance?: typeof console;

  public static prefix() {
    return getPrefix();
  }

  public static setInstance(inst?: typeof console) {
    Logger.instance = inst;
  }

  public static error = (...rest: any): void => {
    if (Logger.instance) {
      Logger.instance.error.call(
        Logger.instance.error,
        Logger.prefix(),
        ...rest
      );
    }
  };

  public static info = (...rest: any): void => {
    if (Logger.instance) {
      Logger.instance.info.call(Logger.instance.info, Logger.prefix(), ...rest);
    }
  };

  public static debug = (...rest: any): void => {
    if (Logger.instance) {
      Logger.instance.debug.call(
        Logger.instance.debug,
        Logger.prefix(),
        ...rest
      );
    }
  };

  public static warn = (...rest: any): void => {
    if (Logger.instance) {
      Logger.instance.warn.call(Logger.instance.warn, Logger.prefix(), ...rest);
    }
  };

  public static log = (...rest: any): void => {
    if (Logger.instance) {
      Logger.instance.log.call(Logger.instance.log, Logger.prefix(), ...rest);
    }
  };
}

if (typeof window !== 'undefined') {
  Logger.info = (function () {
    return Function.prototype.bind.call(console.info, console, getPrefix());
  })();
  Logger.error = (function () {
    return Function.prototype.bind.call(console.error, console, getPrefix());
  })();
  Logger.debug = (function () {
    return Function.prototype.bind.call(console.debug, console, getPrefix());
  })();
  Logger.warn = (function () {
    return Function.prototype.bind.call(console.warn, console, getPrefix());
  })();
  Logger.log = (function () {
    return Function.prototype.bind.call(console.log, console, getPrefix());
  })();
}

export default Logger;
