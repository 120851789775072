import { RootState } from '@/state';

export const getUnreadCount = (state: RootState): number =>
  state.notification.unreadNotifications.length;

export const getUnreadTradeCount = (state: RootState): number =>
  state.notification.unreadNotifications.filter(
    notiObj =>
      notiObj.type === 'buy' ||
      notiObj.type === 'sell' ||
      notiObj.type === 'issue'
  ).length;

export const getUnreadPostCount = (state: RootState): number =>
  state.notification.unreadNotifications.filter(
    notiObj => notiObj.type === 'post' || notiObj.type === 'reply'
  ).length;
