import React from 'react';

const IcSearch = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74997 13.7501C9.40658 13.7501 10.0568 13.6208 10.6634 13.3695C11.27 13.1182 11.8212 12.7499 12.2855 12.2856C12.7498 11.8213 13.1181 11.2701 13.3694 10.6635C13.6206 10.0569 13.75 9.4067 13.75 8.75009C13.75 8.09348 13.6206 7.4433 13.3694 6.83668C13.1181 6.23005 12.7498 5.67885 12.2855 5.21456C11.8212 4.75027 11.27 4.38197 10.6634 4.1307C10.0568 3.87942 9.40658 3.75009 8.74997 3.75009C7.42389 3.75009 6.15212 4.27688 5.21444 5.21456C4.27676 6.15224 3.74997 7.42401 3.74997 8.75009C3.74997 10.0762 4.27676 11.3479 5.21444 12.2856C6.15212 13.2233 7.42389 13.7501 8.74997 13.7501ZM14.0166 12.8384L17 15.8218C17.0795 15.8987 17.1429 15.9907 17.1865 16.0924C17.2301 16.1941 17.253 16.3035 17.2539 16.4141C17.2548 16.5248 17.2336 16.6345 17.1917 16.7369C17.1497 16.8392 17.0877 16.9322 17.0095 17.0104C16.9312 17.0886 16.8381 17.1504 16.7356 17.1922C16.6332 17.2341 16.5234 17.2551 16.4128 17.254C16.3021 17.253 16.1928 17.2299 16.0912 17.1862C15.9895 17.1425 15.8976 17.0789 15.8208 16.9993L12.8375 14.0159C11.4979 15.0558 9.8123 15.5461 8.12393 15.3869C6.43557 15.2278 4.87131 14.4312 3.7496 13.1593C2.62789 11.8874 2.03304 10.2359 2.08616 8.54086C2.13928 6.84585 2.83636 5.23478 4.03551 4.03563C5.23466 2.83648 6.84572 2.1394 8.54074 2.08628C10.2358 2.03316 11.8873 2.62801 13.1592 3.74972C14.4311 4.87143 15.2277 6.43569 15.3868 8.12406C15.5459 9.81242 15.0557 11.498 14.0158 12.8376L14.0166 12.8384Z"
        fill="#898989"
      />
    </svg>
  );
};

export default IcSearch;
