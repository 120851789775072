const IconStakeBlast = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="black"/>
      <path d="M4.7041 8.91556L6.74873 6.84033H18.1168L19.6347 8.3582L18.9533 10.6811L16.6299 12.0649L17.9617 13.2215L17.4044 15.8851L14.8334 17.0002H5.44753L7.73983 9.94311L9.25937 11.0034L8.04952 15.018H15.1741L16.0726 12.7257H10.7445L11.581 10.9602L16.816 10.9292L17.1877 8.88455L4.7041 8.91556Z" fill="#FCF765" stroke="#F3F700" stroke-width="0.485293" stroke-miterlimit="10"/>
    </svg>
  );
};

export default IconStakeBlast;
