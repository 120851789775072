export const IcReply = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4125 16.7125L5.9525 15.865L6.41 16.0875C7.46875 16.6012 8.67875 16.875 10 16.875C14.37 16.875 17.5 13.7525 17.5 10C17.5 6.23375 14.3925 3.125 10 3.125C5.6075 3.125 2.5 6.23375 2.5 10C2.50739 11.4413 2.98196 12.8413 3.8525 13.99L4.275 14.5525L3.4125 16.7113V16.7125ZM2.52875 18.325C2.41563 18.3629 2.29403 18.3676 2.17831 18.3386C2.06259 18.3097 1.95759 18.2481 1.8757 18.1614C1.79381 18.0746 1.73846 17.9663 1.71618 17.8491C1.69391 17.7319 1.70564 17.6107 1.75 17.5L2.85375 14.7413C1.82023 13.376 1.25748 11.7123 1.25 10C1.25 5.8575 4.6025 1.875 10 1.875C15.3975 1.875 18.75 5.8575 18.75 10C18.75 14.1425 15.3625 18.125 10 18.125C8.44375 18.125 7.05375 17.79 5.865 17.2125L2.5275 18.325H2.52875Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
