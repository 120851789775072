import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';

import useDebounce from '@/hooks/useDebounce';
import { selectCommonReducer } from '@/state/common';
import { colors } from '@/utils/color';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import { useSelector } from 'react-redux';
import IcSearch from '../assets/icons/icSearch';
import s from '../styles.module.scss';
import BtnFilter from './btnFilter';
import { IItemSort } from './itemFilterSort';
import SortBy from './sortBy';
import { IItemFilterType } from './itemFilterTypes';

const ProTradeKeyHeader = ({
  onFilter,
  setSearching,
  searching,
  isNotAllowFilter,
  sorts = [],
  keyName = 'filterKeyParams',
  filters,
}: {
  onFilter: (_: any) => void;
  setSearching: (_: any) => void;
  searching?: boolean;
  isNotAllowFilter?: boolean;
  sorts?: IItemSort[];
  keyName?: string;
  filters: IItemFilterType[];
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const filterKeyParams = (useSelector(selectCommonReducer) as any)[
    keyName
  ] as any;
  const [filterKeys, setFilterKeys] = useState(
    queryString.parse(filterKeyParams)
  );

  const _textSearch = useMemo(() => {
    return filterKeys?.['search'];
  }, [filterKeys]);

  const [textSearch, setTextSearch] = useState<any>(_textSearch);

  const textSearchDebounced = useDebounce(textSearch, 1000);

  useEffect(() => {
    const _filterKeyParams = queryString.parse(filterKeyParams);
    setFilterKeys(_filterKeyParams);
    setTextSearch(_filterKeyParams?.['search']);
  }, [filterKeyParams]);

  useEffect(() => {
    onSearch(textSearch);
  }, [textSearchDebounced]);

  const onEnter = (e: any) => {
    if (e.code === 'Enter') {
      e.target.blur();
      return onSearch(textSearch);
    }
  };

  const onSearch = (_txtSearch: any) => {
    setSearching(true);
    setTextSearch(_txtSearch);
    return onFilter({
      ...filterKeys,
      search: _txtSearch?.trim(),
    });
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverAnchor>
        <Flex
          className={s.header}
          gap={'20px'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Flex position={'relative'} justifyContent={'flex-end'}>
            <SortBy
              sorts={sorts}
              filterKeys={filterKeys}
              onFilter={onFilter}
              setFilterKeys={setFilterKeys}
            />
          </Flex>
          <Flex alignItems={'center'} flex={1} justifyContent={'flex-end'}>
            <BtnFilter onFilter={onFilter} filters={filters} keyName={keyName} />
            <PopoverTrigger>
              <IconButton
                className={clsx(
                  s.btnSearch,
                  Boolean(textSearch) ? s.active : ''
                )}
                icon={<IcSearch />}
                h="40px"
                aria-label={''}
              ></IconButton>
            </PopoverTrigger>
          </Flex>
          <PopoverContent>
            <PopoverBody>
              <InputGroup className={s.inputContainer}>
                <InputLeftElement pointerEvents="none">
                  {searching ? (
                    <Spinner color={colors.hLabelColor} size={'md'} />
                  ) : (
                    <IcSearch />
                  )}
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  value={textSearch}
                  onChange={e => setTextSearch(e.target.value)}
                  enterKeyHint="search"
                  onKeyDownCapture={onEnter}
                  lang="en"
                />
                <InputRightElement
                  pointerEvents={'auto'}
                  onClick={() => {
                    onSearch('');
                    onClose();
                  }}
                >
                  <IoIosCloseCircle
                    fontSize={'16px'}
                    color={colors.hLabelColor}
                  />
                </InputRightElement>
              </InputGroup>
            </PopoverBody>
          </PopoverContent>
        </Flex>
      </PopoverAnchor>
    </Popover>
  );
};

export default ProTradeKeyHeader;
