import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import s from './styles.module.scss';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import { formatTCAddress } from '@/utils/format';
import moment from 'moment';
import cs from 'classnames';
import {
  getMessageObject,
  getStickerIndex,
  tryParseStringObj,
} from '@/modules/AlphaPWA/DirectMessage/helpers';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { poolProfilePersistor } from '@/utils/persistor';
import {
  BuyMessageContent,
  DMMessage,
  DMRoom,
} from '@/modules/AlphaPWA/DirectMessage/types';
import {
  ChatGroupMember,
  MessageGameBullBearAction,
  MessageGameType,
  MessageTypeEnum,
} from '@/interfaces/api/chat';
import {
  CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS,
  ASYNC_LOAD_TIMEOUT,
} from '@/modules/AlphaPWA/DirectMessage/constants';
import { HoldingUserTokens } from '@/interfaces/api/verifyFriendTech';
import { compareString } from '@/utils';
import FriendTechIcon from '@/modules/AlphaPWA/DirectMessage/svg/FriendTechIcon';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { IcOwner } from '@/modules/AlphaPWA/Profiles/TradeKey/components/svg/ic-owner';
import ChatImageBrowserIcon from '@/modules/AlphaPWA/DirectMessage/svg/ChatImageBrowserIcon';
import { EMOJI_STICKERS } from '@/modules/AlphaPWA/Home/PostItem/Emoji';
import { Flex } from '@chakra-ui/react';
import { WalletContext } from '@/contexts/wallet-context';
import PinChat from '../../MenuActionChat/PinChat';
import { IUserJoinBullBear } from '@/services/interfaces/bullBear';
import CBullBearAPI from '@/services/classes/bullBear';

const AVATAR_WIDTH = 50;
export const ITEM_HEIGHT = 88;

const CheckedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49999 15.0003C7.49916 15.0003 7.49833 15.0003 7.49666 15.0003C7.27416 14.9995 7.06249 14.9104 6.90665 14.752L3.57333 11.367C3.24999 11.0387 3.25416 10.5112 3.5825 10.1887C3.91083 9.86619 4.43749 9.86951 4.76082 10.1978L7.50499 12.9845L15.245 5.24535C15.5708 4.91952 16.0975 4.91952 16.4233 5.24535C16.7492 5.57035 16.7492 6.09869 16.4233 6.42369L8.08999 14.757C7.93332 14.9129 7.72083 15.0003 7.49999 15.0003Z"
        fill="#F8F0AC"
      />
    </svg>
  );
};

const Item = ({
  room,
  holdingTokenUser,
  order,
  onClickItem,
}: {
  room: DMRoom;
  holdingTokenUser?: HoldingUserTokens | null;
  order: number;
  onClickItem?: (id: string) => void;
}) => {
  const apiBullBear = useRef(new CBullBearAPI()).current;

  const router = useRouter();
  const { roomMessages, getUserProfile, updateUserProfile } = useDM();
  const [userPlayer, setUserPlayer] = useState<IGetPlayerPoolProfile | null>(
    getUserProfile(room.owner)
  );
  const { addressL2 } = useContext(WalletContext);

  const targetRef = useRef<HTMLDivElement>(null);

  const isLoadingRef = useRef(false);
  const getUserTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [joinUser, setJoinUser] = useState<IUserJoinBullBear>();

  const isLoadingLastMessageRef = useRef(false);
  const getUserLastMessageTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const lastMessage = useMemo(() => {
    const messages = roomMessages[room.id];
    return messages?.length
      ? messages[messages.length - 1]
      : room.lastMessage || null;
  }, [roomMessages, room.id]);

  const [lastMessagePlayer, setLastMessagePlayer] =
    useState<IGetPlayerPoolProfile | null>(getUserProfile(lastMessage?.from));

  const isMine = useMemo(() => {
    return addressL2?.toLocaleLowerCase() === room?.owner.toLocaleLowerCase();
  }, [addressL2, room]);

  const isHaveNewMessage = useMemo(() => {
    if (lastMessage && room) {
      if (lastMessage.createdAt && room.lastSeen) {
        return moment(lastMessage.createdAt).isAfter(room.lastSeen);
      } else if (!room.lastSeen) {
        return true;
      }
    }
    return false;
  }, [room, lastMessage]);

  useEffect(() => {
    if (!userPlayer && !isLoadingRef.current) {
      if (getUserTimeoutRef.current) {
        clearTimeout(getUserTimeoutRef.current);
      }
      getUserTimeoutRef.current = setTimeout(() => {
        isLoadingRef.current = true;
        poolProfilePersistor
          .getItem(room.owner)
          .then(storedItem => {
            setUserPlayer(storedItem);
            if (storedItem) {
              updateUserProfile(room.owner, storedItem);
            }
          })
          .finally(() => {
            isLoadingRef.current = false;
          });
      }, ASYNC_LOAD_TIMEOUT);
    }

    return () => {
      if (getUserTimeoutRef.current) {
        clearTimeout(getUserTimeoutRef.current);
      }
    };
  }, [userPlayer]);

  useEffect(() => {
    if (!isLoadingLastMessageRef.current && lastMessage) {
      if (getUserLastMessageTimeoutRef.current) {
        clearTimeout(getUserLastMessageTimeoutRef.current);
      }
      getUserLastMessageTimeoutRef.current = setTimeout(() => {
        isLoadingLastMessageRef.current = true;
        poolProfilePersistor
          .getItem(lastMessage.from)
          .then(storedItem => {
            setLastMessagePlayer(storedItem);
            if (storedItem) {
              updateUserProfile(lastMessage.from, storedItem);
            }
          })
          .finally(() => {
            isLoadingLastMessageRef.current = false;
          });
      }, ASYNC_LOAD_TIMEOUT);
    }

    return () => {
      if (getUserTimeoutRef.current) {
        clearTimeout(getUserTimeoutRef.current);
      }
    };
  }, [lastMessage]);

  useEffect(() => {
    getJoinBullBear();
  }, [lastMessage?.content]);

  const getJoinBullBear = async () => {
    if (!lastMessage?.type) {
      return;
    }
    try {
      const parseContent = JSON.parse(lastMessage?.content);

      if (
        lastMessage.type === MessageTypeEnum.BULL_BEAR_JOIN_EVENT ||
        (lastMessage.type === MessageTypeEnum.GAME_EVENT &&
          parseContent?.action === MessageGameBullBearAction.JOIN)
      ) {
        const eventId = parseContent?.game_id || parseContent?.bullBearEventId;
        const joinId =
          parseContent?.extra_data?.join_id || parseContent?.bullBearJoinId;
        const rs = await apiBullBear.getJoinBullAndBear(eventId, joinId);
        setJoinUser(rs);
      }
    } catch (error) {}
  };

  const onItemClick = () => {
    const url = `${ROUTE_PATH.ALPHA_MOBILE_DM}?id=${room?.id}&key=${
      isFriendTech ? 'ft' : 'btc'
    }`;
    if (onClickItem) {
      onClickItem(room?.id);
    } else {
      router.push(url);
    }
  };

  const getUserName = (address: string) => {
    if (userPlayer) {
      return (
        userPlayer.twitterName ||
        userPlayer.bns ||
        formatTCAddress(userPlayer.address)
      );
    }
    return formatTCAddress(address);
  };

  const getLastMessageUserName = (lastMessage: DMMessage) => {
    if (lastMessagePlayer) {
      return (
        lastMessagePlayer.twitterName ||
        lastMessagePlayer.bns ||
        formatTCAddress(lastMessagePlayer.address)
      );
    }
    return formatTCAddress(lastMessage.from);
  };

  const renderCircleName = (address: string) => {
    const userName = getUserName(address);
    // if (`${userName}`.endsWith('s')) {
    //   return `${userName}' Circle`;
    // }
    // return `${userName}'s Circle`;
    return `${userName} Circle`;
  };

  const localizationMessage = () => {
    try {
      if (room && lastMessage) {
        const translatedMessage =
          lastMessage[CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS[room.language]];
        if (translatedMessage) {
          return translatedMessage;
        }
        return lastMessage.content;
      }
      return '';
    } catch (e) {
      return '';
    }
  };

  const displayNormalMessage = (lastMessage: DMMessage) => {
    try {
      const content = localizationMessage();
      const messageText = getMessageObject(content).plain;

      const isSticker = getMessageObject(content).type === 'STICKER';

      if (isSticker) {
        const stickerText = getStickerIndex(messageText);
        return (
          <>
            <span className={s.sticker}>
              {EMOJI_STICKERS[Number(stickerText)].icon()}
            </span>
          </>
        );
      }

      if (messageText) {
        return (
          <>
            <span
              className={s.innerText}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(messageText, {
                  allowedAttributes: {
                    span: ['class'],
                  },
                }),
              }}
            ></span>
          </>
        );
      }

      if (lastMessage.mediaUrls.length) {
        return (
          <>
            <ChatImageBrowserIcon size={16} />
          </>
        );
      }

      return '';
    } catch (e) {
      return '';
    }
  };

  const displaySpecialMessage = (lastMessage: DMMessage) => {
    const content = localizationMessage();
    const userInfo = tryParseStringObj<ChatGroupMember>(content);
    if (typeof userInfo === 'string') {
      // return <>&#9617;&#9617;&#9617;&#9617;&#9617;&#9617;&#9617;&#9617;</>;
      return '';
    }
    if (lastMessage.type === MessageTypeEnum.ADD_MEMBER) {
      return `${userInfo.twitterName} has joined`;
    }

    if (lastMessage.type === MessageTypeEnum.REMOVE_MEMBER) {
      //return `${userInfo.twitterName} has left`;
      return ``;
    }

    if (lastMessage.type === MessageTypeEnum.CREATE_DONATION) {
      return `${lastMessagePlayer?.twitterName} gifted some red packets to the circle`;
    }

    if (lastMessage.type === MessageTypeEnum.CLAIM_DONATION) {
      return `${lastMessagePlayer?.twitterName} snatched a red packet in the circle`;
    }

    if (lastMessage.type === MessageTypeEnum.WHEEL_EVENT) {
      return `${lastMessagePlayer?.twitterName} created a new Lucky Wheel`;
    }

    if (lastMessage.type === MessageTypeEnum.RAFFLE_EVENT) {
      return `${lastMessagePlayer?.twitterName} created a new raffle`;
    }

    if (lastMessage.type === MessageTypeEnum.BUY_EVENT) {
      const buyInfo = tryParseStringObj<BuyMessageContent>(
        content
      ) as BuyMessageContent;
      if (buyInfo) {
        return `${buyInfo?.fromInfo?.twitterName} just snagged ${
          buyInfo?.buyAmount
        } ${buyInfo?.toInfo?.twitterName} ${
          buyInfo?.buyAmount > 1 ? 'keys' : 'key'
        }.`;
      }
      return '';
    }

    if (lastMessage.type === MessageTypeEnum.BULL_BEAR_EVENT) {
      return `${lastMessagePlayer?.twitterName} created a new Mr. Market`;
    }

    if (lastMessage.type === MessageTypeEnum.BULL_BEAR_JOIN_EVENT) {
      return `${joinUser?.user?.twitter_name} joined ${
        joinUser?.type === 'bull' ? 'bullish' : 'bearish'
      } a Mr. Market`;
    }

    if (lastMessage.type === MessageTypeEnum.GAME_EVENT) {
      try {
        const parseContent = JSON.parse(lastMessage?.content);

        if (parseContent?.game_type === MessageGameType.BULL_BEAR) {
          if (parseContent?.action === MessageGameBullBearAction.END) {
            return `Mr. Market has ended`;
          } else if (
            parseContent?.action === MessageGameBullBearAction.CREATE
          ) {
            return `${lastMessagePlayer?.twitterName} created a new Mr. Market`;
          } else if (parseContent?.action === MessageGameBullBearAction.JOIN) {
            return `${joinUser?.user?.twitter_name} joined ${
              joinUser?.type === 'bull' ? 'bullish' : 'bearish'
            } a Mr. Market`;
          }
        }
      } catch (error) {
        return '';
      }
    }

    return '';
  };

  const isFriendTech = useMemo(() => {
    const fts = holdingTokenUser?.ft || [];
    const nbcs = holdingTokenUser?.nbc || [];
    const isNbcer = nbcs.find(nbc =>
      compareString(nbc.twitterUser?.address, room.owner)
    );
    if (isNbcer) {
      return false;
    }
    return fts.find(ft => compareString(ft.twitterUser?.address, room.owner));
  }, [holdingTokenUser, room.owner]);

  const isCallStarted = false; //useMemo(() => room.isCalling, [room]);

  const renderUsers = () => {
    return (
      <>
        <div className={s.pin}>{!isMine && <PinChat room={room} />}</div>
        <div
          className={s.avatars}
          style={{
            width: `${AVATAR_WIDTH}px`,
            minWidth: `${AVATAR_WIDTH}px`,
          }}
        >
          <div className={s.avatar}>
            <Avatar
              width={AVATAR_WIDTH}
              // url={userPlayer && userPlayer.twitterAvatar}
              url={getUrlAvatarTwitter(
                (userPlayer && userPlayer.twitterAvatar) as string
              )}
            />
          </div>

          <span className={s.avatars_badge}>
            {isFriendTech ? <FriendTechIcon /> : <IcOwner />}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      className={s.wrapper}
      onClick={onItemClick}
      style={{
        height: ITEM_HEIGHT,
        top: `${order * ITEM_HEIGHT}px`,
      }}
      ref={targetRef}
    >
      <div className={s.item}>
        <div className={s.info}>
          {renderUsers()}
          <div className={s.chat}>
            <div className={s.user}>
              <span
                className={cs(
                  s.user_name,
                  isHaveNewMessage ? s.user_name_newMessage : ''
                )}
              >
                {renderCircleName(room.owner)}
              </span>
              {lastMessage && lastMessage.createdAt && (
                <time className={s.user_time}>
                  {moment(lastMessage.createdAt).fromNow().replace(' ago', '')}
                </time>
              )}
            </div>
            <div className={s.message}>
              <span
                className={cs(
                  s.message__text,
                  isHaveNewMessage ? s.message__text__newMessage : ''
                )}
              >
                {!!lastMessage && (
                  <>
                    {lastMessage.type === MessageTypeEnum.NORMAL ? (
                      <Flex alignItems={'center'} gap={'4px'}>
                        {getLastMessageUserName(lastMessage)}:{' '}
                        {displayNormalMessage(lastMessage)}
                      </Flex>
                    ) : (
                      <>{displaySpecialMessage(lastMessage)}</>
                    )}
                  </>
                )}
              </span>
              <span className={s.message__badge}>
                {isCallStarted && (
                  <div className={s.call_ic}>
                    {/* <SvgInset
                      size={12}
                      svgUrl={`${CDN_URL_ICONS}/ic-group-call.svg`}
                    /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.375 9.35596V10.5C6.375 10.707 6.207 10.875 6 10.875C5.793 10.875 5.625 10.707 5.625 10.5V9.35596C3.6635 9.16696 2.125 7.51 2.125 5.5C2.125 5.293 2.293 5.125 2.5 5.125C2.707 5.125 2.875 5.293 2.875 5.5C2.875 7.223 4.277 8.625 6 8.625C7.723 8.625 9.125 7.223 9.125 5.5C9.125 5.293 9.293 5.125 9.5 5.125C9.707 5.125 9.875 5.293 9.875 5.5C9.875 7.51 8.3365 9.16646 6.375 9.35596ZM6 1.25C4.7595 1.25 3.75 2.2595 3.75 3.5V5.5C3.75 6.7405 4.7595 7.75 6 7.75C7.2405 7.75 8.25 6.7405 8.25 5.5V3.5C8.25 2.2595 7.2405 1.25 6 1.25Z"
                        fill="url(#paint0_linear_18336_5907)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_18336_5907"
                          x1="2.125"
                          y1="6.0625"
                          x2="9.875"
                          y2="6.0625"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#00F5A0" />
                          <stop offset="1" stop-color="#00D9F5" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                )}
                {!!room.unreadCount ? (
                  <span className={s.message__badge__unreadCount}>
                    {room.unreadCount < 10 ? room.unreadCount : '9+'}
                  </span>
                ) : (
                  <CheckedIcon />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Item);
