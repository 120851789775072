import React from 'react';

const ReactionGreen = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_460_73)">
        <mask
          id="mask0_460_73"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <path d="M16 0H0V16H16V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_460_73)">
          <path
            d="M15.6501 10.3302C15.1884 11.8429 14.2886 13.1849 13.0645 14.1864C11.8404 15.188 10.3468 15.8042 8.77262 15.9573C7.1984 16.1103 5.61414 15.7933 4.22003 15.0462C2.82593 14.2992 1.68454 13.1557 0.940104 11.7602C0.178147 10.3683 -0.149265 8.77995 0.000104405 7.20017C0.117097 6.01837 0.495733 4.87745 1.10853 3.86019C1.72133 2.84294 2.55293 1.97488 3.54296 1.31901C4.53299 0.663136 5.65662 0.235909 6.83231 0.0683304C8.008 -0.0992478 9.20625 -0.00297242 10.3401 0.350169C11.3456 0.651847 12.2807 1.15166 13.0901 1.82017C13.909 2.48389 14.5887 3.30292 15.0901 4.23017C15.5857 5.16147 15.8948 6.1805 16.0001 7.23017C16.0839 8.2764 15.9651 9.32896 15.6501 10.3302Z"
            fill="#7144FC"
          />
          <path
            d="M12.0528 7.74907C12.0528 7.74907 13.0088 8.28018 12.9876 8.94938C12.9663 9.61858 13.6886 11.0738 11.1181 12.2529C9.27241 12.9598 7.25176 13.0713 5.33957 12.5716C5.18024 12.5716 1.41998 11.3925 1.48371 9.49111C1.50233 7.73817 2.19012 6.05861 3.40633 4.79609C3.78176 3.93882 4.38869 3.20315 5.15899 2.67165C5.66595 2.45176 6.22137 2.36747 6.77074 2.42706C7.32011 2.48665 7.84453 2.68808 8.29255 3.01156C8.29255 3.01156 9.71592 2.1193 10.417 2.35299C11.1181 2.58667 11.7873 3.41521 11.7448 3.78698C11.7448 3.78698 13.2637 4.60489 13.4018 4.99792C13.5715 5.36125 13.598 5.7752 13.4759 6.15717C13.3538 6.53914 13.0922 6.86104 12.7433 7.05862L12.0528 7.74907Z"
            fill="#609647"
          />
          <path
            d="M3.40625 5.87943C3.40625 5.79445 4.06483 0.950725 7.86758 2.74588C8.11774 2.84873 8.33399 3.01975 8.49174 3.23946C8.64948 3.45918 8.74237 3.71876 8.75984 3.98868"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M9.70552 6.33609C9.78614 6.10452 9.84655 5.8664 9.8861 5.6244C9.90471 5.55842 9.90471 5.48857 9.8861 5.42258C8.90712 5.19124 7.87017 5.18047 6.96762 5.62468C6.51595 5.73522 6.05958 5.91241 5.59473 5.90058C6.07841 6.30447 6.65883 6.5756 7.27898 6.68733C7.89914 6.79907 8.53769 6.74758 9.13192 6.53791L9.70552 6.33609Z"
            fill="#FF7781"
          />
          <path
            d="M13.2214 5.43275C12.4559 5.22814 11.65 5.22814 10.8845 5.43275C10.0878 5.81515 9.88601 5.76204 9.88601 5.76204L9.7373 6.34626V6.45249C10.3029 6.67073 10.905 6.7789 11.5112 6.77115C12.0541 6.82414 12.6006 6.71706 13.0833 6.46311C13.0833 6.46311 13.3489 6.22942 13.2214 5.43275Z"
            fill="#FF7781"
          />
          <path
            d="M9.12114 6.58018C9.12114 6.58018 9.26985 5.81538 8.85558 5.61355C8.46544 5.50879 8.0541 5.51246 7.66589 5.62418C7.45067 5.693 7.26958 5.84093 7.15917 6.03808C7.04876 6.23523 7.01727 6.46693 7.07105 6.6864C7.74171 6.9225 8.47846 6.88433 9.12114 6.58018Z"
            fill="#080808"
          />
          <path
            d="M12.3163 6.73047C12.3572 6.57075 12.3865 6.18882 12.3377 6.03132C12.289 5.87382 12.1975 5.7329 12.0736 5.62421C11.723 5.34803 10.9264 5.62421 10.9264 5.62421C10.8308 5.65953 10.7433 5.71358 10.6689 5.78319C10.5945 5.85281 10.5348 5.93658 10.4932 6.02959C10.4517 6.1226 10.4291 6.22298 10.4269 6.32483C10.4246 6.42668 10.4558 6.58494 10.4933 6.67969C11.1211 6.80078 12.0117 6.83203 12.3163 6.73047Z"
            fill="#080808"
          />
          <path
            d="M8.52637 2.97928C8.52637 2.97928 10.279 1.68337 11.3837 2.97928C11.5819 3.27108 11.7192 3.59984 11.7874 3.9459"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M12.5947 9.28853C12.5947 9.28853 10.5446 10.0958 6.72058 9.08671L5.33969 8.62996C5.33969 8.62996 4.27747 8.47062 4.64925 9.25667C4.98363 9.97585 5.52043 10.5819 6.19395 11.0008C6.86746 11.4196 7.64847 11.633 8.44137 11.6148C11.2669 11.6148 12.0848 11.3705 12.3291 11.02C12.6336 10.5897 12.8468 10.1017 12.9558 9.58596C12.9558 9.28853 12.5947 9.28853 12.5947 9.28853Z"
            fill="#A86A42"
          />
          <path
            d="M5.33887 4.11558C5.33887 4.11558 6.88971 2.2142 9.31157 4.56171C9.52282 4.84665 9.67409 5.17147 9.75625 5.51654C9.83841 5.8616 9.84974 6.21974 9.78957 6.56931C9.51339 7.61028 6.77287 7.96081 5.6894 6.68615"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M8.76031 3.85008C10.1088 3.72304 11.468 3.92346 12.7224 4.4343C13.0227 4.60778 13.2567 4.8763 13.3875 5.1975C13.5183 5.5187 13.5384 5.8743 13.4447 6.20821C13.4078 6.34637 13.3386 6.47379 13.2429 6.57999C12.8785 6.93361 12.48 7.25029 12.0532 7.52536L12.4356 8.02461C12.7027 8.37725 12.8812 8.7889 12.9561 9.22492C13.037 9.54025 13.0397 9.87059 12.9638 10.1872C12.8879 10.5038 12.7357 10.797 12.5206 11.0413C12.0682 11.6966 11.4045 12.1766 10.6404 12.401C9.57761 12.7415 8.46238 12.889 7.34756 12.8365C5.22311 12.8365 2.20641 11.6362 1.63281 10.011C1.47842 9.34804 1.46579 8.65999 1.59576 7.99185C1.72572 7.32371 1.99535 6.69056 2.38699 6.13385C2.68524 5.68447 3.04251 5.27718 3.44921 4.92292"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M9.68359 7.05811C9.9831 7.36902 10.3702 7.58131 10.7934 7.6667C11.2166 7.7521 11.6557 7.70654 12.0523 7.53611"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M12.233 11.4348C10.7613 11.8441 9.21251 11.8915 7.71856 11.5729C4.66999 10.9356 4.36195 9.37413 4.79746 8.65182C5.05239 8.21631 5.85968 8.48187 6.56074 8.87489C7.03404 9.13608 7.54183 9.32919 8.0691 9.44849C11.5107 10.1389 12.7535 9.20418 12.7535 9.20418"
            stroke="black"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.15 10.8598L12.91 11.6998C12.9475 11.7116 12.9819 11.7313 13.0109 11.7577C13.04 11.7841 13.0629 11.8166 13.0781 11.8527C13.0934 11.8889 13.1005 11.928 13.0991 11.9672C13.0977 12.0064 13.0878 12.0449 13.07 12.0798L12.32 13.4598L8 10.8398C8 10.8398 8.76 10.8898 9.27 10.8898L10.15 10.8598Z"
            fill="#F9F9F9"
          />
          <path
            d="M5.95557 9.5332C6.84531 10.2913 7.97333 10.7125 9.14223 10.7229C10.3635 10.7626 11.5859 10.6987 12.7963 10.5317"
            stroke="black"
            stroke-width="0.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.2028 12.8081C13.3952 12.3259 13.3391 11.8504 13.0775 11.746C12.8159 11.6417 12.4478 11.948 12.2555 12.4301C12.0631 12.9123 12.1192 13.3878 12.3808 13.4922C12.6424 13.5966 13.0105 13.2903 13.2028 12.8081Z"
            fill="#DD7D54"
          />
          <path
            opacity="0.6"
            d="M13.0714 12.0808C13.0714 12.0808 14.1314 11.3875 14.0714 10.7475C13.9814 9.67746 13.2191 9.3554 13.6891 8.0754C14.1591 6.7954 16.069 7.46919 14.6914 4.8833C15.1981 5.46601 15.532 6.13374 15.4414 6.7833C15.4414 7.6233 13.7514 7.9633 14.0714 8.8633C14.3914 9.7633 14.7914 10.31 14.6914 11.09C14.5214 12.43 12.833 13.1633 12.833 13.1633C12.9768 12.7316 13.0224 12.5332 13.0714 12.0808Z"
            fill="#F9F9F9"
          />
          <path
            d="M9.68398 5.46497C9.19366 5.21173 8.63851 5.1118 8.09065 5.17817C7.36998 5.28079 6.6593 5.44425 5.96621 5.66679C5.79171 5.70915 5.6096 5.70915 5.4351 5.66679H5.22266"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M9.9917 5.52812C9.9917 5.52812 10.8733 5.40065 11.7019 5.30505C12.2908 5.26752 12.882 5.32126 13.4545 5.46438"
            stroke="black"
            stroke-width="0.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M8.62188 6.65489C8.77441 6.65489 8.89806 6.53125 8.89806 6.37872C8.89806 6.22619 8.77441 6.10254 8.62188 6.10254C8.46935 6.10254 8.3457 6.22619 8.3457 6.37872C8.3457 6.53125 8.46935 6.65489 8.62188 6.65489Z"
            fill="white"
          />
          <path
            d="M11.9363 6.52745C12.0889 6.52745 12.2125 6.4038 12.2125 6.25128C12.2125 6.09875 12.0889 5.9751 11.9363 5.9751C11.7838 5.9751 11.6602 6.09875 11.6602 6.25128C11.6602 6.4038 11.7838 6.52745 11.9363 6.52745Z"
            fill="white"
          />
          <path
            d="M8.0267 6.27167C8.1086 6.27167 8.17499 6.20528 8.17499 6.12338C8.17499 6.04149 8.1086 5.9751 8.0267 5.9751C7.94481 5.9751 7.87842 6.04149 7.87842 6.12338C7.87842 6.20528 7.94481 6.27167 8.0267 6.27167Z"
            fill="white"
          />
          <path
            d="M11.2022 6.23884C11.2958 6.23884 11.3717 6.16297 11.3717 6.06937C11.3717 5.97578 11.2958 5.8999 11.2022 5.8999C11.1086 5.8999 11.0327 5.97578 11.0327 6.06937C11.0327 6.16297 11.1086 6.23884 11.2022 6.23884Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_460_73">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReactionGreen;
