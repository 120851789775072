const IconStaking = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM7.25 6C7.94 6 8.5 6.56 8.5 7.25C8.5 7.94 7.94 8.5 7.25 8.5C6.56 8.5 6 7.94 6 7.25C6 6.56 6.56 6 7.25 6ZM12.75 14C12.06 14 11.5 13.44 11.5 12.75C11.5 12.06 12.06 11.5 12.75 11.5C13.44 11.5 14 12.06 14 12.75C14 13.44 13.44 14 12.75 14ZM14.53 6.53003L6.53003 14.53C6.38403 14.676 6.192 14.75 6 14.75C5.808 14.75 5.61597 14.677 5.46997 14.53C5.17697 14.237 5.17697 13.762 5.46997 13.469L13.47 5.46899C13.763 5.17599 14.238 5.17599 14.531 5.46899C14.824 5.76199 14.823 6.23703 14.53 6.53003Z"
        fill="#95A4FC"
      />
    </svg>
  );
};

export default IconStaking;
