export function IcQuestion() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99996 18.3334C5.39746 18.3334 1.66663 14.6026 1.66663 10.0001C1.66663 5.39758 5.39746 1.66675 9.99996 1.66675C14.6025 1.66675 18.3333 5.39758 18.3333 10.0001C18.3333 14.6026 14.6025 18.3334 9.99996 18.3334ZM9.16663 12.5001V14.1667H10.8333V12.5001H9.16663ZM10.8333 11.1292C11.503 10.9274 12.078 10.4917 12.4534 9.90153C12.8289 9.31136 12.9799 8.60596 12.8789 7.9138C12.7779 7.22165 12.4317 6.58878 11.9033 6.13046C11.3749 5.67214 10.6994 5.41886 9.99996 5.41675C9.32567 5.4167 8.67218 5.65021 8.15062 6.07758C7.62906 6.50494 7.27163 7.09977 7.13913 7.76091L8.77413 8.08841C8.82053 7.85627 8.93189 7.64208 9.09526 7.47076C9.25864 7.29944 9.4673 7.17803 9.69698 7.12067C9.92666 7.0633 10.1679 7.07234 10.3926 7.14672C10.6174 7.22111 10.8164 7.35778 10.9665 7.54084C11.1166 7.7239 11.2116 7.94582 11.2405 8.18078C11.2694 8.41574 11.231 8.65408 11.1298 8.86807C11.0285 9.08205 10.8686 9.26288 10.6686 9.38952C10.4686 9.51616 10.2367 9.5834 9.99996 9.58341C9.77895 9.58341 9.56698 9.67121 9.4107 9.82749C9.25442 9.98377 9.16663 10.1957 9.16663 10.4167V11.6667H10.8333V11.1292Z"
          fill="#5B5B5B"
        />
      </svg>
    </>
  );
}
