import React from 'react';

const ReactionYellow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_440_230)">
        <path
          d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1876 13.065 14.1902C11.8407 15.1927 10.3466 15.8095 8.77168 15.9625C7.1968 16.1155 5.61192 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.93838 11.7598C0.194739 10.3629 -0.11794 8.77668 0.0399006 7.20196C0.197741 5.62724 0.819008 4.13472 1.8251 2.91323C2.8312 1.69174 4.17692 0.796164 5.692 0.339808C7.20708 -0.116546 8.82344 -0.113175 10.3366 0.349495C11.3414 0.65488 12.276 1.15549 13.0871 1.82267C13.8982 2.48984 14.5696 3.31048 15.0632 4.2376C15.5567 5.16472 15.8626 6.18008 15.9632 7.2256C16.0639 8.27108 15.9574 9.32616 15.6498 10.3304Z"
          fill="url(#paint0_radial_440_230)"
        />
        <path
          d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1876 13.065 14.1902C11.8407 15.1927 10.3466 15.8095 8.77168 15.9625C7.1968 16.1155 5.61192 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.93838 11.7598C0.194739 10.3629 -0.11794 8.77668 0.0399006 7.20196C0.197741 5.62724 0.819008 4.13472 1.8251 2.91323C2.8312 1.69174 4.17692 0.796164 5.692 0.339808C7.20708 -0.116546 8.82344 -0.113175 10.3366 0.349495C11.3414 0.65488 12.276 1.15549 13.0871 1.82267C13.8982 2.48984 14.5696 3.31048 15.0632 4.2376C15.5567 5.16472 15.8626 6.18008 15.9632 7.2256C16.0639 8.27108 15.9574 9.32616 15.6498 10.3304Z"
          fill="#FF7A00"
        />
        <path
          d="M12.203 7.88034C12.1999 7.88743 12.1968 7.8945 12.1937 7.90156C12.0714 8.1779 11.6804 8.06818 11.7045 7.76393C11.7485 7.20785 11.6838 6.63306 11.4952 6.07336C10.8831 4.25661 8.828 2.18799 7.00814 2.16555C6.8138 2.16315 6.70222 2.39954 6.81691 2.56623C6.96983 2.78844 7.09401 3.03894 7.18193 3.31371C7.47052 4.21566 7.29287 5.17593 6.78471 5.88937C6.57287 6.1868 6.12306 6.03991 6.11566 5.667C6.11559 5.66431 6.11555 5.6616 6.11551 5.65889C6.11253 5.4612 6.14305 5.2714 6.20161 5.09561C6.22744 5.0181 6.1598 4.94182 6.08571 4.96554C5.05402 5.29594 4.26952 6.90899 4.26952 8.05955C4.26952 8.14358 4.27392 8.22651 4.28209 8.30824C4.2831 8.46105 4.3074 8.61649 4.35822 8.76935C4.36004 8.77484 4.36189 8.78031 4.36373 8.7858C4.37956 8.83277 4.39638 8.87909 4.41367 8.92503C4.16933 8.60767 3.96147 8.25733 3.79708 7.88119C3.72069 7.70642 3.47893 7.74111 3.44924 7.93121C3.39634 8.26975 3.36865 8.59994 3.36865 8.91384C3.36865 11.6314 5.4421 13.8345 7.99983 13.8345C10.5576 13.8345 12.631 11.6314 12.631 8.91384C12.631 8.59992 12.6033 8.2697 12.5504 7.93112C12.5208 7.74148 12.2792 7.70595 12.203 7.88034Z"
          fill="#FFE600"
        />
        <path
          d="M10.3465 9.75304C10.3447 9.75701 10.343 9.76095 10.3412 9.76489C10.273 9.91914 10.0548 9.8579 10.0682 9.68806C10.0928 9.37765 10.0566 9.05682 9.95136 8.74438C9.60968 7.73026 8.4625 6.57553 7.44664 6.563C7.33814 6.56165 7.27587 6.69361 7.3399 6.78667C7.42525 6.91072 7.49458 7.05054 7.54366 7.20392C7.70475 7.7074 7.60558 8.24344 7.32192 8.64169C7.20367 8.80772 6.95259 8.72572 6.94845 8.51755C6.94843 8.51604 6.94839 8.51454 6.94837 8.51301C6.9467 8.40266 6.96375 8.2967 6.99644 8.19859C7.01085 8.15533 6.9731 8.11273 6.93174 8.12598C6.35585 8.31042 5.91794 9.21082 5.91794 9.85307C5.91794 9.89997 5.92041 9.94626 5.92495 9.99189C5.92551 10.0772 5.93909 10.1639 5.96745 10.2493C5.96848 10.2523 5.96949 10.2554 5.97052 10.2585C5.97935 10.2847 5.98875 10.3105 5.99838 10.3362C5.86198 10.159 5.74597 9.96345 5.65419 9.7535C5.61154 9.65593 5.4766 9.67532 5.46002 9.78142C5.43048 9.9704 5.41504 10.1547 5.41504 10.3299C5.41504 11.8469 6.57245 13.0767 8.0002 13.0767C9.42795 13.0767 10.5854 11.8469 10.5854 10.3299C10.5854 10.1547 10.5699 9.97035 10.5404 9.78137C10.5238 9.67553 10.389 9.6557 10.3465 9.75304Z"
          fill="white"
        />
        <path
          d="M3.86376 6.25235C4.20261 6.32619 4.47263 5.95593 4.33736 5.61767C4.23627 5.36487 4.25214 5.06481 4.40463 4.81794C4.40489 4.81753 4.40515 4.81712 4.40538 4.81671C4.44393 4.75453 4.41315 4.67084 4.34479 4.65448C4.02891 4.57888 3.68811 4.7128 3.49995 5.0174C3.25747 5.40995 3.36041 5.93704 3.72987 6.19469C3.77125 6.2235 3.8164 6.24203 3.86376 6.25235Z"
          fill="#FFE600"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_440_230"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.99996 7.99712) scale(7.99644 7.99808)"
        >
          <stop stop-color="#FFF2E1" />
          <stop offset="0.65" stop-color="#FFAE4B" />
          <stop offset="1" stop-color="#FF8C00" />
        </radialGradient>
        <clipPath id="clip0_440_230">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReactionYellow;
