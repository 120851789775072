import { Box, Button, Checkbox, Flex, Td, Text, Tr } from '@chakra-ui/react';
import styles from './styles.module.scss';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getLink, getUrlAvatarTwitter } from '@/utils/helpers';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
} from '@/utils';
import clsx from 'classnames';
import KeyPresaleIcon from '@/modules/AlphaPWA/Notification/svg/KeyPresaleIcon';
import KeyIcon from '@/modules/AlphaPWA/Profiles/BuyAKey/KeyIcon';
import px2rem from '@/utils/px2rem';
import { IKeysCommon } from '@/modules/AlphaPWA/Keys/pro/key';
import { ITradeKey } from '@/services/interfaces/trade';
import {
  labelAmountOrNumberAdds,
  SLIDE_ALPHA,
  SLIDE_FT,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { IBodyBuySweetFloorKeys } from '@/contracts/interfaces/tradeKey';
import { clone } from 'lodash';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import React, { useContext, useMemo } from 'react';
import { ROUTE_PATH } from '@/constants/route-path';
import { BsClock } from 'react-icons/bs';
import moment from 'moment';
import { useRouter } from 'next/router';
import { postMentionPlaceholder } from '@/services/player-share';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { WalletContext } from '@/contexts/wallet-context';
import IcTwitterX from '@/modules/AlphaPWA/Keys/pro/key/img/icTwitterX';
import { PLACE_HOLDER_SORTS } from '@/modules/AlphaPWA/Profiles/TradeKey/pro/itemFilterSort';
import { openExternalLink } from '@/utils/client';

interface IProKeyItem extends IKeysCommon {
  userKey: ITradeKey;
  hasSortNew?: boolean;
  hasSortPortfolio?: boolean;
  hasPlaceholderShowTop: string;
  setSelectKeys?: any;
  refParams: any;
  isPlaceHolder?: boolean;
  referralCode?: string;
}

const ItemRow: React.FC<IProKeyItem> = ({
  userKey,
  selectKeys,
  hasSortNew,
  setSelectKeys,
  hasPlaceholderShowTop,
  hasSortPortfolio,
  isPlaceHolder,
  referralCode,
}) => {
  const { isUserFT } = useTradeKey();
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const gaEventTracker = useAnalyticsEventTracker();

  const isChecked =
    selectKeys.findIndex(v =>
      compareString(v.token_address, userKey.address)
    ) >= 0;

  const canCheck = useMemo(() => {
    return (
      (isUserFT && compareString(userKey.side, SLIDE_FT)) ||
      compareString(userKey.side, SLIDE_ALPHA) ||
      compareString(userKey?.base_token_symbol, 'BTC')
    );
  }, [isUserFT, userKey]);

  const onCheckBox = () => {
    if (!canCheck) {
      return;
    }

    const data = userKey;

    if (!isUserFT && compareString(data.side, SLIDE_FT)) {
      return;
    }

    const _selectKeys: IBodyBuySweetFloorKeys[] = clone(selectKeys);
    const _findIndex = _selectKeys.findIndex(v =>
      compareString(v.token_address, data.address)
    );
    if (_findIndex >= 0) {
      _selectKeys.splice(_findIndex, 1);
    } else {
      _selectKeys.push({
        token_address: data.address as string,
        token_amount: 1,
        side: data.side,
        key: data,
        amount_after_fee: 0,
      });
    }
    setSelectKeys(_selectKeys);
    // refFooter.current?.setNumSweep(_selectKeys.length);
  };

  const handleGoToProfile = async () => {
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${userKey.owner}`);
  };

  const renderDesc = () => {
    if (hasSortNew) {
      return (
        <>
          <BsClock fontSize={'10px'} opacity={'70%'} />
          <Text fontSize={`${px2rem(14)} !important`} opacity={'70%'}>
            {`${moment(userKey?.created_at).fromNow()}`}
          </Text>
        </>
      );
    }

    if (!compareString(hasPlaceholderShowTop, '0')) {
      return (
        <Flex alignItems={'center'} gap={2} fontSize={`${px2rem(14)}`}>
          <Text fontSize={`${px2rem(14)} !important`} opacity={'70%'}>
            {abbreviateNumber(userKey.twitter_followers_count)} follower
            {labelAmountOrNumberAdds(userKey.twitter_followers_count as number)}
          </Text>
          ·
          <Text fontSize={`${px2rem(14)} !important`} opacity={'70%'}>
            #{userKey.friend_tech_index} on{' '}
            {compareString(hasPlaceholderShowTop, PLACE_HOLDER_SORTS[2].key)
              ? 'FT'
              : compareString(hasPlaceholderShowTop, PLACE_HOLDER_SORTS[3].key)
              ? 'SA'
              : ''}
          </Text>
        </Flex>
      );
    }

    if (isPlaceHolder) {
      return (
        <Text fontSize={`${px2rem(14)} !important`} opacity={'70%'}>
          {abbreviateNumber(userKey.twitter_followers_count)} follower
          {labelAmountOrNumberAdds(userKey.twitter_followers_count as number)}
        </Text>
      );
    }
    if (hasSortPortfolio) {
      return (
        <Text color={'#000000'} fontSize={px2rem(14)} opacity={'70%'}>
          Portfolio{' '}
          {formatCurrency(
            userKey?.portfolio,
            0,
            2,
            userKey.base_token_symbol
          )}{' '}
          {userKey.base_token_symbol}
        </Text>
      );
    }

    return (
      <Text color={'#000000'} fontSize={px2rem(14)} opacity={'70%'}>
        Vol{' '}
        {formatCurrency(userKey?.total_volume, 0, 2, userKey.base_token_symbol)}{' '}
        {userKey.base_token_symbol}
      </Text>
    );
  };

  return (
    <Tr
      className={clsx(styles.itemRow, isChecked ? styles.isItemSelected : '')}
    >
      <Td>
        <Flex
          gap={3}
          alignItems={'center'}
          width={'100%'}
          id={`user_${userKey.user_twitter_id}`}
        >
          {canCheck && (
            <Box className={styles.checkboxContainer}>
              <Checkbox onChange={() => onCheckBox()} isChecked={isChecked} />
            </Box>
          )}
          <Flex
            width={'100%'}
            flex={1}
            gap={2}
            alignItems={'center'}
            onClick={() => onCheckBox()}
          >
            <Avatar
              url={getUrlAvatarTwitter(
                (userKey?.user_twitter_avatar || userKey?.avatar) as string,
                'normal',
                Boolean(userKey?.user_twitter_avatar)
              )}
              address={userKey?.owner}
              width={32}
            />
            <Flex width={'100%'} gap={2} direction={'column'}>
              <Flex
                // onClick={e => {
                //   e.preventDefault();
                //   e.stopPropagation();
                //   handleGoToProfile();
                // }}
                gap={2}
                alignItems={'center'}
                maxWidth={'100%'}
                width={'90%'}
                flex={1}
              >
                <a href={`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${userKey.owner}`}>
                  <Text className={styles.buyer}>
                    {formatName(userKey?.user_twitter_name as string, 50)}
                  </Text>
                </a>
                <Flex
                  className={clsx(
                    styles.keyContainer,
                    userKey.is_pre_sale && styles.keyContainerPresale
                  )}
                >
                  {userKey.is_pre_sale ? <KeyPresaleIcon /> : <KeyIcon />}
                </Flex>
              </Flex>
              <Flex alignItems={'center'} gap={1}>
                {renderDesc()}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex
          onClick={e => onCheckBox()}
          gap={2}
          direction={'column'}
          alignItems={'flex-end'}
        >
          <Text className={styles.buyer}>
            {formatCurrency(userKey?.price, 0, 2, userKey.base_token_symbol)}{' '}
            {userKey.base_token_symbol}
          </Text>
          <Text
            color={'#000000'}
            fontSize={`${px2rem(14)} !important`}
            opacity={'70%'}
          >
            ${formatCurrency(userKey?.usd_price, 2, 2)}
          </Text>
        </Flex>
      </Td>
      <Td>
        {isPlaceHolder ? (
          <Flex justifyContent={'flex-end'}>
            <Button
              className={styles.btnSendInvitePlaceHolder}
              onClick={() => {
                if (addressL2 && userKey.user_twitter_id) {
                  postMentionPlaceholder(addressL2, userKey.user_twitter_id);
                }
                const content = `GM @${
                  userKey.user_twitter_username
                }.\n\nYou're pretty popular on @NewBitcoinCity, a decentralized social network on #Bitcoin\n\nPeople have been trading your placeholder keys there at the price of ${formatCurrency(
                  userKey?.price,
                  0,
                  6,
                  userKey?.base_token_symbol
                )} ${userKey?.base_token_symbol}.\n\nJoin @NewBitcoinCity to claim your free key and start earning.\n\n`;
                const shareUrl = getLink(referralCode || '');

                gaEventTracker(
                  GameFiActions.TweetTabPlaceHolderReferralCode,
                  addressL2
                );
                window.open(
                  `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
                    content
                  )}`,
                  '_blank'
                );
              }}
            >
              <Flex
                alignItems={'center'}
                gap={2}
                justifyContent={'space-between'}
              >
                Invite
                <Flex alignItems={'center'}>
                  <IcTwitterX />
                  {formatCurrency(userKey?.num_mention, 0)}
                </Flex>
              </Flex>
            </Button>
          </Flex>
        ) : (
          <Flex
            onClick={e => onCheckBox()}
            gap={2}
            direction={'column'}
            alignItems={'flex-end'}
          >
            <Text
              className={styles.buyer}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                const twitter_username = userKey.user_twitter_username;
                openExternalLink(
                  `https://twitter.com/${twitter_username}`,
                  twitter_username || '',
                  twitter_username || ''
                );
              }}
            >
              {abbreviateNumber(userKey.twitter_followers_count)}
            </Text>
            <Text color={'#000000'} fontSize={px2rem(14)} opacity={'0%'}>
              a
            </Text>
          </Flex>
        )}
      </Td>
    </Tr>
  );
};

export default ItemRow;
