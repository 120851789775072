import React from 'react';
import s from './ReplyButton.module.scss';
import { Flex } from '@chakra-ui/react';
import { IcReply } from '@/modules/AlphaPWA/Home/PostItem/Icon/ic-reply';

type Props = {
  replyCount?: number | string;
  handleClickReply: () => void;
  classNames?: string;
  hideNumber?: boolean;
};

const ReplyButton = ({
  handleClickReply,
  replyCount,
  classNames,
  hideNumber,
}: Props) => {
  return (
    <Flex
      className={`${classNames} ${s.reply}`}
      alignItems={'center'}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handleClickReply();
      }}
      gap="5px"
    >
      <IcReply />
      {!hideNumber && <span className={'action_text'}>{replyCount}</span>}
    </Flex>
  );
};

export default ReplyButton;
