import { PropsWithChildren, useEffect, useState, useRef } from 'react';
import s from './styles.module.scss';
import useClickOutside from '@/hooks/useHoverOutside';

interface ITaptip extends PropsWithChildren {
  label: string;
}

export function Taptip({ label, children }: ITaptip) {
  const [show, setShow] = useState<boolean>(false);
  const refContent = useRef<HTMLDivElement | null>(null);

  useClickOutside(refContent, () => setShow(false));

  useEffect(() => {
    const onPopup = () => setShow(true);
    refContent.current?.addEventListener('click', onPopup);
    return () => {
      refContent.current?.removeEventListener('click', onPopup);
    };
  }, []);

  return (
    <div ref={refContent} className={s.taptip}>
      {children}
      {show && <div className={s.taptip_popup}>{label}</div>}
    </div>
  );
}
