import React from 'react';

const IcCheck = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#F8F0AC" />
      <path
        d="M14.5156 7.04685C14.6254 7.15671 14.687 7.30564 14.687 7.46091C14.687 7.61618 14.6254 7.76511 14.5156 7.87497L8.85158 13.539C8.74171 13.6488 8.59279 13.7104 8.43751 13.7104C8.28224 13.7104 8.13331 13.6488 8.02345 13.539L5.48439 11C5.38094 10.8888 5.32461 10.7419 5.32724 10.5901C5.32986 10.4383 5.39123 10.2934 5.49845 10.1859C5.60595 10.0787 5.75084 10.0173 5.90265 10.0147C6.05446 10.0121 6.20137 10.0684 6.31251 10.1718L8.43751 12.2968L13.6875 7.04685C13.7974 6.93712 13.9463 6.87549 14.1016 6.87549C14.2569 6.87549 14.4058 6.93712 14.5156 7.04685Z"
        fill="black"
        stroke="black"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default IcCheck;
