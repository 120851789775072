import React, { useContext, useEffect, useState } from 'react';
import s from './RepostButton.module.scss';
import RepostSvg from '../Icon/RepostSvg';
import { reTweet } from '@/services/alpha_tweet';
import RepostActiveSvg from '../Icon/RepostActiveSvg';
import cn from 'classnames';
import { PostItemContext } from '@/contexts/post-item';
import { IcRepost } from '@/modules/AlphaPWA/Home/PostItem/Icon/ic-repost';

interface props {
  onFetchData: () => void;
  postId: number;
  userAddress: string;
  isPublic: boolean;
  classNames?: string;
  hideNumber?: boolean;
  dataRepost: {
    isRepost: boolean;
    userRepost: string;
    count: number;
    isRepostByMe: boolean;
  } | null;
}

const RepostButton = React.memo((props: props) => {
  const {
    postId,
    userAddress,
    isPublic,
    dataRepost,
    onFetchData,
    classNames,
    hideNumber,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const [resultRepost, setResultRepost] = useState<any>(null);
  const [countRepost, setCountRepost] = useState(
    dataRepost && dataRepost?.count > 0 ? dataRepost?.count : 0
  );

  const { setIsReposted } = useContext(PostItemContext);

  const resetForError = () => {
    setIsActive(false);
    setCountRepost(count => count - 1);
  };

  const onRepost = async () => {
    if (dataRepost?.isRepostByMe || isActive) return;
    setIsActive(true);
    setCountRepost(count => count + 1);
    const res = await reTweet({
      postId,
      address: userAddress,
      public: isPublic,
    });
    if (!res) {
      resetForError();
      return;
    }
    setResultRepost(res);
    setIsReposted(true);

    // onFetchData();
    try {
    } catch (error) {
      console.log('error repost: ', error);
      resetForError();
    } finally {
    }
  };

  useEffect(() => {
    // if (!dataRepost) {
    //   setIsActive(false);
    // } else {
    //   if (dataRepost.userRepost === userAddress) {
    //     setIsActive(true);
    //   } else {
    //     setIsActive(false);
    //   }
    // }
    // if(dataRepost?.isRepostByMe)

    if (resultRepost) return;
    if (dataRepost) {
      setIsActive(dataRepost?.isRepostByMe);
    }
  }, [dataRepost, resultRepost]);

  return (
    <div
      className={cn(s.wrapButton, isActive && s.active, classNames)}
      onClick={e => {
        e.stopPropagation();
        onRepost();
      }}
    >
      <IcRepost />
      {!hideNumber && <span className={'action_text'}>{countRepost}</span>}
    </div>
  );
});

export default RepostButton;
