import { DEFAULT_LIGHTGRAY_AVATAR } from '@/constants/common';
import { detectClientOs } from '@/utils/client';
import { ClientOs } from '@/enums/client';

export const onImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
  const imageElement = e.target as HTMLImageElement;
  const retryCount = parseInt(
    imageElement.getAttribute('data-retry-count') || '0',
    10
  );

  if (retryCount < 3) {
    // Increment the retry count and attempt to reload the image
    imageElement.setAttribute('data-retry-count', (retryCount + 1).toString());
    imageElement.src = imageElement.src; // This will trigger a reload of the image
  } else {
    // Set the source to the default image after 3 retries
    imageElement.src = DEFAULT_LIGHTGRAY_AVATAR;
  }
};

export const linkifyText = (text: string): string => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text into parts based on URL matches
  const parts = text.split(urlRegex);

  // Wrap URLs with anchor tags and concatenate into a string
  const resultString = parts
    .map((part, index) => {
      if (part.match(urlRegex)) {
        return `<a key=${index} href=${part} target="_blank" rel="noopener noreferrer">${part}</a>`;
      }
      return part;
    })
    .join('');

  return resultString;
};

export const disableElasticScrollingSafari = ({
  isRemoveStyle,
}: {
  isRemoveStyle: boolean;
}) => {
  const clientOs = detectClientOs();
  const htmlEl = document.querySelector('html');
  const bodyEl = document.querySelector('body');
  if (clientOs === ClientOs.Ios && htmlEl && bodyEl) {
    if (isRemoveStyle === false) {
      htmlEl.style.cssText = `
      height: 100%;
      width: 100%;
      overflow: hidden;
    `;
      bodyEl.style.cssText = `
      height: 100%;
      width: 100%;
      overflow: hidden;
    `;
    } else {
      htmlEl.style.cssText = '';
      bodyEl.style.cssText = '';
    }
  }
};
