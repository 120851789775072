import { WalletContext } from '@/contexts/wallet-context';
import { getPinnedPosts, getPostDetail } from '@/services/alpha_tweet';
import { Flex, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import PostItem from '../Home/PostItem/PostItemV2';
import s from './TweetDetail.module.scss';
import { goToRoute } from '@/utils/go-route';
import { ROUTE_PATH } from '@/constants/route-path';
import BackLightSvg from './icon/BackLightSvg';
import { AssetsContext } from '@/contexts/assets-context';
import AppLoading from '@/components/AppLoading';
import cs from 'classnames';

interface Props {
  id?: number;
  onClickBack?: () => void;
  isProfile?: boolean;
}
const TweetDetails = (props: Props) => {
  const router = useRouter();
  const { id: __id, onClickBack, isProfile } = props;
  const { id: _id } = router.query as {
    id: string;
    ownerAddress: string;
    fromDetail: string;
    fromUrl: string;
  };
  const id = __id || _id;

  const { addressL2 } = useContext(WalletContext);
  const [postDetail, setPostDetail] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [pinnedPost, setPinnedPost] = useState<any>();
  const { playerPoolProfile, refreshTokenTwitter } = useContext(AssetsContext);

  const fetchPinnedPosts = useCallback(async () => {
    if (!playerPoolProfile?.twitterId || !addressL2) return;
    try {
      await refreshTokenTwitter();
      const res = await getPinnedPosts({
        twitterId: playerPoolProfile?.twitterId,
        network: 'nos',
        address: addressL2,
        activity: postDetail.activity === '' ? 'social' : postDetail.activity,
      });
      setPinnedPost(res);
    } catch (err: unknown) {
      console.log(err);
    }
  }, [playerPoolProfile, addressL2]);

  useEffect(() => {
    fetchPinnedPosts();
  }, [playerPoolProfile, addressL2]);

  const fetchPostDetail = async () => {
    if (!addressL2) return;
    try {
      const res: any = await getPostDetail(Number(id), addressL2);
      if (res) {
        setPostDetail(res);
      }
    } catch (error: any) {
      console.log('fetchPostDetail ~ error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostDetail();
  }, [addressL2, id]);

  return (
    <div className={cs(s.wrapper, { [`${s.wrapper_profile}`]: isProfile })}>
      <div className={cs(s.header, { [`${s.header_profile}`]: isProfile })}>
        <button
          className={s.backBtn}
          onClick={() => {
            if (onClickBack) {
              onClickBack();
            } else {
              if (window.history.length > 1) {
                router.back();
              } else {
                goToRoute(router, `${ROUTE_PATH.ALPHA_MOBILE_HOME}`);
              }
            }
          }}
        >
          <BackLightSvg />
          <span>Feed</span>
        </button>
      </div>
      <div className={s.content}>
        {loading ? (
          <Flex mt="12px" alignItems={'center'} justifyContent={'center'}>
            <AppLoading />
          </Flex>
        ) : (
          postDetail && (
            <div className={s.postItem}>
              <PostItem
                data={
                  !postDetail.is_repost ? postDetail : postDetail.repost_tweet
                }
                dataParent={postDetail}
                fetchPinnedPosts={fetchPinnedPosts}
                isPinned={pinnedPost?.id === postDetail.id}
                isDetail
                isProfile={isProfile}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TweetDetails;
