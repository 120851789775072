/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from '@/state/hooks';
import {
  resetUser,
  updateEVMWallet,
  updateSelectedWallet,
  updateTaprootWallet,
} from '@/state/user/reducer';
import { getConnection } from '@/connection';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@/state/user/selector';
import bitcoinStorage from '@/utils/bitcoin-storage';
import { clearAuthStorage, setWalletChainId } from '@/utils/auth-storage';
import { isSupportedChain, switchChain } from '@/utils';
import { ETH_CHAIN_ID, SupportedChainId } from '@/constants/chains';
import useAsyncEffect from 'use-async-effect';
import { useRouter } from 'next/router';
// import * as TC_SDK from 'trustless-computer-sdk';
import { isProduction } from '@/utils/commons';
import { toast } from 'react-hot-toast';
import SDKError, { ERROR_CODE, getErrorMessage } from '@/utils/error';
import * as CryptoJS from 'crypto-js';
import accountStorage from '@/utils/account.storage';
import { ethers, Wallet } from 'ethers';
import debounce from 'lodash/debounce';
import { openModal } from '@/state/modal';
import AccountErrorModal, {
  ACCOUNT_ERROR_KEY,
} from '@/components/AccountError/modal';
import {
  getJackpotLeaderboardPlayer,
  getUserGameInfo,
  submitReferral,
} from '@/services/gamefi';
import { isAddress } from 'web3-utils';
import { decryptRSA } from '@/utils/encryption';
import GetTCModal from '@/modules/TopupPage/GetTCModal';
import { useRef } from 'react';
import { IUserGameInfo } from '@/interfaces/api/gamefi';
import BigNumber from 'bignumber.js';
import { WalletManager } from '@/services/wallet';
import { isMobile } from '@/utils/animation';
import { openMetamaskDeeplink, sendMetamaskDeeplink } from '@/utils/metamask';
import { WalletError } from '@/enums/wallet-error';
import { METAMASK_DOWNLOAD_PAGE } from '@/constants/common';
import { IWalletState } from '@/interfaces/wallet';
import { generateMessage } from '@/services/signature';
import axios from 'axios';
import SyncProfileModal, {
  SYNC_PROFILE_MODAL,
} from '@/layouts/AlphaLayout/SyncProfileModal';

const INIT_KEY_SET: IKeySetL2 = {
  prvKey: undefined,
  address: undefined,
  password: undefined,
  isNeedCreate: false,
};

const INIT_WALLET_STATE_L2 = {
  isLogged: false,
  isNeedCreate: false,
  isNeedLogin: false,
};

interface IKeySetL2 {
  address: string | undefined;
  prvKey: string | undefined;
  password: string | undefined;
  isNeedCreate: boolean;
}

export type FundAndWithdrawTab = 'fund' | 'withdraw';

export interface IWalletContext {
  keySetL2: IKeySetL2;
  addressL2: string | undefined;
  walletStateL2: {
    isLogged: boolean;
    isNeedCreate: boolean;
    isNeedLogin: boolean;
  };
  onPreloadL2: () => void;
  onDisconnect: (chainID: SupportedChainId) => Promise<void>;
  onConnect: (chainID?: number) => Promise<string | null>;
  requestBtcAddress: () => Promise<void>;
  onLoginL2: (password: string, isSavePass?: boolean) => void;
  onRandomAccountL2: ({
    password,
    inviter,
  }: {
    password: string;
    inviter?: string;
  }) => Promise<void>;
  handleShowGetTcModal: (
    amount?: number,
    address?: string,
    title?: string
  ) => void;
  userGameInfo: IUserGameInfo | undefined;
  handleGetUserGameInfo: (
    address: string,
    calback?: (data: IUserGameInfo) => void
  ) => void;
  transfer: (addr: string, val: string) => Promise<string | null>;
  checkAndSwitchChain: ({ chainID }: IWalletState) => any;
  fundAndWithdrawTab: FundAndWithdrawTab;
  setFundAndWithdrawTab: (tab: FundAndWithdrawTab) => void;
  isSameWallet?: boolean;
  message: string;
  signature: string;
  setSignature: (signature: string) => void;
  eth2Usd: BigNumber | undefined;
  btc2Usd: BigNumber | undefined;
  gameWallet: Wallet | undefined;
  isAuthenticated: boolean;
  onSyncProfile: () => void;
  handleGetBTC2USD: () => Promise<any>;
}

const initialValue: IWalletContext = {
  keySetL2: {
    ...INIT_KEY_SET,
  },
  addressL2: undefined,
  walletStateL2: {
    ...INIT_WALLET_STATE_L2,
  },
  onLoginL2: () => undefined,
  onPreloadL2: () => undefined,
  onRandomAccountL2: () => new Promise<void>(r => r()),
  onDisconnect: () => new Promise<void>(r => r()),
  onConnect: () => new Promise<null>(r => r(null)),
  requestBtcAddress: () => new Promise<void>(r => r()),
  handleShowGetTcModal: () => new Promise<void>(r => r()),
  userGameInfo: undefined,
  handleGetUserGameInfo: () => new Promise<void>(r => r()),
  transfer: (_address: string, _val: string) =>
    new Promise<string | null>(r => r(null)),
  checkAndSwitchChain: () => new Promise<string | null>(r => r(null)),
  fundAndWithdrawTab: 'fund',
  setFundAndWithdrawTab: () => new Promise<void>(r => r()),
  isSameWallet: false,
  message: '',
  signature: '',
  setSignature: () => undefined,
  eth2Usd: undefined,
  btc2Usd: undefined,
  gameWallet: undefined,
  isAuthenticated: false,
  onSyncProfile: () => new Promise<void>(r => r()),
  handleGetBTC2USD: () => new Promise<null>(r => r(null)),
};

export const WalletContext = React.createContext<IWalletContext>(initialValue);

export const WalletProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const { connector, provider, chainId, account } = useWeb3React();
  const dispatch = useAppDispatch();
  const user = useSelector(getUserSelector);
  const router = useRouter();
  const [walletManager, setWalletManager] = useState<WalletManager | null>(
    null
  );

  const walletManagerRef = useRef<WalletManager | null>(walletManager);

  const [addressL2, setAddressL2] = React.useState<string | undefined>(
    undefined
  );
  const [keySetL2, setKeySetL2] = React.useState<IKeySetL2>({
    ...INIT_KEY_SET,
  });

  const [userGameInfo, setUserGameInfo] = useState<IUserGameInfo | undefined>();
  const [gameWallet, setGameWallet] = useState<Wallet | undefined>();

  const [fundAndWithdrawTab, setFundAndWithdrawTab] =
    useState<FundAndWithdrawTab>('fund');

  const [message, setMessage] = useState('');
  const [signature, setSignature] = useState('');

  const [eth2Usd, setEth2Usd] = useState<BigNumber>(new BigNumber('1650'));
  const [btc2Usd, setBtc2Usd] = useState(new BigNumber('28000'));

  const address = accountStorage.getAddress();
  const isAuthenticated = !!address && !!accountStorage.isUserLinkingTwitter;

  const onSyncProfile = () => {
    dispatch(
      openModal({
        id: SYNC_PROFILE_MODAL,
        theme: 'dark',
        title: '',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => <SyncProfileModal />,
      })
    );
  };

  const fetchMessageToSign = async () => {
    try {
      if (!addressL2) return;
      const res = await generateMessage({ wallet_address: addressL2 });
      if (res) {
        setMessage(res);
      }
    } catch (error: unknown) {
      console.log('🚀 ~ fetchMessageToSign ~ error:', error);
      // throw Error("Fail to generate message")
    }
  };

  useEffect(() => {
    if (addressL2) fetchMessageToSign();
  }, [addressL2]);

  useEffect(() => {
    const walletManagerInstance = new WalletManager();
    walletManagerRef.current = walletManagerInstance;
    setWalletManager(walletManagerInstance);

    handleGetBTC2USD();
    handleGetEth2USD();
  }, []);

  useEffect(() => {
    addressL2 && handleGetUserGameInfo(addressL2);
  }, [addressL2]);

  const walletStateL2 = React.useMemo(() => {
    const isLogged = keySetL2.prvKey && keySetL2.address;
    const isNeedCreate = !isLogged && keySetL2.isNeedCreate;
    const isNeedLogin = !isLogged && !isNeedCreate;
    return {
      isLogged: !!isLogged,
      isNeedCreate,
      isNeedLogin,
    };
  }, [keySetL2]);

  const isSameWallet = React.useMemo(() => {
    return !!(
      addressL2 &&
      account &&
      addressL2.toLowerCase() === account.toLowerCase()
    );
  }, [addressL2, account]);

  const onRemoveStorageL1 = () => {
    bitcoinStorage.removeUserTaprootAddress(
      user?.walletAddress || account || ''
    );
    bitcoinStorage.removeCurrentTCL1Connected();
    dispatch(resetUser());
  };

  const handleGetEth2USD = async () => {
    try {
      const { data: eth2Usd } = await axios.get(
        `https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT`
      );
      const eth2UsdInBn = new BigNumber(eth2Usd.price);
      setEth2Usd(eth2UsdInBn);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetBTC2USD = async () => {
    try {
      const { data: eth2Usd } = await axios.get(
        `https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`
      );
      const btc2UsdInBn = new BigNumber(eth2Usd.price);
      setBtc2Usd(btc2UsdInBn);
      return btc2UsdInBn;
      // setEth2Usd(eth2UsdInBn);
    } catch (error) {
      console.log(error);
    }
  };

  const disconnect = React.useCallback(
    async (chainID: SupportedChainId) => {
      if (
        (user?.walletAddress || account) &&
        chainID === SupportedChainId.TRUSTLESS_COMPUTER
      ) {
        onRemoveStorageL1();
      }
      if (connector && connector.deactivate) {
        await connector.deactivate();
      }
      await connector.resetState();
      clearAuthStorage();
    },
    [connector, dispatch, user?.walletAddress, account]
  ) as any;

  const connect = React.useCallback(
    async (chainID?: number) => {
      const wallet = walletManagerRef.current;

      if (wallet && !wallet.isInstalled()) {
        window.open(METAMASK_DOWNLOAD_PAGE);
        throw Error(WalletError.NO_METAMASK);
      }

      const connection = getConnection(connector);
      if (!connection) {
        throw new Error('Get connection error.');
      }
      await connection.connector.activate();
      if (!!chainID) {
        await switchChain(chainID);
      }
      const addresses = await connector.provider?.request({
        method: 'eth_accounts',
      });

      if (addresses && Array.isArray(addresses)) {
        const evmWalletAddress = addresses[0];

        if (
          chainID === SupportedChainId.TRUSTLESS_COMPUTER_LAYER_2 ||
          chainID === SupportedChainId.BASE_CHAIN_ID
        ) {
          setWalletChainId(chainId);
          dispatch(updateEVMWallet(evmWalletAddress));
          dispatch(updateSelectedWallet({ wallet: connection.type }));
          return evmWalletAddress;
        }
      }
      return null;
    },
    [dispatch, connector, provider, chainId]
  );

  const requestBtcAddress = async (): Promise<void> => {
    // await TC_SDK.actionRequest({
    //   method: TC_SDK.RequestMethod.account,
    //   redirectURL: window.location.origin + window.location.pathname,
    //   target: '_self',
    //   isMainnet: isProduction(),
    // });
  };

  const onLoginL2 = (password: string, isSavePass = false) => {
    try {
      const _password = password + '';
      const prvKey = accountStorage.getAccount(_password);
      const wallet = new Wallet(prvKey, window.provider);
      const address = wallet.address;
      accountStorage.setAddress({ address });
      const isSave = accountStorage.getIsStoragePass();
      if (isSave || isSavePass) {
        accountStorage.setPassWord({ password: _password });
        accountStorage.setIsStoragePass({ isSave: true });
      }
      setAddressL2(address);
      setKeySetL2({
        prvKey,
        address: wallet.address,
        password: _password,
        isNeedCreate: false,
      });
      setGameWallet(wallet);
      setMessage('');
      setSignature('');
    } catch (error) {
      const { desc } = getErrorMessage(error);
      toast.error(desc);
    }
  };

  const onRandomAccountL2 = async ({
    password,
    inviter = undefined,
  }: {
    password: string;
    inviter?: string;
  }) => {
    try {
      const id = CryptoJS.lib.WordArray.random(32);
      const prvKey = '0x' + id;
      const address = new ethers.Wallet(prvKey).address;
      if (!address) {
        throw new SDKError(ERROR_CODE.DECRYPT);
      }

      // if (inviter && isAddress(inviter)) {
      //   await submitReferral({ inviter, invitee: address });
      // }

      accountStorage.setAccount({
        prvKey: prvKey,
        password: password + '',
      });
      onLoginL2(password, true);
    } catch (error) {
      throw new Error(error as any);
    }
  };

  const tcWalletUpdater = React.useCallback(
    debounce(async () => {
      if (account) {
        dispatch(updateEVMWallet(account));
      }

      if (connector) {
        try {
          const connection = getConnection(connector);
          if (!connection) {
            throw new Error('Get connection error.');
          }

          const taprootAddress = bitcoinStorage.getUserTaprootAddress(
            account || ''
          );

          const currentAddressL1 =
            bitcoinStorage.getCurrentTCL1Connected() || '';

          if (
            !!account &&
            !!currentAddressL1 &&
            account.toLowerCase() !== (currentAddressL1 as string).toLowerCase()
          ) {
            onRemoveStorageL1();
            return dispatch(
              openModal({
                id: ACCOUNT_ERROR_KEY,
                theme: 'dark',
                title: 'Connection Error',
                modalProps: {
                  centered: true,
                  zIndex: 9999999,
                },
                render: () => <AccountErrorModal />,
              })
            );
          }

          if (account && taprootAddress) {
            dispatch(updateTaprootWallet(taprootAddress));
            dispatch(updateSelectedWallet({ wallet: 'METAMASK' }));
          } else {
            dispatch(updateTaprootWallet(''));
            dispatch(resetUser());
          }
        } catch (err: unknown) {
          clearAuthStorage();
          // console.log(err);
        }
      }
    }, 500),
    [account]
  );

  useAsyncEffect(tcWalletUpdater, [account]);

  useEffect(() => {
    const { tcAddress, tpAddress } = router.query as {
      tcAddress: string;
      tpAddress: string;
    };
    if (tpAddress && tcAddress) {
      dispatch(updateTaprootWallet(tpAddress));
      bitcoinStorage.setUserTaprootAddress(tcAddress, tpAddress);
      bitcoinStorage.setCurrentTCL1Connected(tcAddress);
      router.push(window.location.origin + window.location.pathname);
    }
  }, [router]);

  const onPreloadL2 = () => {
    const cipherText = accountStorage.getAccountCipher();
    const address = accountStorage.getAddress();
    const pass = accountStorage.getPassWord();

    if (address) {
      setAddressL2(address);
      setMessage('');
      setSignature('');
    }
    setKeySetL2(value => ({ ...value, isNeedCreate: !cipherText }));

    if (pass && cipherText) {
      onLoginL2(pass);
    }
  };

  React.useEffect(onPreloadL2, []);

  const [showTcModal, setShowTcMoal] = useState(false);
  const getTCParamsRef = useRef<any | undefined>();

  const handleHideGetTcModal = () => {
    getTCParamsRef.current = undefined;
    setShowTcMoal(false);
  };

  const handleShowGetTcModal = (
    amount?: number,
    address?: string,
    title?: string
  ) => {
    getTCParamsRef.current = { amount, address, title };
    setShowTcMoal(true);
  };

  const handleGetUserGameInfo = async (
    address: string,
    calback?: (data: IUserGameInfo) => void
  ) => {
    try {
      // const data = await getUserGameInfo(address);
      // setUserGameInfo(data);
      // calback && calback(data);
    } catch (error) {}
  };

  const isDeepLinkRequired = (): boolean => {
    const wallet = walletManagerRef.current;
    return isMobile() && !wallet?.isInstalled();
  };

  const checkAndSwitchChain = useCallback(
    async ({ chainID }: IWalletState): Promise<void> => {
      const wallet = walletManagerRef.current;

      if (!wallet) {
        throw Error(WalletError.NO_INSTANCE);
      }

      const isChainSupported = await wallet.isChainSupported(chainID);
      if (!isChainSupported) {
        const walletRes = await wallet.requestSwitchChain(chainID);
        if (walletRes.isError) {
          throw Error(walletRes.message);
        }
      }
    },
    []
  );

  const transfer = useCallback(
    async (toAddress: string, value: string): Promise<string | null> => {
      if (isDeepLinkRequired()) {
        sendMetamaskDeeplink(toAddress, value, ETH_CHAIN_ID);
        return '';
      }

      const wallet = walletManagerRef.current;

      if (!wallet) {
        throw Error(WalletError.NO_INSTANCE);
      }

      if (!wallet.isInstalled()) {
        window.open(METAMASK_DOWNLOAD_PAGE);
        throw Error(WalletError.NO_METAMASK);
      }

      const walletRes = await wallet.connect();
      if (!walletRes.isSuccess || !walletRes.data) {
        throw Error(walletRes.message);
      }

      try {
        await checkAndSwitchChain({
          chainID: ETH_CHAIN_ID,
        });
      } catch (err: unknown) {
        throw Error(WalletError.FAILED_SWITCH_CHAIN);
      }

      const walletAddress = walletRes.data;
      try {
        const transferRes = await wallet.transfer({
          fromAddress: walletAddress,
          toAddress,
          value,
        });
        if (!transferRes.data || !transferRes.isSuccess) {
          throw Error(transferRes.message);
        }
        return transferRes.data;
      } catch (err: any) {
        throw Error(
          err && err.message ? err.message : WalletError.FAILED_TRANSFER
        );
      }
    },
    []
  );

  const contextValues = useMemo((): IWalletContext => {
    return {
      onDisconnect: disconnect,
      onConnect: connect,
      requestBtcAddress,
      keySetL2,
      addressL2,
      walletStateL2,
      onLoginL2,
      onRandomAccountL2,
      onPreloadL2,
      handleShowGetTcModal,
      userGameInfo,
      handleGetUserGameInfo,
      transfer,
      fundAndWithdrawTab,
      setFundAndWithdrawTab,
      isSameWallet,
      message,
      signature,
      setSignature,
      eth2Usd,
      btc2Usd,
      gameWallet,
      isAuthenticated,
      onSyncProfile,
      checkAndSwitchChain,
      handleGetBTC2USD,
    };
  }, [
    disconnect,
    connect,
    requestBtcAddress,
    keySetL2,
    addressL2,
    walletStateL2,
    onLoginL2,
    onRandomAccountL2,
    onPreloadL2,
    handleShowGetTcModal,
    userGameInfo,
    handleGetUserGameInfo,
    transfer,
    fundAndWithdrawTab,
    setFundAndWithdrawTab,
    isSameWallet,
    message,
    signature,
    setSignature,
    eth2Usd,
    btc2Usd,
    gameWallet,
    isAuthenticated,
    onSyncProfile,
    checkAndSwitchChain,
    handleGetBTC2USD,
  ]);

  return (
    <WalletContext.Provider value={contextValues}>
      {children}
      <GetTCModal
        isShow={showTcModal}
        tcAddress={
          getTCParamsRef.current ? getTCParamsRef.current.address : undefined
        }
        tcAmount={
          getTCParamsRef.current ? getTCParamsRef.current.amount : undefined
        }
        title={
          getTCParamsRef.current ? getTCParamsRef.current.title : undefined
        }
        onCloseModal={handleHideGetTcModal}
      />
    </WalletContext.Provider>
  );
};
