import React, { memo, useContext, useMemo } from 'react';
import s from './styles.module.scss';
import cs from 'classnames';
import BtnShareTw from '@/components/RaffleNFT/RaffleNFTPost/Participants/btnShareTw';
import { User } from '@/interfaces/api/post';

interface IProps {
  users: Array<any>;
  joinWheelEvent: () => void;
  hasJoined: boolean;
  isOwnerWheel: boolean;
  wheelCreator?: User | null;
  tokenName: string;
}

const Participants: React.FC<IProps> = ({
  users,
  joinWheelEvent,
  hasJoined,
  isOwnerWheel,
  wheelCreator,
  tokenName,
}: IProps): React.ReactElement => {
  const joinWheelEvents = (event: any) => {
    event?.stopPropagation();
    joinWheelEvent();
  };

  const isHaveUserJoined = useMemo(() => {
    return users.length > 0;
  }, [users]);

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={cs(s.actionWrapper)}>
          {isOwnerWheel && (
            <h4 className={s.listHeading}>
              {users.length} New Bitcoiners are playing
            </h4>
          )}
          <div
            className={`${s.actionLists} ${isOwnerWheel ? s.isOwnerWheel : ''}`}
          >
            <BtnShareTw
              wheelCreator={wheelCreator}
              users={users}
              tokenName={tokenName}
            />
            {!isOwnerWheel && !hasJoined && (
              <button
                onClick={joinWheelEvents}
                className={cs(s.joinBtn, {
                  [s.fullWidth]: !isHaveUserJoined,
                })}
              >
                Tap to play
              </button>
            )}
            {!isOwnerWheel && hasJoined && (
              <button className={s.joinedBtn}>Joined</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Participants);
