import { TRANSACTION_FEE } from '@/constants/amount';
import { AssetsContext } from '@/contexts/assets-context';
import { CurrencyContext } from '@/contexts/currency-context';
import { WalletContext } from '@/contexts/wallet-context';
import { CSwap } from '@/contracts/swap';
import { getErrorMessage } from '@/utils/error';
import { formatEthPrice } from '@/utils/format';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import s from './styles.module.scss';
import throttle from 'lodash/throttle';
import cs from 'classnames';
import { CDN_URL } from '@/configs';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SwapTcToBTCModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const swapContract = new CSwap();

  const [loading, setLoading] = useState(false);
  const { addressL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);

  const { onSwapTC2BTC, swapTC2BTCAble } = useContext(CurrencyContext);

  const [btcReceving, setBtcReceving] = useState('');
  const [estimating, setEstimating] = React.useState(false);
  const [error, setError] = React.useState<string>('');

  const swapTcAmount = swapTC2BTCAble
    ? new BigNumber(balanceL2.amount).minus(
        TRANSACTION_FEE.TC_NEEDS_SWAP_BTC2TC
      )
    : undefined;

  const onEstimateBTCReceiveAmount = async () => {
    try {
      if (
        !swapTcAmount ||
        !swapTcAmount?.toNumber() ||
        !swapContract ||
        !addressL2
      ) {
        setError('Invalid amount');
        return;
      }
      setEstimating(true);

      const data = await swapContract.estimateSwapTC2BTC({
        humanAmount: swapTcAmount.dividedBy(1e18).toString(),
        receiver: addressL2,
      });
      if (data && data.length > 0) {
        setBtcReceving(formatEthPrice(data[0], '-', 4));
        setError('');
      }
    } catch (error) {
      const { message } = getErrorMessage(error);
      setError(message);
    }
    setEstimating(false);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!swapTcAmount || loading || !addressL2) return;

    setLoading(true);
    try {
      await onSwapTC2BTC({
        humanAmount: swapTcAmount.dividedBy(1e18).toString(),
        receiver: addressL2,
      });
      toast.success(
        'Transaction has been created. Please wait for few seconds.'
      );
      onClose();
    } catch (error) {
      const { desc } = getErrorMessage(error);
      toast.error(desc);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onEstimateBTCReceiveAmount();
  }, [addressL2, swapTcAmount?.toString()]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent p="24px 22px" minW={'90%'} textAlign={'center'}>
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            textAlign="center"
            fontSize="24px"
            fontWeight={'600'}
            mb="24px"
          >
            Convert TC to BTC
          </ModalHeader>
          <ModalBody>
            <div className={s.container}>
              {!!swapTcAmount && (
                <div className={cs(s.asset_row, s.horizontal_space)}>
                  <div className={s.asset_row_amount_box}>
                    <p className={s.asset_row_amount_title}>YOUR BALANCE</p>
                    <p className={s.asset_row_amount}>
                      {swapTcAmount.dividedBy(1e18).toFixed(4)} TC
                    </p>
                  </div>
                  <div className={s.asset_row_ic_arrow}>
                    <img
                      src={`${CDN_URL}/icons/ion_arrow-up-outline.svg`}
                      alt="chevron-right-ic"
                    />
                  </div>
                  <div className={cs(s.asset_row_amount_box)}>
                    <p className={s.asset_row_amount_title}>BTC RECEIVED</p>
                    <p className={s.asset_row_amount}>{btcReceving} BTC</p>
                  </div>
                </div>
              )}
              <p className={cs(s.note, s.horizontal_space)}>
                The default currency on NBC is BTC.{`\n`} Convert your TC to BTC
                now.
              </p>
              <button
                disabled={estimating || loading}
                className={s.submitBtn}
                onClick={throttle(handleSubmit, 300)}
                type="submit"
              >
                {loading ? 'Processing...' : 'Convert'}
              </button>
              {!!error && <p className={s.error}>{error}</p>}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SwapTcToBTCModal;
