import { WalletContext } from '@/contexts/wallet-context';
import {
  Carousel,
  CarouselContainer,
  CarouselSlot,
} from '@/modules/AlphaPWA/DirectMessage/List/Carousel';
import CirclesTab from '@/modules/AlphaPWA/DirectMessage/List/CirclesTabV2';
import DMTab from '@/modules/AlphaPWA/DirectMessage/List/DMTab';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import { ChatTab } from '@/modules/AlphaPWA/DirectMessage/types';
import CreatePost from '@/modules/AlphaPWA/Home/CreatePost';
import Posts from '@/modules/AlphaPWA/Home/Posts';
import cs from 'classnames';
import { useContext, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSwipeable } from 'react-swipeable';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonReducer, setShowKeyLeft } from '@/state/common';
import { compareString } from '@/utils';

enum TabIndex {
  Post = 0,
  Keys = 1,
}

export type SocialTab = 'POSTS' | ChatTab;

export default function ProKeySocial(props: any) {
  const showRightSide = useSelector(selectCommonReducer).showKeyRight;
  const { isAuthenticated } = useContext(WalletContext);
  const { getUnreadMessages, setRoomId } = useDM();
  const [tab, setTab] = useState<SocialTab>('CIRCLES');

  const postsRef = useRef<any>(null);
  const circleRef = useRef<any>(null);

  const [showCreatePost, setShowCreatePost] = useState(false);
  const [indexTab, setIndexTab] = useState<number>(TabIndex.Post);
  const dispatch = useDispatch();

  const showCreatePostModal = () => {
    setShowCreatePost(!showCreatePost);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setTab('DMS');
    },
    onSwipedRight: () => {
      setTab('CIRCLES');
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const pos = useMemo(() => {
    switch (tab) {
      case 'CIRCLES':
        return 0;
      case 'DMS':
        return 1;
      default:
        return 0;
    }
  }, [tab]);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div className={styles.tabList}>
          {/*<div
            className={cs(
              styles.tabListItem,
              tab === 'POSTS' ? styles.tabListItemActive : '',
              showRightSide === '1' ? styles.showRightSide : ''
            )}
            onClick={() => {
              setTab('POSTS');
              dispatch(setShowKeyLeft('1'));
            }}
          >
            FEED
          </div>*/}

          <div
            className={cs(
              styles.tabListItem,
              tab === 'CIRCLES' ? styles.tabListItemActive : ''
            )}
            onClick={() => {
              if (tab !== 'CIRCLES') {
                setRoomId(undefined);
                setTab('CIRCLES');
              }
            }}
          >
            Your Circles
            {tab !== 'CIRCLES' && !!getUnreadMessages('CHAT_GROUP') && (
              <span className={styles.message__badge} />
            )}
          </div>
          <div
            className={cs(
              styles.tabListItem,
              tab === 'DMS' ? styles.tabListItemActive : ''
            )}
            onClick={() => {
              if (tab !== 'DMS') {
                setRoomId(undefined);
                setTab('DMS');
              }
            }}
          >
            DM
            {tab !== 'DMS' && !!getUnreadMessages('CHAT_ROOM') && (
              <span className={styles.message__badge} />
            )}
          </div>
        </div>
        {/* <SearchInput /> */}
        <div className={styles.tabPanels} {...handlers}>
          <Carousel>
            <CarouselContainer pos={pos}>
              {/*<CarouselSlot>
                <div
                  className={cs(
                    styles.tabPanelsItem,
                    tab === 'POSTS' ? styles.tabPanelsItemActive : ''
                  )}
                >
                  <Posts ref={postsRef} />
                </div>
              </CarouselSlot>*/}
              <CarouselSlot>
                <div
                  className={cs(
                    styles.tabPanelsItem,
                    tab === 'CIRCLES' ? styles.tabPanelsItemActive : ''
                  )}
                >
                  <CirclesTab isActive={tab === 'CIRCLES'} ref={circleRef} />
                </div>
              </CarouselSlot>
              <CarouselSlot>
                <div
                  className={cs(
                    styles.tabPanelsItem,
                    tab === 'DMS' ? styles.tabPanelsItemActive : ''
                  )}
                >
                  <DMTab isActive={tab === 'DMS'} />
                </div>
              </CarouselSlot>
            </CarouselContainer>
          </Carousel>
        </div>
      </div>
      {/*{isAuthenticated &&
        tab === 'POSTS' &&
        compareString(showRightSide, '1') &&
        indexTab === TabIndex.Post && (
          <button className={styles.create_btn} onClick={showCreatePostModal}>
            +
          </button>
        )}
      {showCreatePost && (
        <div className={styles.createContent}>
          <CreatePost
            show={showCreatePost}
            onClose={() => setShowCreatePost(false)}
            onFetchData={() => postsRef?.current?.refresh()}
          />
        </div>
      )}*/}
    </div>
  );
}
