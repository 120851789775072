import React, { useContext, useEffect, useState } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import s from './styles.module.scss';
import { WalletContext } from '@/contexts/wallet-context';
import { getListPlayerPoolProfile } from '@/services/player-share';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import InvitedItem from './InvitedItem';
import { IReferentEarning, IReferralEarning } from '@/interfaces/api/referral';
import { compareString } from '@/utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  referents: { address: string }[];
  referentsInfo: IReferentEarning[];
  zIndex?: number;
};

type PlayerEarning = IReferentEarning & IGetPlayerPoolProfile;

const CHILD_PADDING = 12;
const CHILD_HEIGHT = 48 + CHILD_PADDING;
const MAX_DISPLAY_CHILD = 7;
const MIN_TABLE_HEIGHT = 300;
const GAP = 10;

const ReferentsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  referents = [],
  referentsInfo = [],
  zIndex = 3000,
}: Props): React.ReactElement => {
  const [loading, setLoading] = useState(true);
  const { addressL2 } = useContext(WalletContext);
  const [tableHeight, setTableHeight] = useState(MIN_TABLE_HEIGHT);
  const [players, setPlayers] = useState<Record<string, PlayerEarning>>({});

  useEffect(() => {
    const maxHeight =
      MAX_DISPLAY_CHILD * CHILD_HEIGHT + (MAX_DISPLAY_CHILD - 1) * CHILD_HEIGHT;
    let bodyHeight =
      referents.length * CHILD_HEIGHT + (referents.length - 1) * GAP;
    if (bodyHeight > maxHeight) {
      bodyHeight = maxHeight;
    } else if (bodyHeight < MIN_TABLE_HEIGHT) {
      bodyHeight = MIN_TABLE_HEIGHT;
    }
    setTableHeight(bodyHeight);
  }, [referents.length]);

  useEffect(() => {
    if (addressL2 && isOpen) {
      const addresses = referents.map(r => r.address);
      getListPlayerPoolProfile(addresses).then(players => {
        setPlayers(
          players.reduce(
            (acc, cv) => ({
              ...acc,
              [`${cv ? cv.address : ''}`.toLowerCase()]: cv,
            }),
            {}
          )
        );
        setLoading(false);
      });
    }
  }, [addressL2, isOpen, referents]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay zIndex={zIndex} />

      <ModalContent
        p="24px 22px"
        maxW={'500px'}
        maxH={'80%'}
        textAlign={'center'}
        zIndex={zIndex}
        style={{
          zIndex: zIndex,
        }}
        containerProps={{
          zIndex: zIndex,
        }}
      >
        <Flex justifyContent={'flex-end'}>
          <ModalCloseButton />
        </Flex>
        <ModalHeader
          textAlign="center"
          fontSize="24px"
          fontWeight={'600'}
          mb="24px"
        >
          Referral earnings
        </ModalHeader>
        <ModalBody overflow="hidden" zIndex={zIndex}>
          <div className={s.referents}>
            <div className={s.table_heading}>
              <div className={s.table_heading__col}>Invited friends</div>
              <div className={s.table_heading__col}>Earning</div>
            </div>
            <div
              className={s.table_body}
              style={{
                height: tableHeight,
              }}
            >
              {loading && (
                <div className={s.loading}>
                  <Spinner />
                </div>
              )}
              {referentsInfo.length > 0 &&
                referents.map(item => {
                  const earning =
                    referentsInfo.find(r =>
                      compareString(r.address, item.address)
                    ) || (item as any);
                  return (
                    <InvitedItem
                      key={item.address}
                      player={players[`${item.address}`.toLowerCase()]}
                      earning={earning}
                    />
                  );
                })}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReferentsModal;
