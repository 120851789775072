/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';

export interface IPostItemContext {
  isRelayShow: boolean;
  setIsRelayShow: (b: boolean) => void;
  isReposted: boolean;
  setIsReposted: (b: boolean) => void;
  tips: number;
  setTips: (n: number) => void;

  isShowMenuAction: boolean;
  setIsShowMenuAction: (b: boolean) => void;

  isParentRepost: boolean;
  setIsParentRepost: (b: boolean) => void;

  isShowReaction: boolean;
  setIsShowReaction: (b: boolean) => void;
  isCanRepost: (data: any, dataParent: any) => boolean;
}

const initialValue: IPostItemContext = {
  isRelayShow: false,
  setIsRelayShow: _b => null,
  isReposted: false,
  setIsReposted: _b => null,
  tips: 0,
  setTips: _n => null,

  isShowMenuAction: false,
  setIsShowMenuAction: _b => null,

  isParentRepost: false,
  setIsParentRepost: _b => null,

  isShowReaction: false,
  setIsShowReaction: _b => null,
  isCanRepost: (_data, _dataParent) => false,
};

export const PostItemContext =
  React.createContext<IPostItemContext>(initialValue);

export const PostItemProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [isRelayShow, setIsRelayShow] = useState(false);
  const [isReposted, setIsReposted] = useState(false);
  const [isParentRepost, setIsParentRepost] = useState(false);
  const [isShowMenuAction, setIsShowMenuAction] = useState(false);
  const [tips, setTips] = useState<number>(0);
  const [isShowReaction, setIsShowReaction] = useState<boolean>(false);
  const POST_CANT_REPOST = [
    'give_away_receive',
    'give_away_deposit',
    'raffle_create',
    'wheel_create',
    'give_away_multiple_deposit',
    '',
  ];

  const isCanRepost = (data: any, dataParent: any): boolean => {
    return (
      POST_CANT_REPOST.indexOf(data?.activity) !== -1 ||
      POST_CANT_REPOST.indexOf(dataParent?.activity) !== -1
    );
  };

  const contextValues = useMemo((): IPostItemContext => {
    return {
      setIsRelayShow: setIsRelayShow,
      setIsReposted: setIsReposted,

      isRelayShow: isRelayShow,
      isReposted: isReposted,

      setTips: setTips,
      tips: tips,

      isShowMenuAction: isShowMenuAction,
      setIsShowMenuAction: setIsShowMenuAction,

      isParentRepost: isParentRepost,
      setIsParentRepost: setIsParentRepost,

      isShowReaction: isShowReaction,
      setIsShowReaction: setIsShowReaction,
      isCanRepost: isCanRepost,
    };
  }, [
    setIsRelayShow,
    isRelayShow,
    setIsReposted,
    isReposted,
    tips,
    setTips,
    isShowMenuAction,
    setIsShowMenuAction,
    isShowReaction,
    setIsShowReaction,
    isCanRepost,
    isParentRepost,
    setIsParentRepost,
  ]);

  return (
    <PostItemContext.Provider value={contextValues}>
      {children}
    </PostItemContext.Provider>
  );
};
