import React, { useState, useContext, forwardRef, use } from 'react';
import {
  MentionsInput,
  Mention,
  MentionItem,
  SuggestionDataItem,
} from 'react-mentions';
import cn from 'classnames';

import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import { getListMentionByKeyWord } from '@/services/player-share';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';

import s from './Mention.module.scss';
import { useRouter } from 'next/router';

type IProps = {
  className?: string;
  onChange: (...args: any[]) => void;
  placeholder?: string;
  ref: any;
  rows?: number;
};

const MentionWrapper: React.FC<IProps> = forwardRef(
  ({ className, onChange, placeholder, rows }, ref: any) => {
    const [value, setValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { addressL2 } = useContext(WalletContext);

    const router = useRouter();

    const fetchUsers = async (
      query: string,
      callback: (data: SuggestionDataItem[]) => void
    ): Promise<void> => {
      if (!query || !addressL2) return;
      setIsLoading(true);
      const result = await getListMentionByKeyWord(addressL2, query);
      setIsLoading(false);
      return callback(
        result?.map?.((user: IGetPlayerPoolProfile) => ({
          id: user.address,
          display: user.twitterName,
          twitterAvatar: user.twitterAvatar,
          twitterName: user.twitterName,
          twitterUsername: user.twitterUsername,
        })) || []
      );
    };

    const renderSuggestion = (
      suggestion: {
        twitterAvatar?: string;
        twitterName?: string;
        twitterUsername?: string;
      } & SuggestionDataItem,
      search: string,
      highlightedDisplay: React.ReactNode,
      index: number,
      focused: boolean
    ): React.ReactNode | undefined => {
      return (
        <div className={cn(s.user, focused && s.highlight)}>
          <img src={suggestion?.twitterAvatar} alt="avatar" />
          <div className={s.twitterInfo}>
            <span className={s.twitterUserName}>{suggestion?.twitterName}</span>
            <span className={s.twitterName}>
              <span>@</span>
              {suggestion?.twitterUsername}
            </span>
          </div>
        </div>
      );
    };

    const handleInputType = (
      _: { target: { value: string } },
      newValue: string,
      newPlainTextValue: string,
      mentions: MentionItem[]
    ): void => {
      setValue(newValue);
      let plainTextValue = newPlainTextValue;
      mentions?.forEach((mention: MentionItem) => {
        plainTextValue = plainTextValue?.replaceAll(
          mention?.display,
          `<a onClick={} href="${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${mention?.id}">${mention?.display}</a>`
        );
      });

      onChange(plainTextValue);
    };

    return (
      <MentionsInput
        inputRef={ref}
        className={cn(s.mention, className)}
        value={value}
        onChange={handleInputType}
        placeholder={placeholder}
        rows={rows}
        style={{
          '&multiLine': {
            highlighter: {
              border: 0,
            },
            input: {
              margin: 0,
            },
          },
          suggestions: {
            top: '2dvh',
            right: -16,
            left: 0,
            zIndex: 9999,
            list: {
              backgroundColor: '#212121',
              height: '70dvh',
              // width: '100vw',
              paddingBottom: '10dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
              borderTop: ' 1px solid rgba(255,255,255, 0.15)',
              boxShadow: '0px 0px 12px -6px rgba(116, 200, 246, 0.16',
            },
            item: {
              height: 58,
              padding: '20px',
              paddingLeft: '0px',
            },
          },
        }}
      >
        <Mention
          trigger="@"
          data={fetchUsers}
          isLoading={isLoading}
          renderSuggestion={renderSuggestion}
          displayTransform={(_, twitterName) => twitterName}
          style={{
            color: '#4185EC',
            backgroundColor: 'rgba(65, 133, 236, 0.3)',
          }}
          className={s.mentionWrapper}
          appendSpaceOnAdd
        />
      </MentionsInput>
    );
  }
);

export default MentionWrapper;
