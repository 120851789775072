import React from 'react';

const ReactionOrange = () => {
  return (
    <svg
      height="16"
      width="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_433_190)">
        <path
          d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1876 13.065 14.1902C11.8407 15.1927 10.3466 15.8095 8.77168 15.9625C7.1968 16.1155 5.61192 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.93838 11.7598C0.194739 10.3629 -0.11794 8.77668 0.0399006 7.20196C0.197741 5.62724 0.819008 4.13472 1.8251 2.91323C2.8312 1.69174 4.17692 0.796164 5.692 0.339808C7.20708 -0.116546 8.82344 -0.113175 10.3366 0.349495C11.3414 0.65488 12.276 1.15549 13.0871 1.82267C13.8982 2.48984 14.5696 3.31048 15.0632 4.2376C15.5567 5.16472 15.8626 6.18008 15.9632 7.2256C16.0639 8.27108 15.9574 9.32616 15.6498 10.3304Z"
          fill="url(#paint0_radial_433_190)"
        />
        <path
          d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1876 13.065 14.1902C11.8407 15.1927 10.3466 15.8095 8.77168 15.9625C7.1968 16.1155 5.61192 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.93838 11.7598C0.194739 10.3629 -0.11794 8.77668 0.0399006 7.20196C0.197741 5.62724 0.819008 4.13472 1.8251 2.91323C2.8312 1.69174 4.17692 0.796164 5.692 0.339808C7.20708 -0.116546 8.82344 -0.113175 10.3366 0.349495C11.3414 0.65488 12.276 1.15549 13.0871 1.82267C13.8982 2.48984 14.5696 3.31048 15.0632 4.2376C15.5567 5.16472 15.8626 6.18008 15.9632 7.2256C16.0639 8.27108 15.9574 9.32616 15.6498 10.3304Z"
          fill="#F7931A"
        />
        <path
          d="M11.8722 6.9508C12.0342 5.826 11.2098 5.22138 10.0825 4.81803L10.4482 3.29439L9.55528 3.06328L9.19928 4.54681C8.96454 4.486 8.72348 4.42869 8.48389 4.37189L8.84248 2.87857L7.95014 2.64746L7.58423 4.17061C7.38998 4.12467 7.19919 4.07926 7.01409 4.03142L7.01512 4.02663L5.7838 3.70724L5.54628 4.69785C5.54628 4.69785 6.20873 4.85559 6.19477 4.8653C6.55634 4.95904 6.62173 5.20766 6.61087 5.40473L6.19429 7.14052C6.21919 7.14709 6.25149 7.15659 6.28712 7.17146C6.25733 7.16378 6.22563 7.15539 6.19274 7.14721L5.60884 9.5788C5.56465 9.69291 5.45249 9.86415 5.1997 9.79913C5.20865 9.81259 4.55073 9.6309 4.55073 9.6309L4.10742 10.6926L5.26938 10.9935C5.48554 11.0498 5.69737 11.1087 5.90598 11.1641L5.53649 12.7052L6.42836 12.9363L6.79426 11.4116C7.03791 11.4803 7.27436 11.5436 7.50584 11.6034L7.14116 13.1209L8.0341 13.3521L8.40355 11.8138C9.92612 12.1131 11.071 11.9925 11.5529 10.5619C11.9412 9.4102 11.5335 8.74588 10.7325 8.31266C11.3159 8.1729 11.7554 7.77431 11.8726 6.95093L11.8723 6.95072L11.8722 6.9508ZM9.83218 9.92232C9.55624 11.0741 7.68939 10.4515 7.08413 10.2953L7.57444 8.25363C8.17967 8.41058 10.1206 8.72117 9.83222 9.92232H9.83218ZM10.1083 6.93411C9.8566 7.98174 8.3028 7.4495 7.79876 7.31899L8.2433 5.46728C8.74734 5.59779 10.3705 5.84137 10.1084 6.93411H10.1083Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_433_190"
          cx="0"
          cy="0"
          gradientTransform="translate(7.99996 7.99712) scale(7.99644 7.99808)"
          gradientUnits="userSpaceOnUse"
          r="1"
        >
          <stop stopColor="#FFF2E1" />
          <stop offset="0.65" stopColor="#FFAE4B" />
          <stop offset="1" stopColor="#FF8C00" />
        </radialGradient>
        <clipPath id="clip0_433_190">
          <rect height="16" width="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReactionOrange;
