export const IcLogo = (): React.ReactElement => (
  <svg
    height="28"
    width="28"
    fill="none"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect height="28" width="28" fill="black" rx="14" />
    <g clipPath="url(#clip0_18658_6343)">
      <path
        d="M18.5494 16.9936L11.0071 9.45127C10.9669 9.41107 10.9172 9.38181 10.8625 9.36625C10.8079 9.3507 10.7501 9.34937 10.6948 9.36239C10.6395 9.37542 10.5885 9.40236 10.5465 9.44067C10.5045 9.47898 10.4731 9.52738 10.4551 9.58127L6.68407 20.8953C6.66739 20.9454 6.66284 20.9987 6.6708 21.0509C6.67876 21.1031 6.69901 21.1526 6.72986 21.1954C6.76072 21.2383 6.8013 21.2732 6.84828 21.2972C6.89526 21.3213 6.94728 21.3339 7.00007 21.3339C7.03589 21.3339 7.07147 21.3281 7.10541 21.3166L18.4194 17.5456C18.4733 17.5276 18.5217 17.4961 18.56 17.4542C18.5983 17.4122 18.6253 17.3612 18.6383 17.3058C18.6513 17.2505 18.65 17.1928 18.6344 17.1382C18.6189 17.0835 18.5896 17.0338 18.5494 16.9936Z"
        fill="#EFD358"
      />
      <path
        d="M18.5493 17.4652C19.2002 16.8144 17.9339 14.4928 15.7209 12.2798C13.5079 10.0668 11.1863 8.8005 10.5354 9.45137C9.88457 10.1022 11.1509 12.4239 13.3639 14.6368C15.5768 16.8498 17.8984 18.1161 18.5493 17.4652Z"
        fill="#E2AC4B"
      />
      <path
        d="M16.3332 14.3337C16.2772 14.3334 16.2222 14.3191 16.1732 14.2919C16.1242 14.2647 16.0828 14.2257 16.0529 14.1783C16.023 14.1309 16.0055 14.0768 16.0021 14.0208C15.9987 13.9649 16.0094 13.9091 16.0332 13.8584C16.8719 12.0754 18.9682 11.4584 21.1299 12.3584C21.2115 12.3924 21.2762 12.4575 21.3099 12.5392C21.3435 12.621 21.3433 12.7128 21.3092 12.7944C21.2752 12.876 21.2101 12.9407 21.1284 12.9743C21.0466 13.008 20.9548 13.0077 20.8732 12.9737C19.0562 12.217 17.3152 12.6977 16.6366 14.1404C16.6098 14.1982 16.567 14.2472 16.5132 14.2815C16.4594 14.3157 16.397 14.3339 16.3332 14.3337Z"
        fill="#43A6DD"
      />
      <path
        d="M18.3335 10C18.8858 10 19.3335 9.55228 19.3335 9C19.3335 8.44772 18.8858 8 18.3335 8C17.7812 8 17.3335 8.44772 17.3335 9C17.3335 9.55228 17.7812 10 18.3335 10Z"
        fill="#E86C60"
      />
      <path
        d="M19.6667 19.6673C20.0349 19.6673 20.3333 19.3688 20.3333 19.0007C20.3333 18.6325 20.0349 18.334 19.6667 18.334C19.2985 18.334 19 18.6325 19 19.0007C19 19.3688 19.2985 19.6673 19.6667 19.6673Z"
        fill="#E2AC4B"
      />
      <path
        d="M15.3332 12.6673C15.7014 12.6673 15.9998 12.3688 15.9998 12.0007C15.9998 11.6325 15.7014 11.334 15.3332 11.334C14.965 11.334 14.6665 11.6325 14.6665 12.0007C14.6665 12.3688 14.965 12.6673 15.3332 12.6673Z"
        fill="#5A7A84"
      />
      <path
        d="M20.1665 15.334C20.4426 15.334 20.6665 15.1101 20.6665 14.834C20.6665 14.5578 20.4426 14.334 20.1665 14.334C19.8904 14.334 19.6665 14.5578 19.6665 14.834C19.6665 15.1101 19.8904 15.334 20.1665 15.334Z"
        fill="#AE453E"
      />
      <path
        d="M11.8335 8C12.1096 8 12.3335 7.77614 12.3335 7.5C12.3335 7.22386 12.1096 7 11.8335 7C11.5574 7 11.3335 7.22386 11.3335 7.5C11.3335 7.77614 11.5574 8 11.8335 8Z"
        fill="#E2AC4B"
      />
      <path
        d="M12.9998 12.3335C12.9299 12.3335 12.8617 12.3115 12.8049 12.2706C12.7482 12.2297 12.7057 12.172 12.6836 12.1056C12.6615 12.0392 12.6608 11.9676 12.6817 11.9008C12.7025 11.834 12.7439 11.7755 12.7998 11.7335C13.5436 11.1798 14.0724 10.3852 14.2962 9.48534C14.5199 8.58544 14.4247 7.6358 14.0268 6.79819C13.9935 6.71721 13.9933 6.62636 14.0264 6.54527C14.0595 6.46418 14.1232 6.39937 14.2037 6.36486C14.2842 6.33035 14.375 6.32891 14.4566 6.36084C14.5381 6.39278 14.6038 6.45553 14.6395 6.53553C15.0994 7.50994 15.2073 8.61366 14.9448 9.65869C14.6823 10.7037 14.0656 11.6254 13.1998 12.2669C13.1422 12.3103 13.072 12.3337 12.9998 12.3335Z"
        fill="#E86C60"
      />
      <path
        d="M7.39048 10.6096C7.30208 10.6096 7.21731 10.5745 7.15481 10.512C6.99563 10.3582 6.86866 10.1743 6.78131 9.97097C6.69396 9.76763 6.64798 9.54893 6.64606 9.32763C6.64414 9.10633 6.68631 8.88687 6.77011 8.68204C6.85391 8.47721 6.97766 8.29113 7.13415 8.13464C7.29064 7.97815 7.47673 7.8544 7.68155 7.7706C7.88638 7.68679 8.10585 7.64462 8.32714 7.64655C8.54844 7.64847 8.76714 7.69445 8.97048 7.7818C9.17382 7.86914 9.35773 7.99611 9.51148 8.1553C9.5722 8.21817 9.60579 8.30237 9.60503 8.38977C9.60427 8.47716 9.56922 8.56077 9.50742 8.62257C9.44561 8.68437 9.36201 8.71943 9.27461 8.72019C9.18721 8.72095 9.10301 8.68735 9.04014 8.62663C8.84966 8.44483 8.59646 8.34339 8.33314 8.34339C8.06982 8.34339 7.81663 8.44483 7.62614 8.62663C7.43867 8.81416 7.33336 9.06847 7.33336 9.33363C7.33336 9.5988 7.43867 9.8531 7.62614 10.0406C7.67275 10.0872 7.70448 10.1466 7.71734 10.2113C7.73019 10.2759 7.72359 10.3429 7.69837 10.4038C7.67315 10.4647 7.63043 10.5168 7.57563 10.5534C7.52083 10.5901 7.45639 10.6096 7.39048 10.6096Z"
        fill="#43A6DD"
      />
    </g>
    <defs>
      <clipPath id="clip0_18658_6343">
        <rect height="16" width="16" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
