export const IcRepost = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.74658 3.15773C4.53627 2.94742 4.19529 2.94742 3.98499 3.15773L1.19716 5.94556C0.934279 6.20844 0.934279 6.63466 1.19716 6.89754C1.46005 7.16043 1.88627 7.16043 2.14915 6.89754L3.69263 5.35407V13.4224C3.69263 15.2812 5.19954 16.7882 7.05841 16.7882H11.3666C11.7384 16.7882 12.0398 16.4868 12.0398 16.115C12.0398 15.7432 11.7384 15.4418 11.3666 15.4418H7.05841C5.94309 15.4418 5.03894 14.5377 5.03894 13.4224V5.35407L6.58241 6.89754C6.8453 7.16043 7.27152 7.16043 7.5344 6.89754C7.79729 6.63466 7.79729 6.20844 7.5344 5.94556L4.74658 3.15773Z"
        fill="white"
      />
      <path
        d="M16.5057 16.8208C16.716 17.0311 17.057 17.0311 17.2673 16.8208L20.0551 14.033C20.318 13.7701 20.318 13.3439 20.0551 13.081C19.7922 12.8181 19.366 12.8181 19.1031 13.081L17.5596 14.6244V6.55614C17.5596 4.69727 16.0527 3.19036 14.1939 3.19036H9.88566C9.51389 3.19036 9.21251 3.49174 9.21251 3.86351C9.21251 4.23529 9.51389 4.53667 9.88566 4.53667H14.1939C15.3092 4.53667 16.2133 5.44082 16.2133 6.55614V14.6244L14.6699 13.081C14.407 12.8181 13.9808 12.8181 13.7179 13.081C13.455 13.3439 13.455 13.7701 13.7179 14.033L16.5057 16.8208Z"
        fill="white"
      />
    </svg>
  );
};
