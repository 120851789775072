import { Box } from '@chakra-ui/react';
import s from './styles.module.scss';
import enhance from './Page.enhance';
import { PageProps } from './Page.types';
import { useMemo } from 'react';
import TableView from './Page.TableView';
import { HEADER_COLUMNS } from './Page.contansts';
import SectionDescrition from './Page.SectionDescription';
import { Text } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react';

const BitcoinWallets = (props: PageProps) => {
  const { data, walletList, isDataFetching } = props;
  const isEmpty = useMemo(() => {
    return !data || Object.keys(data.wallets).length < 1;
  }, [data]);

  const renderData = () => {
    if (isEmpty) return <p>Data Empty</p>;
    return (
      <>
        <SectionDescrition {...props} />
        <Text
          fontSize={'18px'}
          fontWeight={700}
          color={'white'}
          alignSelf={'baseline'}
          marginTop={'30px'}
        >
          Bitcoin Addresses
        </Text>
        <TableView dataHeader={HEADER_COLUMNS} dataSource={walletList} />
      </>
    );
  };

  return (
    <Box className={s.container}>
      {isDataFetching ? (
        <Spinner size={'xl'} color="blue.500" alignSelf={'center'}></Spinner>
      ) : (
        renderData()
      )}
    </Box>
  );
};

export default enhance(BitcoinWallets);
