import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import CContract from '@/contracts/contract';
import { ethers, Wallet } from 'ethers';
import { useContext } from 'react';
import { TOKEN_ADDRESS } from '@/constants/token';
import BigNumber from 'bignumber.js';
import { ISwapParams, ISwapResponse } from '@/contracts/swap/swap.interface';
import {
  Token,
  setTOkenSwap,
  getBestRouteExactIn,
  choiceConFig,
  refreshProvider,
  Environment,
  changeWallet,
  WalletType,
  executeTradeSlippage,
} from 'trustless-swap-sdk';
import { isProduction } from '@/utils/commons';

class CSwap {
  gameWalletProvider: IWalletContext = useContext(WalletContext);
  wallet: Wallet = this.gameWalletProvider.gameWallet as Wallet;

  tokenBTC = new Token(1, TOKEN_ADDRESS.BTC_ADDRESS_L2, 18, 'btc', 'btc');
  tokenTC = new Token(1, TOKEN_ADDRESS.TC_ADDRESS_L2, 18, 'tc', 'tc');
  tokenETH = new Token(1, TOKEN_ADDRESS.ETH_ADDRESS_L2, 18, 'eth', 'eth');

  private contract = new CContract();

  private configSDK = () => {
    changeWallet(
      WalletType.PRIVATEKEY,
      this.wallet.address,
      this.wallet.privateKey
    );
    choiceConFig(isProduction() ? Environment.MAINNET : Environment.TESTNET);
    refreshProvider(null);
  };

  private convertHumanAmountToWei = (humanAmount: string) => {
    return new BigNumber(humanAmount).multipliedBy(1e18).toString();
  };

  public approve = async (tokenAddress: string, humanAmount: string) => {
    const contract = await this.contract
      .getERC20Contract(tokenAddress)
      .connect(this.wallet);

    const allowance = await contract.allowance(
      this.wallet.address,
      TOKEN_ADDRESS.DEX_ROUTER_ADDRESS_L2
    );
    const amountApprove = allowance.toString();

    const isNeedApprove = new BigNumber(amountApprove).lt(
      this.convertHumanAmountToWei(humanAmount)
    );

    console.log('CSwap approve', {
      amountApprove,
      isNeedApprove,
      tokenAddress,
      route: TOKEN_ADDRESS.DEX_ROUTER_ADDRESS_L2,
    });

    if (isNeedApprove) {
      await contract.approve(
        TOKEN_ADDRESS.DEX_ROUTER_ADDRESS_L2,
        ethers.constants.MaxUint256
      );
    }
  };

  /**
   * estimate swap BTC to ETH
   */
  public estimateSwapBTC2TC = async (
    params: ISwapParams
  ): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);

    console.log('CSwap estimateSwapBTC2TC rs1:', rs1);

    return rs1;
  };

  /**
   * swap BTC to TC
   */
  public swapBTC2TC = async (
    params: ISwapParams
  ): Promise<ISwapResponse | undefined> => {
    this.configSDK();
    await this.approve(TOKEN_ADDRESS.BTC_ADDRESS_L2, params.humanAmount);
    const scanTX = true; // scan tx wait for confirm
    const in_amount = params.humanAmount;
    console.log('CSwap swapBTC2TC params:', params);

    const receiver = params.receiver || this.wallet.address;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);

    console.log('CSwap swapBTC2TC rs1:', rs1);

    const rs2 = await executeTradeSlippage(rs1[2], 50, receiver, scanTX);

    console.log('CSwap swapBTC2TC rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      return { txHash: rs2[1].blockHash };
    }
  };

  /**
   * estimate swap BTC to ETH
   */
  public estimateSwapBTC2ETH = async (
    params: ISwapParams
  ): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenETH,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);

    console.log('CSwap estimateSwapBTC2ETH rs1:', rs1);

    return rs1;
  };

  /**
   * swap BTC to ETH
   */
  public swapBTC2ETH = async (
    params: ISwapParams
  ): Promise<ISwapResponse | undefined | any> => {
    this.configSDK();
    await this.approve(TOKEN_ADDRESS.BTC_ADDRESS_L2, params.humanAmount);
    const scanTX = true; // scan tx wait for confirm
    const in_amount = params.humanAmount;

    console.log('CSwap swapBTC2ETH params:', params);

    const receiver = params.receiver || this.wallet.address;

    setTOkenSwap(
      this.tokenBTC,
      in_amount as unknown as number,
      this.tokenETH,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('CSwap swapBTC2ETH rs1:', rs1);

    const rs2 = await executeTradeSlippage(rs1[2], 50, receiver, scanTX);

    console.log('CSwap swapBTC2ETH rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      return { txHash: rs2[1].blockHash, amount: rs2[4] };
    }
  };

  /**
   * estimate swap TC to BTC
   */
  public estimateSwapTC2BTC = async (
    params: ISwapParams
  ): Promise<Array<any>> => {
    this.configSDK();
    const in_amount = params.humanAmount;

    setTOkenSwap(
      this.tokenTC,
      in_amount as unknown as number,
      this.tokenBTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('CSwap estimateSwapTC2BTC rs1:', rs1);
    return rs1;
  };

  /**
   * swap TC to BTC
   */
  public swapTC2BTC = async (
    params: ISwapParams
  ): Promise<ISwapResponse | undefined> => {
    this.configSDK();
    const scanTX = true; // scan tx wait for confirm
    const in_amount = params.humanAmount;

    console.log('CSwap swapTC2BTC params:', params);

    const receiver = params.receiver || this.wallet.address;

    setTOkenSwap(
      this.tokenTC,
      in_amount as unknown as number,
      this.tokenBTC,
      3000
    );

    const rs1 = await getBestRouteExactIn(in_amount);
    console.log('CSwap swapTC2BTC rs1:', rs1);

    const rs2 = await executeTradeSlippage(rs1[2], 50, receiver, scanTX);

    console.log('CSwap swapTC2BTC rs2:', rs2);

    if (rs2 && rs2.length > 1) {
      return { txHash: rs2[1].blockHash };
    }
  };
}

export default CSwap;
