import React, { useEffect, useRef, useState } from 'react';
import styles from './UploadGif.module.scss';
import stylesDesktop from './UploadGifDesktop.module.scss';
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import GiftSvg from '../Icon/GiftSvg';
import createAxiosInstance from '@/services/http-client';
import { API_FOC, CDN_URL_ICONS, PERP_API_URL } from '@/configs';
import ResizeObserver from 'react-resize-observer';
import { Box, Spinner } from '@chakra-ui/react';
import BackSvg from '../Icon/BackSvg';
import SearchSvg from '../Icon/SearchSvg';
import CloseSvg from '../Icon/CloseSvg';

const gf = new GiphyFetch('bRrIUn5NljMx6m59IuQSaFx0xmmWL0iC');

const apiClient = createAxiosInstance({
  baseURL: API_FOC,
});

const searchTerm = 'dogs';

const gifGroups = [
  {
    label: 'Agree',
    img: 'https://media1.giphy.com/media/WJjLyXCVvro2I/giphy_s.gif',
  },
  {
    label: 'Applause',
    img: 'https://media4.giphy.com/media/fnK0jeA8vIh2QLq3IZ/giphy_s.gif',
  },
  {
    label: 'Awww',
    img: 'https://media3.giphy.com/media/rpf0Du8NasK6Q/giphy_s.gif',
  },
  {
    label: 'Dance',
    img: 'https://media0.giphy.com/media/l4Ep3mmmj7Bw3adWw/giphy_s.gif',
  },
  {
    label: 'Deal with it',
    img: 'https://media3.giphy.com/media/tqiYB9X6goN68/giphy_s.gif',
  },
  {
    label: 'Do not want',
    img: 'https://media1.giphy.com/media/2pU8T0OTNkmre/giphy_s.gif',
  },
  {
    label: 'Eww',
    img: 'https://media2.giphy.com/media/10FHR5A4cXqVrO/giphy_s.gif',
  },
  {
    label: 'First bump',
    img: 'https://media3.giphy.com/media/Dnt2VnWFknFNm/giphy_s.gif',
  },
];

interface props {
  isPost?: boolean;
  isChat?: boolean;
  addGif: (gifs: any) => void;
  sizes?: {
    w: string;
    h: string;
    heightModal?: string;
  };
}

const UploadGif = (props: props) => {
  const { addGif, sizes, isPost, isChat } = props;
  // const fetchGifs = (offset: number) =>
  //   gf.search(searchTerm, { offset, limit: 10 });

  // useEffect(() => {
  //   (async () => {
  //     const test = await fetchGifs(1);
  //     console.log({ test });
  //   })();
  // }, []);

  const s = isPost || isChat ? stylesDesktop : styles;

  const [showModal, setShowModal] = useState<boolean | null>(null);
  const [currentTopic, setCurrentTopic] = useState<string | null>(null);
  const [dataGif, setDataGif] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth - 10);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [isResearch, setIsResearch] = useState(false);
  const customSizeIcon: any = sizes
    ? {
        width: sizes.w,
        height: sizes.h,
      }
    : null;

  const onToggleModal = () => {
    if (showModal === null) {
      setShowModal(true);
      return;
    }
    setShowModal(!showModal);
  };

  const onPickTopicGif = async () => {
    if (dataGif?.length > 0) return;
    setIsLoading(true);
    try {
      let res: any = await apiClient.get(
        `/search/gif?q=${currentTopic}&offset=1&limit=50`
      );
      res = JSON.parse(atob(res));
      // console.log('RES Gift: ', res);
      // setDataGif(res.data);
      return res;
    } catch (error) {
      console.log('error fetch gif', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onGifClick = (gif: any, e: any) => {
    e.preventDefault();
    const url = gif.images.original.url;
    const id = gif.id;

    addGif({
      url,
      id,
    });
    onBack();
    // close modal
    setShowModal(false);
  };

  const onChangeSearch = (e: any) => {
    const value = e.target.value;
    setValueSearch(value);
  };

  const onSearch = () => {
    setCurrentTopic(valueSearch);
    setIsResearch(false);
  };

  const onBack = () => {
    setValueSearch('');
    setCurrentTopic(null);
    setIsResearch(false);
  };

  useEffect(() => {
    if (!valueSearch) {
      setCurrentTopic(null);
    }
  }, [valueSearch]);

  const renderContent = () => {
    return (
      <>
        <div
          className={`${s.modalGift} ${
            showModal ? s.open : showModal !== null && s.close
          }`}
          style={{ height: sizes?.heightModal || '95vh' }}
        >
          {!currentTopic ? (
            <div className={s.header}>
              {!!showModal && (
                <>
                  <SearchInput
                    isPost={isPost}
                    onChangeSearch={onChangeSearch}
                    valueSearch={valueSearch}
                    onSearch={onSearch}
                    isResearch={isResearch}
                    onClearValue={() => {
                      setValueSearch('');
                    }}
                  />
                  <button className={s.doneBtn} onClick={onToggleModal}>
                    Close
                  </button>
                </>
              )}
            </div>
          ) : (
            <div className={`${s.header} ${s.space}`}>
              <button className={s.backBtn} onClick={onBack}>
                <BackSvg />
              </button>
              {!isResearch ? (
                <div className={s.topicTitle}>{currentTopic}</div>
              ) : (
                <SearchInput
                  isPost={isPost}
                  onChangeSearch={onChangeSearch}
                  valueSearch={valueSearch}
                  onSearch={onSearch}
                  isResearch={isResearch}
                  onClearValue={() => {
                    setValueSearch('');
                  }}
                />
              )}
              <button
                className={s.research}
                onClick={() => {
                  setIsResearch(!isResearch);
                  setValueSearch('');
                  setCurrentTopic(null);
                }}
              >
                {' '}
                {isResearch ? 'Cancel' : <SearchSvg />}
              </button>
            </div>
          )}

          {isLoading && (
            <div className={s.loading}>
              <Spinner width={10} height={10} />
            </div>
          )}
          {currentTopic && (
            <div className={s.bodyDetail}>
              <Grid
                onGifClick={onGifClick}
                width={width}
                columns={3}
                gutter={6}
                noLink
                fetchGifs={onPickTopicGif}
                className={s.contentGif}
                hideAttribution={true}
              />
              <ResizeObserver
                onResize={({ width }) => {
                  setWidth(width - 10);
                }}
              />
            </div>
          )}
          {!currentTopic && (
            <div className={s.bodyGroup}>
              {gifGroups?.map(item => (
                <div
                  className={s.groupItem}
                  onClick={() => {
                    setCurrentTopic(item.label);
                  }}
                >
                  <img src={item.img} alt="" />
                  <div className={s.des}>{item.label}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {showModal && (
        <div
          className={s.modalBg}
          onClick={() => {
            setShowModal(!showModal);
          }}
        ></div>
      )}

      {isChat && showModal ? (
        <>
          <Box
            position="absolute"
            top="120px"
            left="20px"
            bottom="0px"
            right="20px"
          >
            {renderContent()}
          </Box>
        </>
      ) : (
        <>{renderContent()}</>
      )}

      <div
        className={s.uploadGif}
        onClick={onToggleModal}
        style={customSizeIcon}
      >
        {/* <GiftSvg /> */}
        <img src={`${CDN_URL_ICONS}/gif-img.png`} alt="add media icon" />
      </div>
    </>
  );
};

export default UploadGif;

const SearchInput = ({
  onChangeSearch,
  valueSearch,
  onSearch,
  isResearch,
  onClearValue,
  isPost,
}: {
  onChangeSearch: (e: any) => void;
  onClearValue: () => void;
  valueSearch: string;
  onSearch: () => void;
  isResearch: boolean;
  isPost?: boolean;
}) => {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    console.log('isResearch: ', isResearch);
    if (isResearch) {
      inputRef?.current?.focus();
    }
  }, [isResearch]);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onClear = () => {
    onClearValue();
    inputRef?.current?.focus();
  };

  return (
    <div className={styles.searchInput}>
      <input
        ref={inputRef}
        placeholder="Search for gif..."
        onChange={e => onChangeSearch(e)}
        onKeyPress={handleKeyPress}
        tabIndex={-1}
        // type="search"
        value={valueSearch}
      />
      {!isPost && (
        <button
          className={styles.searchBtn}
          disabled={!valueSearch}
          onClick={onClear}
        >
          {/* <SearchSvg color="black" /> */}
          <CloseSvg />
        </button>
      )}
    </div>
  );
};
