import { PERP_API_URL } from '@/configs';
import { TWITTER_TOKEN } from '@/constants/storage-key';
import { IHolderActivityParams } from '@/interfaces/activity';
import {
  IGetIssuedShareItem,
  IGetPlayerPoolProfile,
  IGetTwiiterProfile,
  IPlayerHolding,
  TokenDetail,
  UserByTwitter,
} from '@/interfaces/api/player-share';
import { IHolder, IHolderListParams } from '@/interfaces/holder';
import { FeedDataType } from '@/modules/AlphaPWA/Notification/types';
import localStorage from '@/utils/localstorage';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import qs from 'query-string';
import createAxiosInstance from './http-client';
import { ILeaderBoardPoint } from '@/interfaces/leader-board-point';

const apiClient = createAxiosInstance({
  baseURL: `${PERP_API_URL}/api`,
});

// set authorization token
export const setBearerToken = (token: string | string[]) => {
  if (token && apiClient) {
    apiClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );
  }
};
setBearerToken(localStorage.get(TWITTER_TOKEN) || '');

export const getIssuedShare = async (
  address: string,
  network = 'nos'
): Promise<Array<IGetIssuedShareItem>> => {
  const res = await apiClient.get(
    `/player-share/issued?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getPlayerPoolProfile = async (
  address: string,
  network = 'nos'
): Promise<IGetPlayerPoolProfile> => {
  const res = await apiClient.get(
    `/player-share/profile?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getPlayerPoolProfile2 = async (
  address: string,
  network = 'nos'
): Promise<IGetPlayerPoolProfile> => {
  const res = await apiClient.get(
    `/player-share/profile/v2?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getUserPoint = async (
  address: string,
  network = 'nos'
): Promise<any> => {
  const res = await apiClient.get(
    `/player-share/get-user-point?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const postUserPoint = async (
  address: string,
  network = 'nos'
): Promise<any> => {
  const res = await apiClient.post(
    `/player-share/update-user-status?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getPlayerTokens = async ({
  address,
  network = 'nos',
  page = 1,
  limit = 100,
}: {
  address: string;
  network?: string;
  page?: number;
  limit?: number;
}): Promise<any> => {
  const res = await apiClient.get(
    `/player-share/tokens?network=${network}&address=${address}&page=${page}&size=${limit}`
  );
  return Object(camelCaseKeys(res));
};

export const getPlayerTokenDetail = async ({
  address,
  network = 'nos',
}: {
  address: string;
  network?: string;
}): Promise<any> => {
  const res = await apiClient.get(
    `/player-share/token/detail?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getTokenHolding = async ({
  address,
  network = 'nos',
  page = 1,
  limit = 100,
  player_address,
}: {
  network?: string;
  address: string;
  page?: number;
  limit?: number;
  player_address?: string;
}): Promise<IPlayerHolding[]> => {
  const query = qs.stringify({
    address,
    network,
    page,
    limit,
    player_address,
  });
  const res = await apiClient.get(`/player-share/holding?${query}`);
  return Object(camelCaseKeys(res));
};

export const getTokenHolders = async ({
  address,
  network = 'nos',
  page = 1,
  limit = 100,
  player_address,
}: {
  network?: string;
  address: string;
  page?: number;
  limit?: number;
  player_address?: string;
}): Promise<IPlayerHolding[]> => {
  const query = qs.stringify({
    address,
    network,
    page,
    limit,
    player_address,
  });
  const res = await apiClient.get(`/player-share/holders?${query}`);
  return Object(camelCaseKeys(res));
};

export const createAddressLinkTwitter = async ({
  address,
  network = 'nos',
  timestamp,
  signature,
}: {
  address: string;
  network?: string;
  timestamp: number;
  signature: string;
}): Promise<any> => {
  try {
    const res = await apiClient.post('user/create', {
      network,
      address,
      timestamp,
      signature,
    });
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const refreshToken = async ({
  address,
  network = 'nos',
  timestamp,
  signature,
}: {
  address: string;
  network?: string;
  timestamp: number;
  signature: string;
}): Promise<any> => {
  try {
    const res = await apiClient.post(`/user/refresh-token`, {
      network,
      address,
      timestamp,
      signature,
    });
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
  }
  return;
};

export const generateToken = async (uuid: string | string[]): Promise<any> => {
  try {
    const res = await apiClient.post(`/user/generate-token`, {
      uuid,
    });
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
  }
  return;
};

export const getLastAddressLinkTwitter = async (
  network = 'nos'
): Promise<IGetPlayerPoolProfile | undefined> => {
  try {
    const res = await apiClient.get(`/auth/user?network=${network}`);
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
  }
  return;
};

type PlayerPoolProfile = IGetPlayerPoolProfile | null;
export const getListPlayerPoolProfile = async (
  addresses: string[],
  network = 'nos'
): Promise<PlayerPoolProfile[]> => {
  const res = await apiClient.get(
    `/player-share/profile/list?network=${network}&address=${addresses.join(
      ','
    )}`
  );
  return Object(camelCaseKeys(res));
};

export const getListMentionByKeyWord = async (
  addresses: string,
  search: string,
  network = 'nos'
): Promise<IGetPlayerPoolProfile[] | undefined> => {
  try {
    const res = await apiClient.get(
      `/alpha-keys/user/relationship?network=${network}&address=${addresses}&search=${search}`
    );
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
  }
  return;
};

export const syncProfileData = async (
  address: string,
  network = 'nos'
): Promise<any> => {
  try {
    const res = await apiClient.get(
      `/alpha-keys/user/sync-profile?network=${network}&address=${address}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
  return;
};

export const getTwitterProfile = async (
  username: string
): Promise<IGetTwiiterProfile | undefined> => {
  try {
    const res = await apiClient.get(
      `/alpha-keys/user/info?twitter=${username}`
    );
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
  }
  return;
};

export const getTwitterProfileVer2 = async (
  username: string
): Promise<string | undefined> => {
  try {
    const res = await apiClient.get(
      `/alpha-keys/user/address-by-twitter?twitter=${username}`
    );
    if (typeof res === 'string') {
      return res;
    }
    throw new Error('Error');
  } catch (error) {
    console.log(error);
  }
  return;
};

export const getPlayerShareHolders = async (
  params: IHolderListParams
): Promise<IHolder[]> => {
  try {
    const res: IHolder[] = await apiClient.get(`/player-share/holders`, {
      params: {
        ...params,
        network: 'nos',
      },
    });

    return res || [];
  } catch (e) {
    return [];
  }
};

export const getPlayerShareHoldings = async (
  params: IHolderListParams
): Promise<IHolder[]> => {
  try {
    const res: IHolder[] = await apiClient.get(`/player-share/holding`, {
      params: {
        ...params,
        network: 'nos',
      },
    });

    return res || [];
  } catch (e) {
    return [];
  }
};

export const getPlayerShareHoldingsV2 = async (
  params: IHolderListParams
): Promise<IHolder[]> => {
  try {
    const res: IHolder[] = await apiClient.get(`/player-share/holding/v2`, {
      params: {
        ...params,
        network: 'nos',
      },
    });

    return res || [];
  } catch (e) {
    return [];
  }
};

export const getAlphaHoldersActivities = async (
  params: IHolderActivityParams
): Promise<FeedDataType[]> => {
  try {
    const res: FeedDataType[] = await apiClient.get(
      `/player-share/holder/activities`,
      {
        params: {
          ...params,
          network: 'nos',
        },
      }
    );

    return res || [];
  } catch (e) {
    return [];
  }
};

export const getAlphaHoldingsActivities = async (
  params: IHolderActivityParams
): Promise<FeedDataType[]> => {
  try {
    const res: FeedDataType[] = await apiClient.get(
      `/player-share/holding/activities`,
      {
        params: {
          ...params,
          network: 'nos',
        },
      }
    );

    return res || [];
  } catch (e) {
    return [];
  }
};

export const postMentionPlaceholder = async (
  address: string,
  ph_twitter_id: string,
  network = 'nos'
): Promise<any> => {
  const res = await apiClient.post(
    `/tw/mention-placeholder?network=${network}&address=${address}&ph_twitter_id=${ph_twitter_id}`
  );
  return Object(camelCaseKeys(res));
};

export const postSpreedNow = async (
  address: string,
  network = 'nos'
): Promise<any> => {
  const res = await apiClient.post(
    `/tw/spread-on-x?network=${network}&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getTopLeaderBoards = async (params: {
  page?: number;
  limit?: number;
  address?: string;
}): Promise<ILeaderBoardPoint[]> => {
  const res: ILeaderBoardPoint[] = await apiClient.get(
    `/player-share/leaderboards`,
    {
      params,
    }
  );

  return res;
};

export const syncWithDesktop = async (data: any): Promise<any> => {
  try {
    const res = await apiClient.post(`/user/qr-sync`, data);
    return Object(camelCaseKeys(res));
  } catch (error) {
    console.log(error);
  }
  return;
};

export const getUserDetailByTwitterId = async ({
  address,
  twitterIds,
  network = 'nos',
}: {
  address: string;
  twitterIds: string[];
  network?: 'nos';
}): Promise<Record<string, UserByTwitter>> => {
  const query = qs.stringify({
    address,
    twitterIds: twitterIds.join(','),
    network,
  });
  const res = await apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/user/get?${query}`
  );
  return Object(camelCaseKeys(res));
};

export const getConfigs = async ({
  network = 'nos',
}: {
  network?: 'nos';
}): Promise<any> => {
  const res = await apiClient.get(`${PERP_API_URL}/api/configs`, {
    params: {
      network,
    },
  });
  return Object(camelCaseKeys(res));
};

export const userPointClaimFTPoint = async (address: string): Promise<any> => {
  const res = await apiClient.post(
    `${PERP_API_URL}/api/player-share/user-point/claim-ft-point?network=nos&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const getUnreadNotification = async (params: {
  page: number;
  limit: number;
  address: string;
  is_count: number;
}) => {
  try {
    const rs: any = await apiClient.get(
      `${PERP_API_URL}/api/player-share/user-notification`,
      {
        params: {
          network: 'nos',
          ...params,
        },
      }
    );
    return rs;
  } catch (error) {
    console.log({ error });
  }
  return [];
};

export const updateLastTimeSeenNotification = async (
  address: string
): Promise<any> => {
  const res = await apiClient.post(
    `${PERP_API_URL}/api/player-share/user-notification/seen?network=nos&address=${address}`
  );
  return Object(camelCaseKeys(res));
};

export const autoFollowHoldings = async (
  address: string,
  data: any
): Promise<any> => {
  const res = await apiClient.put(
    `${PERP_API_URL}/api/alpha-keys/user/update-auto-follow?network=nos&address=${address}`,
    data
  );
  return Object(camelCaseKeys(res));
};
