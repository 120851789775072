import React from 'react';

type Props = {};

const InviteIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M9.85665 7.35734C9.61665 7.59734 9.31399 7.74997 8.98665 7.80664V8C8.98665 8.276 8.76265 8.5 8.48665 8.5C8.21065 8.5 7.98665 8.276 7.98665 8V7.80534C7.29865 7.67534 6.75732 7.10999 6.67666 6.38932C6.64599 6.11466 6.84331 5.86734 7.11731 5.83667C7.38531 5.804 7.63932 6.00268 7.66998 6.27734C7.70598 6.59468 7.97264 6.83333 8.28998 6.83333H8.70998C8.87531 6.83333 9.03131 6.76798 9.14931 6.64998C9.26798 6.53132 9.33331 6.37529 9.33331 6.20996C9.33331 5.92396 9.13864 5.67532 8.86064 5.60466L7.89598 5.36532C7.54398 5.27598 7.22931 5.07135 7.00798 4.78735C6.78864 4.50935 6.66598 4.15404 6.66598 3.79004C6.66598 2.99871 7.23532 2.34001 7.98598 2.19735V2C7.98598 1.724 8.20998 1.5 8.48598 1.5C8.76198 1.5 8.98598 1.724 8.98598 2V2.19263C9.68598 2.31329 10.2407 2.88134 10.3227 3.61068C10.3533 3.88534 10.156 4.13266 9.88198 4.16333C9.61331 4.19666 9.35997 3.99732 9.32931 3.72266C9.29331 3.40532 9.02664 3.16667 8.70931 3.16667H8.28931C7.94531 3.16667 7.66598 3.44604 7.66598 3.79004C7.66598 3.92937 7.71198 4.06467 7.79531 4.17C7.88331 4.28334 8.00132 4.36003 8.13865 4.39469L9.10331 4.63403C9.82798 4.81737 10.3326 5.46531 10.3326 6.20931C10.3333 6.64264 10.164 7.05001 9.85665 7.35734ZM4.49998 13.3333V10.6667C4.49998 10.2987 4.20131 10 3.83331 10H2.49998C2.13198 10 1.83331 10.2987 1.83331 10.6667V13.3333C1.83331 13.7013 2.13198 14 2.49998 14H3.83331C4.20131 14 4.49998 13.7013 4.49998 13.3333ZM13.6066 10.0133C13.4533 10.0133 13.3 10.0533 13.1533 10.14L11.64 11.0466C11.5 11.8666 10.78 12.5 9.91331 12.5H7.83331C7.55998 12.5 7.33331 12.2733 7.33331 12C7.33331 11.7267 7.55998 11.5 7.83331 11.5H9.91331C10.3266 11.5 10.6666 11.1667 10.6666 10.7467C10.6666 10.3333 10.3266 10 9.91331 10H6.49998C5.76665 10 5.16665 10.6 5.16665 11.3333V12.6667C5.16665 13.4 5.76665 14 6.49998 14H10.2333C10.84 14 11.4267 13.7933 11.9 13.4133L14.1666 11.6C14.38 11.4333 14.5 11.1733 14.5 10.9067C14.5 10.3867 14.0733 10.0133 13.6066 10.0133Z"
        fill="black"
      />
    </svg>
  );
};

export default InviteIcon;
