import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from '@chakra-ui/react';
import { Formik } from 'formik';
import { BTC_L2_ADDRESS, CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import s from './styles.module.scss';
import BTCInput from '@/components/BTCInput';
import { AssetsContext } from '@/contexts/assets-context';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '@/utils';
import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import { getDonationInfo, requestScanTxHash } from '@/services/donate';
import CContract from '@/contracts/contract';
import toast from 'react-hot-toast';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import { DonationOption } from '@/enums/donate';
import cs from 'classnames';
import { useAppDispatch } from '@/state/hooks';
import DonatePublicShareModal, {
  DONATE_PUBLIC_SHARE_MODAL,
} from '../DonatePublicShareModal';
import { closeModal, openModal } from '@/state/modal';
import CPlayerShare, { ETypes } from '@/contracts';
import { IGenerateDonationInfoResponse } from '@/interfaces/api/donate';

export const DONATE_MODAL: string = 'DONATE_MODAL';

interface IProps {
  supportPublicType?: boolean;
  onClose: () => void;
  donateToUserName?: string;
  donateToAddress?: string;
  toAnotherOne?: boolean;
}

const DonateModal: React.FC<IProps> = ({
  onClose,
  donateToUserName,
  donateToAddress,
  toAnotherOne,
  supportPublicType = false,
}): React.ReactElement => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const { addressL2 } = useContext(WalletContext);
  const { balanceL2, playerPoolProfile } = useContext(AssetsContext);
  const contract = new CContract();
  const gameWalletProvider: IWalletContext = useContext(WalletContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState<DonationOption>(
    DonationOption.IN_CIRCLE
  );
  const [donationInfo, setDonationInfo] =
    useState<null | IGenerateDonationInfoResponse>(null);
  const dispatch = useAppDispatch();
  const gaEventTracker = useAnalyticsEventTracker();
  const cplayerShare = new CPlayerShare();

  const handleCloseModal = () => {
    dispatch(closeModal({ id: DONATE_PUBLIC_SHARE_MODAL }));
  };

  const fetchDonationInfo = async (): Promise<void> => {
    if (!addressL2 || (toAnotherOne && addressL2 === donateToAddress)) {
      return;
    }
    try {
      const res = await getDonationInfo({
        network: 'nos',
        playerAddress: donateToAddress || addressL2,
        address: addressL2,
      });
      setDonationInfo(res);
    } catch (err: unknown) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (
    values: Record<string, string>
  ): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!values.amount) {
      errors.amount = 'Amount is required.';
    } else {
      const amountBn = new BigNumber(values.amount);
      const balanceBn = new BigNumber(balanceL2.amountBTCFormatted);
      if (amountBn.isLessThan(donationInfo?.minAmount || 0.0001)) {
        errors.amount = `Amount must be greater than ${
          donationInfo?.minAmount || 0.0001
        } BTC.`;
      }
      if (amountBn.isGreaterThan(balanceBn)) {
        errors.amount = `Amount must be less than ${formatCurrency(
          balanceL2.amountBTCFormatted,
          0,
          4
        )} BTC.`;
      }
    }

    return errors;
  };

  const handleSubmit = async (values: Record<string, string>) => {
    if (
      processing ||
      !addressL2 ||
      !gameWalletProvider.gameWallet ||
      !donationInfo
    ) {
      toast.error('Unauthorized');
      return;
    }

    setProcessing(true);

    try {
      const { address, numOfTicket } = donationInfo;
      if (numOfTicket > 0) {
        setErrorMessage(
          'Your previous red packets are still up for grabs. Snatch them all first!'
        );
        return;
      }

      const amount = new BigNumber(values.amount).multipliedBy(1e18).toFixed(0);
      await cplayerShare.estimateTCGasFee({
        type: ETypes.buy,
      });
      const tx = await contract
        .getERC20Contract(BTC_L2_ADDRESS)
        .connect(gameWalletProvider.gameWallet)
        .transfer(address, amount);
      await tx.wait();
      if (!tx?.hash) {
        toast.error('Transaction not found.');
        gaEventTracker(AlphaActions.SendDonateFail, addressL2);
        return;
      }
      await requestScanTxHash({
        txHash: tx.hash,
        address: addressL2,
        network: 'nos',
        public: selectedOption === DonationOption.PUBLIC,
      });

      if (selectedOption === DonationOption.PUBLIC) {
        dispatch(
          openModal({
            id: DONATE_PUBLIC_SHARE_MODAL,
            theme: 'dark',
            modalProps: {
              centered: true,
              zIndex: 9999999,
            },
            hideCloseButton: false,
            render: () => (
              <DonatePublicShareModal
                amount={values.amount.toString()}
                numOfTicket={5}
                onClose={handleCloseModal}
              />
            ),
          })
        );
      }

      gaEventTracker(AlphaActions.SendDonateSuccess, addressL2);
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setProcessing(false);
      gaEventTracker(AlphaActions.ClickSendDonate, addressL2);
    }
  };

  const displayName = useMemo(() => {
    if (toAnotherOne) return donateToUserName;
    return playerPoolProfile?.twitterName;
  }, [toAnotherOne, donateToUserName, playerPoolProfile]);

  const displayFollowers = useMemo(() => {
    if (toAnotherOne) {
      return `their`;
    }
    return `your ${playerPoolProfile?.holders}`;
  }, [toAnotherOne, playerPoolProfile]);

  useEffect(() => {
    fetchDonationInfo();
  }, [addressL2, donateToAddress, toAnotherOne]);

  return (
    <div className={s.donateModal}>
      {loading && (
        <div className={s.loading}>
          <Spinner
            size={'xl'}
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
          />
        </div>
      )}
      <div className={s.imgWrapper}>
        <img
          className={s.envelopImg}
          src={`${CDN_URL_IMAGES}/red-envelop.png`}
          alt="red-envelop"
        />
      </div>
      {supportPublicType ? (
        <h2 className={s.title}>Red Packets</h2>
      ) : (
        <>
          <h2 className={s.title}>
            Red Packet <br /> for {displayName} circle
          </h2>
          <p className={s.description}>
            Support <span>@{displayName} circle</span> by buying random keys
            from their holders and sharing them randomly with {displayFollowers}{' '}
            fellow members.
          </p>
        </>
      )}
      <Formik
        key="create"
        initialValues={{
          amount: '0.0005',
        }}
        enableReinitialize
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, handleSubmit, setFieldValue }) => {
          return (
            <form className={s.form} onSubmit={handleSubmit}>
              {supportPublicType && (
                <div className={s.optionList}>
                  <div
                    onClick={() => setSelectedOption(DonationOption.IN_CIRCLE)}
                    className={cs(s.optionItem, {
                      [`${s.active}`]:
                        selectedOption === DonationOption.IN_CIRCLE,
                    })}
                  >
                    <div className={s.heading}>
                      <div className={s.left}>
                        <img
                          className={s.prefixIc}
                          src={`${CDN_URL_ICONS}/ic-nbc-3x.png`}
                          alt="tc-icon"
                        />
                        <span className={s.optionTitle}>
                          Private red packets
                        </span>
                      </div>
                      <div className={s.right}>
                        <div className={s.customCheckbox}></div>
                      </div>
                    </div>
                    <p className={s.optionDescription}>
                      Gift red packets exclusively to New Bitcoiners in{' '}
                      <span>@{displayName} circle.</span>
                    </p>
                  </div>
                  <div
                    onClick={() => setSelectedOption(DonationOption.PUBLIC)}
                    className={cs(s.optionItem, {
                      [`${s.active}`]: selectedOption === DonationOption.PUBLIC,
                    })}
                  >
                    <div className={s.heading}>
                      <div className={s.left}>
                        <span className={s.titlePrefix}>✨</span>
                        <span className={s.optionTitle}>
                          Soon-to-be New Bitcoiners
                        </span>
                      </div>
                      <div className={s.right}>
                        <div className={s.customCheckbox}></div>
                      </div>
                    </div>
                    <p className={s.optionDescription}>
                      Gift red packets only to your new referrals invited on X.
                    </p>
                  </div>
                </div>
              )}

              <BTCInput
                autoFocus={false}
                name="amount"
                errorMsg={
                  errors.amount && touched.amount ? errors.amount : undefined
                }
                setFieldValue={setFieldValue}
                value={values.amount}
                minValue={donationInfo?.minAmount}
              />

              <Button
                className={s.submitButton}
                type="submit"
                isDisabled={processing || !donationInfo}
              >
                {processing && <>Processing...</>}
                {!processing && <>Gift</>}
              </Button>
              {errorMessage && <p className={s.errorMessage}>{errorMessage}</p>}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default React.memo(DonateModal);
