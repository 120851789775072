import { ROUTE_PATH } from '@/constants/route-path';
import {
  labelAmountOrNumberAdds,
  MAX_DECIMAL,
  MIN_DECIMAL,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { formatCurrency } from '@/utils';
import { goToRoute } from '@/utils/go-route';
import {
  delay,
  getUrlAvatarTwitter,
  settingMomentFromNow,
} from '@/utils/helpers';
import { formatLongAddress } from '@trustless-computer/dapp-core';
import clsx from 'clsx';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { useMemo, useState, useContext, useRef, useEffect } from 'react';
import Avatar from '../Profiles/TradeKey/components/avatar';
import s from './Feed.module.scss';
import { FeedDataType, FeedPlayerType } from './types';
import { WalletContext } from '@/contexts/wallet-context';
import { Ic33 } from '@/modules/AlphaPWA/Notification/svg/ic-3-3';
import { IcLogo } from '@/modules/AlphaPWA/Notification/svg/ic-default';
import { Flex } from '@chakra-ui/react';
import { IcBuy } from '@/modules/AlphaPWA/Notification/svg/ic-buy';
import { IcSell } from '@/modules/AlphaPWA/Notification/svg/ic-sell';
import { IcLimit } from '@/modules/AlphaPWA/Notification/svg/ic-limit';
import { IcSwap } from '@/modules/AlphaPWA/Notification/svg/ic-swap';
import { useIsInViewportOnce } from '@/hooks/useIsInViewportOnce';
import useAsyncEffect from 'use-async-effect';
import { getPostDetail } from '@/services/alpha_tweet';

settingMomentFromNow();
const activities: any = {
  trade: {
    buy: 'bought',
    sell: 'sold',
  },
  trade_token: {
    buy: 'bought',
    sell: 'sold',
  },
  swap_token: 'swapped',
  issue_token: 'bought',
  join: {},
  top100: {},
  '3_3': 'did (3,3) with ',
  '3_3_waiting': 'wanted to (3,3) with ',
  swap: 'swapped',
};

function calculateTimeAgo(targetTime: string) {
  const targetMoment = moment(targetTime);
  const formattedDate = targetMoment.format('DD-MM-YYYY HH:mm');
  return targetMoment.fromNow();
}

const compareString = (a: unknown, b: unknown) => {
  return a?.toString?.()?.toLowerCase?.() === b?.toString?.()?.toLowerCase?.();
};

const FeedItem = ({ item }: { item: FeedDataType; isKey?: boolean }) => {
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const refMain = useRef(null);
  const [postDesc, setPostDesc] = useState('');

  const isPlayerPresale = useMemo(() => {
    return compareString(item?.player.twitter_id, item?.player.address);
  }, [item]);

  const isUserPresale = useMemo(() => {
    return compareString(item?.user.twitter_id, item?.user.address);
  }, [item]);

  const isUserOutPresale = useMemo(() => {
    return compareString(item?.user_out?.twitter_id, item?.user_out?.address);
  }, [item]);

  const isPayerBuy = useMemo(() => {
    return item.action_type === 'buy';
  }, [item]);

  const isTrade = useMemo(() => {
    return item?.activity === 'trade';
  }, [item?.activity]);

  const isIssue = useMemo(() => {
    return item?.activity === 'issue_token';
  }, [item?.activity]);

  useEffect(() => {
    if (isIssue) {
      item.amount = 1;
    }
  }, [isIssue]);

  const isPostTweet = useMemo(() => {
    return (
      item?.activity === 'post_tweet' ||
      item?.activity === 'reply_tweet' ||
      item?.activity === 'like_your_post'
    );
  }, [item?.activity]);

  const isPostTweetOnAnother = useMemo(() => {
    return item?.activity === 'post_tweet' && isHaveUser(item.user);
  }, [item]);

  const isNotiForMe = useMemo(() => {
    return (
      item?.activity === 'like_your_post' || item?.activity === 'reply_tweet'
    );
  }, [item?.activity]);

  const isRepostTweet = useMemo(() => {
    return item.activity === 'repost_tweet';
  }, [item?.activity]);

  const isAccept33 = useMemo(() => {
    return item.activity === '3_3';
  }, [item.activity]);

  const isRequest33 = useMemo(() => {
    return item.activity === '3_3_waiting';
  }, [item.activity]);

  const isSwapKeyToKey = useMemo(() => {
    return item.activity === 'swap';
  }, [item?.activity]);

  const isSwapToken = useMemo(() => {
    return item.activity === 'swap_token';
  }, [item?.activity]);

  const isTradeToken = useMemo(() => {
    return item?.activity === 'trade_token';
  }, [item?.activity]);

  const isSignup = useMemo((): boolean => {
    return item?.tweet_activity === 'signup';
  }, [item]);

  const isConvert = useMemo((): boolean => {
    return item?.tweet_activity === 'convert';
  }, [item]);

  const isLimitPending = useMemo((): boolean => {
    return item?.activity === 'limit_order_pending';
  }, [item]);

  const isLimitFill = useMemo((): boolean => {
    return item?.activity === 'limit_order_filled';
  }, [item]);

  const isUseIcLimit = useMemo((): boolean => {
    return isLimitFill || isLimitPending;
  }, [item]);

  const isPost_tweet = useMemo((): boolean => {
    return item?.tweet_id > 0;
  }, [item]);

  const isHaveUser = (user: any) => {
    if (
      user.twitter_id ||
      user.twitter_name ||
      user.twitter_username ||
      user.address
    ) {
      return true;
    }
    return false;
  };

  const actionText = useMemo(() => {
    if (item.activity === 'trade' || item?.activity === 'trade_token') {
      return activities[item.activity][item.action_type];
    }
    if (item.activity === 'issue_token') {
      return activities[item.activity];
    }
    if (item.activity === '3_3' || item.activity === '3_3_waiting') {
      return activities[item.activity];
    }
    if (item.activity === 'swap') {
      return activities[item.activity];
    }
    if (item.activity === 'swap_token') {
      return activities[item.activity];
    }

    return '';
  }, [item?.activity]);

  const fromLabelKeyOrToken = useMemo(() => {
    if (isSwapKeyToKey) {
      return 'key';
    } else if (isSwapToken) {
      if (item.action_type === 'key_to_token') {
        return 'key';
      } else {
        return 'token';
      }
    }

    return '';
  }, [isSwapKeyToKey, isSwapToken]);

  const toLabelKeyOrToken = useMemo(() => {
    if (isSwapKeyToKey) {
      return 'key';
    } else if (isSwapToken) {
      if (item.action_type === 'token_to_key') {
        return 'key';
      } else {
        return 'token';
      }
    }

    return '';
  }, [isSwapKeyToKey, isSwapToken]);

  const goToTrade = async (address: string) => {
    if (!address) return;
    router.replace({
      query: { ...router.query, idHistory: item.activity_time },
    });
    await delay(500);
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${address}`);
  };

  const isInView = useIsInViewportOnce(refMain);
  useAsyncEffect(async () => {
    if (isPost_tweet && isInView && addressL2) {
      const postDetail: any = await getPostDetail(
        item?.tweet_id,
        addressL2 || ''
      );
      const reviewContent = postDetail?.is_repost
        ? postDetail?.repost_tweet?.content
        : postDetail?.content;
      setPostDesc(
        reviewContent?.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 250)
      );
    }
  }, [isInView, item, isPost_tweet, addressL2]);

  const renderUserAvatar = ({
    user,
    className,
    width = 28,
  }: {
    user: FeedPlayerType;
    className?: string;
    width?: number;
  }) => {
    return (
      <Avatar
        className={className}
        onClick={(event: React.MouseEvent<unknown>) => {
          event?.stopPropagation();
          goToTrade(user?.address);
        }}
        url={getUrlAvatarTwitter(user?.avatar || user?.twitter_avatar)}
        width={width}
        fontSize={12}
        address={user?.address}
        name={user?.twitter_name}
      />
    );
  };

  const renderAloneAvatar = () => {
    return <>{renderUserAvatar({ user: item?.player, width: 40 })}</>;
  };

  const renderGroupAvatar = () => {
    if (isSwapKeyToKey || isSwapToken) {
      return (
        <div className={s.avatarBlock}>
          {renderUserAvatar({
            user: item.user,
            className: s.imgUser,
            width: 28,
          })}
          {renderUserAvatar({
            user: item?.user_out,
            className: s.imgPlayer,
            width: 28,
          })}
        </div>
      );
    }
    return (
      <div className={s.avatarBlock}>
        {renderUserAvatar({ user: item.user, className: s.imgUser, width: 28 })}
        {renderUserAvatar({
          user: item?.player,
          className: s.imgPlayer,
          width: 28,
        })}
      </div>
    );
  };

  const ReturnUserName = ({ twitter_name, twitter_username, address }: any) => {
    if ((!twitter_name && !twitter_username) || !address) {
      return <></>;
    }
    return (
      <span
        className={s.render_user}
        onClick={(event: React.MouseEvent<unknown>) => {
          event?.stopPropagation();
          if (!address) return;
          goToTrade(address);
        }}
      >
        {twitter_name ||
          (twitter_username && `@${twitter_username}`) ||
          formatLongAddress(address || '')}
      </span>
    );
  };

  const renderUserName = ({
    user,
    showTwitter = false,
  }: {
    user: FeedPlayerType;
    showTwitter?: boolean;
  }) => {
    return (
      <>
        <ReturnUserName
          twitter_name={user?.twitter_name}
          twitter_username={user?.twitter_username}
          address={user?.address}
        />
        {showTwitter && <>&nbsp; @{user.twitter_username}</>}
      </>
    );
  };

  return (
    <div
      className={s.feed_item}
      onClick={() => {
        if (
          !(isTrade || isIssue || isAccept33 || isRequest33 || isTradeToken)
        ) {
          if (isPostTweet && item?.tweet_id) {
            goToRoute(
              router,
              `/alpha/tweet/${item?.tweet_id}?ownerAddress=${
                isPostTweetOnAnother
                  ? item?.user.address
                  : isNotiForMe
                  ? addressL2
                  : item?.player.address
              }`
            );
            return;
          }
          if (isRepostTweet && item?.tweet_id) {
            goToRoute(
              router,
              `/alpha/tweet/${item?.tweet_id}?ownerAddress=${item?.player.address}`
            );
            return;
          }
          goToTrade(item.player.address);
        }
      }}
      id={item.activity_time}
    >
      <div className={s.feedMain} ref={refMain}>
        <div className={s.feed_img}>
          {isAccept33 ||
          isRequest33 ||
          isTrade ||
          isUseIcLimit ||
          isSwapKeyToKey ||
          isSwapToken ||
          isTradeToken
            ? renderGroupAvatar()
            : renderAloneAvatar()}
        </div>
        <div className={s.feed_content}>
          <div className={s.feedMain_border}>
            <div className={clsx(s.key_type)}>
              {isAccept33 || isRequest33 ? (
                <Ic33 />
              ) : (isTrade || isTradeToken) && isPayerBuy ? (
                <IcBuy />
              ) : isTrade || isTradeToken ? (
                <IcSell />
              ) : isSignup || isConvert ? (
                <IcLogo />
              ) : isUseIcLimit ? (
                <IcLimit />
              ) : isSwapKeyToKey || isSwapToken ? (
                <IcSwap />
              ) : (
                <div className={s.dot}></div>
              )}
            </div>
          </div>
          <div className={s.detail}>
            {isUseIcLimit ? (
              <div className={s.detail_limit}>
                <>
                  {renderUserName({ user: item?.user })}
                  {isLimitPending
                    ? ` placed a limit order for ${item.amount} `
                    : isLimitFill && ' bought 1 key of '}
                  {renderUserName({ user: item?.player })}
                  {isLimitFill && ' using a limit order '}
                  {isLimitPending && ` key${item.amount !== 1 ? 's' : ''} `}
                </>
              </div>
            ) : (
              <>
                {isTrade || isIssue || isAccept33 || isRequest33 ? (
                  <>
                    <div>
                      {isIssue || isAccept33 || isRequest33 ? (
                        <>
                          {renderUserName({
                            user: item?.player,
                            showTwitter: isIssue,
                          })}
                        </>
                      ) : (
                        isTrade && renderUserName({ user: item?.user })
                      )}
                      &nbsp;
                      {actionText}
                      {!(isAccept33 || isRequest33) && (
                        <>
                          &nbsp;
                          {formatCurrency(
                            item?.amount,
                            MIN_DECIMAL,
                            MAX_DECIMAL
                          )}
                          &nbsp;
                          {renderUserName({ user: item?.player })}
                          {isPlayerPresale && ' placeholder'} key
                          {labelAmountOrNumberAdds(item?.amount)}
                        </>
                      )}
                      {isRequest33 || isAccept33
                        ? renderUserName({ user: item?.user })
                        : null}
                    </div>
                  </>
                ) : isTradeToken ? (
                  <>
                    {renderUserName({ user: item?.user })}&nbsp;
                    {actionText}
                    &nbsp;
                    {formatCurrency(item?.amount, MIN_DECIMAL, MIN_DECIMAL)}
                    &nbsp;
                    {renderUserName({ user: item?.player })}&nbsp; token
                    {labelAmountOrNumberAdds(item?.amount)}
                  </>
                ) : isSwapKeyToKey || isSwapToken ? (
                  <>
                    {renderUserName({ user: item?.player })}
                    &nbsp;
                    {actionText}
                    &nbsp;
                    {formatCurrency(item?.amount, MIN_DECIMAL, MAX_DECIMAL)}
                    &nbsp;
                    {renderUserName({ user: item?.user })}
                    {isUserPresale &&
                      fromLabelKeyOrToken === 'key' &&
                      ' placeholder'}{' '}
                    {fromLabelKeyOrToken}
                    {labelAmountOrNumberAdds(item?.amount)}
                    &nbsp;for&nbsp;
                    {formatCurrency(item?.amount_out, MIN_DECIMAL, MAX_DECIMAL)}
                    &nbsp;
                    {renderUserName({ user: item?.user_out })}
                    {isUserOutPresale &&
                      toLabelKeyOrToken === 'key' &&
                      ' placeholder'}{' '}
                    {toLabelKeyOrToken}
                    {labelAmountOrNumberAdds(item?.amount_out)}
                  </>
                ) : (
                  <>
                    {renderUserName({ user: item?.player })}
                    &nbsp;
                    {item.description}
                    &nbsp;
                    {(isRepostTweet || isPostTweetOnAnother) && renderUserName({ user: item?.user })}
                    {isPost_tweet && (
                      <div className={s.content_review}>{postDesc}</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <Flex alignItems={'center'} gap="6px" className={s.meta}>
            <div className={s.info}>
              {(isTrade || isUseIcLimit) && (
                <>
                  <span className={s.price}>
                    {formatCurrency(item?.price, 0, 7)}{' '}
                    {item?.base_token_symbol}
                    {item?.price_usd && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <span className={s.price_usd}>
                          {formatCurrency(item?.price_usd, 0, 2)} {'USD '}
                        </span>
                      </>
                    )}
                  </span>
                </>
              )}
              <div className={s.time}>
                {calculateTimeAgo(item?.activity_time)}
              </div>
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};
export default FeedItem;
