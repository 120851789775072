import React, { useContext, useEffect, useRef, useState } from 'react';

import { CDN_URL_ICONS } from '@/configs';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { getTweetTip } from '@/services/alpha_tweet';
import { WalletContext } from '@/contexts/wallet-context';
import { formatCurrency } from '@/utils';
import TipModal, { TIP_MODAL_ID } from './TipModal';
import TipBtnSvg from './TipBtnSvg';
import s from './TipButton.module.scss';
import cn from 'classnames';
import { PostItemContext } from '@/contexts/post-item';

const TipButton = ({
  postId,
  tipToAddress,
  classNames,
  hideNumber,
}: {
  postId: number;
  tipToAddress: string;
  classNames?: string;
  hideNumber?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [tweetTips, setTweetTips] = useState<Array<any> | null>(null);
  const isInViewport = useIsInViewport(buttonRef);
  const { addressL2 } = useContext(WalletContext);
  const { setTips } = useContext(PostItemContext);

  const fetchData = async () => {
    if (addressL2) {
      const result = await getTweetTip({ postId, address: addressL2 });
      setTweetTips(result);
      setTips(result);
    }
  };

  useEffect(() => {
    if (isInViewport && addressL2 && tweetTips === null) {
      fetchData();
    }
  }, [isInViewport, addressL2]);

  const handleCloseModal = async () => {
    await fetchData();
    dispatch(closeModal({ id: TIP_MODAL_ID }));
  };

  const handleOnClick = () => {
    dispatch(
      openModal({
        id: TIP_MODAL_ID,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: false,
        render: () => (
          <TipModal
            postId={postId}
            tipToAddress={tipToAddress}
            onClose={handleCloseModal}
          />
        ),
      })
    );
  };

  const totalTips = tweetTips?.reduce(
    (acc, obj) => Number(acc) + Number(obj?.amount),
    0
  );
  const totalTipsFormat = formatCurrency(totalTips, 0, 4);
  const isNoTip =
    (totalTips !== undefined && totalTips <= 0) || Number(totalTipsFormat) <= 0;

  return (
    <button
      className={cn(s.tipButton, isNoTip && s.noTip, classNames)}
      onClick={e => {
        e.stopPropagation();
        handleOnClick();
      }}
      ref={buttonRef}
    >
      <TipBtnSvg />
      {!hideNumber && (
        <span className={`${s.iconBlock} action_text`}>
          <span>
            {totalTipsFormat} {totalTips !== undefined ? 'BTC' : null}
          </span>
        </span>
      )}
    </button>
  );
};

export default TipButton;
