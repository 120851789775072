import { useState, useEffect } from 'react';
import { getPlayerPoolProfile } from '@/services/player-share';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
// let isCallingApi = false;

const usePlayerPoolProfile = (addressL2: string | undefined) => {
  const [playerPoolProfile, setPlayerPoolProfile] =
    useState<IGetPlayerPoolProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getPlayerPoolProfileByAddress = async (): Promise<void> => {
    if (addressL2) {
      // isCallingApi = true;
      try {
        const res = await getPlayerPoolProfile(addressL2);
        setPlayerPoolProfile(res);
      } catch (error) {
        console.log('getPlayerPoolProfileByAddress Error:', error);
      } finally {
        setIsLoading(false);
      }
      // isCallingApi = false;
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlayerPoolProfileByAddress();
  }, [addressL2]);

  return {
    isLoading,
    playerPoolProfile,
    getPlayerPoolProfileByAddress,
  };
};

export default usePlayerPoolProfile;
