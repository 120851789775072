import { useContext, useEffect, useMemo, useState } from 'react';
import profilesContext from './context';
import { useRouter } from 'next/router';
import {
  IGetPlayerPoolProfile,
  TokenDetail,
} from '@/interfaces/api/player-share';
import {
  getTokenHolding,
  getPlayerPoolProfile,
  getPlayerTokenDetail,
  getListMentionByKeyWord,
  getTokenHolders,
} from '@/services/player-share';
import { IUserGameInfo } from '@/interfaces/api/gamefi';
import { getUserGameInfo } from '@/services/gamefi';
import { WalletContext } from '@/contexts/wallet-context';
import { isAddress } from 'web3-utils';
import { compareString } from '@/utils';
import { ROUTE_PATH } from '@/constants/route-path';
import { AddressZero } from '@ethersproject/constants';
import { BASE_SYMBOL } from '@/contracts/configs';
import { isPlatformTradeKey } from '@/utils/helpers';
import { SupportPlatform } from '@/utils/supportPlatform';

type Props = Pick<React.ComponentPropsWithoutRef<'div'>, 'children'>;

export default function ProfilesProvider({ children }: Props) {
  const router = useRouter();
  const {
    query: { id },
  } = router;

  const { addressL2 } = useContext(WalletContext);
  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(true);
  const [playerPoolProfile, setPlayerPoolProfile] =
    useState<IGetPlayerPoolProfile | null>(null);
  const [yourPoolProfile, setYourPoolProfile] =
    useState<IGetPlayerPoolProfile | null>(null);
  const [userGameInfo, setUserGameInfo] = useState<IUserGameInfo | undefined>();
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [playerTokenDetail, setPlayerTokenDetail] =
    useState<TokenDetail | null>(null);

  const [isTokenHolding, setIsTokenHolding] = useState<boolean>(false);
  const [isTokenHolded, setIsTokenHolded] = useState<boolean>(false);
  const [isTokenHoldingLoading, setIsTokenHoldingLoading] =
    useState<boolean>(true);
  const [keyBalance, setKeyBalance] = useState(0);
  const [keyRemaining, setKeyRemaining] = useState(0);
  const [holderLists, setHolderList] = useState<any[]>([]);

  console.log('ProfilesProvider', id);
  const isProfileAddress = useMemo(() => {
    return isAddress(id as string);
  }, [id]);

  const handleGetUserGameInfo = async (
    address: string,
    callback?: (data: IUserGameInfo) => void
  ) => {
    try {
      const data = await getUserGameInfo(address);
      setUserGameInfo(data);
      callback && callback(data);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      const fetchYourProfile = async () => {
        // get your profile
        const res = await getPlayerPoolProfile(addressL2 as string);
        setYourPoolProfile(res);
      };
      fetchYourProfile();
    } catch (error) {
      console.log('getPlayerPoolProfileByAddress Error:', error);
    }
  }, []);

  const fetchProfile = async (): Promise<void> => {
    if (id) {
      try {
        handleGetUserGameInfo(id as string);
        // get view profile

        if (isProfileAddress) {
          const res = await getPlayerPoolProfile(id as string);
          setPlayerPoolProfile(res);
        } else {
          const res = await getListMentionByKeyWord(
            addressL2 as string,
            id as string
          );
          if (!res) return;
          const { address } = res[0];
          const res2 = await getPlayerPoolProfile(address);
          setPlayerPoolProfile(res2);
        }
      } catch (error) {
        console.log('getPlayerPoolProfileByAddress Error:', error);
        router.push(ROUTE_PATH.ALPHA_MOBILE_HOME);
      } finally {
        setIsProfileLoading(false);
      }
    } else {
      setIsProfileLoading(false);
    }
  };

  const handleCheckTokenHolding = async (_id: string) => {
    if (`${addressL2}`.toLowerCase() === `${_id}`.toLowerCase()) {
      setIsTokenHolding(true);
      setIsTokenHoldingLoading(false);
      setKeyBalance(1);
    } else {
      try {
        const res = await getTokenHolding({
          address: addressL2 as string,
          player_address: _id as string,
        });

        console.log('___RES holding: ', res);

        if (res.length === 1) {
          setKeyBalance(Number(res[0].balance));
          const ftBalance = Number(res[0].ftBalance || '0');

          if (ftBalance && ftBalance > 0) {
            setIsTokenHolding(true);
            return;
          }
          if (
            Number(res[0]?.balance) >=
            Number(playerPoolProfile?.minHoldingRequirement)
          ) {
            setIsTokenHolding(true);
          } else {
            setKeyRemaining(
              Number(playerPoolProfile?.minHoldingRequirement) -
                Number(res[0]?.balance)
            );
          }
        }
      } finally {
        setIsTokenHoldingLoading(false);
      }
    }
  };

  const handleCheckTokenHolded = async (_id: string) => {
    if (compareString(_id, addressL2)) {
      return;
    }

    try {
      const res = await getTokenHolding({
        address: _id as string,
        player_address: addressL2 as string,
      });
      if (res.length === 1) {
        setIsTokenHolded(true);
      }
    } finally {
    }
  };

  const getListHoldersReview = async (_id: string) => {
    try {
      const res = await getTokenHolders({
        address: _id as string,
        page: 1,
        limit: 3,
      });
      setHolderList(res);
    } finally {
    }
  };

  useEffect(() => {
    const verifyYourTokenHolding = async () => {
      setIsTokenHoldingLoading(true);
      if (addressL2 && id) {
        if (isProfileAddress) {
          handleCheckTokenHolding(id as string);
          handleCheckTokenHolded(id as string);
          getListHoldersReview(id as string);
        } else {
          const res = await getListMentionByKeyWord(
            addressL2 as string,
            id as string
          );
          if (!res || !res.length) return;
          const { address } = res[0];
          handleCheckTokenHolding(address);
        }
      }
    };
    if (playerPoolProfile) {
      verifyYourTokenHolding();
    }
  }, [id, addressL2, playerPoolProfile]);

  const fetchToken = async () => {
    try {
      const res: any = await getPlayerTokenDetail({
        address: id as string,
      });
      setPlayerTokenDetail(res);
    } catch (error) {
      console.log('getPlayerToken error: ', error);
    } finally {
      //
    }
  };

  const isFTToken = useMemo(() => {
    const compareToken = (token: any) => {
      return isPlatformTradeKey(token) === SupportPlatform.FT;
    };
    return compareToken(playerPoolProfile) || compareToken(playerTokenDetail);
  }, [playerTokenDetail, playerPoolProfile]);

  useEffect(() => {
    const fetchData = () => {
      fetchProfile();
      fetchToken();
    };

    fetchData();

    // const intervalId = setInterval(fetchData, 10000);
    // return () => clearInterval(intervalId);
  }, [id]);

  const values = useMemo(() => {
    return {
      isProfileLoading,
      setIsProfileLoading,
      playerPoolProfile,
      setPlayerPoolProfile,
      showCreatePost,
      setShowCreatePost,
      userGameInfo,
      setUserGameInfo,
      yourPoolProfile,
      playerTokenDetail,
      isTokenHolding,
      isTokenHolded,
      isTokenHoldingLoading,
      keyBalance,
      keyRemaining,
      isFTToken,
      holderLists,
    };
  }, [
    isProfileLoading,
    setIsProfileLoading,
    playerPoolProfile,
    setPlayerPoolProfile,
    showCreatePost,
    setShowCreatePost,
    userGameInfo,
    setUserGameInfo,
    yourPoolProfile,
    playerTokenDetail,
    isTokenHolding,
    isTokenHolded,
    isTokenHoldingLoading,
    keyBalance,
    keyRemaining,
    isFTToken,
    holderLists,
  ]);

  return (
    <profilesContext.Provider value={values}>
      {children}
    </profilesContext.Provider>
  );
}

export function useProfiles() {
  return useContext(profilesContext);
}
