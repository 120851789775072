import React from 'react';

const ReactionBue = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_482_3089)">
        <path
          d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1876 13.065 14.1902C11.8407 15.1927 10.3466 15.8095 8.77168 15.9625C7.1968 16.1155 5.61192 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.93838 11.7598C0.194739 10.3629 -0.11794 8.77668 0.0399006 7.20196C0.197741 5.62724 0.819008 4.13472 1.8251 2.91323C2.8312 1.69174 4.17692 0.796164 5.692 0.339808C7.20708 -0.116546 8.82344 -0.113175 10.3366 0.349495C11.3414 0.65488 12.276 1.15549 13.0871 1.82267C13.8982 2.48984 14.5696 3.31048 15.0632 4.2376C15.5567 5.16472 15.8626 6.18008 15.9632 7.2256C16.0639 8.27108 15.9574 9.32616 15.6498 10.3304Z"
          fill="#13527E"
        />
        <path
          d="M7.38093 7.1321C7.38093 7.00111 7.35577 6.93088 6.90952 6.8692C6.56718 6.82172 2.2742 7.00396 2.01607 7.01821C1.51732 7.04667 1.32373 7.22228 1.32811 7.5412C1.33139 7.81929 1.69889 8.05753 1.78639 8.17237C1.87389 8.28721 1.86732 8.39446 1.75904 8.51501C1.67592 8.60804 1.32373 8.73521 1.32373 9.08544C1.32373 9.51543 1.64748 9.73183 2.01607 9.76029C2.59576 9.80397 5.25031 9.84382 5.93281 9.88558C6.26202 9.90551 6.46327 10.0327 6.79248 10.0536C6.94343 10.0536 7.38093 9.73751 7.38093 9.60655C7.38093 9.47649 7.38093 7.26307 7.38093 7.1321Z"
          fill="#72B53D"
        />
        <path
          d="M14.4587 7.82617C14.4587 4.62587 12.5205 4.46837 12.5205 4.46837C12.5216 4.43774 12.6223 2.57618 10.7705 2.17478C9.26334 1.84775 8.30193 2.45368 7.82068 2.99837C6.91834 2.52697 5.62226 2.66915 5.02617 3.6579C4.71226 4.17962 4.75163 4.98025 4.95834 5.55229C5.16617 6.12867 5.57851 6.48525 5.98647 6.94133C7.16226 8.25821 6.74443 8.76025 6.1363 9.57617C5.76005 10.0804 4.9288 11.5898 6.2063 12.9876C7.10538 13.9709 9.08068 13.4207 9.08068 13.4207C9.22617 13.4645 10.5223 14.4696 11.9627 13.5159C13.5005 12.4976 12.9001 10.5879 12.9001 10.5879C12.9001 10.5879 14.4587 9.81462 14.4587 7.82617Z"
          fill="#DCFFCB"
        />
        <path
          d="M8.67491 10.4857C8.76129 10.4605 8.76567 10.3402 8.67929 10.3118C8.29646 10.1871 7.60521 9.89399 7.19616 9.30991C6.79912 8.74337 6.55958 7.39478 7.381 6.78666C8.5885 5.89087 8.39491 4.87366 8.09083 4.49633C7.77366 4.10258 6.92491 4.17915 6.93804 4.86712C6.94021 4.99399 6.97741 5.11866 7.031 5.23353C7.171 5.53541 7.38866 5.9882 7.08567 6.28462C6.94129 6.4257 6.73021 6.46837 6.53225 6.43008C6.06412 6.33929 5.70866 5.97399 5.52162 5.54962C4.98129 4.32133 5.43958 3.58086 6.08379 2.93993C6.11771 2.90602 6.08271 2.84805 6.03679 2.86336C5.23067 3.12696 4.83254 3.7843 4.80083 4.51929C4.77787 5.05962 4.88616 5.60212 5.17271 6.06041C5.39366 6.41366 5.76554 6.71774 6.01271 7.05241C6.20521 7.31274 6.5935 7.63429 6.53225 7.99195C6.47317 8.33758 6.21179 8.64274 6.00396 8.90741C5.41225 9.66103 5.10708 10.6804 5.34991 11.6068C5.79942 13.3218 7.13491 13.534 7.57679 13.557C7.61179 13.5592 7.62379 13.5088 7.59208 13.4968C7.14146 13.3262 6.87567 13.1501 6.76958 13.0637C5.93616 12.3801 5.74037 11.1955 6.13083 10.1882C6.15929 10.1138 6.20521 10.046 6.26866 9.99679C6.54208 9.78462 6.74442 10.1904 7.20491 10.419C7.73754 10.6826 8.33475 10.583 8.67491 10.4857Z"
          fill="#72B53D"
        />
        <path
          d="M12.3256 4.89588C12.6592 4.56996 12.4886 3.42152 12.0007 2.88667C11.8629 2.73574 11.6113 2.85167 11.6299 3.05511C11.6595 3.39636 11.7174 3.89402 11.5184 4.40699C11.4036 4.70338 11.5063 4.95825 11.6967 5.04575C11.7896 5.08838 12.0532 5.16275 12.3256 4.89588Z"
          fill="#72B53D"
        />
        <path
          d="M14.3698 8.49235C14.4168 8.28781 14.4431 8.0964 14.4551 7.92252C14.4606 7.83939 14.3446 7.8164 14.3195 7.89515C14.1773 8.35015 13.8678 8.94515 13.1962 9.30173C12.9763 9.41877 12.7335 9.48877 12.5104 9.59923C12.2228 9.74252 11.8334 9.88031 11.8399 10.2642C11.8498 10.7925 12.6865 10.8351 13.3187 10.3167C13.8863 9.84969 14.2221 9.13327 14.3698 8.49235Z"
          fill="#72B53D"
        />
        <path
          d="M12.2174 13.3343C12.2787 13.2862 12.2262 13.1921 12.1529 13.2173C11.4693 13.447 10.6862 13.3824 10.0562 13.027C9.91945 12.9493 9.57708 12.6999 9.21945 12.6726C8.99303 12.6551 8.7907 12.8312 8.80599 13.0576C8.81583 13.2107 8.90553 13.3507 9.01928 13.4535C9.32553 13.7303 9.78708 13.8473 10.1874 13.8943C10.6872 13.9534 11.2188 13.8713 11.6869 13.6592C11.9024 13.5618 12.0807 13.4426 12.2174 13.3343Z"
          fill="#72B53D"
        />
        <path
          d="M2.94532 5.87568C3.304 5.73006 3.63727 5.49453 3.83273 5.16027C4.0282 4.82701 4.06534 4.38623 3.86596 4.05491C3.66659 3.7236 3.2092 3.55159 2.86322 3.72457C2.60618 3.85358 2.53581 4.09889 2.66287 4.38916C2.76646 4.62567 2.94727 4.66672 2.91209 4.9746C2.86713 5.37138 2.37651 5.71149 2.14391 5.80922C1.94649 5.89132 1.60246 6.14054 2.03151 6.11024C2.33351 6.09069 2.6658 5.98905 2.94532 5.87568Z"
          fill="#DCFFCB"
        />
        <path
          d="M4.15443 11.6992C4.25216 11.9465 4.39094 12.5857 3.81823 12.6658C3.35888 12.7303 3.09305 12.228 2.98945 11.7852C2.85067 11.193 2.33855 10.8851 2.10399 10.6642C2.0561 10.6193 2.08444 10.5391 2.1509 10.5342C2.57506 10.502 3.69313 10.5362 4.15443 11.6992Z"
          fill="#DCFFCB"
        />
        <path
          d="M8.70983 2.68755C8.56546 2.80458 8.4222 2.96537 8.29312 3.0988C8.2472 3.14583 8.02845 3.43458 7.81733 3.25193C7.71562 3.16333 7.81404 2.94787 7.84687 2.88771C7.97375 2.65583 8.36421 2.23365 9.17796 2.06083C9.44704 2.00396 10.2258 2.01599 10.2258 2.09365C10.2258 2.19865 9.31904 2.18989 8.70983 2.68755Z"
          fill="#72B53D"
        />
        <path
          d="M4.79101 7.58984H1.36865C1.42006 7.71234 1.50975 7.82392 1.59506 7.91797H4.79101C4.88177 7.91797 4.95506 7.84468 4.95506 7.75392C4.95506 7.66313 4.88177 7.58984 4.79101 7.58984Z"
          fill="#DCFFCB"
        />
        <path
          d="M5.74904 8.55908H1.72512C1.61574 8.65642 1.39699 8.74612 1.34668 9.01517H5.74904C5.89996 9.01517 6.02246 8.89158 6.02246 8.74063C6.02246 8.58971 5.89996 8.55908 5.74904 8.55908Z"
          fill="#DCFFCB"
        />
      </g>
      <defs>
        <clipPath id="clip0_482_3089">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

    // <svg
    //     width="17"
    //     height="16"
    //     viewBox="0 0 17 16"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <g clip-path="url(#clip0_460_101)">
    //       <path
    //         d="M15.9955 10.3304C15.5346 11.8444 14.635 13.1876 13.4107 14.1902C12.1864 15.1927 10.6923 15.8095 9.11738 15.9625C7.5425 16.1155 5.95762 15.7979 4.56326 15.0498C3.1689 14.3017 2.02772 13.1568 1.28408 11.7598C0.540442 10.3629 0.227763 8.77668 0.385604 7.20196C0.543444 5.62724 1.16471 4.13472 2.17081 2.91323C3.1769 1.69174 4.52262 0.796164 6.0377 0.339808C7.55278 -0.116546 9.16914 -0.113175 10.6823 0.349495C11.6871 0.65488 12.6217 1.15549 13.4328 1.82267C14.2439 2.48984 14.9153 3.31048 15.4089 4.2376C15.9024 5.16472 16.2083 6.18008 16.3089 7.2256C16.4096 8.27108 16.3031 9.32616 15.9955 10.3304Z"
    //         fill="#2E9BFF"
    //       />
    //       <path
    //         d="M11.4783 7.43293C11.2521 7.42837 11.0324 7.35673 10.847 7.22713C10.6616 7.09753 10.5188 6.91577 10.4367 6.70497C10.3547 6.49413 10.337 6.26365 10.386 6.04277C10.435 5.82189 10.5484 5.62053 10.712 5.46417C10.8755 5.30785 11.0817 5.20357 11.3046 5.16457C11.5274 5.12557 11.7568 5.15361 11.9636 5.24513C12.1705 5.33661 12.3456 5.48749 12.4667 5.67861C12.5877 5.86973 12.6494 6.09249 12.6438 6.31865C12.6363 6.62077 12.5094 6.90765 12.291 7.11645C12.0726 7.32529 11.7804 7.43909 11.4783 7.43293Z"
    //         fill="black"
    //       />
    //       <path
    //         d="M3.56554 7.14204L3.28369 5.6582L6.74201 5.84868L6.65633 6.80108L3.56554 7.14204Z"
    //         fill="black"
    //       />
    //       <path
    //         d="M11.8647 10.1386C11.8738 10.6569 11.7506 11.1689 11.5067 11.6262C11.2774 12.0486 10.9343 12.3983 10.5165 12.6357C10.0956 12.8776 9.0425 12.9976 8.50734 12.9976C7.97222 12.9976 6.60298 12.8776 6.17642 12.6357C5.75802 12.3973 5.4149 12.0462 5.18614 11.6224C4.94226 11.1651 4.81902 10.653 4.8281 10.1348V8.30811H11.8647V10.1386ZM5.82982 10.1062C5.8247 10.4397 5.90886 10.7685 6.07358 11.0586C6.2303 11.3317 6.45458 11.5599 6.72486 11.7215C6.9991 11.8853 8.18934 11.9671 8.50734 11.9671C8.82538 11.9671 9.69378 11.8853 9.96802 11.7215C10.243 11.5586 10.4707 11.3269 10.6288 11.0491C10.7935 10.759 10.8777 10.4302 10.8726 10.0967V9.33479H5.82982V10.1062Z"
    //         fill="black"
    //       />
    //     </g>
    //     <defs>
    //       <clipPath id="clip0_460_101">
    //         <rect
    //           width="16"
    //           height="16"
    //           fill="white"
    //           transform="translate(0.345703)"
    //         />
    //       </clipPath>
    //     </defs>
    //   </svg>
  );
};

export default ReactionBue;
