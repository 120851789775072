import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import { PERP_API_URL } from '@/configs';
import { apiClient } from '@/services';
import s from './Feed.module.scss';
import FeedItem from './FeedItem';
import { FeedDataType } from './types';
import Empty from './Empty';
import InfiniteScroll from 'react-infinite-scroll-component';
import useApiInfinite from '@/hooks/useApiInfinite';
import AppLoading from '@/components/AppLoading';
import NewestFeedItem from '@/modules/AlphaPWA/Notification/NewestFeedItem';
import cs from 'clsx';

const JackpotFeed = () => {
  const router = useRouter();
  const keyId = `global-notify${router?.pathname?.replaceAll('/', '-')}`;
  const infiniteScrollRef = useRef<any>(null);

  const [initialScrollYSaved, setInitialScrollYSaved] = useState<number>(
    Number(sessionStorage.getItem(keyId)) || 0
  );

  const getFeedData = async ({ page = 1, limit = 50 }) => {
    try {
      const rs: FeedDataType[] = await apiClient.get(
        `${PERP_API_URL}/api/player-share/activities?network=nos&address=&page=${page}&limit=${limit}`
      );
      return rs;
    } catch (error) {
      console.log({ error });
    }
    return [];
  };

  const {
    dataInfinite,
    loadMore,
    refresh,
    isLoadingMore,
    isReachingEnd,
    hasFirstFetching,
    isEmpty,
    isRefreshing,
  } = useApiInfinite(
    getFeedData,
    { key: keyId, limit: 50 },
    { revalidateOnFocus: true }
  );

  const handleRouteChangeStart = (url: string) => {
    if (url === ROUTE_PATH.ALPHA_MOBILE_HOME) {
      // scroll to top
      sessionStorage.setItem(keyId, '0');
      setInitialScrollYSaved(0);
      if (infiniteScrollRef?.current) {
        infiniteScrollRef?.current?.el?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
      // reload data
      refresh();
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events]);

  return (
    <div
      className={cs(s.feed_wrapper, {
        [s.feed_wrapper__global]: true,
      })}
    >
      {hasFirstFetching === false ? (
        <div className={s.loadingMore}>
          <AppLoading />
        </div>
      ) : (
        <InfiniteScroll
          ref={infiniteScrollRef}
          className="disable-scrollbar-x"
          height="calc(100dvh - 130px)"
          dataLength={dataInfinite?.length || 500}
          hasMore={!isReachingEnd}
          loader={
            isLoadingMore && (
              <div className={s.loadingMore}>
                <AppLoading />
              </div>
            )
          }
          initialScrollY={initialScrollYSaved}
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={100}
          next={loadMore}
          onScroll={(event: any) => {
            sessionStorage.setItem(keyId, event?.srcElement?.scrollTop);
          }}
          scrollableTarget="scrollableDiv"
        >
          {isRefreshing && (
            <div className={s.loadingMore}>
              <AppLoading />
            </div>
          )}
          {dataInfinite &&
            dataInfinite.length > 0 &&
            dataInfinite?.map((item: any, index) =>
              item.activity === 'issue_token' ||
              item.activity === 'give_away_deposit' ||
              item.activity === 'give_away_receive' ? (
                <div key={index}>
                  <NewestFeedItem item={item} />
                </div>
              ) : (
                <div key={index}>
                  <FeedItem item={item} />
                </div>
              )
            )}
          {isEmpty && <Empty />}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default React.memo(JackpotFeed);
