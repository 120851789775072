import localStorage from '@/utils/localstorage';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import ListTable, { ColumnProp } from '@/components/ListTable';
import { ROUTE_PATH } from '@/constants/route-path';
import { AssetsContext } from '@/contexts/assets-context';
import {
  IBodyBuySweetFloorKeys,
  IResponseBuySweetFloorKeys,
} from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import useDebounce from '@/hooks/useDebounce';
import KeyPresaleIcon from '@/modules/AlphaPWA/Notification/svg/KeyPresaleIcon';
import { IParamsGetKeys, ITradeKey } from '@/services/interfaces/trade';
import {
  requestReload,
  selectCommonReducer,
  setDPSelectKeys,
  setFilterKeyParams,
} from '@/state/common';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
} from '@/utils';
import px2rem from '@/utils/px2rem';
import { BigNumber } from 'ethers';
import { clone, uniqBy } from 'lodash';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import KeyIcon from '../../BuyAKey/KeyIcon';
import Avatar from '../components/avatar';
import HorizontalItem from '../components/horizontalItem';
import { showError } from '../components/toast';
import styles from '../styles.module.scss';
import Buying from './buying';
import Footer from './footer';
import ProTradeKeyHeader from './header';

import ScrollWrapper from '@/components/ScrollWrapper/ScrollWrapper';
import { POPUP_REFERRAL_THROTTLE_TIME } from '@/constants/storage-key';
import { WalletContext } from '@/contexts/wallet-context';
import ProKeys from '@/modules/AlphaPWA/Keys/pro/key';
import ModalAmount from '@/modules/AlphaPWA/Profiles/TradeKey/pro/ModalAmount';
import { useOverlay } from '@/providers/OverlayProvider';
import getFirebaseApp from '@/services/firebase/base';
import { closeModal, openModal } from '@/state/modal';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import {
  delay,
  getErrorMessageContract,
  getUrlAvatarTwitter,
  settingMomentFromNow,
} from '@/utils/helpers';
import storageLocal from '@/utils/storage.local';
import { useWeb3React } from '@web3-react/core';
import { getDatabase, ref } from 'firebase/database';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { useList } from 'react-firebase-hooks/database';
import { BsClock } from 'react-icons/bs';
import IcTwitter from '../assets/icons/icTwitter';
import WrapButtonSubmit from '../components/wrapButtonNetwork';
import {
  DEFAULT_SLIDE,
  KEY_FILTER,
  KEY_SELECT,
  MAX_DECIMAL,
  MIN_DECIMAL,
  SLIDE_FT,
  labelAmountOrNumberAdds,
} from '../constants';
import { SORTS } from './itemFilterSort';
import { FILTER_TYPES } from './itemFilterTypes';

settingMomentFromNow();

export const CHOOSE_AMOUNT_3_3_MODAL_KEY = 'CHOOSE_AMOUNT_3_3_MODAL_KEY';

const firebaseApp = getFirebaseApp();

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(firebaseApp);

const ProTradeKeyModule = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { balanceL2 } = useContext(AssetsContext);
  const hasIncrementedPageRef = useRef(false);
  const { addressL2 } = useContext(WalletContext);

  const DEFAULT_LIMIT = useMemo(() => (isMobile ? 10 : 30), [isMobile]);
  const [snapshots, loading, error] = useList(ref(database, 'token'));

  const debounceSnapshot = useDebounce(snapshots, 4000);

  const {
    getTradeKeys,
    estimateTCMultiKeys,
    buySweetFloorKeys,
    requestTrade33Keys,
    estimateBothSide,
    canTradeFT,
  } = useTradeKey();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openOverlayScreen } = useOverlay();
  const { isActive, account } = useWeb3React();

  const { needReload, filterKeyParams, selectKeys } =
    useSelector(selectCommonReducer);

  const setSelectKeys = (e: any) => dispatch(setDPSelectKeys(e));

  const [isFetching, setIsFetching] = useState(true);
  const [keys, setKeys] = useState<ITradeKey[]>([]);
  const [numKeys, setNumKeys] = useState<number>(0);
  const refParams = useRef<IParamsGetKeys>({
    page: 1,
    limit: DEFAULT_LIMIT,
    key_type: '1',
    side: DEFAULT_SLIDE,
    ...queryString.parse(filterKeyParams),
  });
  const [estimateTC, setEstimateTC] = useState<string>('0');
  const [estimateKeysTC, setEstimateKeysTC] = useState<any[]>([]);
  const [estimateFT, setEstimateFT] = useState<string>('0');
  const [estimateKeysFT, setEstimateKeysFT] = useState<any[]>([]);
  const [estimating, setEstimating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [allowBuy, setAllowBuy] = useState(true);
  const [waitKeys, setWaitKeys] = useState<IResponseBuySweetFloorKeys[]>([]);
  const [numProcessDone, setNumProcessDone] = useState(0);
  const [searching, setSearching] = useState(false);
  const [filtering, setFiltering] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker();
  const selectKeysDebounced = useDebounce(selectKeys);
  const numProcessDoneDebounced = useDebounce(numProcessDone);

  const refFooter = useRef<any>();
  const refDone = useRef<any>(false);
  const refInitial = useRef(false);
  const [isInvite, setIsInvite] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [estimateTC33, setEstimateTC33] = useState<string>('0');
  const [selectKeys33, setSelectKeys33] = useState<IBodyBuySweetFloorKeys[]>(
    []
  );
  const [estimateKeysTC33, setEstimateKeysTC33] = useState<any[]>([]);
  const [realTimeData, setRealTimeData] = useState<ITradeKey[]>([]);

  const params = router.query;

  // useEffect(() => {
  //   const list = snapshots?.map(v => {
  //     return v.val();
  //   });

  //   if (
  //     list &&
  //     list?.length > 0 &&
  //     compareString(refParams?.current?.sort_col, DESKTOP_SORTS[0].key)
  //   ) {
  //     console.log('refParams', refParams.current);
  //     let slide = [SLIDE_ALPHA, SLIDE_FT];

  //     if (compareString(refParams.current.side, SLIDE_ALPHA)) {
  //       slide = [SLIDE_ALPHA];
  //     } else if (compareString(refParams.current.side, SLIDE_FT)) {
  //       slide = [SLIDE_FT];
  //     }

  //     const _list = uniqBy(
  //       [
  //         ...(list as ITradeKey[])
  //           .filter(v => slide.includes(Number(v.side)))
  //           .sort((a, b) =>
  //             moment(a.created_at).unix() < moment(b.created_at).unix() ? 1 : -1
  //           ),
  //         ...realTimeData,
  //       ],
  //       item => item.address
  //     );

  //     setRealTimeData(_list);
  //   }
  // }, [debounceSnapshot, refParams.current]);

  const openReferralModal = () => {
    localStorage.set(
      POPUP_REFERRAL_THROTTLE_TIME,
      `${new Date().toISOString()}`
    );
    openOverlayScreen('SHARE_REFERRAL');
    gaEventTracker(GameFiActions.PopupReferralOpenModal, addressL2);
  };

  const showSuccessInform = () => {
    try {
      const lastShowPopupReferral = localStorage.get(
        POPUP_REFERRAL_THROTTLE_TIME
      );
      if (lastShowPopupReferral) {
        const lastTime = moment(lastShowPopupReferral as any);
        const hoursAgo = moment().diff(lastTime, 'hours');
        if (Math.abs(hoursAgo) >= 3) {
          openReferralModal();
        }
      } else {
        openReferralModal();
      }
    } catch (e) {
      //
    }
  };

  const chekRenderLayout = async (params: any) => {
    await delay(1000);

    if (params.idUser) {
      const element = document.getElementById(
        `user_${params.idUser as string}`
      );
      if (element) {
        element.scrollIntoView({ block: 'center' });
      }
      router.replace({
        query: { ...router.query, idUser: [] },
      });
    }
  };

  useEffect(() => {
    if (!refInitial.current) {
      const localParams = storageLocal.get(KEY_FILTER);
      const localSelects = storageLocal.get(KEY_SELECT);
      if (localParams) {
        const _params = queryString.parse(localParams);
        refParams.current = {
          ...refParams.current,
          ..._params,
        };

        dispatch(setFilterKeyParams(localParams));
      }
      if (localSelects) {
        setSelectKeys(localSelects);
      }

      let isNew = true;
      let pageLimit = DEFAULT_LIMIT;
      if (params.pageHistory) {
        // pageLimit = DEFAULT_LIMIT * (Number(params.pageHistory) + 1);
        refParams.current = {
          ...refParams.current,
          limit: pageLimit,
        };
        isNew = false;
      }
      fetchData(isNew);
      chekRenderLayout(params);
    }
  }, [params, needReload]);

  const onFilter = (_params: any) => {
    if (isFetching || submitting || refreshing || searching || filtering) {
      return true;
    }
    refParams.current = {
      ...refParams.current,
      page: 1,
      ..._params,
    };
    setRealTimeData([]);
    dispatch(setFilterKeyParams(queryString.stringify(_params)));
    setFiltering(true);
    fetchData(true);
  };

  const fetchData = async (isNew?: boolean) => {
    try {
      setIsFetching(true);
      const response = await getTradeKeys(refParams.current);

      if (isNew || response.rows.length > DEFAULT_LIMIT) {
        setKeys(response.rows);
        refParams.current = {
          ...refParams.current,
          page: 1,
        };

        if (response.rows.length > DEFAULT_LIMIT) {
          refParams.current = {
            ...refParams.current,
            page: response.rows.length / DEFAULT_LIMIT,
            limit: DEFAULT_LIMIT,
          };
        }
      } else {
        setKeys(_keys => [..._keys, ...response.rows]);
      }

      router.replace({
        query: { ...router.query },
      });
      setNumKeys(response.count);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsFetching(false);
      setSearching(false);
      setFiltering(false);
      hasIncrementedPageRef.current = false;
      refInitial.current = true;
    }
  };

  const onCheckBox = (data: ITradeKey) => {
    const _selectKeys: IBodyBuySweetFloorKeys[] = clone(selectKeys);
    const _findIndex = _selectKeys.findIndex(v =>
      compareString(v.token_address, data.address)
    );
    if (_findIndex >= 0) {
      _selectKeys.splice(_findIndex, 1);
    } else {
      _selectKeys.push({
        token_address: data.address as string,
        token_amount: 1,
        amount_after_fee: 0,
      });
    }
    setSelectKeys(_selectKeys);
    refFooter.current?.setNumSweep(_selectKeys.length);
  };

  const handleGoToProfile = async (data: ITradeKey) => {
    router.replace({
      query: { ...router.query, idUser: data?.user_twitter_id },
    });
    await delay(500);
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`);
  };

  const columns: ColumnProp[] = useMemo(() => {
    return [
      {
        id: 'player',
        label: 'Key',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey, extraData: any, index: number) {
          const isChecked =
            selectKeys.findIndex(v =>
              compareString(v.token_address, data.address)
            ) >= 0;

          const renderDesc = () => {
            if (refParams.current?.sort_col === 'created_at') {
              return (
                <>
                  <BsClock fontSize={'10px'} opacity={'70%'} />
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    {`${moment(data?.created_at).fromNow()}`}
                  </Text>
                </>
              );
            }
            if (refParams.current?.sort_col === 'latest_online') {
              return (
                <>
                  <BsClock fontSize={'10px'} opacity={'70%'} />
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    {`${moment(data?.latest_online).fromNow()}`}
                  </Text>
                </>
              );
            }
            return (
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                Vol{' '}
                {formatCurrency(
                  data?.total_volume,
                  0,
                  2,
                  data.base_token_symbol
                )}{' '}
                {data.base_token_symbol}
              </Text>
            );
          };

          return (
            <Flex
              gap={3}
              alignItems={'center'}
              className={submitting && isChecked ? styles.isProcessing : ''}
              width={'100%'}
              id={`user_${data.user_twitter_id}`}
            >
              <Box className={styles.checkboxContainer}>
                <Checkbox
                  onChange={() => onCheckBox(data)}
                  isChecked={isChecked}
                />
              </Box>
              <Flex width={'100%'} flex={1} gap={2} alignItems={'center'}>
                <Avatar
                  url={getUrlAvatarTwitter(
                    (data?.user_twitter_avatar || data?.avatar) as string,
                    'normal',
                    Boolean(data?.user_twitter_avatar)
                  )}
                  address={data?.owner}
                  width={32}
                  onClick={() => onCheckBox(data)}
                />
                <Flex width={'100%'} gap={2} direction={'column'}>
                  <Flex
                    onClick={() => handleGoToProfile(data)}
                    gap={2}
                    alignItems={'center'}
                    maxWidth={'100%'}
                    width={'90%'}
                    flex={1}
                  >
                    <Text className={styles.buyer}>
                      {formatName(data?.user_twitter_name as string, 50)}
                    </Text>
                    <Flex
                      className={clsx(
                        styles.keyContainer,
                        data.is_pre_sale && styles.keyContainerPresale
                      )}
                    >
                      {data.is_pre_sale ? <KeyPresaleIcon /> : <KeyIcon />}
                    </Flex>
                  </Flex>
                  <Flex alignItems={'center'} gap={1}>
                    {renderDesc()}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        },
      },
      {
        id: 'tradingVolume',
        label: 'Price',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey) {
          return (
            <Flex
              onClick={e => onCheckBox(data)}
              gap={2}
              direction={'column'}
              alignItems={'flex-end'}
            >
              <Text className={styles.buyer}>
                {formatCurrency(data?.price, 0, 2, data.base_token_symbol)}{' '}
                {data.base_token_symbol}
              </Text>
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                ${formatCurrency(data?.usd_price, 2, 2)}
              </Text>
            </Flex>
          );
        },
      },
      {
        id: 'twitter_followers_count',
        label: (
          <Flex gap={1}>
            <IcTwitter />
          </Flex>
        ),
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: ITradeKey) {
          return (
            <Flex
              onClick={e => onCheckBox(data)}
              gap={2}
              direction={'column'}
              alignItems={'flex-end'}
            >
              <Text className={styles.buyer}>
                {abbreviateNumber(data.twitter_followers_count)}
              </Text>
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'0%'}>
                a
              </Text>
            </Flex>
          );
        },
      },
    ];
  }, [selectKeys, submitting, refParams.current]);

  const onEstimateTC = async () => {
    if (isFetching || submitting || refreshing || searching) {
      return true;
    }
    try {
      setEstimating(true);
      if (selectKeys.length > 0) {
        const response = await estimateBothSide(selectKeys);

        console.log('response', response);

        setEstimateTC(response?.nb?.total_tc_formatted);
        setEstimateFT(response?.ft?.total_tc_formatted);
        setEstimateKeysTC(response?.nb?.alphaTokens);
        setEstimateKeysFT(response?.ft?.alphaTokens);
        setAllowBuy(
          BigNumber.from(response?.nb?.total_tc).lt(balanceL2.amountBTC) ||
            BigNumber.from(response?.ft?.total_tc || '0').lt(
              balanceL2.amountETH
            )
        );
      } else {
        setEstimateTC('0');
        setAllowBuy(true);
        setEstimateKeysTC([]);
      }
    } catch (error) {
      setAllowBuy(false);
      setEstimateTC('0');
      setEstimateKeysTC([]);
    } finally {
      setEstimating(false);
    }
  };

  useEffect(() => {
    onEstimateTC();
  }, [
    selectKeysDebounced,
    needReload,
    account,
    canTradeFT,
    isFetching,
    submitting,
    refreshing,
    searching,
  ]);

  useEffect(() => {
    if (
      submitting &&
      numProcessDone === selectKeys.length &&
      !refDone.current
    ) {
      setIsDone(true);
      // showSuccessInform();
      // onClose();
    }
  }, [
    numProcessDoneDebounced,
    submitting,
    selectKeys,
    refFooter.current,
    refDone.current,
  ]);

  const onBeforeSubmit = (_isInvite: boolean) => {
    setIsInvite(_isInvite);
    if (_isInvite) {
      openChooseAmountFor33();
    } else {
      onOpen();
    }
  };

  const onHideModalChooseAmount33 = () => {
    dispatch(closeModal({ id: CHOOSE_AMOUNT_3_3_MODAL_KEY }));
  };

  const handleSubmit33 = () => {
    onHideModalChooseAmount33();
    onOpen();
  };

  const openChooseAmountFor33 = () => {
    dispatch(
      openModal({
        id: CHOOSE_AMOUNT_3_3_MODAL_KEY,
        theme: 'dark',
        // title: `Amount of keys`,
        className: styles.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => (
          <ModalAmount
            selectKeys={selectKeys}
            onConfirm={handleSubmit33}
            setSelectKeys33={setSelectKeys33}
            setEstimateKeysTC33={setEstimateKeysTC33}
            setEstimateTC33={setEstimateTC33}
          />
        ),
      })
    );
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      const nbSelectKeys = selectKeys;
      const txs = (await buySweetFloorKeys(
        nbSelectKeys
      )) as IResponseBuySweetFloorKeys[];

      setWaitKeys(txs);
    } catch (error: any) {
      showError(getErrorMessageContract(error));
    } finally {
      dispatch(requestReload());
      // setSubmitting(false);
    }
  };

  const onChangeSweep = (_numSweep: any) => {
    if (Number(_numSweep) <= keys.length) {
      const _selectKeys = keys.slice(0, Number(_numSweep));
      setSelectKeys(
        _selectKeys.map(v => ({
          token_address: v.address as string,
          token_amount: 1,
          side: v.side,
          key: _selectKeys,
        }))
      );
    }
  };

  const onRefresh = async () => {
    if (!refInitial.current || searching || isFetching) {
      return;
    }
    try {
      setRefreshing(true);
      refParams.current = {
        ...refParams.current,
        page: 1,
      };
      await fetchData(true);
    } catch (error) {
      console.log('refreshing err', error);
    } finally {
      setRefreshing(false);
    }
  };

  const amountKeys = isInvite
    ? selectKeys33?.length * selectKeys33[0]?.token_amount
    : selectKeys?.length * selectKeys[0]?.token_amount;

  const renderModalTotal = () => {
    if (estimateTC33 && isInvite) {
      return `${formatCurrency(estimateTC33, 0, 6)} BTC`;
    }

    let totals = [];

    if (Boolean(Number(estimateTC))) {
      totals.push(`${formatCurrency(estimateTC, 0, 6)} BTC`);
    }

    if (Boolean(Number(estimateFT))) {
      totals.push(`${formatCurrency(estimateFT, 0, 5)} ETH`);
    }

    if (totals.length > 0) {
      return totals.join(' + ');
    }

    return `${formatCurrency('0', 0, 6)} BTC`;
  };

  const _keys = useMemo(() => {
    return uniqBy([...realTimeData, ...(keys as [])], item => item.address);
  }, [keys, realTimeData]);

  return (
    <>
      <Flex
        className={clsx(styles.formContainer, styles.proTradeContainer)}
        direction={'column'}
        alignItems={'center'}
        gap={0}
        flex={1}
      >
        <>
          <ProTradeKeyHeader
            onFilter={onFilter}
            setSearching={setSearching}
            searching={searching}
            isNotAllowFilter={
              isFetching || refreshing || searching || submitting
            }
            sorts={SORTS}
            filters={FILTER_TYPES}
          />
          <ScrollWrapper
            onFetch={() => {
              refParams.current = {
                ...refParams.current,
                page: refParams.current.page + 1,
              };
              hasIncrementedPageRef.current = true;
              fetchData();
            }}
            isFetching={isFetching || refreshing}
            hasIncrementedPageRef={hasIncrementedPageRef}
            onFetchNewData={onRefresh}
          >
            <ListTable
              data={keys}
              columns={columns}
              showEmpty={!isFetching}
              emptyLabel={
                <Text textAlign={'center'}>
                  Your filters produced no results.
                  <br />
                  Try adjusting or clearing your filters to display better
                  results.
                </Text>
              }
              initialLoading={filtering}
              // onItemClick={e => onCheckBox(e)}
            />
          </ScrollWrapper>

          <Footer
            ref={refFooter}
            sweepMax={keys.length}
            selectKeys={selectKeys}
            onSubmit={onBeforeSubmit}
            estimateTC={estimateTC}
            estimating={estimating}
            submitting={submitting}
            onChangeSweep={onChangeSweep}
            allowBuy={allowBuy}
            numProcessDone={numProcessDone}
            isInvite={isInvite}
            showInvite={true}
          />
        </>
      </Flex>

      {isMobile ? (
        <Drawer
          closeOnEsc={!submitting}
          closeOnOverlayClick={!submitting}
          placement={isMobile ? 'bottom' : 'right'}
          onClose={onClose}
          isOpen={isOpen}
        >
          <DrawerOverlay />
          <DrawerContent
            className={clsx(
              styles.confirmModal,
              !isMobile && styles.desktopModal
            )}
          >
            <DrawerHeader borderBottomWidth="1px">
              {isInvite ? 'Sending (3,3) requests' : 'Buy keys'}
              {isDone && (
                <DrawerCloseButton
                  fontSize={'14px'}
                  mt={4}
                  mr={4}
                  onClick={() => {
                    refDone.current = true;
                    refFooter.current?.reset?.();
                    dispatch(requestReload());
                    onRefresh();
                    setSelectKeys([]);
                    setSubmitting(false);
                    setIsDone(false);
                    onClose();
                  }}
                />
              )}
            </DrawerHeader>
            <DrawerBody>
              {submitting || isDone ? (
                <Buying
                  keys={keys}
                  selectKeys={isInvite ? selectKeys33 : selectKeys}
                  waitKeys={waitKeys}
                  setNumProcessDone={setNumProcessDone}
                  isInvite={isInvite}
                />
              ) : (
                <>
                  <HorizontalItem
                    label={
                      <Text fontSize={px2rem(16)} fontWeight={500}>
                        Amount:
                      </Text>
                    }
                    value={`${formatCurrency(
                      amountKeys,
                      MIN_DECIMAL, MAX_DECIMAL
                    )} key${labelAmountOrNumberAdds(amountKeys)}`}
                  />
                  <Box py={4} />
                  <HorizontalItem
                    label={
                      <Text fontSize={px2rem(16)} fontWeight={500}>
                        Total:
                      </Text>
                    }
                    value={renderModalTotal()}
                  />
                  <Button onClick={onSubmit} className={styles.btnConfirm}>
                    {isInvite ? 'Send' : 'Confirm'}
                  </Button>
                  {isInvite && (
                    <Text>
                      (3,3) runs on a smart contract that arranges the key swap
                      transaction trustlessly and holds the keys for 30 days.
                    </Text>
                  )}
                </>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <Modal
          closeOnEsc={!submitting}
          closeOnOverlayClick={!submitting}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
          // className={clsx(
          //   styles.confirmModal,
          //   !isMobile && styles.desktopModal
          // )}
          >
            <ModalHeader borderBottomWidth="1px">
              {isInvite ? 'Sending (3,3) requests' : 'Buy keys'}
              {isDone && (
                <ModalCloseButton
                  fontSize={'14px'}
                  mt={4}
                  mr={4}
                  onClick={() => {
                    refDone.current = true;
                    refFooter.current?.reset?.();
                    dispatch(requestReload());
                    onRefresh();
                    setSelectKeys([]);
                    setSubmitting(false);
                    setIsDone(false);
                    onClose();
                  }}
                />
              )}
            </ModalHeader>
            <ModalBody>
              {submitting || isDone ? (
                <Buying
                  keys={keys}
                  selectKeys={isInvite ? selectKeys33 : selectKeys}
                  waitKeys={waitKeys}
                  setNumProcessDone={setNumProcessDone}
                  isInvite={isInvite}
                />
              ) : (
                <>
                  <HorizontalItem
                    label={
                      <Text fontSize={px2rem(16)} fontWeight={500}>
                        Amount:
                      </Text>
                    }
                    value={`${formatCurrency(
                      amountKeys,
                      MIN_DECIMAL, MAX_DECIMAL
                    )} key${labelAmountOrNumberAdds(amountKeys)}`}
                  />
                  <Box py={4} />
                  <HorizontalItem
                    label={
                      <Text fontSize={px2rem(16)} fontWeight={500}>
                        Total:
                      </Text>
                    }
                    value={renderModalTotal()}
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {submitting || isDone ? (
                <></>
              ) : (
                <>
                  <WrapButtonSubmit
                    className={styles.btnConfirm}
                    isDetectNetwork={
                      selectKeys.findIndex(v =>
                        compareString(v.side, SLIDE_FT)
                      ) > -1
                    }
                  >
                    <Button
                      width={'100%'}
                      onClick={onSubmit}
                      className={styles.btnConfirm}
                    >
                      {isInvite ? 'Send' : 'Confirm'}
                    </Button>
                  </WrapButtonSubmit>
                  {isInvite && (
                    <Text>
                      (3,3) runs on a smart contract that arranges the key swap
                      transaction trustlessly and holds the keys for 30 days.
                    </Text>
                  )}
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ProTradeKeyModule;
