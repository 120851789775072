import { API_FOC } from '@/configs';
import createAxiosInstance from './http-client';
import {
  ISendFCMTokenPayload,
  ISendFCMTokenResponse,
} from '@/interfaces/api/fcm';
import { camelCaseKeys } from '@trustless-computer/dapp-core';

const apiClient = createAxiosInstance({
  baseURL: API_FOC,
});

export const sendFCMToken = async (
  payload: ISendFCMTokenPayload
): Promise<ISendFCMTokenResponse | null> => {
  try {
    const { token, walletAddress } = payload;
    const res = await apiClient.post(
      `fcm/wallet-address/${walletAddress}/tokens/${token}`
    );
    return camelCaseKeys(Object(res));
  } catch (err) {
    return null;
  }
};

export const updateFcmToken = async ({
  token,
  walletAddress,
  signature,
}: {
  signature: string;
} & ISendFCMTokenPayload): Promise<ISendFCMTokenResponse | null> => {
  try {
    const res = await apiClient.put(
      `fcm/wallet-address/${walletAddress}/tokens/${token}`,
      undefined,
      {
        headers: { signature },
      }
    );
    return camelCaseKeys(Object(res));
  } catch (err) {
    return null;
  }
};


interface INewMessage {
  messages: string
}

export const getNewMessage = async ({ walletAddress }: { walletAddress: string }): Promise<INewMessage | undefined> => {
  try {
    const res = await apiClient.get(
      `fcm/receive-message/count?wallet_address=${walletAddress}`,
      {
        headers: {
          'accept': 'application/json',
          'x-api-key': 'ewogICJ0eXBlIjogInNlcnZpY2VfYWNjb3VudCIsCiAgInByb2plY3RfaWQiOiAibmJ'
        } as any,
      }
    );
    return camelCaseKeys(Object(res)) as INewMessage;
  } catch (err) {
    return undefined;
  }
};
