import React from 'react';

const BackSvg = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
    >
      <path
        d="M6.4001 10.8L1.6001 6L6.4001 1.2"
        stroke="black"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BackSvg;
