import React from 'react';

const IcX = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="20" height="20" rx="10" fill="black" />
      <path
        d="M14.2375 4.10938H16.2314L11.8764 9.09975L17 15.8906H12.9887L9.84675 11.7713L6.25144 15.8906H4.25675L8.91481 10.5525L4 4.10938H8.11369L10.9534 7.87369L14.2375 4.10938ZM13.5387 14.6946H14.6438L7.51244 5.24281H6.32781L13.5387 14.6946Z"
        fill="white"
      />
    </svg>
  );
};

export default IcX;
