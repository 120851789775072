import { apiClient } from '@/services/index';
import { PERP_API_URL } from '@/configs';
import qs from 'query-string';

export const createRaffle = async ({
  address,
  network = 'nos',
}: {
  address: string;
  network?: string;
  only_holder?: boolean;
}): Promise<any> => {
  try {
    const query = qs.stringify({
      address,
      network,
    });
    const res = await apiClient.post(
      `${PERP_API_URL}/api/alpha-keys/raffle/prize-wheel-address?${query}`
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getRaffle = async ({
  address,
  network = 'nos',
  raffleId,
}: {
  address: string;
  network?: string;
  raffleId: number;
}): Promise<any> => {
  try {
    const query = qs.stringify({
      address,
      network,
    });
    const res = await apiClient.get(
      `${PERP_API_URL}/api/alpha-keys/raffle/detail/${raffleId}?${query}`
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const joinRaffle = ({
  raffleId,
  address,
  network = 'nos',
}: {
  raffleId: string;
  address: string;
  network?: string;
}) => {
  const query = qs.stringify({
    address,
    network,
  });
  return apiClient.post(
    `${PERP_API_URL}/api/alpha-keys/raffle/join/${raffleId}?${query}`
  );
};

export const getRaffleJoining = ({
  raffleId,
  address,
  network = 'nos',
}: {
  raffleId: number;
  address: string;
  network?: string;
}): Promise<string> => {
  const query = qs.stringify({
    address,
    network,
  });
  return apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/raffle/join/${raffleId}?${query}`
  );
};

export const updateRaffle = async ({
  raffleId,
  tx_hash,
  address,
  expireTime,
  network = 'nos',
  only_holder = false,
  minHoldingRequirement,
  treasury_hash,
}: {
  raffleId: number;
  tx_hash: string;
  address: string;
  expireTime: number;
  minHoldingRequirement: number;
  only_holder?: boolean;
  network?: string;
  treasury_hash: string;
}): Promise<any> => {
  try {
    const query = qs.stringify({
      address,
      network,
    });
    const res = await apiClient.put(
      `${PERP_API_URL}/api/alpha-keys/raffle/fee/${raffleId}?${query}`,
      {
        tx_hash,
        treasury_hash,
        expire_time: expireTime,
        only_holder,
        min_holding_requirement: minHoldingRequirement,
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
};
