import { LanguageSupportedEnum, MessageTypeEnum } from '@/interfaces/api/chat';
import {
  ContentByLanguageEnum,
  ChatCommandEnum,
} from '@/modules/AlphaPWA/DirectMessage/types';
import { isProduction } from '@/utils/commons';
import sanitizeHtml from 'sanitize-html';

export const FAKE_ROOM_ID = 'FAKE_ROOM_ID';
export const ROOM_CHAT_ON_AVAILABLE_MESSAGE_LENGTH = 500;

export const SUPPORT_MESSAGE_TYPES = [
  MessageTypeEnum.NORMAL,
  MessageTypeEnum.ADD_MEMBER,
  MessageTypeEnum.CREATE_DONATION,
  MessageTypeEnum.CLAIM_DONATION,
  MessageTypeEnum.BUY_EVENT,
  MessageTypeEnum.WHEEL_EVENT,
  MessageTypeEnum.RAFFLE_EVENT,
  MessageTypeEnum.BULL_BEAR_EVENT,
  MessageTypeEnum.BULL_BEAR_JOIN_EVENT,
  MessageTypeEnum.GAME_EVENT,
  MessageTypeEnum.TIP_EVENT,
  // MessageTypeEnum.TIP_EVENT_OPENED,
];
export const ASYNC_LOAD_TIMEOUT = 100; //100ms

export const DEFAULT_LIMIT_GET_MESSAGE = 50;
export const DEFAULT_GET_ROOM_LIMIT = 20;

export const SLEEP_TIME_TO_SEND_NEW_MESSAGE = 5; //5seconds

export const CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS: Record<
  LanguageSupportedEnum,
  ContentByLanguageEnum
> = {
  [LanguageSupportedEnum.OFF]: ContentByLanguageEnum.OFF,
  [LanguageSupportedEnum.ENGLISH]: ContentByLanguageEnum.ENGLISH,
  [LanguageSupportedEnum.CHINESE_SIMPLIFIED]:
    ContentByLanguageEnum.CHINESE_SIMPLIFIED,
  [LanguageSupportedEnum.CHINESE_TRADITIONAL]:
    ContentByLanguageEnum.CHINESE_TRADITIONAL,
  [LanguageSupportedEnum.JAPANESE]: ContentByLanguageEnum.JAPANESE,
};

export const ROOM_CHAT_EVENT_SCROLLING: Record<string, boolean> = {};

let bruceToken = isProduction()
  ? '0x0865f75bd6e9524f64f38d726e420c37fc03a45c'
  : '0x658bf0f028d42ce1ea622fe845608797103d3865';

export const BRUCE_TOKEN_ADDRESS = bruceToken;

export const CHAT_COMMANDS: Record<ChatCommandEnum, string[]> = {
  chart: ['chart', 'Chart'],
  uni: ['uni'],
};

export const LOCAL_LAST_SEEN_STORAGE_KEY = 'chat-room-last-seen';

// allowedTags
export const ALLOWED_ATTRIBUTES = {
  '*': ['style'],
  span: ['class'],
  a: ['href', 'target'],
  img: ['src', 'width', 'height'],
};

export const TEXT_DIRTY_CONFIG = {
  // parseStyleAttributes: true,
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
  allowedAttributes: ALLOWED_ATTRIBUTES,
  allowedSchemes: ['data', 'http', 'https'],
};
