import { FC, useMemo, useState } from 'react';
import { PageProps } from './Page.types';
import * as formatter from 'tc-formatter';

const enhanLastItem =
  (WrappedComponent: FC<PageProps>) => (props: PageProps) => {
    const { walletList, data } = props;
    return (
      <WrappedComponent
        {...{
          ...props,
          data,
          walletList: [
            ...walletList,
            {
              btcAddress: '',
              amount: data?.totalAmount || 0,
              amountStr: formatter.formatAmount({
                originalAmount: data?.totalAmount || 0,
                decimals: 8,
                isCeil: true,
                maxDigits: 2,
              }),
              isLasted: true,
            },
          ],
        }}
      />
    );
  };

export default enhanLastItem;
