import PinChatSvg from '@/modules/AlphaPWA/DirectMessage/List/Icon/PinChatSvg';
import { DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';

import s from './style.module.scss';

const PinChat = ({ room }: { room: DMRoom }) => {
  // const handlePinChat = (e: any) => {
  //   e.stopPropagation();
  //   if (room.ownerToken) {
  //   }
  // };

  return (
    <span className={`${s.pinChat}`}>
      {room.pinned ? <PinChatSvg /> : <></>}
    </span>
  );
};

export default PinChat;
