import { isProduction } from '@/utils/commons';
import { Item } from './Page.types';
import * as formatter from 'tc-formatter';

export const formatAmount = (amount: number) => {
  return formatter.formatAmount({
    originalAmount: amount || '0',
    decimals: 8,
    isCeil: true,
    maxDigits: 2,
  });
};

export const getExplorerByNetwork = (network: string = 'nos') => {
  switch (network) {
    case 'nos':
      return isProduction() ? 'https://mempool.space' : 'https://mempool.space'; //'https://blockstream.regtest.trustless.computer'
    default:
      break;
  }
};

export const itemDecorator = (btcAddress: string, amount: number): Item => {
  const btcAddressShorten = formatter.ellipsisCenter({
    str: btcAddress,
    limit: 16,
  });

  const amountStr = formatAmount(amount || 0);
  const explorer = `${getExplorerByNetwork()}/address/${btcAddress}`;
  const isHide = !btcAddress || !amount;

  return {
    btcAddress,
    amount,
    btcAddressShorten,
    amountStr,
    explorer,
    isHide,
  };
};
