import React from 'react';
import s from './ModalGuideNoti.module.scss';

export const GUIDE_NOTI_MODAL = 'GUIDE_NOTI_MODAL';

const ModalGuideNoti = () => {
  return (
    <div className={s.modalGuideNoti}>
      <p className={s.text}>
        Please go to{' '}
        <b>Settings - Notifications - New Bitcoin City - Allow notifications</b> to ensure
        you don't miss out on anything.
      </p>
    </div>
  );
};

export default ModalGuideNoti;
