import { MutableRefObject, useEffect, useState } from 'react';
import { checkHadFollowUser } from '@/services/follow';
import { useIsInViewportOnce } from '@/hooks/useIsInViewportOnce';
import useAsyncEffect from 'use-async-effect';

interface IUseCheckFollowed {
  refContent?: MutableRefObject<HTMLDivElement | null>;
  to_twitter_id?: string;
  address?: string;
}

export const useCheckFollowed = ({
  refContent,
  to_twitter_id,
  address,
}: IUseCheckFollowed) => {
  const [isFollowed, setIsFollowed] = useState<boolean | undefined>(undefined);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const isInView = useIsInViewportOnce(refContent, { threshold: 0 });

  useAsyncEffect(async () => {
    if (address && to_twitter_id && isInView) {
      await reCheck();
    }
    return () => {
      setIsChecked(false);
      setIsFollowed(undefined);
    };
  }, [isInView, address, to_twitter_id]);

  const reCheck = async () => {
    if (address && to_twitter_id && isInView) {
      setIsChecked(false);
      const res = await checkHadFollowUser({ to_twitter_id, address });
      setIsFollowed(res);
      setIsChecked(true);
    }
  };

  return { isFollowed, isChecked, reCheck };
};
