const KeyIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.059 2.91903C13.4814 2.49814 14.019 2.21184 14.604 2.09627C15.1889 1.98071 15.795 2.04107 16.3457 2.26973C16.8964 2.49839 17.367 2.8851 17.698 3.38102C18.0291 3.87694 18.2057 4.45984 18.2057 5.05611C18.2057 5.65237 18.0291 6.23527 17.698 6.7312C17.367 7.22712 16.8964 7.61383 16.3457 7.84249C15.795 8.07115 15.1889 8.13151 14.604 8.01594C14.019 7.90038 13.4814 7.61407 13.059 7.19318C12.4931 6.62591 12.1754 5.85736 12.1754 5.05611C12.1754 4.25485 12.4931 3.4863 13.059 2.91903ZM13.6658 3.52691C13.3633 3.82947 13.1572 4.21493 13.0738 4.63458C12.9903 5.05422 13.0332 5.48919 13.1969 5.88447C13.3606 6.27976 13.6379 6.61762 13.9937 6.85532C14.3494 7.09303 14.7677 7.21991 15.1955 7.21991C15.6234 7.21991 16.0417 7.09303 16.3974 6.85532C16.7532 6.61762 17.0305 6.27976 17.1942 5.88447C17.3579 5.48919 17.4008 5.05422 17.3173 4.63458C17.2339 4.21493 17.0278 3.82947 16.7253 3.52691C16.3192 3.122 15.769 2.89463 15.1955 2.89463C14.622 2.89463 14.0719 3.122 13.6658 3.52691Z"
        fill="white"
      />
      <path
        d="M13.4836 6.14182C13.845 5.78035 14.3056 5.53418 14.8069 5.43444C15.3083 5.3347 15.828 5.38588 16.3003 5.5815C16.7726 5.77711 17.1762 6.10839 17.4602 6.53343C17.7442 6.95846 17.8958 7.45818 17.8958 7.96937C17.8958 8.48056 17.7442 8.98027 17.4602 9.4053C17.1762 9.83034 16.7726 10.1616 16.3003 10.3572C15.828 10.5529 15.3083 10.604 14.8069 10.5043C14.3056 10.4046 13.845 10.1584 13.4836 9.79691C13 9.31163 12.7285 8.65446 12.7285 7.96937C12.7285 7.28427 13 6.6271 13.4836 6.14182ZM14.0915 6.7497C13.8502 6.99101 13.686 7.29844 13.6195 7.63309C13.553 7.96775 13.5872 8.31461 13.7178 8.62983C13.8484 8.94504 14.0695 9.21445 14.3532 9.404C14.6369 9.59355 14.9705 9.69472 15.3117 9.69472C15.6529 9.69472 15.9864 9.59355 16.2701 9.404C16.5539 9.21445 16.775 8.94504 16.9056 8.62983C17.0362 8.31461 17.0704 7.96775 17.0039 7.63309C16.9374 7.29844 16.7731 6.99101 16.5319 6.7497C16.2082 6.42626 15.7693 6.24458 15.3117 6.24458C14.8541 6.24458 14.4152 6.42626 14.0915 6.7497Z"
        fill="white"
      />
      <path
        d="M10.4408 3.09634C10.8021 2.73509 11.2624 2.48909 11.7636 2.38945C12.2647 2.28981 12.7841 2.341 13.2562 2.53655C13.7282 2.73211 14.1317 3.06324 14.4155 3.48808C14.6994 3.91292 14.8509 4.41239 14.8509 4.92333C14.8509 5.43427 14.6994 5.93374 14.4155 6.35858C14.1317 6.78342 13.7282 7.11455 13.2562 7.3101C12.7841 7.50565 12.2647 7.55685 11.7636 7.45721C11.2624 7.35757 10.8021 7.11157 10.4408 6.75032C9.9567 6.26551 9.68481 5.60842 9.68481 4.92333C9.68481 4.23824 9.9567 3.58114 10.4408 3.09634ZM11.0475 3.7031C10.8063 3.94442 10.6421 4.25184 10.5755 4.5865C10.509 4.92115 10.5432 5.26802 10.6738 5.58323C10.8045 5.89845 11.0256 6.16786 11.3093 6.35741C11.593 6.54695 11.9266 6.64812 12.2678 6.64812C12.609 6.64812 12.9425 6.54695 13.2262 6.35741C13.5099 6.16786 13.7311 5.89845 13.8617 5.58323C13.9923 5.26802 14.0265 4.92115 13.96 4.5865C13.8935 4.25184 13.7292 3.94442 13.488 3.7031C13.164 3.37925 12.7247 3.19733 12.2666 3.19733C11.8086 3.19733 11.3693 3.37925 11.0453 3.7031H11.0475Z"
        fill="white"
      />
      <path
        d="M11.0409 7.61662L12.6196 9.19531L13.5978 8.21712L12.0191 6.63843L11.0409 7.61662Z"
        fill="#FFDE17"
      />
      <path
        d="M2.67583 13.9623C2.60255 14.0358 2.51547 14.0941 2.41959 14.1339C2.32372 14.1737 2.22093 14.1942 2.11712 14.1942C2.01331 14.1942 1.91053 14.1737 1.81465 14.1339C1.71878 14.0941 1.6317 14.0358 1.55841 13.9623L1.25 14.2707L4.0905 17.1112L5.8348 15.3669L2.9943 12.5264L2.67583 12.8448C2.74935 12.9181 2.80769 13.0052 2.84749 13.1011C2.88729 13.1969 2.90778 13.2997 2.90778 13.4035C2.90778 13.5073 2.88729 13.6101 2.84749 13.706C2.80769 13.8019 2.74935 13.889 2.67583 13.9623Z"
        fill="white"
      />
      <g>
        <path
          d="M13.0366 2.8945C13.4637 2.46739 14.0078 2.17653 14.6002 2.05869C15.1926 1.94084 15.8067 2.00132 16.3647 2.23246C16.9227 2.4636 17.3997 2.85503 17.7353 3.35725C18.0708 3.85946 18.25 4.44991 18.25 5.05392C18.25 5.65793 18.0708 6.24838 17.7353 6.75059C17.3997 7.25281 16.9227 7.64423 16.3647 7.87538C15.8067 8.10652 15.1926 8.16699 14.6002 8.04915C14.0078 7.93131 13.4637 7.64044 13.0366 7.21334C12.4647 6.64019 12.1435 5.86359 12.1435 5.05392C12.1435 4.24426 12.4647 3.46765 13.0366 2.8945ZM13.688 3.54707C13.3905 3.84528 13.188 4.22499 13.1062 4.63821C13.0244 5.05144 13.0669 5.47964 13.2283 5.86873C13.3897 6.25783 13.6628 6.59034 14.0132 6.82428C14.3635 7.05821 14.7753 7.18306 15.1965 7.18306C15.6178 7.18306 16.0296 7.05821 16.3799 6.82428C16.7302 6.59034 17.0034 6.25783 17.1648 5.86873C17.3262 5.47964 17.3687 5.05144 17.2869 4.63821C17.2051 4.22499 17.0026 3.84528 16.7051 3.54707C16.3047 3.14736 15.762 2.92295 15.1963 2.92316C14.6306 2.92337 14.0881 3.14818 13.688 3.54819V3.54707Z"
          fill="#FFB81A"
        />
      </g>
      <path
        d="M3.25588 12.2659L6.09644 15.1064L6.86682 14.3361L4.02627 11.4955L3.25588 12.2659Z"
        fill="white"
      />
      <path
        d="M2.83064 17.603L2.67261 17.445C2.56783 17.3402 2.50897 17.1981 2.50897 17.0499C2.50897 16.9017 2.56783 16.7596 2.67261 16.6548L12.3542 6.97326L13.3024 7.92143L3.62078 17.603C3.516 17.7078 3.37389 17.7666 3.22571 17.7666C3.07753 17.7666 2.93542 17.7078 2.83064 17.603Z"
        fill="white"
      />
      <g opacity="0.9">
        <path
          d="M2.67583 13.9623C2.60255 14.0358 2.51547 14.0941 2.41959 14.1339C2.32372 14.1737 2.22093 14.1942 2.11712 14.1942C2.01331 14.1942 1.91053 14.1737 1.81465 14.1339C1.71878 14.0941 1.6317 14.0358 1.55841 13.9623L1.25 14.2707L4.0905 17.1112L5.8348 15.3669L2.9943 12.5264L2.67583 12.8448C2.74935 12.9181 2.80769 13.0052 2.84749 13.1011C2.88729 13.1969 2.90778 13.2997 2.90778 13.4035C2.90778 13.5073 2.88729 13.6101 2.84749 13.706C2.80769 13.8019 2.74935 13.889 2.67583 13.9623Z"
          fill="#FFDE17"
        />
      </g>
      <g opacity="0.9">
        <path
          d="M3.25588 12.2659L6.09644 15.1064L6.86682 14.3361L4.02627 11.4955L3.25588 12.2659Z"
          fill="#FFDE17"
        />
      </g>
      <g opacity="0.9">
        <path
          d="M2.83064 17.603L2.67261 17.445C2.56783 17.3402 2.50897 17.1981 2.50897 17.0499C2.50897 16.9017 2.56783 16.7596 2.67261 16.6548L12.3542 6.97326L13.3024 7.92143L3.62078 17.603C3.516 17.7078 3.37389 17.7666 3.22571 17.7666C3.07753 17.7666 2.93542 17.7078 2.83064 17.603Z"
          fill="#FFB81A"
        />
      </g>
      <g>
        <path
          d="M13.4579 6.11979C13.824 5.75447 14.2901 5.50586 14.7974 5.40535C15.3047 5.30484 15.8304 5.35695 16.3081 5.5551C16.7857 5.75324 17.194 6.08853 17.4812 6.51859C17.7684 6.94866 17.9217 7.45421 17.9217 7.97136C17.9217 8.48851 17.7684 8.99405 17.4812 9.42412C17.194 9.85419 16.7857 10.1895 16.3081 10.3876C15.8304 10.5858 15.3047 10.6379 14.7974 10.5374C14.2901 10.4369 13.824 10.1882 13.4579 9.82293C12.967 9.33177 12.6913 8.66577 12.6913 7.97136C12.6913 7.27694 12.967 6.61095 13.4579 6.11979ZM14.1105 6.77236C13.874 7.00921 13.713 7.31086 13.6478 7.63919C13.5827 7.96751 13.6164 8.30778 13.7446 8.61698C13.8728 8.92618 14.0898 9.19044 14.3681 9.37635C14.6465 9.56227 14.9737 9.6615 15.3084 9.6615C15.6431 9.6615 15.9703 9.56227 16.2487 9.37635C16.527 9.19044 16.744 8.92618 16.8722 8.61698C17.0004 8.30778 17.0341 7.96751 16.9689 7.63919C16.9038 7.31086 16.7428 7.00921 16.5063 6.77236C16.1886 6.45595 15.7585 6.2783 15.3101 6.2783C14.8617 6.2783 14.4316 6.45595 14.1139 6.77236H14.1105Z"
          fill="#FFDE17"
        />
      </g>
      <g>
        <path
          d="M10.4162 3.07263C10.7821 2.70685 11.2482 2.45776 11.7556 2.35687C12.263 2.25597 12.789 2.30781 13.2669 2.50581C13.7449 2.70382 14.1534 3.0391 14.4408 3.46927C14.7282 3.89944 14.8816 4.40518 14.8816 4.92253C14.8816 5.43988 14.7282 5.94562 14.4408 6.37578C14.1534 6.80595 13.7449 7.14124 13.2669 7.33924C12.789 7.53725 12.263 7.58908 11.7556 7.48819C11.2482 7.3873 10.7821 7.13821 10.4162 6.77242C9.92635 6.2814 9.65125 5.61612 9.65125 4.92253C9.65125 4.22893 9.92635 3.56366 10.4162 3.07263ZM11.0677 3.72521C10.8308 3.96212 10.6694 4.26397 10.6041 4.59257C10.5387 4.92118 10.5723 5.26178 10.7005 5.57132C10.8287 5.88085 11.0458 6.14542 11.3244 6.33156C11.603 6.51769 11.9305 6.61704 12.2656 6.61704C12.6006 6.61704 12.9281 6.51769 13.2067 6.33156C13.4853 6.14542 13.7024 5.88085 13.8306 5.57132C13.9588 5.26178 13.9924 4.92118 13.927 4.59257C13.8617 4.26397 13.7003 3.96212 13.4634 3.72521C13.1455 3.40805 12.7147 3.22994 12.2656 3.22994C11.8164 3.22994 11.3857 3.40805 11.0677 3.72521Z"
          fill="#FFDE17"
        />
      </g>
    </svg>
  );
};

export default KeyIcon;
