import { CDN_URL_IMAGES } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import useCountdown from '@/hooks/useCountdown';
import { isProduction } from '@/utils/commons';
import {
  Box,
  Flex,
  ModalBody,
  ModalHeader,
  Spinner,
  Text,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import React, { useContext, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import s from './TopupModal.module.scss';
import { WalletContext } from '@/contexts/wallet-context';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';

export const TOPUP_MODAL_L2 = 'TOPUP_MODAL_L2';

const TopUpModal = ({
  otp,
  expiredTime,
}: {
  otp: string;
  expiredTime: string;
}) => {
  const [fetchingOtp, setFetchingOtp] = useState(false);

  const { addressL2 } = useContext(WalletContext);
  const gaEventTracker = useAnalyticsEventTracker();

  const { hours, minutes, seconds } = useCountdown(expiredTime);
  const origin = isProduction()
    ? 'https://newbitcoincity.com'
    : 'https://dev.newbitcoincity.com';
  const urlDeposit = useMemo(
    () => `
  ${origin}${ROUTE_PATH.DEPOSIT}`,
    []
  );

  const onClickCopy = (text: string) => {
    copy(text);
    toast.remove();
    toast.success('Copied');
  };

  return (
    <div className={s.wrapper}>
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered >
        <ModalOverlay />

        <ModalContent p="24px 22px" minW={'90%'} textAlign={'center'}> */}
      {fetchingOtp && (
        <Box className={s.loading}>
          <Spinner
            size={'xl'}
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
          />
        </Box>
      )}
      <ModalHeader fontSize="24px" fontWeight={'600'} mb="16px">
        {/* <Box mb="24px" className={s.topup_img_wrapper}>
          <img src={`${CDN_URL_ICONS}/ic-topup-tc.png`} alt="" />
        </Box> */}
        {/* <Box textAlign={'center'}> {sharePoolAddr ? 'Top up' : 'Sign up'}</Box> */}
        <Box textAlign={'center'}>Deposit</Box>
        <Text align="center" fontSize={'14px'} mt={'8px'} lineHeight={'160%'} fontWeight={'600'}>
          You can deposit in BTC or ETH.
        </Text>
        {/* <Text textAlign={'center'} fontSize="14px" fontWeight={'400'} mt="4px">
          The default currency on NBC is BTC.
        </Text> */}
      </ModalHeader>
      <ModalBody>
        <div className={s.block_wrapper}>
          <Text
            fontSize={'14px'}
            lineHeight={'160%'}
            fontWeight={'600'}
            mb="8px"
          >
            1. Open the link on desktop browser
          </Text>
          <Text
            fontSize={'16px'}
            lineHeight={'160%'}
            fontWeight={'500'}
            mb="8px"
            textDecor={'underline'}
            color={'#4185EC'}
            onClick={() => onClickCopy(urlDeposit)}
          >
            {urlDeposit}
          </Text>
          <a
            href={`${urlDeposit}?code=${otp}&mode=mobile`}
            className={s.btnMobile}
            onClick={() => {
              gaEventTracker(AlphaActions.ClickDepositOnMobile, addressL2);
            }}
          >
            <p>Open in mobile</p>
          </a>
          {/* <Box className={s.img_wrapper}>
            <img src={`${CDN_URL_IMAGES}/jackpot-deposit_3.png`} alt="" />
          </Box> */}
        </div>

        <div className={s.block_wrapper}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Text fontSize={'14px'} lineHeight={'160%'} fontWeight={'600'}>
              2. Enter this OTP code
            </Text>

            {seconds && (
              <Flex alignItems={'center'} gap="4px">
                <Text
                  fontSize={'12px'}
                  fontWeight={'400'}
                  minW="50px"
                >{`Expires in ${hours} : ${minutes} : ${seconds}`}</Text>
                {/* <CountdownProgress progress={progress} /> */}
              </Flex>
            )}
          </Flex>
          <Box
            textAlign={'center'}
            fontSize={'40px'}
            fontWeight={700}
            mt="16px"
            borderRadius={'8px'}
            letterSpacing={'8px'}
            bgColor={'#F4F4F4'}
            color="#4185EC"
          >
            {otp}
          </Box>
          {/* <Box textAlign={'center'} mt="8px">
            <Text
              fontSize={'10px'}
              className="uppercase"
              fontWeight={300}
              lineHeight={'160%'}
            >
              BTC Receiving Address
            </Text>
            <Text fontSize={'12px'} lineHeight={'160%'}>
              {addressL2}
            </Text>
          </Box> */}
        </div>

        {/*<Box className={s.block_wrapper}>*/}
        {/*  <Text fontSize={'14px'} lineHeight={'160%'} fontWeight={'600'}>*/}
        {/*    3. Make a deposit in either ETH or BTC.*/}
        {/*  </Text>*/}
        {/*  /!* <Text*/}
        {/*    mt="8px"*/}
        {/*    fontSize={'12px'}*/}
        {/*    lineHeight={'160%'}*/}
        {/*    fontWeight={'400'}*/}
        {/*  >*/}
        {/*    BTC payments are credited directly to your balance, while ETH*/}
        {/*    payments are first swapped to BTC via NewBitcoinDex and then*/}
        {/*    credited to your balance.*/}
        {/*  </Text>*/}
        {/*  <Text*/}
        {/*    mt="12px"*/}
        {/*    fontSize={'12px'}*/}
        {/*    lineHeight={'160%'}*/}
        {/*    fontWeight={'400'}*/}
        {/*  >*/}
        {/*    You can also choose to withdraw in either BTC or ETH later.*/}
        {/*  </Text>*/}
        {/*  <Text*/}
        {/*    mt="4px"*/}
        {/*    fontSize={'12px'}*/}
        {/*    lineHeight={'160%'}*/}
        {/*    fontWeight={'400'}*/}
        {/*  >*/}
        {/*    *Note: Before making a payment, please verify your 'BTC Receiving*/}
        {/*    Address' for accuracy. We do not take responsibility for any*/}
        {/*    incorrect information.*/}
        {/*  </Text> *!/*/}
        {/*</Box>*/}
        {/* <Box className={s.divider}>
          <span>Or</span>
        </Box>
        {addressL2 && (
          <Box className={s.qrWrapper}>
            <QRCode className={s.qrCode} size={200} value={addressL2} />
            <p className={s.text}>Scan the QR code using your Wallet.</p>
          </Box>
        )} */}
      </ModalBody>
    </div>
  );
};

export default React.memo(TopUpModal);
