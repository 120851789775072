import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { isProduction } from '@/utils/commons';
import { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { SectionControllers, StyledAbout } from './About.styled';
import s from './styles.module.scss';
import cs from 'classnames';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import useWindowSize from '@/hooks/useWindowSize';
import { useRouter } from 'next/router';

const APP_URL = '/app';

const About = () => {
  const gaEventTracker = useAnalyticsEventTracker();
  const { mobileScreen } = useWindowSize();
  const router = useRouter();
  const { referral } = router.query;

  const firstSectionRef = useRef<HTMLDivElement>(null);
  const secondSectionRef = useRef<HTMLDivElement>(null);
  const thirdSectionRef = useRef<HTMLDivElement>(null);
  const fourthSectionRef = useRef<HTMLDivElement>(null);

  const isInViewport1 = useIsInViewport(firstSectionRef);
  const isInViewport2 = useIsInViewport(secondSectionRef);
  const isInViewport3 = useIsInViewport(thirdSectionRef);
  const isInViewport4 = useIsInViewport(fourthSectionRef);

  const handleOnWheel = useCallback(
    (e: any) => {
      e.preventDefault();
      if (e.deltaY < 0) {
        setTimeout(() => {
          if (isInViewport2) {
            firstSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          } else if (isInViewport3) {
            secondSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          } else if (isInViewport4) {
            thirdSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      } else {
        setTimeout(() => {
          if (isInViewport1) {
            secondSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          } else if (isInViewport2) {
            thirdSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          } else if (isInViewport3) {
            fourthSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    },
    [isInViewport1, isInViewport2, isInViewport3, isInViewport4]
  );

  const renderInstallButton = () => {
    return (
      <div className={s.wInstall}>
        <a
          href={`${APP_URL}${referral ? `?referral=${referral}` : ''}`}
          onClick={() => gaEventTracker(AlphaActions.InstallAlphaApp)}
          className={s.install}
        >
          <img
            alt="mobile"
            className={s.icon}
            src={`${CDN_URL_ICONS}/alpha-mobile-install.svg`}
          />
          <p className={s.title}>Install the app</p>
        </a>
        <p className={s.text}>Available on both iOS and Android</p>
      </div>
    );
  };

  return (
    <StyledAbout onWheel={handleOnWheel}>
      <div className="section-01" id="section-1" ref={firstSectionRef}>
        <div className={s.columnContent}>
          <img
            className={s.imgLogo}
            alt="logo-01"
            src={`${CDN_URL_ICONS}/nbc-logo.svg`}
          ></img>
          <p className={s.desc}>The crypto super app that brings crypto to the mainstream.</p>
          {renderInstallButton()}
          <img
            className={s.image}
            alt="alpha-01"
            src={`${CDN_URL_IMAGES}/block01-03.png`}
          ></img>
          {mobileScreen && (
            <div className={s.wrapMouse}>
              <div className={s.mouse} />
            </div>
          )}
        </div>
      </div>
      <div className="section-02" id="section-2" ref={secondSectionRef}>
        <div className={s.content}>
          <div className={cs(s.left)}>
            <img alt="mine-01" src={`${CDN_URL_IMAGES}/block02-02.png`}></img>
          </div>
          <div className={s.right}>
            <p className={s.ttitle}>The marketplace of alpha</p>
            <div className={s.desc}>
              <p>Buy, sell, and issue access keys to alpha in crypto.</p>
            </div>

            {renderInstallButton()}
          </div>
        </div>
      </div>
      <div className="section-03" id="section-3" ref={thirdSectionRef}>
        <div className={s.content}>
          <div className={cs(s.left)}>
            <img alt="be-01" src={`${CDN_URL_IMAGES}/block03-02.png`}></img>
          </div>
          <div className={s.right}>
            <p className={s.ttitle}>Be the first</p>
            <div className={s.desc}>
              <p>To discover and capture alpha in crypto.</p>
            </div>

            {renderInstallButton()}
          </div>
        </div>
      </div>

      <div className="section-04" id="section-4" ref={fourthSectionRef}>
        <div className={s.content}>
          <div className={s.left}>
            <img alt="air-01" src={`${CDN_URL_IMAGES}/block04-02.png`}></img>
          </div>
          <div className={s.right}>
            <p className={s.ttitle}>Airdrop</p>
            <div className={s.desc}>
              <p>Earn Alpha Points for future protocol uses.</p>
            </div>

            {renderInstallButton()}
          </div>
        </div>
      </div>
      <SectionControllers>
        <div
          onClick={() =>
            firstSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
          className={`dots ${isInViewport1 ? 'active' : ''}`}
        />
        <div
          onClick={() =>
            secondSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
          className={`dots ${isInViewport2 ? 'active' : ''}`}
        />
        <div
          onClick={() =>
            thirdSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
          className={`dots ${isInViewport3 ? 'active' : ''}`}
        />
        <div
          onClick={() =>
            fourthSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
          className={`dots ${isInViewport4 ? 'active' : ''}`}
        />
      </SectionControllers>
    </StyledAbout>
  );
};

export default About;
