import Persistor from './Persistor';
import moment from 'moment';
import { IMetaData } from '@/interfaces/api/alpha';
import { fetchMetaData } from '@/services/alpha_tweet';

const COLLECTION = 'HyperLink';
const DATABASE_NAME = 'HyperLink';

// 1 minutes
const REVALIDATE_TIME = [1, 'minutes'];
const BATCH_TIMEOUT = 10000; //10s

type StoreMetadataType = IMetaData & {
  writtenAt: number;
};

const KEY_PATH = 'stored_id';

class HyperLinkPersistor extends Persistor {
  private isStorageds: Record<string, boolean> = {};
  constructor() {
    super(DATABASE_NAME, (db: any): void => {
      const store = db.createObjectStore(COLLECTION, {
        keyPath: KEY_PATH,
      });

      store.createIndex('item', 'item', {
        unique: false,
      });
    });
  }

  private getRecord = async (
    storedId: string
  ): Promise<StoreMetadataType | null> => {
    await this.get();

    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION);
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.get(`${storedId}`.toLowerCase());

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event.target.result);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  public getItem = async (url: string): Promise<IMetaData | null> => {
    if (this.isStorageds[`${url}`.toLowerCase()] === false) {
      return new Promise(resolve => {
        if (this.isStorageds[`${url}`.toLowerCase()] === undefined) {
          resolve(this.getItem(url));
        } else {
          setTimeout(() => {
            resolve(this.getItem(url));
          }, 50);
        }
      });
    } else if (this.isStorageds[`${url}`.toLowerCase()] === undefined) {
      this.isStorageds[`${url}`.toLowerCase()] = false;
    }
    try {
      const record = await this.getRecord(url);
      if (record) {
        this.isStorageds[`${url}`.toLowerCase()] = true;
        if (
          record.writtenAt &&
          moment(record.writtenAt)
            .add(...REVALIDATE_TIME)
            .isAfter(moment())
        ) {
          return record;
        }
        setTimeout(() => {
          fetchMetaData(url).then(metadata => {
            this.upsertItem(url, metadata);
          });
        }, BATCH_TIMEOUT);

        return record;
      }
      const metadata = await fetchMetaData(url);
      this.upsertItem(url, metadata);
      return metadata;
    } catch (e) {
      delete this.isStorageds[`${url}`.toLowerCase()];
      const metadata = await fetchMetaData(url);
      this.upsertItem(url, metadata);
      return metadata;
    }
  };

  public addItem = async (url: string, item: IMetaData): Promise<void> => {
    await this.add();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);
        const query = collection?.add({
          ...item,
          [KEY_PATH]: `${url}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private updateItem = async (url: string, item: IMetaData): Promise<void> => {
    await this.update();
    return new Promise((resolve, reject) => {
      try {
        const txn = this.database?.transaction(COLLECTION, 'readwrite');
        const collection = txn?.objectStore(COLLECTION);

        const query = collection?.put({
          ...item,
          [KEY_PATH]: `${url}`.toLowerCase(),
          writtenAt: new Date().valueOf(),
        });

        if (query) {
          query.onsuccess = (event: any): void => {
            resolve(event);
          };

          query.onerror = (event): void => {
            reject(event);
          };
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  private upsertItem = async (url: string, item: IMetaData): Promise<void> => {
    try {
      const record = await this.getRecord(url);
      if (record) {
        this.updateItem(url, item);
      } else {
        this.addItem(url, item);
      }
    } catch (e) {
      //
    } finally {
      //
      this.isStorageds[`${url}`.toLowerCase()] = true;
    }
  };
}

const persistor = new HyperLinkPersistor();

export default persistor;
