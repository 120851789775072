
const IcPlaceholderKey = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 28 28"
    fill="none"
  >
    <rect width={28} height={28} fill="#4E4710" rx={14} />
    <rect width={28} height={28} fill="#373636" rx={14} />
    <path
      fill="#CECECE"
      d="M16.676 7.804a2.64 2.64 0 1 1 0 3.74 2.648 2.648 0 0 1 0-3.74Zm.531.532a1.893 1.893 0 1 0 2.677 2.677 1.893 1.893 0 0 0-2.677-2.677Z"
    />
    <path
      fill="#CECECE"
      d="M17.048 10.623a2.262 2.262 0 1 1 3.199 3.2 2.262 2.262 0 0 1-3.2-3.2Zm.532.532a1.51 1.51 0 1 0 2.135 2.135 1.51 1.51 0 0 0-2.135-2.135Z"
    />
    <path
      fill="#CECECE"
      d="M14.385 7.96a2.26 2.26 0 1 1 3.197 3.198 2.26 2.26 0 0 1-3.197-3.198Zm.531.53a1.51 1.51 0 1 0 2.14 2.135 1.51 1.51 0 0 0-2.142-2.134h.002Z"
    />
    <path
      fill="#CECECE"
      d="m14.91 11.914 1.381 1.382.856-.856-1.381-1.382-.856.856ZM7.591 17.467a.689.689 0 0 1-.977 0l-.27.27 2.485 2.485 1.526-1.526L7.87 16.21l-.279.279a.69.69 0 0 1 0 .978Z"
    />
    <path
      fill="#CECECE"
      d="M16.657 7.782a2.672 2.672 0 1 1 3.779 3.779 2.672 2.672 0 0 1-3.78-3.779Zm.57.571a1.865 1.865 0 1 0 2.64 2.632 1.865 1.865 0 0 0-2.64-2.63v-.002Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#CECECE"
      d="m8.1 15.982 2.485 2.486.674-.674-2.486-2.486-.674.674Z"
    />
    <path
      fill="#CECECE"
      d="m7.726 20.653-.138-.138a.489.489 0 0 1 0-.692l8.471-8.471.83.83-8.471 8.47a.489.489 0 0 1-.692 0Z"
    />
    <path
      fill="#CECECE"
      d="M7.591 17.467a.689.689 0 0 1-.977 0l-.27.27 2.485 2.485 1.526-1.526L7.87 16.21l-.279.279a.69.69 0 0 1 0 .978ZM8.1 15.982l2.485 2.486.674-.674-2.486-2.486-.674.674Z"
      opacity={0.9}
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#CECECE"
      d="m7.726 20.653-.138-.138a.489.489 0 0 1 0-.692l8.471-8.471.83.83-8.471 8.47a.489.489 0 0 1-.692 0Z"
      opacity={0.9}
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#CECECE"
      d="M17.025 10.605a2.289 2.289 0 1 1 3.234 3.24 2.289 2.289 0 0 1-3.234-3.24Zm.571.571a1.481 1.481 0 1 0 .003 0h-.003Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#CECECE"
      d="M14.364 7.938a2.289 2.289 0 1 1 3.237 3.237 2.289 2.289 0 0 1-3.237-3.237Zm.57.571a1.483 1.483 0 1 0 2.098 2.097 1.483 1.483 0 0 0-2.098-2.097Z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
  </svg>
)
export default IcPlaceholderKey
