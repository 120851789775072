export enum ClientOs {
  Ios,
  Android,
  Other,
}

export enum Browser {
  Chrome,
  Firefox,
  Safari,
  Opera,
  Edge,
  Unknown,
}