import React from 'react';
import context from '../context';
import Logger from '../Logger';
import { BaseEventName } from '../Socket/constants';
import { camelCaseKeys } from '@trustless-computer/dapp-core';

type EventName =
  | 'SEND_MESSAGE'
  | 'SUBSCRIBE_NOTIFICATION_BY_WALLET'
  | 'NEW_CHAT_ROOM'
  | 'REMOVE_CHAT_ROOM'
  | 'NEW_GROUP_CHAT'
  | 'GROUP_MESSAGE_SENT'
  | 'ADD_MEMBER_TO_GROUP_CHAT'
  | 'REMOVE_MEMBER_FROM_GROUP_CHAT'
  | 'CLAIM_LIXI_MESSAGE_UPDATE'
  | 'GROUP_CALL'
  | 'END_GROUP_CALL'
  | 'BLOCK_CHAT_MEMBER'
  | 'UNBLOCK_CHAT_MEMBER'
  | 'GROUP_DELETE_MESSAGE'
  | 'SET_PIN_GROUP_ROOM';

function useSocketEvent<ReturnDataType>(
  event: EventName | BaseEventName,
  parser?: (_d: string) => ReturnDataType,
  formatSnakeCase?: boolean
): ReturnDataType | undefined {
  const [data, setData] = React.useState<ReturnDataType | string>();
  const { socket, isConnected } = React.useContext(context);

  React.useEffect(() => {
    const handler = (data: string) => {
      Logger.info(`WebSocket: ${event} received `, data);
      if (parser) {
        if (formatSnakeCase) {
          setData(camelCaseKeys(parser(data)));
        } else {
          setData(parser(data));
        }
      } else {
        setData(data);
      }
    };

    if (socket && isConnected) {
      socket.addEventListener(event, handler);
    }

    return () => {
      if (socket) {
        socket.removeEventListener(event, handler);
      }
    };
  }, [socket, event, isConnected]);

  return data as ReturnDataType;
}

export default useSocketEvent;
