import { apiClient } from '.';
import { PERP_API_URL, TC_API_URL } from '@/configs';
import { ISuggestionUser } from '@/interfaces/api/follow';
import { camelCaseKeys } from '@/utils/commons';

const API_PATH = `${PERP_API_URL}/api/follow`;

interface ICheckHadFollowUserPayload {
  to_twitter_id: string;
  address: string;
}

interface IGetListPayload {
  page: number;
  limit: number;
  address: string;
}

export const checkHadFollowUser = async (
  payload: ICheckHadFollowUserPayload
): Promise<boolean> => {
  try {
    const res = await apiClient.get(
      `${API_PATH}/check-follow/${payload.to_twitter_id}?network=nos&address=${payload.address}`
    );
    return Boolean(res);
  } catch (err: unknown) {
    console.error(err);
  }
  return false;
};

export const follow = async (
  payload: ICheckHadFollowUserPayload
): Promise<unknown> => {
  try {
    const res = await apiClient.post(
      `${API_PATH}?network=nos&address=${payload.address}`,
      {
        to_twitter_id: payload.to_twitter_id,
      }
    );
    return Object(res);
  } catch (err: unknown) {
    console.log(err);
    // throw Error('Fail to update status transaction');
  }
};

export const unFollow = async (
  payload: ICheckHadFollowUserPayload
): Promise<unknown> => {
  try {
    const res = await apiClient.delete(
      `${API_PATH}/unfollow/${payload.to_twitter_id}?network=nos&address=${payload.address}`
    );
    return Object(res);
  } catch (err: unknown) {
    console.log(err);
    // throw Error('Fail to update status transaction');
  }
};

export const getListFollowing = async (
  payload: IGetListPayload
): Promise<any> => {
  try {
    const res = await apiClient.get(
      `${API_PATH}/following?network=nos&address=${payload.address}&page=${payload.page}&limit=${payload.limit}`
    );
    return res;
  } catch (err: unknown) {
    console.log(err);
    // throw Error('Get list following something with wrong.');
  }
};

export const getListFollowers = async (
  payload: IGetListPayload
): Promise<any> => {
  try {
    const res = await apiClient.get(
      `${API_PATH}/follower?network=nos&address=${payload.address}&page=${payload.page}&limit=${payload.limit}`
    );
    return res;
  } catch (err: unknown) {
    console.log(err);
    // throw Error('Get list following something with wrong.');
  }
};

export const getSuggestionList = async (
  payload: IGetListPayload
): Promise<Array<ISuggestionUser>> => {
  try {
    const res = await apiClient.get(
      `${API_PATH}/suggest-following?network=nos&address=${payload.address}&page=${payload.page}&limit=${payload.limit}`
    );
    return Object(camelCaseKeys(res)) as Array<ISuggestionUser>;
  } catch (err: unknown) {
    console.log(err);
    return new Array<ISuggestionUser>();
  }
};
