import { ethers } from 'ethers';

import AlphaKeysFactoryABI from './abis/AlphaKeysFactory.json';
import AlphaKeysTokenABI from './abis/AlphaKeysToken.json';
import BridgeL2 from './abis/BridgeL2.json';
import FTABI from './abis/FT.json';
import MultiCallABI from './abis/MultiTargetCall.json';
import ERC20ABI from './abis/erc20.json';

import {
  ALPHA_KEY_FACTORY_ADDRESS,
  ALPHA_KEY_SWEEP_FLOOR_FACTORY_ADDRESS,
} from '@/configs';
import { TOKEN_ADDRESS } from '@/constants/token';
import { Bridge } from '@/contracts/interfaces/Bridge';
import { BASE_RPC, FT_ADDRESS } from './configs';
import { AlphaKeysFactory } from './interfaces/AlphaKeysFactory';
import { AlphaKeysTokenV3 } from './interfaces/AlphaKeysToken';
import { FriendtechSharesV1 } from './interfaces/FT';
import { ERC20 } from './interfaces/IERC20';
import { MultiTargetCall } from './interfaces/MultiTargetCall';

class CContract {
  public provider: ethers.providers.Provider | undefined = window.provider;

  public ethProvider: ethers.providers.Provider | undefined = undefined;

  private alphaKeysFactory: AlphaKeysFactory | undefined = undefined;
  private alphaKeysToken: AlphaKeysTokenV3 | undefined = undefined;
  private erc20: ERC20 | undefined = undefined;
  private multiCall: MultiTargetCall | undefined = undefined;
  private bridge: Bridge | undefined = undefined;
  private ftFactory: FriendtechSharesV1 | undefined = undefined;
  private ftContract: FriendtechSharesV1 | undefined = undefined;

  constructor() {
    if (window.ethProvider === undefined) {
      window.ethProvider = new ethers.providers.JsonRpcProvider(BASE_RPC);
      this.ethProvider = window.ethProvider;
    }
  }

  public getAlphaKeysFactoryContract = () => {
    if (this.alphaKeysFactory === undefined) {
      this.alphaKeysFactory = new ethers.Contract(
        ALPHA_KEY_FACTORY_ADDRESS,
        AlphaKeysFactoryABI,
        this.provider
      ) as AlphaKeysFactory;
    }

    return this.alphaKeysFactory;
  };

  public getAlphaKeysTokenContract = (contractAddress: string) => {
    this.alphaKeysToken = new ethers.Contract(
      contractAddress,
      AlphaKeysTokenABI,
      this.provider
    ) as AlphaKeysTokenV3;

    return this.alphaKeysToken;
  };

  public getERC20Contract = (contractAddress: string) => {
    this.erc20 = new ethers.Contract(
      contractAddress,
      ERC20ABI,
      this.provider
    ) as ERC20;

    return this.erc20;
  };

  public getBridgeContract = () => {
    this.bridge = new ethers.Contract(
      TOKEN_ADDRESS.BRIDGE_ADDRESS_L2,
      BridgeL2,
      this.provider
    ) as Bridge;

    return this.bridge;
  };

  public getMultiCallContract = () => {
    if (this.multiCall === undefined) {
      this.multiCall = new ethers.Contract(
        ALPHA_KEY_SWEEP_FLOOR_FACTORY_ADDRESS,
        MultiCallABI,
        this.provider
      ) as MultiTargetCall;
    }

    return this.multiCall;
  };

  public getFTContractFactory = (_provider = window.ethProvider) => {
    if (this.ftFactory === undefined) {
      this.ftFactory = new ethers.Contract(
        FT_ADDRESS,
        FTABI,
        _provider
      ) as FriendtechSharesV1;
    }

    return this.ftFactory;
  };

  public getFTContract = (token_address: string) => {
    this.ftContract = new ethers.Contract(
      token_address,
      FTABI,
      window.ethProvider
    ) as FriendtechSharesV1;

    return this.ftContract;
  };

  public getTotalSupply = async () => {
    try {
      const contract = this.getERC20Contract(TOKEN_ADDRESS.BTC_ADDRESS_L2);
      const totalSupply = await contract.totalSupply();
      return totalSupply.toString() || '0';
    } catch (error) {
      console.log('[getTotalSupply] error ', error);
      return '0';
    }
  };

  public getTotalBTCKeyTVLOnL2 = async () => {
    try {
      const contract = this.getERC20Contract(TOKEN_ADDRESS.BTC_ADDRESS_L2);
      const balance = await contract.balanceOf(TOKEN_ADDRESS.TOTAL_BTC_KEY_TVL);
      return balance.toString() || '0';
    } catch (error) {
      console.log('[getTotalBTCKeyTVLOnL2] error ', error);
      return '0';
    }
  };
}

export default CContract;
