import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '@/hooks/useIsInViewport';

interface IUseCalcPts {
  refContent?: MutableRefObject<HTMLDivElement | null>;
  last_claim: string;
  portfolio_value: number;
  total_point: number;
  toFix?: number;
}

export const useCalcPts = (
  refContent = null,
  last_claim = '',
  portfolio_value = 0,
  total_point = 0,
  toFix = 2
) => {
  const [pointer, setPointer] = useState<string>('0');
  const isInView = useIsInViewport(refContent, { threshold: 0 });
  const refTime = useRef<any>(null);

  useEffect(() => {
    function calculateIncreasingPoints(): string {
      const now: number = Math.floor(new Date().getTime() / 1000);
      const lastClaim: number = Math.floor(
        new Date(last_claim).getTime() / 1000
      );
      let numSeconds = now - lastClaim;
      if (numSeconds < 0 || numSeconds > 7200) {
        numSeconds = 0;
      }
      
      if (!last_claim) {
        return '0';
      }

      return (
        total_point +
        (portfolio_value * numSeconds * 30000) / 86400
      ).toFixed(toFix);
    }

    const startCountPoint = () => {
      setPointer(calculateIncreasingPoints());
    };

    if (isInView) {
      refTime.current && clearInterval(refTime.current);
      refTime.current = setInterval(startCountPoint, 1000);
    } else {
      refTime.current && clearInterval(refTime.current);
      refTime.current = null;
    }

    return (): void => {
      refTime.current && clearInterval(refTime.current);
      refTime.current = null;
    };
  }, [isInView, last_claim, portfolio_value, total_point]);

  return pointer;
};
