import { TC_LAYER2_NETWORK_RPC } from '@/configs';
import { ethers } from 'ethers';
import { useMemo } from 'react';

function useProviderL2() {
  return useMemo(() => {
    try {
      // if (!window.provider) {
      //   const provider = new ethers.providers.JsonRpcProvider(
      //     TC_LAYER2_NETWORK_RPC
      //   );
      //   window.provider = provider;
      // }
      return window.provider;
    } catch (error) {
      return undefined;
    }
  }, []);
}

export default useProviderL2;
