import React, { useRef } from 'react';
import s from './styles.module.scss';
import { IcPts } from '@/modules/AlphaPWA/Home/PostItem/Icon/ic-pts';
import { useCalcPts } from '@/hooks/useCalcPts';
import { Util } from 'leaflet';
import formatNum = Util.formatNum;
import { formatUsd } from '@/utils/format';

interface IInputCounter {
  portfolio_value?: number;
  total_point?: number;
  last_claim?: string;
}

export const PointerCounter = ({
  last_claim = '',
  portfolio_value = 0,
  total_point = 0,
}: IInputCounter): React.ReactElement => {
  const refContent = useRef<any>(null) as any;

  const pointer = useCalcPts(
    refContent,
    last_claim,
    portfolio_value,
    total_point
  );
  return (
    <div ref={refContent} className={s.pointerCounter}>
      <IcPts />
      <strong>{formatUsd(Number(pointer), 2).replace('$', '')}</strong>
    </div>
  );
};
