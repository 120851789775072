import { PERP_API_URL } from '@/configs';
import {
  HolderReport,
  HolderUserTokens,
  HoldingUserTokens,
  IGenerateSignaturePayload,
  IGenerateSignatureResponse,
  IVerifySignatureResponse,
} from '@/interfaces/api/verifyFriendTech';
import { apiClient } from '@/services/index';
import qs from 'query-string';
import { camelCaseKeys } from 'trustless-swap-sdk';

export const getCodeInfo = async (code: string): Promise<any> => {
  const res = await apiClient.get(
    `${PERP_API_URL}/api/player-share/friend-tech/code-info?network=nos&code=${code}`
  );
  return res;
};

export const verifySignature = async (
  payload: IGenerateSignaturePayload
): Promise<IGenerateSignatureResponse | boolean> => {
  const res = await apiClient.post(
    `${PERP_API_URL}/api/player-share/friend-tech/confirm?network=nos`,
    payload
  );
  return Object(res);
};

export const getOtpByWalletAddress = async (
  walletAddress: string
): Promise<IVerifySignatureResponse | null> => {
  try {
    const res = await apiClient.post(
      `${PERP_API_URL}/api/player-share/friend-tech/verify?network=nos&address=${walletAddress}`
    );
    return Object(res);
  } catch (err) {
    return null;
  }
};

export const verifySpreadAlphaX = async (
  walletAddress: string
): Promise<IVerifySignatureResponse | null> => {
  try {
    const res = await apiClient.post(
      `${PERP_API_URL}/api/player-share/user-point/claim-task?network=nos&address=${walletAddress}`
    );
    return Object(res);
  } catch (err) {
    throw err;
  }
};

export const settingMinKeyRequirement = async (
  walletAddress: string,
  amount: number
): Promise<IVerifySignatureResponse | null> => {
  try {
    const res = await apiClient.put(
      `${PERP_API_URL}/api/alpha-keys/user/update-settings?network=nos&address=${walletAddress}`,
      { min_holding_requirement: amount }
    );
    return Object(res);
  } catch (err) {
    throw err;
  }
};

export const getHoldingUserTokens = async ({
  network = 'nos',
  token_address,
}: {
  network?: string;
  token_address?: string;
}): Promise<HoldingUserTokens> => {
  const query = qs.stringify({
    network,
    token_address,
    // token_address: '0x427CdB1BbC75a540997082D1D05aAa9064d8de16',
  });
  const res = await apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/dapp/holding-user-tokens?${query}`
  );
  // https://stag-perp-api.fprotocol.io/api/alpha-keys/dapp/holding-user-tokens?network=nos&token_address=0x427CdB1BbC75a540997082D1D05aAa9064d8de16
  return Object(camelCaseKeys(res));
};

export const getHolderUserTokens = async ({
  network = 'nos',
  token_address,
}: {
  network?: string;
  token_address?: string;
}): Promise<HolderUserTokens> => {
  const query = qs.stringify({
    network,
    token_address,
    // token_address: '0x427CdB1BbC75a540997082D1D05aAa9064d8de16',
  });
  const res = await apiClient.get(
    `${PERP_API_URL}/api/alpha-keys/dapp/holder-user-tokens?${query}`
  );
  // https://stag-perp-api.fprotocol.io/api/alpha-keys/dapp/holding-user-tokens?network=nos&token_address=0x427CdB1BbC75a540997082D1D05aAa9064d8de16
  return Object(camelCaseKeys(res));
};

export const getHolderReport = async ({
  network = 'nos',
  token_address,
}: {
  network?: string;
  token_address: string;
}): Promise<HolderReport> => {
  const res = await apiClient.get(
    `${PERP_API_URL}/api/user/holders-report?network=${network}&player_address=${token_address}`
  );
  return Object(camelCaseKeys(res)) as HolderReport;
};
