import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AgoraEventEnum,
  AgoraMediaEnum,
  DEFAULT_UNMUTED,
  config,
  context,
} from './constants';
import {
  DeviceInfo,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IMicrophoneAudioTrack,
  UID,
} from 'agora-rtc-react';
import Logger from '@/providers/AgoraProvider/Logger';
import {
  AgoraChannelAuthentication,
  AgoraChannelName,
  AgoraUserInformation,
} from '@/providers/AgoraProvider/types';
import { getUserByAgoraId, joinCall, leaveCall } from '@/services/chat/p2p';
import { RoomId } from '@/modules/AlphaPWA/DirectMessage/types';
import { getUserDetailByTwitterId } from '@/services/player-share';
import { WalletContext } from '@/contexts/wallet-context';

type IProps = {
  children: any;
  logger?: typeof console;
};

export default function AgoraProvider({ children, logger = console }: IProps) {
  const { addressL2 } = useContext(WalletContext);

  const [client, setClient] = useState<IAgoraRTCClient | null>(null);

  const [localAudioTrack, setLocalAudioTrack] =
    useState<IMicrophoneAudioTrack | null>(null);
  const [uid, setUid] = useState<UID>('');

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [inCall, setInCall] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);

  const [volumeLevel, setVolumeLevel] = useState<number>(0);
  const [isUnmuted, setIsUnMuted] = useState<boolean>(false);
  const [channelName, setChannelName] = useState<RoomId>('');
  const [userInformation, setUserInformation] = useState<
    Record<UID, AgoraUserInformation>
  >({});

  // refs
  const localAudioTrackRef = useRef(localAudioTrack);
  const userInformationRef = useRef(userInformation);
  const addressL2Ref = useRef(addressL2);

  const eventListenersRef = useRef<Record<string | AgoraEventEnum, Function>>(
    {}
  );

  useEffect(() => {
    if (client) {
      client.enableAudioVolumeIndicator();
    }
  }, [client]);

  useEffect(() => {
    addressL2Ref.current = addressL2;
  }, [addressL2]);

  useEffect(() => {
    userInformationRef.current = userInformation;
  }, [userInformation]);

  useEffect(() => {
    if (uid) {
      getUserProfileByUid(uid);
    }
  }, [uid]);

  const getUserProfileByUid = async (uid: UID) => {
    const isExit =
      userInformationRef.current && userInformationRef.current[uid];

    if (uid && !isExit) {
      getUserByAgoraId(uid).then(user => {
        const mappingAgoraUser: Record<string, UID> = {
          [user.twitterId]: uid,
        };
        getUserDetailByTwitterId({
          address: addressL2Ref.current as string,
          twitterIds: [user.twitterId],
        }).then(profiles => {
          try {
            setUserInformation(prev => ({
              ...prev,
              ...Object.keys(profiles).reduce(
                (acc, key) => ({
                  ...acc,
                  [mappingAgoraUser[key]]: profiles[key],
                }),
                {}
              ),
            }));
          } catch (e) {
            Logger.log('getUserProfileByUid - error', e);
          }
        });
      });
    }
  };

  const addRemoteUser = (remoteUser: IAgoraRTCRemoteUser) => {
    // fetch user info
    if (remoteUser && remoteUser) {
      getUserProfileByUid(remoteUser.uid);

      setRemoteUsers(prevUsers => {
        const foundUserIndex = prevUsers.findIndex(
          u => u.uid === remoteUser.uid
        );
        if (foundUserIndex > -1) {
          prevUsers[foundUserIndex] = remoteUser;
          return [...prevUsers];
        }
        return [...prevUsers, remoteUser];
      });
    }
  };

  const registerUserPublished = () => {
    if (client) {
      const userPublishHandler = async (
        remoteUser: IAgoraRTCRemoteUser,
        mediaType: AgoraMediaEnum
      ) => {
        await client.subscribe(remoteUser, mediaType);
        Logger.info(
          AgoraEventEnum.USER_PUBLISHED,
          remoteUser,
          mediaType,
          `subscribe success`
        );
        if (mediaType === AgoraMediaEnum.AUDIO) {
          addRemoteUser(remoteUser);
          remoteUser.audioTrack?.play();
        }
      };
      eventListenersRef.current[AgoraEventEnum.USER_PUBLISHED] =
        userPublishHandler;
      client.on(AgoraEventEnum.USER_PUBLISHED, userPublishHandler);
    }
  };

  const removeRegisterUserPublished = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_PUBLISHED,
        eventListenersRef.current[AgoraEventEnum.USER_PUBLISHED]
      );

      delete eventListenersRef.current[AgoraEventEnum.USER_PUBLISHED];
    }
  };

  const registerUserUnpublished = () => {
    if (client) {
      const userUnPublishHandler = async (
        remoteUser: IAgoraRTCRemoteUser,
        mediaType: AgoraMediaEnum
      ) => {
        await client.unsubscribe(remoteUser, mediaType);
        Logger.info(AgoraEventEnum.USER_UNPUBLISHED, remoteUser, mediaType);
        if (mediaType === AgoraMediaEnum.AUDIO) {
          remoteUser.audioTrack?.stop();
        }
      };

      eventListenersRef.current[AgoraEventEnum.USER_UNPUBLISHED] =
        userUnPublishHandler;
      client.on(AgoraEventEnum.USER_UNPUBLISHED, userUnPublishHandler);
    }
  };

  const removeRegisterUserUnpublished = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_UNPUBLISHED,
        eventListenersRef.current[AgoraEventEnum.USER_UNPUBLISHED]
      );

      delete eventListenersRef.current[AgoraEventEnum.USER_UNPUBLISHED];
    }
  };

  const registerUserJoined = () => {
    if (client) {
      const userJoinHandler = async (remoteUser: IAgoraRTCRemoteUser) => {
        Logger.info(AgoraEventEnum.USER_JOINED, remoteUser);
        addRemoteUser(remoteUser);
      };

      eventListenersRef.current[AgoraEventEnum.USER_JOINED] = userJoinHandler;

      client.on(AgoraEventEnum.USER_JOINED, userJoinHandler);
    }
  };

  const removeRegisterUserJoined = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_JOINED,
        eventListenersRef.current[AgoraEventEnum.USER_JOINED]
      );

      delete eventListenersRef.current[AgoraEventEnum.USER_JOINED];
    }
  };

  const registerUserLeft = () => {
    if (client) {
      const userJoinHandler = async (remoteUser: IAgoraRTCRemoteUser) => {
        Logger.info(AgoraEventEnum.USER_LEFT, remoteUser);
        setRemoteUsers(prevUsers => {
          return prevUsers.filter(user => user.uid !== remoteUser.uid);
        });
      };

      eventListenersRef.current[AgoraEventEnum.USER_LEFT] = userJoinHandler;

      client.on(AgoraEventEnum.USER_LEFT, userJoinHandler);
    }
  };

  const removeRegisterUserLeft = () => {
    if (client) {
      client.off(
        AgoraEventEnum.USER_LEFT,
        eventListenersRef.current[AgoraEventEnum.USER_LEFT]
      );
      delete eventListenersRef.current[AgoraEventEnum.USER_LEFT];
    }
  };

  const unpublishAudioTrack = useCallback(async () => {
    Logger.log('unpublishAudioTrack - start');
    try {
      if (client && localAudioTrackRef.current) {
        await client.unpublish([localAudioTrackRef.current]);
        setIsMicrophoneOn(false);
        setIsUnMuted(false);
        Logger.log('unpublishAudioTrack - succeed');
      }
    } catch (e) {
      Logger.log('unpublishAudioTrack - failed');
    } finally {
      Logger.log('unpublishAudioTrack - end');
    }
  }, [client]);

  const leaveChannel = useCallback(async () => {
    try {
      Logger.log('leaveChannel - start');
      if (channelName) {
        leaveCall(channelName);
      }

      if (client && uid) {
        // we close the tracks to perform cleanup
        setUid('');
        unpublishAudioTrack();
        setLocalAudioTrack(null);
        setRemoteUsers([]);
        setIsMicrophoneOn(false);
        setIsUnMuted(false);
        setStart(false);
        setInCall(false);
        setChannelName('');

        removeRegisterUserJoined();
        removeRegisterUserLeft();
        removeRegisterUserPublished();
        removeRegisterUserUnpublished();

        if (localAudioTrack) {
          localAudioTrack.close();
        }
        client.removeAllListeners();
        await client.leave();

        Logger.log('leaveChannel - succeed');
      }
    } finally {
      Logger.log('leaveChannel - end');
    }
  }, [channelName, client, localAudioTrack, uid]);
  const leaveChannelRef = useRef(leaveChannel);

  useEffect(() => {
    localAudioTrackRef.current = localAudioTrack;
  }, [localAudioTrack]);

  useEffect(() => {
    leaveChannelRef.current = leaveChannel;
  }, [leaveChannel]);

  useEffect(() => {
    if (uid && localAudioTrack) {
      publishAudioTrack();
    }
  }, [localAudioTrack, uid]);

  useEffect(() => {
    const handler = () => {
      if (localAudioTrackRef.current) {
        localAudioTrackRef.current.close();
      }
      leaveChannelRef.current();
    };
    window.addEventListener('beforeunload', handler);

    return () => {
      window.addEventListener('beforeunload', handler);
    };
  }, []);

  const registerMicrophoneChanged = async () => {
    const AgoraRTC = (await import('agora-rtc-react')).default;
    AgoraRTC.on(AgoraEventEnum.MICROPHONE_CHANGED, (deviceInfo: DeviceInfo) => {
      Logger.log(AgoraEventEnum.MICROPHONE_CHANGED, deviceInfo);

      // check current microphone state
      if (localAudioTrackRef.current) {
        AgoraRTC.checkAudioTrackIsActive(localAudioTrackRef.current);
      }
    });
  };

  const createAgoraClient = async () => {
    const AgoraRTC = (await import('agora-rtc-react')).default;

    Logger.log('createAgoraClient - start');
    setClient(AgoraRTC.createClient(config));
    Logger.log('createAgoraClient - end');
  };

  const createLocalTracks = async () => {
    try {
      const AgoraRTC = (await import('agora-rtc-react')).default;

      Logger.log('createLocalTracks - start');
      // const tracks = await AgoraRTC.createMicrophoneAndCameraTracks();
      const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      setLocalAudioTrack(audioTrack);

      setReady(true);
      Logger.log('createLocalTracks - succeed');
      return [audioTrack];
    } catch (e) {
      Logger.log('createLocalTracks - failed', e);
      throw e;
    } finally {
      Logger.log('createLocalTracks - end');
    }
  };

  useEffect(() => {
    createAgoraClient();
    registerMicrophoneChanged();
  }, []);

  useEffect(() => {
    Logger.setInstance(logger);
  }, [logger]);

  const publishAudioTrack = useCallback(async () => {
    Logger.log('publishAudioTrack - start');
    try {
      if (client && localAudioTrackRef.current) {
        await client.publish([localAudioTrackRef.current]);
        setIsMicrophoneOn(true);

        setIsUnMuted(DEFAULT_UNMUTED);

        if (!DEFAULT_UNMUTED) {
          localAudioTrackRef.current.setVolume(0);
        }

        setVolumeLevel(localAudioTrackRef.current.getVolumeLevel());
        Logger.log('publishAudioTrack - succeed');
      }
    } catch (e) {
      Logger.log('publishAudioTrack - failed', e);
    } finally {
      Logger.log('publishAudioTrack - end');
    }
  }, [client]);

  const init = useCallback(
    async (authentication: AgoraChannelAuthentication) => {
      Logger.log('init - start', authentication);
      try {
        if (client) {
          createLocalTracks();

          setRemoteUsers([]);

          // check your user
          const _uid = await client.join(
            authentication.appId,
            authentication.channelName,
            authentication.token,
            authentication.uid
          );
          setUid(_uid);
          registerUserPublished();
          registerUserUnpublished();
          registerUserJoined();
          registerUserLeft();

          setStart(true);
          Logger.log('init - join with uid ', uid);

          Logger.log('init - succeed');
        } else {
          Logger.log('init - client don`t init');
        }
      } catch (e) {
        Logger.log('init - error', e);
      } finally {
        Logger.log('init - end');
      }
    },
    [client]
  );

  const getChannelAuthentication = useCallback(
    async (channel: AgoraChannelName): Promise<AgoraChannelAuthentication> => {
      Logger.log('getChannelAuthentication', channel);
      const joinInfo = await joinCall(channel);
      Logger.log('getChannelAuthentication - info', joinInfo);
      return joinInfo;
    },
    []
  );

  const joinChannel = useCallback(
    async (channel: RoomId) => {
      Logger.log('joinChannel', channel);

      if (inCall && start && localAudioTrack) {
        Logger.log(
          'joinChannel - user in calling - call stop - start',
          channel
        );
        try {
          await leaveChannel();
        } catch (e) {
          //
        }

        Logger.log(
          'joinChannel - user in calling - call stop - succeed',
          channel
        );
      }

      // should check have any playing voice call =>
      // to stop last voice call and join new voice call

      setInCall(true);
      getChannelAuthentication(channel).then(auth => {
        if (auth) {
          setChannelName(auth.channelName);
          init(auth);
        } else {
          // handle failed case
          setChannelName('');
          setInCall(false);
        }
      });
    },
    [init, inCall, start, localAudioTrack, leaveChannel]
  );

  const toggleMicrophoneEnable = useCallback(async () => {
    if (localAudioTrack) {
      await localAudioTrack.setEnabled(!isMicrophoneOn);
      setIsMicrophoneOn(prev => !prev);
    }
  }, [localAudioTrack, isMicrophoneOn]);

  const toggleMicrophoneMuted = useCallback(async () => {
    if (localAudioTrack) {
      if (isUnmuted) {
        localAudioTrack.setVolume(0);
      } else {
        localAudioTrack.setVolume(Math.floor(volumeLevel * 100));
      }

      setIsUnMuted(prev => !prev);
    }
  }, [localAudioTrack, isUnmuted, volumeLevel]);

  const values = useMemo(() => {
    return {
      uid,
      inCall,
      start,
      ready,
      localAudioTrack,
      client,

      isMicrophoneOn,
      remoteUsers,

      userInformation,

      channelName,
      toggleMicrophoneEnable,
      leaveChannel,
      joinChannel,
      publishAudioTrack,
      unpublishAudioTrack,
      toggleMicrophoneMuted,
      volumeLevel,
      isUnmuted,
    };
  }, [
    uid,
    inCall,
    start,
    ready,
    localAudioTrack,
    client,

    isMicrophoneOn,
    remoteUsers,

    userInformation,

    channelName,
    toggleMicrophoneEnable,
    leaveChannel,
    joinChannel,
    publishAudioTrack,
    unpublishAudioTrack,
    toggleMicrophoneMuted,
    volumeLevel,
    isUnmuted,
  ]);

  return <context.Provider value={values}>{children}</context.Provider>;
}
