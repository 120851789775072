import { ROUTE_PATH } from '@/constants/route-path';
import {
  labelAmountOrNumberAdds,
  MAX_DECIMAL,
  MIN_DECIMAL,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { formatCurrency } from '@/utils';
import { goToRoute } from '@/utils/go-route';
import {delay, getUrlAvatarTwitter, settingMomentFromNow} from '@/utils/helpers';
import { formatLongAddress } from '@trustless-computer/dapp-core';
import clsx from 'clsx';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { useContext, useMemo, useRef, useState } from 'react';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import s from './Feed.module.scss';
import { NotificationDataType } from './types';
import { WalletContext } from '@/contexts/wallet-context';
import { Ic33 } from '@/modules/AlphaPWA/Notification/svg/ic-3-3';
import { IcLogo } from '@/modules/AlphaPWA/Notification/svg/ic-default';
import { Center, Flex, Text } from '@chakra-ui/react';
import { IcBuy } from '@/modules/AlphaPWA/Notification/svg/ic-buy';
import { IcSell } from '@/modules/AlphaPWA/Notification/svg/ic-sell';
import { IcLimit } from '@/modules/AlphaPWA/Notification/svg/ic-limit';
import { useIsInViewportOnce } from '@/hooks/useIsInViewportOnce';
import useAsyncEffect from 'use-async-effect';
import { IcRepost } from '@/modules/AlphaPWA/Notification/svg/ic-repost';
import { linkifyText } from '@/utils/dom';
import DOMPurify from 'dompurify';
import { IcReaction } from '@/modules/AlphaPWA/Notification/svg/ic-reaction';
import { IcFreeSpin } from '@/modules/AlphaPWA/Notification/svg/ic-free-spin';
import IconStaking from '@/modules/AlphaPWA/Notification/svg/IconStaking';
import IconUnStake from '@/modules/AlphaPWA/Notification/svg/IconUnStake';
import IconStakeBlast from '@/modules/AlphaPWA/Notification/svg/ic-stake-blast';
import BigNumer from 'bignumber.js';
import {FeedPlayerType} from "@/modules/AlphaPWA/Notification/types";

settingMomentFromNow();
const activities: any = {
  trade: {
    buy: 'bought',
    sell: 'sold',
  },
  issue_token: 'bought',
  join: {},
  top100: {},
  '3_3': 'did (3,3) with ',
  '3_3_waiting': 'wanted to (3,3) with ',
};

function calculateTimeAgo(targetTime: string) {
  const targetMoment = moment(targetTime);
  return targetMoment.fromNow();
}

const compareString = (a: unknown, b: unknown) => {
  return a?.toString?.()?.toLowerCase?.() === b?.toString?.()?.toLowerCase?.();
};

const FeedItem = ({ item }: { item: NotificationDataType }) => {
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const refMain = useRef(null);
  const [postDesc, setPostDesc] = useState('');

  const extraData = useMemo(() => {
    return item.extra_data ? Object.entries(JSON.parse(item.extra_data)) : [];
  }, [item.extra_data]);

  const isPlayerPresale = useMemo(() => {
    return compareString(item?.player.twitter_id, item?.player.address);
  }, [item]);

  const isPlayerBuy = useMemo(() => {
    return item.action_type === 'buy';
  }, [item]);

  const isHaveUser = (user: any) => {
    if (
      user.twitter_id ||
      user.twitter_name ||
      user.twitter_username ||
      user.address
    ) {
      return true;
    }
    return false;
  };

  const actionText = useMemo(() => {
    if (item.activity === 'trade') {
      return activities[item.activity][item.action_type];
    }
    if (item.activity === 'issue_token') {
      return activities[item.activity];
    }
    if (item.activity === '3_3' || item.activity === '3_3_waiting') {
      return activities[item.activity];
    }
    if (item.activity === 'staking' || item.activity === 'staking_pass') {
      if (item.action_type === 'created') {
        return 'staked';
      }
      if (item.action_type === 'cancelled') {
        return 'unstaked';
      }
      return '';
    }

    if (item.activity === 'trade_pass') {
      if (item.action_type === 'buy') {
        return 'bought';
      }
      if (item.action_type === 'sell') {
        return 'sold';
      }
    }

    if (item.activity === 'donate_pass') {
      return 'donated';
    }

    return '';
  }, [item?.activity]);

  const isTrade = useMemo(() => {
    return item.activity === 'trade' || item.activity === 'trade_pass';
  }, [item.activity]);

  const isPostTweet = useMemo(() => {
    return (
      item.activity === 'post_tweet' ||
      item.activity === 'reply_tweet' ||
      item.activity === 'like_your_post'
    );
  }, [item.activity]);

  const isPass = useMemo(() => {
    return item.activity === 'trade_pass' || item.activity === 'staking_pass';
  }, [item.activity]);

  const isPostTweetOnAnother = useMemo(() => {
    return item.activity === 'post_tweet' && isHaveUser(item.user);
  }, [item]);

  const isRepostTweet = useMemo(() => {
    return item?.activity === 'repost_tweet';
  }, [item?.activity]);

  const isAccept33 = useMemo(() => {
    return item?.activity === '3_3';
  }, [item?.activity]);

  const isRequest33 = useMemo(() => {
    return item?.activity === '3_3_waiting';
  }, [item?.activity]);

  const isPostTweet2 = useMemo(() => {
    return item?.activity === 'post_tweet';
  }, [item?.activity]);

  const isReactTweet = useMemo(() => {
    return item?.activity === 'like_your_post';
  }, [item?.activity]);

  const isReplyTweet = useMemo(() => {
    return item?.activity === 'reply_tweet';
  }, [item?.activity]);

  const isSignup = useMemo((): boolean => {
    return item?.tweet_activity === 'signup';
  }, [item]);

  const isConvert = useMemo((): boolean => {
    return item?.tweet_activity === 'convert';
  }, [item]);

  const isLimitPending = useMemo((): boolean => {
    return item?.activity === 'limit_order_pending';
  }, [item]);

  const isLimitFill = useMemo((): boolean => {
    return item?.activity === 'limit_order_filled';
  }, [item]);

  const isUseIcLimit = useMemo((): boolean => {
    return isLimitFill || isLimitPending;
  }, [item]);

  const isPost_tweet = useMemo((): boolean => {
    return item?.origin_id > 0;
  }, [item]);

  const isFreeSpin = useMemo((): boolean => {
    return item?.activity === 'free_spin';
  }, [item]);

  const isStaking = useMemo(() => {
    return (
      (item?.activity === 'staking' || item?.activity === 'staking_pass') &&
      item?.action_type === 'created'
    );
  }, [item?.activity]);

  const isDonate = useMemo(() => {
    return item?.activity === 'donate_pass';
  }, [item?.activity]);

  const isUnStaking = useMemo(() => {
    return (
      (item?.activity === 'staking' || item?.activity === 'staking_pass') &&
      item?.action_type === 'cancelled'
    );
  }, [item?.activity]);

  const isStakeBlast = useMemo(() => {
    return item?.activity === 'stake_blast';
  }, [item?.activity]);

  const goToTrade = async (address: string) => {
    if (!address) return;
    router.replace({
      query: { ...router.query, idHistory: item.activity_time },
    });
    await delay(500);
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${address}`);
  };

  const isInView = useIsInViewportOnce(refMain);
  useAsyncEffect(async () => {
    if (isInView && addressL2) {
      const reviewContent = item?.content || '';
      setPostDesc(
        reviewContent?.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 250)
      );
    }
  }, [isInView, item, addressL2]);

  const renderUserAvatar = ({
    user,
    className,
    width = 28,
  }: {
    user: FeedPlayerType;
    className?: string;
    width?: number;
  }) => {
    return (
      <Avatar
        className={className}
        onClick={(event: React.MouseEvent<unknown>) => {
          event?.stopPropagation();
          goToTrade(user?.address);
        }}
        url={getUrlAvatarTwitter(user?.avatar || user?.twitter_avatar)}
        width={width}
        fontSize={12}
        address={user?.address}
        name={user?.twitter_name}
      />
    );
  };

  const renderAloneAvatar = () => {
    return (
      <>
        {isAccept33 ||
        isPostTweet2 ||
        isReplyTweet ||
        isRepostTweet ||
        isReactTweet ||
        isFreeSpin ? (
          <>{renderUserAvatar({ user: item?.user, width: 40 })}</>
        ) : (
          <>{renderUserAvatar({ user: item?.player, width: 40 })}</>
        )}
      </>
    );
  };

  const renderGroupAvatar = () => {
    return (
      <div className={s.avatarBlock}>
        <>{renderUserAvatar({ user: item?.user, className: s.imgUser, width: 36 })}</>
        <>{renderUserAvatar({ user: item?.player, className: s.imgPlayer, width: 36 })}</>
      </div>
    );
  };

  const ReturnUserName = ({
    twitter_name,
    twitter_username,
    address,
  }: any) => {
    if ((!twitter_name && !twitter_username) || !address) {
      return <></>;
    }
    return (
      <span
        onClick={(event: React.MouseEvent<unknown>) => {
          event?.stopPropagation();
          goToTrade(address);
        }}
      >
        {twitter_name ||
          (twitter_username && `@${twitter_username}`) ||
          formatLongAddress(address || '')}
      </span>
    );
  };

  const renderUserName = ({
    user,
    showTwitter = false,
  }: {
    user: FeedPlayerType;
    showTwitter?: boolean;
  }) => {
    return (
      <>
        <ReturnUserName
          twitter_name={user?.twitter_name}
          twitter_username={user?.twitter_username}
          address={user?.address}
        />
        {showTwitter && <>&nbsp; @{user.twitter_username}</>}
      </>
    );
  };

  const showPrice = React.useMemo(() => {
    return (
      new BigNumer(formatCurrency(item?.price || '0', 0, 7)).gt(0) &&
      (isTrade || isUseIcLimit || isStaking || isUnStaking)
    );
  }, []);

  return (
    <div
      className={clsx(s.feed_item)}
      onClick={() => {
        if (!(isTrade || isAccept33 || isRequest33)) {
          if (isPostTweet && item?.origin_id) {
            goToRoute(
              router,
              `/alpha/tweet/${item?.origin_id}?ownerAddress=${item?.player.address}`
            );
            return;
          }
          if (isRepostTweet && item?.origin_id) {
            goToRoute(
              router,
              `/alpha/tweet/${item?.origin_id}?ownerAddress=${item?.user.address}`
            );
            return;
          }
          goToTrade(item.user.address);
        }
      }}
      id={item.activity_time}
    >
      <div className={s.feedMain} ref={refMain}>
        {isPostTweet2 || isReplyTweet ? (
          <div className={clsx(s.action_img)}>{renderAloneAvatar()}</div>
        ) : (
          <div className={clsx(s.key_type)}>
            {isAccept33 || isRequest33 ? (
              <Ic33 />
            ) : isTrade && isPlayerBuy ? (
              <IcBuy />
            ) : isTrade ? (
              <IcSell />
            ) : isSignup || isConvert ? (
              <IcLogo />
            ) : isUseIcLimit ? (
              <IcLimit />
            ) : isRepostTweet ? (
              <IcRepost />
            ) : isReactTweet ? (
              <IcReaction />
            ) : isFreeSpin ? (
              <IcFreeSpin />
            ) : isStaking ? (
              <IconStaking />
            ) : isUnStaking ? (
              <IconUnStake />
            ) : isDonate ? (
              <IcBuy />
            ) : isStakeBlast ? (
              <IconStakeBlast />
            ) : (
              <div className={s.dot}></div>
            )}
          </div>
        )}
        <div className={s.feed_content}>
          {isPostTweet2 || isReplyTweet ? (
            <></>
          ) : isRepostTweet ? (
            <div className={clsx(s.feed_img, s.list)}>
              {renderAloneAvatar()}
            </div>
          ) : isReactTweet ? (
            <div className={clsx(s.feed_img, s.list)}>
              {extraData?.length > 1 ? (
                <>
                  {extraData.slice(0, 5)?.map(d => {
                    return (
                      <Avatar
                        onClick={(event: React.MouseEvent<unknown>) => {
                          event?.stopPropagation();
                          goToTrade(d[0]);
                        }}
                        url={d[1]}
                        address={d[0]}
                        width={40}
                      />
                    );
                  })}
                  {item.num_of_action > 5 && (
                    <Center
                      w={'40px'}
                      h={'40px'}
                      borderRadius={'50%'}
                      bg={'#FFFFFF26'}
                    >
                      <Text>+{item.num_of_action - 5}</Text>
                    </Center>
                  )}
                </>
              ) : (
                renderAloneAvatar()
              )}
            </div>
          ) : (
            <div className={s.feed_img}>
              {isAccept33 ||
              isRequest33 ||
              isTrade ||
              isUseIcLimit ||
              isStaking ||
              isUnStaking ||
              isStakeBlast
                ? renderGroupAvatar()
                : renderAloneAvatar()}
            </div>
          )}
          <div className={s.detail}>
            {isUseIcLimit ? (
              <div className={s.detail_limit}>
                <>
                  {renderUserName({ user: item?.user })}
                  {isLimitPending
                    ? ` placed a limit order for ${item.amount} `
                    : isLimitFill && ' bought 1 key of '}
                  {renderUserName({ user: item?.player })}
                  {isLimitFill && ' using a limit order '}
                  {isLimitPending && ` key${item.amount !== 1 ? 's' : ''} `}
                </>
              </div>
            ) : (
              <>
                {isTrade ||
                isAccept33 ||
                isRequest33 ||
                isStaking ||
                isUnStaking ||
                isDonate ? (
                  <>
                    <div>
                      {isAccept33 || isRequest33 ? (
                        <>{renderUserName({ user: item?.player })}</>
                      ) : (
                        (isTrade || isStaking || isUnStaking || isDonate) &&
                        renderUserName({ user: item?.user })
                      )}
                      &nbsp;
                      {actionText}
                      {!(isAccept33 || isRequest33 || isDonate) && (
                        <>
                          &nbsp;
                          {formatCurrency(item?.amount, 0, 6)}&nbsp;
                          {renderUserName({ user: item?.player })}
                          {isPlayerPresale && ' placeholder'}{' '}
                          {isPass ? 'token' : 'key'}
                          {labelAmountOrNumberAdds(item?.amount)}
                          {isStaking &&
                            ` for ${moment
                              .duration(Number(item?.duration || 0) * 1000)
                              .asDays()
                              .toFixed(0)} days`}
                        </>
                      )}
                      {isDonate &&
                        ` ${formatCurrency(item?.amount, 0, 6)} ${
                          item.content
                        } tokens to ${item?.player.twitter_name}'s treasury`}
                      {isRequest33 || isAccept33 ? renderUserName({ user: item?.user }) : null}
                    </div>
                  </>
                ) : isStakeBlast ? (
                  <div>
                    {renderUserName({ user: item?.user })} just added{' '}
                    {formatCurrency(item?.amount, MIN_DECIMAL, MAX_DECIMAL)} ETH
                    to {renderUserName({ user: item?.player })} Blast.
                  </div>
                ) : (
                  <>
                    {isReplyTweet ? (
                      <>
                        <Flex gap={1}>
                          <Text
                            fontSize={'15px'}
                            fontWeight={700}
                            color={'#FFFFFF'}
                          >
                            {item?.user.twitter_name}
                          </Text>
                          <Flex
                            gap={1}
                            fontSize={'15px'}
                            fontWeight={400}
                            color={'#6E7377'}
                          >
                            <Text
                              fontSize={'15px'}
                              fontWeight={400}
                              color={'#6E7377 !important'}
                            >
                              @{item?.user.twitter_username}
                            </Text>
                            ·
                            <Text
                              fontSize={'15px'}
                              fontWeight={400}
                              color={'#6E7377 !important'}
                            >
                              {calculateTimeAgo(item?.activity_time)}
                            </Text>
                          </Flex>
                        </Flex>
                        {item.is_mentioned ? (
                          <Text color={'#6E7377 !important'}>
                            Replied to a post that you are mentioned in
                          </Text>
                        ) : (
                          <>
                            <Text>
                              <Text
                                as={'span'}
                                fontSize={'14px'}
                                fontWeight={400}
                                color={'#6E7377 !important'}
                              >
                                Replied to
                              </Text>
                              &nbsp;
                              {renderUserName({user: item?.player})}
                            </Text>
                          </>
                        )}
                      </>
                    ) : isReactTweet ? (
                      <>
                        <Text color={'#6E7377 !important'}>
                          {renderUserName({user: item?.user})}
                          {item.num_of_action >= 2 &&
                            ` and ${
                              item.num_of_action - 1
                            } other${labelAmountOrNumberAdds(
                              item.num_of_action - 1
                            )}`}
                          &nbsp;reacted to&nbsp;
                          {item.is_mentioned
                            ? `a post that you are mentioned in`
                            : `your post.`}
                        </Text>
                      </>
                    ) : isRepostTweet ? (
                      <>
                        <Text color={'#6E7377 !important'}>
                          {renderUserName({user: item?.user})}
                          &nbsp;reposted&nbsp;
                          {item.is_mentioned
                            ? `a post that you are mentioned in`
                            : `your post.`}
                        </Text>
                      </>
                    ) : isFreeSpin ? (
                      <></>
                    ) : (
                      <>
                        {renderUserName({user: item?.player})}
                        {item.description}{' '}
                        {(isRepostTweet || isPostTweetOnAnother) && renderUserName({user: item?.user})}
                      </>
                    )}

                    {isPost_tweet && (
                      <>
                        <div
                          className={s.content_review}
                          dangerouslySetInnerHTML={{
                            __html: linkifyText(DOMPurify.sanitize(postDesc)),
                          }}
                        />
                      </>
                    )}
                    {isFreeSpin && (
                      <>
                        <div
                          className={s.content_review}
                          dangerouslySetInnerHTML={{
                            __html: linkifyText(
                              DOMPurify.sanitize(item?.content || '')
                            ),
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <Flex alignItems={'center'} gap="6px" className={s.meta}>
            <div className={s.info}>
              {showPrice && (
                <>
                  <span className={s.price}>
                    {formatCurrency(item?.price, 0, 7)}{' '}
                    {item?.base_token_symbol}
                    {item?.price_usd && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <span className={s.price_usd}>
                          {formatCurrency(item?.price_usd, 0, 2)} {'USD '}
                        </span>
                      </>
                    )}
                  </span>
                </>
              )}
              {!isReplyTweet && (
                <div className={s.time}>
                  {calculateTimeAgo(item?.activity_time)}
                </div>
              )}
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};
export default FeedItem;
