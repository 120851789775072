import BtnShareTw from '@/components/RaffleNFT/RaffleNFTPost/WheelPrize/btnShareTw';
import { IPostData } from '@/interfaces/api/post';
import { convertIpfsToHttp, isVideoUrl } from '@/utils/helpers';
import { Flex } from '@chakra-ui/react';
import React, { memo, useMemo, useRef } from 'react';
import s from './styles.module.scss';
import { getLinkNFT } from '@/utils/go-route';

interface IProps {
  twitterName: string;
  twitterAvatar: string;
  twitterTokenAvatar: string;
  twitterTokenName: string;
  rewardAmount: number;
  tokenName: string;
  tokenId: string;
  tokenUrl: string;
  tokenAddress: string;
  rewardPrice: string;
  users: Array<any>;
  data: IPostData;
  wheelDetail: any;
}

const WheelPrize: React.FC<IProps> = ({
  twitterAvatar,
  twitterName,
  tokenName,
  tokenId,
  tokenUrl,
  users,
  data,
  wheelDetail,
  tokenAddress,
}: IProps): React.ReactElement => {
  const refContent = useRef(null);
  const nftName = useMemo(() => {
    return `${tokenName}`;
  }, [tokenId, tokenName]);

  const gotoNFT = (e: any) => {
    e.preventDefault();
    window.open(getLinkNFT(tokenAddress, tokenId), '_blank');
  };

  return (
    <div ref={refContent} className={s.container}>
      <div className={`${s.prizeContent} ${s['bounce-in']}`}>
        <div className={s.body}>
          {isVideoUrl(tokenUrl) ? (
            <video controls src={convertIpfsToHttp(tokenUrl)} />
          ) : (
            <img src={convertIpfsToHttp(tokenUrl)} alt="prize" />
          )}
        </div>
        <div className={s.footer}>
          <div className={s.item}>
            <div className={s.label}>Winner</div>
            <div className={s.info}>
              <span className={s.name}>{twitterName || ''}</span>
              <span className={s.avatar}>
                <img src={twitterAvatar || ''} alt="" />
              </span>
            </div>
          </div>
          <div className={s.item}>
            <div className={s.label}>NFT</div>
            <div className={s.info}>
              <div className={s.name} onClick={gotoNFT}>
                {nftName}
              </div>
            </div>
          </div>
        </div>
      </div>
      {users.length > 0 && <RenderJoinedUsers users={users} />}
      <Flex justifyContent={'center'}>
        <BtnShareTw
          tokenName={tokenName}
          wheelCreator={data.user}
          wheelDetail={wheelDetail}
          users={users}
        />
      </Flex>
    </div>
  );
};

const RenderJoinedUsers = ({ users }: { users: Array<any> }) => {
  const isLimit = useMemo(() => {
    return users.length > 5;
  }, [users]);

  const remainingUser = useMemo(() => {
    return users.length - 5;
  }, [users]);

  return (
    <div className={s.userList}>
      <div className={s.inner}>
        <div className={s.avaList}>
          {users?.slice(0, 5).map(item => (
            <div className={s.item}>
              <img src={item?.user.twitter_avatar || ''} alt="" />
            </div>
          ))}
          {isLimit && <div className={s.more}>+{remainingUser}</div>}
        </div>
        <div className={s.des}>{users.length} New Bitcoiners played</div>
      </div>
    </div>
  );
};

export default memo(WheelPrize);
