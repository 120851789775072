import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import { goToRoute } from '@/utils/go-route';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import RepostSvg from './Icon/RepostSvg';
import s from './PostItemV2.module.scss';
import { PostItemContext, PostItemProvider } from '@/contexts/post-item';
import IconPinnedPost from './Icon/IconPinnedPost';
import { IPostData } from '@/interfaces/api/post';
import { MainPost } from '@/modules/AlphaPWA/Home/PostItem/MainPost';
import ReplySection from './ReplySection';
import { MainPostDetail } from './MainPostDetail';
import cs from 'classnames';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import WheelPost from '@/modules/AlphaPWA/Home/PostItem/WheelPost';

type Props = {
  isFetchAllData?: boolean;
  data: IPostData;
  dataParent?: any;
  isPinned?: boolean;
  onFetchData?: (isRefeshFromTop?: boolean) => void;
  fetchPinnedPosts?: any;
  isProfile?: boolean;
  isDetail?: boolean;
  onClickDetail?: (id: number) => void;
  onClickReply?: () => void;
};

const PostItem = React.memo((props: Props) => {
  const { data, isPinned, dataParent, isDetail = false, onClickDetail } = props;
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const { isRelayShow } = useContext(PostItemContext);
  const isRepost = dataParent?.is_repost;
  const [isFetchData] = useState(false);

  const isGiveAwayDeposit = useMemo((): boolean => {
    return data?.activity === 'give_away_deposit';
  }, [data]);

  const { rooms, checkRoomPermission } = useDM();
  const gotoGiveAwayCircle = useCallback(async () => {
    if (isGiveAwayDeposit) {
      const dmDetail = await rooms
        ?.filter((v: any) => v.type !== 'CHAT_ROOM')
        .find(
          (item: any) =>
            item.owner.toLowerCase() === data?.to_user?.address.toLowerCase() &&
            item.isActive
        );

      let urlRedirect = `/alpha/dm?address=${data?.to_user?.address}`;
      if (dmDetail?.id) {
        try {
          const checkPermission = await checkRoomPermission(dmDetail?.id);
          if (checkPermission) {
            urlRedirect = `/alpha/dm?id=${dmDetail?.id}`;
          }
        } catch (e) {}
      }

      router.push(urlRedirect);
    }
  }, [data, isGiveAwayDeposit]);

  const onMoveToDetail = (event: React.MouseEvent<HTMLDivElement>) => {
    // if (!data.activity) return;
    if (isDetail) {
      return;
    }
    // if (isGiveAwayDeposit) {
    //   gotoGiveAwayCircle();
    //   return;
    // }

    if (onClickDetail) {
      const targetElement = event.target as HTMLElement;
      if (targetElement.tagName === 'A') {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      onClickDetail(data?.id);
    }
    // goToRoute(
    //   router,
    //   `/alpha/tweet/${data?.id}?ownerAddress=${data?.user?.address}&fromUrl=${ROUTE_PATH.ALPHA_MOBILE_HOME}`
    // );
  };

  const parentId = useMemo(() => {
    if (isDetail) {
      return data.id;
    }
    return isRepost ? dataParent?.id : data?.id;
  }, [isRepost, data, dataParent, isDetail]);

  if (!data) return <></>;

  return (
    <div className={`${s.container} ${s.post}`} data-id={data.id}>
      <div
        className={cs(s.wrapper, {
          [`${s.post_wrapper}`]: !isDetail,
        })}
        onClick={onMoveToDetail}
      >
        <div
          className={`${s.postMain} ${
            isDetail ? s.postMain__detail : s.postMain__item
          }`}
        >
          <div className={` ${isRelayShow && !isDetail ? s.isHadReply : ''}`}>
            {isDetail && <MainPostDetail {...props} />}
            {!isDetail && <MainPost {...props} />}
          </div>
          <div
            style={{
              paddingLeft: isDetail ? '16px' : '0',
              paddingRight: isDetail ? '16px' : '0',
            }}
          >
            <ReplySection
              isFetchAllData={false}
              parentId={parentId}
              isFetchData={isFetchData}
              isShowReply={true}
              isShowTipBtn={isRepost === false && !data?.activity}
              isDetail={isDetail}
              showRepliesOfReply={isDetail}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const WPostItem = (props: Props): React.ReactElement => {
  return (
    <PostItemProvider>
      <PostItem {...props} />
    </PostItemProvider>
  );
};
export default React.memo(WPostItem);
