import React from 'react';
import s from './styles.module.scss';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import { IconBuySell } from '../Icon/IconBuySell';

interface IProps {
  address: string;
}

const BuySellButton = ({ address }: IProps) => {
  const router = useRouter();

  const goToBuySellPage = () => {
    router.push(`${ROUTE_PATH.ALPHA_TRADE}?address=${address}`)
  }

  return (
    <div
      className={s.pinPostBtn}
      onClick={e => {
        e.stopPropagation();
        goToBuySellPage();
      }}
    >
      <span className={s.text}>
        <IconBuySell />
        Buy/sell keys
      </span>
    </div>
  );
}

export default BuySellButton;
