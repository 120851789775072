import React, { useContext, useEffect, useState } from 'react';
import s from './styles.module.scss';
import { follow, getSuggestionList, unFollow } from '@/services/follow';
import { WalletContext } from '@/contexts/wallet-context';
import _uniqBy from 'lodash/uniqBy';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { ISuggestionUser, ISuggestionUserUI } from '@/interfaces/api/follow';
import { Spinner } from '@chakra-ui/react';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import SuggestionUserListModal, {
  SUGGESTION_FOLLOW_LIST,
} from '../SuggestionUserListModal';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import cn from 'classnames';
import { CDN_URL_ICONS } from '@/configs';
interface IProps {
  renderType: 'feed' | 'modal';
}

const SuggestionFollowList: React.FC<IProps> = ({
  renderType,
}: IProps): React.ReactElement => {
  const { addressL2 } = useContext(WalletContext);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [suggestionList, setSuggestionList] = useState<
    Array<ISuggestionUserUI>
  >([]);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    suggestionUser: ISuggestionUserUI
  ) => {
    e.stopPropagation();
    if (!addressL2) return;
    if (!suggestionUser.isFollowed) {
      await follow({
        address: addressL2,
        to_twitter_id: suggestionUser.userTwitterId,
      });
    } else {
      await unFollow({
        address: addressL2,
        to_twitter_id: suggestionUser.userTwitterId,
      });
    }

    // Create a new array with updated isFollowed property
    const updatedSuggestionList = suggestionList.map(user => {
      if (user.userTwitterId === suggestionUser.userTwitterId) {
        // Toggle the isFollowed property
        return {
          ...user,
          isFollowed: !user.isFollowed,
        };
      }
      return user;
    });

    // Set the state with the updated array
    setSuggestionList(updatedSuggestionList);
  };

  const gotoProfile = (item: ISuggestionUserUI) => {
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${item?.owner}`);
    dispatch(closeModal({ id: SUGGESTION_FOLLOW_LIST }));
  };

  const handleShowMore = () => {
    if (renderType === 'modal') {
      fetchSuggestionList(page + 1);
    }
    if (renderType === 'feed') {
      dispatch(
        openModal({
          id: SUGGESTION_FOLLOW_LIST,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          className: s.modalWrapper,
          hideCloseButton: false,
          onClose: () => fetchSuggestionList(1),
          render: () => <SuggestionUserListModal />,
        })
      );
    }
  };

  const fetchSuggestionList = async (p: number) => {
    if (!addressL2) return;
    setLoading(true);

    const res = await getSuggestionList({
      page: p,
      limit: renderType === 'feed' ? 5 : 20,
      address: addressL2,
    });
    const normalized = res
      .map((item: ISuggestionUser) => {
        return {
          ...item,
          isFollowed: false,
        } as ISuggestionUserUI;
      })
      .filter((item: ISuggestionUser) => {
        return item?.owner?.toLowerCase() !== addressL2?.toLowerCase();
      });

    if (res.length === 0) {
      setHasMore(false);
    }

    setSuggestionList((prev: Array<ISuggestionUserUI>) => {
      if (p === 1) {
        return normalized;
      }

      const list = _uniqBy(
        [...prev, ...normalized],
        (item: ISuggestionUserUI) => item.userTwitterId
      );
      return list;
    });
    setPage(p + 1);
    setLoading(false);
  };

  useEffect(() => {
    fetchSuggestionList(1);
  }, [addressL2, renderType]);

  return (
    <section
      className={cn(
        s.suggestionFollowList,
        renderType === 'modal' && s.suggestionModal
      )}
    >
      <h3 className={s.sectionTitle}>
        {renderType === 'feed' && (
          <span className={s.sectionIcon}>
            <img
              src={`${CDN_URL_ICONS}/ri_user-heart-line.svg`}
              alt="ri_user-heart-line"
            />
          </span>
        )}
        <span>Who to follow</span>
      </h3>
      <div className={s.list}>
        {suggestionList.map((item: ISuggestionUserUI) => {
          return (
            <div
              className={s.item}
              key={item.userTwitterId}
              onClick={() => gotoProfile(item)}
            >
              <div className={s.left}>
                <Avatar
                  url={item?.userTwitterAvatar || ''}
                  address={item?.address || ''}
                  width={44}
                />
              </div>
              <div className={s.right}>
                <div className={s.header}>
                  <div className={s.userInfo}>
                    <p className={s.name}>{item.userTwitterName}</p>
                    <p
                      className={s.username}
                    >{`@${item.userTwitterUsername}`}</p>
                  </div>
                  {!item.isFollowed && (
                    <button
                      className={s.followButton}
                      onClick={e => onClick(e, item)}
                    >
                      Follow
                    </button>
                  )}
                </div>
                {item.twitterDescription && (
                  <p className={s.desc}>{item.twitterDescription}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {loading && (
        <div className={s.loadingWrapper}>
          <Spinner
            size={'xl'}
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
          />
        </div>
      )}
      {!loading && hasMore && (
        <p onClick={handleShowMore} className={s.showMore}>
          Show more
        </p>
      )}
    </section>
  );
};

export default SuggestionFollowList;
