import React from 'react';

const ReactionRed = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <path
            d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1877 13.065 14.1902C11.8407 15.1927 10.3465 15.8095 8.77166 15.9625C7.19678 16.1155 5.61191 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.938378 11.7598C0.194739 10.3629 -0.11794 8.77669 0.0399006 7.20196C0.197741 5.62724 0.81901 4.13472 1.8251 2.91323C2.8312 1.69174 4.17691 0.796163 5.692 0.339808C7.20708 -0.116546 8.82345 -0.113175 10.3366 0.349495C11.3414 0.654878 12.276 1.15549 13.0871 1.82267C13.8981 2.48984 14.5697 3.31048 15.0632 4.23759C15.5567 5.1647 15.8626 6.18008 15.9632 7.22558C16.0639 8.27108 15.9574 9.32617 15.6498 10.3304Z"
            fill="#FF3034"
          />
        </g>
        <path
          d="M5.77869 4.56592H6.05181L6.12557 4.57988C6.25715 4.6038 6.39271 4.61776 6.52429 4.65365C7.11743 4.82817 7.62556 5.21502 7.9517 5.74039L8.0155 5.8381C8.07332 5.75235 8.12316 5.67658 8.17698 5.6048C8.50271 5.14098 8.97888 4.80421 9.52465 4.65166C9.67218 4.61178 9.82768 4.59583 9.97919 4.56791H10.2523L10.4616 4.59782C10.8622 4.64924 11.2402 4.81251 11.5522 5.0689C11.8643 5.32529 12.0978 5.66444 12.226 6.04747C12.336 6.38823 12.3773 6.74744 12.3476 7.10429C12.3331 7.91666 12.0307 8.69749 11.4943 9.30767C11.1603 9.67472 10.8041 10.021 10.4278 10.3446L8.05737 12.4542C8.04467 12.4664 8.03062 12.4771 8.0155 12.4861L7.9557 12.4343C7.32173 11.8667 6.68777 11.2997 6.0538 10.7334C5.56737 10.2987 5.06698 9.87796 4.6224 9.3974C4.25378 9.00086 3.98103 8.525 3.82515 8.00646C3.66928 7.48792 3.63443 6.94052 3.72329 6.40639C3.7897 5.97631 3.98553 5.57668 4.28469 5.2607C4.58386 4.94473 4.97217 4.72742 5.39791 4.6377C5.5255 4.60181 5.65508 4.58586 5.77869 4.56592Z"
          fill="#FFF2E1"
        />
      </svg>
    </>
  );
};

export default ReactionRed;
