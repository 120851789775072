import { CDN_URL_IMAGES } from '@/configs';
import React, { useEffect, useState } from 'react';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import FakeAvatars from '@/modules/AlphaPWA/Home/PostItem/WheelPost/Roulette/FakeAvatars';
import s from './styles.module.scss';

const random = (count: number) => Math.floor(Math.random() * count);

type IProps = {
  items?: any[];
  url?: string;
  isSpinning: boolean;
  isWheelSuccess: boolean;
};
export default React.memo(function RealAvatars({
  items = [],
  url,
  isSpinning,
  isWheelSuccess,
}: IProps) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const randomAvatar = () => {
      timeout = setTimeout(() => {
        setIndex(random(items.length));
        randomAvatar();
      }, 500);
    };

    if (isSpinning) {
      randomAvatar();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [items.length, isSpinning]);

  if (url) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            transition: 'all 100ms',
          }}
        >
          <Avatar renderAtMounted className={s.realAvatar} url={url} />
        </div>
      </>
    );
  }

  if (items[index]?.user?.twitter_avatar) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            transition: 'all 100ms',
          }}
        >
          <Avatar
            renderAtMounted
            className={s.realAvatar}
            url={`${items[index]?.user?.twitter_avatar}`}
          />
        </div>
      </>
    );
  }
  return (
    <div
      style={{
        filter: 'blur(10px)',
        // background: '#00D9F599',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        transition: 'all 100ms',
      }}
    >
      <FakeAvatars isWheelSuccess={isWheelSuccess} />
    </div>
  );
});
