import { memo, useMemo } from 'react';
import styles from './styles.module.scss';

type IProps = React.ComponentPropsWithRef<'div'>;

const Carousel = memo(function ({ children, ...rest }: IProps) {
  return <div className={styles.carousel}>{children}</div>;
});

const CarouselContainer = memo(function ({
  children,
  pos,
  ...rest
}: IProps & { pos: number }) {
  const transform = useMemo(() => {
    return `translateX(${-pos * 100}%)`;
  }, [pos]);

  return (
    <div
      className={styles.carouselContainer}
      style={{
        transition: 'transform 0.25s ease',
        transform,
      }}
    >
      {children}
    </div>
  );
});

const CarouselSlot = memo(function ({ children, ...rest }: IProps) {
  return (
    <div
      className={styles.carouselSlot}
      style={{
        flex: '1 0 100%',
        flexBasis: '100%',
        marginRight: '0px',
      }}
    >
      {children}
    </div>
  );
});

export { Carousel, CarouselContainer, CarouselSlot };
