export const IcPts = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5228 7.48949C15.5202 7.97643 13.0725 8.35865 10.0541 8.34033C7.03572 8.32462 4.59065 7.91622 4.59326 7.42666C4.59588 6.93972 7.04357 6.5575 10.062 6.57583C13.0803 6.59154 15.5254 7.00255 15.5228 7.48949Z"
        fill="url(#paint0_linear_19160_8201)"
      />
      <path
        d="M12.065 8.29126C12.9551 8.23891 13.73 8.1499 14.319 8.03471C14.2405 7.63678 14.1515 7.24932 14.0494 6.87757C13.4604 6.77285 12.7221 6.68908 11.8896 6.63672C11.9577 7.15769 12.0179 7.7127 12.065 8.29126Z"
        fill="url(#paint1_linear_19160_8201)"
      />
      <path
        d="M5.80273 7.98966C6.39175 8.11009 7.16925 8.20695 8.05932 8.26978C8.10906 7.6886 8.17189 7.13621 8.24519 6.61523C7.41271 6.65712 6.67186 6.73304 6.08023 6.83252C5.97551 7.20166 5.88389 7.58911 5.80273 7.98966Z"
        fill="url(#paint2_linear_19160_8201)"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.3"
        d="M10.062 6.63257C7.04357 6.61425 4.59588 6.93887 4.59326 7.42581C4.59326 7.60122 4.90479 7.76615 5.44406 7.9049C5.29746 7.82898 5.21893 7.74782 5.21893 7.66666C5.22155 7.22947 7.38913 6.94673 10.062 6.96244C12.7322 6.97814 14.8971 7.28444 14.8945 7.72164C14.8945 7.8028 14.816 7.88395 14.6667 7.95726C15.2086 7.82374 15.5202 7.66405 15.5228 7.48864C15.5254 7.00171 13.0803 6.64828 10.062 6.63257Z"
        fill="url(#paint3_linear_19160_8201)"
      />
      <path
        d="M4.70605 7.16504L10.0857 13.1052L15.4078 7.22525"
        stroke="#C5CAE9"
        stroke-width="0.545424"
        stroke-miterlimit="10"
      />
      <path
        d="M7.17725 6.5918L10.0726 13.1053L12.9391 6.65725"
        stroke="#C5CAE9"
        stroke-width="0.545424"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0728 13.1048V5.07031"
        stroke="#C5CAE9"
        stroke-width="0.545424"
        stroke-miterlimit="10"
      />
      <path
        d="M14.4451 7.06245C15.0551 7.17241 15.4399 7.30331 15.5106 7.44206C14.8692 4.32146 12.6886 2.01766 10.0864 2.00195C12.1257 2.01504 13.8483 4.13297 14.4451 7.06245Z"
        fill="url(#paint4_linear_19160_8201)"
      />
      <path
        d="M5.6755 7.0128C6.30379 4.09116 8.04466 1.99156 10.0866 2.00203C7.48444 1.98633 5.28021 4.26656 4.60742 7.38193C4.6781 7.24056 5.06555 7.1149 5.6755 7.0128Z"
        fill="url(#paint5_linear_19160_8201)"
      />
      <path
        d="M10.0864 2.00195C11.0079 2.00719 11.7854 4.00992 12.076 6.81637C12.5315 6.84255 12.9556 6.87396 13.3404 6.91323C13.7593 6.95774 14.131 7.00748 14.4451 7.06246C13.8483 4.13297 12.1257 2.01504 10.0864 2.00195Z"
        fill="url(#paint6_linear_19160_8201)"
      />
      <path
        d="M5.67529 7.01275C5.98944 6.96039 6.36379 6.91589 6.78264 6.87662C7.16747 6.84259 7.59418 6.81379 8.04707 6.79284C8.36644 3.99164 9.16227 1.99676 10.0864 2.00199C8.04445 1.99152 6.30358 4.09112 5.67529 7.01275Z"
        fill="url(#paint7_linear_19160_8201)"
      />
      <path
        d="M10.0862 2.00196C9.16208 1.99673 8.36625 3.99161 8.04688 6.79282C8.42908 6.77449 8.82961 6.76402 9.24847 6.75878C9.51374 6.75529 9.78513 6.75442 10.0626 6.75617C10.3384 6.75791 10.6097 6.7614 10.8768 6.76664C11.293 6.77711 11.6935 6.79543 12.0757 6.81638C11.7852 4.00993 11.0077 2.0072 10.0862 2.00196Z"
        fill="url(#paint8_linear_19160_8201)"
      />
      <path
        d="M8.23204 13.5265C7.20846 14.5501 7.20846 16.2099 8.23204 17.2335C9.25562 18.2571 10.9153 18.2571 11.9389 17.2335C12.9599 16.2099 12.9599 14.5501 11.9389 13.5265C10.9153 12.5029 9.25562 12.5029 8.23204 13.5265Z"
        fill="#FDD835"
      />
      <path
        d="M10.0854 13.1152C8.83409 13.1152 7.81836 14.1284 7.81836 15.3798C7.81836 16.6311 8.83409 17.6443 10.0854 17.6443C11.3368 17.6443 12.3499 16.6311 12.3499 15.3798C12.3499 14.1284 11.3368 13.1152 10.0854 13.1152Z"
        fill="url(#paint9_linear_19160_8201)"
      />
      <path
        style={{ mixBlendMode: 'overlay' }}
        opacity="0.3"
        d="M7.70865 16.4874C8.58301 16.4324 9.44167 16.0738 10.1118 15.4062C10.7794 14.7386 11.138 13.8773 11.1904 13.0029C10.2218 12.5526 9.03328 12.728 8.23222 13.5265C7.43116 14.3276 7.25838 15.5162 7.70865 16.4874Z"
        fill="url(#paint10_linear_19160_8201)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19160_8201"
          x1="9.91501"
          y1="8.58731"
          x2="9.91501"
          y2="6.66324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6BC0" />
          <stop offset="1" stop-color="#3949AB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_19160_8201"
          x1="12.5413"
          y1="8.29197"
          x2="12.5413"
          y2="6.63737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.809" stop-color="#CCD1EC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_19160_8201"
          x1="6.46432"
          y1="8.2696"
          x2="6.46432"
          y2="6.6155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.809" stop-color="#CCD1EC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_19160_8201"
          x1="9.97732"
          y1="8.14169"
          x2="9.97732"
          y2="6.69708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6BC0" />
          <stop offset="1" stop-color="#3949AB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_19160_8201"
          x1="10.0707"
          y1="7.44269"
          x2="10.0707"
          y2="2.00219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6BC0" />
          <stop offset="1" stop-color="#3949AB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_19160_8201"
          x1="4.70693"
          y1="7.38075"
          x2="4.70693"
          y2="2.00227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6BC0" />
          <stop offset="1" stop-color="#3949AB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_19160_8201"
          x1="9.32814"
          y1="7.06361"
          x2="9.32814"
          y2="2.00219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#CCD1EC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_19160_8201"
          x1="5.03358"
          y1="7.01377"
          x2="5.03358"
          y2="2.00223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#CCD1EC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_19160_8201"
          x1="7.18518"
          y1="6.81588"
          x2="7.18518"
          y2="2.0022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C6BC0" />
          <stop offset="1" stop-color="#3949AB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_19160_8201"
          x1="14.616"
          y1="15.3798"
          x2="7.4298"
          y2="8.19389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9100" />
          <stop offset="1" stop-color="#FFD54F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_19160_8201"
          x1="7.46498"
          y1="12.7598"
          x2="11.1913"
          y2="12.7598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#E2E5F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
