import { ROUTE_PATH } from '@/constants/route-path';
import { POPUP_REFERRAL_THROTTLE_TIME } from '@/constants/storage-key';
import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';
import { IBodyBuySweetFloorKeys } from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { CHOOSE_AMOUNT_3_3_MODAL_KEY } from '@/modules/AlphaPWA/Profiles/TradeKey/pro';
import ModalAmount from '@/modules/AlphaPWA/Profiles/TradeKey/pro/ModalAmount';
import styles from '@/modules/AlphaPWA/Profiles/TradeKey/styles.module.scss';
import { useOverlay } from '@/providers/OverlayProvider';
import { requestReload, selectCommonReducer } from '@/state/common';
import { closeModal, openModal } from '@/state/modal';
import { compareString, formatCurrency } from '@/utils';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { getErrorMessageContract } from '@/utils/helpers';
import px2rem from '@/utils/px2rem';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'classnames';
import { BigNumber } from 'ethers';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {labelAmountOrNumber, labelAmountOrNumberAdds, MAX_DECIMAL, MIN_DECIMAL} from '../../constants';
import HorizontalItem from '../horizontalItem';
import { showError, showSuccess } from '../toast';
import s from './styles.module.scss';

const BtnSend33 = ({
  profile,
  tokenDetail,
  className,
  title = '(3,3)',
  onCallback,
  isResend,
  orderId,
}: {
  profile: any;
  tokenDetail: any;
  className?: any;
  title?: string;
  onCallback?: (_: any) => void;
  isResend?: boolean;
  orderId?: string;
}) => {
  const router = useRouter();

  const { showRequest } = router.query as { showRequest: string };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { estimateTCMultiKeys, requestTrade33Keys, reRequestTrade33Keys } =
    useTradeKey();
  const { balanceL2 } = useContext(AssetsContext);
  const { addressL2 } = useContext(WalletContext);
  const dispatch = useDispatch();
  const { openOverlayScreen } = useOverlay();
  const needReload = useSelector(selectCommonReducer).needReload;

  const [submitting, setSubmitting] = useState(false);
  const [estimateTC, setEstimateTC] = useState<string>('0');
  const [estimating, setEstimating] = useState(false);
  const [allowBuy, setAllowBuy] = useState(true);

  const [estimateTC33, setEstimateTC33] = useState<string>('0');
  const [selectKeys33, setSelectKeys33] = useState<IBodyBuySweetFloorKeys[]>(
    []
  );
  const [estimateKeysTC33, setEstimateKeysTC33] = useState<any[]>([]);

  const gaEventTracker = useAnalyticsEventTracker();

  const selectKeys: IBodyBuySweetFloorKeys[] = useMemo(() => {
    if (tokenDetail) {
      return [
        {
          token_address: tokenDetail.address,
          token_amount: 1,
          amount_after_fee: 0,
        },
      ];
    }

    return [];
  }, [tokenDetail]);

  useEffect(() => {
    onEstimateTC();
  }, [selectKeys, needReload, balanceL2, addressL2]);

  useEffect(() => {
    if (showRequest) {
      onOpen();
    }
  }, [showRequest]);

  const openReferralModal = () => {
    localStorage.set(
      POPUP_REFERRAL_THROTTLE_TIME,
      `${new Date().toISOString()}`
    );
    openOverlayScreen('SHARE_REFERRAL');
    gaEventTracker(GameFiActions.PopupReferralOpenModal, addressL2);
  };

  const showSuccessInform = () => {
    try {
      const lastShowPopupReferral = localStorage.get(
        POPUP_REFERRAL_THROTTLE_TIME
      );
      if (lastShowPopupReferral) {
        const lastTime = moment(lastShowPopupReferral as any);
        const hoursAgo = moment().diff(lastTime, 'hours');
        if (Math.abs(hoursAgo) >= 3) {
          openReferralModal();
        } else {
          // showSuccess()
        }
      } else {
        openReferralModal();
      }
    } catch (e) {
      //
    }
  };

  const onHideModalChooseAmount33 = () => {
    dispatch(closeModal({ id: CHOOSE_AMOUNT_3_3_MODAL_KEY }));
  };

  const handleSubmit33 = () => {
    onHideModalChooseAmount33();
    onOpen();
  };

  const openChooseAmountFor33 = () => {
    dispatch(
      openModal({
        id: CHOOSE_AMOUNT_3_3_MODAL_KEY,
        theme: 'dark',
        // title: `Amount of keys`,
        className: styles.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        render: () => (
          <ModalAmount
            selectKeys={selectKeys}
            onConfirm={handleSubmit33}
            setSelectKeys33={setSelectKeys33}
            setEstimateKeysTC33={setEstimateKeysTC33}
            setEstimateTC33={setEstimateTC33}
          />
        ),
      })
    );
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      let txs = [];
      if (isResend) {
        const tx = await reRequestTrade33Keys({
          token_address: selectKeys33[0].token_address,
          token_amount: selectKeys33[0].token_amount,
          order_id: orderId,
          amount_after_fee: 0,
        });
        txs = [tx];
      } else {
        txs = await requestTrade33Keys([
          {
            token_address: selectKeys33[0].token_address,
            token_amount: selectKeys33[0].token_amount,
            amount_after_fee: 0,
          },
        ]);
      }
      showSuccessInform();
      if (!isResend) {
        showSuccess({
          message: `You have successfully sent a (3,3) request to ${tokenDetail?.userTwitterName}.`,
          linkText: 'Manage your request here!',
          url: `${ROUTE_PATH.ALPHA_MOBILE_KEYS_INVITATIONS}`,
        });
      }

      onClose();
      onCallback && onCallback(txs);
    } catch (error: any) {
      showError(getErrorMessageContract(error));
    } finally {
      dispatch(requestReload());
      setSubmitting(false);
    }
  };

  const onEstimateTC = async () => {
    if (!selectKeys || selectKeys?.length === 0) {
      return;
    }
    try {
      setEstimating(true);

      const response = await estimateTCMultiKeys(selectKeys);

      setEstimateTC(response.total_tc_formatted);
      setAllowBuy(BigNumber.from(response.total_tc).lt(balanceL2.amountBTC));
    } catch (error) {
      console.log(error);

      setAllowBuy(false);
      setEstimateTC('0');
    } finally {
      setEstimating(false);
    }
  };

  if (compareString(addressL2, profile?.address)) {
    return null;
  }

  const amountKeys = selectKeys33?.length * selectKeys33[0]?.token_amount;

  return (
    <>
      <Button
        onClick={openChooseAmountFor33}
        className={clsx(className, s.btn_trade)}
      >
        {title}
      </Button>
      <Drawer
        closeOnEsc={!submitting}
        closeOnOverlayClick={!submitting}
        placement={'bottom'}
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent className={s.confirmModal}>
          <DrawerHeader borderBottomWidth="1px">
            Sending (3,3) request
            <DrawerCloseButton
              fontSize={'14px'}
              mt={4}
              mr={4}
              onClick={() => {
                //   refDone.current = true;
                //   refFooter.current?.reset?.();
                //   dispatch(requestReload());
                //   onRefresh();
                //   setSelectKeys([]);
                setSubmitting(false);
                //   setIsDone(false);
                onClose();
              }}
            />
          </DrawerHeader>
          <DrawerBody>
            <HorizontalItem
              label={
                <Text fontSize={px2rem(16)} fontWeight={500}>
                  {labelAmountOrNumber(1)}:
                </Text>
              }
              value={`${formatCurrency(
                amountKeys,
                MIN_DECIMAL, MAX_DECIMAL
              )} key${labelAmountOrNumberAdds(amountKeys)}`}
            />
            <Box py={4} />
            <HorizontalItem
              label={
                <Text fontSize={px2rem(16)} fontWeight={500}>
                  Total:
                </Text>
              }
              value={`${formatCurrency(estimateTC33, 0, 6)} BTC`}
            />
            {!allowBuy && (
              <Text
                fontSize={'12px'}
                style={{
                  color: '#FF4747',
                  textAlign: 'right',
                  marginTop: '5px',
                }}
              >
                Not enough BTC Balance
              </Text>
            )}
            <Button
              isDisabled={!allowBuy || submitting}
              onClick={onSubmit}
              className={s.btnConfirm}
              isLoading={submitting}
            >
              Send
            </Button>

            <Text>
              (3,3) runs on a smart contract that arranges the key swap
              transaction trustlessly and holds the keys for 30 days.
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BtnSend33;
