import React, { memo, useContext, useMemo } from 'react';
import s from './styles.module.scss';
import { CDN_URL_ICONS } from '@/configs';
import { WalletContext } from '@/contexts/wallet-context';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '@/utils';
import WinnerKeyIcon from './WinnerKeyIcon';
import { IPostData } from '@/interfaces/api/post';
import BtnShareTw from '@/modules/AlphaPWA/Home/PostItem/WheelPost/WheelPrize/btnShareTw';
import { Flex } from '@chakra-ui/react';

interface IProps {
  twitterName: string;
  twitterAvatar: string;
  twitterTokenAvatar: string;
  twitterTokenName: string;
  rewardAmount: number;
  rewardPrice: string;
  users: Array<any>;
  data: IPostData;
  wheelDetail: any;
}

const WheelPrize: React.FC<IProps> = ({
  twitterAvatar,
  twitterName,
  rewardAmount,
  rewardPrice,
  twitterTokenAvatar,
  twitterTokenName,
  users,
  data,
  wheelDetail,
}: IProps): React.ReactElement => {
  const { btc2Usd } = useContext(WalletContext);

  const usdValue = useMemo(() => {
    if (!btc2Usd || !rewardPrice) return '-';
    const priceBn = new BigNumber(rewardPrice);
    return `$${formatCurrency(priceBn.times(btc2Usd), 0, 2)}`;
  }, [btc2Usd, rewardPrice]);

  const btcValue = useMemo(() => {
    if (!rewardPrice) return '-';
    return `${formatCurrency(rewardPrice, 0, 4)} BTC`;
  }, [rewardPrice]);

  return (
    <div className={s.container}>
      <div className={s.winnerBg}>
        <img src={`${CDN_URL_ICONS}/winner-wheel.png`} alt="winner-bg" />
      </div>
      <div className={s.prizeContent}>
        <div
          className={s.body}
          style={{
            background: `url(${twitterTokenAvatar.replace(
              '_normal',
              '_400x400'
            )})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className={s.layer}></div>
          {/* <div className={s.winnerKey}>
            <WinnerKeyIcon />
          </div>
          <div className={s.wrapAmount}>
            <div className={s.amount}>{btcValue}</div>
            <div className={s.desAmount}>{usdValue}</div>
          </div> */}
          <div className={s.avatar}>
            <img
              src={twitterTokenAvatar.replace('_normal', '_400x400')}
              alt=""
            />
          </div>
          <div className={s.des}>
            <span className={s.label}>
              <span>Prize</span>
            </span>{' '}
            <div className={s.rwInfo}>
              {`${rewardAmount} `}
              {twitterTokenName || ''}
              {` ${rewardAmount > 1 || rewardAmount < 1 ? 'keys' : 'key'}`}
            </div>
          </div>
        </div>
        <div className={s.footer}>
          <div className={s.item}>
            <div className={s.label}>Winner</div>
            <div className={s.info}>
              <span className={s.name}>{twitterName || ''}</span>
              <span className={s.avatar}>
                <img src={twitterAvatar || ''} alt="" />
              </span>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.label}>Prize value</div>
            <div className={s.info}>
              <span className={s.name}>{btcValue}</span>
              <span className={s.usdtValue}>{usdValue}</span>
            </div>
          </div>
          {/* <div className={s.item}>
            <div className={s.label}>Key</div>
            <div className={s.info}>
              <span className={s.name}>
                {`${rewardAmount} `}
                {twitterTokenName || ''}
                {` ${rewardAmount > 1 || rewardAmount < 1 ? 'keys' : 'key'}`}
              </span>
              <span className={s.avatar}>
                <img src={twitterTokenAvatar || ''} alt="" />
              </span>
            </div>
          </div> */}
        </div>
      </div>

      {users.length > 0 && <RenderJoinedUsers users={users} />}
      <Flex justifyContent={'center'}>
        <BtnShareTw
          wheelCreator={data.user}
          wheelDetail={wheelDetail}
          users={users}
        />
      </Flex>
    </div>
  );
};

const RenderJoinedUsers = ({ users }: { users: Array<any> }) => {
  const isLimit = useMemo(() => {
    return users.length > 5;
  }, [users]);

  const remainingUser = useMemo(() => {
    // if (users.length > 99) {
    //   return 99;
    // }
    return users.length - 5;
  }, [users]);

  return (
    <div className={s.userList}>
      <div className={s.inner}>
        <div className={s.avaList}>
          {users?.slice(0, 5).map(item => (
            <div className={s.item}>
              <img src={item?.user.twitter_avatar || ''} alt="" />
            </div>
          ))}
          {isLimit && <div className={s.more}>+{remainingUser}</div>}
        </div>
        <div className={s.des}>{users.length} New Bitcoiners played</div>
      </div>
    </div>
  );
};

export default memo(WheelPrize);
