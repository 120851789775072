import React from 'react';

const DeletePostSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5625 4.5C15.5625 4.8105 15.3105 5.0625 15 5.0625H3C2.6895 5.0625 2.4375 4.8105 2.4375 4.5C2.4375 4.1895 2.6895 3.9375 3 3.9375H6.16049C6.23024 3.801 6.28425 3.6465 6.342 3.474L6.49349 3.01875C6.64649 2.55975 7.07625 2.25 7.56075 2.25H10.4393C10.9238 2.25 11.3535 2.55975 11.5065 3.01875L11.658 3.474C11.7158 3.6465 11.7698 3.801 11.8395 3.9375H15C15.3105 3.9375 15.5625 4.1895 15.5625 4.5ZM14.1443 6.05251L13.6425 13.65C13.56 14.835 12.9375 15.75 11.3925 15.75H6.6075C5.0625 15.75 4.44 14.835 4.3575 13.65L3.85574 6.05251C3.84749 5.92276 3.95025 5.8125 4.08 5.8125H13.9192C14.0497 5.8125 14.1525 5.92276 14.1443 6.05251ZM9.79575 10.5L10.8983 9.3975C11.118 9.17775 11.118 8.8215 10.8983 8.60175C10.6785 8.382 10.3223 8.382 10.1025 8.60175L9 9.70425L7.8975 8.60175C7.67775 8.382 7.3215 8.382 7.10175 8.60175C6.882 8.8215 6.882 9.17775 7.10175 9.3975L8.20425 10.5L7.10175 11.6025C6.882 11.8223 6.882 12.1785 7.10175 12.3983C7.21125 12.5078 7.35524 12.5633 7.49924 12.5633C7.64324 12.5633 7.78724 12.5085 7.89674 12.3983L8.99924 11.2958L10.1017 12.3983C10.2112 12.5078 10.3552 12.5633 10.4992 12.5633C10.6432 12.5633 10.7872 12.5085 10.8967 12.3983C11.1165 12.1785 11.1165 11.8223 10.8967 11.6025L9.79575 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export default DeletePostSvg;
