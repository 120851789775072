import { Browser, ClientOs } from "@/enums/client";
import { isBrowser } from "@trustless-computer/dapp-core";

export const isWebApp = (): boolean => {
  if (isBrowser()) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
  }
  return false;
}

export const detectClientOs = (): ClientOs => {
  if (!isBrowser()) {
    return ClientOs.Other;
  }

  if (/android/i.test(navigator.userAgent)) {
    return ClientOs.Android;
  }

  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream) {
    return ClientOs.Ios;
  }

  return ClientOs.Other;
}

export const detectBrowser = (): Browser => {
  if (!isBrowser()) {
    return Browser.Unknown;
  }

  if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
    return Browser.Chrome;
  } else if (navigator.userAgent.match(/firefox|fxios/i)) {
    return Browser.Firefox;
  } else if (navigator.userAgent.match(/safari/i)) {
    return Browser.Safari;
  } else if (navigator.userAgent.match(/opr\//i)) {
    return Browser.Opera;
  } else if (navigator.userAgent.match(/edg/i)) {
    return Browser.Edge;
  } else {
    return Browser.Unknown;
  }
}

export const openExternalLink = (url: string, title:string, text:string) => {
  const isPWA = isWebApp();
  if (isPWA) {
    // if (navigator.share) {
    //   navigator.share({
    //     title,
    //     text,
    //     url,
    //   })
    //     .catch((error) => {
    //       window.open(url, '_blank')
    //     });
    // } else {
    //   window.open(url, '_blank')
    // }
    window.open(url, '_blank')
  } else {
    window.open(url, '_blank')
  }
}