import React from 'react';
import styled from 'styled-components';

const Cell = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  text-overflow: ellipsis;
  overflow: hidden;
  /* background-color: blue; */
`;

type Props = {
  text?: string;
  key?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactElement;
};

const TableCell = React.memo((props: Props) => {
  const { className, style = {}, text = '', key = '', children } = props;
  return (
    <Cell className={className} style={style} key={key}>
      {text || children}
    </Cell>
  );
});

export default TableCell;
