import { NBC_URL } from '@/constants/common';
import { ROUTE_PATH } from '@/constants/route-path';
import web3 from 'web3';
import BigNumber from 'bignumber.js';
import { MAX_DECIMAL } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { isInValidAmount } from './helpers';

export const checkLines = (str: string) => str.split(/\r\n|\r|\n/).length;

export const checkForHttpRegex = (str: string) => {
  const httpsRegex = /^(http|https):\/\//;
  return httpsRegex.test(str);
};

export const isBase64String = (str: string): boolean => {
  try {
    window.atob(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isNumeric = (str: never | string) => {
  return /^\d+$/.test(str);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const stringToBuffer = (str: string): Buffer => {
  return Buffer.from(str);
};

export const compareString = (a: unknown, b: unknown) => {
  return a?.toString?.()?.toLowerCase?.() === b?.toString?.()?.toLowerCase?.();
};

export const formatCurrency = (
  value: any = 0,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  symbol = 'TC'
): string => {
  if (isNaN(Number(value))) return '0';

  let config: any = {
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
  };

  if (Number(value) < 1) {
    if (compareString(symbol, 'BTC')) {
      config = {
        // maximumSignificantDigits: 4,
        maximumFractionDigits: 5,
        minimumFractionDigits: 0,
      };
    } else {
      config = {
        // maximumSignificantDigits: 4,
        maximumFractionDigits: 4,
        minimumFractionDigits: 2,
      };
    }
  } else if (Number(value) >= 1 && Number(value) < 1000) {
    config = {
      // maximumSignificantDigits: 5,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    };
  } else if (Number(value) >= 1000) {
    config = {
      // maximumSignificantDigits: 6,
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: minimumFractionDigits,
    };
  }

  const result = new Intl.NumberFormat('en-US', config);
  return result.format(value);
};

export const formatName = (name: string, length: number = 12): string => {
  if (!name) return '';

  if (web3.utils.isAddress(name)) {
    return name.substring(2, 8);
  } else {
    return name?.length > length ? name.substring(0, length) + '...' : name;
  }
};

export const abbreviateNumber = (value: any) => {
  const config: any = {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 3,
    maximumFractionDigits: 2,
  };
  const result = new Intl.NumberFormat('en-US', config);
  return result.format(value);
};

export const getUrlsFromText = (text: string) => {
  // Define a regular expression pattern to match URLs
  const urlPattern = /(?:https?:\/\/)?(?:www\.)?[\w.-]+\.\w+(?:\S+)?/gi;

  // Use the match() method to find all matches in the text
  const matches = text.match(urlPattern);

  // Return the array of URLs
  if (matches) {
    return matches.map(match => {
      if (!match.includes(`${NBC_URL}/@`)) return match.toString();
      return '';
    });
  } else {
    return [];
  }
};

export function generateRandomString(length: number) {
  for (
    var s = '';
    s.length < length;
    s +=
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789()?!.#%@^=+-"&*`~;<>{}'.charAt(
        (Math.random() * 84) | 0
      )
  );
  return s;
}

interface IFormat {
  amount: string;
  roundCeil?: boolean;
  decimalsDisplay?: number;
}

export const formatAmount = (params: IFormat) => {
  const decimals = params.decimalsDisplay || MAX_DECIMAL;
  return new BigNumber(params.amount).toFixed(
    decimals,
    params.roundCeil ? BigNumber.ROUND_CEIL : BigNumber.ROUND_FLOOR
  );
};

interface IFormatToHuman extends IFormat {
  decimals?: number;
}

export const formatToHumanAmount = (params: IFormatToHuman) => {
  const decimals = params.decimals || 18;
  const amount = new BigNumber(params.amount).dividedBy(
    new BigNumber(10).pow(decimals)
  );
  return formatAmount({ ...params, amount: amount.toString() } as any);
};

type IFormatCurrencyV2 = {
  amount: string | number;
  decimals?: number;
};

export const formatCurrencyV2 = (params: IFormatCurrencyV2) => {
  const { amount, decimals = 6 } = params;

  if (!amount || isInValidAmount(amount)) return '0.0';
  const fmt = {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
  };
  const amount_BN = new BigNumber(amount);
  if (amount_BN.isZero()) return '0';

  return new BigNumber(amount).toFormat(decimals, BigNumber.ROUND_DOWN, fmt);
};
