export const HEADER_COLUMNS = [
  {
    title: 'Address',
    dataIndex: 'Address',
    key: 'Address',
    flex: 4,
  },
  {
    title: 'Balance',
    dataIndex: 'Amount',
    key: 'Amount',
    flex: 2,
  },
];
