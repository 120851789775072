import React, { useContext, useEffect, useRef, useState } from 'react';
import s from './styles.module.scss';
import cs from 'classnames';
import { AssetsContext } from '@/contexts/assets-context';
import { formatCurrency } from '@/utils';
import { Tooltip, Text } from '@chakra-ui/react';
import { WalletContext } from '@/contexts/wallet-context';
import BN from 'bignumber.js';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  classNames?: string;
  setFieldValue?: any;
  errorMsg?: string;
  label?: string;
  minValue?: number;
  minimumRequiredText?: string;
  isShowTip?: boolean;
}

const BTCInput: React.FC<IProps> = ({
  classNames,
  errorMsg,
  value,
  setFieldValue,
  label = 'Gift amount',
  minValue = 0.0001,
  minimumRequiredText,
  isShowTip = true,
}): React.ReactElement => {
  const { balanceL2 } = useContext(AssetsContext);
  const ref = useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const { handleGetBTC2USD } = useContext(WalletContext);
  const [btcPrice, setBTCPrice] = useState<BN | null>(null);
  const [activeFixedOption, setActiveFixedOption] = useState(0);
  const AMOUNT_OPTIONS = [
    new BN(minValue),
    new BN(minValue).times(10),
    new BN(minValue).times(100),
  ];

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setFieldValue('amount', e.target.value);
  };

  const getBTCPrice = async () => {
    const price = await handleGetBTC2USD();
    setBTCPrice(price);
    setFieldValue('amount', AMOUNT_OPTIONS[0].toString());
  };

  useEffect(() => {
    getBTCPrice();
  }, []);

  return (
    <div className={s.btcInput}>
      <div className={s.heading}>
        <label htmlFor={'input'}>
          {label}
          {isShowTip && (
            <Tooltip
              label={
                <Text style={{ fontSize: 12, color: 'white' }}>
                  You are about to gift up to 5 red packets with the total value
                  as you specify below.
                </Text>
              }
              minWidth="200px"
              padding="8px"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 0.5C2.9625 0.5 0.5 2.9625 0.5 6C0.5 9.0375 2.9625 11.5 6 11.5C9.0375 11.5 11.5 9.0375 11.5 6C11.5 2.9625 9.0375 0.5 6 0.5ZM5.75 3C5.61739 3 5.49021 3.05268 5.39645 3.14645C5.30268 3.24021 5.25 3.36739 5.25 3.5C5.25 3.63261 5.30268 3.75979 5.39645 3.85355C5.49021 3.94732 5.61739 4 5.75 4H6C6.13261 4 6.25979 3.94732 6.35355 3.85355C6.44732 3.75979 6.5 3.63261 6.5 3.5C6.5 3.36739 6.44732 3.24021 6.35355 3.14645C6.25979 3.05268 6.13261 3 6 3H5.75ZM5 5C4.86739 5 4.74021 5.05268 4.64645 5.14645C4.55268 5.24021 4.5 5.36739 4.5 5.5C4.5 5.63261 4.55268 5.75979 4.64645 5.85355C4.74021 5.94732 4.86739 6 5 6H5.5V7.5H5C4.86739 7.5 4.74021 7.55268 4.64645 7.64645C4.55268 7.74021 4.5 7.86739 4.5 8C4.5 8.13261 4.55268 8.25979 4.64645 8.35355C4.74021 8.44732 4.86739 8.5 5 8.5H7C7.13261 8.5 7.25979 8.44732 7.35355 8.35355C7.44732 8.25979 7.5 8.13261 7.5 8C7.5 7.86739 7.44732 7.74021 7.35355 7.64645C7.25979 7.55268 7.13261 7.5 7 7.5H6.5V5.5C6.5 5.36739 6.44732 5.24021 6.35355 5.14645C6.25979 5.05268 6.13261 5 6 5H5Z"
                    fill="white"
                  />
                </svg>
              </span>
            </Tooltip>
          )}
        </label>
        <span>
          Balance: {formatCurrency(balanceL2.amountBTCFormatted, 0, 4)} BTC
        </span>
      </div>
      <div className={s.amountOptions}>
        {AMOUNT_OPTIONS.map((item, index) => (
          <div
            className={cs(s.amountOption, {
              [`${s.active}`]: index === activeFixedOption && !isCustom,
            })}
            onClick={() => {
              setFieldValue('amount', item.toString());
              setIsCustom(false);
              setActiveFixedOption(index);
            }}
          >
            <p className={s.usdLabel}>{item.toString()} BTC</p>
            {btcPrice && (
              <p className={s.amountLabel}>
                ${formatCurrency(item.times(btcPrice), 0, 2)}
              </p>
            )}
          </div>
        ))}

        <div
          className={cs(s.inputWrapper, {
            [`${s.active}`]: isCustom,
          })}
          onClick={() => {
            setIsCustom(true);
            setFieldValue(
              'amount',
              inputValue || new BN(minValue).times(1000).toString()
            );
            if (!inputValue) {
              setInputValue(new BN(minValue).times(1000).toString());
            }
          }}
        >
          <input
            ref={ref as any}
            type="number"
            placeholder="0.0 BTC"
            className={cs(s.input, classNames)}
            onChange={handleOnChange}
            value={inputValue}
          />
        </div>
      </div>
      {minimumRequiredText && (
        <div className={s.minimumRequiredLabel}>{minimumRequiredText}</div>
      )}
      {!!errorMsg && <p className={s.error}>{errorMsg}</p>}
    </div>
  );
};

export default React.memo(BTCInput);
