import React from 'react';

const PinSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M6.20605 10.9951L3.16575 14.0352C2.94475 14.2562 2.94475 14.6134 3.16575 14.8343C3.27597 14.9445 3.42066 14.9999 3.56536 14.9999C3.71005 14.9999 3.85475 14.9445 3.96497 14.8343L7.00527 11.7943L6.20605 10.9951Z" fill="white" />
      <path d="M15.8347 6.12194L11.8782 2.16574C11.6572 1.94475 11.3 1.94475 11.079 2.16574C10.858 2.38672 10.858 2.74391 11.079 2.96489L11.5922 3.47807L5.36296 6.95444L4.5304 6.12194C4.3094 5.90096 3.95218 5.90096 3.73118 6.12194C3.51018 6.34293 3.51018 6.70012 3.73118 6.9211L11.079 14.2683C11.1892 14.3786 11.3339 14.4339 11.4786 14.4339C11.6233 14.4339 11.768 14.3786 11.8782 14.2683C12.0992 14.0474 12.0992 13.6902 11.8782 13.4692L11.0456 12.6367L14.5223 6.40792L15.0355 6.9211C15.1457 7.03131 15.2904 7.08669 15.4351 7.08669C15.5798 7.08669 15.7245 7.03131 15.8347 6.9211C16.0557 6.70012 16.0557 6.34293 15.8347 6.12194Z" fill="white" />
    </svg>
  );
};

export default PinSvg;
