import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cs from 'classnames';
import s from './styles.module.scss';
import WheelPrize from './WheelPrize';
import CountDown from './CountDown';
import Participants from './Participants';
import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import Wheel from './Wheel';
import useCountdown from '@/hooks/useCountdown';
import { getRaffle, joinRaffle, getRaffleJoining } from '@/services/raffle';
import { WalletContext } from '@/contexts/wallet-context';
import { compareString, formatCurrency } from '@/utils';
import { IPostData } from '@/interfaces/api/post';
import { ROUTE_PATH } from '@/constants/route-path';
import { closeModal, openModal } from '@/state/modal';
import { useDispatch } from 'react-redux';
import { Button, Flex, Text, Skeleton, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { getPlayerPoolProfile } from '@/services/player-share';
import {
  RafflePostContext,
  RafflePostProvider,
} from '@/components/RaffleNFT/RaffleNFTPost/provider';
import NftInfo from '@/components/RaffleNFT/RaffleNFTPost/NftInfo';
import Confettiful from '@/components/RaffleNFT/RaffleNFTPost/Party';
import { convertIpfsToHttp } from '@trustless-computer/dapp-core';
import { PostItemContext } from '@/contexts/post-item';
import { getPostDetail } from '@/services/alpha_tweet';
import useAsyncEffect from 'use-async-effect';
import BigNumber from 'bignumber.js';
import {
  MAX_DECIMAL,
  MIN_DECIMAL,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';

interface IProps {
  raffleId: string | number;
  data: IPostData;
}

const NOT_HOLD_KEY_MODAL = 'NOT_HOLD_KEY_MODAL';

const RaffleNFTPost: React.FC<IProps> = ({
  raffleId,
  data,
}: IProps): React.ReactElement => {
  const { addressL2 } = useContext(WalletContext);
  const { isParentRepost } = useContext(PostItemContext);
  const [isStopped, setIsStopped] = useState(true);

  const [raffleDetailId, setRaffleDetailId] = useState<number | string>(
    raffleId
  );
  const [isFetchPostDetail, setIsFetchPostDetail] = useState<boolean>(
    Boolean(raffleId)
  );

  const [isRaffleStatusDone, setIsRaffleStatusDone] = useState<boolean>(
    data?.raffle_event && data?.raffle_event?.Status === 'done'
  );

  const [wheelDetail, setWheelDetail] = useState<any>();
  const [usersJoining, setUsersJoing] = useState<any[]>([]);
  const intervalRef = useRef<any>();
  const intervalJoiningRef = useRef<any>();
  const dispatch = useDispatch();
  const router = useRouter();
  const { setIsJoin } = useContext(RafflePostContext);
  const refContent = useRef(null);

  const { days, hours, minutes, seconds, ended } = useCountdown(
    wheelDetail?.ExpiredAt
  );

  const isTimeLeft = ended;
  const isWheelSuccess = useMemo((): boolean => {
    return wheelDetail && wheelDetail.Status === 'done';
  }, [wheelDetail]);

  useAsyncEffect(async () => {
    if (isParentRepost && addressL2) {
      setIsFetchPostDetail(false);
      const res: any = await getPostDetail(Number(data.id), addressL2);
      setWheelDetail(res?.raffle_event);
      setRaffleDetailId(res?.raffle_event?.ID);
      setIsRaffleStatusDone(
        res?.raffle_event && res?.raffle_event?.Status === 'done'
      );
      handleGetUsersJoining();
      setIsFetchPostDetail(true);
    }
  }, [data, isParentRepost, addressL2]);

  const gotoWinner = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    router.push(
      `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${wheelDetail?.RaffleSpend?.ToTwitter?.TwitterName}`
    );
  };

  const gotoCreator = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    router.push(
      `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${wheelDetail?.Twitter?.Address}`
    );
  };

  useEffect(() => {
    if (isParentRepost && isFetchPostDetail) {
      if (!isWheelSuccess) setIsStopped(!(isTimeLeft && !isWheelSuccess));
      else {
        if (isRaffleStatusDone) {
          setIsStopped(true);
        } else {
          setTimeout(() => {
            setIsStopped(true);
          }, 3000);
        }
      }
    } else {
      if (!isWheelSuccess) setIsStopped(!(isTimeLeft && !isWheelSuccess));
      else {
        if (isRaffleStatusDone) {
          setIsStopped(true);
        } else {
          setTimeout(() => {
            setIsStopped(true);
          }, 3000);
        }
      }
    }
  }, [
    isTimeLeft,
    isWheelSuccess,
    isRaffleStatusDone,
    isParentRepost,
    isFetchPostDetail,
  ]);

  const isPartyPreload = useMemo(() => {
    return isStopped && isWheelSuccess;
  }, [isStopped, isWheelSuccess]);

  const hasJoined = useMemo(() => {
    return usersJoining.find(item => {
      return compareString(item.user.address, addressL2);
    });
  }, [usersJoining, addressL2]);

  const isOwnerWheel = useMemo(() => {
    return wheelDetail && compareString(wheelDetail?.TwitterAddress, addressL2);
  }, [wheelDetail, addressL2]);

  useEffect(() => {
    handleGetWheelDetail();
  }, [addressL2]);

  useEffect(() => {
    if (isTimeLeft && wheelDetail && !isWheelSuccess) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      const interval = setInterval(handleGetWheelDetail, 5000);
      intervalRef.current = interval;
      return () => {
        clearInterval(interval);
      };
    }
  }, [isTimeLeft, wheelDetail, isWheelSuccess]);

  useEffect(() => {
    handleGetUsersJoining();
    if (intervalJoiningRef.current) {
      clearInterval(intervalJoiningRef.current);
    }
    const interval = setInterval(handleGetUsersJoining, 15000);
    intervalJoiningRef.current = interval;
    return () => {
      clearInterval(interval);
    };
  }, [addressL2, isWheelSuccess]);

  const handleGetWheelDetail = useCallback(async () => {
    if (!addressL2) return;
    try {
      const data = await getRaffle({
        address: addressL2,
        raffleId: raffleDetailId as number,
      });
      if (data) {
        setWheelDetail(data as any);
      }
    } catch (error) {}
  }, [raffleDetailId, addressL2]);

  const handleGetUsersJoining = useCallback(async () => {
    if (!addressL2 || isWheelSuccess) return;
    try {
      const data = await getRaffleJoining({
        address: addressL2,
        raffleId: raffleDetailId as number,
      });
      setUsersJoing(data as any);
    } catch (error) {}
  }, [raffleDetailId, addressL2]);

  const joinWheelEvent = useCallback(async (): Promise<void> => {
    if (!addressL2) return;
    try {
      await joinRaffle({
        address: addressL2,
        raffleId: raffleDetailId as string,
      });
      handleGetUsersJoining();
      setIsJoin(true);
    } catch (error: any) {
      if (error?.code === -1082) {
        showNotAllowNotHolder();
      }
    }
  }, [JSON.stringify(wheelDetail), raffleDetailId, addressL2]);

  const handleBuyKey = useCallback(() => {
    dispatch(closeModal({ id: NOT_HOLD_KEY_MODAL }));
    router.push(`${ROUTE_PATH.ALPHA_TRADE}?address=${data?.user?.address}`);
  }, [data]);

  const showNotAllowNotHolder = useCallback(async () => {
    const playerPoolProfile = await getPlayerPoolProfile(data?.user?.address);

    dispatch(
      openModal({
        id: NOT_HOLD_KEY_MODAL,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: true,
        render: () => (
          <Flex direction={'column'} justifyContent={'center'}>
            <img
              src={`${CDN_URL_ICONS}/IcKeyWheel2.svg`}
              alt="menu-ic"
              style={{ height: '80px' }}
            />
            <Text
              mt={8}
              mb={8}
              fontSize={'18px'}
              fontWeight={400}
              textAlign={'center'}
            >
              Hold at least{' '}
              {formatCurrency(
                wheelDetail?.raffle_event?.MinHoldingRequirement ||
                  wheelDetail?.raffle_event?.Twitter?.MinHoldingRequirement,
                MIN_DECIMAL,
                MAX_DECIMAL
              )}{' '}
              {data?.user?.twitter_name} key to join the raffle
            </Text>
            <Button onClick={handleBuyKey}>Buy keys</Button>
          </Flex>
        ),
      })
    );
  }, [data, JSON.stringify(wheelDetail)]);

  if (wheelDetail === undefined) {
    return (
      <Stack>
        <Skeleton
          ref={refContent}
          isLoaded={wheelDetail}
          width="100%"
          height="389px"
        ></Skeleton>
      </Stack>
    );
  }

  return (
    <>
      {isWheelSuccess && isStopped && (
        <div className={s.notiText}>
          Congrats{' '}
          <span className={s.name} onClick={gotoWinner}>
            {wheelDetail?.RaffleSpend?.ToTwitter?.TwitterName}
          </span>{' '}
          on winning{' '}
          <span onClick={gotoCreator} className={s.name}>
            {' '}
            {wheelDetail?.Twitter?.TwitterName}
          </span>{' '}
          raffle.
        </div>
      )}
      <div
        ref={refContent}
        className={cs(
          s.mainContent,
          s.mainContent__wrapper,
          isWheelSuccess && isStopped
            ? `${s.noPadding} ${s.isWheelSuccess}`
            : '',
          !isWheelSuccess ? s.noGap : ''
        )}
      >
        <div
          className={cs(
            s.mainContent,
            isWheelSuccess && isStopped ? s.noPadding : ''
          )}
        >
          {isPartyPreload && <Confettiful />}
          {!isTimeLeft && (
            <CountDown
              isHide={isWheelSuccess}
              minutes={minutes}
              seconds={seconds}
              hours={hours}
              days={`${days}`}
            />
          )}
          <Wheel
            url={wheelDetail?.PrizeWheelSpend?.ToTwitter?.TwitterAvatar}
            users={usersJoining}
            isWheelSuccess={isWheelSuccess}
            isOwnerWheel={isOwnerWheel}
            isStopped={isStopped}
            isRunning={isTimeLeft}
          />
          {!isTimeLeft && !isWheelSuccess && (
            <>
              <NftInfo
                tokenAddress={wheelDetail?.ERC721Address}
                tokenName={wheelDetail?.ERC721Name}
                tokenId={wheelDetail?.ERC721TokenId}
                wheelDetail={wheelDetail}
                url={convertIpfsToHttp(
                  wheelDetail?.ERC721URI ||
                    `${CDN_URL_IMAGES}/nft-place-min.png`
                )}
              />

              <Participants
                tokenName={wheelDetail?.ERC721Name}
                users={usersJoining}
                isOwnerWheel={isOwnerWheel}
                joinWheelEvent={joinWheelEvent}
                hasJoined={hasJoined}
                wheelCreator={data.user}
              />
            </>
          )}
        </div>

        {isWheelSuccess && isStopped && (
          <WheelPrize
            tokenAddress={wheelDetail?.ERC721Address || ''}
            tokenName={wheelDetail?.ERC721Name || ''}
            tokenId={wheelDetail?.ERC721TokenId || ''}
            tokenUrl={wheelDetail?.ERC721URI || ''}
            twitterAvatar={wheelDetail?.RaffleSpend?.ToTwitter?.TwitterAvatar}
            twitterName={wheelDetail?.RaffleSpend?.ToTwitter?.TwitterName}
            twitterTokenAvatar={
              wheelDetail?.RaffleSpend?.Token?.TwitterUser?.TwitterAvatar
            }
            twitterTokenName={
              wheelDetail?.RaffleSpend?.Token?.TwitterUser?.TwitterName
            }
            rewardAmount={wheelDetail?.RaffleSpend?.Amount}
            rewardPrice={wheelDetail?.BuyPrice}
            users={usersJoining}
            data={data}
            wheelDetail={wheelDetail}
          />
        )}
      </div>
    </>
  );
};

const MemoRaffleNFTPost = memo(RaffleNFTPost);
export default function (props: IProps) {
  return (
    <>
      <RafflePostProvider>
        <MemoRaffleNFTPost {...props} />
      </RafflePostProvider>
    </>
  );
}
