import { ROUTE_PATH } from '@/constants/route-path';
import accountStorage from '@/utils/account.storage';
import { isWebApp } from '@/utils/client';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import Referral from '@/modules/AlphaPWA/Referral';
import { useAlphaTheme } from '@/hooks/useAlphaTheme';
import { WalletContext } from '@/contexts/wallet-context';
import { isMobile } from 'react-device-detect';

const withAlphaMiddleware = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const { onSyncProfile } = useContext(WalletContext);
    const router = useRouter();
    const address = accountStorage.getAddress();
    const isPWA = isWebApp();
    const isAuthenticated = !!address && !!accountStorage.isUserLinkingTwitter;
    const isWelcomeScreen = router.pathname === ROUTE_PATH.ALPHA_MOBILE;
    useAlphaTheme('dark');

    /// Static check
    useEffect(() => {
      // TODO: OTIS kick out
      // if ((!isAuthenticated || !isPWA) && !isWelcomeScreen) {
      // if (!isAuthenticated && !isWelcomeScreen) {
      //   if (router.pathname === ROUTE_PATH.ALPHA_TRADE) {
      //     onSyncProfile();
      //   }
      //   router.push(ROUTE_PATH.ALPHA_MOBILE_KEYS);
      //   return;
      // }
      //
      // if (isAuthenticated && isPWA && isWelcomeScreen) {
      //   router.push(ROUTE_PATH.ALPHA_MOBILE_KEYS);
      //   return;
      // }
    }, [router, isWelcomeScreen]);

    if (isWelcomeScreen) {
      return (
        <>
          <WrappedComponent {...props} />
        </>
      );
    }

    if (isAuthenticated) {
      return (
        <>
          <WrappedComponent {...props} />
        </>
      );
    } else if (!isMobile) {
      return <WrappedComponent {...props} />;
    }
    return <></>;
  };
};

export default withAlphaMiddleware;
