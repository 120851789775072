import { ROUTE_PATH } from '@/constants/route-path';
import { IBodyBuySweetFloorKeys } from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import {
  SLIDE_ALPHA,
  SLIDE_FT,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { ITradeKey } from '@/services/interfaces/trade';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
} from '@/utils';
import { getUrlAvatarTwitter, isPlatformKey } from '@/utils/helpers';
import { SupportPlatform } from '@/utils/supportPlatform';
import { Box, Flex, Text } from '@chakra-ui/react';
import clsx from 'classnames';
import { clone } from 'lodash';
import { useRouter } from 'next/router';
import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IKeysCommon } from '.';
import Avatar from '../../../Profiles/TradeKey/components/avatar';
import IcCheck from './img/icCheck';
import IcFT from './img/icFT';
import IcNB from './img/icNB';
import IcUncheck from './img/icUncheck';
import s from './styles.module.scss';
import { BsClock } from 'react-icons/bs';
import moment from 'moment';
import { PLACE_HOLDER_SORTS } from '@/modules/AlphaPWA/Profiles/TradeKey/pro/itemFilterSort';
import px2rem from '@/utils/px2rem';

interface IProKeyItem extends IKeysCommon {
  userKey: ITradeKey;
  hasSortNew?: boolean;
  hasSortPortfolio?: boolean;
  hasPlaceholderShowTop: string;
  setSelectKeys?: any;
}

const ProKeyItem: React.FC<IProKeyItem> = ({
  userKey,
  selectKeys,
  hasSortNew,
  setSelectKeys,
  hasPlaceholderShowTop,
  hasSortPortfolio,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isUserFT, eth2USDT } = useTradeKey();
  const refItem = useRef<any>();

  const isChecked =
    selectKeys.findIndex(v =>
      compareString(v.token_address, userKey.address)
    ) >= 0;

  const renderPrice = useMemo(() => {
    switch (isPlatformKey(userKey)) {
      case SupportPlatform.FT:
        return (
          <>
            <Text className={s.price}>
              {formatCurrency(userKey?.ft_share_price, 0, 4)} {'ETH'}
            </Text>
            <Text className={s.priceUSD}>
              $
              {formatCurrency(
                parseFloat((userKey?.ft_share_price || '0') as string) *
                  eth2USDT,
                0,
                2
              )}
            </Text>
          </>
        );

      default:
        return (
          <>
            <Text className={s.price}>
              {formatCurrency(userKey?.price, 0, 2, userKey.base_token_symbol)}{' '}
              {userKey.base_token_symbol}
            </Text>
            <Text className={s.priceUSD}>
              ${formatCurrency(userKey?.usd_price, 0, 2)}
            </Text>
          </>
        );
    }
  }, [userKey, eth2USDT]);

  const handleGoToProfile = async () => {
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${userKey.owner}`);
  };

  const onCheckBox = () => {
    const data = userKey;

    if (!isUserFT && compareString(data.side, SLIDE_FT)) {
      return;
    }

    const _selectKeys: IBodyBuySweetFloorKeys[] = clone(selectKeys);
    const _findIndex = _selectKeys.findIndex(v =>
      compareString(v.token_address, data.address)
    );
    if (_findIndex >= 0) {
      _selectKeys.splice(_findIndex, 1);
    } else {
      _selectKeys.push({
        token_address: data.address as string,
        token_amount: 1,
        side: data.side,
        key: data,
        amount_after_fee: 0,
      });
    }
    setSelectKeys(_selectKeys);
    // refFooter.current?.setNumSweep(_selectKeys.length);
  };

  const renderPlatform = () => {
    switch (userKey.side) {
      case SLIDE_FT:
        return (
          <Flex onClick={onCheckBox} className={clsx(s.badgePlatform, s.ft)}>
            <IcFT />
          </Flex>
        );

      default:
        return (
          <Flex onClick={onCheckBox} className={s.badgePlatform}>
            <IcNB />
          </Flex>
        );
    }
  };

  const canCheck = useMemo(() => {
    return (
      (isUserFT && compareString(userKey.side, SLIDE_FT)) ||
      compareString(userKey.side, SLIDE_ALPHA) ||
      compareString(userKey?.base_token_symbol, 'BTC')
    );
  }, [isUserFT, userKey]);

  return (
    <Flex
      flexDirection={'column'}
      className={clsx(
        s.gridItemContainer,
        isChecked && canCheck ? s.itemActive : ''
      )}
    >
      <Flex flexDirection={'column'} className={clsx(s.gridItem)}>
        <Box ref={refItem} onClick={onCheckBox} className={s.image}>
          <Avatar
            name={userKey.user_twitter_name}
            address={userKey.address}
            url={getUrlAvatarTwitter(
              userKey.user_twitter_avatar as string,
              'medium',
              Boolean(
                userKey.user_twitter_avatar &&
                  compareString(userKey.side, SLIDE_ALPHA)
              )
            )}
          />
          {canCheck && (
            <Box className={s.checkBox}>
              {isChecked ? <IcCheck /> : <IcUncheck />}
            </Box>
          )}

          <Flex
            gap={'10px'}
            className={s.wrapPlatform}
            style={{
              left: '8px',
              right: 'unset',
              justifyContent: 'flex-start',
            }}
          >
            {!compareString(hasPlaceholderShowTop, '0') &&
              hasPlaceholderShowTop && (
                <Flex className={s.twFollowerTotal}>
                  <Text>
                    #{userKey.friend_tech_index} on{' '}
                    {compareString(
                      hasPlaceholderShowTop,
                      PLACE_HOLDER_SORTS[2].key
                    )
                      ? 'FT'
                      : compareString(
                          hasPlaceholderShowTop,
                          PLACE_HOLDER_SORTS[3].key
                        )
                      ? 'SA'
                      : ''}
                  </Text>
                </Flex>
              )}
          </Flex>

          <Flex gap={'10px'} className={s.wrapPlatform}>
            <Flex className={s.twFollowerTotal}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" rx="8" fill="white" />
                <path
                  d="M10.5377 4H11.8916L8.93435 7.38869L12.4135 12H9.68966L7.55614 9.20276L5.11477 12H3.76028L6.92332 8.37517L3.58594 4H6.37932L8.30759 6.55614L10.5377 4ZM10.0632 11.1879H10.8135L5.97104 4.76966H5.16663L10.0632 11.1879Z"
                  fill="black"
                />
              </svg>
              <Text>{abbreviateNumber(userKey.twitter_followers_count)}</Text>
            </Flex>
          </Flex>
        </Box>
        <Box py={'4px'} />
        <a href={`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${userKey.owner}`}>
          <Flex
            alignItems={'center'}
            // onClick={handleGoToProfile}
            px={'12px'}
            justifyContent={'space-between'}
          >
            <Text className={s.name}>
              {formatName(userKey?.user_twitter_name as string, 50)}
            </Text>
            {hasSortNew && (
              <Flex
                onClick={onCheckBox}
                gap={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <BsClock />
                <Text
                  style={{
                    fontSize: '11px',
                    opacity: 0.7,
                  }}
                >
                  {moment(userKey?.created_at).fromNow()}
                </Text>
              </Flex>
            )}
          </Flex>
        </a>
        {hasSortPortfolio && (
          <Text
            onClick={onCheckBox}
            px={'12px'}
            mb={'5px'}
            color={'#000000'}
            fontSize={px2rem(16)}
          >
            Portfolio{' '}
            {formatCurrency(
              userKey?.portfolio,
              0,
              2,
              userKey.base_token_symbol
            )}{' '}
            {userKey.base_token_symbol}
          </Text>
        )}
        <Flex
          onClick={onCheckBox}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={'8px'}
          px={'12px'}
        >
          {renderPrice}
        </Flex>

        {renderPlatform()}
      </Flex>
    </Flex>
  );
};

export default ProKeyItem;
