import {
  Box,
  CloseButton,
  Collapse,
  Flex,
  ModalCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import IcBanner33 from './icBanner33';
import s from './styles.module.scss';
import {CLOSE_33_BANNER, CLOSE_FORGING_BANNER} from '@/constants/storage-key';

const Banner = () => {
  const hasCloseForgingBanner = localStorage.getItem(CLOSE_33_BANNER);

  const { isOpen, onToggle } = useDisclosure({
    id: 'collapse_banner_33',
    defaultIsOpen: !hasCloseForgingBanner,
  });

  const onClose = () => {
    localStorage.setItem(CLOSE_33_BANNER, '1');
    onToggle();
  };

  return (
    <Collapse className={s.forgingContainer} in={isOpen} animateOpacity>
      <Flex
        gap={'6px'}
        px="28px"
        py={'20px'}
        color="white"
        bg="black"
        alignItems={'center'}
        mb={"8px"}
      >
        <Flex flex={1} flexDirection={'column'}>
          <Text className={s.forgingTitle}>Let’s (3,3)</Text>
          <Text className={s.forgingDesc}>
            Goodbye, manual (3,3) requests. Say hello to fully automated (3,3) transactions powered by smart contracts — trustless and unstoppable.
          </Text>
        </Flex>
        <IcBanner33 />
      </Flex>
      <CloseButton onClick={onClose} className={s.closeButton} />
    </Collapse>
  );
};

export default Banner;
