// export const TWITTER_SHARE_DM = `GM mate,\n\nI recently discovered @NewBitcoinCity - the friendtech of Bitcoin!\n\nThe UI/UX is seamless and they have amazing features that you don’t see in other SocialFi apps.\n\nIf you join using my referral link, you’ll get 20% off on platform fees when you buy keys 💪\n\nCheck it out: `;
export const TWITTER_SHARE_DM = `GM mate,\n\nI recently discovered @NewBitcoinCity - the friendtech of Bitcoin!\n\nThe UI/UX is seamless and they have amazing features that you don’t see in other SocialFi apps.\n\nCheck it out: `;

// export const TWITTER_SHARE_TWEET = `I recently discovered @NewBitcoinCity - the @friendtech of Bitcoin!\n\nThe UI/UX is seamless and they have amazing features that you don’t see in other SocialFi apps.\n\nJoin using my referral link and get 20% off on platform fees: `;

export const TWITTER_SHARE_TWEET = `I recently discovered @NewBitcoinCity - the friendtech of #Bitcoin!\n\nIt's the best SoFi app I have tried:\n\n- 8% creator royalty\n- No deposit or wallet needed to join\n- BTC, ETH & AVAX accepted\n- Tons of unique features\n\nJoin with my referral link to earn 1,000 airdrop points now: `;

export const MY_GROUP_OWNER_TWEET_GIVE_AWAY_DEPOSIT = `Spreading joy in my circle!\n
I just gifted red packets to my key holders.\n
Join us at @NewBitcoinCity and share the love.\n
`

export const OWNER_TWEET_GIVE_AWAY_DEPOSIT = (owner: string) => `Spreading joy in @${owner} circle!\n
I just gifted red packets to @${owner} key holders.\n
Join us at @NewBitcoinCity and share the love.\n
`

export const OWNER_TWEET_GIVE_AWAY_RECEIVE = (owner: string, keyHolder: string) => `Exciting times in @${owner} circle.\n
I was just gifted @${keyHolder} keys.\n
Join the fun and spread the cheer at @NewBitcoinCity.\n
`

export const TWEET_GIVE_AWAY_DEPOSIT = (owner: string, sender: string) => `Good vibes are flowing in @${owner} circle.\n
@${sender} just gifted red packets to @${owner} key holders.\n
Join the fun and spread the cheer at @NewBitcoinCity.\n
`

export const MY_GROUP_TWEET_GIVE_AWAY_DEPOSIT = (sender: string) => `Exciting times in my circle.\n
Shout out to @${sender} for gifting red packets to my key holders.\n
Come be a part of my circle at @NewBitcoinCity and spread the good vibes.\n
`

export const TWEET_GIVE_AWAY_RECEIVE = ({ owner, receiver, keyHolder }: {
  owner: string,
  receiver: string,
  keyHolder: string
}) => `Good vibes are flowing in @${owner} circle.\n
@${receiver} was just gifted @${keyHolder} keys!\n
Join the fun and spread the cheer at @NewBitcoinCity.\n
`
