import { CDN_URL_ICONS } from '@/configs';
import { AssetsContext } from '@/contexts/assets-context';
import { formatCurrency } from '@/utils';
import { useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import s from './Treasury.module.scss';
import TreasuryDetail from './TreasuryDetail';

const Treasury = ({ onClick }: { onClick?: () => void }) => {
  // const [balance, setBalance] = useState('0');
  const [showDetail, setShowDetail] = useState(false);

  const { treasuryBalance: balance } = useContext(AssetsContext);

  // const _balance = useMemo(() => parseFloat(balance), [balance]);

  return (
    <>
      <div
        // isLoaded={!loading}
        className={s.wrapper}
        onClick={() => (onClick ? onClick() : setShowDetail(true))}
        // borderRadius={'100px'}
      >
        <span>Treasury</span>
        {/* <img
          width={24}
          alt={'logo-bitcoin-white'}
          src={`${CDN_URL_ICONS}/money-gold.png`}
          style={{ marginRight: '4px' }}
        /> */}
        <img
          alt={'logo-bitcoin-white'}
          style={{ marginLeft: '4px' }}
          src={`${CDN_URL_ICONS}/logo-bitcoin-white.svg`}
        ></img>
        {formatCurrency(balance, 0, 3)}
      </div>
      {createPortal(
        <TreasuryDetail
          show={showDetail}
          onClose={() => setShowDetail(false)}
          balance={balance.toString()}
        />,
        document.body
      )}
    </>
  );
};

export default Treasury;
