const EnvelopIcon = () => {
  return (
    <svg
      width="135"
      height="133"
      viewBox="0 0 135 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.2749 106.521C94.3823 106.521 116.366 84.7025 116.366 57.7603C116.366 30.8182 94.3823 9 67.2749 9C40.1674 9 18.184 30.8182 18.184 57.7603C18.184 84.7025 40.1674 106.521 67.2749 106.521Z"
        fill="white"
        fill-opacity="0.12"
      />
      <path
        d="M115.112 34.4546C116.983 34.4546 118.5 32.9375 118.5 31.0662C118.5 29.1948 116.983 27.6777 115.112 27.6777C113.24 27.6777 111.723 29.1948 111.723 31.0662C111.723 32.9375 113.24 34.4546 115.112 34.4546Z"
        fill="#B1E3FF"
      />
      <path
        d="M111.723 23.464C113.001 23.464 114.037 22.428 114.037 21.15C114.037 19.872 113.001 18.8359 111.723 18.8359C110.445 18.8359 109.409 19.872 109.409 21.15C109.409 22.428 110.445 23.464 111.723 23.464Z"
        fill="#FFE899"
      />
      <path
        d="M18.0042 31.0656C19.2823 31.0656 20.3183 30.0296 20.3183 28.7515C20.3183 27.4735 19.2823 26.4375 18.0042 26.4375C16.7262 26.4375 15.6902 27.4735 15.6902 28.7515C15.6902 30.0296 16.7262 31.0656 18.0042 31.0656Z"
        fill="#95A4FC"
      />
      <path
        d="M12.7975 81.9749C15.171 81.9749 17.095 80.0509 17.095 77.6774C17.095 75.3039 15.171 73.3799 12.7975 73.3799C10.4241 73.3799 8.5 75.3039 8.5 77.6774C8.5 80.0509 10.4241 81.9749 12.7975 81.9749Z"
        fill="#BAEDBD"
      />
      <g filter="url(#filter0_d_17828_30549)">
        <path
          d="M112.502 45.6876V90.4319C112.502 90.6165 112.502 90.7087 112.502 90.8932C112.41 93.4764 111.211 95.6906 109.366 97.2589C107.797 98.6428 105.675 99.5653 103.369 99.5653H31.1322C29.5639 99.5653 28.0878 99.1963 26.7962 98.4583C26.2426 98.1815 25.6891 97.8125 25.2278 97.3512C24.213 96.5209 23.2904 95.3215 22.8291 94.03C22.4601 93.1074 22.1833 92.0926 22.1833 91.0777V45.6876C22.1833 45.6876 25.9659 42.4586 31.409 38.0303C37.0366 33.3252 44.4171 27.2363 50.8751 21.8855C54.5653 18.7487 54.1963 19.1178 59.9162 14.3204C64.2523 10.6302 70.618 10.6302 74.954 14.3204C76.0611 15.243 77.2604 16.3501 78.3675 17.2726C80.7662 19.21 83.3493 21.4242 86.117 23.7306C91.9292 28.5279 98.2949 33.8788 103.277 38.0303C108.351 42.1819 112.041 45.3186 112.502 45.6876Z"
          fill="url(#paint0_linear_17828_30549)"
        />
      </g>
      <path
        d="M103.277 37.9384V53.991L87.5009 63.4934L82.7958 66.3533L72.8321 72.35L68.127 75.2099L66.0974 76.4093L64.16 75.2099L59.7317 72.35L50.2293 66.3533L45.801 63.4934L31.2245 54.36V38.0306C36.8521 33.3255 44.2326 27.2366 50.6906 21.8857L86.0248 23.6386C91.9292 28.5282 98.2949 33.7868 103.277 37.9384Z"
        fill="#989FB0"
      />
      <path
        d="M100.14 25.2069V53.4373L85.7479 62.0172L81.5041 64.6003L72.3707 69.9512L68.127 72.5344L66.2818 73.5492L64.529 72.5344L60.4697 69.9512L51.7976 64.6003L47.7383 62.0172L34.4534 53.7141V25.2069C34.4534 22.2547 36.852 19.856 39.8965 19.856H94.6968C97.649 19.7637 100.14 22.2547 100.14 25.2069Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M22.5524 45.5039L65.9129 48.3639L111.857 45.5039L110.104 53.7147C110.104 53.7147 66.9277 76.2253 67.2045 76.2253C67.4812 76.2253 22.6446 49.5632 22.6446 49.5632L22.5524 45.5039Z"
        fill="url(#paint1_linear_17828_30549)"
      />
      <path
        d="M112.502 45.6875V90.8931C112.41 93.4763 111.211 95.6905 109.366 97.2588C107.797 98.6427 105.675 99.5652 103.369 99.5652H31.1322C29.5639 99.5652 28.0878 99.1962 26.7962 98.4581C26.2426 98.1814 25.6891 97.8124 25.2278 97.3511C24.213 96.5208 23.2904 95.3214 22.8291 94.0298C22.4601 93.1073 22.1833 92.0925 22.1833 91.0776V90.9854V45.6875L62.5916 70.9658L66.0974 73.1799L67.2967 72.4419L68.4038 71.7961L112.502 45.6875Z"
        fill="#738DFF"
      />
      <path
        d="M109.458 97.2588C107.89 98.6427 105.768 99.5652 103.461 99.5652H31.1322C29.5639 99.5652 28.0878 99.1962 26.7962 98.4581C26.2426 98.1814 25.6891 97.8124 25.2278 97.3511C24.213 96.5208 23.2904 95.3214 22.8291 94.0298C22.4601 93.1073 22.1833 92.0925 22.1833 91.0776V90.9854V45.6875L26.8884 48.5474L67.2967 72.4419L68.4038 73.0876L68.4961 73.1799L104.753 94.5834L109.458 97.2588Z"
        fill="url(#paint2_linear_17828_30549)"
      />
      <path
        d="M68.4038 71.7036V74.8403L26.7961 98.4579C26.2426 98.1812 25.689 97.8121 25.2278 97.3509C24.2129 96.5205 23.2904 95.3212 22.8291 94.0296L63.3297 70.043L66.8354 72.2571L67.2967 72.4416L68.4038 71.7036Z"
        fill="url(#paint3_linear_17828_30549)"
      />
      <path
        d="M22.1833 45.6875L67.2967 72.4419"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M112.502 45.6875V90.8931C112.41 93.4763 111.211 95.6905 109.366 97.2588C107.797 98.6427 105.675 99.5652 103.369 99.5652H31.1322C29.5638 99.5652 28.0877 99.1962 26.7961 98.4581C26.2426 98.1814 25.6891 97.8124 25.2278 97.3511L29.8406 94.5834L66.0974 73.1799L67.2967 72.4419L68.4038 71.7961L107.705 48.4552L112.502 45.6875Z"
        fill="url(#paint4_linear_17828_30549)"
      />
      <path
        d="M112.502 45.6875L68.4038 71.7038L67.2967 72.4419L66.0974 73.1799L25.2278 97.2588"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M101.616 77.3324H77.6294C77.0759 77.3324 76.6146 76.8711 76.6146 76.3176C76.6146 75.764 77.0759 75.3027 77.6294 75.3027H101.616C102.17 75.3027 102.631 75.764 102.631 76.3176C102.723 76.8711 102.262 77.3324 101.616 77.3324Z"
        fill="#CED7E2"
      />
      <path
        d="M101.616 84.2513H77.6294C77.0759 84.2513 76.6146 83.79 76.6146 83.2365C76.6146 82.683 77.0759 82.2217 77.6294 82.2217H101.616C102.17 82.2217 102.631 82.683 102.631 83.2365C102.723 83.79 102.262 84.2513 101.616 84.2513Z"
        fill="#CED7E2"
      />
      <path
        d="M106.598 98.6431L109.55 97.167C109.458 97.2592 109.458 97.2592 109.366 97.3515C109.366 97.3515 109.366 97.3515 109.273 97.3515L106.598 98.6431Z"
        fill="#526DFF"
      />
      <path
        d="M64.988 55.1292C64.273 55.1292 63.6 54.8664 63.0532 54.297L53.7578 44.6176C52.7062 43.5227 52.7062 41.6832 53.7578 40.5882C54.8093 39.4933 56.5758 39.4933 57.6274 40.5882L64.988 48.2529L82.3171 30.2519C83.3686 29.1569 85.1351 29.1569 86.1867 30.2519C87.2382 31.3468 87.2382 33.1863 86.1867 34.2813L66.9228 54.297C66.4181 54.8664 65.703 55.1292 64.988 55.1292Z"
        fill="url(#paint5_linear_17828_30549)"
      />
      <defs>
        <filter
          id="filter0_d_17828_30549"
          x="0.18335"
          y="0.552734"
          width="134.319"
          height="132.013"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17828_30549"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17828_30549"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_17828_30549"
          x1="67.3067"
          y1="12.5844"
          x2="67.3067"
          y2="78.2794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17828_30549"
          x1="67.1648"
          y1="85.9651"
          x2="67.1648"
          y2="47.5741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8A90B5" />
          <stop offset="1" stopColor="#A7AED2" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17828_30549"
          x1="65.7922"
          y1="44.4413"
          x2="65.7922"
          y2="100.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17828_30549"
          x1="48.2189"
          y1="86.8526"
          x2="44.6813"
          y2="81.6749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5D8E4" />
          <stop offset="1" stopColor="#E1E4F0" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17828_30549"
          x1="68.8366"
          y1="44.4413"
          x2="68.8366"
          y2="100.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_17828_30549"
          x1="69.9749"
          y1="30.3234"
          x2="69.9749"
          y2="44.7477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EnvelopIcon;
