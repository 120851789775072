// ETH
export const BASE_CHAIN_ID = process.env.NEXT_PUBLIC_ETH_CHAIN_ID;
export const BASE_EXPLORER = process.env.NEXT_PUBLIC_ETH_EXPLORER;
export const BASE_RPC = process.env.NEXT_PUBLIC_ETH_RPC;
export const BASE_SYMBOL = process.env.NEXT_PUBLIC_ETH_SYMBOL;
// FT
export const FT_ADDRESS: string = process.env.NEXT_PUBLIC_FT_ADDRESS!;

export const PUBLIC_BASE_RPC = [
  'https://base.meowrpc.com',
  'https://1rpc.io/base',
  'https://base.publicnode.com'
];
