import { getTokenRate } from '@/services/bitcoin';
import { RETA_BTC_2_USDT } from '@/constants/storage-key';
import { useEffect, useState } from 'react';

export const useRateBTCUSDT = (isNew = false) => {
  const [rate, setRate] = useState<number | undefined>(undefined);
  function timestampToDays(dateCurrent: string): number {
    const currentDate = new Date().getTime();
    const timeDifference = currentDate - new Date(dateCurrent).getTime();
    const days = timeDifference / (1000 * 60 * 60 * 24);

    return days;
  }

  const saveRate = async (): Promise<number> => {
    const rate = await getTokenRate('BTC');
    localStorage.setItem(
      RETA_BTC_2_USDT,
      JSON.stringify({ date: new Date(), rate })
    );
    return rate;
  };

  const getRateBTCUSDT = async (isNew = false): Promise<void> => {
    const rateLocal = localStorage.getItem(RETA_BTC_2_USDT);

    if (rateLocal) {
      const parseRate = JSON.parse(rateLocal);
      if (timestampToDays(parseRate.date) > 1 || isNew) {
        const rate = await saveRate();
        setRate(rate);
      }
      setRate(parseRate.rate);
    } else {
      const rate = await saveRate();
      setRate(rate);
    }
  };

  useEffect(() => {
    getRateBTCUSDT(isNew);
  }, [isNew]);

  return rate;
};
