import React, { useRef, useEffect, useMemo, useState } from 'react';

import useApi from '@/hooks/useApi';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getLastReaction } from '@/services/alpha_tweet';

import { EMOJI_REACTIONS } from '../Emoji';
import s from './styles.module.scss';
import { useIsInViewportOnce } from '@/hooks/useIsInViewportOnce';

const ReactionList: React.FC<{
  postId: number;
  liked: number;
  address: string | undefined;
  isActionLiked: boolean;
  className?: string;
}> = ({ postId, address, isActionLiked, className = '', liked }) => {
  // console.log('🚀 ~ isActionLiked:', isActionLiked);
  const sectionRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewportOnce(sectionRef, { threshold: 0 });
  const [fetchOnly, setFetchOnly] = useState<number>(0);
  const [listReactions, setListReactions] = useState<any>();

  useEffect(() => {
    if (isInViewport && isActionLiked) {
      setFetchOnly(prevState => prevState + 1);
    }
  }, [isInViewport, isActionLiked]);

  const { data: reactionsList } = useApi(
    getLastReaction,
    {
      postId,
      page: 1,
      limit: 7,
      address,
      isActionLiked: fetchOnly,
    },
    { shouldFetch: isInViewport }
  );

  const listReactionsMM = useMemo(() => {
    return reactionsList;
  }, [reactionsList]);

  useEffect(() => {
    if (listReactionsMM.length) {
      setListReactions(listReactionsMM);
    }
  }, [listReactionsMM]);

  return (
    <div
      className={`${s.reactionList} ${
        liked > 0 ? s.isHasLiked : ''
      } ${className}`}
      ref={sectionRef}
    >
      {listReactions?.map((reaction: any, index: number) => (
        <div
          className={s.reactionItem}
          key={`reaction_${reaction?.id || index}`}
        >
          <div className={s.reactionItem_avatar}>
            <Avatar url={reaction?.user?.twitterAvatar} width={28} />
          </div>
          <div className={s.reactionItem_emoji}>
            {EMOJI_REACTIONS.find(
              item => item?.type === reaction?.reactionType
            )?.icon() || EMOJI_REACTIONS[0]?.icon()}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(ReactionList);
