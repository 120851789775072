import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import AppLoading from '@/components/AppLoading';
import { ClientOs } from '@/enums/client';
// import useApiInfinite from '@/hooks/useApiInfinite';
// import { getChatGroup } from '@/services/chat/tribe';
import { detectClientOs } from '@/utils/client';
// import { useRouter } from 'next/router';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import { Box, Grid, GridItem } from '@chakra-ui/react';

import Item from './Item';
import SuggestionBlock from './SuggestionBlock';

import s from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectCommonReducer, setShowKeyLeft } from '@/state/common';
import { compareString } from '@/utils';
import cs from 'classnames';
import dynamic from 'next/dynamic';

const Chat = dynamic(() => import('../../Chat'), {
  ssr: false,
});

const CirclesTabV2: React.FC<any> = forwardRef(
  ({ isActive }: { isActive: boolean }, ref) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const { listChatGroup, setRoomId } = useDM();
    const {
      dataInfinite,
      loadMore,
      refresh,
      isLoadingMore,
      isReachingEnd,
      hasFirstFetching,
      isRefreshing,
    } = listChatGroup;

    const clientOs = detectClientOs();
    const [isRefreshPullDown, setIsRefreshPullDown] = useState<boolean>();

    const [dmDetail, setDmDetail] = useState<string | undefined>();

    const dispatch = useDispatch();
    const showKeyLeft = useSelector(selectCommonReducer).showKeyLeft;
    const showKeyRight = useSelector(selectCommonReducer).showKeyRight;

    const initOwnRoomId = useRef();

    useImperativeHandle(ref, () => ({
      dmDetail: dmDetail,
    }));

    const onClickDmDetail = (roomId: string) => {
      if (compareString(showKeyLeft, '-1')) {
        dispatch(setShowKeyLeft('2'));
      }
      if (dmDetail !== roomId) {
        onSetActiveRoom(roomId);
      }
    };

    const onSetActiveRoom = (roomId: string) => {
      setDmDetail(roomId);
      setRoomId(undefined);
      setTimeout(() => {
        setRoomId(roomId);
      }, 200);
    };

    useEffect(() => {
      if (isActive && dmDetail) {
        setRoomId(dmDetail);
        setDmDetail(dmDetail);
      }
    }, [isActive]);

    useEffect(() => {
      if (!initOwnRoomId.current && dataInfinite && dataInfinite.length > 0) {
        const initRoomId = (dataInfinite[0] as any).id;
        initOwnRoomId.current = initRoomId;
        onClickDmDetail(initRoomId);
      }
    }, [dataInfinite]);

    useEffect(() => {
      if (isRefreshPullDown && isRefreshing === false) {
        setIsRefreshPullDown(false);
      }
    }, [isRefreshing, isRefreshPullDown]);

    const gridTemplate = useMemo(() => {
      if (!dmDetail || compareString(showKeyLeft, '-1')) {
        return '1fr 0';
      } else {
        if (compareString(showKeyRight, '1')) {
          return '1fr 2fr';
        } else {
          if (compareString(showKeyLeft, '1')) {
            return '1fr 3fr';
          } else {
            return '1fr 2fr';
          }
        }
      }
    }, [dmDetail, showKeyRight, showKeyLeft]);

    return (
      <Grid
        className={cs(s.wrapper, s.visible)}
        ref={contentRef}
        templateColumns={gridTemplate}
      >
        <GridItem>
          <div className={s.listSearchResults}>
            {hasFirstFetching === false ? (
              <div className={s.loadingMore}>
                <AppLoading />
              </div>
            ) : (
              <InfiniteScroll
                className="disable-scrollbar-x"
                height={
                  clientOs === ClientOs.Ios
                    ? 'calc(100dvh - 160px)'
                    : 'calc(100dvh - 100px)'
                }
                dataLength={dataInfinite?.length || 500}
                hasMore={isReachingEnd === false}
                refreshFunction={() => {
                  setIsRefreshPullDown(true);
                  refresh();
                }}
                pullDownToRefresh
                pullDownToRefreshThreshold={100}
                loader={
                  isLoadingMore && (
                    <div className={s.loadingMore}>
                      <AppLoading />
                    </div>
                  )
                }
                next={loadMore}
              >
                {isRefreshPullDown && (
                  <div className={s.loadingMore}>
                    <AppLoading />
                  </div>
                )}
                {dataInfinite?.map((r: any, idx) => (
                  <Item
                    animation={false}
                    key={r.id}
                    room={r}
                    order={idx}
                    isNoStyleAnimation
                    onItemClicked={onClickDmDetail}
                  />
                ))}

                {/* {isReachingEnd && <SuggestionBlock isActive={isActive} />} */}
              </InfiniteScroll>
            )}
          </div>
        </GridItem>

        <GridItem
          w="100%"
          h="100%"
          borderLeft={'1px solid #353945'}
          borderRight={'1px solid #353945'}
          position={'relative'}
        >
          {isActive && dmDetail && (
            <Box w="100%" h="100%">
              <Chat />
            </Box>
          )}
          {!isActive && <Box w="100%" h="100%" />}
        </GridItem>
      </Grid>
    );
  }
);

export default React.memo(CirclesTabV2);
