import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import PWALayout from '@/layouts/AlphaLayout';
import MainLayout from '@/layouts/MainLayout';
import AlphaPage from '@/modules/Alpha';
import ModulePageKeys from '@/modules/AlphaPWA/Keys';
import { isMobile } from 'react-device-detect';
import s from './styles.module.scss';
import ModuleProKeys from '@/modules/AlphaPWA/Keys/pro';
import ModalJoinFromFT from '@/components/ModalJoinFromFT';

const Alpha = () => {
  return isMobile ? (
    <>
      <MainLayout
        isWallet={true}
        isLight={false}
        hideFooter
        cssMain={false}
        classNameMain={s.cssAlpha}
        isArcade
      >
        <div className={s.content}>
          <AlphaPage />
        </div>
      </MainLayout>
    </>
  ) : (
    <PWALayout>
      <ModuleProKeys />
      <ModalJoinFromFT />
    </PWALayout>
  );
};

export async function getServerSideProps() {
  return {
    props: {
      seoInfo: {
        title: 'Alpha',
        description:
          'The crypto super app that brings crypto to the mainstream.',
        image: `${CDN_URL_IMAGES}/metadata-2.png`,
        favicon: `${CDN_URL_ICONS}/favicon-alpha-wtf.svg`,
      },
    },
  };
}

export default Alpha;

// import { CDN_URL_IMAGES } from '@/configs';
// import MainLayout from '@/layouts/MainLayout';
// import HomePageV2 from '@/modules/HomePageV2';
// import s from './styles.module.scss';

// const HomeV2 = () => {
//   return (
//     <MainLayout
//       isWallet={true}
//       isLight={false}
//       cssMain={false}
//       bgImg={`${CDN_URL_IMAGES}/home-shadow-banner.svg`}
//       cssBg={s.cssBg}
//       isHome
//     >
//       <HomePageV2 />
//     </MainLayout>
//   );
// };

// export async function getServerSideProps() {
//   return {
//     props: {
//       seoInfo: {
//         title: 'New Bitcoin City',
//         description:
//           'New Bitcoin City is a radically new way to explore Bitcoin - beyond just a currency.',
//         image: `${CDN_URL_IMAGES}/home_meta.png`,
//       },
//     },
//   };
// }

// export default HomeV2;

// import CityMapMobile from '@/components/CityMapMobile';
// import Location from '@/components/Location';
// import LocationAnimation from '@/components/LocationAnimation';
// import SvgInset from '@/components/SvgInset';
// import { CDN_URL } from '@/configs';
// import useWindowSize from '@/hooks/useWindowSize';
// import AboutUsModal from '@/modules/HomePage/AboutUsModal';
// import Welcome from '@/modules/HomePage/Welcome';
// import { Box, Img, PlacementWithLogical } from '@chakra-ui/react';
// import Link from 'next/link';
// import { useEffect, useRef, useState } from 'react';
// import s from './styles.module.scss';

// const CITY_ASSET_URL = `${CDN_URL}/pages/city`;

// type locationMapProps = {
//   imgSrc: string;
//   gridArea: string;
//   id: number;
//   isAnimated?: boolean;
//   placement?: PlacementWithLogical | undefined;
// };

// const Newcity = () => {
//   const landRef = useRef<HTMLImageElement>(null);
//   const [showAboutUs, setShowAboutUs] = useState(false);
//   const [cityHeight, setCityHeight] = useState('auto');
//   const [seaHeight, setSeaHeight] = useState('100vh');

//   const mapRef = useRef<HTMLDivElement>(null);

//   const { mobileScreen } = useWindowSize();

//   const moveYaxis = -4;

//   const locationMap: locationMapProps[] = [
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/generative_village-02.svg`,
//       isAnimated: true,
//       gridArea: `${15 + moveYaxis}/39/${37 + moveYaxis}/61`,
//       id: 0,
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/perceptrons_square-03.svg`,
//       isAnimated: true,
//       gridArea: `${34 + moveYaxis}/74/${50 + moveYaxis}/89`,
//       id: 1,
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/defi_district2.svg`,
//       isAnimated: true,
//       gridArea: `${17 + moveYaxis}/77/${31 + moveYaxis}/95`,
//       id: 2,
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/funsociety.svg`,
//       isAnimated: true,
//       gridArea: `${30 + moveYaxis}/17/${45 + moveYaxis}/39`,
//       id: 3,
//     },
//     // {
//     //   imgSrc: `${CITY_ASSET_URL}/map-v3/dapps_valley-01.svg`,
//     //   isAnimated: true,
//     //   gridArea: `${57 + moveYaxis}/64/${67 + moveYaxis}/88`,
//     //   id: 4,
//     // },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/industry_zone-01.svg`,
//       isAnimated: true,
//       gridArea: `${17 + moveYaxis}/8/${30 + moveYaxis}/32`,
//       id: 5,
//       placement: 'top',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/nft_bazaar-01.svg`,
//       isAnimated: true,
//       gridArea: `${52 + moveYaxis}/42/${66 + moveYaxis}/62`,
//       id: 6,
//     },
//     // {
//     //   imgSrc: `${CITY_ASSET_URL}/map-v3/unexplored_lands-05.svg`,
//     //   isAnimated: true,
//     //   gridArea: `${55 + moveYaxis}/116/${76 + moveYaxis}/160`,
//     //   id: 7,
//     //   placement: 'bottom',
//     // },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/gm_token-04.svg`,
//       gridArea: `${32 + moveYaxis}/96/${55 + moveYaxis}/146`,
//       isAnimated: true,
//       id: 8,
//       placement: 'bottom',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/the_forge%2520(1).svg`,
//       isAnimated: true,
//       gridArea: `${47 + moveYaxis}/14/${65 + moveYaxis}/36`,
//       id: 9,
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/bitcoin-animated-02.svg`,
//       isAnimated: true,
//       gridArea: `${70 + moveYaxis}/12/${96 + moveYaxis}/51`,
//       id: 10,
//       placement: 'top',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/ethereum-02.svg`,
//       isAnimated: true,
//       gridArea: `${61 + moveYaxis}/91/${89 + moveYaxis}/130`,
//       id: 11,
//       placement: 'top',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/nakamoto_bridge.svg`,
//       isAnimated: true,
//       gridArea: `${66 + moveYaxis}/44/${86 + moveYaxis}/71`,
//       id: 12,
//       placement: 'right',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/vitaliz_bridge.svg`,
//       isAnimated: true,
//       gridArea: `${66 + moveYaxis}/80/${77 + moveYaxis}/98`,
//       id: 13,
//       placement: 'left',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/gas_station-02.svg`,
//       isAnimated: true,
//       gridArea: `${35 + moveYaxis}/45/${49 + moveYaxis}/64`,
//       id: 14,
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/bns_office%2520(1).svg`,
//       isAnimated: true,
//       gridArea: `${50 + moveYaxis}/73/${68 + moveYaxis}/86`,
//       id: 15,
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/unexplored_lands3.svg`,
//       isAnimated: true,
//       gridArea: `${54 + moveYaxis}/124/${76 + moveYaxis}/160`,
//       id: 16,
//       placement: 'bottom',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/map-v3/ordinals_bridge-01.svg`,
//       isAnimated: true,
//       gridArea: `${67 + moveYaxis}/32/${81 + moveYaxis}/57`,
//       id: 17,
//       placement: 'top',
//     },
//     {
//       imgSrc: `${CITY_ASSET_URL}/soul2.svg`,
//       isAnimated: true,
//       gridArea: `${33 + moveYaxis}/145/${48 + moveYaxis}/157`,
//       id: 18,
//       placement: 'bottom',
//     },
//   ];

//   const handleResizeBackground = () => {
//     if (mapRef && mapRef.current) {
//       const height = mapRef.current.clientHeight;
//       const browserHeight = window.innerHeight;
//       const browserWidth = window.innerWidth;

//       const bgHeight =
//         browserWidth < 1439 ? 'auto' : height > browserHeight ? 'auto' : 'auto';

//       setCityHeight(bgHeight);
//       setSeaHeight(
//         height > browserHeight
//           ? `calc(100vh + ${height - browserHeight}px)`
//           : '100vh'
//       );
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('onload', handleResizeBackground);
//     window.addEventListener('resize', handleResizeBackground);

//     return () => {
//       window.removeEventListener('onload', handleResizeBackground);
//       window.removeEventListener('resize', handleResizeBackground);
//     };
//   }, []);

//   useEffect(() => {
//     handleResizeBackground();
//   }, [mapRef?.current?.clientHeight]);

//   return (
//     <div className={s.wrapper}>
//       <div className={s.socialShare}>
//         <Img
//           cursor={'pointer'}
//           _hover={{ opacity: 0.8 }}
//           src={`${CDN_URL}/pages/city/v2/gm.svg`}
//           onClick={() => setShowAboutUs(true)}
//           className={s.gm}
//         ></Img>
//         <Link href={'https://discord.gg/yNbatuGMDG'} target="_blank">
//           <SvgInset
//             size={32}
//             svgUrl={`${CDN_URL}/icons/ic-discord-18x18.svg`}
//           />
//         </Link>
//         <Link href={'https://twitter.com/NewBitcoinCity'} target="_blank">
//           <SvgInset
//             size={32}
//             svgUrl={`${CDN_URL}/icons/ic-twitter-18x18.svg`}
//           />
//         </Link>
//       </div>
//       <Welcome />

//       {mobileScreen ? (
//         <div className={s.city_map_grid_mobile}>
//           <div className={s.sea_bg}>
//             <Img src={`${CDN_URL}/pages/city/sea.svg`}></Img>
//           </div>
//           <CityMapMobile className={s.cityMap} />
//         </div>
//       ) : (
//         <>
//           <Box className={s.sea_bg} height={seaHeight} zIndex={'-1'}>
//             <LocationAnimation
//               src={`${CITY_ASSET_URL}/animation/sea-animated.svg`}
//             ></LocationAnimation>
//           </Box>
//           <Box
//             className={s.city_map_grid}
//             ref={mapRef}
//             height={cityHeight}
//             zIndex={1}
//           >
//             <Box gridArea={`${10 + moveYaxis} / 1 / ${75 + moveYaxis} / 100`}>
//               <Img
//                 ref={landRef}
//                 src={`${CDN_URL}/pages/city/land-bg-02.svg`}
//                 alt="mainland background"
//                 width={'100%'}
//                 height={'100%'}
//                 position={'relative'}
//                 zIndex={2}
//                 objectFit={'cover'}
//               />
//             </Box>
//             {locationMap.map(location => (
//               <Location
//                 className={s.location}
//                 {...location}
//                 key={location.id}
//               />
//             ))}
//           </Box>
//         </>
//       )}

//       <AboutUsModal show={showAboutUs} setShow={setShowAboutUs}></AboutUsModal>
//     </div>
//   );
// };

// export default Newcity;
