import React from 'react';

const ReactionHallowin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#333" />
      <path
        d="M23.09,9.37a9.58,9.58,0,0,0-10.93.74c-5.42,4.32-6.61,6.16.83,18.83l1.19-1.84,3.68,2.39,1.19-1.84L22.73,30l2.38-3.68L27,27.55S34.11,16.53,23.09,9.37Z"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.75px"
      />
      <circle cx="13.5" cy="16.5" r="2.5" />
      <circle cx="20.5" cy="15.5" r="2.5" />
      <circle cx="18.5" cy="21.5" r="1.5" />
    </svg>
  );
};

export default ReactionHallowin;
