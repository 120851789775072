import OpenedEnvelop from '@/components/OpenedEnvelop';
import { IPostData } from '@/interfaces/api/post';
import React from 'react';
import s from './styles.module.scss';
import { linkifyText } from '@/utils/dom';
import DOMPurify from 'dompurify';
import { CDN_URL_ICONS } from '@/configs';

interface IProps {
  data: IPostData;
  isSendLixi: boolean;
  isOpenLixi: boolean;
  isRainLixi: boolean;
}

const LixiPost: React.FC<IProps> = ({
  data,
  isSendLixi,
  isOpenLixi,
  isRainLixi,
}): React.ReactElement => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className={s.lixiPost}>
      <div className={s.content}>
        <span
          onClick={onClick}
          dangerouslySetInnerHTML={{
            __html: linkifyText(DOMPurify.sanitize(data?.content)),
          }}
        />
        {data?.donate_history?.NumOfKeys && isSendLixi && (
          <img
            className={s.lixiIcon}
            src={`${CDN_URL_ICONS}/feed-luckey-money-ic.svg`}
            alt="feed-luckey-money-ic"
          />
        )}
      </div>
      {data?.donate_spent && isOpenLixi && (
        <div className={s.envelopWrapper}>
          <OpenedEnvelop
            donatorName={
              data?.donate_spent?.Token?.TwitterUser?.TwitterName ||
              data?.donate_spent?.Token?.TwitterUser?.TwitterUserName
            }
            avatar={
              data?.donate_spent?.Token?.TwitterUser?.TwitterAvatar ||
              data?.donate_spent?.Token?.TwitterUser?.Avatar
            }
            keyNum={data?.donate_spent?.Amount}
          />
        </div>
      )}
      {data?.donate_history?.NumOfKeys && isRainLixi && (
        <img
          className={s.rainLixiIcon}
          src={`${CDN_URL_ICONS}/ic-rain-lixi-3.svg`}
          alt="ic-rain-lixi"
        />
      )}
    </div>
  );
};

export default React.memo(LixiPost);
