import s from './styles.module.scss';
import React, { useMemo } from 'react';
import { IPostData } from '@/interfaces/api/post';
import MediaPost from '../MediaPost';
import LixiPost from '../LixiPost';
import WheelPost from '@/modules/AlphaPWA/Home/PostItem/WheelPost';
import RaffleNFTPost from '@/components/RaffleNFT/RaffleNFTPost';

type Props = {
  data: IPostData;
  isDetail?: boolean;
};

// Post controller, write all conditional logic here
const PostContent: React.FC<Props> = ({
  data,
  isDetail,
}: Props): React.ReactElement => {
  // Send lixi
  const isSendLixi = useMemo((): boolean => {
    return data?.activity === 'give_away_deposit';
  }, [data]);

  // Open lixi
  const isOpenLixi = useMemo((): boolean => {
    return data?.activity === 'give_away_receive';
  }, [data]);

  // Rain lixi
  const isRainLixi = useMemo((): boolean => {
    return data?.activity === 'give_away_multiple_deposit';
  }, [data]);

  const isLixi = useMemo((): boolean => {
    return isSendLixi || isOpenLixi || isRainLixi;
  }, [isSendLixi, isOpenLixi, isRainLixi]);

  const isWheel = useMemo(() => {
    return (
      data?.activity === 'wheel_create' || data?.activity === 'wheel_reward'
    );
  }, [data]);

  const isRaffle = useMemo(() => {
    return data?.activity === 'raffle_create';
  }, [data]);

  const renderPostContent = useMemo(() => {
    if (isWheel) {
      return <WheelPost wheelId={data?.prize_wheel_event?.ID} data={data} />;
    }

    if (isRaffle) {
      return (
        <RaffleNFTPost
          data={data}
          raffleId={data?.raffle_event?.ID as string}
        />
      );
    }

    if (isLixi) {
      return (
        <LixiPost
          data={data}
          isSendLixi={isSendLixi}
          isOpenLixi={isOpenLixi}
          isRainLixi={isRainLixi}
        />
      );
    }
    return <MediaPost isDetail={isDetail} data={data} />;
  }, [data]);

  return <div className={s.postContent}>{renderPostContent}</div>;
};

export default React.memo(PostContent);
