import queryString from 'query-string';
import createAxiosInstance from './http-client';

const BASE_URL = 'https://bridges-api.trustless.computer';

const apiClient = createAxiosInstance({ baseURL: BASE_URL });

const API_PATH = '/api/get-multisig-wallets';

type ParamsBuilder = {
  network?: string;
};

type BTCAddress = string;

export type MultiSigWalletsData = {
  totalAmount: number;
  wallets: {
    [key in BTCAddress]: number;
  };
};

export const getMultiSigWallets = async (
  params: ParamsBuilder
): Promise<MultiSigWalletsData> => {
  try {
    const paramsBuilder: ParamsBuilder = {
      network: params.network || 'nos',
    };
    const paramsJSONStr = queryString.stringify(paramsBuilder);
    const urlFullPath = `${API_PATH}?${paramsJSONStr}`;

    const response: MultiSigWalletsData = await apiClient.get(urlFullPath);
    return response;
  } catch (error: any) {
    console.log('[getMultiSigWallets] ERROR: ', error);
    throw error;
  }
};
