import { CDN_URL_ICONS } from '@/configs';
import { ACCEPTED_FILE_TYPE } from '@/constants/media';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-hot-toast';
import s from './UploadMedia.module.scss';
import cn from 'classnames';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import SvgInset from '@/components/SvgInset';
import MediaSvg from '../Icon/MediaSvg';
import { useEffect, useState } from 'react';

type Props = {
  files: File[] | undefined;
  setFiles: (files: any) => void;
  setPreview: (files: any) => void;
  className?: string;
  isDisabledUpload: boolean;
};

const UploadMedia = ({
  files,
  setFiles,
  setPreview,
  className,
  isDisabledUpload,
}: Props) => {
  // Just use this state for check permission public

  const [openCondition, setOpenCondition] = useState(false);

  const onChangeFile = (files: any): void => {
    // setFiles(files);

    const filesArray = Array.from(files);

    const filesArrayLength = filesArray.length;

    if (filesArrayLength > 4) {
      // toast.error('Maximum 4 files.');
      toast.remove();
      toast.custom(t => (
        <Box
          padding={'12px'}
          borderRadius={'8px'}
          border={'2px solid #C27605'}
          boxShadow={'0px 0px 24px -6px rgba(0, 0, 0, 0.12)'}
          backgroundColor={'#FFF'}
          mt="-10px"
        >
          <Flex
            alignItems={'center'}
            gap="8px"
            fontSize={'14px'}
            fontWeight={500}
            className={cn('font-bandeis', s.customToastText)}
          >
            <Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <circle cx="14" cy="14" r="14" fill="#FEEED7" />
                <path
                  d="M14 6C12.4178 6 10.871 6.46919 9.55544 7.34824C8.23985 8.22729 7.21447 9.47672 6.60897 10.9385C6.00347 12.4003 5.84504 14.0089 6.15372 15.5607C6.4624 17.1126 7.22433 18.538 8.34315 19.6569C9.46197 20.7757 10.8874 21.5376 12.4393 21.8463C13.9911 22.155 15.5997 21.9965 17.0615 21.391C18.5233 20.7855 19.7727 19.7602 20.6518 18.4446C21.5308 17.129 22 15.5822 22 14C21.9959 11.8795 21.1518 9.84705 19.6524 8.34764C18.153 6.84823 16.1205 6.00407 14 6ZM13.3846 10.3077C13.3846 10.1445 13.4495 9.98796 13.5649 9.87255C13.6803 9.75714 13.8368 9.69231 14 9.69231C14.1632 9.69231 14.3197 9.75714 14.4351 9.87255C14.5506 9.98796 14.6154 10.1445 14.6154 10.3077V14.6154C14.6154 14.7786 14.5506 14.9351 14.4351 15.0505C14.3197 15.1659 14.1632 15.2308 14 15.2308C13.8368 15.2308 13.6803 15.1659 13.5649 15.0505C13.4495 14.9351 13.3846 14.7786 13.3846 14.6154V10.3077ZM14 18.3077C13.8174 18.3077 13.639 18.2536 13.4872 18.1521C13.3354 18.0507 13.2171 17.9065 13.1472 17.7379C13.0773 17.5692 13.059 17.3836 13.0947 17.2045C13.1303 17.0255 13.2182 16.861 13.3473 16.7319C13.4764 16.6028 13.6409 16.5149 13.8199 16.4793C13.999 16.4437 14.1846 16.4619 14.3532 16.5318C14.5219 16.6017 14.6661 16.72 14.7675 16.8718C14.8689 17.0236 14.9231 17.202 14.9231 17.3846C14.9231 17.6294 14.8258 17.8642 14.6527 18.0373C14.4796 18.2104 14.2448 18.3077 14 18.3077Z"
                  fill="#C27605"
                />
              </svg>
              {/* <SvgInset size={28} svgUrl={`${CDN_URL_ICONS}/ic-warning.svg`} /> */}
            </Box>
            Display is limited to the first four images.
          </Flex>
        </Box>
      ));
    }

    let list = new DataTransfer();

    filesArray.slice(0, 4).forEach((item: any) => {
      let file = new File([item], item.name, { type: item.type });
      list.items.add(file);
    });

    let myFileList = list.files;

    setFiles(myFileList);

    setPreview(
      filesArray.slice(0, 4).map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const onSizeError = (): void => {
    toast.error('File size error.');
  };

  const onTypeError = (): void => {
    toast.error('File type not supported.');
  };

  const onShowCondition = () => {
    if (isDisabledUpload) {
      setOpenCondition(true);
      setTimeout(() => {
        setOpenCondition(false);
      }, 5000);
      return;
    }
  };

  useEffect(() => {
    if (!isDisabledUpload) {
      setOpenCondition(false);
    }
  }, [isDisabledUpload]);

  return (
    <div
      className={`${className} ${s.wrapUpload} ${
        isDisabledUpload ? s.disabled : ''
      }`}
    >
      {isDisabledUpload && (
        <div className={s.layerDisabled} onClick={onShowCondition}></div>
      )}
      <FileUploader
        disabled={isDisabledUpload}
        handleChange={onChangeFile}
        name={'zipFileUploader'}
        // maxSize={maxSize}
        minSize={0}
        types={ACCEPTED_FILE_TYPE}
        onSelect={(file: any) => console.log(file)}
        onTypeError={onTypeError}
        onSizeError={onSizeError}
        fileOrFiles={files}
        classes={s.dropZone}
        multiple={4}
      >
        <Tooltip
          label={
            <span style={{ fontSize: '12px' }}>
              This feature is only available to the top 100 New Bitcoiners.
            </span>
          }
          placement="top-end"
          isOpen={openCondition}
        >
          <img src={`${CDN_URL_ICONS}/imgIcon.png`} alt="add media icon" />
        </Tooltip>
      </FileUploader>
    </div>
  );
};

export default UploadMedia;
