import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  Text,
} from '@chakra-ui/react';
import { Input } from '@/components/Input';
import { Formik } from 'formik';
import s from './styles.module.scss';
import { isAddress } from 'web3-utils';
import { debounce, isNumber } from 'lodash';
import { AssetsContext } from '@/contexts/assets-context';
import { getErrorMessage } from '@/utils/error';
import BigNumber from 'bignumber.js';
import toast from 'react-hot-toast';
import { CSwap } from '@/contracts/swap';
import { formatCurrency, validateBTCAddress } from '@/utils';
import { CurrencyContext } from '@/contexts/currency-context';
import {
  estimateWithdrawETHL2,
  estimateWithdrawBTCL2,
} from '@/services/token-bridge';
import { IEstimateWithdrawResponse } from '@/services/interfaces/token-bridge';
import { TOKEN_ADDRESS } from '@/constants/token';
import { formatEthPrice } from '@/utils/format';
import CPlayerShare, { ETypes } from '@/contracts';
import AppLoading from '@/components/AppLoading';
import { ALPHA_KEY_FACTORY_ADDRESS } from "@/configs";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

interface IFormValue {
  walletAddress: string;
  amount: string;
}

const MIN_AMOUNT = 0.0003;

const WithdrawModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const swapContract = new CSwap();
  const cplayerShare = new CPlayerShare();

  const [loading, setLoading] = useState(false);
  const { withdrawBTCOnBTCNetwork, swapAndWithdrawBTCOnETHNetwork } =
    useContext(CurrencyContext);
  const { balanceL2 } = useContext(AssetsContext);

  const [networkType, setNetworkType] = React.useState<'eth' | 'btc'>('eth');

  const [estimateEth, setEstimateEth] = React.useState<
    IEstimateWithdrawResponse | undefined
  >(undefined);
  const [estimateBtc, setEstimateBtc] = React.useState<
    IEstimateWithdrawResponse | undefined
  >(undefined);

  const [ethReceiveAmount, setEthReceiveAmount] = React.useState<
    number | undefined
  >(undefined);
  const [estimating, setEstimating] = React.useState(false);
  const [error, setError] = React.useState<string>('');

  useEffect(() => {
    getEstimateWithdrawEth();
    getEstimateWithdrawBtc();
  }, []);

  const getEstimateWithdrawEth = async () => {
    try {
      const res: any = await estimateWithdrawETHL2({
        tcTokenID: TOKEN_ADDRESS.ETH_ADDRESS_L2,
      });
      if (res) {
        setEstimateEth(res as IEstimateWithdrawResponse);
      }
    } catch (error) {}
  };

  const getEstimateWithdrawBtc = async () => {
    try {
      // estAddress just use for estimate
      const estAddress = '0x70b5AB86461f8A38db04C0Bc307162CC98ec122c';
      const res: any = await estimateWithdrawBTCL2({
        tcTokenID: TOKEN_ADDRESS.BTC_ADDRESS_L2,
        tcAddress: estAddress,
      });
      if (res) {
        setEstimateBtc(res as IEstimateWithdrawResponse);
      }
    } catch (error) {}
  };

  const onEstimateEthReceive = async (payload: IFormValue) => {
    try {
      if (!payload.amount || !payload.walletAddress || !swapContract) {
        setEthReceiveAmount(undefined);
        return;
      }
      setEstimating(true);

      const data = await swapContract.estimateSwapBTC2ETH({
        humanAmount: `${payload.amount}`,
        receiver: payload.walletAddress,
      });

      const ethMount =
        data && data.length > 0 && data[0] > 0 ? data[0] : undefined;
      if (ethMount) {
        if (
          estimateEth &&
          networkType === 'eth' &&
          new BigNumber(estimateEth.minWithdrawAmount).gt(ethMount)
        ) {
          setError(
            `The minimum amount is ${formatEthPrice(
              estimateEth.minWithdrawAmount
            )} ETH.`
          );
        } else {
          setEthReceiveAmount(data[0]);
          setError('');
        }
      } else {
        setEthReceiveAmount(undefined);
        setError('Can not estimate swap');
      }
    } catch (error) {
      const { message } = getErrorMessage(error);
      setError(message);
    }
    setEstimating(false);
  };

  const debounceEstimateEthReceive = debounce(onEstimateEthReceive, 300);

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.walletAddress) {
      errors.walletAddress = 'Wallet address is required.';
    } else if (
      networkType === 'eth'
        ? !isAddress(values.walletAddress)
        : !validateBTCAddress(values.walletAddress)
    ) {
      errors.walletAddress = `Invalid ${networkType.toUpperCase()} wallet address.`;
    }

    if (!values.amount) {
      errors.amount = 'Required';
    } else if (!isNumber(values.amount) || parseFloat(values.amount) < 0) {
      errors.amount = 'Invalid amount';
    } else if (new BigNumber(values.amount).lt(MIN_AMOUNT)) {
      errors.amount = `The minimum withdraw amount is ${MIN_AMOUNT} BTC.`;
    } else if (
      balanceL2 &&
      Number(values.amount) > Number(balanceL2.amountBTCFormatted)
    ) {
      if (balanceL2.amountBTCFormatted) {
        errors.amount = `Insufficient balance. Max amount is ${formatCurrency(
          balanceL2.amountBTCFormatted,
          0,
          5
        )} BTC.`;
      } else {
        errors.amount = 'Insufficient balance';
      }
    } else if (networkType === 'eth') {
      debounceEstimateEthReceive(values);
    } else if (
      estimateBtc &&
      networkType === 'btc' &&
      new BigNumber(estimateBtc.minWithdrawAmount).gt(
        new BigNumber(values.amount).multipliedBy(1e18)
      )
    ) {
      errors.amount = `The minimum amount is ${formatEthPrice(
        estimateBtc.minWithdrawAmount
      )} BTC.`;
    }

    return errors;
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    if (loading) return;
    try {
      setLoading(true);
      await cplayerShare.getBTCApprove({
        token_amount: 1,
        spender_address: ALPHA_KEY_FACTORY_ADDRESS,
        need_approve: true,
      });

      const checkBuy1 = await cplayerShare.estimateTCGasFee({
        type: ETypes.withdraw,
      });

      const checkBuy2: any = await cplayerShare.estimateTCGasFee({
        type: ETypes.withdraw,
      });

      console.log('isBuy', { checkBuy1, checkBuy2 });

      let withdrawAmount = payload.amount;

      if (checkBuy1 || checkBuy2) {
        const amountBTCSendOut = new BigNumber(0.1)
          .multipliedBy(10)
          .dividedBy(30000)
          .multipliedBy(1e18)
          .integerValue(BigNumber.ROUND_FLOOR);

        console.log('amountBTCSendOut', amountBTCSendOut);

        withdrawAmount = new BigNumber(withdrawAmount)
          .multipliedBy(1e18)
          .minus(amountBTCSendOut)
          .dividedBy(1e18)
          .toFixed(6, BigNumber.ROUND_FLOOR);
        console.log('withdrawAmount', withdrawAmount);

        if (new BigNumber(withdrawAmount).lt(0)) {
          throw new Error(
            'Your BTC balance is not enough to pay fee, please try again.'
          );
        }
      }
      networkType === 'eth'
        ? await swapAndWithdrawBTCOnETHNetwork({
            humanAmount: payload.amount,
            receiver: payload.walletAddress,
          })
        : await withdrawBTCOnBTCNetwork({
            humanAmount: payload.amount,
            receiver: payload.walletAddress,
          });

      toast.success('Transaction has been created.');
      onClose();
    } catch (error) {
      const { desc } = getErrorMessage(error);
      toast.error(desc);
    } finally {
      setLoading(false);
    }
  };

  const getError = () => {
    if (error) return error;
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent
          p="24px 22px"
          w={'90%'}
          maxW="660px"
          bg={'#292929'}
          textAlign={'center'}
        >
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            textAlign="center"
            fontSize="24px"
            fontWeight={'600'}
            mb="24px"
          >
            Withdraw
          </ModalHeader>
          <ModalBody>
            <Formik
              key="cash-out-form"
              initialValues={{
                walletAddress: '',
                amount: '',
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleChange, handleSubmit }) => {
                const _error = getError();
                return (
                  <form className={s.form} onSubmit={handleSubmit}>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      gap="80px"
                      mb="28px"
                    >
                      <Box className={s.checkboxContainer}>
                        <Checkbox
                          onChange={() => {
                            setNetworkType('eth');
                            setError('');
                          }}
                          isChecked={networkType === 'eth'}
                        >
                          <p className={s.text}>ETH</p>
                        </Checkbox>
                      </Box>
                      <Box className={s.checkboxContainer}>
                        <Checkbox
                          onChange={() => {
                            setNetworkType('btc');
                            setError('');
                          }}
                          isChecked={networkType === 'btc'}
                        >
                          <p className={s.text}>BTC</p>
                        </Checkbox>
                      </Box>
                    </Flex>
                    <Input
                      id="amount"
                      type="number"
                      title="Amount"
                      value={values.amount}
                      rightTitle={`Balance: ${formatCurrency(
                        balanceL2.amountBTCFormatted || '0',
                        0,
                        4
                      )} BTC`}
                      placeholder="0.0 BTC"
                      onChange={handleChange}
                      errorMsg={
                        _error
                          ? _error
                          : errors.amount
                          ? errors.amount
                          : undefined
                      }
                      classContainer={s.input}
                      rightElement={
                        <div
                          className={s.max_button}
                          onClick={() => {
                            if (balanceL2) {
                              handleChange({
                                target: {
                                  id: 'amount',
                                  value: Number(
                                    new BigNumber(
                                      balanceL2.amountBTCFormatted
                                    ).toFixed(4, BigNumber.ROUND_FLOOR)
                                  ),
                                },
                              });
                            }
                          }}
                        >
                          MAX
                        </div>
                      }
                    />

                    <Input
                      id="walletAddress"
                      title={`${
                        networkType === 'eth' ? 'ETH' : 'BTC'
                      } receiving address`}
                      value={values.walletAddress}
                      placeholder={`Enter your ${
                        networkType === 'eth' ? 'ETH' : 'BTC'
                      } wallet address`}
                      onChange={handleChange}
                      errorMsg={errors.walletAddress}
                      classContainer={s.input}
                    />
                    {networkType === 'eth' && ethReceiveAmount && (
                      <div className={s.gasContainer}>
                        <p className={s.title}>Estimated receiving amount:</p>
                        <p className={s.amount}>
                          ~ {formatEthPrice(ethReceiveAmount)} ETH
                        </p>
                      </div>
                    )}
                    <div className={s.gasContainer}>
                      <p className={s.title}>Estimated network fee:</p>
                      <p className={s.amount}>
                        {networkType === 'eth'
                          ? estimateEth
                            ? formatEthPrice(estimateEth.withdrawFee, '-', 6)
                            : ''
                          : estimateBtc
                          ? formatEthPrice(estimateBtc.withdrawFee, '-', 6)
                          : ''}{' '}
                        {networkType === 'eth' ? 'ETH' : 'BTC'}
                      </p>
                    </div>
                    <div className={s.gasContainer}>
                      <p className={s.title}>Estimated waiting time:</p>
                      <p className={s.amount}>
                        ~ {networkType === 'btc' ? '145' : '135'} mins
                      </p>
                    </div>
                    {networkType === 'eth' && (
                      <p className={s.note}>
                        Your BTC balance is first converted to ETH via New
                        Bitcoin DEX before being sent to your mainnet ETH
                        wallet.
                      </p>
                    )}

                    <button
                      disabled={estimating || !!_error || loading}
                      className={s.submitBtn}
                      type="submit"
                    >
                      {loading
                        ? 'Processing...'
                        : estimating
                        ? 'Estimating...'
                        : `Withdraw`}
                    </button>
                    {loading && (
                      <div className={s.loading}>
                        <AppLoading />
                        <Text>
                          Please do not exit the app until the transaction is
                          completed.
                        </Text>
                      </div>
                    )}
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default WithdrawModal;
