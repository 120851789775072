import { ApiInfiniteHook } from '@/hooks/useApiInfinite';
import {
  ChatGroupMember,
  LanguageSupportedEnum,
  ChatTribeResponse,
} from '@/interfaces/api/chat';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { HoldingFriendTechUser } from '@/interfaces/api/verifyFriendTech';
import {
  ChatTab,
  ChatType,
  DMMessage,
  DMRoom,
  DMScreen,
  ExtendScrollbars,
  ReplyMessageData,
  RoomId,
  MemberInCall,
} from '@/modules/AlphaPWA/DirectMessage/types';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export interface IDMContext {
  screen: DMScreen;
  setScreen: React.Dispatch<React.SetStateAction<DMScreen>>;

  roomId?: string;
  setRoomId: (id?: string) => void;

  rooms: DMRoom[];
  setRooms: React.Dispatch<React.SetStateAction<DMRoom[]>>;

  activeRoom?: DMRoom;
  activeRoomMessages: DMMessage[];
  // activeRenderGroupMessages: DMMessage[][];

  roomMessages: Record<string, DMMessage[]>;
  setRoomMessages: React.Dispatch<
    React.SetStateAction<Record<string, DMMessage[]>>
  >;

  yourPoolProfile: IGetPlayerPoolProfile | null;

  voiceRoom?: string;
  setVoiceRoom: React.Dispatch<React.SetStateAction<string>>;

  joinedVoiceRoom: boolean;
  setJoinedVoiceRoom: React.Dispatch<React.SetStateAction<boolean>>;

  addActiveRoomUIMessage: (newMessage: DMMessage) => void;
  updateActiveRoomUIMessage: (newMessage: DMMessage) => void;

  appendMessagesToRoom: (
    roomId: string,
    appendMessages: DMMessage[],
    shouldReverse?: boolean
  ) => void;

  updateRoomLastSeen: (id: string, time: string) => void;

  getUnreadMessages: (type?: ChatType) => number;

  tab: ChatTab;
  setTab: React.Dispatch<React.SetStateAction<ChatTab>>;

  updateRoomSoundSetting: (id: string, muted: boolean) => void;

  activeRoomMembers: ChatGroupMember[] | null;
  setActiveRoomMembers: React.Dispatch<React.SetStateAction<ChatGroupMember[]>>;

  getUserProfile: (addr: string | undefined) => IGetPlayerPoolProfile | null;
  updateUserProfile: (
    addr: string | undefined,
    user: IGetPlayerPoolProfile
  ) => void;

  appendNewRooms: (newRooms: DMRoom[], force?: boolean) => void;

  checkRoomPermission: (roomId: string) => Promise<boolean>;

  updateRoomLanguageSettings: (
    roomId: RoomId,
    lang: LanguageSupportedEnum
  ) => void;

  updateRoomPinned: (roomId: string, pinned: boolean) => void;
  fetchGroupMembers: () => void;
  isBlockedFromGroupChat?: boolean;
  updateRoomBlockFtSetting: (id: string, status: boolean) => void;

  dataReply: ReplyMessageData | null;
  setDataReply: React.Dispatch<React.SetStateAction<ReplyMessageData | null>>;

  dataReplyPreview: ReplyMessageData | null;
  setDataReplyPreview: React.Dispatch<
    React.SetStateAction<ReplyMessageData | null>
  >;

  aroundMessages: DMMessage[];
  setAroundMessages: React.Dispatch<React.SetStateAction<DMMessage[]>>;

  roomOwnerTwitterId: string;
  setRoomOwnerTwitterId: React.Dispatch<React.SetStateAction<string>>;

  handlePinCommunity: ({
    ownerToken,
    pinned,
  }: {
    ownerToken: string;
    pinned: boolean;
  }) => Promise<boolean>;
  listChatGroup: ApiInfiniteHook<ChatTribeResponse[]>;

  searchMessageById: (messageId: string) => Promise<DMMessage | undefined>;

  roomChatAvailableOfUsers: Record<string, DMRoom[]>;
  getRoomChatAvailableOfUserByTwitterId: (
    twitterId: string,
    noCheckClient?: boolean
  ) => Promise<DMRoom[]>;

  fetchTribes: () => void;

  isForwardMessageTargets: boolean;
  onOpenForwardMessageTargets: () => void;
  onCloseForwardMessageTargets: () => void;

  forwardMessage: DMMessage | undefined;
  setForwardMessage: React.Dispatch<
    React.SetStateAction<DMMessage | undefined>
  >;
}

export default React.createContext<IDMContext>({} as IDMContext);
