import { CDN_URL_IMAGES } from '@/configs';
import React, { useEffect, useState } from 'react';
import s from './styles.module.scss';

const random = () => Math.floor(Math.random() * 8);

export default React.memo(function FakeAvatars({
  isWheelSuccess,
}: {
  isWheelSuccess: boolean;
}) {
  const [index, setIndex] = useState(random());

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const randomAvatar = () => {
      timeout = setTimeout(() => {
        setIndex(random());

        randomAvatar();
      }, 200);
    };

    if (!isWheelSuccess) {
      randomAvatar();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isWheelSuccess]);

  return (
    <>
      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-01.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 0 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-02.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 1 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-03.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 2 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-04.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 3 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-05.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 4 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-06.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 5 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-07.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 6 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />

      <img
        src={`${CDN_URL_IMAGES}/roulette-avatar-fake-08.png`}
        alt="roulette-avatar-fake-01.png"
        style={{
          display: index === 7 ? 'block' : 'none',
          transition: 'all 100ms',
        }}
      />
    </>
  );
});
