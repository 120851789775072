import sleep from '@/utils/sleep';

export enum IContentWindowEventName {
  gamefi_wallet_created = 'gamefi_wallet_created',
  create_gamefi_wallet = 'create_gamefi_wallet',
  set_avatar_success = 'set_avatar_success',
  set_name_success = 'set_name_success',
  was_in_allowlist = 'was_in_allowlist',
  connect_signature_trustless = 'connect_signature_trustless',
}

const useContentWindowMessage = () => {
  const onPostMessageWalletCreated = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.gamefi_wallet_created,
        '*'
      );
    }
  };

  const onPostMessageOpenCreateWallet = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.create_gamefi_wallet,
        '*'
      );
    }
  };

  const onPostMessageSetAvatarSuccess = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.set_avatar_success,
        '*'
      );
    }
  };

  const onPostMessageSetNameSuccess = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.set_name_success,
        '*'
      );
    }
  };

  const onPostMessageWasOnAllowlist = async () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.was_in_allowlist,
        '*'
      );
    }
  };

  const onPostMessageConnectWithSignatureTrustless = () => {
    const element: any = document.getElementById('game_iframe');
    if (element && element.contentWindow) {
      element.contentWindow.postMessage(
        IContentWindowEventName.connect_signature_trustless,
        '*'
      );
    }
  };

  return {
    onPostMessageWalletCreated,
    onPostMessageOpenCreateWallet,
    onPostMessageSetAvatarSuccess,
    onPostMessageSetNameSuccess,
    onPostMessageWasOnAllowlist,
    onPostMessageConnectWithSignatureTrustless
  };
};

export default useContentWindowMessage;
