/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { filterType } from '@/modules/AlphaPWA/Home/PostItem/FilterButton';

export interface IWalletContext {
  filterPost: filterType[];
  setFilterPost: (arr: filterType[]) => void;

  isHadPostFollowing: boolean;
  setIsHadPostFollowing: (b: boolean) => void;
}

const initialValue: IWalletContext = {
  filterPost: [],
  setFilterPost: _arr => null,

  isHadPostFollowing: false,
  setIsHadPostFollowing: b => null,
};

export const HomeContext = React.createContext<IWalletContext>(initialValue);

export const HomeProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [filterPost, setFilterPost] = useState<filterType[]>([]);
  const [isHadPostFollowing, setIsHadPostFollowing] = useState<boolean>(false);

  const contextValues = useMemo((): IWalletContext => {
    return {
      setFilterPost: setFilterPost,
      filterPost: filterPost,
      isHadPostFollowing: isHadPostFollowing,
      setIsHadPostFollowing: setIsHadPostFollowing,
    };
  }, [setFilterPost, filterPost, isHadPostFollowing, setIsHadPostFollowing]);

  return (
    <HomeContext.Provider value={contextValues}>
      {children}
    </HomeContext.Provider>
  );
};
