export const IcSell = (): React.ReactElement => (
  <svg height="30" width="30" fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <rect height="28" width="28" fill="black" rx="14" x="1" y="1.00012"/>
    <rect height="28" width="28" rx="14" stroke="url(#paint1_linear_42_1016)" x="1" y="1.00012"/>
    <path d="M20.25 15.7501H9.75C9.336 15.7501 9 15.4141 9 15.0001C9 14.5861 9.336 14.2501 9.75 14.2501H20.25C20.664 14.2501 21 14.5861 21 15.0001C21 15.4141 20.664 15.7501 20.25 15.7501Z" fill="url(#paint0_linear_42_1016)"/>
    <defs>
      <linearGradient id="paint0_linear_42_1016" gradientUnits="userSpaceOnUse" x1="9" x2="21" y1="15.0001" y2="15.0001">
        <stop stopColor="#FF5C5C"/>
        <stop offset="1" stopColor="#FD89FF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_42_1016" gradientUnits="userSpaceOnUse" x1="1" x2="29" y1="15.0001" y2="15.0001">
        <stop stopColor="#FF5C5C"/>
        <stop offset="1" stopColor="#FD89FF"/>
      </linearGradient>
    </defs>
  </svg>
);
