import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { follow } from '@/services/follow';
import { WalletContext } from '@/contexts/wallet-context';
import s from './style.module.scss';
import { useCheckFollowed } from '@/hooks/useCheckFollowed';
import { PostItemContext } from '@/contexts/post-item';
import { toast } from 'react-hot-toast';

export function PostFollow({
  twitter_id,
  twitter_name,
  isFollowed,
  setIsFollowed,
  reCheckFollow,
  className = '',
}: {
  twitter_id: string;
  twitter_name: string;
  className?: string;
  reCheckFollow?: number;
  isFollowed: boolean | undefined;
  setIsFollowed: (b: boolean | undefined) => void;
}) {
  const { addressL2 } = useContext(WalletContext);
  const refTarget = useRef(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const {
    isFollowed: isFollowedChecked,
    isChecked,
    reCheck,
  } = useCheckFollowed({
    refContent: refTarget,
    to_twitter_id: twitter_id,
    address: addressL2,
  });

  useEffect(() => {
    setIsFollowed(isFollowedChecked);
  }, [isFollowedChecked]);

  useEffect(() => {
    reCheck();
  }, [reCheckFollow]);

  const callAction = useCallback(async () => {
    if (!addressL2 || isSending) return;
    setIsSending(true);
    if (!isFollowed) {
      const res = await follow({
        address: addressL2,
        to_twitter_id: twitter_id,
      });
      setIsFollowed(true);
      toast.success(`You are following ${twitter_name}.`);
    }
    setIsSending(false);
  }, [isFollowed, addressL2, twitter_id, isSending]);

  if (!isChecked || isFollowed || isFollowed === undefined)
    return <div ref={refTarget}></div>;

  return (
    <>
      <div
        ref={refTarget}
        className={`${s.btnFollow} ${className}`}
        onClick={e => {
          e.stopPropagation();
          callAction();
        }}
      >
        <>
          {!isFollowed && (
            <>
              <span>{isSending ? 'Processing' : 'Follow'}</span>
            </>
          )}
        </>
      </div>
    </>
  );
}
