import { useContext, useEffect, useState } from 'react';
import s from './styles.module.scss';
import cs from 'classnames';

import {
  claimReferralEarning,
  getAddressReferents,
  getAddressReferralEarning,
} from '@/services/referral';
import { WalletContext } from '@/contexts/wallet-context';
import toast from 'react-hot-toast';
import { useRouter } from 'next/router';
import { Spinner, useDisclosure } from '@chakra-ui/react';
import ReferentsModal from '../ReferentsModal';
import { IReferralEarning } from '@/interfaces/api/referral';
import ShareReferralTwitter from '@/modules/AlphaPWA/Profile/ShareReferralTwitter';
import { convertBtcToUsdt, displayBTCValue } from '@/utils/format';
import ShareInfo from '../ShareInfo';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { MINIMUM_BTC_AMOUNT_CAN_CLAIM } from '@/constants/amount';

export default function InviteAndRewards() {
  const {
    query: { section },
  } = useRouter();
  const { addressL2 } = useContext(WalletContext);
  // const [referralCode, setReferralCode] = useState<string>();
  const [referralEarnings, setReferralEarnings] = useState<IReferralEarning>();
  const [referents, setReferents] = useState<{ address: string }[]>([]);

  // const [earningUsdt, setEarningUsdt] = useState(0);
  // const [claimUsdt, setClaimUsdt] = useState(0);
  // const [remainUsdt, setRemainUsdt] = useState(0);
  const [isClaiming, setIsClaiming] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker();

  // useEffect(() => {
  //   if (referralEarnings?.totalBtcEarning) {
  //     convertBtcToUsdt(referralEarnings?.totalBtcEarning).then(_usdt => {
  //       setEarningUsdt(_usdt);
  //     });
  //   }

  //   if (referralEarnings?.totalBtcClaim) {
  //     convertBtcToUsdt(referralEarnings?.totalBtcClaim).then(_usdt => {
  //       setClaimUsdt(_usdt);
  //     });
  //   }
  //   if (referralEarnings?.totalBtcRemain) {
  //     convertBtcToUsdt(referralEarnings?.totalBtcRemain).then(_usdt => {
  //       setRemainUsdt(_usdt);
  //     });
  //   }
  // }, [referralEarnings]);

  const {
    isOpen: isOpenReferents,
    // onOpen: onOpenReferents,
    onClose: onCloseReferents,
  } = useDisclosure({ id: 'referents' });

  useEffect(() => {
    getAddressReferents().then(_referents => {
      setReferents(_referents);
    });
  }, []);

  useEffect(() => {
    const getEarings = () => {
      getAddressReferralEarning().then(res => {
        setReferralEarnings(res);
      });
    };

    getEarings();

    let interval: any;
    if (!isOpenReferents) {
      interval = setInterval(() => {
        getEarings();
      }, 30000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOpenReferents]);

  const handleOnClaim = () => {
    if (addressL2) {
      gaEventTracker(GameFiActions.ReferralClaimClick, addressL2);
      setIsClaiming(true);
      claimReferralEarning()
        .then(() => {
          toast.success('Claim your reward successfully.');
        })
        .catch(() => {
          toast.error('Claim your reward failed.');
        })
        .finally(() => {
          getAddressReferralEarning()
            .then(res => {
              setReferralEarnings(res);
            })
            .finally(() => {
              setIsClaiming(false);
            });
        });
    }
  };

  return (
    <>
      <div className={cs(s.wrapper, section === 'referral' ? s.flashing : '')}>
        <ShareInfo referents={referents} referralEarnings={referralEarnings}>
          {/* <div
            className={s.earningRewards}
            onClick={e => {
              // if has invited friend should show
              if (referents.length) {
                onOpenReferents();
              }
            }}
          >
            <div
              className={cs(s.earningRewards__block, s.earningRewards__invited)}
            >
              <h4 className={cs(s.earningRewards__block__title)}>Invited</h4>
              <span className={cs(s.earningRewards__block__amount)}>
                {referents.length || 0}
              </span>
            </div>
            <div
              className={cs(s.earningRewards__block, s.earningRewards__total)}
            >
              <h4 className={cs(s.earningRewards__block__title)}>Earnings</h4>
              <span className={cs(s.earningRewards__block__amount)}>
                {displayBTCValue(referralEarnings?.totalBtcEarning)}
              </span>
              <div className={cs(s.earningRewards__block__amountUSD)}>
                ${earningUsdt.toFixed(2)}
              </div>
            </div>

            <div
              className={cs(s.earningRewards__block, s.earningRewards__claim)}
            >
              <h4 className={cs(s.earningRewards__block__title)}>Claimed</h4>
              <span className={cs(s.earningRewards__block__amount)}>
                {displayBTCValue(referralEarnings?.totalBtcClaim)}
              </span>
              <div className={cs(s.earningRewards__block__amountUSD)}>
                ${claimUsdt.toFixed(2)}
              </div>
            </div>

            <div
              className={cs(s.earningRewards__block, s.earningRewards__remain)}
            >
              <h4 className={cs(s.earningRewards__block__title)}>Remain</h4>
              <span className={cs(s.earningRewards__block__amount)}>
                {displayBTCValue(referralEarnings?.totalBtcRemain)}
              </span>
              <div className={cs(s.earningRewards__block__amountUSD)}>
                ${remainUsdt.toFixed(2)}
              </div>
            </div>
            */}

          {/* Claim */}
          {/* temp hide this section until implement claim feature */}
          {/*
            {Number(referralEarnings?.totalBtcRemain) >
            MINIMUM_BTC_AMOUNT_CAN_CLAIM ? (
              <>
                {isClaiming ? (
                  <div className={s.rewardClaiming}>
                    <Spinner color="blue" />
                  </div>
                ) : (
                  <div className={s.rewardClaiming} onClick={handleOnClaim}>
                    <span>Claim</span>
                  </div>
                )}
              </>
            ) : (
              <div className={cs(s.rewardClaiming, s.rewardClaiming_inactive)}>
                <span>Claim</span>
              </div>
            )}
          </div> */}
        </ShareInfo>

        <ShareReferralTwitter fullHeight />
        {Number(referralEarnings?.totalBtcRemain) > 0 && (
          <div className={s.claimSection}>
            <span className={s.claimSection__message}>
              You have{' '}
              <span className={s.claimSection__highlight}>
                {displayBTCValue(referralEarnings?.totalBtcRemain)}
              </span>{' '}
              unclaimed
            </span>

            {Number(referralEarnings?.totalBtcRemain) >
            MINIMUM_BTC_AMOUNT_CAN_CLAIM ? (
              <>
                {isClaiming ? (
                  <button className={s.claimSection__btn}>
                    <Spinner />
                  </button>
                ) : (
                  <button
                    className={s.claimSection__btn}
                    onClick={handleOnClaim}
                  >
                    Claim
                  </button>
                )}
              </>
            ) : (
              <button
                className={cs(
                  s.claimSection__btn,
                  s.claimSection__btn__inactive
                )}
              >
                Claim
              </button>
            )}
          </div>
        )}
      </div>
      <ReferentsModal
        isOpen={isOpenReferents}
        onClose={onCloseReferents}
        referents={referents || []}
        referentsInfo={referralEarnings?.referents || []}
      />
    </>
  );
}
