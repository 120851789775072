/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Text,
} from '@chakra-ui/react';
import styles from './styles.module.scss';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouter } from 'next/router';
import { useAppSelector } from '@/state/hooks';
import { requestReload, selectCommonReducer } from '@/state/common';
import { WalletContext } from '@/contexts/wallet-context';
import ListTable, { ColumnProp } from '@/components/ListTable';
import { getThreeThreeInvitationList } from '@/services/three-three';
import clsx from 'clsx';
import { IThreeThreeInvitation } from '@/interfaces/threethree';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
  getExplorer,
} from '@/utils';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { ROUTE_PATH } from '@/constants/route-path';
import KeyPresaleIcon from '@/modules/AlphaPWA/Notification/svg/KeyPresaleIcon';
import KeyIcon from '@/modules/AlphaPWA/Profiles/BuyAKey/KeyIcon';
import px2rem from '@/utils/px2rem';
import { SIDE } from '../index';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { showSuccess } from '@/modules/AlphaPWA/Profiles/TradeKey/components/toast';
import { useDispatch } from 'react-redux';
import { labelAmountOrNumberAdds } from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { closeModal, openModal } from '@/state/modal';
import ModalAccept from '@/modules/AlphaPWA/ThreeThreeInvitations/Invitations/ModalAccept';
import { parseEther } from 'ethers/lib/utils';
import TradeKeyProvider from '@/contexts/trade-key-context';
import { AssetsContext } from '@/contexts/assets-context';
import { ethers } from 'ethers';

const LIMIT_PAGE = 50;

const CONFIRM_TRADE_MODAL_ID = 'CONFIRM_TRADE_MODAL_ID';

const ModuleHistory: React.FC<any> = ({ side }: { side: number }) => {
  const { addressL2 } = useContext(WalletContext);
  const [data, setData] = useState<IThreeThreeInvitation[]>([]);
  const needReload = useAppSelector(selectCommonReducer).needReload;
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const dataRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { balanceL2 } = useContext(AssetsContext);

  const {
    trade33Keys,
    cancelTrade33Keys,
    scanTrxAlpha,
    rejectTrade33Keys,
    reRequestTrade33Keys,
    checkAEnoughBalanceAndAllowance,
  } = useTradeKey();

  const router = useRouter();
  const params = router.query;

  useEffect(() => {
    if (addressL2) {
      let pageLimit = LIMIT_PAGE;
      if (params.pageHistory) {
        pageLimit = LIMIT_PAGE * Number(params.pageHistory);
      }
      fetchData(1, false, pageLimit);
    }
  }, [addressL2, side, needReload]);

  const fetchData = async (
    page = 1,
    isFetchMore = false,
    pageLimit = LIMIT_PAGE
  ) => {
    try {
      setIsFetching(true);
      const res = await getThreeThreeInvitationList({
        address: addressL2 || '',
        side: side,
        page: page,
        limit: pageLimit,
      });
      router.replace({
        query: { ...router.query, pageHistory: page },
      });

      if (isFetchMore) {
        setData(prev => [...prev, ...res]);
      } else {
        setData(res);
      }
    } catch (e) {
    } finally {
      setIsFetching(false);
    }
  };

  const onLoadMoreTokens = () => {
    if (isFetching || data.length % LIMIT_PAGE !== 0) return;
    const page = Math.floor(data.length / LIMIT_PAGE) + 1;
    fetchData(page, true);
  };

  const debounceLoadMore = debounce(onLoadMoreTokens, 300);

  const handleCancel = async (data: IThreeThreeInvitation) => {
    try {
      setIsSubmitting(true);
      dataRef.current = data;

      const tx = await cancelTrade33Keys(`0x${data.order_id}`);
      await scanTrxAlpha(tx?.hash);
      showSuccess({
        message: `You have canceled your outgoing (3,3) request with ${data?.user_b.twitter_name}.`,
        url: getExplorer(tx?.hash, 'nos', 'tx'),
      });
      dispatch(requestReload());
    } catch (e) {
    } finally {
      dataRef.current = null;
      setIsSubmitting(false);
    }
  };

  const closeModalAccept = () => {
    dispatch(closeModal({ id: CONFIRM_TRADE_MODAL_ID }));
  };

  const handleTrade = async (data: IThreeThreeInvitation) => {
    const isUserABalanceAllow = await checkAEnoughBalanceAndAllowance({
      user_address: data.user_a.address,
      token_address: data.user_a.token_address,
      amount: data.user_b.buy_price,
    });

    const isUserABuyPriceUp = parseEther(data.buy_price_b_after_fee).gt(
      parseEther(data.buy_price_b_after_fee_max)
    );
    const isUserBNotEnoughBalance = parseEther(data.buy_price_a_after_fee).gt(
      ethers.BigNumber.from(balanceL2.amountBTC)
    );
    const amountRequire = parseEther(data.buy_price_a_after_fee).sub(
      ethers.BigNumber.from(balanceL2.amountBTC)
    );

    if (isUserABuyPriceUp || !isUserABalanceAllow || isUserBNotEnoughBalance) {
      dispatch(
        openModal({
          id: CONFIRM_TRADE_MODAL_ID,
          theme: 'dark',
          className: styles.modalContent,
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          render: () => (
            <ModalAccept
              isUserABalanceAllow={isUserABalanceAllow}
              isUserBNotEnoughBalance={isUserBNotEnoughBalance}
              data={data}
              handleReject={handleReject}
              handleResend33={handleResend33}
              onClose={closeModalAccept}
              amountRequire={amountRequire}
            />
          ),
        })
      );
    } else {
      executeTrade(data);
    }
  };

  const executeTrade = async (data: IThreeThreeInvitation) => {
    try {
      setIsSubmitting(true);
      dataRef.current = data;

      const tx = await trade33Keys([
        {
          token_address: data.user_a.token_address,
          token_amount: Number(data.amount),
          order_id: `0x${data.order_id}`,
        },
      ]);

      const txx = tx[0].tx;
      await scanTrxAlpha(txx?.hash);
      showSuccess({
        message: `You have accepted a (3,3) request from ${
          data?.user_a.twitter_name
        }. You and ${data?.user_a.twitter_name} now each have ${formatCurrency(
          data?.amount,
          0,
          2
        )} key${labelAmountOrNumberAdds(
          Number(data?.amount)
        )} from each other.`,
        url: getExplorer(txx?.hash, 'nos', 'tx'),
      });
      dispatch(requestReload());
    } catch (e) {
    } finally {
      dataRef.current = null;
      setIsSubmitting(false);
    }
  };

  const handleReject = async (data: IThreeThreeInvitation) => {
    try {
      setIsRejecting(true);
      dataRef.current = data;

      const tx = await rejectTrade33Keys(`0x${data.order_id}`);
      await scanTrxAlpha(tx?.hash);
      showSuccess({
        message: `You have rejected (3,3) request from ${data?.user_a.twitter_name}.`,
        url: getExplorer(tx?.hash, 'nos', 'tx'),
      });
      dispatch(requestReload());
      closeModalAccept();
    } catch (e) {
    } finally {
      dataRef.current = null;
      setIsRejecting(false);
    }
  };

  const handleResend33 = async (txs: any) => {
    const tx = txs[0];
    if (tx) {
      await tx.wait();
      await scanTrxAlpha(tx?.hash);
      showSuccess({
        message: `You have resent a (3,3) request successfully.`,
        url: getExplorer(tx?.hash, 'nos', 'tx'),
      });
      dispatch(requestReload());
      closeModalAccept();
    }
  };

  const onItemClick = (data: IThreeThreeInvitation) => {
    const user = side === SIDE.REQUESTED ? data?.user_a : data?.user_b;
    const address = user?.address;

    if (!address) return;
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${address}`);
  };

  const columns: ColumnProp[] = useMemo(() => {
    return [
      {
        id: 'player',
        label: 'Key',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: IThreeThreeInvitation, extraData: any, index: number) {
          const isPresale = compareString(
            data?.user_a.address,
            data?.user_a.twitter_id
          );
          const user = side === SIDE.REQUESTED ? data?.user_a : data?.user_b;
          return (
            <Flex
              gap={3}
              alignItems={'center'}
              width={'100%'}
              onClick={() => onItemClick(data)}
            >
              <Flex flex={1} gap={2} alignItems={'center'}>
                <Avatar
                  url={getUrlAvatarTwitter(
                    (user?.twitter_avatar || user?.avatar) as string,
                    'normal',
                    Boolean(user?.twitter_avatar)
                  )}
                  address={user?.address}
                  width={32}
                />
                <Flex width={'100%'} flex={1} gap={2} direction={'column'}>
                  <Flex
                    onClick={() =>
                      router.push(
                        `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${user?.address}`
                      )
                    }
                    gap={2}
                    alignItems={'center'}
                    maxWidth={'100%'}
                    width={'86%'}
                    flex={1}
                  >
                    <Text className={styles.buyer}>
                      {formatName(user?.twitter_name as string, 50)}
                    </Text>
                    <Flex
                      className={clsx(
                        styles.keyContainer,
                        isPresale && styles.keyContainerPresale
                      )}
                    >
                      {isPresale ? <KeyPresaleIcon /> : <KeyIcon />}
                    </Flex>
                  </Flex>
                  <Flex alignItems={'center'} gap={2}>
                    {/*<Text
                      color={'#000000'}
                      fontSize={px2rem(12)}
                      opacity={'70%'}
                    >
                      Vol{' '}
                      {formatCurrency(
                        user?.vol,
                        0,
                        2,
                        user?.base_token_symbol
                      )}{' '}
                      {user?.base_token_symbol}
                    </Text>*/}
                    <Flex gap={1}>
                      <Text
                        color={'#000000'}
                        fontSize={px2rem(12)}
                        opacity={'70%'}
                      >
                        {abbreviateNumber(user?.twitter_followers_count)}{' '}
                        follower
                        {labelAmountOrNumberAdds(user?.twitter_followers_count)}
                      </Text>
                    </Flex>
                    ·
                    <Flex gap={1}>
                      <Text
                        color={'#000000'}
                        fontSize={px2rem(12)}
                        opacity={'70%'}
                      >
                        {formatCurrency(data.amount, 0, 2)} key
                        {labelAmountOrNumberAdds(Number(data.amount))}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        },
      },
      {
        id: 'tradingVolume',
        label: 'Price',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: IThreeThreeInvitation) {
          const user = side === SIDE.REQUESTED ? data?.user_a : data?.user_b;
          return (
            <Flex
              gap={2}
              direction={'column'}
              alignItems={'flex-end'}
              onClick={() => onItemClick(data)}
            >
              <Text className={styles.buyer}>
                {formatCurrency(user?.buy_price, 0, 2, user?.base_token_symbol)}{' '}
                {user?.base_token_symbol}
              </Text>
              <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                ${formatCurrency(user?.usd_price, 0, 2)}
              </Text>
            </Flex>
          );
        },
      },
      {
        id: 'twitter_followers_count',
        label: 'Status',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: 'none',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
        },
        render(data: IThreeThreeInvitation) {
          return (
            <Flex alignItems={'center'} justifyContent={'flex-end'} gap={2}>
              {['cancelled', 'confirmed', 'rejected'].includes(data.status) ? (
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  className={clsx(styles.statusText, styles[data.status])}
                  onClick={() => onItemClick(data)}
                >
                  {data.status}
                </Flex>
              ) : (
                <>
                  {side === SIDE.REQUESTED && (
                    <>
                      <Button
                        isDisabled={isSubmitting || isRejecting}
                        isLoading={
                          dataRef.current?.order_id === data.order_id &&
                          isSubmitting
                        }
                        className={styles.earnButton}
                        onClick={() => {
                          handleTrade(data);
                        }}
                      >
                        Accept
                      </Button>
                      <Menu>
                        <MenuButton className={styles.btnMenu}>
                          <svg
                            className={styles.dots_icon}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g id="solar:menu-dots-bold">
                              <path
                                id="Vector"
                                d="M12 7C11.4696 7 10.9609 6.78929 10.5858 6.41421C10.2107 6.03914 10 5.53043 10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C14 5.53043 13.7893 6.03914 13.4142 6.41421C13.0391 6.78929 12.5304 7 12 7ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14ZM12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19C10 18.4696 10.2107 17.9609 10.5858 17.5858C10.9609 17.2107 11.4696 17 12 17C12.5304 17 13.0391 17.2107 13.4142 17.5858C13.7893 17.9609 14 18.4696 14 19C14 19.5304 13.7893 20.0391 13.4142 20.4142C13.0391 20.7893 12.5304 21 12 21Z"
                                fill="black"
                              />
                            </g>
                          </svg>
                        </MenuButton>
                        <MenuList py="8px" boxShadow="2xl" borderRadius={'8px'}>
                          <div
                            className={styles.wallet_block}
                            onClick={() => {
                              handleReject(data);
                            }}
                          >
                            Cancel
                          </div>
                        </MenuList>
                      </Menu>
                    </>
                  )}
                  {side === SIDE.REQUESTING && (
                    <Button
                      isDisabled={isSubmitting}
                      isLoading={
                        dataRef.current?.order_id === data.order_id &&
                        isSubmitting
                      }
                      className={styles.earnButton}
                      onClick={() => {
                        handleCancel(data);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </>
              )}
            </Flex>
          );
        },
      },
    ];
  }, [side, isSubmitting, balanceL2]);

  return (
    <Flex
      overflow={'hidden'}
      height={'100%'}
      className={clsx(styles.container)}
      direction={'column'}
    >
      <InfiniteScroll
        className={styles.tokensList}
        dataLength={data?.length || 0}
        hasMore={true}
        // height={"calc(100vh - env(safe-area-inset-top) - 73px - 87px - 39px)"}
        loader={
          isFetching && (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Spinner speed="0.65s" emptyColor="gray.200" color="blue.500" />
            </Flex>
          )
        }
        next={debounceLoadMore}
      >
        <ListTable
          data={data}
          columns={columns}
          // noHeader={true}
          // ItemListComponent={(row, extraData, columns, i) => {
          //   return (
          //     <div key={i}>
          //       <FeedItem item={row} />
          //     </div>
          //   );
          // }}
          showEmpty={!isFetching}
          emptyLabel={'There is no requests.'}
        />
      </InfiniteScroll>
    </Flex>
  );
};

export default ModuleHistory;
