export const colors = {
  white: '#FFFFFF',
  dark: '#0F0F0F',
  dark100: '#0f0f0f1a',
  white50: 'rgba(255, 255, 255, 0.05)',
  white100: 'rgba(255, 255, 255, 0.1)',
  white500: 'rgba(255, 255, 255, 0.5)',
  black: '#000000',
  greenPrimary: '#16c784',
  redPrimary: '#ea3943',
  redSecondary: '#FF6838',
  darkBorderColor: '#353945',
  bluePrimary: '#3385FF',
  hLabelColor: '#A1A8B8',
};
