import { CDN_URL } from '@/configs';
import { ClientOs } from '@/enums/client';
import { detectClientOs } from '@/utils/client';
import cs from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import s from './PlayerSharesIframe.module.scss';

interface IProps {
  url: string;
  setShow: React.Dispatch<React.SetStateAction<string>>;
}

const PlayerSharesIframe: React.FC<IProps> = ({
  url,
  setShow,
}: IProps): React.ReactElement => {
  const clientOs = detectClientOs();

  return (
    <>
      <Button
        onClick={() => setShow('')}
        onTouchEnd={() => setShow('')}
        className={cs(s.backBtn, {
          [`${s.ios}`]: clientOs === ClientOs.Ios,
        })}
      >
        <img
          className={s.backIcon}
          src={`${CDN_URL}/icons/chevron-right-ic-32.svg`}
          alt="chevron-right-ic"
        />
        <span>Back</span>
      </Button>
      {url && (
        <iframe className={s.iframe} src={`${url}?header=false`}></iframe>
      )}
    </>
  );
};

export default React.memo(PlayerSharesIframe);
