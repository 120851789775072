import React, { useContext, useState } from 'react';
import s from './DeleteButton.module.scss';
import DeletePostSvg from '../../CreatePost/Icon/DeletePostSvg';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import { deleteTweet } from '@/services/alpha_tweet';
import { WalletContext } from '@/contexts/wallet-context';
import { Button } from '@chakra-ui/react';

export const CONFIRM_DELETE_POST = 'CONFIRM_DELETE_POST';

interface props {
  postId: string;
  onFetchData: () => void;
}

const DeleteButton = (props: props) => {
  const { postId, onFetchData } = props;
  const dispatch = useAppDispatch();
  const { addressL2 } = useContext(WalletContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(closeModal({ id: CONFIRM_DELETE_POST }));
  };

  const _onDeletePost = async () => {
    if (!addressL2) return;
    setIsLoading(true);
    try {
      const res = await deleteTweet(postId, addressL2);
      if (res) {
        onFetchData();
      }
    } catch (error) {
      console.log('Error delete post: ', error);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <div
      className={s.deleteButon_wrapper}
      onClick={e => {
        e.stopPropagation();
        dispatch(
          openModal({
            id: CONFIRM_DELETE_POST,
            theme: 'dark',
            // title: 'Connection Error',
            modalProps: {
              centered: true,
              zIndex: 9999999,
            },
            hideCloseButton: true,
            render: () => (
              <ModalConfirmDelete
                handleCloseModal={handleCloseModal}
                isLoading={isLoading}
                _onDeletePost={_onDeletePost}
              />
            ),
          })
        );
      }}
    >
      <span className={s.delete_text}>
        <DeletePostSvg />
        Delete post
      </span>
    </div>
  );
};

const ModalConfirmDelete = ({
  handleCloseModal,
  isLoading,
  _onDeletePost,
}: {
  handleCloseModal: () => void;
  isLoading: boolean;
  _onDeletePost: () => void;
}) => {
  return (
    <div className={s.wrapModal}>
      <div className={s.body}>Do you want to delete this post?</div>
      <div className={s.footer}>
        <Button className={s.cancel} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          className={s.delete}
          loadingText="Deleting"
          variant="solid"
          isLoading={isLoading}
          onClick={_onDeletePost}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteButton;
