import { WalletContext } from '@/contexts/wallet-context';
import { submitLike } from '@/services/alpha_tweet';
import { Flex, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import s from './LikeButton.module.scss';
import cn from 'classnames';
import { kFormatter } from '@/utils/format';
import { EMOJI_REACTIONS } from '@/modules/AlphaPWA/Home/PostItem/Emoji';
import EmojisBar from '@/modules/AlphaPWA/Home/PostItem/LikeButton/Emojis';
import ReactionDefault from '@/icon-svgs/reaction-default';
import { PostItemContext } from '@/contexts/post-item';

type Props = {
  id: number;
  numOfLikes: number;
  isLiked?: boolean;
  reactionType?: number;
  classNames?: string;
  hideNumber?: boolean;
  setActionLiked?: (b: boolean) => void;
};

const LikeButton = ({
  id,
  numOfLikes,
  isLiked = false,
  reactionType = 0,
  setActionLiked,
  classNames,
  hideNumber,
}: Props) => {
  const { addressL2 } = useContext(WalletContext);
  const [clickLike, setClickLike] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const refTime = useRef(null);
  const [reactionTypeCurrent, setReactionTypeCurrent] = useState(reactionType);
  const [isAction, setIsAction] = useState(false);
  const [likeCount, setLikeCount] = useState(numOfLikes);

  const handleSubmitLikes = async (reactionTypeSubmit: number) => {
    if (!addressL2) return;

    setIsAction(true);
    // setActionLiked && setActionLiked(false);
    setTimeout(() => {
      setIsAction(false);
    }, 2000);

    try {
      // call POST API
      await submitLike({
        id,
        address: addressL2,
        reaction_type: reactionTypeSubmit,
      });

      setClickLike(true);
      setReactionTypeCurrent(reactionTypeSubmit);
      setActionLiked && setActionLiked(true);
      setLikeCount(prev => prev + 1);

      setTimeout(() => {
        setActionLiked && setActionLiked(false);
      }, 2000);
    } catch (error) {
      console.log(' handleSubmitLikes ~ error:', error);
    }
  };

  const onToggle = () => {
    setIsToggle(prev => !prev);
  };

  return (
    <Flex
      className={cn(
        s.wrapper,
        isLiked || clickLike ? s.liked : '',
        isToggle ? s.isToggle : '',
        classNames
      )}
      alignItems={'center'}
      onClick={e => {
        e.stopPropagation();
        onToggle();
      }}
      gap="5px"
    >
      {isToggle && (
        <EmojisBar submitEmoji={handleSubmitLikes} />
      )}
      {isLiked || clickLike ? (
        <div className={`${s.emojeLiked} ${isAction ? s.isAnimation : ''}`}>
          {EMOJI_REACTIONS.find(
            item => item?.type === reactionTypeCurrent
          )?.icon() || EMOJI_REACTIONS[0]?.icon()}
        </div>
      ) : (
        <span className={s.likeDetaulIc}>
          <ReactionDefault />
        </span>
      )}
      {!hideNumber && (
        <span className={`action_text`}>{kFormatter(likeCount)}</span>
      )}
    </Flex>
  );
};

export default LikeButton;
