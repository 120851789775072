import { API_FOC } from '@/configs';
import createAxiosInstance from './http-client';
import { IUploadFile } from '@/interfaces/files';

const apiClient = createAxiosInstance({
  baseURL: API_FOC,
});

export const uploadFile = async (payload: IUploadFile) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  const res = await apiClient.post(`/files/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

// const uploadFile = async (
//   uploadId: string,
//   file: File | Blob,
//   chunkSize: number
// ) => {
//   const totalChunks = Math.ceil(file.size / chunkSize);
//   setChunkCount(totalChunks);
//   setCounter(0);
//   const uploadPromises = [];
//   const maxRetries = 3;

//   for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
//     const start = chunkIndex * chunkSize;
//     const end = Math.min(start + chunkSize, file.size);
//     const chunk = file.slice(start, end);
//     const formData = new FormData();
//     formData.append('file', chunk, file.name);

//     let retries = 0;
//     let success = false;

//     while (retries <= maxRetries && !success) {
//       try {
//         const partNumber = chunkIndex + 1;
//         const xhr = new XMLHttpRequest();
//         xhr.overrideMimeType('application/octet-stream');
//         xhr.open(
//           'PUT',
//           `${API_PATH}/${uploadId}?partNumber=${partNumber}`,
//           true
//         );

//         const promise = new Promise((resolve, reject) => {
//           xhr.onload = () => {
//             if (xhr.status === 200) {
//               resolve(xhr.response);
//               setCounter(prev => prev + 1);
//             } else {
//               reject(xhr.statusText);
//             }
//           };

//           xhr.onerror = () => {
//             reject(xhr.statusText);
//           };

//           xhr.send(formData);
//         });

//         uploadPromises.push(promise);
//         success = true;
//       } catch (error) {
//         log(`Upload failed, retrying...${error}`, LogLevel.ERROR, LOG_PREFIX);
//         retries++;
//       }
//     }

//     if (!success) {
//       log(
//         `Upload failed after ${maxRetries} retries`,
//         LogLevel.ERROR,
//         LOG_PREFIX
//       );
//       return;
//     }
//   }

//   try {
//     await Promise.all(uploadPromises);
//   } catch (error: unknown) {
//     setError(error);
//     setCounter(0);
//     log('Upload chunk file error', LogLevel.ERROR, LOG_PREFIX);
//     throw Error('Upload chunk file error');
//   }
// };
