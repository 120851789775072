import DeleteButton from '@/modules/AlphaPWA/Home/PostItem/DeleteButton';
import s from './style.module.scss';
import { IcMenu } from '@/modules/AlphaPWA/Home/PostItem/Icon/ic-menu';
import React, { useContext, useRef, useState } from 'react';
import useClickOutside from '@/hooks/useHoverOutside';
import PinPostButton from '../PinPostButton';
import { ButtonFollow } from '../FollowButton';
import { WalletContext } from '@/contexts/wallet-context';
import BuySellButton from '../BuySellButton';
import { PostItemContext } from '@/contexts/post-item';
import { compareString } from '@/utils';
import ReportButton from '../ReportButton';

export function MenuActions({
  data,
  onFetchData,
  dataParent,
  fetchPinnedPosts,
  isPinned,
  postType,
}: any) {
  const { addressL2 } = useContext(WalletContext);
  const [show, setShow] = useState<boolean>(false);
  const refContent = useRef<HTMLDivElement>(null);
  const isRepost = dataParent?.is_repost || data?.is_repost;

  useClickOutside(refContent, () => {
    setShow(false);
  });

  return (
    <div className={`${s.menuActions} ${show ? s.show : ''}`} ref={refContent}>
      <button
        className={s.menuActions_btn}
        onClick={e => {
          e.stopPropagation();

          setShow(prevState => {
            return !prevState;
          });
        }}
      >
        <IcMenu />
      </button>
      <div className={`${s.menuActions_list} ${show ? s.show : ''}`}>
        {addressL2 !== data?.user?.address && (
          <ButtonFollow twitter_id={data?.user?.twitter_id} />
        )}

        {addressL2 === data?.user?.address && postType === 'post' && (
          <PinPostButton
            isPinned={isPinned}
            twitterId={data?.user?.twitter_id}
            postId={data.id}
            fetchPinnedPosts={fetchPinnedPosts}
          />
        )}

        <BuySellButton address={data?.user?.address} />

        {!isRepost && addressL2 === data?.user?.address && (
          <DeleteButton
            postId={data.id?.toString()}
            onFetchData={() => {
              onFetchData && onFetchData(true);
            }}
          />
        )}

        {!compareString(addressL2, data?.user?.address) && (
          <ReportButton
            postId={data.id}
            twitterId={data?.user?.twitter_id}
            onClose={() => setShow(false)}
          />
        )}
      </div>
    </div>
  );
}
