import React from 'react';

const RepostSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path d="M3.6226 2.21051C3.47539 2.06329 3.23671 2.06329 3.08949 2.21051L1.13801 4.16199C0.953995 4.34601 0.953995 4.64436 1.13801 4.82838C1.32203 5.0124 1.62039 5.0124 1.80441 4.82838L2.88484 3.74794V9.39576C2.88484 10.697 3.93968 11.7518 5.24089 11.7518H8.25663C8.51687 11.7518 8.72784 11.5408 8.72784 11.2806C8.72784 11.0204 8.51687 10.8094 8.25663 10.8094H5.24089C4.46016 10.8094 3.82726 10.1765 3.82726 9.39576V3.74794L4.90769 4.82838C5.09171 5.0124 5.39006 5.0124 5.57408 4.82838C5.7581 4.64436 5.7581 4.34601 5.57408 4.16199L3.6226 2.21051Z" fill="#6E7377" />
      <path d="M11.854 11.7746C12.0012 11.9219 12.2399 11.9219 12.3871 11.7746L14.3386 9.82317C14.5226 9.63915 14.5226 9.3408 14.3386 9.15678C14.1546 8.97276 13.8562 8.97276 13.6722 9.15678L12.5918 10.2372V4.5894C12.5918 3.28819 11.5369 2.23335 10.2357 2.23335H7.21997C6.95972 2.23335 6.74876 2.44432 6.74876 2.70456C6.74876 2.9648 6.95972 3.17577 7.21997 3.17577H10.2357C11.0164 3.17577 11.6493 3.80867 11.6493 4.5894V10.2372L10.5689 9.15678C10.3849 8.97276 10.0865 8.97276 9.90251 9.15678C9.71849 9.3408 9.71849 9.63915 9.90251 9.82317L11.854 11.7746Z" fill="#6E7377" />
      <path d="M3.6226 2.21051C3.47539 2.06329 3.23671 2.06329 3.08949 2.21051L1.13801 4.16199C0.953995 4.34601 0.953995 4.64436 1.13801 4.82838C1.32203 5.0124 1.62039 5.0124 1.80441 4.82838L2.88484 3.74794V9.39576C2.88484 10.697 3.93968 11.7518 5.24089 11.7518H8.25663C8.51687 11.7518 8.72784 11.5408 8.72784 11.2806C8.72784 11.0204 8.51687 10.8094 8.25663 10.8094H5.24089C4.46016 10.8094 3.82726 10.1765 3.82726 9.39576V3.74794L4.90769 4.82838C5.09171 5.0124 5.39006 5.0124 5.57408 4.82838C5.7581 4.64436 5.7581 4.34601 5.57408 4.16199L3.6226 2.21051Z" stroke="#6E7377" stroke-width="0.3" />
      <path d="M11.854 11.7746C12.0012 11.9219 12.2399 11.9219 12.3871 11.7746L14.3386 9.82317C14.5226 9.63915 14.5226 9.3408 14.3386 9.15678C14.1546 8.97276 13.8562 8.97276 13.6722 9.15678L12.5918 10.2372V4.5894C12.5918 3.28819 11.5369 2.23335 10.2357 2.23335H7.21997C6.95972 2.23335 6.74876 2.44432 6.74876 2.70456C6.74876 2.9648 6.95972 3.17577 7.21997 3.17577H10.2357C11.0164 3.17577 11.6493 3.80867 11.6493 4.5894V10.2372L10.5689 9.15678C10.3849 8.97276 10.0865 8.97276 9.90251 9.15678C9.71849 9.3408 9.71849 9.63915 9.90251 9.82317L11.854 11.7746Z" stroke="#6E7377" stroke-width="0.3" />
    </svg>
  );
};

export default RepostSvg;
