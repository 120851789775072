import { useOverlay } from '@/providers/OverlayProvider';
import styles from './styles.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import { CDN_URL_ICONS } from '@/configs';
import {
  claimReferralEarning,
  getAddressReferents,
  getAddressReferralEarning,
  getReferralCode,
} from '@/services/referral';
import { Spinner, useDisclosure } from '@chakra-ui/react';
import ShareReferralTwitter from '@/modules/AlphaPWA/Profile/ShareReferralTwitter';
import toast from 'react-hot-toast';
import cs from 'classnames';
import SvgInset from '@/components/SvgInset';
import BackIcon from '@/modules/AlphaPWA/OverlayPages/ReferralEarning/icons/BackIcon';
import ShareInfo from '@/modules/AlphaPWA/Profile/ShareInfo';
import { IReferralEarning } from '@/interfaces/api/referral';
import ReferentsModal from '@/modules/AlphaPWA/Profile/ReferentsModal';
import { displayBTCValue } from '@/utils/format';
import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { MINIMUM_BTC_AMOUNT_CAN_CLAIM } from '@/constants/amount';

export default function ReferralEarning() {
  const { addressL2 } = useContext(WalletContext);
  const [referralEarnings, setReferralEarnings] = useState<IReferralEarning>();
  const [referents, setReferents] = useState<{ address: string }[]>([]);
  const [isClaiming, setIsClaiming] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker();

  const {
    isOpen: isOpenReferents,
    onOpen: onOpenReferents,
    onClose: onCloseReferents,
  } = useDisclosure({ id: 'referents_earning' });

  const handleOnClaim = () => {
    if (addressL2) {
      gaEventTracker(GameFiActions.ReferralClaimClick, addressL2);
      setIsClaiming(true);
      claimReferralEarning()
        .then(() => {
          toast.success('Claim your reward successfully.');
        })
        .catch(() => {
          toast.error('Claim your reward failed.');
        })
        .finally(() => {
          getAddressReferralEarning()
            .then(res => {
              setReferralEarnings(res);
            })
            .finally(() => {
              setIsClaiming(false);
            });
        });
    }
  };

  useEffect(() => {
    getAddressReferents().then(_referents => {
      setReferents(_referents);
    });
  }, []);

  useEffect(() => {
    if (!setReferralEarnings) return;
    const getEarings = () => {
      getAddressReferralEarning().then(res => {
        setReferralEarnings(res);
      });
    };

    getEarings();

    let interval: any;
    if (!isOpenReferents) {
      interval = setInterval(() => {
        getEarings();
      }, 30000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOpenReferents]);

  return (
    <div className={styles.referralEarning}>
      <div className={styles.container}>
        {/* Heading */}
        <div className={styles.heading}>
          <span className={styles.heading__text}>
            <SvgInset svgUrl={`${CDN_URL_ICONS}/fire-ic.svg`} />
            Total Referral Earnings
          </span>
          {/* <span
            className={styles.heading__close}
            onClick={() => {
              closeOverlayScreen();
            }}
          >
            <BackIcon />
          </span> */}
          <span className={styles.heading__balance}>
            {' '}
            {displayBTCValue(referralEarnings?.totalBtcEarning)}
          </span>
        </div>

        <div className={styles.body}>
          <div className={styles.body__inner}>
            {/* Loading */}
            {/* {isLoading && (
              <div className={styles.loading}>
                <Spinner />
              </div>
            )} */}

            {/* Codes */}
            <ShareInfo
              className={styles.shareInfo}
              referents={referents}
              referralEarnings={referralEarnings}
            ></ShareInfo>

            <div className={styles.twitterList}>
              <ShareReferralTwitter />
            </div>

            {/* Claim */}
            {Number(referralEarnings?.totalBtcRemain) > 0 && (
              <div className={styles.claimSection}>
                <span className={styles.claimSection__message}>
                  You have{' '}
                  <span className={styles.claimSection__highlight}>
                    {displayBTCValue(referralEarnings?.totalBtcRemain)}
                  </span>{' '}
                  unclaimed
                </span>

                {Number(referralEarnings?.totalBtcRemain) >
                MINIMUM_BTC_AMOUNT_CAN_CLAIM ? (
                  <>
                    {isClaiming ? (
                      <button className={styles.claimSection__btn}>
                        <Spinner />
                      </button>
                    ) : (
                      <button
                        className={styles.claimSection__btn}
                        onClick={handleOnClaim}
                      >
                        Claim
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className={cs(
                      styles.claimSection__btn,
                      styles.claimSection__btn__inactive
                    )}
                  >
                    Claim
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <ReferentsModal
        isOpen={isOpenReferents}
        onClose={onCloseReferents}
        referents={referents || []}
        referentsInfo={referralEarnings?.referents || []}
      />
    </div>
  );
}
