import React from 'react';

import styled from 'styled-components';
import TableContent from './Page.TableContent';
import TableHeader from './Page.TableHeader';
import px2rem from '@/utils/px2rem';

const Container = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;

  * {
    text-align: center;
    font-weight: 500;
    font-size: ${px2rem(16)};
    color: white;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

type Props = {
  dataHeader: any[];
  dataSource: any[];
};

const TableView = React.memo((props: Props) => {
  const { dataSource = [] as any[], dataHeader = [] } = props;
  return (
    <Container>
      <TableHeader dataHeader={dataHeader} />
      <TableContent dataSource={dataSource} />
    </Container>
  );
});

export default TableView;
