import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cs from 'classnames';
import sanitizeHtml from 'sanitize-html';
import s from './styles.module.scss';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import { formatTCAddress } from '@/utils/format';
import moment from 'moment';
import {
  getMessageObject,
  getStickerIndex,
} from '@/modules/AlphaPWA/DirectMessage/helpers';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { poolProfilePersistor } from '@/utils/persistor';
import { DMMessage, DMRoom } from '@/modules/AlphaPWA/DirectMessage/types';
import {
  ASYNC_LOAD_TIMEOUT,
  CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS,
} from '@/modules/AlphaPWA/DirectMessage/constants';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import ChatImageBrowserIcon from '@/modules/AlphaPWA/DirectMessage/svg/ChatImageBrowserIcon';
import { EMOJI_STICKERS } from '@/modules/AlphaPWA/Home/PostItem/Emoji';
import { WalletContext } from '@/contexts/wallet-context';
import PinChat from '../../MenuActionChat/PinChat';

const AVATAR_WIDTH = 50;
export const ITEM_HEIGHT = 88;

const CheckedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49999 15.0003C7.49916 15.0003 7.49833 15.0003 7.49666 15.0003C7.27416 14.9995 7.06249 14.9104 6.90665 14.752L3.57333 11.367C3.24999 11.0387 3.25416 10.5112 3.5825 10.1887C3.91083 9.86619 4.43749 9.86951 4.76082 10.1978L7.50499 12.9845L15.245 5.24535C15.5708 4.91952 16.0975 4.91952 16.4233 5.24535C16.7492 5.57035 16.7492 6.09869 16.4233 6.42369L8.08999 14.757C7.93332 14.9129 7.72083 15.0003 7.49999 15.0003Z"
        fill="#F8F0AC"
      />
    </svg>
  );
};

const Item = ({
  room,
  order,
  onClickItem,
}: {
  room: DMRoom;
  order: number;
  onClickItem?: (id: string) => void;
}) => {
  const router = useRouter();
  const { roomMessages, getUserProfile, updateUserProfile } = useDM();
  const [userPlayer, setUserPlayer] = useState<IGetPlayerPoolProfile | null>(
    getUserProfile(room.owner)
  );

  const { addressL2 } = useContext(WalletContext);

  const isMine = useMemo(() => {
    return addressL2?.toLocaleLowerCase() === room?.owner.toLocaleLowerCase();
  }, [addressL2, room]);

  const targetRef = useRef<HTMLDivElement>(null);
  // const isInViewport = useIsInViewport(targetRef);
  const isLoadingRef = useRef(false);
  const getUserTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const isLoadingLastMessageRef = useRef(false);
  const getUserLastMessageTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const lastMessage = useMemo(() => {
    const messages = roomMessages[room.id];
    return messages?.length
      ? messages[messages.length - 1]
      : room.lastMessage || null;
  }, [roomMessages, room.id]);

  const [lastMessagePlayer, setLastMessagePlayer] =
    useState<IGetPlayerPoolProfile | null>(getUserProfile(lastMessage?.from));

  const isHaveNewMessage = useMemo(() => {
    if (lastMessage && room) {
      if (lastMessage.createdAt && room.lastSeen) {
        return moment(lastMessage.createdAt).isAfter(room.lastSeen);
      } else if (!room.lastSeen) {
        return true;
      }
    }
    return false;
  }, [room, lastMessage]);

  useEffect(() => {
    if (!userPlayer && !isLoadingRef.current) {
      if (getUserTimeoutRef.current) {
        clearTimeout(getUserTimeoutRef.current);
      }
      getUserTimeoutRef.current = setTimeout(() => {
        isLoadingRef.current = true;
        poolProfilePersistor
          .getItem(room.owner)
          .then(storedItem => {
            setUserPlayer(storedItem);
            if (storedItem) {
              updateUserProfile(room.owner, storedItem);
            }
          })
          .finally(() => {
            isLoadingRef.current = false;
          });
      }, ASYNC_LOAD_TIMEOUT);
    }
    return () => {
      if (getUserTimeoutRef.current) {
        clearTimeout(getUserTimeoutRef.current);
      }
    };
  }, [userPlayer]);

  useEffect(() => {
    if (!isLoadingLastMessageRef.current && lastMessage) {
      if (getUserLastMessageTimeoutRef.current) {
        clearTimeout(getUserLastMessageTimeoutRef.current);
      }
      getUserLastMessageTimeoutRef.current = setTimeout(() => {
        isLoadingLastMessageRef.current = true;
        poolProfilePersistor
          .getItem(lastMessage.from)
          .then(storedItem => {
            setLastMessagePlayer(storedItem);
            if (storedItem) {
              updateUserProfile(lastMessage.from, storedItem);
            }
          })
          .finally(() => {
            isLoadingLastMessageRef.current = false;
          });
      }, ASYNC_LOAD_TIMEOUT);
    }

    return () => {
      if (getUserTimeoutRef.current) {
        clearTimeout(getUserTimeoutRef.current);
      }
    };
  }, [lastMessage]);

  const onItemClick = () => {
    if (onClickItem) {
      onClickItem(room?.id);
    } else {
      router.push(`${ROUTE_PATH.ALPHA_MOBILE_DM}?id=${room?.id}`);
    }
  };

  const renderUsers = () => {
    return (
      <>
        <div className={s.pin}>{!isMine && <PinChat room={room} />}</div>
        <div
          className={s.avatars}
          style={{
            width: `${AVATAR_WIDTH}px`,
            minWidth: `${AVATAR_WIDTH}px`,
          }}
        >
          <div className={s.avatar}>
            <Avatar
              width={AVATAR_WIDTH}
              // url={userPlayer && userPlayer.twitterAvatar}
              url={getUrlAvatarTwitter(
                (userPlayer && userPlayer.twitterAvatar) as string
              )}
            />
            {/* <div className={s.avatar_online}></div> */}
          </div>
        </div>
      </>
    );
  };

  const getUserName = (address: string) => {
    if (userPlayer) {
      return (
        userPlayer.twitterName ||
        userPlayer.bns ||
        formatTCAddress(userPlayer.address)
      );
    }
    return formatTCAddress(address);
  };

  const getLastMessageUserName = (lastMessage: DMMessage) => {
    if (lastMessagePlayer) {
      return (
        lastMessagePlayer.twitterName ||
        lastMessagePlayer.bns ||
        formatTCAddress(lastMessagePlayer.address)
      );
    }
    return formatTCAddress(lastMessage.from);
  };

  const localizationMessage = () => {
    try {
      if (room && lastMessage) {
        const translatedMessage =
          lastMessage[CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS[room.language]];
        if (translatedMessage) {
          return translatedMessage;
        }

        return lastMessage.content;
      }
      return '';
    } catch (e) {
      return '';
    }
  };

  const displayMessage = (lastMessage: DMMessage) => {
    try {
      const content = localizationMessage();
      const messageText = getMessageObject(content).plain;
      const isSticker = getMessageObject(content).type === 'STICKER';

      if (isSticker) {
        const stickerText = getStickerIndex(messageText);
        return (
          <>
            {getLastMessageUserName(lastMessage)}:{' '}
            <span className={s.sticker}>
              {EMOJI_STICKERS[Number(stickerText)].icon()}
            </span>
          </>
        );
      }

      if (messageText) {
        return (
          <>
            {getLastMessageUserName(lastMessage)}:
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(messageText, {
                  allowedAttributes: {
                    span: ['class'],
                  },
                }),
              }}
            ></span>
          </>
        );
      }

      if (lastMessage.mediaUrls.length) {
        return (
          <>
            {getLastMessageUserName(lastMessage)}:{' '}
            <ChatImageBrowserIcon size={16} />
          </>
        );
      }

      return '';
    } catch (e) {
      return '';
    }
  };

  return (
    <div
      className={s.wrapper}
      onClick={onItemClick}
      style={{
        height: ITEM_HEIGHT,
        top: `${order * ITEM_HEIGHT}px`,
      }}
      ref={targetRef}
    >
      <div className={s.item}>
        <div className={s.info}>
          {renderUsers()}
          <div className={s.chat}>
            <div className={s.user}>
              <span
                className={cs(
                  s.user_name,
                  isHaveNewMessage ? s.user_name_newMessage : ''
                )}
              >
                {getUserName(room.owner)}
              </span>
              <div className={s.rightUser}>
                {lastMessage && lastMessage.createdAt && (
                  <time className={s.user_time}>
                    {moment(lastMessage.createdAt)
                      .fromNow()
                      .replace(' ago', '')}
                  </time>
                )}
              </div>
            </div>
            <div className={s.message}>
              <span
                className={cs(
                  s.message__text,
                  isHaveNewMessage ? s.message__text__newMessage : ''
                )}
              >
                {!!lastMessage && <>{displayMessage(lastMessage)}</>}
              </span>
              <span className={s.message__badge}>
                {!!room.unreadCount ? (
                  <span className={s.message__badge__unreadCount}>
                    {room.unreadCount < 10 ? room.unreadCount : '9+'}
                  </span>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Item);
