import React from 'react';

const IcUncheck = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="black" fill-opacity="0.5" />
    </svg>
  );
};

export default IcUncheck;
