import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

const context = createContext<{
  connected: boolean;
}>({} as any);

const InternetProvider = ({ children }: { children: any }) => {
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    if (
      isMobile &&
      window.location.pathname.includes('pro.newbitcoincity.com')
    ) {
      window.location.href = 'https://newbitcoincity.com/alpha';
    }

    window.addEventListener('online', function () {
      setConnected(true);
    });

    window.addEventListener('offline', function () {
      setConnected(false);
    });
  }, [window?.location?.pathname, isMobile]);

  const values = useMemo(() => {
    return {
      connected,
    };
  }, [connected]);

  return <context.Provider value={values}>{children}</context.Provider>;
};

export default InternetProvider;

export const useInternet = () => {
  return useContext(context);
};
