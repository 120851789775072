import React, { useState, useRef, useEffect, PropsWithChildren } from 'react';
import s from './styles.module.scss';

interface IProps extends PropsWithChildren {
  linesToShow: number;
  lineHeight: number;
}

const ShowMoreByLines: React.FC<IProps> = ({
  linesToShow,
  lineHeight,
  children,
}: IProps): React.ReactElement => {
  const [showMore, setShowMore] = useState(false);
  const contentRef = useRef<any>(null);
  const [totalLines, setTotalLines] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = contentRef.current.clientHeight / linesToShow;
      const totalLines = contentRef.current.scrollHeight / lineHeight;
      setTotalLines(Math.ceil(totalLines));
    }
  }, [linesToShow, lineHeight]);

  const toggleShowMore = (e: any) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <div>
      <div
        ref={contentRef}
        style={{
          maxHeight: showMore ? 'none' : `${linesToShow * lineHeight}px`, // 1.2em as an approximation of line height
          overflow: 'hidden',
        }}
      >
        <>{children}</>
      </div>
      {totalLines > linesToShow && (
        <span className={s.showMore} onClick={toggleShowMore}>
          {showMore ? 'Show less' : 'Show more'}
        </span>
      )}
    </div>
  );
};

export default ShowMoreByLines;
