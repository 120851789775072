import useAnalyticsEventTracker, { GameFiActions } from '@/utils/ga';
import { Button, Flex } from '@chakra-ui/react';
import IcTwitter from '@/modules/AlphaPWA/Profiles/TradeKey/assets/icons/icTwitter';
import { useContext, useEffect, useState } from 'react';
import { WalletContext } from '@/contexts/wallet-context';
import { getLink } from '@/utils/helpers';
import { getReferralCode, getReferralCodeByTwId } from '@/services/referral';
import s from './styles.module.scss';
import { User } from '@/interfaces/api/post';
import { compareString, formatCurrency } from '@/utils';

const BtnShareTw = ({
  wheelCreator,
  isDisabled,
  wheelDetail,
  tokenName,
  users,
}: {
  wheelCreator: User | null;
  isDisabled?: boolean;
  tokenName: string;
  wheelDetail?: any;
  users: Array<any>;
}) => {
  const { addressL2 } = useContext(WalletContext);
  const [referralCode, setReferralCode] = useState();
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    // getReferralCode()
    //   .then(code => {
    //     // setReferralCode(code);
    //   })
    //   .finally(() => {});
    if (wheelCreator?.twitter_id) {
      getReferralCodeByTwId({ twitter_id: wheelCreator?.twitter_id || '' })
        .then(code => {
          setReferralCode(code);
        })
        .finally(() => {});
    }
  }, [wheelCreator]);

  return (
    <Button
      isDisabled={isDisabled}
      className={s.btnSendInvitePlaceHolder}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        let content: string = '';

        content = `@${wheelCreator?.twitter_username} created a magical raffle at @NewBitcoinCity.`;

        content +=
          users.length > 0
            ? `\n\n${formatCurrency(
                users.length,
                0,
                0
              )} played and @${wheelDetail?.RaffleSpend?.ToTwitter
                ?.TwitterUsername} won 1${tokenName} .`
            : '';

        content += `\n\nJoin in on the enchanting fun with me.\n\n`;
        const shareUrl = getLink(referralCode || '');

        gaEventTracker(
          GameFiActions.TweetTabPlaceHolderReferralCode,
          addressL2
        );
        window.open(
          `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
            content
          )}`,
          '_blank'
        );
      }}
    >
      <Flex alignItems={'center'} gap={2} justifyContent={'space-between'}>
        Share on <IcTwitter />
      </Flex>
    </Button>
  );
};

export default BtnShareTw;
