import { Input } from '@/components/Input';
import { AssetsContext } from '@/contexts/assets-context';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { Formik } from 'formik';
import { isNumber } from 'lodash';
import React, { useContext } from 'react';
import s from './styles.module.scss';
import { getErrorMessage } from '@/utils/error';
import { settingMinKeyRequirement } from '@/services/verifyFriendTech';
import { WalletContext } from '@/contexts/wallet-context';
import { colors } from '@/utils/color';
import { formatCurrency } from '@/utils';
import { showSuccess } from '../../Profiles/TradeKey/components/toast';
import {
  labelAmountOrNumber,
  labelAmountOrNumberAdds,
} from '../../Profiles/TradeKey/constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fetchPlayerPoolProfile: () => void;
  poolProfile: any;
};

interface IFormValue {
  amount: string;
}

const MinKeyRequirement: React.FC<Props> = ({
  isOpen,
  onClose,
  poolProfile,
  fetchPlayerPoolProfile,
}) => {
  const { addressL2 } = useContext(WalletContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<string>('');
  const [numKey, setNumKey] = React.useState<any>(0);

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.amount) {
      errors.amount = 'Amount is required';
    } else if (parseFloat(values.amount) < 0.1) {
      errors.amount = 'Minimum amount is: 0.1 key';
    }
    // debounceEstimateGas(values);
    return errors;
  };

  const getError = (inputAmount: string) => {
    if (inputAmount !== '0') {
      if (error) return error;
    }
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    try {
      setError('');
      setLoading(true);
      const response: any = await settingMinKeyRequirement(
        addressL2 as string,
        Number(payload.amount)
      );
      await fetchPlayerPoolProfile();
      setSuccess('');
      setError('');
      showSuccess({
        message: 'Successfully',
      });
      onClose();
    } catch (error) {
      setSuccess('');
      const { message } = getErrorMessage(error);
      setError(message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={s.wrapper}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent
          p="24px 22px"
          w={'90%'}
          textAlign={'center'}
          maxW="600px"
          className={s.wrapper}
        >
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader textAlign="left" fontSize="24px" fontWeight={'600'}>
            Minimum key requirement
          </ModalHeader>
          <ModalBody>
            <Text textAlign={'left'} mb={5}>
              Set up the {labelAmountOrNumber(numKey).toLowerCase()} of key
              {labelAmountOrNumberAdds(numKey)} a holder must have to access
              your content and DM you.
            </Text>
            <Formik
              key="min-key-requirement-form"
              initialValues={{
                amount: poolProfile?.minHoldingRequirement,
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ values, touched, errors, handleChange, handleSubmit }) => {
                const _error = getError(
                  ethers.utils
                    .parseEther(String(values.amount || '0'))
                    .toString()
                );
                return (
                  <form className={s.form} onSubmit={handleSubmit}>
                    <Input
                      id="amount"
                      type="number"
                      title={`${labelAmountOrNumber(
                        numKey
                      )} of key${labelAmountOrNumberAdds(numKey)}`}
                      value={values.amount}
                      placeholder="0.0"
                      onChange={e => {
                        setNumKey(e.target.value);
                        handleChange(e);
                      }}
                      errorMsg={
                        _error
                          ? _error
                          : errors.amount && touched.amount
                          ? errors.amount
                          : undefined
                      }
                      classContainer={s.input}
                      classInput={s.classInput}
                    />
                    {success && (
                      <Text color={colors.greenPrimary}>{success}</Text>
                    )}
                    <Button
                      isLoading={loading}
                      disabled={!!_error || loading}
                      className={s.submitBtn}
                      type="submit"
                      width={'100%'}
                      mt={10}
                    >
                      {loading ? 'Processing...' : 'Confirm'}
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MinKeyRequirement;
