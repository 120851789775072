import { useRouter } from 'next/router';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  PropsWithRef,
} from 'react';
import cn from 'classnames';
import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import { getPinnedPosts, getPosts } from '@/services/alpha_tweet';
import AppLoading from '@/components/AppLoading';
import { AssetsContext } from '@/contexts/assets-context';
import useApiInfinite from '@/hooks/useApiInfinite';
import InfiniteScroll from 'react-infinite-scroll-component';
import Empty from './Empty';
import PostItem from './PostItem/PostItemV2';
import s from './AlphaHome.module.scss';
import { isMobile } from '@/utils/animation';
import { HomeContext } from '@/modules/AlphaPWA/Home/provider';
import { useProfiles } from '../Profiles/provider';
import TweetDetails from '../TweetDetail';
import SuggestionFollowList from '@/components/SuggestionFollowList';

interface IFormTradeKey extends PropsWithRef<any> {
  className?: string;
}

const Posts: React.FC<IFormTradeKey> = forwardRef(({ className }, ref) => {
  const router = useRouter();
  const keyId = `post${router?.pathname?.replaceAll('/', '-')}`;
  const infiniteScrollRef = useRef<any>(null);
  const isReloadPage =
    localStorage.getItem('RELOAD_PAGE') === ROUTE_PATH.ALPHA_MOBILE_HOME;
  const [initialScrollYSaved, setInitialScrollYSaved] = useState<number>(
    Number(sessionStorage.getItem(keyId)) || 0
  );
  const [postData, setPostData] = useState<any[]>();
  const { addressL2 } = useContext(WalletContext);
  const { refreshTokenTwitter } = useContext(AssetsContext);
  const { filterPost } = useContext(HomeContext);
  const [pinnedPost, setPinnedPost] = useState<any>();
  const { playerPoolProfile } = useProfiles();

  const [postDetailId, setPostDetailId] = useState<number | undefined>(
    undefined
  );

  const getFeedData = async ({ page = 1, limit = 20 }) => {
    try {
      await refreshTokenTwitter();
      const res: any = await getPosts({
        page: page,
        limit,
        address: addressL2,
        only_following: true,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const {
    dataInfinite,
    loadMore,
    refresh,
    isLoadingMore,
    isReachingEnd,
    hasFirstFetching,
    isEmpty,
    isRefreshing,
  } = useApiInfinite(
    getFeedData,
    { key: keyId, limit: 20 },
    { revalidateOnFocus: true }
  );
  const handleRefresh = () => {
    // scroll to top
    sessionStorage.setItem(keyId, '0');
    setInitialScrollYSaved(0);
    if (infiniteScrollRef?.current) {
      infiniteScrollRef?.current?.el?.scrollTo({
        top: 0,
        left: 0,
        // behavior: 'smooth',
      });
    }
    // reload data
    refresh();
  };

  const handleRouteChangeStart = (url: string) => {
    if (url === ROUTE_PATH.ALPHA_MOBILE_HOME) {
      handleRefresh();
    }
  };

  const fetchPinnedPosts = async () => {
    if (!playerPoolProfile?.twitterId || !addressL2) return;
    try {
      await refreshTokenTwitter();
      const res = await getPinnedPosts({
        twitterId: playerPoolProfile?.twitterId,
        network: 'nos',
        address: addressL2,
        activity: 'social',
      });
      setPinnedPost(res);
    } catch (err: unknown) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPinnedPosts();
  }, [playerPoolProfile, addressL2]);

  useEffect(() => {
    if (isReloadPage) {
      handleRefresh();
      localStorage.removeItem('RELOAD_PAGE');
    }
  }, [isReloadPage]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events]);

  const onBackTop = () => {
    if (infiniteScrollRef?.current) {
      infiniteScrollRef?.current?.el?.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  useEffect(() => {
    if (!hasFirstFetching) return;
    const renderData = dataInfinite?.map(item => {
      if (pinnedPost?.id === item.id) {
        return {
          ...item,
          type: 'post',
          isPinned: true,
        };
      }
      return {
        ...item,
        type: 'post',
      };
    });

    if (renderData && renderData?.length > 5) {
      renderData.splice(4, 0, {
        type: 'suggestion',
      });
    } else if (
      renderData &&
      renderData?.length > 0 &&
      renderData?.length <= 5
    ) {
      renderData?.push({
        type: 'suggestion',
      });
    }

    setPostData(renderData);
  }, [hasFirstFetching, filterPost, isLoadingMore, isRefreshing, pinnedPost]);

  useEffect(() => {
    onBackTop();
  }, [filterPost]);

  useImperativeHandle(ref, () => ({
    refresh: handleRefresh,
  }));

  const handleOnClickReply = () => {
    if (Number(sessionStorage.getItem(keyId)) === 0) {
      if (infiniteScrollRef?.current) {
        infiniteScrollRef?.current?.el?.scrollTo({
          top: 1,
          left: 0,
        });
      }
    }
  };

  return (
    <div className={cn(s.feed_wrapper, className)}>
      {hasFirstFetching === false ? (
        <div className={s.loadingMore}>
          <AppLoading />
        </div>
      ) : (
        <InfiniteScroll
          ref={infiniteScrollRef}
          className={`disable-scrollbar-x ${
            isMobile() ? '' : 'hide-scrollbar'
          }`}
          height="calc(100dvh - 130px)"
          dataLength={dataInfinite?.length || 500}
          hasMore={isReachingEnd === false}
          loader={
            isLoadingMore && (
              <div className={s.loadingMore}>
                <AppLoading />
              </div>
            )
          }
          initialScrollY={initialScrollYSaved}
          refreshFunction={refresh}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          next={loadMore}
          onScroll={(event: any) => {
            sessionStorage.setItem(keyId, event?.srcElement?.scrollTop);
          }}
          scrollableTarget="scrollableDiv"
        >
          {isRefreshing && (
            <div className={s.loadingMore}>
              <AppLoading />
            </div>
          )}
          <div className={s.post_list_wrapper}>
            {postData &&
              postData.length > 0 &&
              postData?.map((post, index) => {
                return (
                  <>
                    {post.type === 'post' && (
                      <PostItem
                        data={!post.is_repost ? post : post.repost_tweet}
                        key={`${post.id}-${index}`}
                        dataParent={post}
                        onFetchData={() => {
                          refresh();
                        }}
                        isPinned={post.isPinned}
                        fetchPinnedPosts={fetchPinnedPosts}
                        onClickDetail={id => setPostDetailId(id)}
                        onClickReply={handleOnClickReply}
                      />
                    )}
                    {post.type === 'suggestion' && (
                      <SuggestionFollowList key={index} renderType="feed" />
                    )}
                  </>
                );
              })}
          </div>
          {(isEmpty || (postData && postData.length === 0)) && <Empty />}
        </InfiniteScroll>
      )}
      {postDetailId && (
        <div className={s.detailContent}>
          <TweetDetails
            id={postDetailId}
            onClickBack={() => setPostDetailId(undefined)}
          />
        </div>
      )}
    </div>
  );
});

export default React.memo(Posts);
