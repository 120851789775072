import CPlayerShare from '@/contracts';
import { IBodyIssueKeys } from '@/contracts/interfaces/issueKey';
import {
  IBodyBuySweetFloorKeys,
  IBodyCheckABalance,
  IBodyFillOrderLimit,
  IBodyFillOrderLimitV2,
  IEstimateTCMultiKeys,
} from '@/contracts/interfaces/tradeKey';
import { IPagingParams } from '@/interfaces/api/query';
import { IThreeThreeInvitation } from '@/interfaces/threethree';
import {
  SLIDE_ALPHA,
  SLIDE_FT,
  TRADE_ACTION,
} from '@/modules/AlphaPWA/Profiles/TradeKey/constants';
import { SIDE } from '@/modules/AlphaPWA/ThreeThreeInvitations';
import CTradeAPI from '@/services/classes/trade';
import { ILimitOrder, IResponseOrders } from '@/services/interfaces/order';
import {
  IParamsGetKeys,
  IResponseTradeKeys,
  ITradeKey,
} from '@/services/interfaces/trade';
import { IUserProfile } from '@/services/interfaces/userProfile';
import { getThreeThreeInvitationList } from '@/services/three-three';
import { selectCommonReducer } from '@/state/common';
import { useAppSelector } from '@/state/hooks';
import { compareString } from '@/utils';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import { formatAmountToClient } from '@/utils/helpers';
import { BigNumber, ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { WalletContext } from './wallet-context';
import { getBtcUsdtRateV2 } from '@/utils/format';
import { isMobile } from 'react-device-detect';
import { BASE_SYMBOL } from '@/contracts/configs';
import { sortBy, uniqBy } from 'lodash';
import errorLogger from '@/services/errorLogger';
import { AssetsContext } from './assets-context';
import { ILocation } from '@/services/interfaces/location';

interface IProps {
  children: React.ReactNode;
}
interface IContext {
  userProfile: IUserProfile | undefined;
  userLocation: ILocation | undefined;
  getEstimatePriceTC: (_: string, __: number, ___: string) => Promise<any>;
  alphaBuyOrSell: (_: string, __: number, ___: string) => Promise<any>;
  getTokenDetail: (token_address: string) => Promise<ITradeKey>;
  scanTrxAlpha: (tx_hash: string, network?: 'nos' | 'base') => Promise<any>;
  getTradeKeys: (params: IParamsGetKeys) => Promise<IResponseTradeKeys>;
  getTopPlaceholdersConverted: (
    params: IParamsGetKeys
  ) => Promise<IResponseTradeKeys>;
  getEstimatePriceFT: (_: string, __: number, ___: string) => Promise<any>;
  alphaBuyOrSellFT: (_: string, __: number, ___: string) => Promise<any>;
  estimateTCMultiKeys: (
    body: IBodyBuySweetFloorKeys[]
  ) => Promise<IEstimateTCMultiKeys>;
  estimateBothSide: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  buySweetFloorKeys: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  requestTrade33Keys: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  trade33Keys: (body: IBodyBuySweetFloorKeys[]) => Promise<any>;
  cancelTrade33Keys: (body: string) => Promise<any>;
  rejectTrade33Keys: (body: string) => Promise<any>;
  reRequestTrade33Keys: (body: IBodyBuySweetFloorKeys) => Promise<any>;
  checkAEnoughBalanceAndAllowance: (body: IBodyCheckABalance) => Promise<any>;
  issueKeyByTwitter: (body: IBodyIssueKeys) => Promise<any>;
  estimateTCBeforeMint: ({
    token_amount,
    supply,
  }: {
    token_amount: number;
    supply: number;
  }) => Promise<any>;
  has33Requested: boolean;
  estimateBTCForLimitOrder: ({
    supply,
    amount,
  }: {
    supply: BigNumber;
    amount: BigNumber;
  }) => any;
  getFillOpenOrders: (params: IPagingParams) => Promise<IResponseOrders>;
  limitOrder: (body: IBodyFillOrderLimit) => Promise<any>;
  limitOrderV2: (body: IBodyFillOrderLimitV2) => Promise<any>;
  cancelFillOrder: ({ nonce }: { nonce: string }) => Promise<any>;
  updateCreateFee: ({
    token_address,
    fee,
  }: {
    token_address: string;
    fee: number;
  }) => Promise<any>;
  canTradeFT: boolean | undefined;
  tokenDetail: ITradeKey | undefined;
  eth2USDT: number;
  isUserFT: boolean;
}

export const TradeKeyContext = React.createContext({} as IContext);

const TradeKeyProvider = ({ children }: IProps) => {
  const playerShareContract = new CPlayerShare();
  const { gameWallet, addressL2 } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);
  const needReload = useAppSelector(selectCommonReducer).needReload;
  const tradeAPI = new CTradeAPI();
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [userLocation, setUserLocation] = useState<ILocation>();

  const address = gameWallet?.address;

  const gaEventTracker = useAnalyticsEventTracker();

  const intervalRef = useRef<any>();

  const [data33Request, setData33Request] = useState<IThreeThreeInvitation[]>(
    []
  );

  const isUserFT = useMemo(
    () => Boolean(userProfile?.ft_trader_address),
    [userProfile]
  );

  const [tokenDetail, setTokenDetail] = useState<ITradeKey | undefined>(
    undefined
  );
  const [eth2USDT, setEth2USDT] = useState<number>(0);

  useEffect(() => {
    getCurrentUserProfile();
    getETH2USDT();
  }, [address, needReload]);

  // TODO: LEON hide three three
  // useEffect(() => {
  //   if (addressL2) {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //     const interval = setInterval(fetch33Request, 7000);
  //     intervalRef.current = interval;
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [addressL2, needReload]);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    try {
      const response = await tradeAPI.getLocation();
      setUserLocation(response);
    } catch (error) {}
  };

  const getETH2USDT = async () => {
    try {
      const response = await getBtcUsdtRateV2('ETHUSDT');
      setEth2USDT(response);
    } catch (error) {}
  };

  const updateCreateFee = async ({
    token_address,
    fee,
  }: {
    token_address: string;
    fee: number;
  }) => {
    try {
      const response = await playerShareContract.updateCreateFee({
        token_address,
        fee,
      });
      scanTrxAlpha(response.hash);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetch33Request = async (
    page = 1,
    isFetchMore = false,
    pageLimit = 1
  ) => {
    try {
      const res = await getThreeThreeInvitationList({
        address: addressL2 || '',
        side: SIDE.REQUESTED,
        page: page,
        limit: pageLimit,
      });

      if (isFetchMore) {
        setData33Request(prev => [...prev, ...res]);
      } else {
        setData33Request(res);
      }
    } catch (e) {
    } finally {
    }
  };

  const has33Requested = useMemo(() => {
    const i = data33Request.findIndex(d => d.status === 'waiting');
    return i >= 0;
  }, [JSON.stringify(data33Request)]);

  const getCurrentUserProfile = async () => {
    try {
      if (address) {
        const res = await tradeAPI.getUserProfile(address);

        const tokenAddress = await playerShareContract.getAlphaKeysToken();

        const fee = await playerShareContract.getCreatorFee(tokenAddress);

        setUserProfile({
          ...res,
          creator_fee: fee,
          token_address: tokenAddress,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const scanTrxAlpha = async (
    tx_hash: string,
    network: 'nos' | 'base' = 'nos'
  ) => {
    try {
      return await tradeAPI.scanTrxAlpha({ tx_hash, network });
    } catch (error) {
      //
    }
  };

  const getTokenDetail = async (
    tokenAddress: string = ''
  ): Promise<ITradeKey> => {
    try {
      const response = await tradeAPI.getTokenDetail(tokenAddress);

      if (
        response.address &&
        !compareString(response.address, ethers.constants.AddressZero)
      ) {
        const [yourBalance, tokenInfo, fee, platformFee] =
          await Promise.allSettled([
            playerShareContract.getTokenBalanceFree(response.address),
            playerShareContract.getTokenERC20Info(response.address),
            playerShareContract.getCreatorFee(response.address as string),
            playerShareContract.getPlatformFee(response.address as string),
          ]);

        if (fee.status === 'fulfilled') {
          response.create_fee = fee.value;
        }
        if (platformFee.status === 'fulfilled') {
          response.trading_fee = platformFee.value;
        }

        if (yourBalance.status === 'fulfilled') {
          response.your_balance = formatAmountToClient(
            yourBalance?.value?.toString?.() || '0'
          ) as any;
        }

        if (tokenInfo.status === 'fulfilled') {
          response.symbol = tokenInfo.value.symbol;
          response.total_supply_number = parseFloat(
            formatEther(tokenInfo.value.totalSupply?.toString())
          );
        }

        response.btc_price = (
          parseFloat(response.usd_price as string) /
          parseFloat(response.price as string)
        ).toString();
        setTokenDetail(response);
      }

      return response;
    } catch (error) {
      throw error;
    }
  };

  const canTradeFT = useMemo(() => {
    if (tokenDetail) {
      if (userProfile) {
        const _canTrade =
          Boolean(userProfile?.ft_trader_address) &&
          // Boolean(tokenDetail?.ft_trader_address) &&
          !compareString(
            tokenDetail?.ft_trader_address,
            ethers.constants.AddressZero
          ) &&
          !compareString(
            userProfile?.ft_trader_address,
            ethers.constants.AddressZero
          ) &&
          compareString(tokenDetail?.base_token_symbol, BASE_SYMBOL) &&
          // compareString(tokenDetail?.address, tokenDetail?.ft_trader_address) &&
          window.ethProvider &&
          !isMobile;
        return _canTrade;
      } else {
        const _canTrade =
          // Boolean(tokenDetail?.ft_trader_address) &&
          !compareString(
            tokenDetail?.ft_trader_address,
            ethers.constants.AddressZero
          ) &&
          compareString(tokenDetail?.base_token_symbol, BASE_SYMBOL) &&
          window.ethProvider &&
          !isMobile;
        return _canTrade;
      }
    }

    return undefined;
  }, [userProfile, tokenDetail, window.ethProvider]);

  useEffect(() => {
    if (canTradeFT) {
      getFTBalance();
    }
  }, [canTradeFT, userProfile, needReload]);

  const getFTBalance = async () => {
    try {
      const FTBalance = await playerShareContract.getBalanceFT(
        tokenDetail?.ft_trader_address as string,
        userProfile?.ft_trader_address as string
      );

      setTokenDetail(_detail => ({
        ...tokenDetail,
        your_ft_balance: parseFloat(FTBalance.toString()),
      }));
    } catch (error) {}
  };

  const estimateTCBeforeMint = async ({
    token_amount,
    supply,
  }: {
    token_amount: number;
    supply: number;
  }) => {
    try {
      const amount = await playerShareContract.estimateTCBeforeMint({
        token_amount,
        supply,
      });
      return amount;
    } catch (error) {
      return 0;
    }
  };

  const getEstimatePriceTC = async (
    token_address: string,
    token_amount: number,
    type: string
  ) => {
    let resultAmount: any = {
      amount: 0,
      amount_after_fee: 0,
      amount_fee: 0,
    };
    try {
      if (type === TRADE_ACTION.BUY) {
        const response = await Promise.all([
          playerShareContract.getBuyPriceAfterFee({
            token_amount,
            token_address,
          }),
          playerShareContract.getBuyPrice({
            token_amount,
            token_address,
          }),
        ]);

        console.log(
          'response[0]',
          response[0].toString(),
          response[1].toString()
        );

        const amount_fee = BigNumber.from(response[0])
          .sub(response[1])
          .toString();
        resultAmount = {
          amount_after_fee: response[0],
          amount: response[1],
          amount_fee,
        };
      } else {
        const response = await Promise.all([
          playerShareContract.getSellPriceAfterFee({
            token_amount,
            token_address,
          }),
          playerShareContract.getSellPrice({
            token_amount,
            token_address,
          }),
        ]);
        const amount_fee = BigNumber.from(response[0])
          .sub(response[1])
          .abs()
          .toString();
        console.log(
          'response[0]',
          response[0].toString(),
          response[1].toString()
        );
        resultAmount = {
          amount_after_fee: response[0],
          amount: response[1],
          amount_fee,
        };
      }
      return resultAmount;
    } catch (error) {
      console.log('error', error);

      return resultAmount;
    }
  };

  const alphaBuyOrSell = async (
    token_address: string,
    token_amount: number,
    type: string
  ) => {
    try {
      let tx: any = 0;

      const tcAmount = await getEstimatePriceTC(
        token_address,
        token_amount,
        type
      );

      if (type === TRADE_ACTION.BUY) {
        tx = await playerShareContract.buyKeys({
          token_amount,
          token_address,
          tc_amount: tcAmount.amount_after_fee.toString(),
        });
      } else {
        tx = await playerShareContract.sellKeys({
          token_amount,
          token_address,
        });
      }
      await tx.wait();
      gaEventTracker(
        TRADE_ACTION.BUY
          ? AlphaActions.DeskTopBuyNBC
          : AlphaActions.DeskTopSellNBC,
        JSON.stringify({
          token_address,
          trader: gameWallet?.address,
          token_amount,
        })
      );
      return tx;
    } catch (error: any) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.BuyKey,
        error: JSON.stringify({
          error: error,
          info: {
            token_address,
            trader: gameWallet?.address,
            token_amount,
            type,
            trader_balance: balanceL2,
          },
        }),
      });
      gaEventTracker(
        AlphaActions.TradeKey,
        JSON.stringify({
          error: error,
          info: {
            token_address,
            trader: gameWallet?.address,
            token_amount,
            type,
          },
        })
      );
      throw error;
    }
  };

  const getTradeKeys = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      let response: IResponseTradeKeys;

      if (compareString(params.placeholder, '1')) {
        params.key_type = '2';
        response = await tradeAPI.getKeys(params);
      } else {
        params.key_type = '1';

        if (compareString(params.sort_col, 'portfolio')) {
          params.portfolio = '1';
          response = await tradeAPI.getKeys(params);
          let _rows = response.rows;
          const [_profile, _tokenDetail] = await Promise.all([
            tradeAPI.getUserProfile(
              '0xFab817c73F818Fb4DeCd086Adb9da8FfD43dF113'
            ),
            tradeAPI.getTokenDetail(
              '0xFab817c73F818Fb4DeCd086Adb9da8FfD43dF113'
            ),
          ]);
          const detail: ITradeKey = {
            ..._tokenDetail,
            address: _profile?.address,
            portfolio: _profile?.portfolio,
          };
          _rows.unshift(detail);

          _rows = uniqBy(_rows, 'address').sort(
            (a, b) => Number(b.portfolio) - Number(a.portfolio)
          );
          response.rows = _rows;
        } else {
          response = await tradeAPI.getKeysV1(params);
        }
      }

      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const getTopPlaceholdersConverted = async (
    params: IParamsGetKeys
  ): Promise<IResponseTradeKeys> => {
    try {
      const response: IResponseTradeKeys =
        await tradeAPI.getTopPlaceholdersConverted(params);
      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const estimateTCMultiKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<IEstimateTCMultiKeys> => {
    try {
      const response = await playerShareContract.estimateTCMultiKeys(body);
      return response;
    } catch (error) {
      throw error;
      //
    }
  };

  const getEstimatePriceFT = async (
    ft_trader_address: string,
    token_amount: number,
    type: string
  ) => {
    let resultAmount: any = {
      amount: 0,
      amount_after_fee: 0,
      amount_fee: 0,
    };
    try {
      if (type === TRADE_ACTION.BUY) {
        const response = await Promise.all([
          playerShareContract.getBuyPriceAfterFeeFT({
            token_amount,
            ft_trader_address,
          }),
          playerShareContract.getBuyPriceFT({
            token_amount,
            ft_trader_address,
          }),
        ]);

        console.log(
          'response[0]',
          response[0].toString(),
          response[1].toString()
        );

        const amount_fee = BigNumber.from(response[0])
          .sub(response[1])
          .toString();
        resultAmount = {
          amount_after_fee: response[0],
          amount: response[1],
          amount_fee,
          token_address: ft_trader_address,
          token_amount,
        };
      } else {
        const response = await Promise.all([
          playerShareContract.getSellPriceAfterFeeFT({
            token_amount,
            ft_trader_address,
          }),
          playerShareContract.getSellPriceFT({
            token_amount,
            ft_trader_address,
          }),
        ]);
        const amount_fee = BigNumber.from(response[0])
          .sub(response[1])
          .abs()
          .toString();
        console.log(
          'response[0]',
          response[0].toString(),
          response[1].toString()
        );
        resultAmount = {
          amount_after_fee: response[0],
          amount: response[1],
          amount_fee,
        };
      }
      return resultAmount;
    } catch (error) {
      console.log('error', error);

      return resultAmount;
    }
  };

  const estimateBothSide = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const isMyFT = Boolean(userProfile?.ft_trader_address);
      const nb = body.filter(v => compareString(v.side, SLIDE_ALPHA));
      const ft = body.filter(v => compareString(v.side, SLIDE_FT));

      console.log('estimateBothSide', nb, ft);

      const ftPromises = () =>
        ft.map(v =>
          getEstimatePriceFT(v.token_address, v.token_amount, TRADE_ACTION.BUY)
        );

      const nbPromises = () =>
        nb.map(v => playerShareContract.getBuyPriceAfterFeeForMultiCall(v));

      // const [resNB, resFT] = await Promise.all([
      //   await Promise.all(nbPromises()),
      //   await Promise.all(ftPromises()),
      // ]);

      const totalNB = nb.reduce((p: any, c) => {
        return BigNumber.from(p)
          .add(parseEther(c.key?.price?.toString() || '0'))
          .toString();
      }, '0');

      let result: any = {
        nb: {
          total_tc: totalNB,
          total_tc_formatted: formatAmountToClient(totalNB),
          alphaTokens: nb,
        },
      };

      const totalT = ft.reduce(
        (p: any, c) =>
          BigNumber.from(p)
            .add(parseEther(c.key?.ft_share_price?.toString() || '0'))
            .toString(),
        '0'
      );

      console.log('totalT', totalT);

      result = {
        ...result,
        ft: {
          total_tc: totalT,
          total_tc_formatted: formatAmountToClient(totalT),
          alphaTokens: ft,
        },
      };

      return result;
    } catch (error) {
      console.log('aaaa', error);

      throw error;
      //
    }
  };

  const buySweetFloorKeys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const response = await playerShareContract.buySweetFloorKeys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: errorLogger.ERROR_LOGGER_TYPE.SWEEP_KEYS,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.SweepTradeKey,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const issueKeyByTwitter = async (body: IBodyIssueKeys): Promise<any> => {
    try {
      const response = await playerShareContract.autoCreateAlphaKeysFor(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.IssuedKey,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.IssuedKey,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const requestTrade33Keys = async (
    body: IBodyBuySweetFloorKeys[]
  ): Promise<any> => {
    try {
      const response = await playerShareContract.requestTrade33Keys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.Request33,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.Request33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const trade33Keys = async (body: IBodyBuySweetFloorKeys[]): Promise<any> => {
    try {
      const response = await playerShareContract.trade33Keys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.Trade33,
        error: JSON.stringify({
          error: error,
          info: body,
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.Trade33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const cancelTrade33Keys = async (order_id: string): Promise<any> => {
    try {
      const response = await playerShareContract.cancelTrade33Keys(order_id);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.CancelTrade33,
        error: JSON.stringify({
          error: error,
          info: { order_id },
          trader_balance: balanceL2,
        }),
      });
      gaEventTracker(
        AlphaActions.CancelTrade33,
        JSON.stringify({
          error: error,
          info: { order_id },
        })
      );
      throw error;
      //
    }
  };

  const rejectTrade33Keys = async (order_id: string): Promise<any> => {
    try {
      const response = await playerShareContract.rejectTrade33Keys(order_id);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.CancelTrade33,
        error: JSON.stringify({
          error: error,
          info: { order_id },
        }),
      });
      gaEventTracker(
        AlphaActions.CancelTrade33,
        JSON.stringify({
          error: error,
          info: { order_id },
        })
      );
      throw error;
      //
    }
  };

  const reRequestTrade33Keys = async (
    body: IBodyBuySweetFloorKeys
  ): Promise<any> => {
    try {
      const response = await playerShareContract.reRequestTrade33Keys(body);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.ReRequest33,
        error: JSON.stringify({
          error: error,
          info: body,
        }),
      });
      gaEventTracker(
        AlphaActions.ReRequest33,
        JSON.stringify({
          error: error,
          info: body,
        })
      );
      throw error;
      //
    }
  };

  const checkAEnoughBalanceAndAllowance = async (
    body: IBodyCheckABalance
  ): Promise<any> => {
    try {
      const response =
        await playerShareContract.checkAEnoughBalanceAndAllowance(body);
      return response;
    } catch (error) {
      // gaEventTracker(
      //   AlphaActions.ReRequest33,
      //   JSON.stringify({
      //     error: error,
      //     info: body,
      //   })
      // );
      throw error;
      //
    }
  };

  const estimateBTCForLimitOrder = ({
    supply,
    amount,
  }: {
    supply: BigNumber;
    amount: BigNumber;
  }) => {
    try {
      const response = playerShareContract.getBuyPriceV2(supply, amount);

      return response;
    } catch (error) {
      return 0;
    }
  };

  const getSignatureFillLimitOrder = async (body: IBodyFillOrderLimit) => {
    try {
      const response = await playerShareContract.getSignatureFillLimitOrder(
        body
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const limitOrder = async (body: IBodyFillOrderLimit) => {
    try {
      const {
        signature,
        nonce,
      }: {
        signature: string;
        nonce: string;
      } = (await getSignatureFillLimitOrder(body)) as any;
      const _body: ILimitOrder = {
        ...body,
        signature,
        nonce,
      };
      const response = await tradeAPI.limitOrder(
        _body,
        gameWallet?.address as string
      );
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.OpenLimitOrder,
        error: JSON.stringify({
          error: error,
          trader_balance: balanceL2,
        }),
      });
      throw error;
    }
  };

  const limitOrderV2 = async (body: IBodyFillOrderLimitV2) => {
    try {
      const response = await playerShareContract.createFillOrder(body);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getFillOpenOrders = async (
    params: IPagingParams
  ): Promise<IResponseOrders> => {
    try {
      const response: IResponseOrders = await tradeAPI.openLimitOrders({
        ...params,
        address: gameWallet?.address as string,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const cancelFillOrder = async ({
    nonce,
  }: {
    nonce: string;
  }): Promise<any> => {
    try {
      const response = await playerShareContract.cancelFillOrder({ nonce });
      scanTrxAlpha(response.hash);
      return response;
    } catch (error) {
      errorLogger.report({
        address: gameWallet?.address,
        action: AlphaActions.CancelLimitOrder,
        error: JSON.stringify({
          error: error,
          info: {
            nonce,
            address: gameWallet?.address,
          },
        }),
      });
      gaEventTracker(
        AlphaActions.CancelLimitOrder,
        JSON.stringify({
          error: error,
          info: {
            nonce,
            address: gameWallet?.address,
          },
        })
      );
      throw error;
    }
  };

  const alphaBuyOrSellFT = async (
    ft_trader_address: string,
    token_amount: number,
    type: string
  ) => {
    try {
      let tx: any = 0;

      const tcAmount = await getEstimatePriceFT(
        ft_trader_address,
        token_amount,
        type
      );

      if (type === TRADE_ACTION.BUY) {
        tx = await playerShareContract.FTBuyShares({
          token_amount,
          ft_trader_address,
          tc_amount: tcAmount.amount_after_fee.toString(),
        });
      } else {
        tx = await playerShareContract.FTSellShares({
          token_amount,
          ft_trader_address,
        });
      }
      await tx.wait();
      gaEventTracker(
        TRADE_ACTION.BUY
          ? AlphaActions.DeskTopBuyNBC
          : AlphaActions.DeskTopSellNBC,
        JSON.stringify({
          token_address: ft_trader_address,
          trader: gameWallet?.address,
          token_amount,
        })
      );
      return tx;
    } catch (error) {
      console.log('error', error);

      gaEventTracker(
        AlphaActions.TradeKey,
        JSON.stringify({
          error: error,
          info: {
            ft_trader_address,
            trader: gameWallet?.address,
            token_amount,
            type,
          },
        })
      );
      throw error;
    }
  };

  const values = useMemo(() => {
    return {
      userProfile,
      userLocation,
      getTokenDetail,
      getEstimatePriceTC,
      alphaBuyOrSell,
      scanTrxAlpha,
      getTradeKeys,
      estimateTCMultiKeys,
      buySweetFloorKeys,
      issueKeyByTwitter,
      estimateTCBeforeMint,
      getTopPlaceholdersConverted,
      requestTrade33Keys,
      trade33Keys,
      cancelTrade33Keys,
      rejectTrade33Keys,
      reRequestTrade33Keys,
      checkAEnoughBalanceAndAllowance,
      has33Requested,
      estimateBTCForLimitOrder,
      getFillOpenOrders,
      limitOrder,
      cancelFillOrder,
      limitOrderV2,
      updateCreateFee,
      canTradeFT,
      getEstimatePriceFT,
      alphaBuyOrSellFT,
      tokenDetail,
      eth2USDT,
      estimateBothSide,
      isUserFT,
      // setCanTradeFT,
    };
  }, [
    JSON.stringify(userProfile),
    getTokenDetail,
    getEstimatePriceTC,
    alphaBuyOrSell,
    scanTrxAlpha,
    getTradeKeys,
    estimateTCMultiKeys,
    buySweetFloorKeys,
    issueKeyByTwitter,
    estimateTCBeforeMint,
    getTopPlaceholdersConverted,
    requestTrade33Keys,
    trade33Keys,
    cancelTrade33Keys,
    rejectTrade33Keys,
    reRequestTrade33Keys,
    checkAEnoughBalanceAndAllowance,
    has33Requested,
    estimateBTCForLimitOrder,
    getFillOpenOrders,
    limitOrder,
    cancelFillOrder,
    limitOrderV2,
    updateCreateFee,
    canTradeFT,
    getEstimatePriceFT,
    alphaBuyOrSellFT,
    tokenDetail,
    eth2USDT,
    estimateBothSide,
    isUserFT,
    userLocation,
    // setCanTradeFT,
  ]);

  return (
    <TradeKeyContext.Provider value={values}>
      {children}
    </TradeKeyContext.Provider>
  );
};

export default TradeKeyProvider;
