import px2rem from '@/utils/px2rem';
import React from 'react';
import styled from 'styled-components';
import TableCell from './Page.TableCell';
import { useWindowSize } from '@trustless-computer/dapp-core';

const Container = styled.div`
  border-top-right-radius: ${px2rem(24)};
  border-top-left-radius: ${px2rem(24)};
  max-height: 90px;
  /* background-color: slategrey; */
  background-color: transparent;
  display: grid;
  overflow: hidden;

  grid-template-columns: 3fr 1.2fr;
  grid-gap: 1.2px;

  div {
    color: #898989;
  }
`;

type Props = {
  dataHeader: any[];
};

const HistoryTableHeader = (props: Props) => {
  const { dataHeader = [] } = props;
  const { mobileScreen } = useWindowSize();

  return (
    <Container>
      <TableCell
        key={`${'Address_Header'}`}
        text={'Address'}
        style={{
          justifyContent: 'flex-start',
        }}
      />
      <TableCell
        key={`${'Balance_Header'}`}
        text={'Balance'}
        style={{
          justifyContent: 'flex-end',
        }}
      />
    </Container>
  );
};

export default React.memo(HistoryTableHeader);
