import React from 'react';
import s from './style.module.scss';
import IcEmpty from '@/components/ListTable/EmptyList/IcEmpty';

const EmptySearch = () => {
  return (
    <div className={s.wrapEmtpy}>
      <div className={s.emptyIcon}>
        <IcEmpty />
      </div>
      Your filters produced no results.
      <br />
      Try adjusting or clearing your filters to display better results.
    </div>
  );
};

export default EmptySearch;
