import { compose } from '@reduxjs/toolkit';
import { FC } from 'react';

import enhanFetchData from './Page.enhanFetchData';
import enhanSort from './Page.enhanSort';
import enhanLastItem from './Page.enhanLastItem';
import { PageProps } from './Page.types';

const enhance = (WrappedComponent: FC<PageProps>) => (props: PageProps) => {
  return <WrappedComponent {...{ ...props }} />;
};

export default compose<FC>(enhanFetchData, enhanSort, enhanLastItem, enhance);
