import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { unFollow, follow } from '@/services/follow';
import { WalletContext } from '@/contexts/wallet-context';
import s from './style.module.scss';
import { useCheckFollowed } from '@/hooks/useCheckFollowed';
import { IconFollow } from '../Icon/IconFollow';
import { IconUnfollow } from '../Icon/IconUnfollow';

export function ButtonFollow({
  twitter_id,
  className = '',
}: {
  twitter_id: string;
  className?: string;
}) {
  const { addressL2 } = useContext(WalletContext);
  const refTarget = useRef(null);
  const [isFollowed, setIsFollowed] = useState<boolean | undefined>(undefined);
  const [isSending, setIsSending] = useState<boolean>(false);

  const { isFollowed: isFollowedCheck, isChecked } = useCheckFollowed({
    refContent: refTarget,
    to_twitter_id: twitter_id,
    address: addressL2,
  });

  useEffect(() => {
    setIsFollowed(!!isFollowedCheck);
  }, [isFollowedCheck]);

  const callAction = useCallback(async () => {
    if (!addressL2) return;
    setIsSending(true);
    if (!isFollowed) {
      await follow({ address: addressL2, to_twitter_id: twitter_id });
      setIsFollowed(true);
    } else {
      await unFollow({ address: addressL2, to_twitter_id: twitter_id });
      setIsFollowed(false);
    }
    setIsSending(false);
  }, [isFollowed, addressL2, twitter_id]);
  if (!isChecked) return <div ref={refTarget}></div>;

  return (
    <>
      <div
        ref={refTarget}
        className={`${s.btnFollow} ${className}`}
        onClick={e => {
          e.stopPropagation();
          // if (isChecked) return;
          callAction();
        }}
      >
        <>
          {!isFollowed && (
            <>
              <IconFollow />
              <span>{isSending ? 'Processing' : 'Follow'}</span>
            </>
          )}
          {isFollowed && (
            <>
              <IconUnfollow />
              <span>{isSending ? 'Processing' : 'Unfollow'}</span>
            </>
          )}
        </>
      </div>
    </>
  );
}
