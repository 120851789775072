import React from 'react';

const IconAirDropActive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="white">
      <path d="M14.7991 21.8258C14.9375 21.8258 15.0702 21.7709 15.168 21.673C15.2659 21.5752 15.3208 21.4425 15.3208 21.3041V12.3438C14.9853 12.4745 14.6129 12.4745 14.2773 12.3438V21.3041C14.2773 21.4425 14.3323 21.5752 14.4302 21.673C14.528 21.7709 14.6607 21.8258 14.7991 21.8258Z" fill="white" />
      <path d="M14.7988 2C12.1706 2.0029 9.65084 3.04824 7.79241 4.90667C5.93398 6.76509 4.88864 9.28483 4.88574 11.913C4.88579 12.0291 4.92453 12.1418 4.99583 12.2334L11.1466 20.1445C11.4236 19.8934 11.7819 19.7505 12.1557 19.7423L5.94696 11.7597C6.05339 10.9697 6.93461 10.3478 8.01618 10.3478C8.36674 10.3353 8.71535 10.4051 9.03403 10.5517C9.35271 10.6983 9.63257 10.9176 9.85113 11.192C9.89934 11.26 9.96315 11.3155 10.0372 11.3539C10.1113 11.3922 10.1935 11.4121 10.2769 11.4121C10.3603 11.4121 10.4424 11.3922 10.5165 11.3539C10.5906 11.3155 10.6544 11.26 10.7026 11.192C10.9214 10.9178 11.2014 10.6986 11.5201 10.552C11.8389 10.4054 12.1875 10.3355 12.5381 10.3478C12.8886 10.3356 13.2372 10.4056 13.5558 10.5521C13.8745 10.6985 14.1546 10.9175 14.3736 11.1915C14.4241 11.256 14.4886 11.3082 14.5623 11.3441C14.636 11.38 14.7168 11.3986 14.7988 11.3986C14.8807 11.3986 14.9616 11.38 15.0353 11.3441C15.109 11.3082 15.1735 11.256 15.224 11.1915C15.443 10.9175 15.723 10.6985 16.0417 10.5521C16.3604 10.4056 16.7089 10.3356 17.0595 10.3478C17.4101 10.3356 17.7587 10.4055 18.0775 10.552C18.3963 10.6985 18.6764 10.9175 18.8955 11.1915C18.946 11.256 19.0106 11.3082 19.0843 11.3441C19.1581 11.38 19.239 11.3986 19.321 11.3986C19.4029 11.3986 19.4839 11.38 19.5576 11.3441C19.6313 11.3082 19.6959 11.256 19.7464 11.1915C19.9651 10.9172 20.2449 10.698 20.5636 10.5515C20.8823 10.405 21.2309 10.3352 21.5814 10.3478C22.663 10.3478 23.5442 10.9697 23.648 11.7607L17.4393 19.7433C17.8139 19.7507 18.1732 19.8932 18.451 20.1445L24.6017 12.2334C24.673 12.1418 24.7118 12.0291 24.7118 11.913C24.7089 9.28483 23.6636 6.76509 21.8052 4.90667C19.9467 3.04824 17.427 2.0029 14.7988 2Z" fill="white" />
      <path d="M17.4071 20.7822H16.3636V21.304C16.3636 21.7191 16.1987 22.1172 15.9052 22.4107C15.6116 22.7043 15.2135 22.8692 14.7984 22.8692C14.3833 22.8692 13.9852 22.7043 13.6916 22.4107C13.3981 22.1172 13.2332 21.7191 13.2332 21.304V20.7822H12.1897C12.0513 20.7822 11.9186 20.8372 11.8208 20.935C11.7229 21.0329 11.668 21.1656 11.668 21.304V23.9127C11.668 24.4662 11.8878 24.997 12.2792 25.3884C12.6706 25.7797 13.2014 25.9996 13.7549 25.9996H15.8419C16.3954 25.9996 16.9262 25.7797 17.3176 25.3884C17.709 24.997 17.9288 24.4662 17.9288 23.9127V21.304C17.9288 21.1656 17.8739 21.0329 17.776 20.935C17.6782 20.8372 17.5455 20.7822 17.4071 20.7822Z" fill="white" />
    </svg>
  );
};

export default IconAirDropActive;
