import {
  ChatCommandEnum,
  EmojiType,
  MessageContent,
  PromptEncapsulationEnum,
} from '@/modules/AlphaPWA/DirectMessage/types';
import { parseEmojiToUnicode } from '@/modules/AlphaPWA/DirectMessage/emoji.helpers';
import { CHAT_COMMANDS, DEFAULT_GET_ROOM_LIMIT, LOCAL_LAST_SEEN_STORAGE_KEY } from '@/modules/AlphaPWA/DirectMessage/constants';
import { EMOJI_STICKERS, NameSticker } from '../Home/PostItem/Emoji';
import moment from 'moment';
import { SYMBOL_OPTIONS } from './Chat/Input/ChartSelector';

export interface IQuillEmoji {
  name: string;
  unicode: string;
  shortname: string;
  code_decimal: string;
  category: string;
  emoji_order: string;
}
export const convertConfigEmojiToEditorEmoji = (
  emoji: EmojiType
): IQuillEmoji => {
  return {
    name: emoji.id,
    unicode: emoji.unified,
    shortname: emoji.shortcodes,
    code_decimal: parseEmojiToUnicode(emoji.native, true, false),
    category: 'p',
    emoji_order: '7',
  };
};

export const getStickerIndex = (sticker: string): string => {
  if (!isNaN(Number(sticker))) {
    const index = EMOJI_STICKERS.findIndex(s => s.type === Number(sticker));
    return `${index}`;
  }

  const stickerIndex = NameSticker[sticker as keyof typeof NameSticker];
  if (stickerIndex === undefined) {
    throw new Error('Invalid sticker');
  }
  const index = EMOJI_STICKERS.findIndex(s => s.type === stickerIndex);

  return `${index}`;
};


export const getMessage = (raw: string): string => {
  try {
    const content = JSON.parse(raw);
    if (content.type === 'STICKER') {
      return `${NameSticker[content.plain as keyof typeof NameSticker]}`;
    }

    if (checkMessageIsCommand(ChatCommandEnum.chart, content.plain)) {
      const symbol = content.plain.split(' ')[1];
      const interval = content.plain.split(' ')[2];

      if (SYMBOL_OPTIONS.includes(symbol) && !!interval) {
        const promptEncapsulation = getMatchEncapsulatePrompt(content.plain);
        return (
          content.rawText.replace(
            `${promptEncapsulation}${CHAT_COMMANDS[ChatCommandEnum.chart][0]}`,
            `${promptEncapsulation}${CHAT_COMMANDS[ChatCommandEnum.chart][1]}`
          ) ||
          content.plain.replace(
            `${promptEncapsulation}${CHAT_COMMANDS[ChatCommandEnum.chart][0]}`,
            `${promptEncapsulation}${CHAT_COMMANDS[ChatCommandEnum.chart][1]}`
          )
        );
      }
    }

    return content.rawText || content.plain;
  } catch (e) {
    return raw;
  }
};
export const getMessageObject = (raw: string): MessageContent => {
  try {
    return JSON.parse(raw);
  } catch (e) {
    return {
      type: 'TEXT',
      rawText: raw,
      plain: raw,
    };
  }
};

export const stringCompare = (a: any, b: any) => {
  return `${a}`.toLowerCase() === `${b}`.toLowerCase();
};

export const tryParseStringObj = <T>(str: string): T | string => {
  try {
    return JSON.parse(str) as T;
  } catch (e) {
    return str;
  }
};


export const createLocalTime = () => {
  const finalTime = moment().add(-1, 'seconds').toISOString();
  return finalTime;
};

export const getLocalLastSeens = () => {
  try {
    const lastSeens = localStorage.getItem(LOCAL_LAST_SEEN_STORAGE_KEY);
    return lastSeens ? JSON.parse(lastSeens) : {};
  } catch (e) {
    return {};
  }
};

export const getLocalLastSeenByRoomId = (id: string) => {
  try {
    const lastSeens = getLocalLastSeens();
    return lastSeens[id];
  } catch (e) {
    //
  }
};

export const updateLocalLastSeens = (id: string, time: string) => {
  try {
    const lastSeens = getLocalLastSeens();

    if (lastSeens[id]) {
      if (moment(time).isAfter(lastSeens[id])) {
        lastSeens[id] = time;
        localStorage.setItem(
          LOCAL_LAST_SEEN_STORAGE_KEY,
          JSON.stringify(lastSeens)
        );
      }
    } else {
      lastSeens[id] = time;
      localStorage.setItem(
        LOCAL_LAST_SEEN_STORAGE_KEY,
        JSON.stringify(lastSeens)
      );
    }
  } catch (e) {
    //
  }
};

export const getMatchEncapsulatePrompt = (str: string) => {
  if (str.startsWith(PromptEncapsulationEnum.public)) {
    return PromptEncapsulationEnum.public;
  } else if (str.startsWith(PromptEncapsulationEnum.private)) {
    return PromptEncapsulationEnum.private;
  }
};


export const checkMessageIsCommand = (
  command: ChatCommandEnum,
  str: string
) => {
  const promptEncapsulation = getMatchEncapsulatePrompt(str);
  if (promptEncapsulation) {
    const strCommands = CHAT_COMMANDS[command];
    return strCommands.some(cmd =>
      str.startsWith(`${promptEncapsulation}${cmd}`)
    );
  }
};
