import React from 'react';

const SearchSvg = ({ color }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.75009 13.7501C9.4067 13.7501 10.0569 13.6208 10.6635 13.3695C11.2701 13.1182 11.8213 12.7499 12.2856 12.2856C12.7499 11.8213 13.1182 11.2701 13.3695 10.6635C13.6208 10.0569 13.7501 9.4067 13.7501 8.75009C13.7501 8.09348 13.6208 7.4433 13.3695 6.83668C13.1182 6.23005 12.7499 5.67885 12.2856 5.21456C11.8213 4.75027 11.2701 4.38197 10.6635 4.1307C10.0569 3.87942 9.4067 3.75009 8.75009 3.75009C7.42401 3.75009 6.15224 4.27688 5.21456 5.21456C4.27688 6.15224 3.75009 7.42401 3.75009 8.75009C3.75009 10.0762 4.27688 11.3479 5.21456 12.2856C6.15224 13.2233 7.42401 13.7501 8.75009 13.7501ZM14.0168 12.8384L17.0001 15.8218C17.0796 15.8987 17.143 15.9907 17.1866 16.0924C17.2302 16.1941 17.2532 16.3035 17.254 16.4141C17.2549 16.5248 17.2338 16.6345 17.1918 16.7369C17.1498 16.8392 17.0879 16.9322 17.0096 17.0104C16.9313 17.0886 16.8382 17.1504 16.7357 17.1922C16.6333 17.2341 16.5236 17.2551 16.4129 17.254C16.3023 17.253 16.1929 17.2299 16.0913 17.1862C15.9897 17.1425 15.8977 17.0789 15.8209 16.9993L12.8376 14.0159C11.498 15.0558 9.81242 15.5461 8.12406 15.3869C6.43569 15.2278 4.87143 14.4312 3.74972 13.1593C2.62801 11.8874 2.03316 10.2359 2.08628 8.54086C2.1394 6.84585 2.83648 5.23478 4.03563 4.03563C5.23478 2.83648 6.84585 2.1394 8.54086 2.08628C10.2359 2.03316 11.8874 2.62801 13.1593 3.74972C14.4312 4.87143 15.2278 6.43569 15.3869 8.12406C15.5461 9.81242 15.0558 11.498 14.0159 12.8376L14.0168 12.8384Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default SearchSvg;
