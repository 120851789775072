const KeyIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_16278_16385)">
            <path d="M3.82375 16.5344L6.97812 13.38L8.98437 15.3869C9.10197 15.5041 9.18036 15.6549 9.20878 15.8185C9.23719 15.9821 9.21423 16.1505 9.14305 16.3006C9.07187 16.4506 8.95597 16.5749 8.81127 16.6564C8.66657 16.7379 8.50019 16.7725 8.335 16.7556L7.65999 16.6869C7.58096 16.6789 7.50113 16.6883 7.42613 16.7145C7.35114 16.7407 7.2828 16.7831 7.22595 16.8385C7.16909 16.894 7.1251 16.9613 7.09708 17.0356C7.06905 17.11 7.05767 17.1895 7.06375 17.2688L7.11624 17.9388C7.12822 18.0934 7.09254 18.2479 7.01399 18.3817C6.93544 18.5154 6.8178 18.6218 6.67692 18.6866C6.53604 18.7515 6.37867 18.7716 6.22602 18.7442C6.07337 18.7169 5.93275 18.6434 5.82312 18.5338L3.82375 16.5344Z" fill="url(#paint0_linear_16278_16385)"/>
        </g>
        <g filter="url(#filter1_i_16278_16385)">
            <path d="M10.2482 7.6075L12.3775 9.73625L4.29067 17.8225C4.00695 18.0982 3.6261 18.2512 3.23048 18.2483C2.83486 18.2455 2.45626 18.087 2.17655 17.8072C1.89684 17.5274 1.73849 17.1488 1.73575 16.7532C1.73302 16.3576 1.8861 15.9768 2.16192 15.6931L10.2482 7.6075Z" fill="url(#paint1_linear_16278_16385)"/>
        </g>
        <g filter="url(#filter2_i_16278_16385)">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6763 11.25C14.335 11.2574 14.9887 11.134 15.5994 10.887C16.2101 10.6401 16.7657 10.2744 17.2341 9.81119C17.7026 9.348 18.0744 8.79648 18.3282 8.18857C18.582 7.58066 18.7127 6.92845 18.7127 6.26969C18.7127 5.61093 18.582 4.95871 18.3282 4.35081C18.0744 3.7429 17.7026 3.19138 17.2341 2.72818C16.7657 2.26499 16.2101 1.89931 15.5994 1.65234C14.9887 1.40536 14.335 1.282 13.6763 1.28938C12.3651 1.30406 11.1126 1.83524 10.1906 2.76764C9.26858 3.70003 8.75146 4.95841 8.75146 6.26969C8.75146 7.58097 9.26858 8.83935 10.1906 9.77174C11.1126 10.7041 12.3651 11.2353 13.6763 11.25ZM13.6763 8.75C14.0053 8.75522 14.3321 8.69493 14.6376 8.57263C14.9431 8.45032 15.2212 8.26846 15.4558 8.03762C15.6903 7.80678 15.8765 7.53159 16.0037 7.22807C16.1308 6.92455 16.1963 6.59876 16.1963 6.26969C16.1963 5.94061 16.1308 5.61483 16.0037 5.31131C15.8765 5.00778 15.6903 4.73259 15.4558 4.50176C15.2212 4.27092 14.9431 4.08905 14.6376 3.96675C14.3321 3.84445 14.0053 3.78415 13.6763 3.78938C13.0253 3.79971 12.4045 4.06557 11.9477 4.5296C11.491 4.99362 11.235 5.6186 11.235 6.26969C11.235 6.92077 11.491 7.54575 11.9477 8.00978C12.4045 8.4738 13.0253 8.73967 13.6763 8.75Z" fill="url(#paint2_linear_16278_16385)"/>
        </g>
        <path d="M10.687 11.2882L8.63769 9.2389C8.45122 9.05242 8.14888 9.05242 7.96241 9.2389C7.77593 9.42537 7.77593 9.72771 7.9624 9.91418L10.0117 11.9635C10.1982 12.1499 10.5005 12.1499 10.687 11.9635C10.8735 11.777 10.8735 11.4747 10.687 11.2882Z" fill="url(#paint3_linear_16278_16385)"/>
        <path d="M10.687 11.2882L8.63769 9.2389C8.45122 9.05242 8.14888 9.05242 7.96241 9.2389C7.77593 9.42537 7.77593 9.72771 7.9624 9.91418L10.0117 11.9635C10.1982 12.1499 10.5005 12.1499 10.687 11.9635C10.8735 11.777 10.8735 11.4747 10.687 11.2882Z" fill="url(#paint4_radial_16278_16385)"/>
        <path d="M10.687 11.2882L8.63769 9.2389C8.45122 9.05242 8.14888 9.05242 7.96241 9.2389C7.77593 9.42537 7.77593 9.72771 7.9624 9.91418L10.0117 11.9635C10.1982 12.1499 10.5005 12.1499 10.687 11.9635C10.8735 11.777 10.8735 11.4747 10.687 11.2882Z" fill="url(#paint5_linear_16278_16385)"/>
        <path d="M11.3624 10.6136L9.31311 8.56434C9.12663 8.37786 8.8243 8.37786 8.63782 8.56434C8.45135 8.75081 8.45135 9.05315 8.63782 9.23962L10.6871 11.2889C10.8736 11.4754 11.1759 11.4754 11.3624 11.2889C11.5489 11.1024 11.5489 10.8001 11.3624 10.6136Z" fill="url(#paint6_linear_16278_16385)"/>
        <path d="M11.3624 10.6136L9.31311 8.56434C9.12663 8.37786 8.8243 8.37786 8.63782 8.56434C8.45135 8.75081 8.45135 9.05315 8.63782 9.23962L10.6871 11.2889C10.8736 11.4754 11.1759 11.4754 11.3624 11.2889C11.5489 11.1024 11.5489 10.8001 11.3624 10.6136Z" fill="url(#paint7_radial_16278_16385)"/>
        <path d="M11.3624 10.6136L9.31311 8.56434C9.12663 8.37786 8.8243 8.37786 8.63782 8.56434C8.45135 8.75081 8.45135 9.05315 8.63782 9.23962L10.6871 11.2889C10.8736 11.4754 11.1759 11.4754 11.3624 11.2889C11.5489 11.1024 11.5489 10.8001 11.3624 10.6136Z" fill="url(#paint8_linear_16278_16385)"/>
        <g filter="url(#filter3_f_16278_16385)">
            <path d="M13.9431 9.94563C16.1209 9.94563 17.8863 8.18023 17.8863 6.0025C17.8863 3.82477 16.1209 2.05938 13.9431 2.05938C11.7654 2.05938 10 3.82477 10 6.0025C10 8.18023 11.7654 9.94563 13.9431 9.94563Z" stroke="url(#paint9_linear_16278_16385)" stroke-width="1.25"/>
            <path d="M13.9431 9.94563C16.1209 9.94563 17.8863 8.18023 17.8863 6.0025C17.8863 3.82477 16.1209 2.05938 13.9431 2.05938C11.7654 2.05938 10 3.82477 10 6.0025C10 8.18023 11.7654 9.94563 13.9431 9.94563Z" stroke="url(#paint10_linear_16278_16385)" stroke-width="1.25"/>
        </g>
        <defs>
            <filter id="filter0_i_16278_16385" x="3.82373" y="13.23" width="5.39685" height="5.52611" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.15"/>
                <feGaussianBlur stdDeviation="0.25"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.839216 0 0 0 0 0.592157 0 0 0 0 0.34902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_16278_16385"/>
            </filter>
            <filter id="filter1_i_16278_16385" x="1.73572" y="7.1075" width="10.6418" height="11.1409" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.5"/>
                <feGaussianBlur stdDeviation="0.75"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.85098 0 0 0 0 0.541176 0 0 0 0 0.34902 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_16278_16385"/>
            </filter>
            <filter id="filter2_i_16278_16385" x="8.75146" y="0.889062" width="9.9613" height="10.3613" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-0.4"/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.815686 0 0 0 0 0.505882 0 0 0 0 0.337255 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_16278_16385"/>
            </filter>
            <filter id="filter3_f_16278_16385" x="8.125" y="0.184376" width="11.6362" height="11.6362" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="0.625" result="effect1_foregroundBlur_16278_16385"/>
            </filter>
            <linearGradient id="paint0_linear_16278_16385" x1="7.27" y1="17.4219" x2="6.02" y2="16.0681" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F4B954"/>
                <stop offset="0.908" stopColor="#ECA84F"/>
            </linearGradient>
            <linearGradient id="paint1_linear_16278_16385" x1="5.02379" y1="13.1444" x2="7.04942" y2="15.1175" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F7C250"/>
                <stop offset="1" stopColor="#F8AB54"/>
            </linearGradient>
            <linearGradient id="paint2_linear_16278_16385" x1="13.6763" y1="1.75813" x2="13.6763" y2="11.25" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F8CF4D"/>
                <stop offset="1" stopColor="#ED9953"/>
            </linearGradient>
            <linearGradient id="paint3_linear_16278_16385" x1="8.27442" y1="9.64018" x2="10.1292" y2="11.5348" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F6BF4E"/>
                <stop offset="1" stopColor="#EA994D"/>
            </linearGradient>
            <radialGradient id="paint4_radial_16278_16385" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.66079 10.6014) rotate(137.756) scale(0.204468 1.18439)">
                <stop stopColor="#FCBC66"/>
                <stop offset="1" stopColor="#FCBC66" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint5_linear_16278_16385" x1="9.03588" y1="11.1733" x2="9.32447" y2="10.8803" gradientUnits="userSpaceOnUse">
                <stop offset="0.186" stopColor="#E29226"/>
                <stop offset="1" stopColor="#EC9B4F" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint6_linear_16278_16385" x1="8.94939" y1="8.96518" x2="10.8051" y2="10.8598" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F6BF4E"/>
                <stop offset="1" stopColor="#EA994D"/>
            </linearGradient>
            <radialGradient id="paint7_radial_16278_16385" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.3362 9.92596) rotate(137.756) scale(0.204468 1.18439)">
                <stop stopColor="#FBB865"/>
                <stop offset="1" stopColor="#FBB865" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint8_linear_16278_16385" x1="9.7113" y1="10.4987" x2="10.0003" y2="10.2053" gradientUnits="userSpaceOnUse">
                <stop offset="0.186" stopColor="#E29226"/>
                <stop offset="1" stopColor="#E29226" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint9_linear_16278_16385" x1="17.075" y1="2.68625" x2="13.4175" y2="5.78688" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFDD67"/>
                <stop offset="1" stopColor="#FFDD67" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint10_linear_16278_16385" x1="11.02" y1="8.75" x2="11.8794" y2="7.38313" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FEB765"/>
                <stop offset="1" stopColor="#FEB765" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
  )
}

export default KeyIcon
