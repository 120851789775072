import createAxiosInstance from '@/services/http-client';
import { isProduction } from '@/utils/commons';
import {
  IEstimateWithdrawBTC,
  IEstimateWithdrawETH,
} from '@/services/interfaces/token-bridge';

const API_URL = isProduction()
  ? 'https://api.trustlessbridge.io'
  : 'https://api-dev.trustlessbridge.io';

export const axiosInstance = createAxiosInstance({ baseURL: API_URL });

export const estimateWithdrawBTCL2 = (params: IEstimateWithdrawBTC) => {
  return axiosInstance.post(`${API_URL}/api/estimate-withdraw-fee`, {
    isBridgeLayer: false,
    ...params,
  });
};

export const estimateWithdrawETHL2 = (params: IEstimateWithdrawETH) => {
  return axiosInstance.post(`${API_URL}/api/estimate-withdraw-fee`, {
    isBridgeLayer: false,
    ...params,
  });
};

const tokenBridge = {
  estimateWithdrawBTCL2,
};

export default tokenBridge;
