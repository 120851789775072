import React, { useContext } from 'react';
import s from './styles.module.scss';
import PinIcon from '../../CreatePost/Icon/PinIcon';
import { pinPost, unpinPost } from '@/services/alpha_tweet';
import { WalletContext } from '@/contexts/wallet-context';
import toast from 'react-hot-toast';

interface IProps {
  postId: string;
  isPinned: boolean;
  twitterId: string;
  fetchPinnedPosts: () => void;
}

const PinPostButton = (props: IProps) => {
  const { addressL2 } = useContext(WalletContext);
  const { postId, fetchPinnedPosts } = props;

  const handlePinPost = async () => {
    if (!addressL2) return;
    try {
      if (!props.isPinned) {
        await pinPost({
          tweetId: postId,
          twitterId: props.twitterId,
          network: 'nos',
          address: addressL2
        });
        // toast.success('Pinned successfully.');
      } else {
        await unpinPost({
          tweetId: postId,
          twitterId: props.twitterId,
          network: 'nos',
          address: addressL2
        });
        // toast.success('Unpinned successfully.');
      }
      if (fetchPinnedPosts !== undefined) {
        fetchPinnedPosts();
      }
    } catch (error) {
      console.log('Error pin post: ', error);
      toast.error('Something went wrong.')
    }
  };

  return (
    <div
      className={s.pinPostBtn}
      onClick={e => {
        e.stopPropagation();
        handlePinPost();
      }}
    >
      <span className={s.text}>
        <PinIcon />
        {props.isPinned ? 'Unpin this post' : 'Pin to your profile'}
      </span>
    </div>
  );
}

export default PinPostButton;
