/* eslint-disable import/no-anonymous-default-export */
import connection from './connection/reducer';
import user from './user/reducer';
import wallets from './wallets/reducer';
import modal from '@/state/modal';
import common from '@/state/common';
import notification from '@/state/notification/reducer';

export default {
  user,
  wallets,
  connection,
  modal,
  common,
  notification,
};
