import React, {
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import s from './styles.module.scss';
import { CDN_URL_IMAGES } from '@/configs';
import cs from 'classnames';
import Roulette from '@/modules/AlphaPWA/Home/PostItem/WheelPost/Roulette';

let rotation = Math.floor(Math.random() * 360);

const MAX_ROTATION = Math.pow(2, 31);
const INCRE = 0.0001;
let LINEAR_TIME = 0;
let DIR = 1;
let STOPPING = 360;

type IProps = React.ComponentPropsWithRef<'div'> & {
  url?: string;
  users: any[];
  isStopped: boolean;
  isWheelSuccess: boolean;
  setIsStopped: React.Dispatch<React.SetStateAction<boolean>>;
};

const Wheel = React.forwardRef(
  (
    {
      url,
      users,
      setIsStopped,
      isStopped,
      isWheelSuccess,
      className,
      ...rest
    }: IProps,
    ref: any
  ) => {
    const [isSpinning, setSpinning] = useState(false);
    const rouletteRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<any>();

    // const startTimeRef = useRef<number>();
    const isStoppingRef = useRef(false);

    // const step = (timeStamp: number) => {
    const step = () => {
      // if (startTimeRef.current === undefined) {
      //   startTimeRef.current = timeStamp;
      // }

      LINEAR_TIME += INCRE * DIR;

      if (isStoppingRef.current) {
        // LINEAR_TIME += INCRE * DIR * 2;
        STOPPING -= 0.75;
        if (LINEAR_TIME < 0) {
          LINEAR_TIME = 0;
        }
      } else {
        // LINEAR_TIME += INCRE * DIR;
        if (LINEAR_TIME > 1) {
          DIR = -1;
        }
        if (LINEAR_TIME < 0) {
          DIR = 1;
        }
      }

      if (rouletteRef.current) {
        rotation += (isStoppingRef.current ? STOPPING : 720) * LINEAR_TIME;
        if (rotation > MAX_ROTATION) {
          rotation = Math.floor(Math.random() * 360);
        }
        rouletteRef.current.style.transform = `rotate(${rotation}deg)`;
      }
      animationRef.current = window.requestAnimationFrame(step);
    };

    const startSpin = useCallback(() => {
      setIsStopped(false);
      setSpinning(true);
      animationRef.current = window.requestAnimationFrame(step);
    }, []);

    const stopSpin = useCallback((target: number = 22.5) => {
      setSpinning(false);

      isStoppingRef.current = true;
      LINEAR_TIME = 4500 * INCRE + INCRE;
      DIR = -1;
      setTimeout(() => {
        setIsStopped(true);
        if (rouletteRef.current) {
          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
          }

          rouletteRef.current.style.transform = `rotate(${
            rotation + target + (360 - (rotation % 360))
          }deg)`;
        }
      }, 5000);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        startSpin,
        stopSpin,
      }),
      [startSpin, stopSpin]
    );

    const combineWheelUI = () => {
      return (
        <div className={s.roulette}>
          <img
            className={cs(s.rouletteRecipient, s.rouletteRecipientHidden)}
            src={`${CDN_URL_IMAGES}/lucky-wheel-roulette-anchor.png`}
            alt="lucky-wheel-roulette-anchor.png"
          />

          <img
            className={cs(s.rouletteRecipient, s.rouletteRecipientVisible)}
            style={{
              zIndex: 1,
            }}
            src={`${CDN_URL_IMAGES}/lucky-wheel-roulette-wrapper.png`}
            alt="lucky-wheel-roulette-wrapper.png"
          />
          <Roulette
            url={url}
            items={users}
            className={cs(s.rouletteRecipient, s.rouletteRecipientVisible)}
            style={{
              zIndex: 2,
              // transition: 'all',
            }}
            ref={rouletteRef as any}
            isSpinning={isSpinning}
            isWheelSuccess={isWheelSuccess}
          >
            <img
              src={`${CDN_URL_IMAGES}/lucky-wheel-roulette-wheeling.png`}
              alt="lucky-wheel-roulette-wheeling.png"
            />
          </Roulette>

          <img
            className={cs(s.rouletteRecipient, s.rouletteRecipientVisible)}
            style={{
              zIndex: 3,
            }}
            src={`${CDN_URL_IMAGES}/lucky-wheel-roulette-anchor.png`}
            alt="lucky-wheel-roulette-anchor.png"
          />
        </div>
      );
    };

    const wheelStyle = useMemo(() => {
      if (isSpinning && !isStopped) {
        return {};
      }
      return {
        // filter: 'blur(5px)',
      };
    }, [isSpinning, isStopped]);

    return (
      <>
        <div
          {...rest}
          ref={ref}
          className={cs(
            s.wheel,
            {
              [`${s.spinning}`]: isSpinning,
            },
            className
          )}
          style={wheelStyle}
        >
          {combineWheelUI()}
        </div>
      </>
    );
  }
);

export default memo(Wheel);
