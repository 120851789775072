/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useMemo, useState } from 'react';

export interface IRafflePostContext {
  isJoin: boolean;
  setIsJoin: (b: boolean) => void;
}

const initialValue: IRafflePostContext = {
  isJoin: false,
  setIsJoin: _b => null,
};

export const RafflePostContext =
  React.createContext<IRafflePostContext>(initialValue);

export const RafflePostProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [isJoin, setIsJoin] = useState(false);

  const contextValues = useMemo((): IRafflePostContext => {
    return {
      isJoin: isJoin,
      setIsJoin: setIsJoin,
    };
  }, [isJoin, setIsJoin]);

  return (
    <RafflePostContext.Provider value={contextValues}>
      {children}
    </RafflePostContext.Provider>
  );
};
