import { CDN_URL_ICONS } from '@/configs';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import QRCode from 'react-qr-code';
import s from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DownloadAppModal: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  const downloadLink = 'https://alpha.wtf/app';
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="24px 16px"
        maxW={'540px'}
        bg="#212121"
        textAlign={'center'}
      >
        <Flex justifyContent={'flex-end'}>
          <ModalCloseButton />
        </Flex>
        <ModalBody>
          <div className={s.container}>
            <img
              alt="uil_download"
              className={s.downloadBanner}
              src={`${CDN_URL_ICONS}/uil_download.png`}
            />
            <h2>
              Open the link in a phone browser to install the New Bitcoin City
              app.
            </h2>
            <a href={downloadLink}>
              <span>{downloadLink}</span>
            </a>

            <p className={s.or}>Or simply scan the QR code.</p>

            <QRCode className={s.qrCode} size={132} value={downloadLink} />

            <div className={s.availContainer}>
              <p>Available on both</p>
              <div className={s.flatform}>
                <img alt="uil_ios" src={`${CDN_URL_ICONS}/uil_ios.svg`} />
                <p>iOS</p>
              </div>
              <p>and</p>
              <div className={s.flatform}>
                <img
                  alt="uil_android"
                  src={`${CDN_URL_ICONS}/uil_android.svg`}
                />
                <p>Android</p>
              </div>
            </div>

            <p className={s.note}>
              NBC uses progressive web app (PWA) technology that combines the
              best features of traditional websites and platform-specific apps.
            </p>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DownloadAppModal;
