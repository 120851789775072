import React, { useEffect, useState } from 'react';

const ReactionGM = () => {
  return (
    <>
      <svg
        height="16"
        width="16"
        fill="none"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_433_102)">
          <path
            d="M15.6498 10.3304C15.1889 11.8444 14.2893 13.1876 13.065 14.1902C11.8406 15.1927 10.3466 15.8095 8.77168 15.9625C7.1968 16.1155 5.61192 15.7979 4.21756 15.0498C2.8232 14.3017 1.68202 13.1568 0.93838 11.7598C0.194739 10.3629 -0.11794 8.77668 0.0399006 7.20196C0.197741 5.62724 0.819008 4.13472 1.8251 2.91323C2.8312 1.69174 4.17692 0.796164 5.692 0.339808C7.20708 -0.116546 8.82344 -0.113175 10.3366 0.349495C11.3414 0.65488 12.276 1.15549 13.0871 1.82267C13.8982 2.48984 14.5696 3.31048 15.0632 4.2376C15.5567 5.16472 15.8626 6.18008 15.9632 7.2256C16.0639 8.27108 15.9574 9.32616 15.6498 10.3304Z"
            fill="black"
          />
          <path
            d="M14.3176 9.25342H13.2505V10.3833C13.2505 10.5276 13.3019 10.6569 13.4043 10.7593L13.4066 10.7617L13.4091 10.764C13.5147 10.862 13.6438 10.913 13.7879 10.913C13.9322 10.913 14.0615 10.8616 14.1639 10.7593C14.2662 10.6569 14.3176 10.5276 14.3176 10.3833V9.25342Z"
            fill="#FF57E4"
          />
          <path
            d="M10.001 9.25342L10.4952 10.475L10.4962 10.4774C10.5507 10.6069 10.6369 10.7136 10.752 10.7948L10.7569 10.7983L10.762 10.8014C10.8831 10.8754 11.0163 10.913 11.1581 10.913C11.2999 10.913 11.4323 10.8753 11.5504 10.7989L11.5525 10.7975C11.6733 10.717 11.7643 10.6097 11.82 10.4774L12.3152 9.25342H11.2443L11.1581 9.46844L11.0718 9.25342H10.001Z"
            fill="#FF57E4"
          />
          <path
            d="M7.99854 9.25342V10.3833C7.99854 10.5276 8.04992 10.6569 8.15227 10.7593C8.25463 10.8616 8.38389 10.913 8.52823 10.913C8.67141 10.913 8.7998 10.8624 8.90177 10.7617C9.00914 10.6608 9.06564 10.531 9.06564 10.3833V9.25342H7.99854Z"
            fill="#FF57E4"
          />
          <path
            d="M12.7408 5.52289L12.7422 5.51979C12.7962 5.39832 12.8778 5.29658 12.9846 5.21648L12.9888 5.21334C13.1057 5.13084 13.2374 5.0874 13.3796 5.0874H13.6341C13.8234 5.0874 13.9886 5.1597 14.1197 5.29685C14.2507 5.42868 14.318 5.59286 14.318 5.77909V7.01456H12.1426L12.7408 5.52289Z"
            fill="#FF57E4"
          />
          <path
            d="M10.174 7.01456L9.57572 5.52289L9.57435 5.51979C9.51941 5.39622 9.4338 5.29425 9.32208 5.21487C9.20868 5.13053 9.07845 5.0874 8.93699 5.0874H8.68246C8.49317 5.0874 8.32796 5.15966 8.19689 5.29681C8.06586 5.42868 7.99854 5.59286 7.99854 5.77909V7.01456H10.174Z"
            fill="#FF57E4"
          />
          <path
            d="M14.6779 9.25454H13.6107V7.01465H14.6779V9.25454Z"
            fill="#46FFF4"
          />
          <path
            d="M12.6753 9.25454H11.6045L12.5028 7.01465H13.5815L12.6753 9.25454Z"
            fill="#46FFF4"
          />
          <path
            d="M11.4321 9.25454H10.3612L9.45508 7.01465H10.5338L11.4321 9.25454Z"
            fill="#46FFF4"
          />
          <path
            d="M9.426 9.25454H8.35889V7.01465H9.426V9.25454Z"
            fill="#46FFF4"
          />
          <path
            d="M7.27612 8.98662V8.10804C7.27612 7.95732 7.22125 7.82327 7.11466 7.71668C7.00802 7.61004 6.87398 7.55518 6.72326 7.55518H5.20395C5.06819 7.55518 4.94714 7.60522 4.85113 7.70123C4.7551 7.79727 4.70508 7.91828 4.70508 8.05405C4.70508 8.18982 4.7551 8.31083 4.85113 8.40688C4.94714 8.50288 5.06819 8.55293 5.20395 8.55293H6.29381V8.98662H7.27612Z"
            fill="#46FFF4"
          />
          <path
            d="M2.14502 8.0075C2.14502 7.6537 2.19258 7.32186 2.28885 7.01318H3.42826C3.28652 7.28613 3.21216 7.61555 3.21216 8.0075C3.21216 8.37552 3.28598 8.70053 3.42989 8.98633H2.28226C2.19044 8.68092 2.14502 8.35429 2.14502 8.0075Z"
            fill="#46FFF4"
          />
          <path
            d="M1.89062 7.01455H3.02984C3.0374 6.99996 3.04516 6.98554 3.0531 6.97127C3.21635 6.68226 3.43777 6.46624 3.71903 6.31869C4.00386 6.16927 4.32645 6.09281 4.69076 6.09281C4.95801 6.09281 5.20244 6.1268 5.42527 6.19317C5.65089 6.26039 5.87873 6.36186 6.10889 6.49897L6.11467 6.50241L6.12067 6.50543C6.19702 6.54358 6.27892 6.56328 6.36434 6.56328C6.50255 6.56328 6.6309 6.5182 6.7276 6.41447C6.82184 6.31888 6.8709 6.19887 6.8709 6.0644C6.8709 5.97061 6.847 5.88141 6.7986 5.80074C6.75688 5.73123 6.70053 5.66856 6.62486 5.62811C6.33677 5.44746 6.03472 5.30603 5.71895 5.20429C5.3982 5.09926 5.02871 5.04883 4.61364 5.04883C4.08538 5.04883 3.59923 5.17171 3.15901 5.41932L3.15815 5.41981C2.72211 5.66818 2.37739 6.01909 2.12522 6.46861C2.02821 6.64153 1.9501 6.82361 1.89062 7.01455Z"
            fill="#FF57E4"
          />
          <path
            d="M14.3176 9.25454H13.2505V7.01465H14.3176V9.25454Z"
            fill="white"
          />
          <path
            d="M12.3155 9.25454H11.2446L12.143 7.01465H13.2216L12.3155 9.25454Z"
            fill="white"
          />
          <path
            d="M11.0722 9.25454H10.0014L9.09521 7.01465H10.1739L11.0722 9.25454Z"
            fill="white"
          />
          <path
            d="M9.06564 9.25454H7.99854V7.01465H9.06564V9.25454Z"
            fill="white"
          />
          <path
            d="M1.32812 7.01455H2.46734C2.4749 6.99996 2.48266 6.98554 2.4906 6.97127C2.65385 6.68226 2.87527 6.46624 3.15653 6.31869C3.44136 6.16927 3.76395 6.09281 4.12826 6.09281C4.39551 6.09281 4.63994 6.1268 4.86277 6.19317C5.08839 6.26039 5.31623 6.36186 5.54639 6.49897L5.55217 6.50241L5.55817 6.50543C5.63452 6.54358 5.71642 6.56328 5.80184 6.56328C5.94005 6.56328 6.0684 6.5182 6.1651 6.41447C6.25934 6.31888 6.3084 6.19887 6.3084 6.0644C6.3084 5.97061 6.2845 5.88141 6.2361 5.80074C6.19438 5.73123 6.13803 5.66856 6.06236 5.62811C5.77427 5.44746 5.47222 5.30603 5.15645 5.20429C4.8357 5.09926 4.46621 5.04883 4.05114 5.04883C3.52288 5.04883 3.03673 5.17171 2.59651 5.41932L2.59565 5.41981C2.15961 5.66818 1.81489 6.01909 1.56272 6.46861C1.46571 6.64153 1.3876 6.82361 1.32812 7.01455Z"
            fill="white"
          />
          <path
            d="M5.89876 8.98633H6.88106V9.85865C6.88106 9.99996 6.84359 10.1319 6.7677 10.2497C6.69823 10.3604 6.6031 10.4452 6.48519 10.5011C5.88637 10.8002 5.27046 10.9513 4.63922 10.9513C4.08217 10.9513 3.58019 10.8294 3.13832 10.5808L3.13682 10.58C2.69624 10.3267 2.35341 9.97557 2.11103 9.52938L2.11059 9.52854C2.01805 9.35608 1.94351 9.17527 1.88672 8.98633H3.03431C3.03695 8.99158 3.03962 8.99682 3.04231 9.00205C3.19553 9.28464 3.41267 9.50911 3.6971 9.6773C3.9836 9.83865 4.32638 9.92277 4.73178 9.92277C5.14838 9.92277 5.53661 9.83349 5.89876 9.65501V8.98633Z"
            fill="#FF57E4"
          />
          <path
            d="M5.33382 8.98633H6.31612V9.85865C6.31612 9.99996 6.27865 10.1319 6.20276 10.2497C6.13329 10.3604 6.03816 10.4452 5.92025 10.5011C5.32143 10.8002 4.70552 10.9513 4.07427 10.9513C3.51723 10.9513 3.01525 10.8294 2.57337 10.5808L2.57188 10.58C2.1313 10.3267 1.78847 9.97557 1.54609 9.52938L1.54565 9.52854C1.45311 9.35608 1.37857 9.17527 1.32178 8.98633H2.46937C2.47201 8.99158 2.47468 8.99682 2.47737 9.00205C2.63058 9.28464 2.84773 9.50911 3.13216 9.6773C3.41866 9.83865 3.76144 9.92277 4.16684 9.92277C4.58344 9.92277 4.97166 9.83349 5.33382 9.65501V8.98633Z"
            fill="white"
          />
          <path
            d="M6.87622 8.98662V8.10804C6.87622 7.95732 6.82135 7.82327 6.71476 7.71668C6.60812 7.61004 6.47407 7.55518 6.32335 7.55518H4.80405C4.66828 7.55518 4.54723 7.60522 4.45123 7.70123C4.3552 7.79727 4.30518 7.91828 4.30518 8.05405C4.30518 8.18982 4.3552 8.31083 4.45123 8.40688C4.54723 8.50288 4.66828 8.55293 4.80405 8.55293H5.89391V8.98662H6.87622Z"
            fill="white"
          />
          <path
            d="M1.74463 8.00799C1.74463 7.65419 1.79219 7.32235 1.88846 7.01367H3.02787C2.88613 7.28662 2.81177 7.61604 2.81177 8.00799C2.81177 8.376 2.88559 8.70101 3.0295 8.98682H1.88187C1.79005 8.68141 1.74463 8.35478 1.74463 8.00799Z"
            fill="white"
          />
          <path
            d="M13.9509 9.25342H12.8838V10.3833C12.8838 10.5276 12.9352 10.6569 13.0376 10.7593L13.0399 10.7617L13.0424 10.764C13.148 10.862 13.2771 10.913 13.4212 10.913C13.5655 10.913 13.6948 10.8616 13.7972 10.7593C13.8995 10.6569 13.9509 10.5276 13.9509 10.3833V9.25342Z"
            fill="white"
          />
          <path
            d="M9.63428 9.25342L10.1285 10.475L10.1295 10.4774C10.184 10.6069 10.2702 10.7136 10.3853 10.7948L10.3902 10.7983L10.3953 10.8014C10.5164 10.8754 10.6496 10.913 10.7914 10.913C10.9332 10.913 11.0656 10.8753 11.1837 10.7989L11.1858 10.7975C11.3066 10.717 11.3976 10.6097 11.4533 10.4774L11.9485 9.25342H10.8776L10.7914 9.46844L10.7051 9.25342H9.63428Z"
            fill="white"
          />
          <path
            d="M7.63184 9.25342V10.3833C7.63184 10.5276 7.68322 10.6569 7.78557 10.7593C7.88793 10.8616 8.0172 10.913 8.16153 10.913C8.30472 10.913 8.43311 10.8624 8.53508 10.7617C8.64244 10.6608 8.69895 10.531 8.69895 10.3833V9.25342H7.63184Z"
            fill="white"
          />
          <path
            d="M12.3741 5.52289L12.3755 5.51979C12.4295 5.39832 12.5111 5.29658 12.6179 5.21648L12.6221 5.21334C12.739 5.13084 12.8707 5.0874 13.0129 5.0874H13.2674C13.4567 5.0874 13.6219 5.1597 13.753 5.29685C13.884 5.42868 13.9513 5.59286 13.9513 5.77909V7.01456H11.7759L12.3741 5.52289Z"
            fill="white"
          />
          <path
            d="M9.80727 7.01456L9.20902 5.52289L9.20765 5.51979C9.15271 5.39622 9.0671 5.29425 8.95538 5.21487C8.84198 5.13053 8.71175 5.0874 8.57029 5.0874H8.31576C8.12647 5.0874 7.96126 5.15966 7.83019 5.29681C7.69917 5.42868 7.63184 5.59286 7.63184 5.77909V7.01456H9.80727Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_433_102">
            <rect height="16" width="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ReactionGM;
