const IcEmpty = () => {
  return (
    <svg width="119" height="123" viewBox="0 0 119 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M104.36 45.3347C106.472 45.3347 108.184 43.6209 108.184 41.5067C108.184 39.3926 106.472 37.6787 104.36 37.6787C102.247 37.6787 100.535 39.3926 100.535 41.5067C100.535 43.6209 102.247 45.3347 104.36 45.3347Z" fill="#EAEEF9"/>
        <path d="M2.76618 66.0359C4.15582 66.0359 5.28235 64.9083 5.28235 63.5175C5.28235 62.1266 4.15582 60.999 2.76618 60.999C1.37653 60.999 0.25 62.1266 0.25 63.5175C0.25 64.9083 1.37653 66.0359 2.76618 66.0359Z" fill="#EAEEF9"/>
        <path d="M14.3326 111.312C15.7222 111.312 16.8488 110.185 16.8488 108.794C16.8488 107.403 15.7222 106.275 14.3326 106.275C12.9429 106.275 11.8164 107.403 11.8164 108.794C11.8164 110.185 12.9429 111.312 14.3326 111.312Z" fill="#EAEEF9"/>
        <path d="M27.7822 111.312L63.0912 100.248V54.8867L27.7822 65.8654V111.312Z" fill="#B7BFCD"/>
        <path opacity="0.4" d="M98.4003 111.312L63.0913 100.248V54.8867L98.4003 65.8654V111.312Z" fill="#6D7C9A"/>
        <g opacity="0.13">
          <path d="M27.7822 111.312L63.0912 100.248V54.8867L27.7822 65.8654V111.312Z" fill="url(#paint0_linear_18193_7472)"/>
          <path d="M98.4002 111.312L63.0912 100.248V54.8867L98.4002 65.8654V111.312Z" fill="url(#paint1_linear_18193_7472)"/>
        </g>
        <path d="M63.0912 122.461L27.7822 111.397V66.0352L63.0912 77.099V122.461Z" fill="#EDF1F9"/>
        <path d="M63.0913 122.461L98.4003 111.397V66.0352L63.0913 77.099V122.461Z" fill="#DAE1ED"/>
        <path opacity="0.09" d="M55.0912 114.461L27.7822 111.397V66.0352L63.0912 77.099L55.0912 114.461Z" fill="url(#paint2_linear_18193_7472)"/>
        <path opacity="0.2" d="M71.0913 106.461L98.4003 111.397V66.0352L63.0913 77.099L71.0913 106.461Z" fill="url(#paint3_linear_18193_7472)"/>
        <path d="M63.0913 54.8865L46.4902 42.4609L10.5913 54.546L27.7823 65.8652L63.0913 54.8865Z" fill="#DAE1ED"/>
        <path d="M63.0913 54.8865L79.6924 42.4609L115.591 54.546L98.4003 65.8652L63.0913 54.8865Z" fill="#DAE1ED"/>
        <path d="M27.7823 66.0352L63.0913 77.099L45.479 88.333L10.5913 76.7586L27.7823 66.0352Z" fill="#EFF4FD"/>
        <path d="M98.4003 66.0352L63.0913 77.099L80.7037 88.333L115.591 76.7586L98.4003 66.0352Z" fill="#EDF1F9"/>
        <path d="M72.9428 11.3384C77.8894 24.7629 79.3638 27.7457 79.8477 40.9736C79.6616 43.9931 79.5462 47.2765 78.1227 49.9204C76.2215 53.9648 71.5715 56.7658 67.1782 56.8112C62.6522 56.892 58.2151 54.1209 56.218 49.8478C54.8129 47.2545 54.8576 43.7072 56.785 41.3527C58.8803 39.0945 62.5665 38.5315 65.2182 39.9424C68.1351 41.2824 69.8761 44.0686 70.521 47.0069C71.1658 49.9452 70.8825 53.1322 70.0954 56.0298C68.4491 63.0773 67.0856 63.4182 63.0483 75.35" stroke="#ABB5CC" stroke-width="2" strokeMiterlimit="10" stroke-dasharray="4 4"/>
        <path d="M78.9632 4.23203C78.9517 6.206 77.1148 7.41469 74.7852 6.7844C72.3325 6.36629 70.5965 5.93537 70.4848 4.17357C70.541 2.36678 72.5123 1.65963 74.8197 0.862479C77.5863 -0.236842 78.8067 2.30305 78.9632 4.23203Z" fill="#DAE2EB"/>
        <path d="M63.1139 10.2689C64.357 11.5485 66.9777 11.9216 68.2548 9.96704C69.6551 7.80026 70.8538 6.22502 69.566 4.7782C68.3229 3.49857 67.1132 4.36009 64.3466 5.45941C62.1289 6.59092 61.6581 8.86706 63.1139 10.2689Z" fill="#DAE2EB"/>
        <path d="M68.8496 2.1038C69.9809 1.62163 71.336 1.97537 71.9407 2.88846C72.1983 3.17782 72.5006 3.63436 72.5902 3.96872C73.553 6.21925 73.2503 8.4504 71.951 8.97756C70.5285 9.7169 68.5687 8.45008 67.8186 6.32173C67.6395 5.65301 67.5499 5.31865 67.4156 4.81711C67.2702 3.60185 67.7183 2.58596 68.8496 2.1038C69.0176 2.0588 68.8496 2.1038 68.8496 2.1038Z" fill="#989FB0"/>
        <path d="M16.8486 38.3316H14.6585V36.1416H12.7814V38.3316H10.5913V40.2714H12.7814V42.4614H14.6585V40.2714H16.8486V38.3316Z" fill="url(#paint4_linear_18193_7472)"/>
        <path d="M21.6596 93.2789H19.4696V91.0889H17.5924V93.2789H15.4023V95.2187H17.5924V97.4087H19.4696V95.2187H21.6596V93.2789Z" fill="url(#paint5_linear_18193_7472)"/>
        <path d="M117.397 93.4023L114.718 92.0494L116.071 89.3699L113.774 88.2103L112.421 90.8898L109.741 89.537L108.543 91.9103L111.223 93.2632L109.87 95.9427L112.167 97.1023L113.519 94.4228L116.199 95.7756L117.397 93.4023Z" fill="url(#paint6_linear_18193_7472)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_18193_7472" x1="51.945" y1="58.172" x2="47.1289" y2="78.2218" gradientUnits="userSpaceOnUse">
          <stop offset="0.00289017" stopColor="#606673" stop-opacity="0"/>
          <stop offset="1" stopColor="#373C47"/>
        </linearGradient>
        <linearGradient id="paint1_linear_18193_7472" x1="51.945" y1="58.172" x2="47.1289" y2="78.2218" gradientUnits="userSpaceOnUse">
          <stop offset="0.00289017" stopColor="#606673" stop-opacity="0"/>
          <stop offset="1" stopColor="#373C47"/>
        </linearGradient>
        <linearGradient id="paint2_linear_18193_7472" x1="35.6983" y1="97.0804" x2="44.1809" y2="73.1223" gradientUnits="userSpaceOnUse">
          <stop offset="0.00289017" stopColor="#6C80AA" stop-opacity="0"/>
          <stop offset="1" stopColor="#5D6A86"/>
        </linearGradient>
        <linearGradient id="paint3_linear_18193_7472" x1="82.1471" y1="103.625" x2="82.8142" y2="73.2223" gradientUnits="userSpaceOnUse">
          <stop offset="0.00289017" stopColor="#314F91" stop-opacity="0"/>
          <stop offset="1" stopColor="#324264"/>
        </linearGradient>
        <linearGradient id="paint4_linear_18193_7472" x1="13.7204" y1="36.3611" x2="13.7204" y2="39.9084" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC"/>
          <stop offset="1" stopColor="#969EAE"/>
        </linearGradient>
        <linearGradient id="paint5_linear_18193_7472" x1="18.5315" y1="91.3084" x2="18.5315" y2="94.8557" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC"/>
          <stop offset="1" stopColor="#969EAE"/>
        </linearGradient>
        <linearGradient id="paint6_linear_18193_7472" x1="114.787" y1="89.059" x2="112.596" y2="93.3991" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BACC"/>
          <stop offset="1" stopColor="#969EAE"/>
        </linearGradient>
      </defs>
    </svg>
  )
};

export default IcEmpty;