import errorLogger from '@/services/errorLogger';
import accountStorage from '@/utils/account.storage';
import Logger from '@/utils/persistor/Logger';

class Persistor {
  protected dbName: string;
  protected database?: IDBDatabase;

  constructor(db: string, callback?: (database: any) => void) {
    this.dbName = db;
    Logger.info(`Create ${db} database instance`);
    this.connect(callback).catch(() => {
      //
    });
  }

  protected sendErrorLog = (error: string, info: string) => {
    try {
      Logger.error(error, info);
      // const address = accountStorage.getAddress();
      // errorLogger.report({
      //   action: errorLogger.ERROLR_PERSISTOR_LOGGER_TYPE.CHAT_ROOM,
      //   address: address,
      //   error: error,
      //   info: info,
      // });
    } catch (e) {
      //
    }
  };

  protected connect = async (
    callback?: (database: any) => void
  ): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      const indexedDB =
        Object(window).indexedDB ||
        Object(window).mozIndexedDB ||
        Object(window).webkitIndexedDB ||
        Object(window).msIndexedDB;
      const request = indexedDB.open(this.dbName);
      request.onerror = (event: any): void => {
        reject(event);
        try {
          this.sendErrorLog(
            `Cannot connect index ${this.dbName}`,
            JSON.stringify(event)
          );
        } catch (_e) {
          //
        }
      };
      request.onsuccess = (event: any): void => {
        this.database = event.target.result;
        resolve(event.target.result);
      };
      request.onupgradeneeded = (event: any): void => {
        callback && callback(event.target.result);
      };
    });
  };

  private openConnect = async (): Promise<void> => {
    if (!this.database) {
      await this.connect();
    }
  };

  protected add = async (): Promise<void> => {
    await this.openConnect();
  };

  protected update = async (): Promise<void> => {
    await this.openConnect();
  };

  protected get = async (): Promise<void> => {
    await this.openConnect();
  };

  protected remove = async (): Promise<void> => {
    await this.openConnect();
  };

  protected clear = async (): Promise<void> => {
    await this.openConnect();
  };

  protected deleteDb = (): void => {
    try {
      const DBDeleteRequest = window.indexedDB.deleteDatabase(this.dbName);

      DBDeleteRequest.onerror = (): void => {
        //
      };
      DBDeleteRequest.onsuccess = (): void => {
        //
      };
    } catch (e) {
      //
    }
  };
}

export default Persistor;
