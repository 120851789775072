import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import NavBar from './NavBar';
import Header from './Header';
import DesktopHeader from './DesktopHeader';
import { detectClientOs, isWebApp } from '@/utils/client';
import cs from 'classnames';
import { ClientOs } from '@/enums/client';
import s from './styles.module.scss';
import RequiredBackupModal from '@/layouts/AlphaLayout/RequiredBackupModal';
import NotificationPermissionModal from './NotificationPermissionModal';
import accountStorage from '@/utils/account.storage';
import Referral from '@/modules/AlphaPWA/Referral';
import { initializeFCM } from '@/services/firebase/messaging';
import { isPermissionGranted } from '@/utils/notification';
import { WalletContext } from '@/contexts/wallet-context';
import { useAlphaTheme } from '@/hooks/useAlphaTheme';
import {
  useSocketEvent,
  useSocketProvider,
} from '@/providers/SocketProvider/hooks';
import { useAppDispatch } from '@/state/hooks';
import { updateUnreadNotification } from '@/state/notification/reducer';
import { ALPHA_LAYOUT_MAIN_ID } from '@/layouts/AlphaLayout/constants';
import { Keyboard } from '@/components/Keyboard';
import { isMobile } from '@/utils/animation';

const PWALayout: React.FC<
  {
    hideHeader?: boolean;
    hideNavBar?: boolean;
    noLayout?: boolean;
  } & PropsWithChildren
> = ({ children, hideHeader, hideNavBar, noLayout }): React.ReactElement => {
  const clientOs = detectClientOs();
  const { sendEmit } = useSocketProvider();
  const { addressL2, isAuthenticated } = useContext(WalletContext);
  const showedNotification = accountStorage.getNeedShowNotification();
  const newNoti = useSocketEvent<string>('SUBSCRIBE_NOTIFICATION_BY_WALLET');
  const dispatch = useAppDispatch();
  const isPWA = isWebApp();
  const needToRefresh = useRef(false);
  const timeoutIdRef = useRef<null | NodeJS.Timeout>(null);

  const registerNotification = async () => {
    if (!addressL2) {
      return;
    }

    try {
      sendEmit('SUBSCRIBE_ADDRESS', addressL2);
    } catch (err) {
      console.log('emitSocketEvent error', err);
    }
  };

  const checkIfDiscarded = () => {
    if ('wasDiscarded' in document) {
      if (document.wasDiscarded) {
        // The PWA was discarded from memory
        // console.log('PWA was discarded from memory');
        // Perform actions to reload the previous state or restore the app
        window.location.reload();
      }
    }
  };

  const handleVisibilityChange = () => {
    // The PWA is minimized
    if (document.visibilityState === 'hidden') {
      // Start a countdown
      timeoutIdRef.current = setTimeout(() => {
        // Hidden for over 5 mins, need to refresh
        needToRefresh.current = true;
      }, 300_000);
    }
    // The PWA is in the foreground
    else {
      // console.log('PWA is in the foreground');

      // Android --- so easy to handle
      if (clientOs === ClientOs.Android) {
        checkIfDiscarded();
      }
      // IOS --- hmm...
      else {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
        if (needToRefresh.current) {
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    if ('visibilityState' in document && isPWA) {
      // Handle page visibility change
      document.addEventListener('visibilitychange', handleVisibilityChange);
      // Initial check when the page loads
      checkIfDiscarded();
    }

    return () => {
      if ('visibilityState' in document && isPWA) {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      }
    };
  }, []);

  useEffect(() => {
    if (newNoti) {
      const notiObj = JSON.parse(window.atob(newNoti)) as any;
      dispatch(
        updateUnreadNotification({
          notifications: [notiObj],
        })
      );
    }
  }, [newNoti]);

  useEffect(() => {
    registerNotification();
  }, [addressL2]);

  useAlphaTheme('dark');

  useEffect(() => {
    if (isPermissionGranted() && addressL2) {
      accountStorage.setShowedNotification();
      initializeFCM(addressL2);
    }
  }, [addressL2]);

  return (
    <div className={s.fontWrapper}>
      <Keyboard>
        {hideHeader ? null : isMobile() ? <Header /> : <DesktopHeader />}
        {noLayout ? (
          <main
            id={ALPHA_LAYOUT_MAIN_ID}
            className={cs({
              [`${s.hideHeader}`]: hideHeader,
            })}
          >
            {children}
          </main>
        ) : (
          <main
            id={ALPHA_LAYOUT_MAIN_ID}
            className={cs(s.main, {
              [`${s.ios}`]: clientOs === ClientOs.Ios,
              [`${s.hideHeader}`]: hideHeader,
              [`${s.desktop}`]: !isMobile(),
            })}
          >
            {children}
          </main>
        )}
        {hideNavBar || !isMobile() ? null : <NavBar />}
        {isMobile() && <RequiredBackupModal />}
        {/* {isAuthenticated && !showedNotification && (
          <NotificationPermissionModal />
        )} */}
      </Keyboard>
      {/* <NotificationPermissionModal /> */}
    </div>
  );
};

export default PWALayout;
