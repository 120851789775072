import ModalManager from '@/components/ModalManage';
import ToastOverlay from '@/components/ToastOverlay';
import ClientOnly from '@/components/Utils/ClientOnly';
import Web3Provider from '@/components/Web3Provider';
import {
  GA_TRACKING_ID,
  SOCKET_DGAMES,
  TC_LAYER2_NETWORK_RPC,
} from '@/configs';
import { SEO_DESCRIPTION, SEO_IMAGE, SEO_TITLE } from '@/constants/seo';
import { AssetsProvider } from '@/contexts/assets-context';
import { BitcoinAracdeProvider } from '@/contexts/bitcoin-arcade-context';
import { CurrencyProvider } from '@/contexts/currency-context';
import TradeKeyProvider from '@/contexts/trade-key-context';
import { WalletProvider } from '@/contexts/wallet-context';
import DMProvider from '@/modules/AlphaPWA/DirectMessage/provider';
import Referral from '@/modules/AlphaPWA/Referral';
import Hydrated from '@/modules/Hydrated';
import UserIdle from '@/modules/UserIdle';
import KeepAliveTracking from '@/modules/UserIdle/KeepAliveTracking';
import InternetProvider from '@/providers/InternetProvider';
import OverlayProvider from '@/providers/OverlayProvider';
import SocketProvider from '@/providers/SocketProvider';
import * as serviceWorkerRegistration from '@/serviceWorkerRegistration';
import { SwapConfigProvider } from '@/contexts/swap-configs-context';
import store from '@/state';
import '@/styles/index.scss';
import customTheme from '@/theme';
import { getPWAFavicon } from '@/utils/pwa';
import { ChakraProvider } from '@chakra-ui/react';
import { ethers } from 'ethers';
import 'leaflet/dist/leaflet.css';
import type { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import Head from 'next/head';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import 'swiper/css';

ReactGA.initialize(GA_TRACKING_ID);

const montserrat = Montserrat({ subsets: ['latin'] });

export default function App({ Component, pageProps }: AppProps) {
  const { seoInfo = {} } = pageProps;
  const { title, description, image, favicon } = seoInfo;
  // const isMounted = useIsMounted();

  // const { addressL2 } = useContext(WalletContext);

  // useEffect(() => {
  //   if (isMounted && typeof window !== 'undefined') {
  //     ReactGA.send({
  //       hitType: 'pageview',
  //       address: addressL2,
  //       page: window.location.pathname + window.location.search,
  //       title,
  //     });
  //   }
  // }, [isMounted]);

  useEffect(() => {
    if (window.provider === undefined) {
      const provider = new ethers.providers.JsonRpcProvider(
        TC_LAYER2_NETWORK_RPC
      );
      window.provider = provider;
    }
  }, []);

  return (
    <>
      <Head>
        <title>{title ?? SEO_TITLE}</title>
        <meta property="og:title" content={title ?? SEO_TITLE} />
        <meta
          property="og:description"
          content={description ?? SEO_DESCRIPTION}
        />
        <meta property="og:image" content={image ?? SEO_IMAGE} />
        <meta property="og:type" content="website" />
        <meta property="twitter:title" content={title ?? SEO_TITLE} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content={description ?? SEO_DESCRIPTION}
        />
        <meta name="twitter:image" content={image ?? SEO_IMAGE} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="icon"
          type="image/svg"
          sizes="16x16 32x32"
          href={favicon ?? getPWAFavicon()}
        />
        <link
          rel="apple-touch-icon"
          sizes="16x16 32x32"
          href={favicon ?? getPWAFavicon()}
        />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        ></meta>
        <link rel="manifest" href={'/manifest.json'} />
      </Head>

      <style jsx global>{`
        html {
          font-family: ${montserrat.style.fontFamily};
        }
        body {
          background: #000000;
        }
      `}</style>
      <ClientOnly>
        <InternetProvider>
          <UserIdle>
            <ChakraProvider theme={customTheme}>
              <Provider store={store}>
                <Web3Provider>
                  <WalletProvider>
                    <AssetsProvider>
                      <Referral />
                      <CurrencyProvider>
                        <SocketProvider
                          host={`${SOCKET_DGAMES}/gamefi`}
                          path=""
                        >
                          <DMProvider>
                            <BitcoinAracdeProvider>
                              <Hydrated>
                                <OverlayProvider>
                                  <TradeKeyProvider>
                                    <SwapConfigProvider>
                                      <Component {...pageProps} />
                                    </SwapConfigProvider>
                                    <ToastOverlay />
                                    <ModalManager />
                                    {/* <KeepAliveTracking /> */}
                                  </TradeKeyProvider>
                                </OverlayProvider>
                              </Hydrated>
                            </BitcoinAracdeProvider>
                          </DMProvider>
                        </SocketProvider>
                      </CurrencyProvider>
                    </AssetsProvider>
                  </WalletProvider>
                </Web3Provider>
              </Provider>
            </ChakraProvider>
          </UserIdle>
        </InternetProvider>
      </ClientOnly>
    </>
  );
}

// Register the service worker
serviceWorkerRegistration.register();
