import PageLoading from '@/components/PageLoading';
import SvgInset from '@/components/SvgInset';
import { CDN_URL_ICONS } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import { AuthenticationType } from '@/enums/chat';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import {
  ChatGroupMember,
  MessageGameBullBearAction,
  MessageGameType,
  MessageTypeEnum,
} from '@/interfaces/api/chat';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import { HoldingUserTokens } from '@/interfaces/api/verifyFriendTech';
import { ITribeMetadata } from '@/interfaces/tribe';
import PinChat from '../MenuActionChat/PinChat';
import { CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS } from '@/modules/AlphaPWA/DirectMessage/constants';
import {
  getMessageObject,
  getStickerIndex,
  tryParseStringObj,
} from '@/modules/AlphaPWA/DirectMessage/helpers';
import { useDM } from '@/modules/AlphaPWA/DirectMessage/provider';
import ChatImageBrowserIcon from '@/modules/AlphaPWA/DirectMessage/svg/ChatImageBrowserIcon';
import TribeMemberIcon from '../svg/TribeMemberIcon';
import {
  BuyMessageContent,
  DMMessage,
  DMRoom,
} from '@/modules/AlphaPWA/DirectMessage/types';
import { EMOJI_STICKERS } from '@/modules/AlphaPWA/Home/PostItem/Emoji';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';
import CBullBearAPI from '@/services/classes/bullBear';
import { IUserJoinBullBear } from '@/services/interfaces/bullBear';
import { useAppDispatch } from '@/state/hooks';
import { openModal } from '@/state/modal';
import { compareString, formatCurrency } from '@/utils';
import { formatTCAddress } from '@/utils/format';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { poolProfilePersistor } from '@/utils/persistor';
import { Flex, Text } from '@chakra-ui/react';
import cs from 'classnames';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import sanitizeHtml from 'sanitize-html';

import s from './styles.module.scss';

const AVATAR_WIDTH = 50;
export const ITEM_HEIGHT = 88;

type IProps = {
  room: DMRoom;
  holdingTokenUser?: HoldingUserTokens | null;
  order: number;
  animation: boolean;
  isNoStyleAnimation?: boolean;
  onItemClicked: (roomId: string) => void;
};

const ComponentItem = ({
  room,
  holdingTokenUser,
  order,
  onItemClicked,
}: IProps) => {
  const apiBullBear = useRef(new CBullBearAPI()).current;
  const { roomMessages, getUserProfile, rooms, setRoomOwnerTwitterId } =
    useDM();

  const groupRoomIds = useMemo(() => {
    if (room?.id) {
      return rooms
        .filter(
          item =>
            ['CHAT_GROUP', 'CHAT_TRIBE'].includes(item.type) &&
            compareString(item.ownerInfo?.address, room?.ownerInfo?.address)
        )
        .map(room => room.id);
    }
    return [];
  }, [rooms.length, room?.id]);

  const lastMessage = useMemo((): DMMessage | undefined => {
    const messages = orderBy(
      groupRoomIds
        .map(id => {
          const messages = roomMessages[id] || [];
          return messages[messages.length - 1];
        })
        .filter(item => !!item)
        .map(item => ({
          ...item,
          sortedTime: Math.floor(moment(item.createdAt).valueOf() / 60000),
        })),
      ['sortedTime'],
      'desc'
    );

    return messages[0];
  }, [roomMessages, groupRoomIds]);

  const roomLastMessage = useMemo(() => {
    if (lastMessage) {
      const foundRoom = rooms.find(item => item.id === lastMessage?.roomId);
      if (foundRoom) {
        return foundRoom;
      }
    }
    return room;
  }, [lastMessage?.roomId, room, rooms.length]);

  const [userPlayer, setUserPlayer] = useState<IGetPlayerPoolProfile | null>(
    getUserProfile(room.owner)
  );
  const { addressL2 } = useContext(WalletContext);
  const targetRef = useRef<HTMLDivElement>(null);
  const [joinUser, setJoinUser] = useState<IUserJoinBullBear>();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);

  const [lastMessagePlayer, setLastMessagePlayer] =
    useState<IGetPlayerPoolProfile | null>(getUserProfile(lastMessage?.from));

  const isMine = useMemo(() => {
    return compareString(addressL2, room?.owner);
  }, [addressL2, room]);

  const isHaveNewMessage = useMemo(() => {
    if (lastMessage && roomLastMessage) {
      if (lastMessage.createdAt && roomLastMessage.lastSeen) {
        return moment(lastMessage.createdAt).isAfter(roomLastMessage.lastSeen);
      } else if (!roomLastMessage.lastSeen) {
        return true;
      }
    }
    return false;
  }, [lastMessage?.createdAt, roomLastMessage.lastSeen]);

  useEffect(() => {
    poolProfilePersistor
      .getItem(room.owner)
      .then(storedItem => {
        setUserPlayer(storedItem);
      })
      .finally(() => {
        const user = getUserProfile(room.owner);
        if (user) {
          setUserPlayer(user);
        }
      });
  }, [room?.owner]);

  useEffect(() => {
    getJoinBullBear();
  }, [lastMessage?.content]);

  const getJoinBullBear = async () => {
    if (!lastMessage?.type) {
      return;
    }
    try {
      const parseContent = JSON.parse(lastMessage?.content);

      if (
        lastMessage.type === MessageTypeEnum.BULL_BEAR_JOIN_EVENT ||
        (lastMessage.type === MessageTypeEnum.GAME_EVENT &&
          parseContent?.action === MessageGameBullBearAction.JOIN)
      ) {
        const eventId = parseContent?.game_id || parseContent?.bullBearEventId;
        const joinId =
          parseContent?.extra_data?.join_id || parseContent?.bullBearJoinId;
        const rs = await apiBullBear.getJoinBullAndBear(eventId, joinId);
        setJoinUser(rs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (lastMessage) {
      poolProfilePersistor
        .getItem(lastMessage.from)
        .then(storedItem => {
          setLastMessagePlayer(storedItem);
        })
        .finally(() => {
          const user = getUserProfile(lastMessage.from);
          if (user) {
            setLastMessagePlayer(user);
          }
        });
    }
  }, [lastMessage]);

  const onItemClick = async () => {
    if (lastMessage) {
      // setTimeout(() => {
      //   if (room?.ownerInfo?.twitterId) {
      //     setRoomOwnerTwitterId(room?.ownerInfo?.twitterId);
      //   }
      // }, 1000);

      onItemClicked(lastMessage?.roomId);
    } else {
      onItemClicked(room?.id);
    }
  };

  const getLastMessageUserName = (lastMessage: DMMessage) => {
    if (lastMessagePlayer) {
      return (
        lastMessagePlayer.twitterName ||
        lastMessagePlayer.bns ||
        formatTCAddress(lastMessagePlayer.address)
      );
    }
    return formatTCAddress(lastMessage.from);
  };

  const renderTribeName = (metadata: string) => {
    const { name: nameFromMeta } = tryParseStringObj(
      metadata
    ) as ITribeMetadata;
    const name = room?.ownerInfo?.twitterName || nameFromMeta;
    return (
      <Flex alignItems={'center'} gap="12px">
        <p className={s.circle_name_wrapper}>
          <span className={s.circle_name}>{name}</span>
          {/* &nbsp;Tribe */}
        </p>

        <Text
          className={s.circle_member}
          opacity={0.7}
          display={'flex'}
          alignItems={'center'}
          gap="2px"
          h={'15px'}
        >
          <TribeMemberIcon />
          <Text as="span" lineHeight={'15px'} className={s.circle_name}>
            {room.memberCount}
          </Text>
        </Text>
      </Flex>
    );
  };

  const localizationMessage = () => {
    try {
      if (room && lastMessage) {
        const translatedMessage =
          lastMessage[CONTENT_LANG_DATA_TO_MESSAGE_UI_KEYS[room.language]];
        if (translatedMessage) {
          return translatedMessage;
        }
        return lastMessage.content;
      }
      return '';
    } catch (e) {
      return '';
    }
  };

  const displayNormalMessage = (lastMessage: DMMessage) => {
    try {
      const content = localizationMessage();
      const messageText = getMessageObject(content).plain;

      const isSticker = getMessageObject(content).type === 'STICKER';

      if (isSticker) {
        const stickerText = getStickerIndex(messageText);
        return (
          <>
            <span className={s.sticker}>
              {EMOJI_STICKERS[Number(stickerText)].icon()}
            </span>
          </>
        );
      }

      if (messageText) {
        return (
          <>
            <span
              className={s.innerText}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(messageText, {
                  allowedAttributes: {
                    span: ['class'],
                  },
                }),
              }}
            ></span>
          </>
        );
      }

      if (lastMessage.mediaUrls.length) {
        return (
          <>
            <ChatImageBrowserIcon size={16} />
          </>
        );
      }

      return '';
    } catch (e) {
      return '';
    }
  };

  const displaySpecialMessage = (lastMessage: DMMessage) => {
    try {
      const content = localizationMessage();
      const userInfo = tryParseStringObj<ChatGroupMember>(content);
      if (typeof userInfo === 'string') {
        // return <>&#9617;&#9617;&#9617;&#9617;&#9617;&#9617;&#9617;&#9617;</>;
        return '';
      }
      if (lastMessage.type === MessageTypeEnum.ADD_MEMBER) {
        return `${userInfo.twitterName} has joined`;
      }

      if (lastMessage.type === MessageTypeEnum.REMOVE_MEMBER) {
        //return `${userInfo.twitterName} has left`;
        return ``;
      }

      if (lastMessage.type === MessageTypeEnum.CREATE_DONATION) {
        return `${lastMessagePlayer?.twitterName} gifted some red packets to the community`;
      }

      if (lastMessage.type === MessageTypeEnum.CLAIM_DONATION) {
        return `${lastMessagePlayer?.twitterName} snatched a red packet in the community`;
      }

      if (lastMessage.type === MessageTypeEnum.BUY_EVENT) {
        const buyInfo = tryParseStringObj<BuyMessageContent>(
          content
        ) as BuyMessageContent;
        if (buyInfo) {
          return `${buyInfo?.fromInfo?.twitterName} just snagged ${
            buyInfo?.buyAmount
          } ${buyInfo?.toInfo?.twitterName} ${
            buyInfo?.buyAmount > 1 ? 'tokens' : 'token'
          }.`;
        }
        return '';
      }

      if (lastMessage.type === MessageTypeEnum.WHEEL_EVENT) {
        return `${lastMessagePlayer?.twitterName} created a new Lucky Wheel`;
      }

      if (lastMessage.type === MessageTypeEnum.RAFFLE_EVENT) {
        return `${lastMessagePlayer?.twitterName} created a new raffle`;
      }

      if (lastMessage.type === MessageTypeEnum.BULL_BEAR_EVENT) {
        return `${lastMessagePlayer?.twitterName} created a new Mr. Market`;
      }

      if (lastMessage.type === MessageTypeEnum.BULL_BEAR_JOIN_EVENT) {
        return `${joinUser?.user?.twitter_name} joined ${
          joinUser?.type === 'bull' ? 'bullish' : 'bearish'
        } a Mr. Market`;
      }

      if (lastMessage.type === MessageTypeEnum.GAME_EVENT) {
        try {
          const parseContent = JSON.parse(lastMessage?.content);

          if (parseContent?.game_type === MessageGameType.BULL_BEAR) {
            if (parseContent?.action === MessageGameBullBearAction.END) {
              return `Mr. Market has ended`;
            } else if (
              parseContent?.action === MessageGameBullBearAction.CREATE
            ) {
              return `${lastMessagePlayer?.twitterName} created a new Mr. Market`;
            } else if (
              parseContent?.action === MessageGameBullBearAction.JOIN
            ) {
              return `${joinUser?.user?.twitter_name} joined ${
                joinUser?.type === 'bull' ? 'bullish' : 'bearish'
              } a Mr. Market`;
            }
          }

          if (parseContent?.game_type === MessageGameType.AIRDROP) {
            const { User, Content } = parseContent?.extra_data;
            if (!User || !Content) {
              return <></>;
            }
            return `${User?.TwitterName || ''} ${Content}`;
          }

          if (parseContent?.game_type === MessageGameType.SWAP_UNI) {
            const { User, Content } = parseContent?.extra_data;
            if (!User || !Content) {
              return <></>;
            }
            return `${Content}`;
          }

          if (parseContent?.game_type === MessageGameType.TOKEN_BURNED) {
            const { user, token, amount } = parseContent?.extra_data;
            if (!user || !token || !amount) {
              return <></>;
            }
            return `${user?.twitter_name || ''} burned ${formatCurrency(
              amount,
              0,
              6
            )} ${token?.user_twitter_name || ''} tokens.`;
          }

          if (parseContent?.game_type === MessageGameType.TOKEN_DONATE) {
            const { user, token, amount } = parseContent?.extra_data;
            if (!user || !token || !amount) {
              return <></>;
            }
            return `${user?.twitter_name || ''} donated ${formatCurrency(
              amount,
              0,
              6
            )} ${token?.user_twitter_name || ''} tokens.`;
          }
        } catch (error) {
          return '';
        }
      }

      if (lastMessage.type === MessageTypeEnum.TIP_EVENT) {
        return `${userInfo?.sender?.twitter_name} sent ${userInfo?.receiver?.twitter_name} a gift`;
      }
      // if (lastMessage.type === MessageTypeEnum.JAM_STARTED) {
      //   return `${lastMessagePlayer?.twitterName} start JAM in community`;
      // }
      // if (lastMessage.type === MessageTypeEnum.JAM_JOINED) {
      //   return `${lastMessagePlayer?.twitterName} has joined JAM`;
      // }
    } catch (err) {
      console.log(err);
    }

    return '';
  };

  const renderTripeType = useMemo(() => {
    switch (room.tribeType) {
      case AuthenticationType.Passcode:
        return <span className={s.tribeType}>Code access</span>;
      case AuthenticationType.NFTGated:
        return <span className={s.tribeType}>NFT access</span>;
      case AuthenticationType.TokenGated:
        return <span className={s.tribeType}>Token access</span>;
      default:
        return <span className={s.tribeType}>Public</span>;
    }
  }, [room.tribeType]);

  const renderUsers = () => {
    const tribeThumbnail = getUrlAvatarTwitter(
      room?.ownerInfo?.twitterAvatar as string,
      'high'
    );

    return (
      <>
        <div className={s.pin}>{!isMine && <PinChat room={room} />}</div>
        <div
          className={s.avatars}
          style={{
            width: `${AVATAR_WIDTH}px`,
            minWidth: `${AVATAR_WIDTH}px`,
          }}
        >
          <div className={s.avatar}>
            <Avatar
              width={AVATAR_WIDTH}
              url={tribeThumbnail}
              address={userPlayer?.address}
              name={userPlayer?.twitterName}
              fontSize={26}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div
        className={s.wrapper}
        onClick={onItemClick}
        style={{
          height: ITEM_HEIGHT,
          top: `${order * ITEM_HEIGHT}px`,
        }}
        ref={targetRef}
      >
        <div className={s.item}>
          <div className={s.info}>
            {renderUsers()}
            <div className={s.chat}>
              <div className={s.user}>
                <span
                  className={cs(
                    s.user_name,
                    isHaveNewMessage || !room.isActive
                      ? s.user_name_newMessage
                      : ''
                  )}
                >
                  {room.metadata ? (
                    renderTribeName(room.metadata)
                  ) : (
                    <Flex alignItems={'center'} gap="12px">
                      <p className={s.circle_name_wrapper}>
                        <span className={s.circle_name}>
                          {room.ownerInfo?.twitterName}
                        </span>
                        &nbsp;
                        {/* {room.type === 'CHAT_TRIBE' ? 'Community' : 'Frens'} */}
                      </p>
                      <Text className={s.circle_member} opacity={0.7}>
                        <TribeMemberIcon />
                        <span className={s.circle_name}>
                          {room.memberCount}
                        </span>
                      </Text>
                    </Flex>
                  )}

                  {/* {renderCircleName(room.owner)} */}
                </span>
                <div>
                  {lastMessage && lastMessage.createdAt && room.isActive && (
                    <time className={s.user_time}>
                      {moment(lastMessage.createdAt)
                        .fromNow()
                        .replace(' ago', '')}
                    </time>
                  )}
                </div>
              </div>
              <div className={s.message}>
                {!room.isActive && (
                  <>
                    {renderTripeType}
                    <span className={s.division} />
                  </>
                )}
                <span
                  className={cs(
                    s.message__text,
                    isHaveNewMessage || !room.isActive
                      ? s.message__text__newMessage
                      : ''
                  )}
                >
                  {!!lastMessage && room.isActive && (
                    <>
                      {lastMessage.type === MessageTypeEnum.NORMAL ? (
                        <Flex alignItems={'center'} gap={'4px'}>
                          {getLastMessageUserName(lastMessage)}:{' '}
                          {displayNormalMessage(lastMessage)}
                        </Flex>
                      ) : (
                        <span className={s.special_msg}>
                          {displaySpecialMessage(lastMessage)}
                        </span>
                      )}
                    </>
                  )}
                  {!room.isActive && (
                    <>
                      <span className={s.roomOwnerName}>
                        {room?.ownerInfo?.twitterName}
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {processing && <>{createPortal(<PageLoading />, document.body)}</>}
    </>
  );
};

const Item = ({
  order,
  animation,
  isNoStyleAnimation = false,
  ...rest
}: IProps) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(targetRef, { threshold: 0.5 });
  const [isInViewportOnce, setIsInViewportOnce] = useState(isInViewport);

  useEffect(() => {
    if (isInViewport) {
      setIsInViewportOnce(isInViewport);
    }
  }, [isInViewport]);

  return (
    <div
      ref={targetRef}
      className={cs(isNoStyleAnimation === false && s.floatItem)}
      style={
        isNoStyleAnimation
          ? {}
          : {
              transition: animation ? 'all 200ms' : 'none',
              height: ITEM_HEIGHT,
              top: `${order * ITEM_HEIGHT}px`,
            }
      }
    >
      <ComponentItem order={order} animation={animation} {...rest} />
    </div>
  );
};

export default React.memo(Item);
