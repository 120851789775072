import {
  CHAINS_INFO,
  NETWORK_TO_EXPLORER,
  SupportedChainId,
} from '@/constants/chains';
import { IResourceChain } from '@/interfaces/chain';
import Web3 from 'web3';
import { setWalletChainId } from './auth-storage';
import { compareString } from "@/utils/string";
import { BASE_CHAIN_ID, BASE_RPC } from "@/contracts/configs";

const API_PATH = 'https://chainid.network/chains.json';

const getChainList = async (): Promise<Array<IResourceChain>> => {
  try {
    const res = await fetch(API_PATH);
    const data = await res.json();
    return [...CHAINS_INFO, ...data] as Array<IResourceChain>;
  } catch (err: unknown) {
    return [];
  }
};

export function isSupportedChain(
  chainId: number | null | undefined
): chainId is SupportedChainId {
  return !!chainId && !!SupportedChainId[chainId];
}

export const switchChain = async (chainId: SupportedChainId) => {
  if (window.ethereum) {
    try {
      await Object(window.ethereum).request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
      setWalletChainId(chainId);
    } catch (err: unknown) {
      if (Object(err).code !== 4902) throw err;

      const chainList = await getChainList();
      const info = chainList.find((c: IResourceChain) => compareString(c.chainId, chainId));
      if (!info) {
        throw new Error(`Chain ${chainId} not supported`);
      }

      const params = {
        chainId: Web3.utils.toHex(info.chainId),
        chainName: info.name,
        nativeCurrency: {
          name: info.nativeCurrency.name,
          symbol: info.nativeCurrency.symbol,
          decimals: info.nativeCurrency.decimals,
        },
        rpcUrls: compareString(info.chainId, BASE_CHAIN_ID) ? [BASE_RPC] : info.rpc,
        blockExplorerUrls: [
          info.explorers && info.explorers.length > 0 && info.explorers[0].url
            ? info.explorers[0].url
            : info.infoURL,
        ],
      };

      await Object(window.ethereum).request({
        method: 'wallet_addEthereumChain',
        params: [params],
      });
    }
  }
};

export const getExplorer = (
  hash: string,
  network: 'eth' | 'tc' | 'nos' | 'base' = 'tc',
  type: 'tx' | 'address' = 'tx'
) => {
  return `${NETWORK_TO_EXPLORER[network]}/${type}/${hash}`;
};
