import React, { useContext, useState } from 'react';
import { Container, MDContainer } from './GetTC.styled';
import SubmitForm from './SubmitForm';
import { IGetTcResponse, GetTcPayType } from '@/interfaces/topup';
import { requestGetTc } from '@/services/topup';
import PaymentForm from './PaymentForm';
import { getErrorMessage } from '@/utils/error';
import toast from 'react-hot-toast';
import useAnalyticsEventTracker, { TopupActions } from '@/utils/ga';
import { useRouter } from 'next/router';
import { WalletContext } from '@/contexts/wallet-context';
import { formatTCPrice } from '@trustless-computer/dapp-core';
import { formatEthPrice } from '@/utils/format';

const GetTC = ({
  reCaptcha,
  isModal,
  tcAmount,
  tcAddress,
  onCloseModal,
  title,
}: {
  reCaptcha?: string;
  isModal?: boolean;
  tcAmount?: number;
  tcAddress?: string;
  title?: string;
  onCloseModal?: () => void;
}) => {
  const { transfer } = useContext(WalletContext);

  const gaEventTracker = useAnalyticsEventTracker();
  const router = useRouter();
  const params = router.query;

  const [paymentInfo, setPaymentInfo] = useState<IGetTcResponse | undefined>();

  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmitGenerate = async (
    tcAddress: string,
    tcAmount: number,
    payType: GetTcPayType,
    onErrorCallback?: () => void
  ) => {
    const gaPayload = {
      tcAddress,
      tcAmount,
      payType,
    };
    gaEventTracker(TopupActions.BuyTC, JSON.stringify(gaPayload));
    try {
      setIsProcessing(true);
      const data = await requestGetTc({
        tcAddress,
        tcAmount,
        payType: payType === 'ethereum-mm' ? 'ethereum' : payType,
        reCaptcha: '',
      });

      if (payType === 'ethereum-mm') {
        const resp = await transfer(
          data.address,
          formatEthPrice(data.paymentAmount)
        );
        if (resp && resp !== '') {
          toast.success('Transaction successfully.');
          onCloseModal && onCloseModal();
        }
      } else {
        setPaymentInfo(data);
      }
    } catch (err) {
      onErrorCallback && onErrorCallback();
      const { message } = getErrorMessage(err);
      toast.error(message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onBackToSubmit = () => {
    gaEventTracker(TopupActions.BuyMore);
    setPaymentInfo(undefined);
  };

  return (
    <MDContainer>
      <Container>
        <p className="title">{'Buy TC'}</p>
        {paymentInfo && (
          <PaymentForm
            paymentInfo={paymentInfo}
            isProcessing={isProcessing}
            onBackToSubmit={onBackToSubmit}
            onSubmitGenerate={onSubmitGenerate}
          />
        )}

        <SubmitForm
          isProcessing={isProcessing}
          onSubmitGenerate={onSubmitGenerate}
          tcAmount={tcAmount}
          tcAddress={tcAddress}
          paymentInfo={paymentInfo}
        />
        {/* {isModal && (
          <a
            target="_blank"
            href={`/topup/history${location.search}`}
            className="checkHistory checkHistoryModal"
          >
            Check your TC transaction history here.
          </a>
        )} */}
      </Container>
      {/* {!isModal && (
        <a
          target="_blank"
          href={`/topup/history${location.search}`}
          className="checkHistory"
        >
          Check your TC transaction history here.
        </a>
      )} */}
    </MDContainer>
  );
};

export default GetTC;
