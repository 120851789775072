export const IcOwner = () => {
  return (
    <>
      <svg
        height="24"
        width="24"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          height="22.5"
          width="22.5"
          fill="white"
          rx="11.25"
          x="0.75"
          y="0.75"
        />
        <rect
          height="22.5"
          width="22.5"
          rx="11.25"
          stroke="black"
          strokeWidth="1.5"
          x="0.75"
          y="0.75"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M10.7032 17.0599C8.28954 18.344 5.29189 17.4284 4.00776 15.0146C2.72363 12.6011 3.6393 9.60341 6.05296 8.31928C8.46663 7.03516 11.4643 7.95081 12.7484 10.3645C14.0325 12.7781 13.1169 15.7758 10.7032 17.0599Z"
            fill="url(#paint0_linear_18913_45309)"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M16.1014 9.77001C18.7164 10.5688 20.1887 13.3361 19.39 15.9511C18.5912 18.566 15.8238 20.0383 13.2089 19.2395C10.594 18.4408 9.12163 15.6735 9.92041 13.0585C10.7192 10.4436 13.4865 8.97125 16.1014 9.77001Z"
            fill="url(#paint1_linear_18913_45309)"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M17.6712 10.2987C16.8724 12.9137 14.1051 14.386 11.4901 13.5872C8.8752 12.7885 7.40289 10.0211 8.20165 7.40615C9.00041 4.7912 11.7678 3.31891 14.3827 4.11767C16.9976 4.91643 18.47 7.68378 17.6712 10.2987Z"
            fill="url(#paint2_linear_18913_45309)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_18913_45309"
            gradientUnits="userSpaceOnUse"
            x1="4.0015"
            x2="12.7421"
            y1="15.003"
            y2="10.3527"
          >
            <stop stopColor="#006CEA" />
            <stop offset="1" stopColor="#E6F1FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_18913_45309"
            gradientUnits="userSpaceOnUse"
            x1="16.9804"
            x2="12.3299"
            y1="18.8755"
            y2="10.1341"
          >
            <stop stopColor="#00ED7B" />
            <stop offset="1" stopColor="#E7FFF3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_18913_45309"
            gradientUnits="userSpaceOnUse"
            x1="11.5175"
            x2="14.4101"
            y1="13.6166"
            y2="4.14704"
          >
            <stop stopColor="#FFF2E1" />
            <stop offset="1" stopColor="#F7931A" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
