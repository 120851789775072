import { AuthenticationType } from '@/enums/chat';
import {
  ChatGroupMember,
  ChatMessageResponse,
  ChatRoomResponse,
  ChatTypeResponse,
  LanguageSupportedEnum,
  TipBase,
} from '@/interfaces/api/chat';
import { ReturnValue } from '@enzoferey/ethers-error-parser';
import { Scrollbars } from 'react-custom-scrollbars';

export type DMScreen = '' | 'HOME' | 'CHAT';

export type MessageContent = {
  type?: 'TEXT' | 'STICKER' | 'HTML';
  plain: string;
  rawText: string;
  joinContent?: string; // for join or leave
};

export interface EmojiType {
  id: string;
  name: string;
  keywords: string[];
  native: string;
  shortcodes: string;
  unified: string;
}

export type DMRealTimeMessage = Omit<
  ChatMessageResponse,
  'id' | 'createdAt' | 'updatedAt'
>;

export type UploadFile = File & {
  isSucceed?: boolean;
  url?: string;
};

export type MediaFile = {
  url: string;
  size: number;
  name: string;
  type: string;
};

export type DMRoom = ChatRoomResponse & {
  messageLoaded?: boolean;
  pinned?: boolean;
  ownerToken?: string;
  tribeType?: AuthenticationType;
  metadata?: string;
  collectionAddress?: string;
  roles?: Array<string>;
};

export type ChatType = ChatTypeResponse;

export type DMMessage = ChatMessageResponse & {
  uiid?: string;
  isLoading?: boolean;
  isError?: boolean;
  timestamp?: string;
};

export type TipMessage = {
  id: string;
  roomId: string;
};

export type ExtendScrollbars = Scrollbars & {
  scrolledIDs: Record<string, boolean>;
};

export type ChatTab = 'CIRCLES' | 'DMS';

export type RoomId = string;

export type RemoveMemberFromGroupRealTime = {
  roomId: RoomId;
  info: ChatGroupMember;
};

export type AddMemberFromGroupRealTime = {
  roomId: RoomId;
  info: ChatGroupMember;
};

type EnvelopInvolvedPerson = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: 0;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type EnvelopContent = {
  id: string;
  quantity: number;
  receiverInfo: EnvelopInvolvedPerson;
  rewardInfo: EnvelopInvolvedPerson;
  tokenGroupOwner: string;
  tokenReceiver: string;
  tokenReward: string;
};

export type MessageWheelContent = {
  prizeWheelFeedId: number;
  roomId: string;
  timestamp: number;
};

export type MessageRaffleContent = {
  raffleFeedId: number;
  roomId: string;
  timestamp: number;
};

export type MessageBullBearContent = {
  bullBearFeedId: number;
  game_feed_id: number;
  bullBearJoinId: number;
  extra_data: {
    join_id: number;
  };
  bullBearEventId: number;
  game_id: number;
  roomId: string;
  timestamp: number;
  action: string;
};

type BuyFromPerson = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: 0;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};

export type BuyMessageContent = {
  buyAmount: number;
  eventContent: string;
  fromInfo: BuyFromPerson;
  network: string;
  toInfo: BuyFromPerson;
};

export type UpdateRealTimeRoomCalling = {
  appId: string;
  channelName: string;
  result: string;
  roomId: string;
  sessionId: string;
  token: string;
  uid: string;
};

export type GroupCallingEnded = {
  remainMember: number;
  result: boolean;
  roomId: string;
  sessionId: string;
};

export type BlockChatUser = {
  room_id: string;
  address: string[];
};

export type UnblockChatUser = {};

export type GameTournamentUser = {
  address: string;
  twitterAvatar: string;
  twitterFollowersCount: 0;
  twitterId: string;
  twitterName: string;
  twitterUsername: string;
};
export type RoomLanguageSettings = Record<RoomId, LanguageSupportedEnum>;

export enum ContentByLanguageEnum {
  OFF = 'content',
  ENGLISH = 'contentEn',
  CHINESE_SIMPLIFIED = 'contentCn',
  CHINESE_TRADITIONAL = 'contentTw',
  JAPANESE = 'contentJp',
}

export type ReplyMessageData = DMMessage & {
  isSender?: boolean;
  isGroup?: boolean;
};

export type PinUpdateSocket = {
  roomId: string;
  address: string;
  pinned: boolean;
};

export type MemberInCall = {
  members?: string[];
  total?: number;
};

export enum ChatCommandEnum {
  chart = 'chart',
  uni = 'uni',
}

export enum PromptEncapsulationEnum {
  public = '/',
  private = '!',
  // private = 'thisfeatureisnotunsupportforthismomentpleasecommentwhenisreadyandreplacebyonlineabove',
}

export type ForwardMessageMetadata = {
  originForwardMessage: ChatMessageResponse;
};

export type TipMessageContent = {
  isTipOpened: boolean;
  messageId: string;
  sender: TipBase;
  receiver: TipBase;
  token: TipBase;
  amount: string;
  animation: string;
};
