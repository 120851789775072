import { IWalletContext, WalletContext } from '@/contexts/wallet-context';
import { BASE_SYMBOL } from '@/contracts/configs';
import {
  IBodyBuySweetFloorKeys,
  IResponseBuySweetFloorKeys,
} from '@/contracts/interfaces/tradeKey';
import useTradeKey from '@/hooks/playerShareToken/useTradeKey';
import { ITradeKey } from '@/services/interfaces/trade';
import {
  compareString,
  formatCurrency,
  formatName,
  getExplorer,
} from '@/utils';
import { colors } from '@/utils/color';
import {
  formatAmountToClient,
  getErrorMessageContract,
  getUrlAvatarTwitter,
} from '@/utils/helpers';
import { Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import clsx from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdWarning } from 'react-icons/md';
import Avatar from '../components/avatar';
import HorizontalItem from '../components/horizontalItem';
import { SLIDE_ALPHA, SLIDE_FT, TRADE_ACTION } from '../constants';
import styles from '../styles.module.scss';

interface IBuying {
  keys: ITradeKey[];
  selectKeys: IBodyBuySweetFloorKeys[];
  waitKeys: IResponseBuySweetFloorKeys[];
  setNumProcessDone: (_: any) => any;
  isInvite?: boolean;
}

interface ItemBuying extends IBuying {
  token_address: string;
  isLast?: boolean;
  isInvite?: boolean;
  pendingFTTxs: IResponseBuySweetFloorKeys[];
  ftTxDone: number;
  setFTTxDone: (_: any) => void;
}

const ItemBuying: React.FC<ItemBuying> = ({
  token_address,
  isLast,
  waitKeys,
  setNumProcessDone,
  isInvite,
  selectKeys,
  pendingFTTxs,
  ftTxDone,
  setFTTxDone,
}) => {
  const refBuying = useRef(false);

  const { alphaBuyOrSellFT } = useTradeKey();

  const infoTransaction = useMemo(
    () => waitKeys?.find?.(v => compareString(v.token_address, token_address)),
    [token_address, waitKeys]
  );

  const infoKey = useMemo(
    () => ({
      ...selectKeys.find(
        v =>
          compareString(v.key?.address, token_address) ||
          compareString(v.key?.ft_trader_address, token_address)
      ),
      infoTransaction,
    }),
    [token_address, selectKeys, infoTransaction]
  );

  const { scanTrxAlpha } = useTradeKey();

  const gameWalletProvider: IWalletContext = useContext(WalletContext);

  const [loading, setLoading] = useState(true);
  const [txSuccess, setTxSuccess] = useState<any>(undefined);
  const [txError, setTxError] = useState<any>(undefined);

  // useEffect(() => {
  //   if (infoTransaction && !refBuying.current) {
  //     refBuying.current = true;
  //     onWaitTransaction();
  //   }
  // }, [infoTransaction]);

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData) {
            

            if (
              !refBuying.current &&
              compareString(parsedData?.token_address, token_address) &&
              compareString(parsedData?.side, SLIDE_ALPHA)
            ) {
              console.log('parsedData', parsedData);
              refBuying.current = true;
              onWaitTransaction(parsedData);
            }
          }
        } catch (error) {}
      },
      false
    );
  }, []);

  useEffect(() => {
    if (
      infoTransaction &&
      infoKey &&
      pendingFTTxs.length > 0 &&
      compareString(infoKey?.key?.base_token_symbol, BASE_SYMBOL) &&
      ftTxDone < pendingFTTxs.length &&
      compareString(
        pendingFTTxs[ftTxDone].token_address,
        infoKey.token_address
      ) &&
      !refBuying.current
    ) {
      refBuying.current = true;
      onProcessFTBuy();
    }
  }, [ftTxDone, infoKey, infoTransaction, pendingFTTxs]);

  const onProcessFTBuy = async () => {
    try {
      const tx = await alphaBuyOrSellFT(
        infoTransaction?.token_address as string,
        infoTransaction?.token_amount as number,
        TRADE_ACTION.BUY
      );

      await scanTrxAlpha(tx?.hash, 'base');
      setTxSuccess(tx);
      setTxError(null);
    } catch (error) {
      setTxSuccess(undefined);
      setTxError(getErrorMessageContract(error));
    } finally {
      setFTTxDone((v: any) => v + 1);
      setNumProcessDone((v: any) => v + 1);
      setLoading(false);
    }
  };

  const onWaitTransaction = async (_infoTransaction: any) => {
    try {
      if (_infoTransaction?.error) {
        throw _infoTransaction?.error;
      }

      const tx =
        await gameWalletProvider.gameWallet?.provider.waitForTransaction(
          _infoTransaction?.tx?.hash
        );

      await scanTrxAlpha(tx?.transactionHash as string);
      setTxError(undefined);
      setTxSuccess(tx);
      setTxError(null);
    } catch (error) {
      setTxError(getErrorMessageContract(error)?.message);
      setTxSuccess(undefined);
      setTxError(getErrorMessageContract(error));
    } finally {
      setNumProcessDone((v: any) => v + 1);
      setLoading(false);
    }
  };

  const renderLabel = () => {
    if (isInvite) {
      return (
        <>
          <Spinner color={colors.redSecondary} />
          <Text color={colors.redSecondary}>Sending</Text>
        </>
      );
    }

    return (
      <>
        <Spinner color={colors.redSecondary} />
        <Text color={colors.redSecondary}>Buying</Text>
      </>
    );
  };

  const renderStatus = useMemo(() => {
    if (loading) {
      return renderLabel();
    }

    if (txSuccess) {
      return (
        <>
          <Tooltip placement="auto">
            <Flex alignItems={'center'} gap={1}>
              <BsFillCheckCircleFill color={colors.greenPrimary} />
              <Text
                cursor={'pointer'}
                onClick={() =>
                  window.open(
                    getExplorer(
                      compareString(infoTransaction?.side, SLIDE_FT)
                        ? txSuccess?.hash
                        : txSuccess?.transactionHash,
                      compareString(infoTransaction?.side, SLIDE_FT)
                        ? 'base'
                        : 'nos',
                      'tx'
                    ),
                    '_blank'
                  )
                }
                color={colors.greenPrimary}
                textDecorationLine={'underline'}
              >
                {isInvite ? 'Sent' : `Successfully`}
              </Text>
            </Flex>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip placement="auto" label={txError?.message || txError}>
          <Flex alignItems={'center'} gap={1}>
            <MdWarning color={colors.redPrimary} />
            <Text color={colors.redPrimary}>Failed</Text>
          </Flex>
        </Tooltip>
      </>
    );
  }, [loading, txSuccess, token_address, ftTxDone, infoTransaction, isInvite]);

  return (
    <HorizontalItem
      className={clsx(
        styles.buyingContainerItem,
        isLast && styles.buyingContainerItemLast
      )}
      label={
        <Flex alignItems={'center'} gap={'12px'}>
          <Avatar
            url={getUrlAvatarTwitter(
              infoKey?.key?.user_twitter_avatar as string
            )}
            name={infoKey?.key?.user_twitter_name}
            address={infoKey?.token_address}
            width={36}
          />
          <Flex flexDirection={'column'}>
            <Text fontWeight={'500'}>
              {formatName(
                (infoKey?.key?.user_twitter_name ||
                  infoKey?.key?.user_twitter_username ||
                  infoKey?.key?.address) as string,
                20
              )}
            </Text>
            <Text as="b">
              {formatCurrency(
                Boolean(Number(infoTransaction?.tc_amount?.toString()))
                  ? formatAmountToClient(infoTransaction?.tc_amount?.toString())
                  : Boolean(Number(infoKey?.key?.ft_share_price))
                  ? infoKey?.key?.ft_share_price
                  : infoKey?.key?.price,
                0,
                compareString(infoKey?.key?.side, SLIDE_FT) ? 5 : 7
              )}{' '}
              {compareString(infoKey?.key?.side, SLIDE_FT)
                ? 'ETH'
                : infoKey?.key?.base_token_symbol}
            </Text>
          </Flex>
        </Flex>
      }
      value={
        <Flex alignItems={'center'} gap={2}>
          {renderStatus}
        </Flex>
      }
    />
  );
};

const Buying: React.FC<IBuying> = ({
  keys,
  selectKeys,
  waitKeys,
  setNumProcessDone,
  isInvite,
}) => {
  const [ftTxDone, setFTTxDone] = useState(0);
  const pendingFTTxs = waitKeys.filter(v => compareString(v.side, SLIDE_FT));

  return (
    <>
      <Flex
        className={styles.buyingContainer}
        flexDirection={'column'}
        gap={2}
        maxHeight={'60vh'}
      >
        {selectKeys.map((v, i) => (
          <ItemBuying
            key={'sweep_' + v.token_address}
            keys={keys}
            token_address={v.token_address}
            isLast={i === selectKeys.length - 1}
            waitKeys={waitKeys}
            setNumProcessDone={setNumProcessDone}
            selectKeys={selectKeys}
            isInvite={isInvite}
            pendingFTTxs={pendingFTTxs}
            ftTxDone={ftTxDone}
            setFTTxDone={setFTTxDone}
          />
        ))}
      </Flex>
    </>
  );
};

export default Buying;
