import React, { memo, MouseEventHandler, useContext, useMemo } from 'react';
import s from './styles.module.scss';
import { getLinkNFT } from '@/utils/go-route';
import IcArrow from '../../svgs/IcArrow';
import { WalletContext } from '@/contexts/wallet-context';
import BigNumber from 'bignumber.js';
import { formatCurrency } from '@/utils';

type INftInfo = React.ComponentPropsWithRef<'div'> & {
  url?: string;
  tokenName: string;
  tokenId: string;
  tokenAddress: string;
  floorPriceETH?: string;
  floorPriceUSDT?: string;
  wheelDetail: any;
};

const NftInfo = React.forwardRef(
  ({ url, tokenName, tokenId, tokenAddress, wheelDetail }: INftInfo) => {
    const gotoNFT = (e: any) => {
      e.preventDefault();
      window.open(getLinkNFT(tokenAddress, tokenId), '_blank');
    };

    const { btc2Usd } = useContext(WalletContext);
    const { Amount: rewardPrice } = wheelDetail;

    const usdValue = useMemo(() => {
      if (!btc2Usd || !rewardPrice) return 0;
      const priceBn = new BigNumber(rewardPrice);
      return `$${formatCurrency(priceBn.times(btc2Usd), 0, 2)}`;
    }, [btc2Usd, rewardPrice]);

    const btcValue = useMemo(() => {
      if (!rewardPrice) return 0;
      return `${formatCurrency(rewardPrice, 0, 4)} BTC`;
    }, [rewardPrice]);

    return (
      <>
        <div className={s.tokenInfo}>
          {url && (
            <div className={s.tokenInfo_img} onClick={gotoNFT}>
              <img src={url} alt="" />
            </div>
          )}
          <div className={s.tokenInfo_content}>
            <div className={s.tokenInfo_content_name}>{tokenName}</div>
            {Boolean(btcValue) && (
              <div className={s.tokenInfo_content_id}>
                Floor Price:{` `}
                {btcValue} {` `} <span>{usdValue}</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default memo(NftInfo);
