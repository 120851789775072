import { APP_ENV } from '@/configs';

export const goToRoute = (router: any, url: string) => {
  router.push(url);
};

export const getLinkNFT = (tokenAddress: string, tokenId: string) => {
  if (APP_ENV === 'production') {
    return `https://opensea.io/assets/ethereum/${tokenAddress}/${tokenId}`;
  }
  return `https://opensea.io/assets/matic/${tokenAddress}/${tokenId}`;
};
