export const IcReaction = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9">
      <path d="M23.4747 15.4957C22.7833 17.7666 21.434 19.7815 19.5975 21.2853C17.761 22.7891 15.5198 23.7143 13.1575 23.9438C10.7952 24.1733 8.41787 23.6969 6.32634 22.5747C4.2348 21.4526 2.52303 19.7351 1.40757 17.6397C0.292108 15.5443 -0.17691 13.165 0.0598509 10.8029C0.296612 8.44086 1.22851 6.20208 2.73766 4.36984C4.2468 2.5376 6.26537 1.19424 8.53799 0.509712C10.8106 -0.174819 13.2352 -0.169763 15.5049 0.524242C17.0121 0.982317 18.414 1.73324 19.6306 2.734C20.8472 3.73477 21.8545 4.96572 22.5948 6.35639C23.3351 7.74706 23.7939 9.27012 23.9448 10.8384C24.0958 12.4066 23.9361 13.9893 23.4747 15.4957Z" fill="url(#paint0_radial_20016_11153)"/>
    </g>
    <path d="M8.81807 7.11572H9.20942L9.31511 7.13572C9.50364 7.17001 9.69789 7.19001 9.88642 7.24144C10.7363 7.49149 11.4644 8.0458 11.9317 8.79858L12.0231 8.93858C12.106 8.81572 12.1774 8.70715 12.2545 8.60429C12.7212 7.93971 13.4035 7.45717 14.1855 7.23858C14.3969 7.18144 14.6197 7.15858 14.8368 7.11858H15.2282L15.5281 7.16144C16.102 7.23512 16.6436 7.46905 17.0907 7.83643C17.5379 8.2038 17.8725 8.68975 18.0562 9.23858C18.2138 9.72685 18.273 10.2415 18.2304 10.7529C18.2096 11.9169 17.7764 13.0357 17.0078 13.91C16.5291 14.4359 16.0188 14.9321 15.4796 15.3957L12.0831 18.4186C12.0649 18.4361 12.0448 18.4514 12.0231 18.4643L11.9374 18.39C11.029 17.5767 10.1207 16.7643 9.21228 15.9529C8.51528 15.33 7.79829 14.7272 7.16128 14.0386C6.63309 13.4704 6.24228 12.7885 6.01893 12.0455C5.79558 11.3025 5.74565 10.5182 5.87297 9.75287C5.96813 9.13663 6.24872 8.564 6.67739 8.11126C7.10605 7.65851 7.66245 7.34713 8.27247 7.21858C8.45529 7.16715 8.64097 7.14429 8.81807 7.11572Z" fill="#FFF9F8"/>
    <defs>
      <radialGradient id="paint0_radial_20016_11153" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.9999 11.9957) scale(11.9947 11.9971)">
        <stop stop-color="#FFF2E1"/>
        <stop offset="1" stop-color="#FF0005"/>
      </radialGradient>
    </defs>
  </svg>
)
